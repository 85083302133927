import { Tabs } from 'antd';
// import IconLocation from 'assets/svg/IconLocation';
import ServicesTab from '../../widgets/Tabs/ServicesTab';
import VouchersTab from '../../widgets/Tabs/VouchersTab';
import IconLocation from 'assets/svg/IconLocation';
import checkoutSelectors from 'features/checkout/services/selectors';
import CustomerDetail from 'features/checkout/widgets/Customer/Detail';


const items = [
  // TODO: NEXT PHRASE
  // {
  //   key: '1',
  //   label: 'Favorites',
  //   children: <ServicesTab isFavorite />,
  // },
  {
    key: '2',
    label: 'Services',
    children: <ServicesTab />,
  },
  {
    key: '3',
    label: 'Vouchers',
    children: <VouchersTab />,
  },
];
const BlockInformation = ({step}: {step: number}) => {
  const location = checkoutSelectors.getLocationData();

  return (
    <div className='block'>
      {location && <div className='block-address'>
        <IconLocation />
        {location.name} - {location.address}
      </div>}
      <CustomerDetail step={step} />

      <div className='block-tabs'>
        <Tabs defaultActiveKey='2' items={items} />
      </div>
    </div>
  );
};

export default BlockInformation;
