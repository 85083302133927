import { get } from 'lodash';
import uiSelector from 'services/UI/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { PATH_LOADING } from './constants';
import { IStateCustomerDetail } from './types/reducer';
import { IAppointmentItemResData } from './types/appointment';
import { ICustomerParam } from './types/cutomer';


type MyState = RootState['customer'];

const getCurrentState = (state: RootState): MyState => state.customer;

export const getPureParams = (root: RootState) => {
  const state = getCurrentState(root);
  return state.params ?? {} as ICustomerParam;
};

const selector = <KEY = keyof MyState>(key: KEY, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key as any, defaultValue));

const getCustomerList = () => selector('customerList') as MyState['customerList'];

const loadingCustomerList = () => uiSelector.getLoading(PATH_LOADING.getListCustomersPaginate);

const getCustomerListPaginate = () => selector('customerListPaginate') as MyState['customerListPaginate'];

const loadingCustomerListPaginate = () => uiSelector.getLoading(PATH_LOADING.getListCustomersPaginate);


const loadingTableCustomer = () => {
  const loadingDeleteCustomer = uiSelector.getLoading(PATH_LOADING.deleteCustomer);
  const loadingCreateCustomer = uiSelector.getLoading(PATH_LOADING.createCustomer);
  return (
    loadingDeleteCustomer || loadingCreateCustomer
  );
};

const getCustomerDetail = () => selector('customerDetail.info') as IStateCustomerDetail | null;

const getCustomerIsWalkinIn = () => selector('customerDetail.is_walkin_in');

const getAppointmentsCustomerDetail = () => selector('customerDetail.appointments') as IAppointmentItemResData[];

const loadingCustomerDetail = () => uiSelector.getLoading(PATH_LOADING.getCustomerDetail);

const getIdCustomerDetail = () => selector('customerDetail.id') as string | number | null;

const getParams = () => selector('params') as ICustomerParam;

const customerSelectors = {
  getCustomerList,
  loadingCustomerList,
  getCustomerListPaginate,
  loadingCustomerListPaginate,
  getCustomerDetail,
  loadingCustomerDetail,
  getIdCustomerDetail,
  getAppointmentsCustomerDetail,
  loadingTableCustomer,
  getCustomerIsWalkinIn,
  getParams
};

export default customerSelectors;