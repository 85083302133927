// import AuthAvatar from 'assets/images/auth/auth_avatar.jpg';
import { Empty, Spin, message } from 'antd';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import notificationActions from 'components/layout/Header/service/actions';
import apisNotification from 'components/layout/Header/service/apis';
import notificationSelectors from 'components/layout/Header/service/selectors';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import getSingleDateShortFormat from 'utils/moment/getSingleDateShortFormat';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

const AllTab = () => {
  const selectors = notificationSelectors.getNoificationList();
  const dispatch = useDispatch();
  const loadingListNotification = notificationSelectors.loadingNotification();
  const [open, setOpen] = useState(false);
  const modalConfirmRef = useRef<ModalConfirmRef>(null);
  const { t: headerLang } = useTranslation('header');
  useEffect(() => {
    dispatch(notificationActions.getListNotification.fetch());
  }, []);
  const onclickCheckView = (e: number) => {
    apisNotification.getCheckViewNotification(e);
    dispatch(notificationActions.getListNotification.fetch());
  };

  const onClickDeleteNotification = () => {
    setOpen(!open);
    modalConfirmRef.current?.show({
      title: headerLang('DeleteThisNotifications'),
      msg: headerLang('AreYouSureYouWantToDeleteThisNotifications'),
      submit: async () => {
        try {
          const res = await apisNotification.deleteNotification();
          const msg = get(res, 'data.message', '');
          if (msg) {
            message.success(msg);
            dispatch(notificationActions.getListNotification.fetch());
          } else {
            const msgError = get(res, 'data.error.message');
            if (msgError) message.error(msgError);
          }
        } catch (error) {
          message.error('An error occurred. Please try again');
        }
      },
      icon: 'delete',
    });
  };

  return (

    <div className='notification-block is-all'>
      <NotificationListStyled>
        <button className={selectors.length > 0 ? 'notification-remove-btn' : 'dl-none'} onClick={() => onClickDeleteNotification()}></button>
        <p className='notification-title'>{headerLang('Notifications')}</p>
        <Spin spinning={loadingListNotification}>
          <ul className='notification-list'>
            {
              selectors.length > 0 ?
                selectors?.map((o, index) =>
                  <>
                    <li onClick={() => onclickCheckView(o.id)} key={index}>
                      <div className={o.viewed == 0 ? 'icon' : 'no_icon'}></div>
                      <NotificationImageStyled>
                        <div className='notification-avatar'>

                          <div className='notification-img common-avatar'>
                            {o.full_name && Array.from(o.full_name)[0]}
                          </div>

                        </div>
                      </NotificationImageStyled>
                      <div>
                        <p className='notification-text'>
                          <span>{o.full_name}</span> {o.notify_type} {' '}
                          {/* <span>{e.service}</span> */}
                        </p>
                        <p className='notification-time'>{getSingleDateShortFormat(moment(o.time))} {headerLang('at')} {moment(o.time).format('HH:mm')}</p>
                      </div>
                    </li>
                  </>
                ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No notification'} />
            }
          </ul>
        </Spin>
        <ModalConfirm ref={modalConfirmRef} />
      </NotificationListStyled>
    </div>
  );
};

export default AllTab;

const NotificationListStyled = styled.div`
.dl-none {
    display: none;
}
li {
  display: flex;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid var(--color-white-02);
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid var(--color-purple);
  }

  &:hover {
    opacity: 0.7;
  }

  .no_icon {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    left: 8px;
    top: 8px;
    border-radius: 50%;
  }

  .icon {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background: var(--color-blue-02);
    border: 1px solid var(--color-blue-01);
    position: absolute;
    left: 8px;
    top: 8px;
    border-radius: 50%;
  }
}
`;

const NotificationImageStyled = styled.div`
.notification-img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
}
`;
