import MultipleSelect from 'components/common/MultipleSelect';
import bookingActions from 'features/bookings/services/actions';
import apisBooking from 'features/bookings/services/apis';
import bookingSelectors from 'features/bookings/services/selectors';
import { IApiBookingParams } from 'features/bookings/services/types/booking';
import { memo, useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';

const StatusPicker = () => {
  const dispatch = useAppDispatch();
  const lstBookingStatus = bookingSelectors.getLstBookingStatus();
  const value = bookingSelectors.getLstTableParamValue('book_status') as IApiBookingParams['book_status'];

  const options = useMemo(() => {
    return lstBookingStatus.map(item => {
      return ({
        value: item.value,
        title: item.label,
      });
    });
  }, [lstBookingStatus]);

  const allValue = options.map(o => o.value);

  const onChange = async (val: (string | number)[]) => {
    dispatch(bookingActions.setListTableBookingParams({
      book_status: val,
    }));

    await apisBooking.storeParamOptionCalendar({action_behavior: 'booking_status_filter', action_behavior_value: val});
    dispatch(bookingActions.getParamOptionCalendar.fetch());
  };

  return (
    <div className='d-flex'>
      <MultipleSelect
        onChange={onChange}
        value={value === undefined ? allValue : value}
        options={options}
        isWhiteInput
        maxTagPlaceholder='status'
        placeholder='Select status'
      />
    </div>
  );
};

export default memo(StatusPicker);
