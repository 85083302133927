import { Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import PhoneNumberInput from 'components/common/PhoneNumberInput';
import { formOptionalText } from 'utils/unit';
import NumberValidations from 'utils/validations/number';

const LocationsFormFirstStep = () => {

  return (
    <>
      <Form.Item label='Location Name' name='locationName'
        rules={[
          { required: true }
        ]}>
        <Input autoFocus placeholder='Enter location name' />
      </Form.Item>
      <Form.Item label='Address' name='address'
        rules={[
          { required: true }
        ]}>
        <Input placeholder='Enter address' />
      </Form.Item>
      <p className='form-note is-bottom'>
        This address can be used as your billing detail address
      </p>
      <div className='form-row '>
        <Form.Item label='Phone number' name='locationPhoneNumber'
          rules={[
            { required: true },
            NumberValidations.PHONE
          ]}>
          <PhoneNumberInput placeholder='Enter your phone number' />
        </Form.Item>
        
      </div>
      <div className='form-row items-end'>
        <Form.Item label='ABN' name='business_abn'>
          <Input placeholder='Enter your ABN' />
        </Form.Item>
      </div>
      <Form.Item
        label={
          <p>
            Description <span>{formOptionalText}</span>
          </p>
        }
        name='description'
      >
        <TextArea
          placeholder='This is store description.'
          rows={4}
        />
      </Form.Item>
    </>
  );
};

export default LocationsFormFirstStep;
