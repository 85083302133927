import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IDaysViewTableItem, IMemberViewItem } from 'features/workingHour/services/types/member';
import EditWorkingTime from 'features/workingHour/widgets/WorkingTime/Edit';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import getSingleDateShortFormat from 'utils/moment/getSingleDateShortFormat';
type IDaysViewTableProps = {
  data: IDaysViewTableItem;
  loading?: boolean;
};
const DaysViewTable: React.FC<IDaysViewTableProps> = ({ data }) => {
  const [isCollapse, setCollapse] = useState(true);  
  const [numOfItem, setNumOfItem] = useState(3);

  const columns: ColumnsType<IMemberViewItem> = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      fixed: 'left',
      width: '150px',
      render: (value) => getSingleDateShortFormat(moment(value)),
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (value: { start: string, end: string }[]) => {
        return value.length === 0 ? 
          <p>--:-- - --:--</p> : 
          value.map((o, i) =>  {          
            const time_start = o.start ? moment(o.start).format('HH:mm') : undefined;
            const time_end = o.end ? moment(o.end)?.format('HH:mm') : undefined;
          return <p key={i}>{time_start ? time_start : '--:--'} - {time_end ? time_end : '--:--'}</p>;
      });
      }
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <ul className={'common-action'}>
          <EditWorkingTime data={record} employee_id={Number(data.id)} />
        </ul>
      ),
    },
  ];

  useEffect(() => {
    setNumOfItem(3);
  }, [data.days]);

  const dataSource = useMemo(() => {
    if (data.days.length <= 3) return data.days;
    // if (isCollapse) return data.days;
    if(numOfItem >= data.days.length) setCollapse(false);
    return data.days.slice(0, numOfItem);
  }, [data.days, numOfItem]);

  return (
    <DaysViewTableStyled>
      <div className='header-table'>
        <p >{data.name}: {data.total_worked}</p>
      </div>
      <Table
        className={'custom-table'}
        columns={columns}
        dataSource={dataSource}
        rowKey={(re) => re.id}
        scroll={{
          x: 900,
        }}
        pagination={false}
      />
      <div className='footer-table'>
        <p >Total: : {data.total_worked}</p>
      </div>
      {data.days.length > 3 ? (isCollapse ?
        <button className='btn-collapse' onClick={() => setNumOfItem(numOfItem + 5)}> <span>View more</span> <IconDown /></button> :
        <button className='btn-collapse' onClick={() => {setNumOfItem(3); setCollapse(true);}}><span>Close</span> <IconUp /></button>) : <></>
      }
    </DaysViewTableStyled >
  );
};

export default DaysViewTable;
const DaysViewTableStyled = styled.div`
border-radius: 6px;
background: var(--background-white, #FFF);
box-shadow: 0px 4px 24px 0px rgba(225, 225, 239, 0.60);
display: flex;
width: 100%;
padding: 24px;
flex-direction: column;
align-items: center;
gap: 24px;
.ant-table-wrapper table {
  width: 100% !important;
  @media only screen and (max-width: 992px) {
    width: 100% !important;
  }
  @media only screen and (max-width: 767.98px) {
    width: 900px !important;
  }
}
.custom-table {
  width:100%;
}
.header-table, .footer-table {
  align-self: stretch;
  p {
    color: var(--primary-blue, #363565);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    text-transform: capitalize;
  }
}
.btn-collapse {
  display: flex;
  align-items: center;
  &:hover {
    background:var(--color-overlay);
  }
}
`;

const IconDown = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.5 7.5L10.5 12.5L15.5 7.5" stroke="#363565" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
const IconUp = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 12.5L10 7.5L5 12.5" stroke="#363565" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);