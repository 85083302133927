import { Modal, Spin } from 'antd';
import PromotionForm, { IPromotionFormProps } from './Form';
import promotionSelectors from '../services/selectors';
import { useTranslation } from 'react-i18next';
interface IPromotionModalProps extends IPromotionFormProps {
  visible?: boolean;
}
const PromotionModal = ({ visible, form, ...rest }: IPromotionModalProps) => {
  const { t: formLang } = useTranslation('form');
  const loading = promotionSelectors.getLoadingDetailPromotion();


  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={rest.onClose}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <h2 className='modal-title'>
        {rest.isEdit ? formLang('promotion.editPromotionModalTitle') : formLang('promotion.addPromotionModalTitle')}
      </h2>
      <Spin spinning={loading}>
        <PromotionForm {...rest} form={form} />
      </Spin>
    </Modal>

  );
};

export default PromotionModal;
