import message from 'components/common/Message';
import { IPromotionErrorBoundary } from 'features/checkout/services/types/promotion';
import { IServiceSelectedItem as CheckoutSer } from 'features/checkout/services/types/service';
import apisPromotion from 'features/promotions/services/apis';
import { IPromotionDetailResData } from 'features/promotions/services/types/promotion';
import { IServiceSelectedItem as SalesSer } from 'features/sales/services/types/service';
import { first, get } from 'lodash';
import { mappingPromotionInfo } from './PromotionInput';
import useCheckServicesPromotion from './useCheckServicesPromotion';
type IService = CheckoutSer | SalesSer;
type IProps = {
  setErrorBoundary: (val: IPromotionErrorBoundary | null) => void,
  servicesSelected: IService[]
};
const useDoRedeem = (location_id: number | string, {
  setErrorBoundary,
  servicesSelected,
}: IProps) => {
  const checkSuitableServices = useCheckServicesPromotion(servicesSelected);
  return async (text: string | null) => {
    if (!text) {
      setErrorBoundary({ error: 'Please input promotion code' });
      return null;
    }

    try {
      const res = await apisPromotion.checkPromotionOfflineValid(text, location_id);
      const errorMsg = get(res, 'data.error.message');
      if (errorMsg) {
        setErrorBoundary({ error: errorMsg });
        return null;
      } else {
        const resData: IPromotionDetailResData = res?.data?.data;
        if (resData) {
          const dataMapping = mappingPromotionInfo(resData);
          const isNoSuitableServices = !checkSuitableServices(dataMapping);
          if (isNoSuitableServices) {
            setErrorBoundary({ isNoSuitableServices: true });
            return null;
          }
          setErrorBoundary({ success: true });
          return dataMapping;
        }
        return null;
      }
    } catch (error: any) {
      const responseMsg = get(
        error,
        'response.data.error.message',
        {}
      );
      if (responseMsg) {
        setErrorBoundary({ error: responseMsg });
        return null;
      }

      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.entries(response);
      const err = first(errors);
      if (err) {
        const msg = get(err, [1, 0], '');
        if (err[0] === 'promotion_code') {
          setErrorBoundary({ error: msg });
        } else {
          message.error(msg);
        }
      }
      return null;
    }
  };
};

export default useDoRedeem;
