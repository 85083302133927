import { message } from 'antd';
import { IMessageProps } from '../type';

const CustomWarningMessage = (props: IMessageProps) => {
  const _text = typeof props === 'string' ? props : props?.text;
  return message.warning({
    className: 'is-warning',
    content: (
      <p>
        <span>Warning :</span>
        {_text}
      </p>
    ),
  });
};

export default CustomWarningMessage;
