import { Modal } from 'antd';
import React from 'react';
import RewardForm, { RewardFormProps } from './Form';
interface Props extends RewardFormProps {
  visible?: boolean;
}
const RewardModal = ({ visible, ...rest }: Props) => {
  if (!visible) return null;

  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={rest.onCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <h2 className='modal-title'>
        <p>{!rest.isEdit ? 'Add Reward' : 'Edit Reward'} </p>
      </h2>
      <RewardForm {...rest} />
    </Modal>
  );
};

export default RewardModal;
