import { createAction, createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IApiTeamMembersParams, IServiceItemResData, ITeamMemberItemResData } from './types/teamMember';
import { IReqApiListUser } from './types/reducer';
const getTeamMembers = createAsyncAction<IApiTeamMembersParams>(PREFIX_ACTIONS + 'GET_TEAM_MEMBERS');

const getListPaginateStaffs = createAsyncAction<any>(PREFIX_ACTIONS + 'GET_LIST_STAFFS');

const getShowCreateStaffs = createAsyncAction(PREFIX_ACTIONS + 'GET_SHOW_CREATE_STAFFS');

const setStaffServices = createAction<IServiceItemResData[]>(PREFIX_ACTIONS + 'SET_STAFF_SERVICES');

const getStaffServices = createAction<IServiceItemResData[]>(PREFIX_ACTIONS + 'GET_STAFF_SERVICES');

const setStaffDetail = createAction<ITeamMemberItemResData>(PREFIX_ACTIONS + 'SET_STAFF_DETAIL');

const getProfileStaffDetail = createAsyncAction(PREFIX_ACTIONS + 'GET_STAFF_DETAIL');

const deleteStaffs = createAsyncAction<string>(PREFIX_ACTIONS + 'DELETE_STAFFS');

const setParams = createAction<IReqApiListUser>(PREFIX_ACTIONS + 'SET_PARAMS');

const setLocationSelectedId = createAction<string>(PREFIX_ACTIONS + 'SET_LOCATION_SELECTED_ID');

const userActions = {
    getTeamMembers,
    getListPaginateStaffs,
    getShowCreateStaffs,
    setStaffServices,
    getStaffServices,
    setStaffDetail,
    getProfileStaffDetail,
    deleteStaffs,
    setParams,
    setLocationSelectedId
};

export default userActions;