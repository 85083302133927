import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import IconLocation from 'assets/svg/IconLocation';
import salesActions from 'features/sales/services/actions';
import salesSelectors from 'features/sales/services/selectors';
import { find, get } from 'lodash';
import { useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

const LocationPicker = () => {
  const dispatch = useAppDispatch();
  const data = salesSelectors.getConfigsData();
  const valueId = salesSelectors.getMerchantLocationId();
  const locations = useMemo(() => data?.locations ?? [], [data?.locations]);

  const onChange = (val: typeof valueId) => {
    const cur = locations.find(o => o.id?.toString() === val?.toString());
    if (!cur) return;
    dispatch(salesActions.setMerchantLocationId(cur));
  };


  const valueLabel = useMemo(() => {
    const item = find(locations, o => o.id?.toString() === valueId?.toString());
    return get(item, 'name', '');
  }, [locations, valueId]);

  const items: MenuProps['items'] = locations.map(o => ({
    label: o.name,
    key: o.id,
    onClick: (e) => onChange(e.key)
  }));
  if (locations.length <= 1) return null;
  return (
    <div>
      <Dropdown
        menu={{ items }}
        trigger={['click']}
        overlayClassName='location-dropdown'
      >
        <div style={{ marginBottom: 12 }}>
          <LocationPickerStyled>
            <div className="icon left"><IconLocation /></div>
            <p className='title'>
              {valueLabel}
            </p>
            <div className="icon right"><DownOutlined /></div>
          </LocationPickerStyled>
        </div>
      </Dropdown>
    </div>
  );
};

export default LocationPicker;
const LocationPickerStyled = styled.div`
  :hover {
    cursor:pointer;
  }
  display:flex;
  align-items:center;
  .icon {
    display: inline-flex;
    margin:0 10px;
    &.left {
      margin-left:0;
    }
  }
  .title {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #363565;
  }
`;