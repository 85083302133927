import ico_action_remove from './ico_action_remove.svg';
import ico_action_send from './ico_action_send.svg';
import ico_arrow_back from './ico_arrow_back.svg';
import ico_check_circle from './ico_check_circle.svg';
import ico_check from './ico_check.svg';
import ico_customers from './ico_customers.svg';
import ico_delete from './ico_delete.svg';
import ico_email from './ico_email.svg';
import ico_export from './ico_export.svg';
import ico_filter from './ico_filter.svg';
import ico_gt from './ico_gt.svg';
import ico_menu_close from './ico_menu_close.svg';
import ico_menu from './ico_menu.svg';
import ico_notification from './ico_notification.svg';
import ico_pagination_left from './ico_pagination_left.svg';
import ico_pagination_right from './ico_pagination_right.svg';
import ico_placeholder from './ico_placeholder.svg';
import ico_plus from './ico_plus.svg';
import ico_search_big from './ico_search_big.svg';
import ico_input_search from './ico_input_search.svg';
import ico_search from './ico_search.svg';
import ico_send from './ico_send.svg';
import ico_settings_close from './ico_settings_close.svg';
import ico_settings_copy from './ico_settings_copy.svg';
import ico_settings_edit from './ico_settings_edit.svg';
import ico_settings_user from './ico_settings_user.svg';
import ico_view_calendar_active from './ico_view_calendar_active.svg';
import ico_view_calendar from './ico_view_calendar.svg';
import ico_view_grid_active from './ico_view_grid_active.svg';
import ico_view_grid from './ico_view_grid.svg';
import ico_view_list_active from './ico_view_list_active.svg';
import ico_view_list from './ico_view_list.svg';
import ico_arrow_up from './ico_arrow_up.svg';
import ico_info from './ico_info.svg';
import ico_reward from './ico_reward.svg';
import ico_help_circle from './ico_help_circle.svg';

const icons = {
  ico_reward,
  ico_arrow_up,
  ico_action_remove,
  ico_action_send,
  ico_arrow_back,
  ico_check_circle,
  ico_check,
  ico_customers,
  ico_delete,
  ico_email,
  ico_export,
  ico_filter,
  ico_gt,
  ico_menu_close,
  ico_menu,
  ico_notification,
  ico_pagination_left,
  ico_pagination_right,
  ico_placeholder,
  ico_plus,
  ico_search_big,
  ico_input_search,
  ico_search,
  ico_send,
  ico_settings_close,
  ico_settings_copy,
  ico_settings_edit,
  ico_settings_user,
  ico_view_calendar_active,
  ico_view_calendar,
  ico_view_grid_active,
  ico_view_grid,
  ico_view_list_active,
  ico_view_list,
  ico_info,
  ico_help_circle
};

export default icons;
