import IconReward from 'assets/svg/IconReward';
import invoiceSelectors from 'features/invoices/services/selectors';
import { Fragment } from 'react';
import { CUSTOMER_WALKIN_INDEX, formatMoney, pluralizeWord, roundNumber } from 'utils/unit';

export interface IResultItem {
  label: string;
  value: number;
  isMinus?: boolean;
  isPlus?: boolean;
  isFinalTotal?: boolean;
  customName?: string;
}

const ServiceDetails = () => {
  const data = invoiceSelectors.detailInvoice.getData();

  const ResultItem = ({ label, value, isMinus = false, isPlus = false, isFinalTotal = false, customName = undefined }: IResultItem) => {
    return value > 0 || isFinalTotal ? (
      <tr className={isFinalTotal ? 'tr-total' : ''}>
        <td colSpan={2}>{customName ?? label}</td>
        <td className="text-price">{isMinus ? '-' : ''} {isPlus ? '+' : ''} {formatMoney(value)}</td>
      </tr>
    ) : <></>;
  };

  const getValuePercent = (percent: number) => {    
    return roundNumber((data?.subtotal ?? 0)*(percent/100));
  };
  
  const extraFeeValue = (data?.extra_fee?.fee_type ?? 'amount') === 'percent' ? getValuePercent(Number(data?.extra_fee?.fee_value ?? 0)) ?? 0 : Number(data?.extra_fee?.fee_value ?? 0) ?? 0;
  const discountFeeValue = (data?.discount_type ?? 'amount') === 'percent' ? 
    ((data?.subtotal ?? 0) + extraFeeValue)*( Number(data?.discount_value ?? 0 )/100):
    Number(data?.discount_value) ?? 0;

  const resultsInvoice: IResultItem[] = [
    {
      label: 'Subtotal',
      value: data?.subtotal ?? 0,
      isMinus: false,
    },
    {
      label: 'Extra fee',
      value: extraFeeValue,
      isPlus: true,
      customName: data?.extra_fee?.name
    },
    {
      label: 'Extra discount',
      value: discountFeeValue,
      isMinus: true,
      customName: data?.discount_name
    },
    {
      label: 'Reward',
      value: data?.reward?.total_amount_reward ?? 0,
      isMinus: true,
    },
    {
      label: 'Promotion',
      value: data?.total_apply_promotion ?? 0,
      isMinus: true,
    },
    {
      label: 'Public Holiday charge',
      value: roundNumber(data?.holiday_public?.total_amount_surcharge ?? 0),
    },
    {
      label: 'Card surcharge',
      value: roundNumber(data?.card_surcharge_price ?? 0),
    },
    {
      label: 'Voucher',
      value: data?.total_amount_apply_voucher ?? 0,
      isMinus: true,
    },

    {
      label: 'Refund' + (data?.refunds && data?.refunds?.length > 0 ? ' - ' + data?.refunds[0]?.refund_method : ''),
      value: data?.refunds && data?.refunds?.length > 0 ? data?.refunds[0].amount_refund : 0,
      isMinus: true,
    },
    {
      label: 'TOTAL',
      value: (data?.total_price ?? 0) - (data?.refunds && data?.refunds?.length > 0 ? data?.refunds[0]?.amount_refund : 0) ?? 0,
      isMinus: false,
      isFinalTotal: true,
    },
  ];
  

  return (
    <div className="common-card">
      <h2 className="card-title">Invoice Details</h2>
      <div className="card-body">
        <div className="invoice-service-details-card">
          <table className="services-table">
            <thead>
              <tr className='tr-service'>
                <th>ITEMS</th>
                <th>QTY</th>
                <th className="text-right">Price</th>
              </tr>
            </thead>
            <tbody>
              {data?.merchant_invoice_details.map((row, idx) => {

                return (
                  <Fragment key={idx}>
                    <tr>
                      <td>
                        <p className='service_name'>{row.service_name || row.service_variant_name}</p>
                        <p className='service_by'>{row.service_variant_name || row.service_name} with {row.employee_name}</p>
                      </td>
                      <td >{row.quantity}</td>
                      <td className="text-price">{formatMoney(row?.service_price ?? 0)}</td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="divider"></td>
                    </tr>
                  </Fragment>
                );
              })}
              {data?.voucher_sales.map((row, idx) => {

                return (
                  <Fragment key={idx}>
                    <tr>
                      <td>
                        <p className='service_name'>{row.voucher_name}</p>
                        {/* <p className='service_by'>{row.voucher_code}</p> */}
                      </td>
                      <td >{row.quantity}</td>
                      <td className="text-price">{formatMoney(row?.retail_price ?? 0)}</td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="divider"></td>
                    </tr>
                  </Fragment>
                );
              })}
              <tr>
                <td colSpan={3} className="space"></td>
              </tr>

              {resultsInvoice.map((o, index) => (
                <ResultItem key={index} {...o} />
              ))}

              <tr className='tr-payment-method'>
                <td colSpan={2}>Payment method</td>
                <td className="text-price text">{data?.payment_methods === 'mixin' ? 'Mixed' : data?.payment_methods}</td>
              </tr>
             {(data?.earned_point ?? 0) > 0 && data?.customer.is_walkin_in !== CUSTOMER_WALKIN_INDEX && <tr className='tr-payment-method'>
                <td colSpan={2}> <IconReward /> <span>Earned ({data?.earned_point} {pluralizeWord('point', data?.earned_point ?? 0)})</span></td>
              </tr>}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
