import React, { useMemo } from 'react';
import styles from './index.module.scss';
import IconClose from 'assets/icons/ico_close.svg';
import settingSelectors from 'features/settings/services/selectors';

const Icon = () => {
  return <img src={IconClose} alt={'icon-close'} />;
};

type Props = {
  label?: string;
  isCheckIn?: boolean;
};
const StickyPromotion: React.FC<Props> = ({ label = '', isCheckIn }) => {
  const [visible, setVisible] = React.useState(true);
  const hide = () => setVisible(false);
  const setting = settingSelectors.getSetting();
  const _label = useMemo(() => {
    if (isCheckIn) {
      return setting?.checkin_text?.promotion_text;
    } else {
      return setting?.booking_online_text?.promotion_text;
    }
    // return label;
  }, [label, isCheckIn, setting]);

  if (!visible || !_label) return null;

  return (
    <div className={styles.container}>
      <div className={styles.icon_placeholder}><Icon /></div>
      <p className={styles.label}>{_label}</p>
      <button className={styles.btn_close} onClick={hide}><Icon /></button>
    </div>
  );
};

export default StickyPromotion;
