import { createSlice } from '@reduxjs/toolkit';
import { NAME_REDUCER } from './constants';
import actions from './actions';
import authActions from 'features/auth/services/actions';

const initialState: any = {
  listPaginateLocation: [],
  getLocationDetail: {},
  getLocationDetailPublic: {}
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActions.logout.success, (state) => {
        state.listPaginateLocation = [];
        state.getLocationDetail = {};
        state.getLocationDetailPublic = {};
      })
      .addCase(actions.getListPaginateLocations.success, (state, { payload }) => {
        const data = payload as any[];
        state.listPaginateLocation = data;
      })
      .addCase(actions.getLocationDetail.success, (state, { payload }) => {
        const data = payload as any[];
        state.getLocationDetail = data;
      })
      .addCase(actions.getLocationDetailPublic.success, (state, { payload }) => {
        const data = payload as any[];
        state.getLocationDetailPublic = data;
      });
  },
});
const locationServiceReducer = Slice.reducer;
export default locationServiceReducer;
