import fetch from 'services/request';

const BASE_URL = 'api/v1/merchants/:merchant_code/location';

const getListPaginateLocations = (params?: any) => {
  return fetch({
    method: 'get',
    url: BASE_URL,
    params: { ...params },
  });
};

const getLocationDetail = (id?: string) => {
  return fetch({
    method: 'get',
    url: `${BASE_URL}/${id}`,
  });
};

const getLocationDetailPublic = (merchant_code: string, location_id?: string) => {  
  return fetch({
    method: 'get',
    url: `api/v1/merchants/${merchant_code}/public/locations/${location_id}`,
  });
};

const storeLocation = (body?: any) => {
  return fetch({
    method: 'post',
    url: BASE_URL,
    body: { ...body },
  });
};

const deleteLocation = (id: string) => {
  return fetch({
    method: 'delete',
    url: `${BASE_URL}/destroy/${id}`,
  });
};

const updateLocation = (id: number, body?: any) => {
  return fetch({
    method: 'put',
    url: `${BASE_URL}/update/${id}`,
    body: { ...body },
  });
};

const updateLocationStatus = (id: number, status: boolean) => {
  return fetch({
    method: 'put',
    url: `${BASE_URL}/update-status`,
    body: {
      id,
      status,
    },
  });
};

const apisLocation = {
  getListPaginateLocations,
  storeLocation,
  updateLocation,
  deleteLocation,
  getLocationDetail,
  updateLocationStatus,
  getLocationDetailPublic
};

export default apisLocation;
