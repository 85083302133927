import { IPagination } from 'models/config';
import { IApiTimeScheduleBodyReq } from './api';

export enum ETimeSchedule {
  ON = 'working_day',
  CLOSED_DAY = 'closed_day',
  HOLIDAY_OFF = 'holiday_off',
  DAY_OFF = 'day_off',
}

export enum TimeOffModalType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export type TimingShiftScheduleType = {
  time_start: string;
  time_end: string;
  is_work?: boolean;
};

export interface TimingType {
  shift_type: ETimeSchedule;
  date: string;
  day_of_week: string;
  full_day_of_week: string;
  content?: string;
  timing_shifts?: TimingShiftScheduleType[];
  timing_schedules?: TimingShiftScheduleType[];
  repeat_every_day?: boolean;
}

export interface IResTimeScheduleList {
  data: IApiTimeScheduleBodyReq[];
  pagination: IPagination;
}

export interface ILocationTimeOpeningItem {
  weekday: string;
  time_start: string;
  time_end: string;
}

export interface ILocationDateCloseItem {
  start_date: string;
  end_date: string;
  description: null | string;
}

export interface ITimeOffFormValue {
  teamMember?: number | string;
  oneDayLeave?: boolean;
  dayOff?: string;
  startTime?: string;
  endTime?: string;
  repeatCheck?: boolean;
  timeOffRepeatType?: string;
}

export interface EditShiftInitValue {
  date: string;
  time: {
    text?: string;
    type?: ETimeSchedule;
  }[];
  merchantEmployeeId: string | number;
  repeat_every_week?: boolean
}

export interface ITimeShiftFormValue {
  dayOff?: string;
  times?: {
    start: string;
    end: string;
  }[];
  repeatEveryWeek?: boolean;
}

export interface ITimeTimeOffFormValue {
  teamMember?: string | number;
  dayOff?: string;
  startTime?: string;
  endTime?: string;
}
