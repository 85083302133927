import { message } from 'antd';
import { IMessageProps } from '../type';

const CustomErrorMessage = (props?: IMessageProps) => {
  
  const _text = (typeof props === 'string' ? props : props?.text) || 'An error occurred. Please try again';
  return message.error({
    className: 'is-error',
    content: (
      <p>
        <span>Error :</span>
        {_text}
      </p>
    ),
  });
};

export default CustomErrorMessage;
