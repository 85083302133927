const IconVoucherCircle = () => {
  return (
    <svg
      width='40'
      height='41'
      viewBox='0 0 40 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect y='0.5' width='40' height='40' rx='20' fill='#F4FFFA' />
      <path
        d='M22.8 16.5L24 17.7L17.2 24.5L16 23.3L22.8 16.5ZM12 12.5H28C29.11 12.5 30 13.39 30 14.5V18.5C29.4696 18.5 28.9609 18.7107 28.5858 19.0858C28.2107 19.4609 28 19.9696 28 20.5C28 21.0304 28.2107 21.5391 28.5858 21.9142C28.9609 22.2893 29.4696 22.5 30 22.5V26.5C30 27.61 29.11 28.5 28 28.5H12C11.4696 28.5 10.9609 28.2893 10.5858 27.9142C10.2107 27.5391 10 27.0304 10 26.5V22.5C11.11 22.5 12 21.61 12 20.5C12 19.9696 11.7893 19.4609 11.4142 19.0858C11.0391 18.7107 10.5304 18.5 10 18.5V14.5C10 13.9696 10.2107 13.4609 10.5858 13.0858C10.9609 12.7107 11.4696 12.5 12 12.5ZM12 14.5V17.04C12.6077 17.3904 13.1124 17.8947 13.4633 18.5021C13.8142 19.1094 13.9989 19.7985 13.9989 20.5C13.9989 21.2015 13.8142 21.8906 13.4633 22.4979C13.1124 23.1053 12.6077 23.6096 12 23.96V26.5H28V23.96C27.3923 23.6096 26.8876 23.1053 26.5367 22.4979C26.1858 21.8906 26.0011 21.2015 26.0011 20.5C26.0011 19.7985 26.1858 19.1094 26.5367 18.5021C26.8876 17.8947 27.3923 17.3904 28 17.04V14.5H12ZM17.5 16.5C18.33 16.5 19 17.17 19 18C19 18.83 18.33 19.5 17.5 19.5C16.67 19.5 16 18.83 16 18C16 17.17 16.67 16.5 17.5 16.5ZM22.5 21.5C23.33 21.5 24 22.17 24 23C24 23.83 23.33 24.5 22.5 24.5C21.67 24.5 21 23.83 21 23C21 22.17 21.67 21.5 22.5 21.5Z'
        fill='#4A9D77'
      />
    </svg>
  );
};

export default IconVoucherCircle;
