import { DatePicker, Form, Input, Radio } from 'antd';
import PrimaryButton from 'components/common/Buttons/PrimaryButton';
import FormRow from 'components/common/Form/FormRow';
import Header from 'features/bookings/components/BookingForm/Information/Header';
import Section from 'features/bookings/components/BookingForm/Section';
import { HBox } from 'features/bookings/components/BookingForm/UIBase';
import { useTranslation } from 'react-i18next';
import { dateFormat } from 'utils/unit';
import NumberValidations from 'utils/validations/number';

const FormInfo = () => {
  const { t: formLang } = useTranslation('form');

  return (
    <>
      <Header />
      <Section
        label='General Information'
        text='Please fill in your full name and the information below to schedule an appointment.'
      >
        <>
          <Form.Item label='Location' name='Location'>
            <Input placeholder='StoreOne, 22 Sydney Road, Wilpinjon, New South Wales, 28..' />
          </Form.Item>
          <Form.Item label='Name' name='Name'>
            <Input placeholder={formLang('EnterYourNamePlaceholder') || ''} />
          </Form.Item>
          <HBox>
            <Form.Item
              label='Phone Number'
              name='phoneNumber'
              rules={[
                {
                  required: true,
                  message: 'Please input your phone number!',
                },
                NumberValidations.PHONE
                // {
                //   /* eslint-disable-next-line */
                //   pattern:/^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/,
                //   message: 'Your phone number format is invalid!'
                // },
              ]}
            >
              <Input placeholder={formLang('EnterNumberPlaceholder') || ''} />
            </Form.Item>
            <div className='btn-list-search is-end'></div>
          </HBox>
          <p className='form-note'>
            Enter your phone number to check if you have already had an account
          </p>
          <Form.Item label='Name' name='Name'>
            <Input placeholder={formLang('EnterYourNamePlaceholder') || ''} />
          </Form.Item>
          <FormRow label='Gender' name='gender' optional>
            <Radio.Group>
              <Radio value={0}>Female</Radio>
              <Radio value={1}>Male</Radio>
              <Radio value={2}>Non-binary</Radio>
            </Radio.Group>
          </FormRow>
          <FormRow label='Birth Date' name='birthDate' optional>
            <DatePicker
              format={dateFormat}
              placeholder={formLang('EnterYourDOBPlaceholder') || ''}
              suffixIcon={false}
              allowClear={false}
            />
          </FormRow>
        </>
      </Section>
      <div className='items-center'>
        <PrimaryButton
          className='primary_button'
          label='Continue'
          // onClick={_onSubmit}
        />
      </div>
    </>
  );
};

export default FormInfo;
