import MultipleSelect from 'components/common/MultipleSelect';
import workingHourActions from 'features/workingHour/services/actions';
import workingHourSelectors from 'features/workingHour/services/selectors';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
type ITeamMemberPickerProps = {};
const TeamMemberPicker: React.FC<ITeamMemberPickerProps> = () => {
  const dispatch = useAppDispatch();
  const teamMembers = workingHourSelectors.getLstTeamMembers();
  const value = workingHourSelectors.getParamValue('merchant_employee_id') as (string | number)[];
  const merchant_location_id = (workingHourSelectors.getParamValue('merchant_location_id')) as (string | number);
  const {state} = useLocation();


  const options = useMemo(() => {
    return teamMembers.filter(o => {
      if (!merchant_location_id) return true;
      return o.location_id?.toString() === merchant_location_id?.toString();
    }).map(item => {
      return ({
        value: item.id,
        title: item.full_name,
      });
    });
  }, [teamMembers, merchant_location_id]);
  const allValue = options.map(o => o.value);  

  useEffect(() => {
    dispatch(workingHourActions.setParams({
      // @ts-ignore
      merchant_employee_id: state?.employee_id ? [state?.employee_id] : allValue,
      date: moment().unix(),
      start_date: undefined,
      end_date: undefined,
    }));
  }, [options]);

  const onChange = (val: (string | number)[]) => {
    dispatch(workingHourActions.setParams({
      merchant_employee_id: val ?? allValue,
    }));
  };

  return (
    <TeamMemberPickerStyled>
      <MultipleSelect
        onChange={onChange}
        value={value ?? allValue}
        options={options}
        isWhiteInput
        maxTagPlaceholder='employee'
        placeholder='Select employee'
      />
    </TeamMemberPickerStyled>
  );
};

export default TeamMemberPicker;
const TeamMemberPickerStyled = styled.div``;
