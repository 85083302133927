import checkoutSelectors from 'features/checkout/services/selectors';

const useFee = () => {
  const extraFee = checkoutSelectors.getExtraFee();
  const extraDiscount = checkoutSelectors.getDiscountFee();

  return ({
    discount_extra: extraDiscount?.value ? {
      discount_value: extraDiscount?.value  ?? undefined,
      discount_type: extraDiscount?.type === 'price' ? 'amount' : 'percent' ?? undefined,
      discount_name: extraDiscount?.name ?? '',
    } : undefined,
    extra_fee: extraFee?.value ? {
      name: extraFee?.name ?? '',
      fee_type: extraFee?.type === 'price' ? 'amount' : 'percent' ?? undefined,
      fee_value: extraFee?.value ?? 0,
    } : undefined
  });
};

export default useFee;