import React from 'react';

function ImageDeleteConfirm() {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4412_179869)">
        <path d="M6.71973 0V80H34.9655L44.0208 37.2592L37.2089 0H6.71973Z" fill="#EBF3FE" />
        <path d="M67.7068 40.9805V16.0144L57.2076 10.5614L51.684 0H37.209V70.8309L67.7068 40.9805Z" fill="#D0E4FC" />
        <path d="M67.7068 16.0144H51.684V0L67.7068 16.0144Z" fill="#EBF3FE" />
        <path d="M34.8826 46.8389V80.0003H51.2948L52.4883 64.2601L51.2948 46.8389H34.8826Z" fill="#4F5F73" />
        <path d="M51.2948 46.8389H67.7068V80.0003H51.2948V46.8389Z" fill="#3D4959" />
        <path d="M43.1945 28.9424V36.4177C43.1945 36.9943 42.7254 37.4635 42.1487 37.4635L40.5359 40.2661V42.151H42.1487C45.3101 42.151 47.882 39.5789 47.882 36.4177V33.6299H51.2948L54.6787 31.3533L51.2948 28.9424H43.1945Z" fill="#4F5F73" />
        <path d="M60.4535 37.4635C59.8768 37.4635 59.4076 36.9943 59.4076 36.4177V28.9424H51.2948V33.6299H54.7201V36.4177C54.7201 39.5789 57.2921 42.151 60.4535 42.151H62.0664V40.7543L60.4535 37.4635Z" fill="#3D4959" />
        <path d="M29.3217 37.4639V48.4423H51.2948L55.6009 42.8817L51.2948 37.4639H29.3217Z" fill="#3D4959" />
        <path d="M51.2948 37.4639H73.2806V48.4423H51.2948V37.4639Z" fill="#222842" />
        <path d="M44.0326 54.3457H48.7201V73.8474H44.0326V54.3457Z" fill="#3D4959" />
        <path d="M53.882 54.3457H58.5695V73.8474H53.882V54.3457Z" fill="#222842" />
      </g>
      <defs>
        <clipPath id="clip0_4412_179869">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
}

export default ImageDeleteConfirm;
