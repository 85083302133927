import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { ICustomerDetailResData, ICustomerItemResData, ICustomerParam, ICustomerResDataPaginate } from './types/cutomer';
import { IState } from './types/reducer';
import { IAppointmentItemResData } from './types/appointment';
import authActions from 'features/auth/services/actions';

const initialState: IState = {
  customerList: [],
  customerListPaginate: {},
  customerDetail: {
    id: null,
    info: null,
    is_walkin_in: false,
    appointments: [],
  },
  params: {},
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActions.logout.success, (state) => {
        state.customerList = [];
        state.customerListPaginate = {};
        state.customerDetail = {
          id: null,
          info: null,
          is_walkin_in: false,
          appointments: [],
        };
        state.params = {};
      })
      .addCase(actions.setCustomerListParams, (state, { payload }) => {
        const _params = payload as ICustomerParam;
        const params = {
          ...state.params ?? {},
          ..._params
        };

        if (params.keyword === undefined || params.keyword === null) {
          delete params.keyword;
        }
        if (params.page === undefined || params.page === null) {
          params.page = 1;
        }

        if (params.per_page === undefined || params.per_page === null) {
          params.per_page = 10;
        }

        state.params = params;
      })
      .addCase(actions.getListCustomers.success, (state, { payload }) => {
        const data: ICustomerItemResData[] = payload;
        state.customerList = data;
      })
      .addCase(actions.getListCustomersPaginate.success, (state, { payload }) => {
        const data: ICustomerResDataPaginate = payload;
        state.customerListPaginate = data;
      })
      .addCase(actions.getCustomerDetail.success, (state, { payload }) => {
        const data: ICustomerDetailResData = payload;
        state.customerDetail.info = data;
        state.customerDetail.id = data.id;
        state.customerDetail.is_walkin_in = !!data.is_walkin_in;
      })
      .addCase(actions.getCustomerDetailAppointment.success, (state, { payload }) => {
        const data: IAppointmentItemResData[] = payload;
        state.customerDetail.appointments = data;
      })
      .addCase(actions.setCustomerCodeDetail, (state, { payload }) => {
        state.customerDetail.id = payload.id;
        state.customerDetail.is_walkin_in = payload.is_walkin_in;
      });
  },
});
const customerServiceReducer = Slice.reducer;
export default customerServiceReducer;
