import { Modal, ModalProps } from 'antd';
import { ICONS } from 'features/checkout/services/constants';
import { EPaymentMethod } from 'features/sales/services/constants';
import { useState } from 'react';
import styled from 'styled-components';
import CashPayment from './CashPayment';
import CardPayment from './CardPayment';

interface ModalRefundProps extends ModalProps {
  onClose: () => void
  total: number
}

const MIX_PAYMENT_METHODS = [
  {
    icon: ICONS[EPaymentMethod.CASH],
    label: 'Cash',
    value: EPaymentMethod.CASH,
  },
  {
    icon: ICONS[EPaymentMethod.CARD],
    label: 'Card',
    value: EPaymentMethod.CARD,
  },
];

const ModalRefund = ({
  onClose,
  total,
  ...props}
   : ModalRefundProps) => {

  const [currMethod, setCurrMethod] = useState<EPaymentMethod>();
  
  const onBack = () => {
    setCurrMethod(undefined);
  };
  
  return (
    <>

      <ModalRefundStyled 
        maskClosable={false}
        open={true}
        onCancel={onClose}
        width={574}
        footer={null}
        forceRender
        centered
        {...props}>
        {!currMethod && (
          <>
            <h2 className='modal-title'>
              <p>Select payment</p>
            </h2>
            <div className='modal-body'>
              <ul className='list-methods'>
                {MIX_PAYMENT_METHODS.map((method, index) => {
                  const { icon, label, value } = method;
                  return (
                    <li
                      key={index}
                      // className={`${currMethod === label && 'is-active'}`}
                      onClick={() => {
                        setCurrMethod(value);
                      }}
                    >
                      <span>{icon}</span>
                      {label}
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        )}
        {currMethod === EPaymentMethod.CASH && (<CashPayment total={total} onCancel={onClose} onBack={onBack}/>)}
        {currMethod === EPaymentMethod.CARD && (<CardPayment total={total} onCancel={onClose} onBack={onBack}/>)}
      </ModalRefundStyled>
     
    </>
  );
};

export default ModalRefund;


const ModalRefundStyled = styled(Modal)`
 
 display: flex;
  flex-direction: column;
  min-height: 565px;

  .list-methods {
    padding-bottom: 200px;
    li {
      display: flex;
      align-items: center;
      height: 72px;
      border-bottom: 1px solid #f0f2f7;
      border-radius: 6px;
      gap: 16px;
      transition: all 0.3s ease;
      font-weight: 600;
      font-size: 20px;
      color: #363565;
      padding: 0 12px;
      cursor: pointer;
      background: var(--icon-gt) center right 20px/8px auto no-repeat;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: #f0f2f7;
      }
      span {
        display: flex;
      }
    }
  }

  .mixmethod-footer {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    margin-top: auto;
    padding-top: 24px;
    margin-bottom: 24px;
  }
`;
