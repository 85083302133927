import { Select } from 'antd';
import bookingActions from 'features/bookings/services/actions';
import apisBooking from 'features/bookings/services/apis';
import bookingSelectors from 'features/bookings/services/selectors';
import { IApiBookingParams } from 'features/bookings/services/types/booking';
import { memo, useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';

const LocationPicker = () => {
  const dispatch = useAppDispatch();
  const lstMerchantLocations = bookingSelectors.getLstMerchantLocations();
  const value = (bookingSelectors.getCalendarParamValue('merchant_location_id') ?? 'all') as IApiBookingParams['merchant_location_id'];

  const options = useMemo(() => {
    return lstMerchantLocations.map(item => {
      return ({
        value: item.id,
        label: item.name,
      });
    });
  }, [lstMerchantLocations]);

  const onChange = async (val: string | number | undefined) => {
    dispatch(bookingActions.setCalendarBookingParams({
      merchant_location_id: val,
      employee_id: undefined,
    }));
    await apisBooking.storeParamOptionCalendar({action_behavior: 'merchant_location_id', action_behavior_value: Number(val)});
    await apisBooking.storeParamOptionCalendar({action_behavior: 'employee_filter', action_behavior_value: []});
    dispatch(bookingActions.getParamOptionCalendar.fetch());

    return;
  };

  if (options.length < 1) return null;

  return (
    <Select
      value={value}
      options={options}
      onChange={onChange}
      className='is-normal'
    />
  );
};

export default memo(LocationPicker);
