import React from 'react';
import styled from 'styled-components';
export type ServiceBoxColorType = 'green' | 'red' | 'purple';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  label?: string;
  colorStyle?: ServiceBoxColorType,
  selected?: boolean;
}
const ServiceBox: React.FC<Props> = (props) => {

  return (
    <ServiceItemStyled {...props} type='button'>
      <span>{props.label}</span>
    </ServiceItemStyled>
  );
};

export default ServiceBox;

function hexToRGB(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}

const ServiceItemStyled = styled.button`
  display:inline-flex;
  padding: 8px 20px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  .overlay {
    position: absolute;
    z-index:1;
    inset: 0;
    background: ${(props: Props) => props.color};
    opacity: 0.2;
  }
  background: ${(props: Props) => hexToRGB(props?.color ?? '', 0.1)};
  border: 1.5px solid ${(props: Props) => hexToRGB(props?.color ?? '', props.selected ? 1 : 0.2)};
  &:hover {
    background: ${(props: Props) => hexToRGB(props?.color ?? '', 0.3)};
  }
  border-radius: 6px;
  span {
    position: relative;
    z-index: 2;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    text-transform: uppercase;
    color: ${(props: Props) => props.color};
  }
`;