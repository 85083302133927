import { Checkbox, DatePicker, Form, Select, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import timeScheduleSelectors from '../services/selectors';
import { TIME_OFF_REPEAT_OPTS } from '../services/constants';
import TimePickerAutoChange from 'components/common/TimePickerAutoChange';
import { TimeOffModalType } from '../types/timeSchedule';
import { IApiTimeScheduleListReq } from '../types/api';
import { useMemo } from 'react';

interface TimeOffFormProps {
  modalType?: TimeOffModalType;
}
const TimeOffForm = ({ modalType }: TimeOffFormProps) => {
  const { t: formLang } = useTranslation('form');

  const form = Form.useFormInstance();

  const teamMembers = timeScheduleSelectors.getMerchantTeamMember();
  const locationValue = (timeScheduleSelectors.getTimeScheduleParamValue(
    'merchant_location_id'
  ) ?? 'All') as IApiTimeScheduleListReq['merchant_location_id'];

  const TEAM_OPTS = useMemo(() => {
    const lstTeamMemberOpt = teamMembers
      ?.filter((it) => it?.location_id === locationValue)
      .map((item) => {
        return {
          value: item.id,
          label: item.full_name,
        };
      });
    if (lstTeamMemberOpt?.length === 1) {
      form.setFieldValue('teamMember', lstTeamMemberOpt?.[0]?.value);
    }

    return lstTeamMemberOpt;
  }, [teamMembers, locationValue]);

  const handleRepeatTextClick = () => {
    const repeatCheck = form.getFieldValue('repeatCheck');
    form.setFieldValue('repeatCheck', !repeatCheck);
  };

  return (
    <TimeOffFormStyled>
      <div className='wrap-timeoff-form'>
        <div className='form-row'>
          <Form.Item
            label={formLang('timeSchedule.timeOffForm.teamMember')}
            name='teamMember'
            rules={[
              {
                required: true,
                message: 'Please select team member',
              },
            ]}
          >
            <Select
              options={TEAM_OPTS}
              placeholder={formLang(
                'timeSchedule.timeOffForm.placeHolder.teamMember'
              )}
            />
          </Form.Item>
        </div>

        <div className='form-row one-date-leave-switch'>
          <p className='form-label'>
            {formLang('timeSchedule.timeOffForm.oneDayLeave')}
          </p>
          <Form.Item noStyle name={'oneDayLeave'} valuePropName='checked'>
            <Switch />
          </Form.Item>
        </div>

        <div className='form-row'>
          <Form.Item
            label={formLang('timeSchedule.timeOffForm.dayOff')}
            name='dayOff'
            rules={[{ required: true, message: 'Please select day off' }]}
          >
            <DatePicker
              format={'DD.MM.YYYY'}
              picker='date'
              placeholder={'DD.MM.YYYY'}
              className='time-off-datepicker'
              disabled={modalType === TimeOffModalType.UPDATE}
            />
          </Form.Item>
        </div>

        <Form.Item
          dependencies={['oneDayLeave']} // if using dependencies
          noStyle
        >
          {({ getFieldValue }: any) => {
            // getFieldValue defined here
            return !getFieldValue('oneDayLeave') ? (
              <div className='form-row'>
                <Form.Item
                  label={formLang('timeSchedule.timeOffForm.startTime')}
                  name='startTime'
                  rules={[
                    { required: true, message: 'Please select start time' },
                  ]}
                >
                  <TimePickerAutoChange
                    format={'HH:mm'}
                    allowClear={false}
                    className='is-time-picker time-off-timepicker'
                    minuteStep={15}
                    showNow={false}
                    popupClassName='time-picker-custom'
                  />
                </Form.Item>
                <Form.Item
                  label={formLang('timeSchedule.timeOffForm.endTime')}
                  name='endTime'
                  rules={[
                    { required: true, message: 'Please select end time' },
                  ]}
                >
                  <TimePickerAutoChange
                    format={'HH:mm'}
                    allowClear={false}
                    className='is-time-picker time-off-timepicker'
                    minuteStep={15}
                    showNow={false}
                    popupClassName='time-picker-custom'
                  />
                </Form.Item>
              </div>
            ) : null;
          }}
        </Form.Item>

        <div className='form-row repeat-check'>
          <Form.Item noStyle name={'repeatCheck'} valuePropName='checked'>
            <Checkbox />
          </Form.Item>
          <p
            className='form-label is-center'
            onClick={() => handleRepeatTextClick()}
            style={{ cursor: 'pointer' }}
          >
            {formLang('timeSchedule.timeOffForm.repeat')}
          </p>
        </div>

        <Form.Item
          dependencies={['repeatCheck']}
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.repeatCheck !== currentValues.repeatCheck
          }
        >
          {({ getFieldValue }: any) => {
            return getFieldValue('repeatCheck') ? (
              <div className='form-row'>
                <Form.Item
                  label={formLang('timeSchedule.timeOffForm.repeat')}
                  name='timeOffRepeatType'
                  rules={[
                    {
                      required: true,
                      message: 'Please select repeat type',
                    },
                  ]}
                >
                  <Select options={TIME_OFF_REPEAT_OPTS} />
                </Form.Item>
              </div>
            ) : null;
          }}
        </Form.Item>
      </div>
    </TimeOffFormStyled>
  );
};

const TimeOffFormStyled = styled.div`
  .ant-picker {
    min-width: unset;
  }

  .time-off-datepicker,
  .time-off-timepicker,
  .time-off-datepicker:hover,
  .time-off-timepicker:hover {
    background: #f0f2f6 !important;
  }

  .wrap-timeoff-form {
    margin-top: 28px;
  }

  .repeat-check {
    margin-bottom: 16px;
  }

  .one-date-leave-switch {
    margin-bottom: 16px;
  }
`;

export default TimeOffForm;
