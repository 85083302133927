import { createAction, createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { APIListInvoicesParams } from './types/invoice';

const getInvoices = createAsyncAction<APIListInvoicesParams>(PREFIX_ACTIONS + 'GET_INVOICES');

const setInvoiceParams = createAction<APIListInvoicesParams>(PREFIX_ACTIONS + 'SET_INVOICES_PARAMS');

const getInvoiceDetail = createAsyncAction<string>(PREFIX_ACTIONS + 'GET_INVOICE_DETAIL');

const setInvoiceDetailId = createAction<string>(PREFIX_ACTIONS + 'SET_INVOICE_DETAIL_ID');

const invoiceActions = {
  getInvoices,
  setInvoiceParams,
  getInvoiceDetail,
  setInvoiceDetailId,
};

export default invoiceActions;
