const IconIncrease = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.1666 8.00016C13.1666 7.63197 12.8681 7.3335 12.4999 7.3335C12.1317 7.3335 11.8333 7.63197 11.8333 8.00016V11.3335H8.49992C8.13173 11.3335 7.83325 11.632 7.83325 12.0002C7.83325 12.3684 8.13173 12.6668 8.49992 12.6668H11.8333V16.0002C11.8333 16.3684 12.1317 16.6668 12.4999 16.6668C12.8681 16.6668 13.1666 16.3684 13.1666 16.0002V12.6668H16.4999C16.8681 12.6668 17.1666 12.3684 17.1666 12.0002C17.1666 11.632 16.8681 11.3335 16.4999 11.3335H13.1666V8.00016Z'
        fill='currentColor'
      />
      <rect x='1' y='0.5' width='23' height='23' rx='3.5' stroke='currentColor' />
    </svg>
  );
};

export default IconIncrease;
