import { Select } from 'antd';
import { IServiceView } from '../services/types/services';
import { useNavigate } from 'react-router-dom';

const ServiceSelectView = () => {

    const navigate = useNavigate();

    const serviceViewOption: IServiceView[] = [
        {
            value: '/private/services',
            label: 'Service View'
        },
        {
            value: '/private/services/categories',
            label: 'Category View'
        },
    ];

    const value = () => {
        const option = serviceViewOption.find(o => o.value === window.location.pathname);
        return option?.value;
    };

    const onChange = (valueLink: string) => {
        navigate(`${valueLink}`);    
    };

    return (
        <div className='common-select'>
          <Select options={serviceViewOption} onChange={onChange} value={value()} />
        </div>
    );
};

export default ServiceSelectView;