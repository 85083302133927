import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { IOptPeriodItemResData, IVoucherItemResData, IVoucherPagination } from './types/voucher';
import uiSelector from 'services/UI/selectors';
import { PATH_LOADING } from './constants';
import { IApiListVoucherParams } from './types/api';
import { ICategoryServiceItemResData, IServiceItemResData } from './types/service';

type MyState = RootState['voucher'];

const getCurrentState = (state: RootState): MyState => state.voucher;

export const getPureParams = (root: RootState) => {
  const state = getCurrentState(root);
  return state.params ?? {};
};

const selector = <Modal = keyof MyState>(key: Modal, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key as any, defaultValue));

const getLstVouchers = () => selector('vouchers') as IVoucherItemResData[];

const getLstVoucherPagination = () => selector('pagination') as IVoucherPagination;

const getLstVoucherParams = () => selector('params') as IApiListVoucherParams;

const getLoadingLstVouchers = () => uiSelector.getLoading(PATH_LOADING.getListVouchers) as boolean;

const getLoadingVoucherActions = () => uiSelector.getLoading(PATH_LOADING.voucherActions) as boolean;

const getPeriodOpts = () => selector('periodOpts') as IOptPeriodItemResData[];

const getServices = () => selector('services') as IServiceItemResData[];

const getCategories = () => selector('categories') as ICategoryServiceItemResData[];

const voucherSelectors = {
  getLstVouchers,
  getLstVoucherPagination,
  getLoadingLstVouchers,
  getLoadingVoucherActions,
  getLstVoucherParams,
  getPeriodOpts,
  getServices,
  getCategories,
};

export default voucherSelectors;
