import { Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import message from 'components/common/Message';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import { PATH } from 'constants/path.enum';
import { get } from 'lodash';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import { getFormatShortDateAndTime } from 'utils/moment/getSingleDateShortFormat';
import customerActions from '../services/actions';
import apisCustomer from '../services/apis';
import selectors from '../services/selectors';
import { ICustomerItemResData } from '../services/types/cutomer';
import { IStateCustomerItem } from '../services/types/reducer';
import queryString from 'query-string';
import moment from 'moment';
import { CUSTOMER_WALKIN_INDEX } from 'utils/unit';

const CustomersTable = () => {
  const data = selectors.getCustomerListPaginate();
  const loading = selectors.loadingCustomerList();
  const loadingTableCustomer = selectors.loadingTableCustomer();
  const setPageLoading = useSetLoadingPage();
  const dispatch = useAppDispatch();
  const { t: tableLang } = useTranslation('table');
  const confirmRef = useRef<ModalConfirmRef>(null);

  const params = selectors.getParams();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // @ts-ignore
    setSearchParams(params || {});
  }, [params]);

  const page: { page: number, per_page: number, keyword: string } = {
    page: Number(searchParams.get('page') ?? 1),
    per_page: Number(searchParams.get('per_page') ?? 10),
    keyword: searchParams.get('keyword') ?? ''

  };

  const confirm = (param: IStateCustomerItem) => {
    const onOk = async () => {
      setPageLoading(true);
      try {
        const res = await apisCustomer.deleteCustomer(param);
        const msg = get(res, 'data.message', '');
        if (msg) {
          message.success(msg);
          dispatch(customerActions.deleteCustomer.success({}));
          dispatch(customerActions.setCustomerListParams({}));
        } else {
          const errorMsg = get(res, 'data.error.message', '');
          if (errorMsg) {
            message.error(errorMsg);
          } else {
            throw 'fail';
          }
        }
      } catch (error) {
        message.error('An error occurred. Please try again!');
      } finally {
        setPageLoading(false);
      }
    };
    confirmRef.current?.show({
      title: 'Are you sure',
      icon: 'delete',
      msg: 'Are you sure remove this customer?',
      submit: onOk
    });
  };


  const columns: ColumnsType<ICustomerItemResData> = [
    {
      title: tableLang('CustomerID'),
      dataIndex: 'customer_code',
      key: 'customer_code',
      fixed: 'left',
      className: 'nowrap',
      render: (text: string, record: IStateCustomerItem) => record.is_walkin_in !== CUSTOMER_WALKIN_INDEX ? (
        <NavLink
          to={PATH.customersDetail + record?.customer_code?.replace('#', '') + '?' + queryString.stringify(
            {
              is_walkin_in: record?.is_walkin_in,
            },
            { arrayFormat: 'bracket' }
          )}
        >
          {text}</NavLink>
      ) : text,
    },
    {
      title: tableLang('CustomerName'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: tableLang('PhoneNumber'),
      dataIndex: 'phone_number',
      key: 'phone_number',
      className: 'nowrap',
    },
    {
      title: tableLang('Email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: tableLang('LastVisit'),
      dataIndex: 'last_visit',
      key: 'last_visit',
      className: 'nowrap',
      width: '170px',
      render(value) {
        const booking_time = getFormatShortDateAndTime(value);
        return <span>{booking_time}</span>;
      },
    },
    {
      title: tableLang('Type'),
      dataIndex: 'is_walkin_in',
      key: 'is_walkin_in',
      render: (text, record) => !record.is_walkin_in ? 'Customer' : 'Walk-in'
    },
    {
      title: tableLang('Create At'),
      dataIndex: 'created_at',
      key: 'created_at',
      render(value, record) {
        const booking_time = moment(value ?? record.updated_at).format('MMM DD,YYYY');
        return <span>{booking_time}</span>;
      },
    },
    {
      title: tableLang('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (text: string, record: IStateCustomerItem) => record.is_walkin_in !== CUSTOMER_WALKIN_INDEX ? (
        <ul className={'common-action'}>
          <NavLink
            to={PATH.customersDetail + record?.customer_code?.replace('#', '') + '?' + queryString.stringify(
              {
                is_walkin_in: record?.is_walkin_in,
              },
              { arrayFormat: 'bracket' }
            )}

          >
            <li
              className="action-edit"
            ></li>
          </NavLink>
          <li className="action-remove" onClick={() => confirm(record)}></li>
        </ul>
      ) : <></>,
    },
  ];

  return (
    <>
      <Spin spinning={loading || loadingTableCustomer}>
        <Table
          className={'custom-table'}
          dataSource={data.data}
          columns={columns}
          rowKey={(item) => item.id}
          scroll={{
            x: 900,
          }}
          loading={(data?.data?.length === 0 && loading) || loadingTableCustomer}
          pagination={{
            defaultCurrent: Number(page?.page ?? 1),
            total: data?.pagination?.total_record,
            defaultPageSize: 10,
            current: Number(params.page || ''),
            onChange: (pageChange, sizeChange) => {
              const params: any = {
                page: pageChange == 0 ? 1 : pageChange,
                per_page: sizeChange,
                keyword: searchParams.get('keyword')
              };
              dispatch(customerActions.setCustomerListParams(params));
            },
            showSizeChanger: true,
            showTotal(total) {
              return `Total ${total} items`;
            },
          }}
        />
      </Spin>
      <ModalConfirm ref={confirmRef} />
    </>
  );
};

export default CustomersTable;
