import { Form, InputNumber, Modal } from 'antd';
import FormRow from 'components/common/Form/FormRow';
import { get } from 'lodash';
import { useEffect, useRef, useState, useTransition } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export enum EFields {
  'PIN' = 'pin',
  'NOTE' = 'note',
  'REASON' = 'reason',
}

// type IFormValues = {
//   _one: number;
//   _two: number;
//   _three: number;
//   _four: number;
//   note: string;
//   reason: string;
// }


type Errors = {
  [EFields.NOTE]?: string | null,
  [EFields.REASON]?: string | null,
  [EFields.PIN]?: string | null,
};


export type IModalCancelSubmitFunc = (values: {
  reason: string;
  note: string;
  PIN: string;
  form: any;
}, setErrors: (errors: Errors) => void, resetPin: () => void) => void
type Props = {
  visible?: boolean;
  onCancel?: () => void;
  onSubmit?: IModalCancelSubmitFunc;
  setPinNumber?: any;
  resetOnSubmit?: boolean;
};

const ModalConfirm = ({ onCancel = () => undefined, visible = false, onSubmit = () => undefined, resetOnSubmit }: Props) => {
  const [, startTransition] = useTransition();
  const [form] = Form.useForm();

  const _one = useRef<HTMLInputElement>(null);
  const _two = useRef<HTMLInputElement>(null);
  const _three = useRef<HTMLInputElement>(null);
  const _four = useRef<HTMLInputElement>(null);

  const [errors, setErrors] = useState<Errors>({});
  const [errorPin, setErrorPin] = useState('');

  const resetPin = () => {
    form.setFieldsValue({
      _one: null,
      _two: null,
      _three: null,
      _four: null,
    });
  };

  useEffect(() => {
    if (!visible) form.resetFields();
  }, [visible]);

  const _onCancel = () => {
    onCancel();
  };

  const reset = () => {
    startTransition(() => {
      setErrorPin('');
      setErrors({});
    });
    form.resetFields();
  };

  const handleSubmit = (values: any) => {
    setErrorPin('');
    setErrors({});
    const pinValue = String(values['_one'] ?? '') + String(values['_two'] ?? '') + String(values['_three'] ?? '') + String(values['_four'] ?? '');
    if (!pinValue || pinValue.length !== 4) {
      setErrorPin('Please enter digits PIN!');
      return;
    }

    onSubmit({
      form: form,
      note: values?.note ?? '',
      PIN: pinValue,
      reason: values?.reason ?? '',
    }, setErrors, resetPin);
    if (resetOnSubmit) reset();
  };

  const StepInputConfirm = () => {

    // const pinNumbers = [
    //   {
    //     name: '_one',
    //     ref: _one
    //   },
    //   {
    //     name: '_two',
    //     ref: _two
    //   },
    //   {
    //     name: '_three',
    //     ref: _three
    //   },
    //   {
    //     name: '_four',
    //     ref: _four
    //   },
    // ];

    return (
      <div className='PIN_form'>
        <p>Please enter a 4 digits PIN to confirm this action</p>
        <Form.Item extra={errorPin || get(errors, [EFields.PIN])}>
          <div className="PIN_group" >
            <FormRow shouldUpdate noStyle name="_one">
                <InputNumber
                ref={_one}
                  maxLength={1}
                  minLength={1}
                autoFocus
                  className='PIN_input'
                onChange={e => {
                  if (e === null || e === undefined) return;
                  form?.setFieldValue('_two', undefined);
                  setTimeout(() => _two.current?.focus(), 1);
                }}
                  onFocus={() => {
                  setTimeout(() => _one.current?.setSelectionRange(0, 2), 1);
                  }}
              />
            </FormRow>
            <FormRow shouldUpdate noStyle name="_two">
              <InputNumber
                className='PIN_input'
                ref={_two}
                maxLength={1}
                minLength={1}
                onChange={e => {
                    if (e === null || e === undefined) return;
                  form?.setFieldValue('_three', undefined);
                  setTimeout(() => _three.current?.focus(), 1);
                }}
                onFocus={() => {
                  setTimeout(() => _two.current?.setSelectionRange(0, 2), 1);
                }}
                onKeyDown={e => {
                  if (e.key === 'Backspace') {
                    setTimeout(() => { _one.current?.focus(); }, 1);
                    }
                  }}
              />
            </FormRow>
            <FormRow shouldUpdate noStyle name="_three">
              <InputNumber
                className='PIN_input'
                ref={_three}
                maxLength={1}
                minLength={1}
                onChange={e => {
                  if (e === null || e === undefined) return;
                  form?.setFieldValue('_four', undefined);
                  setTimeout(() => _four.current?.focus(), 1);
                }}
                onFocus={() => {
                  setTimeout(() => _three.current?.setSelectionRange(0, 2), 1);
                }}
                  onKeyDown={e => {
                    if (e.key === 'Backspace') {                    
                    setTimeout(() => { _two.current?.focus(); }, 1);
                      }
                }}
              />
            </FormRow>
            <FormRow shouldUpdate noStyle name="_four">
              <InputNumber
                className='PIN_input'
                ref={_four}
                accept='/^[0-9]*$/'
                maxLength={1}
                minLength={1}
                onFocus={() => {
                  setTimeout(() => _four.current?.setSelectionRange(0, 2), 1);
                }}
                onKeyDown={e => {
                  if (e.key === 'Backspace') {
                    setTimeout(() => { _three.current?.focus(); }, 1);
                    }
                  }}
                />
              </FormRow>
          </div>
        </Form.Item>
        <p>Don’t remember the number? <Link to='/private/settings' className='label-highlight'><b>Reset PIN</b></Link></p>
        <div className='form-row form-submit-wrapper form-submit-floating'>
          <button className='common-btn is-white' type='button' onClick={_onCancel}>
            Cancel
          </button>
          <button className='common-btn' type='submit'>
            Confirm
          </button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={_onCancel}
      forceRender
      footer={null}
      centered
      className='modal-cancel-booking'
    >
      <ModalCancelStyled>
        <h2 className='modal-title'>
          <p>Enter PIN</p>
        </h2>
        <Form
          form={form}
          className='modal-form'
          layout='vertical'
          onFinish={handleSubmit}
          onValuesChange={() => setErrors({})}
        >
          <StepInputConfirm />
        </Form>
      </ModalCancelStyled>
    </Modal>
  );
};

export default ModalConfirm;

const ModalCancelStyled = styled.div`
  .ant-form-item-extra {
    color:#ff4d4f;
  }
  .hide {
    display:none;
  }
  .form-confirm {
    text-align: center;
  }
  .PIN_form {
    text-align: center;
    .label-highlight {
      color:#4A9D77;
    }
  }
  .PIN_group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width:100%;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .PIN_input {
    flex:1;
    background: none;
    border: unset;
    border-radius: 0;
    border-bottom: 14px solid #C3C2DE;
    input {
      padding: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 140%;
      text-align: center;
      color: #363565;
      background: none;
    }
    &.ant-input-number {
      width: 40px;
    }
  }
`;