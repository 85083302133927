import { Tabs } from 'antd';
import AllTab from '../components/AllTab';
import UnreadTab from '../components/UnreadTab';
import { useTranslation } from 'react-i18next';

const NotificationPage = () => {
  const onChange = () => undefined;
  const { t: headerLang } = useTranslation('header');
  const items = [
    {
      key: '1',
      label: headerLang('All'),
      children: <AllTab />,
    },
    {
      key: '2',
      label: headerLang('Unread'),
      children: <UnreadTab />,
    },
  ];
  return <Tabs defaultActiveKey='1' items={items} onChange={onChange} />;
};

export default NotificationPage;
