import fetch from 'services/request';
const makeReview = (
  { customer_code, merchant_code, invoice_code }: {
    merchant_code: string,
    customer_code: string,
    invoice_code: string | number,
  },
  formData: FormData
) => {
  return fetch({
    method: 'post',
    url: `api/v1/merchants/${merchant_code}/review/${customer_code}/create/${invoice_code}`,
    body: formData,
    configs: {
      headers: {
        'accept': '*/*',
        'Content-Type': 'multipart/form-data',
      }
    },
  });
};

const getListReview = (params: any) => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/review/list',
    params: params as any
  });
};

const getDetailReview = (id: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/review/detail/' + id,
  });
};
const replyCustomer = (review_id: string | number, reply_content: string) => {
  return fetch({
    method: 'put',
    url: 'api/v1/merchants/:merchant_code/review/submit/' + review_id,
    body: {
      reply_content
    }
  });
};


const reviewApis = {
  makeReview,
  getListReview,
  getDetailReview,
  replyCustomer,
};

export default reviewApis;
