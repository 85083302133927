import { AxiosResponse } from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { setLoading } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import actions from './actions';
import { default as apis, default as apisCustomer } from './apis';
import { PATH_LOADING } from './constants';
import { IAppointmentItemResData } from './types/appointment';
import { ICustomerDetailResData, ICustomerItemResData, ICustomerParam, ICustomerResDataPaginate } from './types/cutomer';
import { getPureParams } from './selectors';

const getListCustomers = function* ({ payload }: any) {
  try {
    yield setLoading(PATH_LOADING.getListCustomers, true);
    const res: AxiosResponse<{ data: ICustomerItemResData[] }> = yield call(apis.getListCustomers, payload);
    if (res?.data?.data) {
      const customerList = res?.data?.data.sort((o1, o2) => Number(o2.is_walkin_in) - Number(o1.is_walkin_in));
      yield put(actions.getListCustomers.success(customerList));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getListCustomers.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getListCustomers, false);
  }
};

const getListCustomersPaginate = function* ({ payload }: any) {
  try {
    yield setLoading(PATH_LOADING.getListCustomersPaginate, true);
    const res: AxiosResponse<{ data: ICustomerResDataPaginate }> = yield call(apis.getListCustomersPaginate, payload);
    if (res?.data?.data) {
      
      const customerList = {
          // @ts-ignore
          data: res?.data?.data?.sort((o1, o2) => Number(o2.is_walkin_in) - Number(o1.is_walkin_in)),
          // @ts-ignore
          pagination: res?.data.pagination
      };
      yield put(actions.getListCustomersPaginate.success(customerList));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getListCustomersPaginate.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getListCustomersPaginate, false);
  }
};

const getCustomerDetail: ISagaFunc<string> = function* ({ payload }) {
  const param = payload;
  try {
    yield setLoading(PATH_LOADING.getCustomerDetail, true);
    const res: AxiosResponse<{ data: ICustomerDetailResData }> = yield call(apisCustomer.getCustomerDetail, param);
    if (res?.data?.data) {
      const customerDetail = res.data.data;
      yield put(actions.getCustomerDetail.success(customerDetail));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getCustomerDetail.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getCustomerDetail, false);
  }
};

const getCustomerDetailAppointment: ISagaFunc<string> = function* ({ payload }) {
  const param = payload;
  
  try {
    yield setLoading(PATH_LOADING.getCustomerDetailAppointment, true);
    const res: AxiosResponse<{ data: IAppointmentItemResData[] }> = yield call(apisCustomer.getCustomerDetailAppointment, param);
    if (res?.data?.data) {
      yield put(actions.getCustomerDetailAppointment.success(res.data.data));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getCustomerDetailAppointment.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getCustomerDetailAppointment, true);
  }
};

const setCustomerCodeDetail: ISagaFunc<string> = function* ({ payload }) {
  const id = payload;
  if (!id) return;
  yield put(actions.getCustomerDetail.fetch(id));
  yield put(actions.getCustomerDetailAppointment.fetch(id));
};

const updateCustomerSuccess: ISagaFunc<string> = function* ({ payload }) {
  const id = payload;
  yield put(actions.getCustomerDetail.fetch(id));
};

const deleteCustomerSuccess = function* () {
  try {
    yield setLoading(PATH_LOADING.deleteCustomer, true);
    yield put(actions.getListCustomersPaginate.fetch());
  } catch (error) {
    yield put(actions.getListCustomers.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.deleteCustomer, false);
  }
};

const createCustomerSuccess = function* () {
  try {
    yield setLoading(PATH_LOADING.createCustomer, true);
    yield put(actions.getListCustomersPaginate.fetch({
      page: 1,
      per_page: 10,
    }));
  } catch (error) {
    yield put(actions.getListCustomers.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.createCustomer, false);
  }
};

const setCustomerListParams: ISagaFunc<ICustomerParam> = function* ({ payload }) {
  const _params: ICustomerParam = yield select(getPureParams);
  const params: ICustomerParam = {
    ..._params,
    ...payload ?? {},
  };

  if (params.keyword === undefined || params.keyword === null) {
    delete params.keyword;
  }
  if (params.page === undefined || params.page === null) {
    params.page = 1;
  }

  if (params.per_page === undefined || params.per_page === null) {
    params.per_page = 10;
  }

  yield put(actions.getListCustomersPaginate.fetch(params));
};

export default function* customerServiceSaga() {

  yield takeLatest(actions.getListCustomers.fetch, getListCustomers);
  yield takeLatest(actions.getListCustomersPaginate.fetch, getListCustomersPaginate);
  yield takeLatest(actions.getCustomerDetail.fetch, getCustomerDetail);
  yield takeLatest(actions.getCustomerDetailAppointment.fetch, getCustomerDetailAppointment);
  yield takeLatest(actions.setCustomerCodeDetail, setCustomerCodeDetail);
  yield takeLatest(actions.updateCustomerInfo.success, updateCustomerSuccess);
  yield takeLatest(actions.deleteCustomer.success, deleteCustomerSuccess);
  yield takeLatest(actions.createCustomer.success, createCustomerSuccess);
  yield takeLatest(actions.setCustomerListParams, setCustomerListParams);
}
