import { IRewardItemResData } from 'features/loyaltyProgram/services/types/reward';
import { IPromotionDetailInput } from 'features/promotions/services/types/promotion';
import { createAction, createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { EPaymentMethod, IApiCheckoutByCardBody, IApiCheckoutByCashBody, IApiCheckoutByVoucherBody, IApiCheckoutWithMixinBody, IMixPaymentItem, IResultCheckoutRes } from './types/checkout';
import { IApiUpdateCusInfoBody } from './types/customer';
import { IServiceSelectedItem } from './types/service';
import { IVoucherDataItem, IVoucherForSalesSelectedItem } from './types/voucher';
import { IPromotionErrorBoundary } from './types/promotion';
import { IModalFeeFormPayload } from './types/fee';

const getBookingDetail = createAsyncAction<string>(PREFIX_ACTIONS + 'GET_BOOKING_DETAIL');

const getServiceConfigs = createAsyncAction(PREFIX_ACTIONS + 'GET_SERVICE_CONFIGS');

const getVouchersConfig = createAsyncAction(PREFIX_ACTIONS + 'getVouchersConfig');

const updateCusInfo = createAsyncAction<{ id: string, data: IApiUpdateCusInfoBody }>(PREFIX_ACTIONS + 'UPDATE_CUSTOMER_INFO');

const checkoutByCash = createAsyncAction<IApiCheckoutByCashBody>(PREFIX_ACTIONS + 'CHECKOUT_BY_CASH');

const checkoutByCard = createAsyncAction<IApiCheckoutByCardBody>(PREFIX_ACTIONS + 'CHECKOUT_BY_CARD');

const checkoutByVoucher = createAsyncAction<IApiCheckoutByVoucherBody>(PREFIX_ACTIONS + 'CHECKOUT_BY_VOUCHER');

const checkoutWithMixin = createAsyncAction<IApiCheckoutWithMixinBody>(PREFIX_ACTIONS + 'CHECKOUT_WITH_MIXIN');

const setCheckoutBookingId = createAction<string>(PREFIX_ACTIONS + 'SET_CHECKOUT_BOOKING_ID');

const setQuantitySelectedService = createAction<{ id: IServiceSelectedItem['id'], service_variant_id: IServiceSelectedItem['service_variant_id'], quantity: IServiceSelectedItem['quantity'] }>(PREFIX_ACTIONS + 'SET_SELECTED_SERVICE');

const removeSelectedService = createAction<{ id: IServiceSelectedItem['id'], service_variant_id: IServiceSelectedItem['service_variant_id'] }>(PREFIX_ACTIONS + 'REMOVE_SELECTED_SERVICE');

const addSelectedService = createAction<IServiceSelectedItem>(PREFIX_ACTIONS + 'ADD_SELECTED_SERVICE');

const setPaymentMethod = createAction<EPaymentMethod | null>(PREFIX_ACTIONS + 'SET_PAYMENT_METHOD');

const setPurchaseCash = createAction<number | null>(PREFIX_ACTIONS + 'SET_PURCHASE_CASH');

const setPurchaseMethodErrors = createAction<{ method: EPaymentMethod, errors: Record<string, string | null> }>(PREFIX_ACTIONS + 'SET_PURCHASE_METHOD_ERRORS');

const setServiceSelected = createAction<IServiceSelectedItem[]>(PREFIX_ACTIONS + 'SET_CHECKOUT_SERVICE_LIST');

const setLoyaltyPoint = createAction<number>(PREFIX_ACTIONS + 'SET_CHECKOUT_LOYALTY_POINT');

const setResultTotal = createAction<IResultCheckoutRes>(PREFIX_ACTIONS + 'SET_CHECKOUT_RESULT_TOTAL');

const setSelectedReward = createAction<IRewardItemResData | null>(PREFIX_ACTIONS + 'SET_SELECT_REWARD');

const setSelectedVouchersForSales = createAction<IVoucherForSalesSelectedItem[]>(PREFIX_ACTIONS + 'SET_SELECT_VOUCHER_FOR_SALES');

const setSelectedVoucher = createAction<IVoucherDataItem | null>(PREFIX_ACTIONS + 'SET_SELECTED_VOUCHER');

const setSelectedVoucherUsed = createAction<IVoucherDataItem | null>(PREFIX_ACTIONS + 'SET_SELECTED_VOUCHER_USED');

const setMixPayments = createAction<IMixPaymentItem[]>(PREFIX_ACTIONS + 'SET_MIX_PAYMENTS');

const getRewardOfCustomer = createAsyncAction<any>(PREFIX_ACTIONS + 'GET_REWARD_OF_CUSTOMER');

const getVoucherOfCustomer = createAsyncAction<string>(PREFIX_ACTIONS + 'GET_VOUCHER_OF_CUSTOMER');

const setPurchaseCard = createAction<number | null>(PREFIX_ACTIONS + 'setPurchaseCard');

const setPromotionInfo = createAction<IPromotionDetailInput | null>(PREFIX_ACTIONS + 'setPromotionInfo');

const reviewPointCustomerWillReceive = createAsyncAction<number>(PREFIX_ACTIONS + 'reviewPointCustomerWillReceive');

const resetFormPayment = createAction(PREFIX_ACTIONS + 'resetFormPayment');

const setPromotionErrorBoundary = createAction<IPromotionErrorBoundary | null>(PREFIX_ACTIONS + 'setPromotionErrorBoundary');

const setSoftPromotionCode = createAction<string | null>(PREFIX_ACTIONS + 'setSoftPromotionCode');

const getTotalPointCustomer = createAsyncAction<{ is_walkin_in: boolean, customer_code: string }>(PREFIX_ACTIONS + 'getTotalPointCustomer');

const updateInitialSelectedServices = createAction(PREFIX_ACTIONS + 'updateInitialSelectedServices');

const setExtraFee = createAction<IModalFeeFormPayload | null>(PREFIX_ACTIONS + 'setExtraFee');

const setDiscountFee = createAction<IModalFeeFormPayload | null>(PREFIX_ACTIONS + 'setDiscountFee');

const checkoutActions = {
  getBookingDetail,
  getServiceConfigs,
  updateCusInfo,
  checkoutByCash,
  checkoutByCard,
  checkoutByVoucher,
  checkoutWithMixin,
  setCheckoutBookingId,
  setQuantitySelectedService,
  removeSelectedService,
  addSelectedService,
  setPaymentMethod,
  setPurchaseCash,
  setPurchaseMethodErrors,
  setServiceSelected,
  setLoyaltyPoint,
  setSelectedReward,
  setSelectedVouchersForSales,
  setSelectedVoucher,
  setMixPayments,
  setSelectedVoucherUsed,
  getRewardOfCustomer,
  getVoucherOfCustomer,
  setResultTotal,
  setPromotionInfo,
  reviewPointCustomerWillReceive,
  resetFormPayment,
  setPromotionErrorBoundary,
  setSoftPromotionCode,
  getTotalPointCustomer,
  getVouchersConfig,
  updateInitialSelectedServices,
  setExtraFee,
  setDiscountFee,
  setPurchaseCard
};

export default checkoutActions;
