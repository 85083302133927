export const PREFIX_ACTIONS = 'TIME_SCHEDULE_FEATURE_';

export const NAME_REDUCER = 'timeSchedule';

export const PATH_LOADING = {
  getListTimeSchedules: `loading.${NAME_REDUCER}.getListTimeSchedules`,
  getDetailTimeSchedules: `loading.${NAME_REDUCER}.getDetailTimeSchedules`,
};

export const TIME_OFF_REPEAT_OPTS = [
  {
    value: 'daily',
    label: 'Daily',
  },
  {
    value: 'weekly',
    label: 'Weekly',
  },
  {
    value: 'monthly',
    label: 'Monthly',
  },
  {
    value: 'annually',
    label: 'Annually',
  },
];