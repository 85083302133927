import { Switch } from 'antd';

type Props = {
  onChange?: any;
  defaultChecked?: boolean;
  label: string;
};

const FormSwitch = (props: Props) => {
  const { defaultChecked = true, onChange, label } = props;

  return (
    <div className='form-row'>
      <p className='form-label'>{label}</p>
      <Switch
        defaultChecked={defaultChecked}
        onChange={onChange}
        checkedChildren=''
        unCheckedChildren=''
      />
    </div>
  );
};

export default FormSwitch;
