import fetch from 'services/request';
import { IApiCategoryParams } from './types/category';
import { IApiCategoryBodyReq } from 'features/sales/services/types/category';

const BASE_URL = 'api/v1/merchants/:merchant_code/service';

const getListPaginateServices = (params?: any) => {
  return fetch({
    method: 'get',
    url: BASE_URL,
    params: { ...params },
  });
};

const storeServices = (body?: any) => {
  return fetch({
    method: 'post',
    url: `${BASE_URL}/store-service`,
    body: { ...body },
  });
};

const updateServices = (id?: number, body?: any) => {
  return fetch({
    method: 'put',
    url: `${BASE_URL}/${id}`,
    body: { ...body },
  });
};

const deleteService = (id?: number) => {
  return fetch({
    method: 'delete',
    url: `${BASE_URL}/${id}`,
  });
};

const getShowCreateServices = (params?: any) => {
  return fetch({
    method: 'get',
    url: `${BASE_URL}/show-create`,
    params: { ...params },
  });
};

const switchFavourite = (id: number) => {
  return fetch({
    method: 'put',
    url: `${BASE_URL}/${id}/switch-favourite`,
  });
};

const importServiceInfo = (body: any) => {
  return fetch({
    method: 'post',
    url: 'api/v1/merchants/:merchant_code/service/import',
    configs: {
      headers: {
        'accept': '*/*',
        'Content-Type': 'multipart/form-data',
      }
    },
    body: body as any,
  });
};

const exportData = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/service/export',
  });
};


const category = {
  getData: (params: IApiCategoryParams) => {
    return fetch({
      method: 'get',
      url: 'api/v1/merchants/:merchant_code/service-category/list',
      params,
    });
  },
  update: (id: number ,body: IApiCategoryBodyReq) => {
    return fetch({
      method: 'put',
      url: `api/v1/merchants/:merchant_code/service-category/${id}`,
      body: body as any,
    });
  },
  create: (body: IApiCategoryBodyReq) => {
    return fetch({
      method: 'post',
      url: 'api/v1/merchants/:merchant_code/service-category/create',
      body: body as any,
    });
  },
  delete: (id: number) => {
    return fetch({
      method: 'delete',
      url: `api/v1/merchants/:merchant_code/service-category/${id}`,
    });
  },
};

const apisService = {
  getListPaginateServices,
  storeServices,
  getShowCreateServices,
  deleteService,
  switchFavourite,
  updateServices,
  importServiceInfo,
  exportData,
  category,
};

export default apisService;
