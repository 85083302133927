import React from 'react';

const IconTimeAndDate = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M8 2.5V5.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 2.5V5.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3 8C3 5.79086 4.79086 4 7 4H17C19.2091 4 21 5.79086 21 8V18.5C21 20.7091 19.2091 22.5 17 22.5H7C4.79086 22.5 3 20.7091 3 18.5V8Z" stroke="white" strokeWidth="1.5" />
            <path d="M3 9.5H21" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M12 12.5V18.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 15.5L9 15.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default IconTimeAndDate;