import React from 'react';

const IconInfo = () => {
    return (
        <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width="30" height="30" rx="6" />
            <path d="M15.2948 23.5651C19.8971 23.5651 23.6281 19.8341 23.6281 15.2318C23.6281 10.6294 19.8971 6.89844 15.2948 6.89844C10.6924 6.89844 6.96143 10.6294 6.96143 15.2318C6.96143 19.8341 10.6924 23.5651 15.2948 23.5651Z" stroke="#363565" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.2949 18.5638V15.2305" stroke="#363565" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.2949 11.8984H15.3033" stroke="#363565" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default IconInfo;