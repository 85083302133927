import InputSearchText from 'components/common/Input/InputSearchDebounce';
import ImportModal from 'features/customers/components/ImportModal';
import { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Select } from 'antd';
import CategoryAdd from 'features/services/widgets/category/Add';
import serviceSelectors from 'features/services/services/selectors';
import { useSearchParams } from 'react-router-dom';
import ServiceSelectView from '../../ServiceSelectView';
import { useDispatch } from 'react-redux';
import locationActions from 'features/locations/services/actions';
import locationSelectors from 'features/locations/services/selectors';
import serviceActions from 'features/services/services/actions';

const CategoryFilter = () => {
  const [, setSearchParams] = useSearchParams();
  // const { t: commonLang } = useTranslation('common');
  const [openModalImport, setOpenModalImport] = useState<boolean>(false);
  const params = serviceSelectors.category.getParams();
  const dispatch = useDispatch();

  const allLocationOption = [{
    value: null,
    label: 'All locations'
  }];
  const locationOptions = allLocationOption.concat(locationSelectors.getPaginateListLocations()?.data
    ?.filter((o: any) => o.status === 1)
    ?.map((o: any) => ({
      value: o.id,
      label: o.name
    })) ?? []);

  const handleFilterLocation = (value: number) => {
    dispatch(serviceActions.category.setParams({
      location_id: value === null ? undefined : value,
      page: 1,
    }));
  };

  const onSearchText = (value: string) => {
    dispatch(serviceActions.category.setParams({
      keyword: value || undefined,
      page: 1,
    }));
  };

  useEffect(() => {
    dispatch(locationActions.getListPaginateLocations.fetch({}));
  }, []);

  useEffect(() => {
    // @ts-ignore
    setSearchParams(params);
  }, [params]);

  return (
    <CategoryFilterStyled>
      <div className={'private-filter'}>
        <ServiceSelectView />
        <InputSearchText value={params?.keyword ?? ''} onSearchText={onSearchText} />
        <div className="common-select">
          <Select onChange={handleFilterLocation} value={params.location_id ? params.location_id : null} options={locationOptions ?? []} />
        </div>
        {/* <button
          className='common-btn-import'
          onClick={() => setOpenModalImport(true)}
        >
          <span>{commonLang('Import')}</span>
        </button>
        <button className='common-btn-export'>
          <span>{commonLang('Export')}</span>
        </button> */}
        <CategoryAdd />
      </div>
      <ImportModal
        isOpenModal={openModalImport}
        handleCancel={() => setOpenModalImport(false)}
      />
    </CategoryFilterStyled>
  );
};

export default CategoryFilter;

const CategoryFilterStyled = styled.div`
  display:flex;
  align-items: center;
  justify-content: right;
`;
