import Logo from 'assets/icons/sidebar_logo.svg';
import IconAnnouncement from 'assets/svg/IconAnnouncement';
import IconBill from 'assets/svg/IconBill';
import IconCalendar from 'assets/svg/IconCalendar';
import IconChart from 'assets/svg/IconChart';
import IconCustomers from 'assets/svg/IconCustomers';
import IconHeart from 'assets/svg/IconHeart';
import IconMap from 'assets/svg/IconMap';
import IconMore from 'assets/svg/IconMore';
import IconSettings from 'assets/svg/IconSettings';
import IconSpa from 'assets/svg/IconSpa';
import IconStar from 'assets/svg/IconStar';
import IconUsers from 'assets/svg/IconUsers';
import IconVoucher from 'assets/svg/IconVoucher';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { PATH } from '../../../constants/path.enum';
import NavItem from './components/NavItem';
import styles from './index.module.scss';
import IconSales from 'assets/svg/IconSales';

type Props = {
  setIsShowSidebar: any;
  isShowSidebar: boolean;
};

const Sidebar = (props: Props) => {
  const { isShowSidebar, setIsShowSidebar } = props;
  const { t: commonLang } = useTranslation('common');
  const location = useLocation();
  const [openSubSidebar, setOpenSubSidebar] = useState(false);
  useEffect(() => {
    setIsShowSidebar(false);
  }, [location]);

  const getNavbar = () => {
    return [
      {
        text: commonLang('Sidebar.Bookings'),
        path: PATH.bookings,
        icon: <IconCalendar />,
      },
      {
        text: commonLang('Sidebar.Sales'),
        path: PATH.sales,
        icon: <IconSales />,
      },
      {
        text: commonLang('Sidebar.Customers'),
        path: PATH.customers,
        icon: <IconCustomers />,
      },
      {
        text: commonLang('Sidebar.Services'),
        path: PATH.services,
        icon: <IconSpa />,
      },
      {
        text: commonLang('Sidebar.Analytics'),
        path: PATH.analytics,
        icon: <IconChart />,
      },
      {
        text: commonLang('Sidebar.Invoices'),
        path: PATH.invoices,
        icon: <IconBill />,
      },
      {
        text: commonLang('Sidebar.More'),
        path: PATH.more,
        icon: <IconMore />,
        childrens: [
          {
            text: commonLang('Sidebar.Reviews'),
            path: PATH.reviews,
            icon: <IconStar />,
          },
          {
            text: commonLang('Sidebar.Promotions'),
            path: PATH.promotions,
            icon: <IconAnnouncement />,
          },
          {
            text: commonLang('Sidebar.Vouchers'),
            path: PATH.vouchers,
            icon: <IconVoucher />,
          },
          {
            text: commonLang('Sidebar.Locations'),
            path: PATH.locations,
            icon: <IconMap />,
          },
          {
            text: commonLang('Sidebar.LoyaltyProgram'),
            path: PATH.loyaltyProgram,
            icon: <IconHeart />,
          },
          {
            text: commonLang('Sidebar.Users'),
            path: PATH.users,
            icon: <IconUsers />,
          },
          {
            text: commonLang('Sidebar.WorkingHour'),
            path: PATH.workingHour,
            icon: <IconUsers />,
          },
          {
            text: commonLang('Sidebar.TimeSchedule'),
            path: PATH.timeSchedule,
            icon: <IconUsers />,
          },
        ],
      },
      {
        text: commonLang('Settings'),
        path: PATH.settings,
        icon: <IconSettings />,
      },
    ];
  };

  const activeStyle = {
    background: 'rgba(195, 194, 224, 0.2)',
    color: 'var(--color-primary)',
  };

  return (
    <>
      <button
        onClick={() => setIsShowSidebar(!isShowSidebar)}
        className={styles.aside_toggle}
      ></button>
      <aside
        className={`${styles.aside} ${isShowSidebar ? styles.aside_show : undefined}`}
      >
        <div className={styles.aside_inner}>
          <button
            className={styles.aside_close}
            onClick={() => setIsShowSidebar(false)}
          ></button>
          <div className={styles.aside_logo}>
            <img src={Logo} alt='N'></img>
          </div>
          <ul className={styles.list_navbar}>
            {getNavbar().map((nav) => {
              const { text, path, icon, childrens } = nav;
              return (
                <li key={path} className={`${styles.navbar_item}`} onClick={e => e.stopPropagation()}>
                  {!childrens ? (
                    <NavLink
                      reloadDocument={path === PATH.sales}
                      to={path}
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      className={({ isActive }) =>
                        isActive ? styles.navbar_link_active : undefined
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenSubSidebar(false);
                      }}
                    >
                      {icon}
                      <span>{text}</span>
                    </NavLink>
                  ) : (
                    <NavItem data={nav} isNavShow={isShowSidebar} openSub={openSubSidebar} setOpenSub={setOpenSubSidebar} />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </aside>
      <div
        className={styles.aside_overlay}
        onClick={() => setIsShowSidebar(false)}
      ></div>
    </>
  );
};

export default Sidebar;
