import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Sydney');
dayjs.updateLocale('en', {
  weekStart: 1
});


export default dayjs;