const IconStarCircle = () => {
  return (
    <svg
      width='40'
      height='41'
      viewBox='0 0 40 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect y='0.5' width='40' height='40' rx='20' fill='#FEF7D7' />
      <path
        d='M19.1157 11.3199C19.4824 10.5761 20.5431 10.5761 20.9097 11.3199L23.1881 15.9428C23.3338 16.2383 23.6157 16.4432 23.9418 16.4904L29.0001 17.2226C29.8194 17.3412 30.1481 18.3471 29.5568 18.9265L25.8802 22.5297C25.646 22.7592 25.5392 23.0889 25.5944 23.4121L26.4618 28.4969C26.6013 29.3149 25.7412 29.9372 25.0078 29.5487L20.4809 27.1502C20.1881 26.9951 19.8374 26.9951 19.5446 27.1502L15.0176 29.5487C14.2843 29.9372 13.4241 29.3149 13.5637 28.4969L14.4308 23.4135C14.4861 23.0896 14.3786 22.7591 14.1433 22.5296L10.4515 18.9281C9.85803 18.3492 10.1864 17.341 11.007 17.2226L16.0833 16.4902C16.4096 16.4431 16.6917 16.2382 16.8375 15.9425L19.1157 11.3199Z'
        stroke='#EBA119'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
    </svg>
  );
};

export default IconStarCircle;
