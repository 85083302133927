import IconCard from 'assets/svg/IconCard';
import IconCash from 'assets/svg/IconCash';
import IconVouchers from 'assets/svg/IconVouchers';

export const NAME_REDUCER = 'sales';

export const PREFIX_ACTIONS = 'SALES_FEATURE_';


export const PATH_LOADING = {
  getConfigData: `loading.${NAME_REDUCER}.getConfigData`,
  getVouchersList: `loading.${NAME_REDUCER}.getVouchersList`,
  getCheckoutList: `loading.${NAME_REDUCER}.getCheckoutList`,
  getVoucherOfCustomer: `loading.${NAME_REDUCER}.getVoucherOfCustomer`,
  getRewardOfCustomer: `loading.${NAME_REDUCER}.getRewardOfCustomer`,
};


export enum EPaymentMethod {
  CASH = 'cash',
  VOUCHER = 'voucher',
  CARD = 'card',
  MIX = 'mix'
}

export const EErrorMethodKeys = {
  cash: {
    amount: 'amount',
  },
  voucher: 'voucher',
  mix: 'mix'
};


export const ICONS = {
  [EPaymentMethod.VOUCHER]: <IconVouchers />,
  [EPaymentMethod.CASH]: <IconCash />,
  [EPaymentMethod.CARD]: <IconCard />

};
export const MIX_PAYMENT_METHODS = [
  {
    icon: ICONS[EPaymentMethod.CASH],
    label: 'Cash',
    value: EPaymentMethod.CASH,
  },
  {
    icon: ICONS[EPaymentMethod.VOUCHER],
    label: 'Voucher',
    value: EPaymentMethod.VOUCHER,
  },
  {
    icon: ICONS[EPaymentMethod.CARD],
    label: 'Card',
    value: EPaymentMethod.CARD,
  },
];