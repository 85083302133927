import { Checkbox } from 'antd';
import { IVoucherDataItem } from 'features/checkout/services/types/voucher';
import styled from 'styled-components';
import { formatMoney, pluralizeWord } from 'utils/unit';
type Props = {
  data: IVoucherDataItem,
  onChoose?: () => void;
  checked?: boolean;
};
const ForSalesItem = ({ data, onChoose = () => undefined, checked }: Props) => {
  const { name, services } = data ?? {};
  return (
    <ForSalesItemStyled onClick={onChoose}>
      <div className='voucher_content'>
        <div className='group_name'>
          <div className='checkbox'>
            <Checkbox checked={checked} />
          </div>
          <div>
            <p className='voucher_name'>{name}  <span style={{ opacity: 0.7 }}> &nbsp;(  {`${services.length} ${pluralizeWord('service' ,services.length)}`}  )</span> </p>
            {/* <p className='voucher_time'>
              Code: {voucher_code} {expiry_date && <span>- {moment(expiry_date).format('MM DD YYYY')}</span>}
            </p> */}
          </div>
        </div>
      </div>
      <div className='voucher_amount'>
        <p>{formatMoney(data.retail_price)}</p>
      </div>
    </ForSalesItemStyled>
  );
};

export default ForSalesItem;
const ForSalesItemStyled = styled.div`
  display:flex;
  align-items:center;
  padding:10px 0px;
  &:hover {
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    background: #F0F2F7;
  }
`;