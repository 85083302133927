const IconDiscountCircle = () => {
  return (
    <svg
      width='40'
      height='41'
      viewBox='0 0 40 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        y='0.5'
        width='40'
        height='40'
        rx='20'
        fill='#E95060'
        fillOpacity='0.1'
      />
      <path
        d='M27 13.5L13 27.5'
        stroke='#E95060'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.5 17.5C15.8807 17.5 17 16.3807 17 15C17 13.6193 15.8807 12.5 14.5 12.5C13.1193 12.5 12 13.6193 12 15C12 16.3807 13.1193 17.5 14.5 17.5Z'
        stroke='#E95060'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25.5 28.5C26.8807 28.5 28 27.3807 28 26C28 24.6193 26.8807 23.5 25.5 23.5C24.1193 23.5 23 24.6193 23 26C23 27.3807 24.1193 28.5 25.5 28.5Z'
        stroke='#E95060'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconDiscountCircle;
