import checkoutActions from 'features/checkout/services/actions';
import checkoutSelectors from 'features/checkout/services/selectors';
import { IFuncPromotionAccept, IFuncSetErrorBoundary, IFuncSoftOnChangeText } from 'features/checkout/services/types/promotion';
import React, { useMemo, useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import PromotionInput, { IPromotionInputRef } from './PromotionInput';
import { Checkbox } from 'antd';
type IPromotionCodeProps = {};
const PromotionCode: React.FC<IPromotionCodeProps> = () => {
  const promotionInputRef = useRef<IPromotionInputRef>(null);
  const dispatch = useAppDispatch();
  const selected = checkoutSelectors.getSelectedServices();
  const detail = checkoutSelectors.getBookingDetail();
  const [isPromotion, setIsPromotion] = useState<boolean>(!!detail?.promotion.code);

  const amount = checkoutSelectors.getOriginTotalPrice();
  const voucherValue = checkoutSelectors.getVoucherValue() ?? 0;

  
  const isDisable = useMemo(() => {
    return amount - voucherValue === 0;
  }, [amount, voucherValue]);

 

  const errorBoundary = checkoutSelectors.getPromotionErrorBoundary();

  const onSoftOnChangeText: IFuncSoftOnChangeText = (val) => dispatch(checkoutActions.setSoftPromotionCode(val));

  const setErrorBoundary: IFuncSetErrorBoundary = (val) => dispatch(checkoutActions.setPromotionErrorBoundary(val));

  const onPromotionAccept: IFuncPromotionAccept = (val) => dispatch(checkoutActions.setPromotionInfo(val));



  return (
    <ComponentCusStyled isDisable={isDisable}>
      <div className='form-row'>
        <Checkbox checked={isPromotion} onChange={() => setIsPromotion(! isPromotion)} />
        <p className='form-label is-center'>
          Apply promotion
        </p>
      </div>
      {isPromotion && <PromotionInput
        ref={promotionInputRef}
        location_id={detail?.merchant_location_id}
        onPromotionAccept={onPromotionAccept}
        errorBoundary={errorBoundary}
        softOnChangeText={onSoftOnChangeText}
        setErrorBoundary={setErrorBoundary}
        selectedServices={selected}
      />}
    </ComponentCusStyled>
  );
};

export default PromotionCode;
const ComponentCusStyled = styled.div<{isDisable : boolean}>`

  opacity: ${props => props.isDisable ? 0.5 : 1};
  pointer-events: ${props => props.isDisable ? 'none' : undefined}
`;
