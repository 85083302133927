import moment from 'moment';
import { useAppDispatch } from 'store/hooks';
import bookingActions from '../services/actions';

const useSubscribeGetRealtime = () => {
  const dispatch = useAppDispatch();
  return () => {
    dispatch(bookingActions.setCurrentTime(moment().format()));
    const timeout = setInterval(() => {
      dispatch(bookingActions.setCurrentTime(moment().format()));
    }, 60000);
    return () => {
      clearInterval(timeout);
    };
  };
};

export default useSubscribeGetRealtime;