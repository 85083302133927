import { Form, FormInstance, Input, Modal, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import bookingSelectors from 'features/bookings/services/selectors';
import { useEffect } from 'react';
import { disabledDateSameOrBefore, valueAllOptions } from 'utils/unit';
import { IClosedPeriodForm } from '.';
import DatePicker from 'components/common/DatePickerInput';

interface IFormModal {
  isModalOpen?: boolean;
  handleCancel?: () => void;
  form?: FormInstance;
  handleSubmit?: (values: IClosedPeriodForm) => Promise<boolean>;
  isDisableSubmit?: boolean;
  formData?: any;
}

const 
FormModal = ({
  isModalOpen,
  handleCancel,
  handleSubmit,
  formData,
  isDisableSubmit
}: IFormModal) => {
  const [form] = Form.useForm();
  const dataForm = bookingSelectors.bookingOnline.getLocations()?.filter((item) => (item.status === 1));

  useEffect(() => {
    form?.setFieldsValue({
      locations: dataForm.length <= 1 ? dataForm[0]?.id : valueAllOptions,
    });
  }, [dataForm]);

  useEffect(() => {
    form?.setFieldsValue(formData);
    form.setFieldValue('locations', !formData?.locations ? (dataForm.length > 1 ? valueAllOptions : dataForm[0]?.id) : formData?.locations);
  }, [formData]);

  return (
    <Modal
      maskClosable={false}
      open={isModalOpen}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <h2 className='modal-title'>Add Closed Date</h2>

      <Form
        className='modal-form'
        onFinish={handleSubmit}
        autoComplete='off'
        form={form}
        layout='vertical'
        initialValues={{
          locations: null,
        }}
      >
        <div className='form-row'>
          <Form.Item label='Name' name='name' rules={[{required: true}]}>
            <Input placeholder='Enter name' />
          </Form.Item>
        </div>
        <div className='form-row'>
          <Form.Item label='Start Date' name='startDate' rules={[
            { required: true, message: 'Please select date start' }
          ]}>
            <DatePicker
              disabledDate={(current: Dayjs) => disabledDateSameOrBefore(current, dayjs())} />
          </Form.Item>
          <Form.Item label='End Date' name='endDate' rules={[
            { required: true, message: 'Please select date end' }
          ]}>
            <DatePicker
              disabledDate={(current: Dayjs) => disabledDateSameOrBefore(current, form?.getFieldValue('startDate'))}
            />
          </Form.Item>
        </div>
        <div className='form-row'>
          <Form.Item label='Locations' name='locations' rules={[
            { required: true, message: 'Please select locations' }
          ]} initialValue={dataForm[0]?.id} >
            <Select
              placeholder='Select locations'
              defaultValue={dataForm[0]}
              options={dataForm.length > 1 ? [{
                value: valueAllOptions,
                label: 'All Locations'
              }].concat(dataForm?.map((item: any) => ({
                value: item.id,
                label: item.name
              })) ?? []) : dataForm?.map((item: any) => ({
                value: item.id,
                label: item.name
              })) ?? []}
            />
          </Form.Item>
        </div>
        {/* <div className='form-row'>
          <Form.Item label='Description' name='description'>
            <TextArea placeholder='Enter description' rows={4} />
          </Form.Item>
        </div> */}
        <div className='form-submit-wrapper'>
          <div onClick={handleCancel} className='common-btn is-white'>
            Cancel
          </div>
          <button disabled={isDisableSubmit} type='submit' className='common-btn'>
            Done
          </button>
        </div>
      </Form>
    </Modal>
  );
};

export default FormModal;