const IconUploadClound = () => {
  return (
    <svg
      width='48'
      height='49'
      viewBox='0 0 48 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M32 32.5L24 24.5L16 32.5'
        stroke='#363565'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24 24.5V42.5'
        stroke='#363565'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M40.78 37.2809C42.7307 36.2175 44.2717 34.5347 45.1598 32.4982C46.0479 30.4617 46.2325 28.1874 45.6845 26.0343C45.1364 23.8812 43.887 21.972 42.1334 20.6078C40.3798 19.2437 38.2218 18.5024 36 18.5009H33.48C32.8747 16.1594 31.7464 13.9856 30.1799 12.1429C28.6135 10.3002 26.6497 8.83664 24.4362 7.86216C22.2227 6.88767 19.8171 6.42766 17.4003 6.51671C14.9834 6.60576 12.6182 7.24154 10.4824 8.37628C8.34662 9.51101 6.49587 11.1152 5.06929 13.0681C3.64271 15.0211 2.67742 17.272 2.246 19.6517C1.81458 22.0314 1.92825 24.478 2.57847 26.8075C3.22869 29.1369 4.39853 31.2887 6.00004 33.1009'
        stroke='#363565'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M32 32.5L24 24.5L16 32.5'
        stroke='#363565'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconUploadClound;
