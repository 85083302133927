import {
  CalendarOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Avatar, Popover, Space } from 'antd';
import { EBookingStatus } from 'constants/index';
import bookingActions from 'features/bookings/services/actions';
import moment from 'moment';
import React, { MouseEventHandler, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { checkStatusCanEditBooking, formatMoney, formatTimeMinutes } from 'utils/unit';
import UIServiceItem from '../UIServiceItem';
import { BookingItem } from '../types';
import { ButtonSuccess, PopoverContentStyled, PopoverTitleStyled } from './styles';
import getSingleDateFormat from 'utils/moment/getSingleDateFormat';
// import useSendCustomerInvoice from 'hooks/useSendCustomerInvoice';

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  data: BookingItem;
  children?: React.ReactNode;
  isMemberView?: boolean;
  removeWrapStyle?: boolean;
};
const UIPopover: React.FC<Props> = ({ open, setOpen, data, isMemberView, children, removeWrapStyle }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    customer,
    time_start,
    time_end,
    book_status,
    book_assignment_services = [],
    id,
    noted_client,
    appointment_note,
    parent_time_start,
  } = data;

  const customerName = book_status === EBookingStatus.DRAFT ? 'Draft booking' : customer?.name ?? '';
  const note = noted_client || appointment_note;

  // const _sendCustomerInvoice = useSendCustomerInvoice();

  // const handleSendInvoice = () => {
  //   const customerCode = data?.customer?.customer_code ?? '';
  //   const email = data?.customer?.email ?? '';
  //   _sendCustomerInvoice({ invoiceId: data?.invoice_code ?? '', email, customerCode });
  // };

  const text = useMemo(
    () => (
      <PopoverTitleStyled>
        <div className='group'>
          {book_status !== EBookingStatus.DRAFT ? (
            <Avatar style={{textTransform: 'uppercase'}} className='avatar' size={'small'}>
              {!!customerName && customerName[0]}
            </Avatar>
          ) : (
            <div className='avatar-blank' />
          )}
          {book_status !== EBookingStatus.DRAFT ? (
            <div className='inner'>
              <p className='name' style={{ textTransform: 'capitalize' }}>{customerName}</p>
              {/* <p className='id'>
                Customer ID: <b className='code'>{customer?.customer_code}</b>
              </p> */}
            </div>
          ) : (
            <div>
              <p className='name'>No customer</p>
            </div>
          )}
        </div>
        <CloseOutlined onClick={() => setOpen(false)} className='icon-close' />
      </PopoverTitleStyled>
    ),
    [data]
  );

  const checkIn = () => {
    if(data?.book_assignment_services?.length === 0) {
      navigate(`/private/bookings/edit-booking?bookId=${id}`);
      return;
    }
    dispatch(bookingActions.checkInBooking(String(id)));
    setOpen(false);
  };

  const content = useMemo(() => {
    const _time_start = isMemberView ? parent_time_start : time_start;
    const _timeStart = moment(_time_start);
    const _timeFinish = moment(time_end);
    const _timeMinute = _timeFinish.diff(_timeStart, 'minutes');
    let total = 0;

    const getButton = () => {      
      if (  book_assignment_services.length === 0 && checkStatusCanEditBooking(book_status) ) return (
        <Link to={`/private/bookings/edit-booking?bookId=${id}`}>
          <ButtonSuccess type='button'>Edit Booking</ButtonSuccess>
        </Link>
      );

      // const bookingTime = _timeStart.clone().subtract(1, 'hour');
      // const currentTime = moment();

      switch (book_status) {
        case EBookingStatus.OPEN:
          // if (currentTime > bookingTime) {
          return (
            <ButtonSuccess type='button' onClick={checkIn}>
              Check-in
            </ButtonSuccess>
          );
        // }
        // return null;
        case EBookingStatus.IN_PROGRESS: {
          return (
            <Link to={`/private/bookings/checkout/${id}`}>
              <ButtonSuccess type='button'>Checkout</ButtonSuccess>
            </Link>
          );
        }
        case EBookingStatus.COMPLETED:
          return (
            <Space direction='horizontal'>
              {/* <ButtonSuccess type='button'>Refund</ButtonSuccess> */}
              {/* <ButtonSuccess type='button' onClick={handleSendInvoice}>Send Invoice</ButtonSuccess> */}
            </Space>
          );
        case EBookingStatus.CANCELLED:
        case EBookingStatus.NO_SHOW:
          return null;
        case EBookingStatus.DRAFT:
          return (
            <Link to={`/private/bookings/edit-booking?bookId=${id}`}>
              <ButtonSuccess type='button'>Edit Booking</ButtonSuccess>
            </Link>
          );
        default:
          return null;
      }
    };

    return (
      <PopoverContentStyled isEmptyService={!book_assignment_services.length}>
        <div className='box'>
          <table>
            <tbody>
              <tr>
                <td className='icon'>
                  <CalendarOutlined />
                </td>
                <td className='right-content'>
                  <p>{getSingleDateFormat(_timeStart)}</p>
                </td>
              </tr>
              <tr>
                <td className='icon'>
                  <ClockCircleOutlined />
                </td>
                <td className='right-content'>
                  <p>
                    {_timeStart.format('HH:mm')} -{_timeFinish.format('HH:mm')}{' '}
                    <span className='subtext'>
                      {_timeMinute ? `(${formatTimeMinutes(_timeMinute)})` : ''}
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td className='icon' style={{ verticalAlign: 'top' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: '2px',
                    }}
                  >
                    <UnorderedListOutlined />
                  </div>
                </td>
                <td>
                  {book_assignment_services.length > 0 ? (
                    book_assignment_services.map((ser, i) => {
                      total = total + ser.service_price * ser.quantity;
                      return <UIServiceItem key={i} data={ser} />;
                    })
                  ) : (
                    <div className='service-item'>
                      <span className='name'>No services</span>
                    </div>
                  )}
                </td>
              </tr>

              {book_assignment_services.length > 0 && (
                <>
                  <tr>
                    <td colSpan={2}>
                      <div className='divider'></div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ opacity: 0 }}>
                      <UnorderedListOutlined />
                    </td>
                    <td className='right-content'>
                      <div className='total'>
                        <p>TOTAL</p>
                        <p className='amount'>
                          <b>{formatMoney(total)}</b>
                        </p>
                      </div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          {note ? <div className='input-note-disable'>
            {note ? <p className='text-note-show'>{note}</p> : <div className='text-note'></div>}
          </div> : <></>}
          {customer?.important_client_info && <>
            <p>Customer Note:</p>
            <div className='input-note-disable'>
              <p className='text-note-show'>{customer.important_client_info}</p> : <div className='text-note'></div>
            </div>
          </>}
        </div>
        <div className='button'>{getButton()}</div>
      </PopoverContentStyled>
    );
  }, [data, book_status, isMemberView]);

  const onClickButton: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <Popover
      arrow={false}
      open={open}
      onOpenChange={setOpen}
      overlayInnerStyle={{ padding: 0, }}
      placement='right'
      title={text}
      content={content}
      align={{ offset: [0, 100] }}
      trigger='click'
      overlayClassName='is-detail'
    >
      <div style={removeWrapStyle ? {} : { width: '100%', height: '100%' }} onClick={onClickButton}>{children}</div>
    </Popover>
  );
};

export default UIPopover;
