import React from 'react';

function IconEye() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.1668 12.4987C18.5002 10.4987 15.5002 7.83203 12.5002 7.83203C9.50016 7.83203 6.50016 10.4987 5.8335 12.4987" stroke="#363565" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M5.83366 12.4974C6.50033 14.4974 9.50033 17.1641 12.5003 17.1641C15.5003 17.1641 18.5003 14.4974 19.167 12.4974" stroke="#363565" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M14.331 12.4622C14.331 13.4849 13.5127 14.3306 12.5002 14.3306C11.4877 14.3306 10.6636 13.4849 10.6636 12.4622C10.6636 11.4395 11.4877 10.664 12.5002 10.664C13.5127 10.664 14.331 11.4395 14.331 12.4622Z" stroke="#363565" strokeWidth="1.5" />
    </svg>

  );
}

export default IconEye;