import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import apis from './apis';
import { setLoading } from 'services/UI/sagas';
import { PATH_LOADING } from './constants';
import actions from './actions';
import { get } from 'lodash';
import message from 'components/common/Message';

const getListPaginateLocations = function* (payload: any) {
  try {

    const params = payload.payload;

    yield setLoading(PATH_LOADING.getListPaginateLocations, true);
    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getListPaginateLocations, params);


    if (res?.data) {
      const customerList = res?.data;
      yield put(actions.getListPaginateLocations.success(customerList));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getListPaginateLocations.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getListPaginateLocations, false);
  }
};

const getLocationDetail = function* (payload: any) {
  try {

    const id = payload.payload;
    yield setLoading(PATH_LOADING.getLocationDetail, true);
    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getLocationDetail, id);

    if (res?.data?.data) {
      const customerList = res?.data?.data;
      yield put(actions.getLocationDetail.success(customerList));
    } else {
      const msgError = get(res.data, 'error.message');
      if (msgError) message.error(msgError);
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getLocationDetail.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getLocationDetail, false);
  }
};

const getLocationDetailPublic = function* (payload: any) {
  try {    
    const {merchant_code ,location_id} = payload.payload;
    yield setLoading(PATH_LOADING.getLocationDetailPublic, true);
    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getLocationDetailPublic, merchant_code, location_id);
    
    if (res?.data?.data) {
      const customerList = res?.data?.data;
      yield put(actions.getLocationDetailPublic.success(customerList));
    } else {
      const msgError = get(res.data, 'error.message');
      if (msgError) message.error(msgError);
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getLocationDetailPublic.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getLocationDetailPublic, false);
  }
};

export default function* locationServiceSaga() {
  yield takeLatest(actions.getListPaginateLocations.fetch, getListPaginateLocations);
  yield takeLatest(actions.getLocationDetail.fetch, getLocationDetail);
  yield takeLatest(actions.getLocationDetailPublic.fetch, getLocationDetailPublic);
}

