import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IHolidayParam } from './types/holiday';

const getSetting = createAsyncAction(PREFIX_ACTIONS + 'GET_SETTING');

const getSettingBookingForm = createAsyncAction<string>(PREFIX_ACTIONS + 'GET_SETTING_BOOKING_FORM');

const getOptionBooking = createAsyncAction(PREFIX_ACTIONS + 'GET_OPTION_BOOKING');

const getListClosedDate = createAsyncAction(PREFIX_ACTIONS + 'GET_LIST_CLOSED_DATE');

const getNumberOfNotification = createAsyncAction(PREFIX_ACTIONS + 'GET_NUMBER_OF_NOTIFICATION');

const getListCancellationReason = createAsyncAction(PREFIX_ACTIONS + 'GET_LIST_CANCELLATION_REASON');

const getListServiceCharge = createAsyncAction(PREFIX_ACTIONS + 'GET_SERVICE_CHARGE');

const getDetailServiceCharge = createAsyncAction<any>(PREFIX_ACTIONS + 'GET_DETAIL_SERVICE_CHARGE');

const getShowServiceCharge = createAsyncAction(PREFIX_ACTIONS + 'GET_SHOW_SERVICE_CHARGE');

const getTopUpHistory = createAsyncAction(PREFIX_ACTIONS + 'GET_TOP_UP_HISTORY');

const getTopUpSMSHistory = createAsyncAction(PREFIX_ACTIONS + 'GET_TOP_UP_SMS_HISTORY');

const getMerchantStripeBalance = createAsyncAction(PREFIX_ACTIONS + 'GET_MERCHANT_STRIPE_BALANCE');

const getHolidayInfoToday = createAsyncAction<IHolidayParam>(PREFIX_ACTIONS + 'GET_HOLIDAY_INFO');


const settingActions = { 
    getSetting,
    getOptionBooking,
    getListClosedDate,
    getNumberOfNotification,
    getSettingBookingForm,
    getListCancellationReason,
    getListServiceCharge,
    getDetailServiceCharge,
    getShowServiceCharge,
    getTopUpHistory,
    getTopUpSMSHistory,
    getMerchantStripeBalance,
    getHolidayInfoToday
};

export default settingActions;