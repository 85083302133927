const IconSales = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.75 10.3125H5.25C4.65326 10.3125 4.08097 10.5496 3.65901 10.9715C3.23705 11.3935 3 11.9658 3 12.5625V20.4375H21V12.5625C21 11.9658 20.7629 11.3935 20.341 10.9715C19.919 10.5496 19.3467 10.3125 18.75 10.3125ZM18.75 14.8125H14.25V12.5625H18.75M17.625 9.1875H6.375V3.5625H17.625V9.1875Z" fill="#363565" />
    </svg>

  );
};

export default IconSales;
