import fetch from 'services/request';
import { IApiCreateNewCustomerBody, IApiUpdateCustomerBody } from './types/cutomer';
const getListCustomers = (params?: any) => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/customers',
    params: { ...params, per_page: 100 },
  });
};

const getListCustomersPaginate = (params?: any) => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/customers',
    params: { ...params },
  });
};

const createNewCustomer = (body: IApiCreateNewCustomerBody) => {
  return fetch({
    method: 'post',
    url: 'api/v1/merchants/:merchant_code/customers/store',
    body: { ...body },
  });
};

const createNewCustomerWalkIn = (body: IApiCreateNewCustomerBody) => {
  return fetch({
    method: 'post',
    url: 'api/v1/merchants/:merchant_code/customers-walkin/create',
    body: { ...body, merchant_location_id: 1 },
  });
};

const deleteCustomer = (param: any) => {
  return fetch({
    method: 'delete',
    url: 'api/v1/merchants/:merchant_code/customers/delete/' + param.id,
    params: { ...param }
  });
};

const getCustomerDetail = (param: any) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/:merchant_code/customers/detail/${param.customer_code}`,
    params: { is_walkin_in: param.is_walkin_in, }
  });
};

const getCustomerDetailAppointment = (param: any) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/:merchant_code/customers/${param.customer_code}/appointments`,
    params: { is_walkin_in: param.is_walkin_in, }
  });
};

const updateCustomerInfo = (id: string, body: IApiUpdateCustomerBody) => {
  return fetch({
    method: 'put',
    url: `api/v1/merchants/:merchant_code/customers/update/${id}`,
    body: body as any,
  });
};

const importCustomerInfo = (body: any) => {
  return fetch({
    method: 'post',
    url: 'api/v1/merchants/:merchant_code/customers/import',
    configs: {
      headers: {
        'accept': '*/*',
        'Content-Type': 'multipart/form-data',
      }
    },
    body: body as any,
  });
};

const exportData = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/customers/export',
  });
};

const apisCustomer = {
  getListCustomers,
  createNewCustomer,
  getCustomerDetail,
  getCustomerDetailAppointment,
  updateCustomerInfo,
  deleteCustomer,
  createNewCustomerWalkIn,
  importCustomerInfo,
  getListCustomersPaginate,
  exportData
};

export default apisCustomer;
