import IconDecrease from 'assets/svg/IconDecrease';
import IconIncrease from 'assets/svg/IconIncrease';
import PopoverEdit from 'features/bookings/components/BookingForm/QuickBooking/PopoverEdit';
import { IServiceSelectedItem } from 'features/checkout/services/types/service';
import { useMemo } from 'react';
import styled from 'styled-components';
import { ANONYMOUS_NAME, formatMoney } from 'utils/unit';

export enum EDetailActions {
  INCREASE = 'increase',
  DECREASE = 'decrease',
  REMOVE = 'remove',
}

export type IDetailActions = {
  SET_QUANTITY: (quantity: number) => void;
  REMOVE: () => void;
}

type Props = {
  step: number;
  data: IServiceSelectedItem;
  disabledActions?: EDetailActions[],
  setQuantity?: IDetailActions['SET_QUANTITY'];
  remove?: IDetailActions['REMOVE'];
  viewOnly?: boolean;
  isApplyReward?: boolean;
  onEditService: () => void;
};

const DetailItem = ({
  data,
  disabledActions = [],
  setQuantity = () => undefined,
  remove = () => undefined,
  viewOnly = false,
  isApplyReward = false,
  onEditService,
  step
}: Props) => {
  const disabledActionsOb = useMemo(() => {
    const decrease = disabledActions.includes(EDetailActions.DECREASE);
    const increase = disabledActions.includes(EDetailActions.INCREASE);
    const remove = disabledActions.includes(EDetailActions.REMOVE);
    return ({
      increase,
      decrease,
      remove
    });
  }, [disabledActions]);  

  return (
    <DetailItemStyled>
      <div className={`${step !== 3 ? 'click-item-container' : ''} item-container`} onClick={step === 1 ? onEditService : undefined}>
        <div className='item-info'>
          <p className='item-title service_name'>
            {data?.name}
            {isApplyReward && <IconReward />}
          </p>
          <p className='item-text'>
            {(data as any)?.serviceVariantName || data?.serivce_variant_name || data?.name}{' '}
            {/* <span>
            {data?.duration_time
              ? formatTimeMinutes(data?.duration_time)
              : ''}
          </span>{' '} */}
            with {''}
            <span className='item-emloyee-name'>{data?.employee?.name ?? ANONYMOUS_NAME}</span>
          </p>
        </div>
        <div className='gr-actions'>
          {/* <div> */}
          <div className='quantity-control'>
            {!viewOnly && step !== 1 && step !== 3 && <button
              type='button'
              className={`control-btn ${disabledActionsOb.decrease ? 'is-disabled' : ''}`}
              disabled={disabledActionsOb.decrease}
              onClick={() => setQuantity(data?.quantity - 1)}
            >
              <IconDecrease />
            </button>}
            {
              step !== 2 && <span className='quantity-value'>{data?.quantity}</span>
            }
            
            {!viewOnly && step !== 1 && step !== 3 && <button
              type='button'
              className={`control-btn ${disabledActionsOb.increase ? 'is-disabled' : ''}`}
              disabled={disabledActionsOb.increase}
              onClick={() => setQuantity(data?.quantity + 1)}
            >
              <IconIncrease />
            </button>}
          </div>

          <div>
            {formatMoney(data.price)}
          </div>

          {!viewOnly && step <= 1 && <PopoverEdit
            quantity={data?.quantity}
            onOpenConfirmModal={remove}
            handleEditService={onEditService}
            isSliptService={false}
          />}

          {/* {!viewOnly && <>
          {!disabledActionsOb.remove &&
            <div className='item-control'>
              <button onClick={onEditService}>
                <IconEdit />
              </button>
              <button
                type='button'
                onClick={remove}
              >
                <IconClose />
              </button>
            </div>
          }
        </>} */}

        </div>
      </div>
    </DetailItemStyled>
  );
};

export default DetailItem;

const DetailItemStyled = styled.div`
.click-item-container {
  cursor: pointer;
  :hover {
    background-color: var(--color-white-01);
  }
}
.item-container {
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  gap: 24px;
  border: 1px solid var(--color-purple-05);
  border-radius: 6px;
  align-items: center;
  
  .item-info {
    flex: 1;
    min-width: 40%;
    & + div {
      width: 50%;
      /* justify-content: space-between; */
      justify-content: end;
      display: flex;
      gap: 24px;
      margin-left: auto;
    }
  }
  .item-title {
    font-weight: 600;
    font-size: 16px;
    display:flex;
    gap:8px;
    align-items: center;
  }
  .item-text {
    font-size: 12px;
    .item-emloyee-name {
      color: #4a9d77;
    }
    span {
      font-weight: 600;
    }
  }
  .item-control {
    display: flex;
    gap: 8px;
    align-items: center;
    button {
      display: flex;
      align-items: center;
    }
  }
  .control-btn{
    &.is-disabled {
      opacity:0.3;
    }
  }
  .gr-actions {
    display:flex;
    align-items:center;
  }
  .info-price {
    display:flex;
  }
}
`;

const IconReward = () => <div className='d-flex' style={{ marginBottom: 4 }}><svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.7858 5.71261L15.5001 9.99833L10.5001 2.85547L5.50007 9.99833L1.21436 5.71261V14.9983C1.21436 15.5666 1.44012 16.1117 1.84198 16.5136C2.24385 16.9154 2.78889 17.1412 3.35721 17.1412H17.6429C18.2113 17.1412 18.7563 16.9154 19.1582 16.5136C19.56 16.1117 19.7858 15.5666 19.7858 14.9983V5.71261Z" fill="#FAAD14" />
</svg></div>;
