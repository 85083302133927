import IconBack from 'assets/svg/IconBack';
// import { AxiosResponse } from 'axios';
import { Popover } from 'antd';
import message from 'components/common/Message';
import ModalSendInvoiceEmail, { ICustomerSendEmail } from 'components/common/Modal/ModalSendInvoiceEmail';
import { PATH } from 'constants/path.enum';
import apisBooking from 'features/bookings/services/apis';
import settingSelectors from 'features/settings/services/selectors';
import { get, isEqual } from 'lodash';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ModalConfirm, { EFields } from '../components/ModalConfirm';
import checkoutActions from '../services/actions';
import checkoutSelectors from '../services/selectors';
import Purchase from '../widgets/Purchase';
import moment from 'moment';
import { TIME_START_FORMAT_RESPONSE } from 'features/bookings/services/constants';
import { ANONYMOUS_NAME } from 'utils/unit';
// interface InotifyInvoiceRes {
//   id?: number,
//   created_at?: string;
//   customer_name?: string;
//   merchant_name?: string;
// }


type ButtonStepOneProps = {
  step: number,
  setStep: (val: number) => void
};

const ButtonHandler = ({ step, setStep }: ButtonStepOneProps) => {
  const navigate = useNavigate();
  const bookAssignmentSelected = checkoutSelectors.getSelectedServices();
  const initBookAssignmentSelected = checkoutSelectors.getInitialSelectedServices();
  const selectedVouchersForSales = checkoutSelectors.getSelectedVouchersForSales();
  const initSelectedVouchersForSales = checkoutSelectors.getInitSelectedVouchersForSales();
  const isRequirePin = settingSelectors.getIsRequirePin();
  const bookDetail = checkoutSelectors.getBookingDetail();
  const setLoadingPage = useSetLoadingPage();
  const dispatch = useAppDispatch();
  const totalPrice = checkoutSelectors.getOriginTotalPrice();
  const currCustomer = checkoutSelectors.getCustomerInfo();
  const { book_assignment_id = '' } = useParams();
  const customerInfo = checkoutSelectors.getCustomerInfo();



  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState<boolean>();

  function checkIsUpdateBook(): boolean {
    if (!isEqual(selectedVouchersForSales, initSelectedVouchersForSales)) return true;

    if (initBookAssignmentSelected.length !== bookAssignmentSelected.length)
      return true;

    const result = bookAssignmentSelected?.filter(o1 => initBookAssignmentSelected?.some(o2 => (o1.id === o2.id && o1.quantity === o2.quantity && o1.employee?.id === o2.employee?.id)));
    if (result.length !== bookAssignmentSelected.length)
      return true;

    return false;
  }

  const onSubmit = async (values: any, setError: any, resetPin: any) => {
    const payload = {
      book_start: moment(bookAssignmentSelected[0].time_start).format(TIME_START_FORMAT_RESPONSE),
      customer_id: currCustomer?.id,
      customer_code: currCustomer?.customer_code,
      is_walkin_in: (currCustomer as any)?.is_walkin_in,
      pin_number: values?.PIN ?? undefined,
      action_update: 'after_work',
      merchant_location_id: bookDetail?.merchant_location_id ?? '',
      book_assignment_services: bookAssignmentSelected.map((item: any) => {
        return {
          service_id: item?.service_id ?? item?.id,
          service_variant_id: item?.service_variant_id,
          time_start: moment(item?.time_start).format(TIME_START_FORMAT_RESPONSE),
          duration_time: item?.duration_time,
          quantity: item?.quantity,
          employee_id: item?.employee?.id ?? item?.employee_id,
          sale_price: item?.price
        };
      })
    };

    setLoadingPage(true);
    try {
      const rest = await apisBooking.updateBooking(book_assignment_id || '', payload);
      const msg = rest.data.message;
      // if (rest?.data.error) {
      //   message.error(rest?.data.error.message);
      // }
      if (msg) {
        message.success(msg);
        dispatch(checkoutActions.updateInitialSelectedServices());
        setIsModalConfirmOpen(false);
        setStep(step + 1);
      } else {
        const msgError = get(rest, 'data.error.message', '');
        if (!msgError) return;
        const employee_busy = get(rest, 'data.error.employee_busy', []);
        if (employee_busy.length > 0) {
          message.error(msgError);
        } else {
          if (typeof setError === 'function')
            setError({ [EFields.PIN]: msgError });
          if (typeof resetPin === 'function')
            resetPin();
        }
      }
    } catch (error: any) {

      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(value[0]);
      });
      return false;
    } finally {
      setLoadingPage(false);
    }
  };

  const onCancel = () => setIsModalConfirmOpen(false);
  const onContinue = () => {

    if(! customerInfo) {
      message.error('Please select customer');
      window.scrollTo(0, 0);
      return;
    }

    if (bookAssignmentSelected.length === 0) {
      message.error('Please select at least one service');
      return;
    }

    const bookAnonymous = bookAssignmentSelected.find(o => o.employee?.id === undefined);
    if(bookAnonymous) {
      message.error(`The team member in '${bookAnonymous.name}' must not be ${ANONYMOUS_NAME}.`);
      return;
    }

    if (checkIsUpdateBook()) {
      if (!isRequirePin) {
        onSubmit({}, () => undefined, () => undefined);
      } else {
        setIsModalConfirmOpen(true);
      }
    } else {
      setStep(step + 1);
    }
    dispatch(checkoutActions.reviewPointCustomerWillReceive.fetch(totalPrice));
  };

  const onBack = () => {
    if (step === 1) {
      return navigate(-1);
    }
    if (step === 2) {
      setStep(step - 1);
    }
  };
  return (
    <>
      <Button step={step} onBack={onBack} setStep={setStep} onContinue={onContinue} />
      {isModalConfirmOpen ? <ModalConfirm visible={true} onCancel={onCancel} onSubmit={onSubmit} /> : <></>}
    </>
  );
};

export default ButtonHandler;

const Button = ({ step, onBack, setStep, onContinue }: { step: number, onBack: any, setStep: (a: number) => void; onContinue: any }) => {
  const dispatch = useAppDispatch();
  const customerInfo = checkoutSelectors.getCustomerInfo();
  const invoiceResult = checkoutSelectors.getResultTotal();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);
  const [isSendMailDone, setIsSendMailDone] = useState<boolean>(false);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const onSuccessDone = () => {
    dispatch(checkoutActions.getTotalPointCustomer.fetch({
      is_walkin_in: !!customerInfo?.is_walkin_in,
      customer_code: customerInfo?.customer_code?.replace('#', '') ?? '',
    }));
    setStep(3);
  };
  

  return (
    <div className='search-wrapper'>
      {step !== 3 && (
        <button
          className='icon-back'
          onClick={onBack}
        >
          <IconBack />
        </button>
      )}
      {step === 1 && (
        <button
          type='submit'
          className={'form-booking-submit'}
          onClick={onContinue}
          // disabled={!customerInfo}
        >
          Continue
        </button>
      )}
      {step === 2 && <Purchase onSuccess={onSuccessDone} />}
      {step === 3 && (
        <>
          {!isSendMailDone && <Popover
            trigger={'click'}
            placement='topRight'
            open={isOpenPopover}
            onOpenChange={setIsOpenPopover}
            content={
              <ActionsStyled>
                <ul className='more-settings'>
                  <li onClick={() => {
                    setIsOpenPopover(false);
                    setIsOpen(true);
                  }}>
                    <IconSendInvoice />
                    Send Invoice
                  </li>
                  {/* <li className='icon-refund'>
                    <IconRefund />
                    Refund
                  </li> */}
                </ul>
              </ActionsStyled>
            }>
            <button
              onClick={() => setIsOpenPopover(true)}
              className='icon-back'
            >
              <IconDash />
            </button>
          </Popover>}
          <Link to={PATH.quick_booking} reloadDocument className='form-booking-submit link'>
            Book New Appointment
          </Link>
        </>
      )}
      <ModalConfirm />
      {isOpen && <ModalSendInvoiceEmail
        handleCancel={handleCancel}
        isOpen={isOpen}
        onDone={() => setIsSendMailDone(true)}
        customer={customerInfo as ICustomerSendEmail}
        invoice_id={invoiceResult?.invoice_no ?? ''} />}
    </div>
  );
};

export const IconDash = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#363565" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#363565" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#363565" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const IconSendInvoice = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2305_492305" maskUnits="userSpaceOnUse" x="1" y="1" width="18" height="18">
        <path d="M1.6665 1.66536H18.3332V18.332H1.6665V1.66536Z" fill="white" />
      </mask>
      <g mask="url(#mask0_2305_492305)">
        <path d="M9.47754 10.5234L17.8456 2.15537" stroke="#363565" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.47682 10.5217C9.47682 10.5217 4.77643 9.17874 2.90775 8.64482C2.46204 8.51748 2.15479 8.11016 2.15479 7.64658V7.64632C2.15479 7.18001 2.46064 6.76891 2.90732 6.63493C6.03503 5.69661 17.8449 2.15364 17.8449 2.15364C17.8449 2.15364 14.3019 13.9635 13.3636 17.0912C13.2296 17.5379 12.8185 17.8438 12.3522 17.8438H12.352C11.8884 17.8438 11.4811 17.5365 11.3537 17.0908C10.8198 15.2221 9.47682 10.5217 9.47682 10.5217Z" stroke="#363565" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>

  );
};

export const IconRefund = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.3332 10.0013C18.3332 14.5963 14.5948 18.3346 9.99984 18.3346C5.40487 18.3346 1.6665 14.5963 1.6665 10.0013C1.6665 9.71363 1.89949 9.48047 2.18734 9.48047C2.47518 9.48047 2.70817 9.71363 2.70817 10.0013C2.70817 14.022 5.97918 17.293 9.99984 17.293C14.0205 17.293 17.2915 14.022 17.2915 10.0013C17.2915 5.98064 14.0205 2.70964 9.99984 2.70964C8.06216 2.70964 6.26268 3.46293 4.90921 4.79297H6.87484C7.16268 4.79297 7.39567 5.02613 7.39567 5.3138C7.39567 5.60148 7.16268 5.83464 6.87484 5.83464H3.74984C3.46199 5.83464 3.229 5.60148 3.229 5.3138V2.1888C3.229 1.90113 3.46199 1.66797 3.74984 1.66797C4.03768 1.66797 4.27067 1.90113 4.27067 2.1888V3.96536C5.8073 2.4987 7.8264 1.66797 9.99984 1.66797C14.5948 1.66797 18.3332 5.40634 18.3332 10.0013ZM10.5207 9.48047H9.479C9.19168 9.48047 8.95817 9.24679 8.95817 8.95964C8.95817 8.67248 9.19168 8.4388 9.479 8.4388H11.5623C11.8502 8.4388 12.0832 8.20564 12.0832 7.91797C12.0832 7.6303 11.8502 7.39714 11.5623 7.39714H10.5207V6.8763C10.5207 6.58863 10.2877 6.35547 9.99984 6.35547C9.71199 6.35547 9.479 6.58863 9.479 6.8763V7.39714C8.61737 7.39714 7.9165 8.098 7.9165 8.95964C7.9165 9.82127 8.61737 10.5221 9.479 10.5221H10.5207C10.808 10.5221 11.0415 10.7558 11.0415 11.043C11.0415 11.3301 10.808 11.5638 10.5207 11.5638H8.43734C8.14949 11.5638 7.9165 11.797 7.9165 12.0846C7.9165 12.3723 8.14949 12.6055 8.43734 12.6055H9.479V13.1263C9.479 13.414 9.71199 13.6471 9.99984 13.6471C10.2877 13.6471 10.5207 13.414 10.5207 13.1263V12.6055C11.3823 12.6055 12.0832 11.9046 12.0832 11.043C12.0832 10.1813 11.3823 9.48047 10.5207 9.48047Z" fill="#E95060" stroke="#E95060" strokeWidth="0.25" />
    </svg>
  );
};

export const ActionsStyled = styled.div`
  .more-settings {
    display: block;
    position: static;
    transform: none;
    padding: 0;
    box-shadow: none;
  }
  button {
    border: none;
    background: transparent;
    display: block;
  }
  li > * {
    margin-right: 8px;
  }
  .icon-refund {
    color: #E95060; 
  }
`;