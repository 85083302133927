import checkoutSelectors from 'features/checkout/services/selectors';
import { formatMoney } from 'utils/unit';
// import formatNumber from 'utils/formatNumber';

const SubTotalAmount = () => {
  const result = Number(checkoutSelectors.getSubTotal());

  if (!result) return null;

  return (
    <p className='extra-price-row'>
      Subtotal
      <span>{formatMoney(result ?? 0)}</span>
    </p>
  );
};

export default SubTotalAmount;
