import { createSlice } from '@reduxjs/toolkit';
import { NAME_REDUCER } from './constants';
import actions from './actions';
import generateListDurations from 'utils/moment/generateListDurations';
import { IState } from './types/reducer';
import { IApiServiceParams } from './types/services';
import authActions from 'features/auth/services/actions';
import { ICategoryResData } from './types/category';

const initialState: IState = {
  listPaginateService: [],
  showCreateService: {},
  serviceDetail: null,
  durations: generateListDurations(6),
  params: {},
  category: {
    data: [],
    pagination: null,
    params: {}
  }
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.category.setParams, (state, { payload }) => {
        const _params = {
          ...state.category.params ?? {},
          ...payload ?? {}
        };
        if (_params.keyword === undefined || _params.keyword === null) {
          delete _params.keyword;
        }
      
        if (_params.location_id === undefined || _params.location_id === null) {
          delete _params.location_id;
        }
        state.category.params = _params;
      })
      .addCase(actions.category.getLstData.success, (state, { payload }) => {
        const resData = payload as ICategoryResData;
        state.category.data = resData?.data ?? [];
        state.category.pagination = resData?.pagination ?? null;
      })
      .addCase(authActions.logout.success, (state) => {
        state.listPaginateService = [];
        state.showCreateService = {};
        state.serviceDetail = null;
        state.durations = generateListDurations(6);
        state.params = {};
        state.category.data = [];
        state.category.pagination = null;
        state.category.params = {};
      })
      .addCase(actions.setServiceListParams, (state, { payload }) => {
        const data = payload as IApiServiceParams;
        const params: IApiServiceParams = {
          ...state.params ?? {},
          ...data ?? {},
        };

        if (params.keyword === undefined || params.keyword === null) {
          delete params.keyword;
        }
        if (params.page === undefined || params.page === null) {
          params.page = 1;
        }

        if (params.per_page === undefined || params.per_page === null) {
          params.per_page = 10;
        }
        state.params = params;
      })
      .addCase(actions.getListPaginateServices.success, (state, { payload }) => {
        const data = payload as any[];
        state.listPaginateService = data;
      })
      .addCase(actions.getShowCreateService.success, (state, { payload }) => {
        const data = payload as any[];
        state.showCreateService = data;
      })
      .addCase(actions.setServiceDetail, (state, { payload }) => {
        const data: any = payload;
        state.serviceDetail = data;
      });
  },
});
const serviceServiceReducer = Slice.reducer;
export default serviceServiceReducer;
