import IconReward from 'assets/svg/IconReward';
import { IconAvatarWalkIn } from 'features/bookings/components/BookingForm/QuickBooking/CustomerDetail';
import salesSelectors from 'features/sales/services/selectors';
import styled from 'styled-components';
import { CUSTOMER_WALKIN_INDEX, pluralizeWord } from 'utils/unit';

const CustumerDetail = ({ step }: { step: number }) => {
    const currCustomer = salesSelectors.getCustomer();
    const total_point_program_will_earn = salesSelectors.getTotalPointWillEarn();
    const total_point_of_customer = currCustomer?.loyalty_point ?? 0;
    const name = currCustomer?.name ?? '';


    const Rewards = () => {        

        const totalLoyaltyAfterCheckout = () => {
            return Number(total_point_of_customer) + Number(total_point_program_will_earn);
        };

        if (step === 3) {
            const totalPoint = totalLoyaltyAfterCheckout();
            return totalPoint > 0 ? <><IconReward /> <span>Have {totalPoint} {pluralizeWord('point' ,totalPoint)}</span></> : <></>;

        }

        return null;
    };

    return (
        <>
            <CustumerDetailStyled>
                <div className="form-row">
                    <p className="form-label">Customer Details</p>
                </div>
            </CustumerDetailStyled>
            <div className='current-customer'>
            {Number(currCustomer?.is_walkin_in ?? 0) !== CUSTOMER_WALKIN_INDEX ? <p className='customer-avatar common-avatar'>
                {name && Array.from(name)[0]}
            </p> : <IconAvatarWalkIn/> }
                <div className='customer-detail'>
                    <p className={`detail-name ${(currCustomer?.is_walkin_in  ?? 0) !== CUSTOMER_WALKIN_INDEX ? 'customer-link' : 'customer-link-disable'}`} style={{ textTransform: 'capitalize' }}>{name}</p>
                    {(currCustomer?.is_walkin_in  ?? 0) !== CUSTOMER_WALKIN_INDEX ? 
                    <>
                        <p>Customer ID: <span>{currCustomer?.customer_code ?? ''}</span></p>
                        <div className='reward'> <Rewards /></div>
                    </> : <></>}
                </div>
            </div>
        </>
    );
};

export default CustumerDetail;

const CustumerDetailStyled = styled.div`
    .form-row {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        .form-label {
            font-weight: 600;
            font-size: 18px;
            color: '#363565';
        }
    }
    .customer-link-disable {
        pointer-events: none;
    }

`;