import { createSlice } from '@reduxjs/toolkit';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';
import authActions from 'features/auth/services/actions';
import actions from './actions';
import { INotificationItemResData } from './types/notification';

const initialState: IState = {
    notificationList: [],
    params: {},
  };

  export const Slice = createSlice({
    name: NAME_REDUCER,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
          .addCase(authActions.logout.success, (state) => {
            state.notificationList = [];
            state.params = {};
          })
          .addCase(actions.getListNotification.success, (state, { payload }) => {
            const data: INotificationItemResData[] = payload;
            state.notificationList = data;
          });
        }
});
const notificationServiceReducer = Slice.reducer;
export default notificationServiceReducer;