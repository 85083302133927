import MaskedInput from 'antd-mask-input';
 
const PhoneNumberInput = (props: any) => {
  return (
    <MaskedInput
      mask={'\\0400000000'}
      {...props}
    />
  );
};
export default PhoneNumberInput;

