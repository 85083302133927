import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { APIListInvoicesParams, IResInvoiceItem, InvoiceCategoryItem } from './types/invoice';
import uiSelector from 'services/UI/selectors';
import { PATH_LOADING } from './constants';

type MyState = RootState['invoice'];

const getCurrentState = (state: RootState): MyState => state.invoice;

const selector = <T = keyof MyState>(key: T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key as any, defaultValue));

const getCategories = () => selector('categories') as InvoiceCategoryItem[];

const getInvoices = () => selector('invoices') as IResInvoiceItem[];

export const getPureInvoiceParam = (root: RootState) => {
  const state = getCurrentState(root);
  return state.invoiceParams ?? {};
};
const getInvoiceParam = (key: keyof APIListInvoicesParams) => selector(`invoiceParams.${key}`);

const getInvoiceAllParam = () => selector('invoiceParams');

const getInvoicesLoading = () => uiSelector.getLoading(PATH_LOADING.getInvoices);

const getPagination = () => selector('pagination') as MyState['pagination'];

const detailInvoice = {
  getInvoiceNo: () => selector('detail.id') as string | null,
  getData: () => selector('detail.data') as IResInvoiceItem | null,
  getLoading: () => uiSelector.getLoading(PATH_LOADING.getDetail),
};


const invoiceSelectors = {
  getCategories,
  getInvoices,
  getInvoiceParam,
  getInvoicesLoading,
  detailInvoice,
  getInvoiceAllParam,
  getPagination
};
export default invoiceSelectors;
