import { Form } from 'antd';
import ActiveHoursElement from 'features/locations/pages/widgets/ActiveHoursElement';
import styled from 'styled-components';

type Props = {
  onCancel?: () => void;
  onNextStep?: () => void;
  onPrevStep?: () => void;
  onSubmit?: () => void;
};

export const dayOfWeek = [
  {
    label: 'SUN',
    value: 'sunday',
  },
  {
    label: 'MON',
    value: 'monday',
  },
  {
    label: 'TUE',
    value: 'tuesday',
  },
  {
    label: 'WED',
    value: 'wednesday',
  },
  {
    label: 'THU',
    value: 'thursday',
  },
  {
    label: 'FRI',
    value: 'friday',
  },
  {
    label: 'SAT',
    value: 'saturday',
  },
];

const UserFormFouthStep = ({
  onPrevStep = () => undefined,
  onSubmit,

}: Props) => {


  const form = Form.useFormInstance();

  return (
    <>
      <ActiveHoursStyled className='form-calendar'>
        <Form.Item name='calenda'>
          {dayOfWeek.map((item: any, index) => (
            <ActiveHoursElement
              name={item.value}
              key={index}
              label={item.label}
              form={form}
            />
          ))}
        </Form.Item>
      </ActiveHoursStyled>
      <div className='form-submit-wrapper'>
        <button className='common-btn is-white'  type='button' onClick={onPrevStep}>
          {'Back'}
        </button>
        <button className='common-btn' type={'button'} onClick={onSubmit}>{'Save'}</button>
      </div>
    </>
  );
};

export default UserFormFouthStep;


const ActiveHoursStyled = styled.div`
  .calendar-row {
    padding: 8px 18px !important;
  }
  .ant-form-item-control {
    margin-bottom: 0px !important;
  }
`;