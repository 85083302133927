import { Form, Modal, Spin, UploadFile, UploadProps, message } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import IconUploadClound from 'assets/svg/IconUploadClound';
import { useState } from 'react';

export type ImportModalProps = {
  isOpenModal: boolean;
  handleCancel: () => void;
  onSubmit?: (file: UploadFile) => Promise<boolean>;
};
export type ImportModalRef = {
  reset: () => void;
};
// @ts-ignore
const ImportModal = ({ isOpenModal, handleCancel, onSubmit = () => undefined }: ImportModalProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isSpin, setIsSpin] = useState<boolean>(false);

  const beforeUpload: UploadProps['beforeUpload'] = (file) => {
    const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
    if (!allowedTypes.includes(file.type)) {
      message.error('You can only upload xls, xlsx, or csv files!');
      return false;
    }
    setFileList([file]);
    return false;
  };

  const handleUpload = async () => {
    if (fileList.length === 0) return;
    setIsSpin(true);
    try {
      const isSuccess = await onSubmit(fileList[0]);
      if (isSuccess) setFileList([]);
    } catch (error) { }
    finally {
      setIsSpin(false);
    }
  };

  return (

    <Modal
      open={isOpenModal}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      closable={!isSpin}
      maskClosable={false}
      centered
    // closable={false}
    >
      <Spin spinning={isSpin}>
        <h2 className='modal-title'>
          <p>Import file</p>
        </h2>
        <Form
          name='basic'
          className='modal-form'
          autoComplete='off'
          layout='vertical'
        >
          <div className='form-row'>
            <p className='form-notifications-note'>
              Date format should be in Y-m-d (e.g. 2022-04-21) format. Make sure
              the date format is correct in the excel file.
            </p>
          </div>
          <Form.Item name={'file'} label='Upload File (file must be a file of type: xls, xlsx, csv)'>
            <Dragger beforeUpload={beforeUpload} fileList={fileList} maxCount={1}>
              <p className='ant-upload-drag-icon'>
                <IconUploadClound />
              </p>
              <p className='ant-upload-text'>Choose a file </p>
            </Dragger>
          </Form.Item>
          <div className='form-row form-submit-wrapper'>
            <button className='common-btn is-white' onClick={handleCancel}>
              Cancel
            </button>
            <button
              type='button'
              onClick={handleUpload}
              className='common-btn'>Upload</button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ImportModal;
