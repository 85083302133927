import { Empty } from 'antd';
import checkoutSelectors from 'features/checkout/services/selectors';
import moment from 'moment';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import DetailItem, { EDetailActions, IDetailActions } from './Item';
import { IServiceSelectedItem } from 'features/checkout/services/types/service';
import { useAppDispatch } from 'store/hooks';
import checkoutActions from 'features/checkout/services/actions';
import { find } from 'lodash';
import { CHOOSE_CATEGORY_REWARD } from 'features/loyaltyProgram/services/constants';
import ForSalesSelectedItem from '../VouchersTabs/ForSalesSelectedItem';
import useVoucherForSalesFeature from 'features/checkout/hook/useVoucherForSalesFeature';
import ModalEditService, { IModalEditServiceProps } from 'features/sales/components/ServiceDetails/ModalEditService';
import checkoutHook from 'features/checkout/hook/checkoutHook';
import BookingDate from 'components/common/BookingDate';
// import FeeItem from '../FeeButton/Item';
// import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
// import { useTranslation } from 'react-i18next';
// import ModalConfirm from '../ModalConfirm';

const ServiceDetails = ({ viewOnly, step }: { viewOnly: boolean, step?: number }) => {
  const detail = checkoutSelectors.getBookingDetail();
  const selectedReward = checkoutSelectors.getSelectedReward();
  const currCustomer = checkoutSelectors.getCustomerInfo();
  const [currEditService, setCurrEditService] = useState<IServiceSelectedItem | null | undefined>(null);
  const [selectedVouchersForSales, actionsSelectedVouchersForSales] = useVoucherForSalesFeature();
  const resultTotal = checkoutSelectors.getResultTotal();
  // const modalConfirmRef = useRef<ModalConfirmRef>(null);
  // const { t: bookingLang } = useTranslation('booking');

  const dispatch = useAppDispatch();
  // const [currServices, setCurrServices] = quickBookingHooks.useSelectedServices();

  // const extraFee = checkoutSelectors.getExtraFee();
  // const extraFeeValue = checkoutSelectors.getExtraFeeValue();

  // const deta

  const bookingDate = useMemo(() => moment(detail?.time_start), [detail]);
  const initialSelected = checkoutSelectors.getInitialSelectedServices();
  const selected = checkoutSelectors.getSelectedServices();

  const setQuantity = (service: IServiceSelectedItem): IDetailActions['SET_QUANTITY'] => (quantity) => {
    //   modalConfirmRef.current?.show({
    //     title: bookingLang('modalConfirm.editService.title'),
    //     msg: bookingLang('modalConfirm.editService.message'),
    dispatch(checkoutActions.setQuantitySelectedService({ id: service.id, service_variant_id: service.service_variant_id, quantity }));
    //   });
  };

  const onEditService: IModalEditServiceProps['onSubmit'] = (formValue, service) => {
    if (!service) return;
    dispatch(checkoutActions.setServiceSelected(selected.map(item => {
      if (service.service_variant_id ? (service.id === item.id && service.service_variant_id === item.service_variant_id) : (service.id === item.id)) {
        return ({
          ...item,
          employee: formValue.employee,
          quantity: formValue.quantity,
        });
      }
      return item;
    })));
  };

  const remove = (service: IServiceSelectedItem): IDetailActions['REMOVE'] => () => {
    dispatch(checkoutActions.removeSelectedService({ id: service.id, service_variant_id: service.service_variant_id }));
  };
  
  // modalConfirmRef.current?.show({
  //   title: bookingLang('modalConfirm.deleteService.title'),
  //   msg: bookingLang('modalConfirm.deleteService.message'),
  // submit: () => dispatch(checkoutActions.removeSelectedService(service.id)),
  // };


  /* eslint-disable-next-line */
  const disabledActions = (service: IServiceSelectedItem): EDetailActions[] => {
    const result: EDetailActions[] = [];
    const existItem = find(initialSelected, (o: IServiceSelectedItem) => o.id === service.id && o.service_variant_id === service.service_variant_id);

    if (viewOnly) {
      result.push(EDetailActions.REMOVE);
      result.push(EDetailActions.DECREASE);
      result.push(EDetailActions.INCREASE);
      return result;
    }

    // remove
    if (existItem && viewOnly) {
      result.push(EDetailActions.REMOVE);

      if (service.quantity === existItem.quantity) {
        result.push(EDetailActions.DECREASE);
        result.push(EDetailActions.INCREASE);
      }
    }

    if (service.quantity === 1) {
      result.push(EDetailActions.DECREASE);
    }

    return result;
  };

  const checkApplyReward = (service: IServiceSelectedItem) => {
    if (!viewOnly) return false;
    if (!selectedReward) return false;

    if (selectedReward.category !== CHOOSE_CATEGORY_REWARD.FREE_SERVICE) return false;

    if (selectedReward.free_service.length === 0) return true;

    return !!selectedReward.free_service.find(o => {
      if (o.extend_services.length === 0) return o.id.toString() === service.id?.toString();
      return o.id.toString() === service.id?.toString() && !!o.extend_services.find(s => s.toString() === service.service_variant_id?.toString());
    });
  };

  

  return (
    <ServiceDetailsStyled>
      <div className='heading'>
        <div>
          <div className='title'>Service Details</div>
          <BookingDate 
            bookingDate={moment(bookingDate).format()}  
            isEdit={false}
            setIsOpenModal={() => undefined}
          />
        </div>
      </div>
      {(selected.length > 0 || selectedVouchersForSales.length > 0) ? (
        <div className='list-detail-items common-scrollbar'>
          {selected?.map((service) => {
            return (
              <DetailItem
                step={step ?? 0}
                data={service}
                key={service.id + '-' + service.service_variant_id}
                setQuantity={setQuantity(service)}
                remove={remove(service)}
                disabledActions={disabledActions(service)}
                isApplyReward={checkApplyReward(service)}
                onEditService={() => setCurrEditService(service)}
              // viewOnly={viewOnly}
              />
            );
          })}
          {(step === 3 ? (resultTotal?.list_voucher_sell ?? selectedVouchersForSales) : selectedVouchersForSales)?.map(voucher =>
            <ForSalesSelectedItem
              key={voucher.id}
              data={voucher}
              setQuantity={actionsSelectedVouchersForSales.setQuantity}
              remove={actionsSelectedVouchersForSales.remove}
              viewOnly={viewOnly}
              customer={currCustomer}
              step={step}
            />
          )}
          {/* {step === 3 && extraFee && <FeeItem data={extraFee} amount={extraFeeValue} />} */}
        </div>
      ) : (
        <div className='empty-service'>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No selected'} />
        </div>
      )}
      {!!currEditService && <ModalEditService
        isOpenModal={!!currEditService}
        handleCancel={() => setCurrEditService(null)}
        onSubmit={onEditService}
        selector={checkoutSelectors}
        hook={checkoutHook}
        data={currEditService as any}
      />}
      {/* <ModalConfirm ref={modalConfirmRef} usePinVerify={detail?.book_status === EBookingStatus.IN_PROGRESS} /> */}
    </ServiceDetailsStyled>
  );
};

export default ServiceDetails;

const ServiceDetailsStyled = styled.div`
  .heading {
    display: flex;
    gap: 24px;
    align-items: flex-start;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    color: var(--color-primary);
  }

  .time {
    display: flex;
    font-weight: 600;
    font-size: 18px;
    color: #60669f;
    gap: 4px;
    span {
      font-weight: 600;
      color: var(--color-primary);
    }
    .booking_date_value {
      color: #4A9D77;
    }
    .time-edit {
      max-width: 20px;
      margin-left: 8px;
      cursor: pointer;
      svg {
        max-width: 100%;
      }
    }
  }

  .btn-delete-all {
    display: inline-flex;
    align-items: center;
    padding: 4px 12px;
    min-width: 88px;
    height: 32px;
    background: #f0f2f7;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    color: var(--color-primary);
    margin-left: auto;
  }

  .empty-service {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #363565;
  }

  .list-detail-items {
    margin: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-height: 267px;
    max-height: 267px;
    overflow: auto;
    padding-right: 12px;
  }
`;
