import { ISagaFunc } from 'services/actionConfigs';
import actions from './actions';
import apis from './apis';
import { IApiDataWorkingHourParams, IApiDetailWorkingHourParams, IWorkingHourResData } from './types/workingHour';
import { call, delay, put, select, takeLatest, all } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { setLoading } from 'services/UI/sagas';
import { PATH_LOADING } from './constants';
import { getPureDetailParams, getPureParams } from './selectors';
import { ILocationItemResData } from 'features/loyaltyProgram/services/types/response';
import { ITeamMemberItemResData } from 'features/users/services/types/teamMember';
import uiActions from 'services/UI/actions';

const getData: ISagaFunc<IApiDataWorkingHourParams> = function* ({ payload }) {
  const params = { ...payload };
  yield setLoading(PATH_LOADING.getData, true);
  yield delay(500);
  try {
    const res: AxiosResponse<{ data: IWorkingHourResData }> = yield call(apis.getData, params);  
    
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getData.success(resData));
    }
  } catch (error) {

  } finally {
    yield setLoading(PATH_LOADING.getData, false);

  }
};

const setParams: ISagaFunc<IApiDataWorkingHourParams> = function* ({ payload }) {
  const storeParams = yield select(getPureParams);
  const _params = {
    ...storeParams ?? {},
    ...payload ?? {},
  };
  yield put(actions.getData.fetch(_params));
};

const getListLocations = function* () {
  yield delay(500);
  try {
    const res: AxiosResponse<{ data: ILocationItemResData[] }> = yield call(apis.getLocations);
    const resData = res?.data?.data;

    if (resData) {
      yield put(actions.getListLocations.success(resData));
    }
  } catch (error) {

  } finally {
  }
};

const getListTeamMembers = function* () {
  yield delay(500);
  try {
    const res: AxiosResponse<{ data: ITeamMemberItemResData[] }> = yield call(apis.getTeamMembers);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getListTeamMembers.success(resData));
    }
  } catch (error) { }
};

const initData = function* () {
  put(uiActions.setLoadingPage(true));

  yield all([
    put(actions.getListLocations.fetch()),
    put(actions.getListTeamMembers.fetch()),
  ]
  );
  put(uiActions.setLoadingPage(false));

};

const getDetailData: ISagaFunc<IApiDataWorkingHourParams> = function* ({ payload }) {
  put(uiActions.setLoadingPage(true));
  const params = { ...payload };
  yield delay(500);
  yield setLoading(PATH_LOADING.getDetailData, true);
  try {
    const res: AxiosResponse<{ data: IWorkingHourResData }> = yield call(apis.getData, params);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.detail.getData.success(resData));
    }
  } catch (error) {

  } finally {
    yield setLoading(PATH_LOADING.getDetailData, false);
    put(uiActions.setLoadingPage(false));

  }
};

const setDetailParams: ISagaFunc<IApiDetailWorkingHourParams> = function* ({ payload }) {
  const storeParams = yield select(getPureDetailParams);
  const _params = {
    ...storeParams ?? {},
    ...payload ?? {},
  };
  yield put(actions.detail.getData.fetch(_params));
};

export default function* workingHourServiceSaga() {
  yield takeLatest(actions.getData.fetch, getData);
  yield takeLatest(actions.setParams, setParams);
  yield takeLatest(actions.getListLocations.fetch, getListLocations);
  yield takeLatest(actions.getListTeamMembers.fetch, getListTeamMembers);
  yield takeLatest(actions.initData, initData);

  yield takeLatest(actions.detail.setParams, setDetailParams);
  yield takeLatest(actions.detail.getData.fetch, getDetailData);
}

