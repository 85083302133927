import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import settingActions from 'features/settings/services/actions';
import settingApis from 'features/settings/services/apis';
import { get } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from '../../pages/index.module.scss';

interface CancellationReasonItem {
  id: number;
  name: string;
  handleEdit: () => void;

}

const CancellationReasonItem = ({
  id,
  name,
  handleEdit,

}: CancellationReasonItem) => {

  const dispatch = useDispatch();

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const handleDelete = async (e: any, id: number) => {
    setIsDisabled(true);
    try {
      const rest: AxiosResponse<{ message: string }> = await settingApis.deleteCancellationReason(id);

      const msg = get(rest, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(settingActions.getListCancellationReason.fetch());
        setIsDisabled(false);
        return true;
      }
      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });

      return false;
    }
  };

  return (
    <>
      <div className={styles.item_content}>
        <p className={styles.item_label}>
          {name}
        </p>

      </div>
      <ul className='common-action'>
        <li className='action-edit' onClick={handleEdit}></li>
        <li className='action-remove'><button disabled={isDisabled} className='action-remove' style={{
          width: '100%',
          height: '100%'
        }} onClick={(e) => handleDelete(e, id)}></button></li>
      </ul>
    </>
  );
};

export default CancellationReasonItem;