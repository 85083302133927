// import { Switch } from 'antd';
import { IStaffFormInput } from '.';
import SwitchInput from 'components/common/Input/SwitchInput';

type Props = IStaffFormInput;

const StaffFormInputSwitch = ({
    // label,
    title,
    subTitle,
    name
}: Props) => {
    return (
            <div>
                <SwitchInput
                    label={title}
                    name={name}
                />
                <p className='form-note'>
                    {subTitle}
                </p>
            </div>
    );
};

export default StaffFormInputSwitch;