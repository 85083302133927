import { Checkbox, Form, FormInstance } from 'antd';
import dayjs from 'utils/dayjs';
import { get } from 'lodash';
import TimePickerHourElement from './TimePickerHourElement';

interface ActiveHoursElementProps {
  label?: string;
  name: string;
  form?: FormInstance;
}

const ActiveHoursElement = ({
  // onChange,
  label,
  name,
  form,
}: ActiveHoursElementProps) => {
    
  return (
    <>
      <div className='calendar-row'>
        <p className='calendar-label'>
          <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.additional !== curValues.additional}>
            {() => (
              <Checkbox onChange={(val) => {
                const workingTime = form?.getFieldValue('workingTime') ?? [];
                if (!workingTime) return;
                if (val.target.checked) {
                  form?.setFieldValue('workingTime', {
                    ...workingTime,
                    [name]: [{
                      time_start: dayjs('09:00:00', 'H:m:s'),
                      time_end: dayjs('18:00:00', 'H:m:s'),
                      weekday: name,
                    }]
                  });
                } else {
                  form?.setFieldValue('workingTime', { ...workingTime, [name]: [] });
                }
              }} checked={get(form?.getFieldValue('workingTime'), [name, 'length'], 0) > 0}>{label}</Checkbox>
            )}
          </Form.Item>
        </p>
        <div className='calendar-pick'>
          <ul className='list-pick'>
            <Form.List name={'workingTime'}>
              {() => (
                <>
                  <Form.List name={name} >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <>
                            <TimePickerHourElement weekday={name} key={index} remove={remove} field={field} index={index} />
                          </>
                        ))}
                        {fields.length < 3 && <span className='calendar-add' onClick={() => add()}></span>}

                      </>
                    )}
                  </Form.List>
                </>
              )}
            </Form.List>
          </ul>
        </div>
      </div >
    </>
  );
};

export default ActiveHoursElement;