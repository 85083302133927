export const NAME_REDUCER = 'customer';

export const PREFIX_ACTIONS = 'CUSTOMER_FEATURE_';

export const PATH_LOADING = {
  getListCustomers: `loading.${NAME_REDUCER}.getListCustomers`,
  getListCustomersPaginate: `loading.${NAME_REDUCER}.getListCustomersPaginate`,
  getCustomerDetail: `loading.${NAME_REDUCER}.getCustomerDetail`,
  getCustomerDetailAppointment: `loading.${NAME_REDUCER}.getCustomerDetailAppointment`,
  deleteCustomer: `loading.${NAME_REDUCER}.deleteCustomer`,
  createCustomer: `loading.${NAME_REDUCER}.createCustomer`,
};