import { Form } from 'antd';
import PrimaryButton from 'components/common/Buttons/PrimaryButton';
import dayjs from 'utils/dayjs';
import bookingActions from 'features/bookings/services/actions';
import { IApiCreateBookingOnlineBody, IBookingOnlineFormInfoValue } from 'features/bookings/services/types/bookingOnline';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import Section from '../Section';
import { BOOKING_FORM } from '../types';
import BookingDetails from './BookingDetails';
// import Footer from './Footer';
import GeneralInformationForm, { GeneralInformationRef } from './GeneralInformationForm';
import Header from './Header';
import ServicesForm from './ServicesForm';
import { first, get } from 'lodash';
import bookingSelectors from 'features/bookings/services/selectors';
import Footer from './Footer';
import settingActions from 'features/settings/services/actions';
import settingSelectors from 'features/settings/services/selectors';
import { useParams } from 'react-router-dom';
import { useBookingConfirmChecking } from '../Confirm';
import PopupConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import { TIME_START_FORMAT_RESPONSE } from 'features/bookings/services/constants';
import { formatTimeMinutes } from 'utils/unit';
import { Dayjs } from 'dayjs';

interface Props {
  isCheckIn?: boolean;
  errors: Record<string, string>;
  setFormActive: (val: BOOKING_FORM) => void;
}

const Information: React.FC<Props> = ({ isCheckIn = false, errors, setFormActive }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const informationRef = useRef<GeneralInformationRef>(null);
  const sectionGeneralInfoRef = useRef<HTMLDivElement>(null);
  const sectionServiceRef = useRef<HTMLDivElement>(null);
  const sectionDetailRef = useRef<HTMLDivElement>(null);
  const workingHour = bookingSelectors.getWorkingHour();
  const servicesStore = bookingSelectors.bookingOnline.getServices();
  const { location_id = '', merchant_code = '' } = useParams();
  const selected = bookingSelectors.bookingOnline.getSelectedServices();

  useEffect(() => {
    dispatch(settingActions.getListClosedDate.fetch());  
  }, []);

  const [_errors, setErrors] = useState<Record<string, string>>({});

  const handleConfirmCheckIn = useBookingConfirmChecking({ setErrors, setFormActive });

  useEffect(() => {
    dispatch(settingActions.getSettingBookingForm.fetch(merchant_code));
  }, []);

  const now = dayjs(new Date());

  const setting = settingSelectors.getSettingBookingForm();
  const closedDate = settingSelectors.getListClosedDate().filter((item: any) => now.diff(dayjs(item.start_time, 'YYYY-MM-DD'), 'days') <= 0);
  const modalConfirmRef = useRef<ModalConfirmRef>(null);


  const scrollToSection = (section: 'general' | 'services' | 'detail') => {
    const onScroll = () => {
      switch (section) {
        case 'general':
          sectionGeneralInfoRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        case 'services':
          sectionServiceRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        case 'detail':
          sectionDetailRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        default:
          break;
      }
    };
    setTimeout(onScroll, 10);
  };

  useEffect(() => {
    const [key = '', msg = ''] = first(Object.entries(errors)) ?? [];

    if (!key) return;

    const keyMapping = {
      'customer.phone_number': 'phoneNumber',
      'customer.name': 'name',
      'customer.email': 'email',
      'customer.date_of_birth': 'birthDate',
    };
    const field = get(keyMapping, [key]);
    setErrors({ [field]: msg });
    if (field) {
      if (['phoneNumber', 'name', 'email'].includes(field)) {
        scrollToSection('general');
      }
      if (field === 'service') {
        scrollToSection('services');
      }
      if (['bookingDate', 'bookingTime'].includes(field)) {
        scrollToSection('detail');
      }
    }
  }, [errors]);

  const checkValid = async () => {
    try {
      await form.validateFields([
        'phoneNumber',
        'service',
        'bookingDate',
        'bookingTime',
      ]);
      

      if(! isCheckIn) {
        const date = form.getFieldValue('bookingDate') as Dayjs;
        const time = form.getFieldValue('bookingTime') as Dayjs;
    
        if(!time || !date) return;
        const times = time.set('date', date.date()).set('month', date.month()).set('year', date.year());
  
        if(moment(times.format()).isBefore(moment().add(setting?.cancel_reschedule ?? 0, 'minutes'))) {
          scrollToSection('detail');
  
          form.setFields([
            {
              name: 'bookingTime',
              errors: [`Sorry for inconvenience. We will serve better if have a booking before ${formatTimeMinutes(setting?.cancel_reschedule ?? 0)}`]
            }
          ]);
          return false;
        }
      }
      
      return true;
    } catch (error) {
      const errorFields: { name: string[], errors: string[] }[] = get(error, 'errorFields', []);
      const field = get(first(errorFields), ['name', 0], '');

      if (['phoneNumber', 'name', 'email'].includes(field)) {
        scrollToSection('general');
      }
      if (field === 'service') {
        scrollToSection('services');
      }
      if (['bookingDate', 'bookingTime'].includes(field)) {
        scrollToSection('detail');
      }

      return false;
    }
  };


  const _onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();

    const isFormValid = await checkValid();
    if (!isFormValid) return;

    if(selected.length === 0 && isCheckIn && setting.customer_services ) {
      modalConfirmRef.current?.show({
        title: 'Confirmation',
        msg: 'Are you sure you do not want to select any services?',
        submit: () => form.submit()
      });
      return;
    }

    form.submit();
  };

  const handleSubmit = async (values: any) => {
    if (!values) return;
    const ruleValid = !values.name;
    if (ruleValid) {
      const isExist = await informationRef.current?.checkCustomerInfo();

      if (!isExist) {
        await form.validateFields(['name']);
        scrollToSection('general');
        return;
      } else {
        setTimeout(() => form.submit(), 0);
      }
      return;
    }

    const bookingDate = moment(values.bookingDate?.format());
    const bookingTime = moment(values.bookingTime?.format()).set({
      year: bookingDate.get('year'),
      month: bookingDate.get('month'),
      date: bookingDate.get('date'),
    });
    const payload = {
      ...values || {},
      birthDate: values.birthDate?.format(),
      bookingTime: bookingTime?.format(),
      bookingDate: bookingDate?.format(),
      notifyMarket: true,
      platform: 'all',
    } as IBookingOnlineFormInfoValue;
    dispatch(bookingActions.setBookingOnlineFormValues(payload));
    if (!isCheckIn) {
      setFormActive(BOOKING_FORM.CONFIRM);
    } else {
      const formValue = payload;

      const body: IApiCreateBookingOnlineBody = {
        book_start: moment(formValue?.bookingTime ?? '').format('YYYY-MM-DD HH:mm:ss'),
        customer: {
          phone_number: formValue?.phoneNumber ?? '',
          name: formValue?.name ?? '',
          email: formValue?.email ?? '',
          gender: formValue?.gender ?? 3,
          important_client_info: formValue?.importantClientInfo ?? null,
          date_of_birth: formValue?.birthDate ? moment(formValue?.birthDate).format('YYYY-MM-DD') : null,
          is_walkin_in: false
        },
        book_assignment_services: (formValue?.book_assignment_services ?? []).map(o => ({
          sale_price: o.sale_price,
          service_id: o.service_id,
          quantity: o.quantity,
          service_variant_id: o.service_variant_id,
          employee_id: o.employee_id,
          time_start: o.time_start,
          duration_time: o.duration_time
        })),
        note: formValue?.note ?? '',
        total_price: formValue?.totalPrice ?? 0,
        merchant_location_id: formValue?.location,
        book_date_current: moment().format(TIME_START_FORMAT_RESPONSE),

      };
      
      handleConfirmCheckIn(body);
    }
  };

  const initialValues = useMemo(() => {
    let bookingTime = moment().add(5, 'minute');
    let bookingDate = moment();
    const closeWorkingTime = moment().set({
      'hour': workingHour.close,
      'minute': 0,
      'second': 0,
    });
    const openWorkingTime = moment().set({
      'hour': workingHour.open,
      'minute': 0,
      'second': 0,
    });

    if (bookingTime > closeWorkingTime) {
      bookingTime = openWorkingTime;
      bookingDate = bookingDate.clone().add(1, 'days');
    } else if (bookingTime < openWorkingTime) {
      bookingTime = openWorkingTime;
    }

    return {
      phoneNumber: '',
      name: '',
      email: '',
      gender: 0,
      birthDate: null,
      teamMember: null,
      location: isCheckIn ? (+location_id ?? null) : null,
      bookingTime: dayjs(bookingTime.format()),
      bookingDate: dayjs(bookingDate.format()),
      note: '',
      service: '',
    };
  }, [location_id, isCheckIn]);  

  const sectionServices = () => {
    if (servicesStore.length == 0) return null;

    if (isCheckIn) {
      if (setting?.customer_services)
        return (
          <div ref={sectionServiceRef}>
            <Section label='Services'  >
              <ServicesForm isCheckIn />
            </Section>
          </div>
        );
      return null;
    }

    return (
      <div ref={sectionServiceRef}>
        <Section label='Services'  >
          <ServicesForm />
        </Section>
      </div>
    );
  };

  const headerContent = useMemo(() => {
    if (isCheckIn) {
      if (setting?.checkin_text) {
        return ({
          title: setting?.checkin_text?.header_text,
          text: setting?.checkin_text?.description_text,
        });
      }
    } else {
      if (setting?.booking_online_text) {
        return ({
          title: setting?.booking_online_text?.header_text,
          text: setting?.booking_online_text?.description_text,
        });
      }
    }

    return {
      title: undefined,
      text: undefined,
    };
  }, [isCheckIn, setting]);

  return (
    <InformationStyled>
      <div ref={sectionGeneralInfoRef} />
      <Header title={headerContent.title} text={headerContent.text} />
      <Form
        className='form-info'
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        scrollToFirstError
      >
        <Section label='General Information' >
          <GeneralInformationForm errors={_errors} ref={informationRef} isCheckIn={isCheckIn} />
        </Section>
        {!isCheckIn ? <div ref={sectionDetailRef}>
          <Section label='Booking Details'
            Footer={closedDate.length !== 0 ? <Footer closedDates={closedDate} /> : <></>}
          >
            <BookingDetails />
          </Section>
        </div> : <>
          {/* <Form.Item noStyle name={'bookingDate'} /> */}
          {/* <Form.Item noStyle name={'bookingTime'} /> */}
          <Form.Item noStyle name="location" />
        </>}
        {sectionServices()}
        <PrimaryButton type='button' className='primary_button' label={isCheckIn ? 'Check-in' : 'Continue'} onClick={_onSubmit} />
      </Form>
      <PopupConfirm ref={modalConfirmRef} />

    </InformationStyled >
  );
};

export default Information;

const InformationStyled = styled.div`
display: flex;
flex-direction: column;
margin-top: 16px;
width: 100%;
  .form-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    .primary_button {
      display: flex;
      align-self: center;
    }
}
.ant-form-item-extra {
  color:#ff4d4f;
}
`;