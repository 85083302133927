import { createAction } from '@reduxjs/toolkit';
import { IApiTimeScheduleListReq } from '../types/api';
import { PREFIX_ACTIONS } from './constants';
import { createAsyncAction } from 'services/actionConfigs';

const setListTimeScheduleParams = createAction<IApiTimeScheduleListReq>(PREFIX_ACTIONS + 'setListTimeScheduleParams');

const getListTimeSchedules = createAsyncAction<IApiTimeScheduleListReq>(PREFIX_ACTIONS + 'getListTimeSchedules');

const setTimeScheduleFilterOpts = createAction<any[]>(PREFIX_ACTIONS + 'setTimeScheduleFilterOpts');

const getInit = createAsyncAction(PREFIX_ACTIONS + 'getInit');

const timeScheduleActions = {
  setListTimeScheduleParams,
  getListTimeSchedules,
  getInit,
  setTimeScheduleFilterOpts
};

export default timeScheduleActions;
