import { uuidv4 } from '@antv/xflow-core';
import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
import { useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';

const useOpenPopover = (): [boolean, (val: boolean) => void] => {
  const dispatch = useAppDispatch();
  const activePopover = bookingSelectors.getActivePopover();
  const keyPopover = useMemo(() => uuidv4(), []);

  const setOpen = (val: boolean) => {
    if (val) {
      dispatch(bookingActions.setActivePopover(keyPopover));
    } else {
      dispatch(bookingActions.setActivePopover(null));
    }
  };
  return [activePopover === keyPopover, setOpen];
};

export default useOpenPopover;
