import fetch from 'services/request';
import { APIListInvoicesParams, IInvoiceRefundParam } from './types/invoice';

const apiGetInvoices = (params?: APIListInvoicesParams) => {
  return fetch({
    method: 'get',
    url: '/api/v1/merchants/:merchant_code/invoice/list',
    params,
  });
};

const getInvoiceDetail = (no_invoice: string) => {
  return fetch({
    method: 'get',
    url: '/api/v1/merchants/:merchant_code/invoice/detail/' + no_invoice,
  });
};

const sendInvoiceEmail = (invoice_id: string, customer_code: string, body: {email_customer: string, is_update_email?: boolean}) => {  
  return fetch({
    method: 'post',
    url: `/api/v1/merchants/:merchant_code/invoice/${invoice_id}/customer/${customer_code}/send-mail`,
    body: body as any
  });
};

const refundInvoice = (invoice_no: string, body: IInvoiceRefundParam) => {
  return fetch({
    method: 'post',
    url: `/api/v1/merchants/:merchant_code/invoice/${invoice_no}/refund`,
    body: body as any
  });
};

const apisInvoice = {
  apiGetInvoices,
  getInvoiceDetail,
  sendInvoiceEmail,
  refundInvoice
};

export default apisInvoice;