const IconTrendUp = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.6129 11.7903L3.70711 11.7071L6 9.415L7.29289 10.7071C7.65338 11.0676 8.22061 11.0953 8.6129 10.7903L8.70711 10.7071L11.5 7.915L12.2929 8.70711C12.8955 9.30968 13.9072 8.92767 13.994 8.11363L14 8L13.9983 4.94062L13.9893 4.85305L13.9643 4.73416L13.9401 4.65833L13.9063 4.57678L13.854 4.47929L13.7871 4.38298L13.7071 4.29286C13.6717 4.2575 13.6343 4.22531 13.5953 4.19633L13.4841 4.12467L13.3713 4.07123L13.266 4.03585L13.1485 4.01102L13.0892 4.00397L13 4H10C9.14783 4 8.70257 4.98551 9.21677 5.62253L9.29289 5.70711L10.085 6.5L8 8.585L6.70711 7.29289C6.34662 6.93241 5.77939 6.90468 5.3871 7.2097L5.29289 7.29289L2.29289 10.2929C1.90237 10.6834 1.90237 11.3166 2.29289 11.7071C2.65338 12.0676 3.22061 12.0953 3.6129 11.7903Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconTrendUp;
