import React from 'react';

function IconRoundedClose() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#F0F2F7" />
      <path d="M16.6673 16.6654L7.33398 7.33203" stroke="#363565" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M16.6673 7.33203L7.33398 16.6654" stroke="#363565" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}

export default IconRoundedClose;