import fetch from 'services/request';
import { IApiAddDateOff, IApiTimeScheduleListReq, IApiTimeShift } from '../types/api';

const BASE_URL = 'api/v1/merchants/:merchant_code/staffs';
const LOCATION_URL = 'api/v1/merchants/:merchant_code/location';

const getTeamMembers = () => {
  return fetch({
    method: 'get',
    url: BASE_URL,
    params: {
      per_page: Number.MAX_SAFE_INTEGER,
    }
  });
};

const getLocations = () => {
  return fetch({
    method: 'get',
    url: LOCATION_URL
  });
};


const getListTimeSchedule = (params?: IApiTimeScheduleListReq) => {
  const _params = { ...params };

  if (_params['per_page'] === undefined) {
    _params.per_page = 20;
  }

  return fetch({
    method: 'get',
    url: `${BASE_URL}/time-schedules`,
    params: _params
  });
};

const createDayOff = (body: IApiAddDateOff) => {
  return fetch({
    method: 'post',
    url: `${BASE_URL}/day-off`,
    body: body as any
  });
};

const editTimeShift = (employeeId: string | number, body: IApiTimeShift) => {
  return fetch({
    method: 'post',
    url: `${BASE_URL}/${employeeId}/edit-shift`,
    body: body as any
  });
};

const apisPromotion = {
  getListTimeSchedule,
  getTeamMembers,
  getLocations,
  createDayOff,
  editTimeShift
};

export default apisPromotion;
