import { CalendarViewType } from 'constants/index';
import { ICustomerItemResData } from 'features/customers/services/types/cutomer';
import { find, get } from 'lodash';
import moment from 'moment';
import uiSelector from 'services/UI/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { IDaysOfWeekItem } from 'utils/moment/getDatesOfWeek';
import { PATH_LOADING } from './constants';
import { IActiveMerchantLocation, IApiBookingParams, IMerchantLocationItemResData } from './types/booking';
import { IBookingOnlineFormInfoValue, IBookingOnlineFormPromotion, ILocationResDataItem, IServiceCategoryResDataItem, IServiceItemResData } from './types/bookingOnline';
import { IBookAssignmentsResponse, IBusyEmployeeItem, IServiceQuickBookingItem } from './types/quickBooking';
import { IActivity, IStateBookingItem, IStateBookingStatusItem, IStateTeamMemberBookingItem, IStateWorkingHour, IStateZoomViewOpts } from './types/reducer';
import { IServiceItem } from './types/service';
import { IPagination } from 'models/config';
import { VIEW } from 'constants/view.enum';

type MyState = RootState['booking'];

const getCurrentState = (state: RootState): MyState => state.booking;

export const getCalendarParams = (_state: RootState) => {
  const state = getCurrentState(_state);
  return state.calendarTable.params ?? {};
};

export const getLstTableParams = (_state: RootState) => {
  const state = getCurrentState(_state);
  return state.listTable.params ?? {};
};

export const geBookingLayoutPure = (_state: RootState) => {
  const state = getCurrentState(_state);
  return state.bookingLayout ?? {};
};

const selector = <KEY = keyof MyState>(key: KEY, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key as any, defaultValue));

const getBookings = () => (selector('bookings') ?? []) as (IStateBookingItem[]);

const getLstTableBookings = () => (selector('listTable.data') ?? []) as (IStateBookingItem[]);

const loadingBookings = () => uiSelector.getLoading(PATH_LOADING.getBookings);

const getTeamMembers = () => (selector('teamMembers') ?? []) as (IStateTeamMemberBookingItem[]);

const getActiveTeamMembers = () => useAppSelector(_state => {
  const state = getCurrentState(_state);
  const teamMembers = state.teamMembers ?? [];
  const employee_ids = state.calendarTable.params?.employee_id;
  if (!employee_ids) return teamMembers;

  return teamMembers.filter(o => employee_ids.includes(o.id));
});

const getLstBookingStatus = () => (selector('lstBookingStatus') ?? []) as (IStateBookingStatusItem[]);

const getLstMerchantLocations = () => (selector('merchantLocations') ?? []) as (IMerchantLocationItemResData[]);

const getDaysOfWeek = () => (selector('calendarTable.daysOfWeek') ?? []) as (IDaysOfWeekItem[]);

const getCalendarParamValue = (key: keyof IApiBookingParams) => selector(`calendarTable.params.${key}`) as IApiBookingParams[typeof key];

const getLstTableParamValue = (key: keyof IApiBookingParams) => selector(`listTable.params.${key}`) as IApiBookingParams[typeof key];

const getCalendarCurrentLocation = () => selector('calendarTable.activeLocation') as IActiveMerchantLocation | null;

export const getPureCalendarCurrentLocation = (root: RootState) => {
  const state = getCurrentState(root);
  return state?.calendarTable?.params?.merchant_location_id ?? '';
};

const getZoomView = () => selector('calendarTable.zoomView') as number;

const getZoomViewOptions = () => selector('calendarTable.zoomViewOptions') as IStateZoomViewOpts;

const getCalendarViewType = () => selector('calendarTable.viewType') as CalendarViewType;

const getCalendarViewPreviousDate = () => selector('calendarTable.previousDate') as number;

const getCalendarWorkingHour = () => selector('calendarTable.workingHour') as IStateWorkingHour;

const getPixelPerMinute = () => selector('calendarTable.pixelPerMinute') as number;

const getBookingLayout = () => {
  const layout = selector('bookingLayout');
  return layout === 'Gird' ? VIEW.GRID : selector('bookingLayout') as string;
};

const getCurrentTime = () => {
  const currentTime = selector('currentTime');
  return moment(currentTime);
};

export const getQuickBookingPureBookingDate = (_state: RootState) => {
  const state = getCurrentState(_state);
  return state.quickBooking.bookingTime ?? '';
};

export const getQuickBookingPureIsEdit = (_state: RootState) => {
  const state = getCurrentState(_state);
  return state.quickBooking.isEdit ?? '';
};

const bookingOnline = {
  getCategories: () => (selector('bookingOnline.categories') ?? []) as IServiceCategoryResDataItem[],
  getServices: () => (selector('bookingOnline.services') ?? []) as IServiceItemResData[],
  getLoading: () => uiSelector.getLoading(PATH_LOADING.getBookingOnlineData),
  getSelectedServices: () => (selector('bookingOnline.selectedServices') ?? []) as IServiceItem[],
  getLocations: () => (selector('bookingOnline.locations') ?? []) as IMerchantLocationItemResData[],
  getFormValues: () => selector('bookingOnline.formValue') as IBookingOnlineFormInfoValue | null,
  getSelectedLocation: () => {
    const locations = (selector('bookingOnline.locations') ?? []) as ILocationResDataItem[];
    const location = selector('bookingOnline.formValue.location');
    return find(locations, (o: ILocationResDataItem) => o.id === location);
  },
  getBookingTime: () => selector('bookingOnline.bookingTime') as { start: string, finish: string } | null,
  getActiveLocationId: () => selector('bookingOnline.merchant_location_id') as IMerchantLocationItemResData['id'] | null,
  getActiveLocation: () => {
    const id = selector('bookingOnline.merchant_location_id') as IMerchantLocationItemResData['id'] | null;
    const locations = bookingSelectors.bookingOnline.getLocations();
    return find(locations, (o: ILocationResDataItem) => o.id === id);
  },
  getPromotion: () => selector('bookingOnline.promotionActive') as IBookingOnlineFormPromotion
};

const quickBooking = {
  getSelectedServices: () => (selector('quickBooking.selectedServices') ?? []) as IServiceQuickBookingItem[],
  getInitialServices: () => (selector('quickBooking.initialServices') ?? []) as IServiceQuickBookingItem[],
  getBookingDate: () => selector('quickBooking.bookingTime') as string,
  getBookingStatus: () => selector('quickBooking.bookingStatus') as string,
  getCustomer: () => selector('quickBooking.customer') as ICustomerItemResData | null,
  getDataConfigs: () => selector('quickBooking.dataConfigs') as IBookAssignmentsResponse['data'] | null,
  getLoadingDataConfigs: () => uiSelector.getLoading(PATH_LOADING.getDataConfigs),
  getBookAssignmentCode: () => selector('quickBooking.dataConfigs.book_assignment_code', ''),
  getBusyEmployees: () => selector('quickBooking.busyEmployees') as IBusyEmployeeItem[],
  getMerchantLocationId: () => selector('quickBooking.merchant_location_id') as (string | number | null),
  getMerchantLocationActive: () => {
    const val = quickBooking.getMerchantLocationId();
    const dataConfigs = bookingSelectors.quickBooking.getDataConfigs();
    const locations = dataConfigs?.locations ?? [];
    return find(locations, o => o.value?.toString() === val?.toString());
  }
};

const getWorkingHour = () => selector('workingHour') as IStateWorkingHour;

const getListTablePagination = () => selector('listTable.pagination') as IPagination | null;

const getActivePopover = () => selector('activePopover') as string | null;

const getListActivity = () => selector('listActivity') as IActivity[];

const bookingSelectors = {
  getBookings,
  getLstTableBookings,
  getLstTableParamValue,
  loadingBookings,
  getTeamMembers,
  getActiveTeamMembers,
  getCalendarParamValue,
  getZoomView,
  getZoomViewOptions,
  getDaysOfWeek,
  getCalendarViewType,
  getLstBookingStatus,
  getLstMerchantLocations,
  getBookingLayout,
  bookingOnline,
  getCurrentTime,
  quickBooking,
  getCalendarViewPreviousDate,
  getWorkingHour,
  getCalendarCurrentLocation,
  getCalendarWorkingHour,
  getPixelPerMinute,
  getListTablePagination,
  getActivePopover,
  getListActivity,
};

export default bookingSelectors;
