import { Spin, Table } from 'antd';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import serviceActions from '../services/actions';
import serviceSelectors from '../services/selectors';
import { useEffect } from 'react';
// type ServiceItem = {
//   key: string;
//   serviceName: string;
//   category: string;
//   cost: string;
//   time: string;
//   status: string;
// };
const ServicesTable = ({ columns }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = serviceSelectors.getParams();

  useEffect(() => {
    // @ts-ignore
    setSearchParams(params ?? {});
  }, [params]);

  const dispatch = useDispatch();

  const data = serviceSelectors.getPaginateListServices();
  const loading = serviceSelectors.loadingStaffPaginateList();

  const dataSource: any = data?.data?.map((item: any) => ({
    ...item,
    status: item.enable ? 'active' : 'disable',
    category_title: item.category.name,
    category_color: item.category.color,
    sale_price: (item.sale_price === 0 ? item.regular_price : item.sale_price) ?? 0,
  }));

  return (
    <>
      <Spin spinning={loading}>
        <Table
          className={'custom-table'}
          dataSource={dataSource}
          columns={columns}
          scroll={{
            x: 900,
          }}
          pagination={{
            total: data?.pagination?.total_record,
            defaultCurrent: Number(searchParams.get('page') ?? 1),
            defaultPageSize: Number(searchParams.get('per_page') ?? 10),
            current: +(params.page ?? ''),
            onChange: (pageChange, sizeChange) => {
              const params: any = {
                page: pageChange == 0 ? 1 : pageChange,
                per_page: sizeChange,
                keyword: searchParams.get('keyword')
              };
              dispatch(serviceActions.setServiceListParams(params));
            },
            showSizeChanger: true,
            showTotal(total) {
              return `Total ${total} items`;
            },
          }}
        />
      </Spin>
    </>
  );
};

export default ServicesTable;
