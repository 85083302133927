import IconEye from 'assets/svg/IconEye';
import { IVoucherDataItem } from 'features/checkout/services/types/voucher';
import styled from 'styled-components';
import { formatMoney } from 'utils/unit';
type Props = {
  data: IVoucherDataItem,
  onView?: () => void;
};
const PurchasedItem = ({ data, onView = () => undefined }: Props) => {
  const { services_flatten_ids = [], voucher_code, name } = data ?? {};
  return (
    <PurchasedItemStyled>
      <div className='voucher_content'>
        <div className='group_name'>
          <div>
            <p className='voucher_name'>{name}</p>
            <p className='voucher_time'>
              Code: {voucher_code} - {services_flatten_ids.length > 0 ? services_flatten_ids.length : 'all'} services
            </p>
          </div>
        </div>
      </div>
      <div className='voucher_amount'>
        <p>{formatMoney(data.value)}</p>
      </div>
      <button type='button' onClick={onView}><IconEye /></button>
    </PurchasedItemStyled>
  );
};

export default PurchasedItem;
const PurchasedItemStyled = styled.div`
  display:flex;
  align-items:center;
  padding:10px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  border: 1px solid #E2E5FF;
  border-radius: 6px;
  button:hover {
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    background: #F0F2F7;
    border-radius: 6px;
  }
`;