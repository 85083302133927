import { Select } from 'antd';
import timeScheduleActions from 'features/timeSchedule/services/actions';
import timeScheduleSelectors from 'features/timeSchedule/services/selectors';
import { IApiTimeScheduleListReq } from 'features/timeSchedule/types/api';
import { memo, useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';

const LocationPicker = () => {
  const dispatch = useAppDispatch();
  const lstMerchantLocations = timeScheduleSelectors.getMerchantLocation();
  const value = (timeScheduleSelectors.getTimeScheduleParamValue('merchant_location_id') ?? 'All') as IApiTimeScheduleListReq['merchant_location_id'];

  const options = useMemo(() => {
    return lstMerchantLocations.map(item => {
      return ({
        value: item.id,
        label: item.name,
      });
    });
  }, [lstMerchantLocations]);

  const onChange = async (val: string | number | undefined) => {
    dispatch(timeScheduleActions.setListTimeScheduleParams({
      merchant_location_id: val,
      merchant_employee_id: undefined,
    }));
    return;
  };

  if (options.length < 1) return null;

  return (
    <Select
      value={value}
      options={options}
      onChange={onChange}
      className='is-normal'
    />
  );
};

export default memo(LocationPicker);
