import message from 'components/common/Message';
import loyalActions from 'features/loyaltyProgram/services/actions';
import apisLoyal from 'features/loyaltyProgram/services/apis';
import { IProgramFormValues, IProgramItemResData } from 'features/loyaltyProgram/services/types/program';
import { get } from 'lodash';
import { useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import { ProgramFormProps } from './Form';
import ProgramModal from './Modal';
type Props = {
  data: IProgramItemResData;
};
const ProgramEdit = ({ data }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState<IProgramFormValues>();

  const showModal = () => {
    setIsOpen(true);
    setFormData({
      amountSpent: data?.amount_spent,
      earnReward: data?.earn_point,
      location_ids: data?.location_id ? [data?.location_id] : [],
      minimumSpend: data?.minimum_spend,
      name: data?.name,
      points: data?.point
    });
  };
  const closeModal = () => {
    setIsOpen(false);
    setFormData(undefined);
  };

  const dispatch = useAppDispatch();

  const setPageLoading = useSetLoadingPage();

  const handleSubmit: ProgramFormProps['onSubmit'] = async (payload) => {
    setPageLoading(true);
    try {
      const res = await apisLoyal.updateProgram(data?.id?.toString(), payload);
      dispatch(loyalActions.getListLoyalProgram.fetch({}));
      const msg = get(res, 'data.message', '');
      if (msg) {
        closeModal();
        message.success(msg);
      } else {
        throw 'fail';
      }
    } catch (error: any) {
      const errorText = get(error, 'response.data.error.message', '');
      if (errorText) {
        message.error(errorText);
        return;
      }
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <>
      <li onClick={showModal} className='action-edit'></li>
      <ProgramModal isEdit visible={isOpen} onCancel={closeModal} onSubmit={handleSubmit} formData={formData} />
    </>
  );
};

export default ProgramEdit;
