import { AxiosResponse } from 'axios';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import apis from './apis';
import { setLoading } from 'services/UI/sagas';
import { PATH_LOADING } from './constants';
import actions from './actions';
import { get } from 'lodash';
import message from 'components/common/Message';
import { ILocationItemResData } from './types/response';
import { ILoyalSettingResData } from './types/setting';

const getListLoyalProgram = function* (payload: any) {
  try {

    const params = payload.payload;

    yield setLoading(PATH_LOADING.getLoyalProgram, true);
    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getListLoyalProgram, params);


    if (res?.data?.data) {
      const programList = res?.data?.data;
      yield put(actions.getListLoyalProgram.success(programList));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getListLoyalProgram.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getLoyalProgram, false);
  }
};

const getListLoyalReward = function* (payload: any) {
  try {
    const id = payload.payload;
    yield setLoading(PATH_LOADING.getLoyalReward, true);
    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getListLoyalReward, id);

    if (res?.data?.data) {
      const customerList = res?.data?.data;
      yield put(actions.getListLoyalReward.success(customerList));
    } else {
      const msgError = get(res.data, 'error.message');
      if (msgError) message.error(msgError);
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getListLoyalReward.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getLoyalReward, false);
  }
};

const getListLocations = function* () {
  try {
    const res: AxiosResponse<{ data: ILocationItemResData[] }> = yield call(apis.getLocations);
    if (res?.data?.data) {
      yield put(actions.getListLocations.success(res.data.data));
    } else throw 'fail';
  } catch (error) {
    yield put(actions.getListLocations.fail(error));
  }
};

const getListServices = function* () {
  try {
    const res: AxiosResponse<{ data: ILocationItemResData[] }> = yield call(apis.getServices);
    if (res?.data?.data) {
      yield put(actions.getListServices.success(res.data.data));
    } else throw 'fail';
  } catch (error) {
    yield put(actions.getListServices.fail(error));
  }
};

const getLoyalSetting = function* () {
  try {
    const res: AxiosResponse<{ data: ILoyalSettingResData }> = yield call(apis.getLoyalSetting);
    if (res?.data?.data) {
      yield put(actions.getLoyalSetting.success(res.data.data));
    } else throw 'fail';
  } catch (error) {
    yield put(actions.getLoyalSetting.fail(error));
  }
};

const getLoyalConfigs = function* () {
  yield all([
    put(actions.getListLocations.fetch()),
    put(actions.getListServices.fetch()),
    put(actions.getListLoyalProgram.fetch({})),
    put(actions.getListLoyalReward.fetch({})),
    put(actions.getLoyalSetting.fetch()),
  ]);
};

export default function* LoyalServiceSaga() {
  yield takeLatest(actions.getListLoyalProgram.fetch, getListLoyalProgram);
  yield takeLatest(actions.getListLoyalReward.fetch, getListLoyalReward);
  yield takeLatest(actions.getLoyalConfigs, getLoyalConfigs);
  yield takeLatest(actions.getListLocations.fetch, getListLocations);
  yield takeLatest(actions.getListServices.fetch, getListServices);
  yield takeLatest(actions.getLoyalSetting.fetch, getLoyalSetting);
}

