import IconFilter from 'assets/svg/IconFilter';
import bookingSelectors from 'features/bookings/services/selectors';
import { IApiBookingParams } from 'features/bookings/services/types/booking';
import useDetachScreen from 'hooks/useDetachScreen';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import getAddBookingLink from './getAddBookingLink';
import DateViewPicker from './widgets/DateViewPicker';
import FilterModal from './widgets/FilterModal';
import InputSearchText from './widgets/InputSearchText';
import LocationPicker from './widgets/LocationPicker';
import SelectLayout from './widgets/SelectLayout';
// import StatusPicker from './widgets/StatusPicker';
import TeamMemberSelect from './widgets/TeamMemberSelect';
import ZoomView from './widgets/ZoomStatus';
import IconTimeAndDate from 'assets/svg/IconTimeAndDate';

const CalendarFilter = () => {
  const navigate = useNavigate();
  const dateStore = bookingSelectors.getCalendarParamValue('date') as number;
  const merchant_location_id = (bookingSelectors.getCalendarParamValue('merchant_location_id') ?? 'all') as IApiBookingParams['merchant_location_id'];
  const onAdd = () => {
    navigate(getAddBookingLink(dateStore, merchant_location_id));
  };

  const { isTablet } = useDetachScreen();
  if (isTablet) return <MobileView onAdd={onAdd} />;

  return (
    <BrowserView onAdd={onAdd} />
  );
};

export default CalendarFilter;

type IChildViewProps = {
  onAdd?: () => void;
};
const BrowserView = ({ onAdd }: IChildViewProps) => {
  return (
    <>
      <div className='block-left desktop-view'>
        <LocationPicker />
        <TeamMemberSelect isCalendar />
      </div>
      <div className='block-right'>
        <div className='d-flex no-wrap'>
          <InputSearchText />
          <div className='desktop-view'>
            <DateViewPicker isNoDatePicker={true} />
          </div>
          <div className='desktop-view'>
            <ZoomView />
          </div>
          <SelectLayout />
        </div>
        <div className='quick-booking-btn'>
          <button
            className={'common-btn'}
            onClick={onAdd}
          >
            <p>Add Booking</p>
          </button>
        </div>
      </div>
      <div className='booking-grid-block tablet-desktop-view is-full'>
        <div className='block-heading no-border'>
          <div className='heading-date'>
            <DateViewPicker isNoSelect={true} />
          </div>
        </div>
      </div>
    </>
  );
};


const MobileView = ({ onAdd }: IChildViewProps) => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  return <MobileViewStyled className='MobileViewStyled'>
    <div className='block-left desktop-view'>
      <LocationPicker />
      <TeamMemberSelect isCalendar />
    </div>
    <div className='block-right'>
      <div className='d-flex no-wrap'>
        <button
          className='filter-btn tablet-mobile-view'
          onClick={() => setIsShowModal(true)}
        >
          <IconFilter />
        </button>
        <div className='desktop-view'>
            <DateViewPicker isNoDatePicker={true} />
          </div>
        {/* <div className='desktop-view'>
          <LocationPicker />
        </div> */}
        {/* <div className='desktop-view'>
          <StatusPicker />
        </div> */}
        <div className='desktop-view'>
          <ZoomView />
        </div>
        <SelectLayout />
      </div>
      <div className='quick-booking-btn'>
        <button
          className={'common-btn'}
          onClick={onAdd}
        >
          <p>Add Booking</p>
          <IconTimeAndDate />
        </button>
      </div>
    </div>
    <InputSearchText />
    <div className=' item-full'>
      <DateViewPicker isNoSelect={true} />
    </div>
    <FilterModal isShow={isShowModal} onClose={() => setIsShowModal(false)} />
  </MobileViewStyled>;
};

const MobileViewStyled = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  gap:12px;
  .common-input-search {
    width:100%;
    input {
      width:100%;
    }
  }
  .item-full {
    text-align: center;
  }
`;
