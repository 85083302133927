import { createSlice } from '@reduxjs/toolkit';
import { NAME_REDUCER } from './constants';
import actions from './actions';
import { ITeamMemberItemResData } from './types/teamMember';
import { IState } from './types/reducer';

const initialState: IState = {
  teamMembers: [],
  staffPaginateList: [],
  staffShowCreate: null,
  staffServices: [],
  staffDetail: null,
  profileStaffDetail: null,
  locationSelectedId: '',
  params: {}
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.setParams, (state, { payload }) => {
        const _params = {
          ...state.params ?? {},
          ...payload ?? {},
        };

        if (_params['keyword'] === undefined) {
          delete _params.keyword;
        }

        state.params = _params;
      })
      .addCase(actions.getTeamMembers.success, (state, { payload }) => {
        const data = payload as ITeamMemberItemResData[];
        state.teamMembers = data;
      })
      .addCase(actions.getListPaginateStaffs.success, (state, { payload }) => {
        const data: any[] = payload;
        state.staffPaginateList = data;
      })
      .addCase(actions.getShowCreateStaffs.success, (state, { payload }) => {
        const data: any[] = payload;
        state.staffShowCreate = data;
      })
      .addCase(actions.setStaffServices, (state, { payload }) => {
        // const data: IServiceItemResData[] = payload;
        const data: any[] = payload;
        state.staffServices = data;
      })
      .addCase(actions.setStaffDetail, (state, { payload }) => {
        const data: ITeamMemberItemResData = payload;
        state.staffDetail = data;
      })
      .addCase(actions.getProfileStaffDetail.success, (state, { payload }) => {
        const data = payload as any[];
        state.profileStaffDetail = data;
      })
      .addCase(actions.setLocationSelectedId, (state, { payload }) => {
        const data = payload as any;
        state.locationSelectedId = data;
      });
  },
});
const userServiceReducer = Slice.reducer;
export default userServiceReducer;
