
interface ButtonFavoriteProps {
  isFavourite?: boolean;
  handleSwitchFavourite?: () => void;
}

const ButtonFavorite = ({ isFavourite, handleSwitchFavourite }: ButtonFavoriteProps) => {
  return (
    <li
      onClick={handleSwitchFavourite}
      className={`action-favorite ${isFavourite && 'is-active'}`}
    ></li>
  );
};

export default ButtonFavorite;
