import { useAppDispatch } from 'store/hooks';
import { IServiceQuickBookingItem } from '../services/types/quickBooking';
import bookingSelectors from '../services/selectors';
import bookingActions from '../services/actions';
import { ICustomerItemResData } from 'features/customers/services/types/cutomer';
import moment from 'moment';

export const useSelectedServices = (): [IServiceQuickBookingItem[], (a: IServiceQuickBookingItem[]) => void] => {
  const dispatch = useAppDispatch();

  const data = bookingSelectors.quickBooking.getSelectedServices().sort((o1, o2) => moment(o1.time_start).diff(moment(o2.time_start)) );
  const setData = (a: IServiceQuickBookingItem[]) => {
    dispatch(bookingActions.quickBooking.setServiceLst(a));
  };
  return [data, setData];
};

export const useSetDateBooking = (): [string, (a: string) => void] => {
  const dispatch = useAppDispatch();

  const data = bookingSelectors.quickBooking.getBookingDate();

  const setData = (a: string) => {
    dispatch(bookingActions.quickBooking.setBookingDate(a));
  };

  return [data, setData];
};
type Customer = ICustomerItemResData | null;
export const useSetCustomerBooking = (): [Customer, (a: Customer) => void] => {
  const dispatch = useAppDispatch();
  

  const data = bookingSelectors.quickBooking.getCustomer();

  const setData = (a: Customer) => {
    dispatch(bookingActions.quickBooking.setCustomer(a));
  };

  return [data, setData];
};

const quickBookingHooks = {
  useSelectedServices,
  useSetDateBooking,
  useSetCustomerBooking,
};

export default quickBookingHooks;
