const IconSpa = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9984 22C10.4318 21.8 9.03177 21.4 7.79844 20.8C6.5651 20.2 5.5191 19.4167 4.66044 18.45C3.80244 17.4833 3.14844 16.3623 2.69844 15.087C2.24844 13.8123 2.02344 12.4 2.02344 10.85C3.85677 11.0333 5.40677 11.3667 6.67344 11.85C7.9401 12.3333 8.96944 13.0167 9.76144 13.9C10.5528 14.7833 11.1234 15.8873 11.4734 17.212C11.8234 18.5373 11.9984 20.1333 11.9984 22ZM11.9984 13.575C11.6151 12.9917 11.0944 12.4167 10.4364 11.85C9.77777 11.2833 9.0151 10.7667 8.14844 10.3C8.24844 9.6 8.4151 8.875 8.64844 8.125C8.88177 7.375 9.1651 6.63733 9.49844 5.912C9.83177 5.18733 10.2108 4.49167 10.6354 3.825C11.0608 3.15833 11.5151 2.55 11.9984 2C12.4818 2.55 12.9361 3.15833 13.3614 3.825C13.7861 4.49167 14.1651 5.18733 14.4984 5.912C14.8318 6.63733 15.1151 7.375 15.3484 8.125C15.5818 8.875 15.7484 9.6 15.8484 10.3C14.9818 10.75 14.2191 11.2583 13.5604 11.825C12.9024 12.3917 12.3818 12.975 11.9984 13.575ZM13.9984 21.6C13.9651 20.4333 13.8778 19.3543 13.7364 18.363C13.5944 17.371 13.3734 16.4333 13.0734 15.55C13.8568 14.2 14.9358 13.1 16.3104 12.25C17.6858 11.4 19.5734 10.9333 21.9734 10.85C21.9901 13.4833 21.2861 15.754 19.8614 17.662C18.4361 19.5707 16.4818 20.8833 13.9984 21.6Z"
        fill="#363565"
      />
    </svg>
  );
};

export default IconSpa;
