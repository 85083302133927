import fetch from 'services/request';
import { IApiDataWorkingHourParams, IBodyWorkingHour } from './types/workingHour';

const getData = (params: IApiDataWorkingHourParams) => {
  return fetch({
    url: 'api/v1/merchants/:merchant_code/staffs/total-analytic',
    method: 'get',
    params,
  });
};
const getLocations = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/location'
  });
};

const updateWorkingTime = (merchant_employee_id: number, body: IBodyWorkingHour) => {
  return fetch({
    method: 'post',
    url: `api/v1/merchants/:merchant_code/staffs/${merchant_employee_id}/update-time-working`,
    body: body as any
  });
};

const getTeamMembers = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/staffs',
    params: {
      per_page: Number.MAX_SAFE_INTEGER,
    }
  });
};

const apisWorkingData = {
  getData,
  getLocations,
  getTeamMembers,
  updateWorkingTime,
};

export default apisWorkingData;
