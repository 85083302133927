import {
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
} from 'antd';
import IconClose from 'assets/svg/IconClose';
import FormToggleBlock from 'components/common/Form/FormToggleBlock';
import MultipleSelect, { IOptionMulti } from 'components/common/MultipleSelect';
import SYSTEM_VARIABLES from 'constants/variables';
import { first, get } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import storage from 'utils/sessionStorage';
import { formOptionalText } from 'utils/unit';
import SwitchInput from '../../../../components/common/Input/SwitchInput';
import styles from '../../pages/index.module.scss';
import serviceSelectors from '../../services/selectors';
import FormBlock from './FormBlock';
import formatNumber from 'utils/formatNumber';
import styled from 'styled-components';
export interface IServiceForm {
  service_name?: string;
  category?: number;
  price?: number;
  availableLocation?: number | number[];
  locationTemp: number[];
  newCategory?: string;
  color: string;
  teamMember?: number[];
  teamMemberTemp: number[];
  duration?: number;
  isEnableService?: boolean;
  isEnableBookingLink?: boolean;
  isEnableVoucherSales?: boolean;
  isEnableDiscounts?: boolean;
  isMakeNewCategory?: boolean;
  serviceDescription?: string;
  serviceVariants?: IServiceVariant[];
}

interface IServiceVariant {
  name?: string;
  price?: number;
  duration?: number;
}

type IEmployeeItem = {
  id: string | number;
  title_name: string;
  merchant_location_id: string;
  employee_id: number;
  user: IUser;
};

interface IUser {
  id: number;
  full_name: string;
  email: string;
  phone: string;
}

export type IFuncHandleSubmit = (value: IPayloadReturnType) => void;
interface ServicesFormProps {
  isModalOpen?: boolean;
  handleCancel?: () => void;
  handleSubmit: IFuncHandleSubmit;
  currService?: any;
}

const ServicesForm = ({
  isModalOpen,
  handleCancel,
  handleSubmit,
  currService,
}: ServicesFormProps) => {
  const [form] = Form.useForm<IServiceForm>();
  const durations = serviceSelectors.getDurations();
  const [isExtra, setIsExtra] = useState<boolean>(false);

  const dataForm = serviceSelectors.getShowCreateService();

  const locations: IOptionMulti[] = useMemo(
    () =>
      (dataForm?.locations as { id: string | number; name: string }[])
        ?.filter((o) => o.id != 99999)
        ?.map((o) => ({ value: o.id, title: o.name })) ?? [],
    [dataForm?.locations]
  );
  const [selectedLocations, setSelectedLocations] = useState<any[]>(
    locations.map(({ value }) => value)
  );

  const employees: IOptionMulti[] = useMemo(
    () =>
      (dataForm?.employees as IEmployeeItem[])
        ?.filter((o) => selectedLocations.includes(o.merchant_location_id))
        ?.map((o) => ({ value: o.id, title: o.user.full_name })) ?? [],
    [dataForm?.employees, selectedLocations]
  );

  const categories: IOptionMulti[] = useMemo(() => (
    dataForm?.categories.filter((o: any) => o.location_ids.find((i: any) => selectedLocations.includes(i))).map((o: any) => ({ value: o.id, label: o.name }))
  ), [dataForm?.categories, selectedLocations]);

  const [selectedEmployees, setSelectedEmployees] = useState<any[]>(employees.map(({ value }) => value));



  useEffect(() => {
    if (!currService) return;
    form.setFieldsValue(currService);
    setSelectedLocations(currService.availableLocation);
    setSelectedEmployees(currService.teamMember);
  }, [currService]);

  useEffect(() => {
    form?.setFieldValue('availableLocation', [...selectedLocations]);
    if (locations.length > 1 && selectedLocations.length === locations.length) {
      form?.setFieldValue('locationTemp', [SYSTEM_VARIABLES.ALL_LOCATIONS]);
    } else {
      form?.setFieldValue('locationTemp', [...selectedLocations]);
    }
  }, [selectedLocations]);

  useEffect(() => {
    form?.setFieldValue('teamMember', [...selectedEmployees]);
    if (employees.length > 1 && selectedEmployees.length === employees.length) {
      form?.setFieldValue('teamMemberTemp', [SYSTEM_VARIABLES.ALL_EMPLOYEES]);
    } else {
      form?.setFieldValue('teamMemberTemp', [...selectedEmployees]);
    }
  }, [selectedEmployees]);

  const checkValid = async () => {
    try {
      await form.validateFields([]);
      return true;
    } catch (error) {
      return false;
    }
  };

  const _onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    const isFormValid = await checkValid();
    if (!isFormValid) return;
    form.submit();
  };

  const _handleSubmit = (values: IServiceForm) => {
    const payload: IPayloadReturnType = getPayload(values);
    handleSubmit(payload);
  };

  const initialValues = useMemo(() => {
    const result = {
      isMakeNewCategory: false,
      isEnableService: currService?.isEnableService === 0 ? false : true,
      isEnableBookingLink: currService?.isEnableBookingLink === 0 ? false : true,
      isEnableVoucherSales: currService?.isEnableVoucherSales === 0 ? false : true,
      isEnableDiscounts: currService?.isEnableDiscounts === 0 ? false : true,
      isNewCategory: false,
      serviceVariants: [],
    };

    if (locations.length === 0) {
      form.setFieldValue('locationTemp', [SYSTEM_VARIABLES.ALL_LOCATIONS]);
      form.setFieldValue('availableLocation', SYSTEM_VARIABLES.ALL_LOCATIONS);
      form.setFieldValue('teamMemberTemp', [SYSTEM_VARIABLES.ALL_EMPLOYEES]);
      form.setFieldValue('teamMember', SYSTEM_VARIABLES.ALL_EMPLOYEES);
    }

    return result;
  }, [locations]);

  const myFocus = useRef<any>();
  // const [value, setValue] = useState<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);

  return (
    <Modal
      maskClosable={false}
      open={isModalOpen}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      footer={null}
      width={'100%'}
      forceRender
      centered
      className='modal-big'
    >
      {/* <h2 className='modal-title'>
          {page < 3 && <p>New Service</p>}
          {page === 3 && <p>Advanced Settings</p>}
          {page > 3 && <p>New Team member</p>}
        </h2> */}
      <Form
        name='basic'
        className='modal-form'
        form={form}
        onFinish={_handleSubmit}
        autoComplete='off'
        layout='vertical'
        initialValues={initialValues}
      >
        <div className='form-inner is-primary'>
          <FormBlock label={'Service settings'}>
            <Form.Item
              label={'Service name'}
              name={'service_name'}
              rules={[
                {
                  validator: (_, value) =>
                    value?.trim() ? Promise.resolve() : Promise.reject(new Error('Service name is required')),
                },
              ]}
            >
              <Input ref={myFocus} placeholder={'Enter service name'}></Input>
            </Form.Item>

            {!isExtra ? (
              <div className='form-row'>
                <Form.Item
                  label='Price'
                  name='price'
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value == undefined || value == null) return Promise.reject(new Error('Price is required'));
                        if (value <= 0) return Promise.reject(new Error('Price must be greater than 0'));
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputNumber addonBefore='$' />
                </Form.Item>
                <Form.Item
                  label='Duration'
                  name='duration'
                  rules={[
                    { required: true, message: 'Duration Time is required' },
                  ]}
                >
                  <Select options={durations} placeholder={'Select Duration'} />
                </Form.Item>
              </div>
            ) : (
              <></>
            )}

            <Form.List name='serviceVariants'>
              {(fields, { add, remove }) => {
                return (
                  <>
                    {setIsExtra(fields.length != 0)}

                    {fields.map((field, index) => (
                      <div key={field.key}>
                        <div className={styles.form_extra}>
                          <div className='form-row'>
                            <Checkbox checked onClick={() => remove(field.name)} />
                            {isExtra && (
                              <p className='form-label is-center'>
                                {`PRICING OPTION ${index + 1}`}
                              </p>
                            )}
                            <button
                              style={{ marginLeft: 'auto' }}
                              type='button'
                              onClick={() => remove(field.name)}
                            >
                              <IconClose />
                            </button>
                          </div>
                          <div className='form-row'>
                            <Form.Item
                              label='Price'
                              name={[index, 'price']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Price is required',
                                },
                              ]}
                            >
                              <InputNumber
                                addonBefore='$'
                                inputMode='numeric'
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                              />
                            </Form.Item>
                            <Form.Item
                              label='Duration'
                              name={[index, 'duration']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Duration is required',
                                },
                              ]}
                            >
                              <Select
                                options={durations}
                                placeholder={'Select Duration'}
                              />
                            </Form.Item>
                          </div>
                          <Form.Item
                            label={'Service Name'}
                            name={[index, 'name']}
                            rules={[{ required: true }]}
                          >
                            <Input placeholder={'Enter extra name'}></Input>
                          </Form.Item>
                        </div>
                        {index != fields.length - 1 ? (
                          <Divider></Divider>
                        ) : (
                          <></>
                        )}
                      </div>
                    ))}
                    <Form.Item>
                      <div className='form-change-view'>
                        <span onClick={() => add()}>Add extras</span>
                      </div>
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
          </FormBlock>
          <FormBlock label={''}>
            {/* <div className='form-row items-end'>
              <CategoryInput
                isNewCategory={isNewCategory}
                options={dataForm?.service_categories?.map((item: any) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </div> */}
            {/* <SwitchInput
              label='Make new Category'
              name='isMakeNewCategory'
              onChange={handleSwitchCategory}
            /> */}

            <FormBlock label='Locations'>
              <>
                <Form.Item
                  label='Available Location'
                  name='availableLocation'
                  rules={[{ required: true }]}
                >
                  {locations.length > 0 ? (
                    <MultipleSelect
                      onChange={(arr) => {
                        setSelectedLocations(arr);
                        form?.setFieldValue('teamMember', []);
                      }}
                      value={selectedLocations}
                      options={locations}
                      placeholder='Available Locations'
                      maxTagPlaceholder='locations'
                    />
                  ) : (
                    <Select
                      options={[
                        {
                          label: 'All locations',
                          value: SYSTEM_VARIABLES.ALL_LOCATIONS,
                        },
                      ]}
                    />
                  )}
                </Form.Item>
                <Form.Item name={'locationTemp'} noStyle></Form.Item>
              </>
            </FormBlock>
            <FormToggleBlock label='Category'>
              <>
                <Form.Item
                  label='Select category'
                  name='category'
                  rules={[
                    { required: true }
                  ]}>
                  <Select
                    placeholder='Select category'
                    options={categories}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <p>
                      Service description <span>{formOptionalText}</span>
                    </p>}
                  name='serviceDescription'
                >
                  <Input placeholder='Description of this services' />
                </Form.Item>
              </>
            </FormToggleBlock>

            <FormToggleBlock label='Team members'>
              <>
                <Form.Item
                  label='Team Member'
                  name='teamMember'
                  rules={[{ required: true }]}
                >
                  {employees.length > 0 ? (
                    <MultipleSelect
                      onChange={setSelectedEmployees}
                      value={selectedEmployees}
                      options={employees}
                      placeholder='Available Employees'
                      maxTagPlaceholder='employees'
                    />
                  ) : (
                    <Select
                      disabled={employees?.length === 0}
                      options={[
                        {
                          label: 'All employees',
                          value: SYSTEM_VARIABLES.ALL_EMPLOYEES,
                        },
                      ]}
                    />
                  )}
                </Form.Item>
                <Form.Item name={'teamMemberTemp'} noStyle></Form.Item>
              </>
            </FormToggleBlock>
            <SwitchInputStyled>
              <SwitchInput label='Enable Service' name='isEnableService' />
              <SwitchInput
                label='Enable Booking Online'
                name='isEnableBookingLink'
              />
              <SwitchInput
                label='Enable Voucher Sales'
                name='isEnableVoucherSales'
              />
              <SwitchInput label='Enable Discounts' name='isEnableDiscounts' />
            </SwitchInputStyled>
          </FormBlock>
        </div>
        <div className='form-submit-wrapper'>
          <button onClick={handleCancel} type='reset' className='common-btn is-white'>
            Cancel
          </button>
          <button className='common-btn' onSubmit={_onSubmit}>
            Save
          </button>
        </div>
      </Form>
    </Modal>
  );
};

export default ServicesForm;

const SwitchInputStyled = styled.div`
@media only screen and (max-width: 767.98px) {
  display: grid;
  gap: 8px;
}
`;

const getPayload = (values: IServiceForm) => {
  const firstVariant = first(values?.serviceVariants);
  const servicePrice =
    get(firstVariant, 'price', 0) != 0
      ? get(firstVariant, 'price', 0)
      : values.price;
  const serviceDuration =
    get(firstVariant, 'duration', 0) != 0
      ? get(firstVariant, 'duration', 0)
      : values.duration;

  const merchant_id = storage.merchantId.get() ?? '';
  const service_category_id = values?.category;

  const merchant_location_id =
    values?.locationTemp?.length > 0
      ? values?.locationTemp
      : values?.availableLocation;
  const regular_price = formatNumber(servicePrice);
  const sale_price = formatNumber(servicePrice);
  const name = values?.service_name;
  const duration_time = serviceDuration;
  const enable = !!values?.isEnableService;

  const enable_online_booking = !!values?.isEnableBookingLink;
  const enable_voucher_sale = !!values?.isEnableVoucherSales;
  const enable_discount = !!values?.isEnableDiscounts;
  const checkNewCategory = values?.isMakeNewCategory;
  const checkNewExtraService =
    values?.serviceVariants?.length != 0 ? true : false;

  const emoloyee_service_id =
    values.teamMemberTemp.length > 0
      ? values?.teamMemberTemp
      : values?.teamMember;
  const service_variants = values?.serviceVariants?.map(
    (item: IServiceVariant) => ({
      extra_name: item?.name,
      price: formatNumber(item?.price),
      duration: item?.duration,
    })
  );

  const name_category = values?.newCategory;
  const color_id = values.color ?? 1;

  const description = values?.serviceDescription ?? '';

  return {
    merchant_id,
    service_category_id,
    merchant_location_id,
    regular_price,
    sale_price,
    name,
    duration_time,
    enable,
    enable_online_booking,
    enable_voucher_sale,
    enable_discount,
    checkNewCategory,
    checkNewExtraService,
    emoloyee_service_id,
    service_variants,
    name_category,
    color_id,
    description,
  };
};

export type IPayloadReturnType = ReturnType<typeof getPayload>;
