import CustomErrorMessage from './CustomError';
import CustomInfoMessage from './CustomInfo';
import CustomSuccessMessage from './CustomSuccess';
import CustomWarningMessage from './CustomWarning';

const message = {
  error: CustomErrorMessage,
  success: CustomSuccessMessage,
  warning: CustomWarningMessage,
  info: CustomInfoMessage
};

export default message;