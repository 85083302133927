import { Form } from 'antd';
import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import LocationForm from '../components/LocationForm';
import apisLocation from '../services/apis';
import { DayLocation, ILocationForm } from '../services/types/location';

const LocationAddNewPage = () => {
  // const [page, setPage] = useState(1);

  const [form] = Form.useForm();
  const navigate = useNavigate();


  const getPayload = (values: ILocationForm) => {
    const manager_id = values.manager;
    const name = values.locationName;
    const address = values.address;
    const contact_code = values.phoneNumberCode;
    const contact_number = values.locationPhoneNumber;
    const description = values.description;
    const status = true;
    const abn = values.business_abn;
    const weekWorkingTime: any = [];
    Object.values(values.workingTime).forEach((workTime) => {
      workTime.forEach((element: any) => {
        weekWorkingTime.push(element);
      });
    });

    const weekday_working_times = weekWorkingTime.map((item: DayLocation) => ({
      weekday: item?.weekday,
      time_start: item?.time_start?.format('HH:mm:ss'),
      time_end: item?.time_end?.format('HH:mm:ss'),
    }));

    return {
      manager_id,
      name,
      address,
      contact_code,
      contact_number,
      weekday_working_times,
      status,
      business_abn:values.business_abn,
      description,
      abn
    };
  };



  const handleSubmit = async (values: any) => {
    try {
      const payload = getPayload(values);
      const rest: AxiosResponse<{ data: { message: string } }> =
        await apisLocation.storeLocation(payload);

      const msg = get(rest, 'data.data.message', '');
      if (msg) {
        message.success(rest.data.data.message);

        navigate(-1);
      }
      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });
      return false;
    }
  };


  return (
    <>
      <LocationForm form={form} handleSubmit={handleSubmit} />
    </>
  );
};

export default LocationAddNewPage;
