import React from 'react';
import styled from 'styled-components';
export type IBoxSummaryItemProps = {
  label: string;
  value: number | string;
};
const BoxSummaryItem: React.FC<IBoxSummaryItemProps> = ({ label = '', value = 0 }) => {
  return (
    <BoxSummaryItemStyled>
      <p className='label'>{label}</p>
      <p className='value'>{value}</p>
    </BoxSummaryItemStyled>
  );
};

export default BoxSummaryItem;
const BoxSummaryItemStyled = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 4px 24px 0px rgba(225, 225, 239, 0.60);
  .label {
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    @media only screen and (max-width: 992px) {
      font-size: 12px;
    }
  }
  .value {
    color: var(--color-primary);
    text-wrap: nowrap;
    font-size: 28px;
    font-weight: 600;
    line-height: 22px;
  }
`;
