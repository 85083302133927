import React from 'react';
import { Form, Input, message } from 'antd';
import apisPayment from '../service/apis';
// import { AxiosResponse } from 'axios';
// import { loadStripe } from '@stripe/stripe-js';
import CryptoJS from 'crypto-js';
import { loadStripe } from '@stripe/stripe-js';
// import StripePaymentForm from './PaymentsPage';
// import PaymentForm from './PaymentForm';

const PaymentPage = () => {
    const [form] = Form.useForm();

    const getPayload = (data: any) => {
        const number = data.number ?? '';
        const exp_month = data?.exp_month;
        const exp_year = data?.exp_year ?? '';
        const cvc = data?.cvc ?? '';

        const payload: any = {
            number,
            exp_month,
            exp_year,
            cvc,
        };
        return payload;
    };

    const stripePromise = loadStripe('pk_test_51MqdWcBtLGQGXwvdQ26v2J9umgYbpiD4meXh41FIqQlNVXL7WyOuoRFH5R9NhYRHbqeJd6V47HPDz4bGtv9mpCY900dH7QcCmL');

    const handleSubmit: any = async (data: any) => {
        const payload = getPayload(data);
        const stripe = await stripePromise;
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(payload), '123456').toString();
        console.log('ciphertext', ciphertext);
        if (stripe) {
            const { token, error } = await stripe.createToken(payload);
            console.log('token', token);
            console.log('error', error);
        } else {
            console.log('Stripe is not available');
        }


        try {
            const rest: any = await apisPayment.setPayment(ciphertext);
            console.log('rest', rest);

            if (rest.data) {
                message.success(rest?.data?.message);
                return true;
            }
        } catch (error) {
            message.error('Error');
            return true;
        }
    };

    return (
        <>
            {/* <PaymentForm /> */}
            {/* <StripePaymentForm /> */}
            <Form
                form={form}
                layout='vertical'
                onFinish={handleSubmit}
            >
                <Form.Item
                    label='Number'
                    name='number'
                    rules={[{ required: true }]}
                >
                    <Input autoFocus />
                </Form.Item>
                <Form.Item
                    label='Month'
                    name='exp_month'
                    rules={[{ required: true }]}
                >
                    <Input autoFocus />
                </Form.Item>
                <Form.Item
                    label='Year'
                    name='exp_year'
                    rules={[{ required: true }]}
                >
                    <Input autoFocus />
                </Form.Item>
                <Form.Item
                    label='cvc'
                    name='cvc'
                    rules={[{ required: true }]}
                >
                    <Input autoFocus />
                </Form.Item>
                <button type='submit' className='common-btn'>
                    pay
                </button>
            </Form>
        </>
    );
};

export default PaymentPage;