import { Form, Input, InputNumber, Radio } from 'antd';
import { useTranslation } from 'react-i18next';

const FormFirstStep = () => {
  const { t: formLang } = useTranslation('form');

  return (
    <>
      <h2 className='modal-title'>Refund details</h2>
      <div className='modal-content'>
        <Form layout='vertical'>
          <div className='refund-heading'>
            <p>TOTAL payment</p>
            <p className='heading-price'>$44</p>
          </div>
          <Form.Item label='Enter value for refund'>
            <InputNumber addonBefore='$' placeholder={formLang('AmountPlaceholder') || ''} />
          </Form.Item>
          <Form.Item label='Reason for refund'>
            <Input placeholder='e.g why do customers return their purchases' />
          </Form.Item>
          <Form.Item label='Choose refund method'>
            <Radio.Group options={['Cash', 'Card']} />
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default FormFirstStep;
