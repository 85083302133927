import { Empty, Form, Input } from 'antd';
import { InputSearchContain } from 'components/common/Input/InputSearch';
import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
import { IServiceItem } from 'features/bookings/services/types/service';
import { debounce, findIndex, remove } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ServiceBox from './ServiceBox';
import ServiceItem from './ServiceItem';
import { IServiceCategoryItem } from './type';
import NewServicesModal from './NewServicesModal';
import { useParams } from 'react-router-dom';
import { Moment } from 'moment';
import { TIME_START_FORMAT_RESPONSE } from 'features/bookings/services/constants';
import settingSelectors from 'features/settings/services/selectors';
// import { useTranslation } from 'react-i18next';

type ServiceFormProps = {
  hideInput?: boolean;
  errorService?: string;
  isCheckIn?: boolean;
};
const ServicesForm = (props: ServiceFormProps) => {
  const { hideInput, isCheckIn = false } = props;

  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');
  const form = Form.useFormInstance();

  const [categorySelected, setCategorySelected] = useState<string | number>(
    'general'
  );
  const selected = bookingSelectors.bookingOnline.getSelectedServices();
  const param = useParams();
  const merchant_location_id = ! isCheckIn ? bookingSelectors.bookingOnline.getActiveLocationId() : param.location_id;  
  const [draffService, setDraffService] = useState<IServiceItem | null>(null);
  const servicesStore = bookingSelectors.bookingOnline.getServices();
  const setting = settingSelectors.getSetting();
  const location = bookingSelectors.bookingOnline.getActiveLocation();
    

  const getServiceCheckout = () => {
    return servicesStore.filter(o => {
      if (!o.merchant_location_id || o.merchant_location_id?.length === 0) return true;
      return !!o.merchant_location_id?.find(d => d?.toString() === merchant_location_id?.toString());
    }).map(o => ({
      ...o,
      merchant_employees: o.merchant_employees.filter(f => {
        if (!f.id) return true;
        return f.merchant_location_id?.toString() === merchant_location_id?.toString();
      })
    }));
  };

  const getServiceCheckIn = () => {
    const paths = window.location.pathname.split('/');
    const location_id = paths[paths.length - 1];
    return servicesStore.filter(o => {
      if (!o.merchant_location_id || o.merchant_location_id?.length === 0) return true;
      return !!o.merchant_location_id?.find(d => d?.toString() === location_id?.toString());
    })
    .map(o => ({
      ...o,
      merchant_employees: o.merchant_employees.filter(f => {
        if (!f.id) return true;
        return f.merchant_location_id?.toString() === merchant_location_id?.toString();
      })
    }));
  };  

  const services = useMemo(() => {
    return ! isCheckIn ? getServiceCheckout() : getServiceCheckIn();
  }, [servicesStore, merchant_location_id]);  
  
  const categories = useMemo(() => {
   
    if (!services.length) return [];
    const result: IServiceCategoryItem[] = [];
    // result.push({
    //   id: 'general',
    //   color: '#4A9D77',
    //   name: 'General'
    // });
    services?.forEach(o => {
      if (o?.category && !result.find(c => c.id === o?.category?.id))
        result.push(o?.category);
    });
    return result;
  }, [services]);  

  const _services = useMemo(() => {
    const _search = search.toLowerCase();
    return services?.filter(o => {
      const isPassCate = categorySelected === 'general' ? true : o.category.id === categorySelected;
      
      if (isPassCate) {
        if (o?.name?.toLowerCase().includes(_search)) {
          return true;
        }
        return !!o?.service_variants?.some(s => s.name?.toLowerCase().includes(_search));
      }
      return false;
    })
    .map(o => ({
      name: o.name,
      id: o.id,
      price: o.sale_price || o.regular_price,
      duration: o.duration_time,
      quantity: 1,
      merchant_employees: o.merchant_employees,
      sale_price: o.sale_price,
      regular_price: o.regular_price,
      // @ts-ignore
      description: o?.description,
      subServices: o?.service_variants?.filter(s => _search ? s.name?.toLowerCase().includes(_search) : true)?.map(s => ({
        id: o.id,
        service_variant_id: s.id,
        name: s.name,
        price: s.price ?? 0,
        duration: s.duration ?? 0,
        quantity: 1,
        payload: {
          ...s,
          sale_price: s.price,
          merchant_employees: o.merchant_employees,
          id: o.id,
          service_variant_id: s.id,
        },
      }) as IServiceItem),
      payload: o,
      category: o.category,
    }) as IServiceItem);
  }, [services, categorySelected, search]);

  const setFormValue = (serviceItem: IServiceItem) => {
    const value = serviceItem.id + '-' + serviceItem?.service_variant_id ?? '';
    const temp: string[] = selected.map(
      (o) => o.id + '-' + o?.service_variant_id ?? ''
    );
    const idx = temp.indexOf(value);
    if (idx !== -1) {
      remove(temp, (n) => n === value);
      form.setFieldsValue({
      });
    } else {
      temp.push(value);
      const serviceFirst = selected.length > 0 ? selected[0] : undefined;
      
      form.setFieldsValue({
        service: temp.join('') ? temp.join('') : (serviceFirst?.id + '-' + serviceFirst?.service_variant_id),
      });
    }
    
  };
  
  
  // const getTimeStart = () => {
  //   let timePicker;
  //     if (service.length === 0) {
  //       timePicker = dayjs(bookingDate);
  //     } else {
  //       const lastItem = last(currServices);
  //       const breakTimeMinutes = BREAK_TIME_MINUTES;
  //       const prevServiceMinutes = (lastItem?.duration_time ?? 0) * (lastItem?.quantity ?? 0);
  //       const nextTimeStart = moment(lastItem?.time_start, TIME_START_FORMAT).add(breakTimeMinutes + prevServiceMinutes, 'minute');
  //       timePicker = dayjs(nextTimeStart.format());
  //     }
  //   }
  //   return timePicker;
  // };
  

  const handleAddService = (formValue: {members: number, quantity: number, time_start: Moment, duration: number}) => {
    if(! draffService) return null;
      
    draffService.employee_id = formValue.members;
    draffService.quantity = formValue.quantity;
    draffService.price = (draffService?.sale_price > 0 ? draffService?.sale_price : draffService?.regular_price) ?? 0;
    draffService.time_start = formValue.time_start.format(TIME_START_FORMAT_RESPONSE);
    draffService.duration = draffService.duration || formValue.duration;    

    setFormValue(draffService);
    dispatch(bookingActions.bookingOnlineServiceSelected.toggle(draffService));
    setDraffService(null);
  };



  const onChangeServices = (serviceItem: IServiceItem) => {
    // if(isCheckIn) {
      const idx = findIndex(selected, (o) => o.id === serviceItem.id && o?.service_variant_id === serviceItem.service_variant_id);
      if (idx !== -1) {
        dispatch(bookingActions.bookingOnlineServiceSelected.toggle(serviceItem));
      } else {
        if(setting?.booking?.is_team_member_booking) {
          setDraffService(serviceItem?.payload);
        } else {
          serviceItem.employee_id = serviceItem?.merchant_employees?.find(o => o.id === location?.manager.id) ? (location?.manager.id ?? '') : '';
          setFormValue(serviceItem);
          dispatch(bookingActions.bookingOnlineServiceSelected.toggle(serviceItem));
          
        }
      }
      // return;
    // }
    // setFormValue(serviceItem);
    // dispatch(bookingActions.bookingOnlineServiceSelected.toggle(serviceItem));
  };

  const updateQuantity = (
    id: IServiceItem['id'],
    service_variant_id: IServiceItem['service_variant_id'],
    quantity: number
  ) => {
    dispatch(
      bookingActions.bookingOnlineSetQuantityServiceItem({
        id,
        service_variant_id,
        value: quantity,
      })
    );
  };
  return (
    <ServicesStyled>
      {!hideInput && <InputSearch onSearch={setSearch} />}
      <FeaturedServicesStyled className='common-scrollbar'>
        {categories.map((item) => (
          <ServiceBox
            key={item.id}
            label={item.name}
            className='service_item'
            onClick={() => setCategorySelected(item.id)}
            selected={item.id === categorySelected}
            color={item.color}
          />
        ))}
      </FeaturedServicesStyled>
      <MessageStyled>
        <Form.Item shouldUpdate noStyle>
          {() => {
            return (
              <Form.Item name={'service'} rules={[
                { required: !isCheckIn, message: 'Please choose your services' },
              ]}>
                <Input />
              </Form.Item>
            );
          }}
        </Form.Item>
      </MessageStyled>
      <ServicesSelectionStyled>
        <table>
          <tbody>
            {
              _services?.length > 0 ?

                _services?.map((item) => (
                  <ServiceItem
                    selectedList={selected}
                    key={item.id}
                    data={item}
                    onServiceChange={onChangeServices}
                    updateQuantity={updateQuantity}
                  />
                ))
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No services'} />
            }
          </tbody>
        </table>
      </ServicesSelectionStyled>
      <NewServicesModal isOpenModal={!!draffService} handleCancel={() => setDraffService(null)} dataService={draffService} handleAddServices={handleAddService}/>
    </ServicesStyled>
  );
};

export default ServicesForm;

const InputSearch = ({ onSearch }: { onSearch: (val: string) => void }) => {
  const [text, setText] = useState('');
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setText(e.target.value ?? '');
  };
  // const { t: formLang } = useTranslation('form');

  const _debounce = useCallback(
    debounce((text) => {
      onSearch(text);
    }, 200),
    []
  );

  useEffect(() => {
    _debounce(text);
  }, [text]);

  return (
    <InputSearchContain
      className='input_search'
      placeholder={'Search service'}
      value={text}
      onChange={onChange}
    />
  );
};

const MessageStyled = styled.div`
  .ant-form-item-control-input {
    display: none !important;
  }
`;
const ServicesStyled = styled.div`
  .input_search {
    margin-bottom: 16px;
  }
`;

const FeaturedServicesStyled = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  overflow: auto;
  &.disableScroll {
    display: flex;
  }
  .service_item {
    margin-right: 12px;
    margin-bottom: 12px;
    min-width: max-content;
  }
`;

const ServicesSelectionStyled = styled.div`
  display: flex;
  max-height: 360px;
  overflow-x: scroll;
  table {
    width: 100%;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d9d9d9;
  }

  @media (max-width: 767.98px) {
    min-height: 300px;
  }
`;
