import { createSlice } from '@reduxjs/toolkit';
import { BREAK_TIME_MINUTES } from 'features/bookings/services/constants';
import { IVoucherItemResData } from 'features/vouchers/services/types/voucher';
import { find, first, get, merge, remove } from 'lodash';
import moment from 'moment';
import actions from './actions';
import { EErrorMethodKeys, EPaymentMethod, NAME_REDUCER } from './constants';
import { IState } from './types/reducer';
import { IServiceConfigsResData, IServiceSelectedItem } from './types/service';
import authActions from 'features/auth/services/actions';
import { getTimeRound5Minute } from 'utils/unit';
const initialState: IState = {
  configsData: null,
  voucherList: [],
  selectedServices: [],
  selectedVouchers: [],
  selectedReward: null,
  customer: null,
  bookingDate: moment().format(),
  voucherOfCustomer: [],
  rewardOfCustomer: [],
  paymentMethod: EPaymentMethod.CASH,
  purchaseErrors: {
    cash: null,
    card: null,
  },
  purchaseInfo: {
    currentCash: null,
    currentCard: null
  },
  selectedVoucherUsed: null,
  mixPayments: [],
  merchant_location_id: null,
  total_point_program_will_earn: null,
  promotionInfo: null,
  promotionErrorBoundary: null,
  softPromotionCode: null,
  invoiceResult: null,
  total_point: null,
  extraFee: null,
  discountFee: null,
  holidayInfo: null
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.setDiscountFee, (state, { payload }) => {
        state.discountFee = payload;
      })
      .addCase(actions.setExtraFee, (state, { payload }) => {
        state.extraFee = payload;
      })
      .addCase(actions.getTotalPointCustomer.success, (state, { payload }) => {
        state.total_point = payload;
      })
      .addCase(actions.setSoftPromotionCode, (state, { payload }) => {
        state.softPromotionCode = payload;
      })
      .addCase(actions.setPromotionErrorBoundary, (state, { payload }) => {
        state.promotionErrorBoundary = payload;
      })
      .addCase(actions.setPromotionInfo, (state, { payload }) => {
        state.promotionInfo = payload;
      })
      .addCase(actions.updateCusInfo.success, (state, { payload }) => {
        merge(state.customer, payload);
      })
      .addCase(actions.getRewardOfCustomer.success, (state, { payload }) => {
        const { pointEnough } = payload;
        // const pointLeftNearest = pointLeft.filter((o: any) => (state?.customer?.current_point ?? 0) - o.points_to_earn_reward < 10);
        state.rewardOfCustomer = [...pointEnough];
      })
      .addCase(actions.reviewPointCustomerWillReceive.success, (state, { payload }) => {
        if (payload.total_point_program_earn) {
          state.total_point_program_will_earn = payload.total_point_program_earn;
        }
      })
      .addCase(authActions.logout.success, (state) => {
        state.configsData = null;
        state.voucherList = [];
        state.selectedServices = [];
        state.selectedVouchers = [];
        state.selectedReward = null;
        state.customer = null;
        state.bookingDate = getTimeRound5Minute(moment()).format();
        state.voucherOfCustomer = [];
        state.rewardOfCustomer = [];
        state.paymentMethod = EPaymentMethod.CASH;
        state.purchaseErrors = {
          cash: null,
          card: null,
        };
        state.purchaseInfo = {
          currentCash: null,
          currentCard: null
        };
        state.selectedVoucherUsed = null;
        state.mixPayments = [];
        state.merchant_location_id = null;
        state.promotionInfo = null;
        state.promotionErrorBoundary = null;
        state.softPromotionCode = null;
      })
      .addCase(actions.resetFormPayment, (state) => {
        state.paymentMethod = EPaymentMethod.CASH;
        state.purchaseErrors = {
          cash: null,
          card: null,
        };
        state.purchaseInfo = {
          currentCash: null,
          currentCard: null,
        };
        state.selectedVoucherUsed = null;
        state.mixPayments = [];
        state.promotionInfo = null;
        state.promotionErrorBoundary = null;
        state.softPromotionCode = null;
       
      })
      .addCase(actions.editSelectedService, (state, { payload }) => {
        const item = find(state.selectedServices, (o: IServiceSelectedItem) => o.id === payload?.id && o.service_variant_id === payload?.service_variant_id);
        merge(item, { ...payload });

        const timeStart = moment(state.bookingDate);
        
        state.selectedServices = state.selectedServices?.map(o => {
          const duration_time = (o.duration_time ?? 0) * (o.quantity ?? 1);
          const result = {
            ...o,
            time_start: timeStart.format(),
          };
          timeStart.add(duration_time, 'minute');
          return result;
        }) ?? [];
      })
      .addCase(actions.setSelectedReward, (state, { payload }) => {
        state.selectedReward = payload;
      })
      .addCase(actions.fetchConfigsData, (state) => {
        state.configsData = null;
        state.voucherList = [];
        state.selectedServices = [];
        state.selectedVouchers = [];
        state.selectedReward = null;
        state.customer = null;
        state.rewardOfCustomer = [];
        state.bookingDate = getTimeRound5Minute(moment()).format();
        state.voucherOfCustomer = [];
        state.paymentMethod = EPaymentMethod.CASH;
        state.purchaseErrors = {
          cash: null,
          card: null,
        };
        state.purchaseInfo = {
          currentCash: null,
          currentCard: null,
        };
        state.selectedVoucherUsed = null;
        state.mixPayments = [];
        state.merchant_location_id = null;
        state.promotionInfo = null;
        state.promotionErrorBoundary = null;
        state.softPromotionCode = null;
      })
      .addCase(actions.setBookingDate, (state, { payload }) => {
        const currentTime = moment();
        const curBookingDate = moment(state.bookingDate);
        let bookingDate = payload;
        if (currentTime.isSame(curBookingDate, 'day')) {
          if (curBookingDate.isBefore(currentTime)) {
            bookingDate = currentTime.add(BREAK_TIME_MINUTES, 'minute').format();
          }
        }

        state.bookingDate = bookingDate;
        const timeStart = moment(bookingDate);
        state.selectedServices = state.selectedServices.map(o => {
          const result = {
            ...o,
            time_start: timeStart.format()
          };
          const breakTimeMinutes = BREAK_TIME_MINUTES;
          const quantity = (o.quantity ?? 0);
          const prevServiceMinutes = ((o.duration_time ?? 0) * quantity) + (quantity - 1) * breakTimeMinutes;
          timeStart.add(breakTimeMinutes + prevServiceMinutes, 'minute');
          return result;
        });
      })
      .addCase(actions.setMerchantLocationId, (state, { payload }) => {
        state.merchant_location_id = payload.id;
        state.selectedServices = [];
      })
      .addCase(actions.setMixPayments, (state, { payload }) => {
        merge(state.purchaseErrors, {
          [EErrorMethodKeys.mix]: null,
        });
        state.mixPayments = payload;
      })
      .addCase(actions.setSelectedVoucherUsed, (state, { payload }) => {
        state.selectedVoucherUsed = payload;
        const isExist = state.voucherOfCustomer.findIndex(o => o.voucher_code === payload?.voucher_code) !== -1;
        if (!isExist && payload) {
          state.voucherOfCustomer = [payload, ...state.voucherOfCustomer];
        }
      })
      .addCase(actions.setPurchaseCash, (state, { payload }) => {
        state.purchaseInfo.currentCash = payload;
      })
      .addCase(actions.setPurchaseCard, (state, { payload }) => {
        state.purchaseInfo.currentCard = payload;
      })
      .addCase(actions.setPurchaseMethodErrors, (state, { payload }) => {
        const { method, errors } = payload;
        merge(state.purchaseErrors, {
          [method]: errors,
        });
      })
      .addCase(actions.setPaymentMethod, (state, { payload }) => {
        state.paymentMethod = payload;
      })
      .addCase(actions.getVoucherOfCustomer.success, (state, { payload }) => {
        state.voucherOfCustomer = payload;
      })
      .addCase(actions.removeSelectedService, ((state, { payload }) => {
        const { id, service_variant_id } = payload;
        remove(state.selectedServices, o => o.id === id && o.service_variant_id === service_variant_id);

        const timeStart = moment(state.bookingDate);
        state.selectedServices = state.selectedServices?.map(o => {
          const duration_time = (o.duration_time ?? 0) * (o.quantity ?? 1);
          const result = {
            ...o,
            time_start: timeStart.format(),
          };
          timeStart.add(duration_time, 'minute');
          return result;
        }) ?? [];
      }))
      .addCase(actions.setQuantitySelectedService, (state, { payload }) => {
        const { quantity, id, service_variant_id } = payload;
        const item = find(state.selectedServices, (o: IServiceSelectedItem) => o.id === id && o.service_variant_id === service_variant_id);
        merge(item, { quantity });

        const timeStart = moment(state.bookingDate);
        state.selectedServices = state.selectedServices?.map(o => {
          const duration_time = (o.duration_time ?? 0) * (o.quantity ?? 1);
          const result = {
            ...o,
            time_start: timeStart.format(),
          };
          timeStart.add(duration_time, 'minute');
          return result;
        }) ?? [];
      })
      .addCase(actions.setCustomer, (state, { payload }) => {
        state.customer = payload;
      })
      .addCase(actions.setSelectedVouchers, (state, { payload }) => {
        state.selectedVouchers = payload;
      })
      .addCase(actions.getConfigData.success, (state, { payload }) => {
        const resData = payload as IServiceConfigsResData;
        state.configsData = resData;
        state.bookingDate = moment().format();
        state.merchant_location_id = get(first(resData?.locations), 'id') ?? null;
      })
      .addCase(actions.getVouchersList.success, (state, { payload }) => {
        const vouchers = payload as IVoucherItemResData[];
        state.voucherList = vouchers.map(v => {
          const result: string[] = [];
          v.services.forEach(o => {
            if (o.service_variants.length > 0) {
              o.service_variants.forEach(s => {
                const id = o?.id + '_' + s.id;
                result.push(id);
              });
            } else {
              result.push(o?.id?.toString());
            }
          });
          return ({
            ...v,
            services_flatten_ids: result,
            value_remaining: v.value - v.redeemed_value
          });
        });
      })
      .addCase(actions.setServiceSelected, (state, { payload }) => {
        state.selectedServices = payload ?? [];
        
        const timeStart = moment(get(first(state.selectedServices), 'time_start'));
        state.selectedServices = state.selectedServices.map(o => {
          
          const duration_time = (o.duration_time ?? 0) * (o.quantity ?? 1) + BREAK_TIME_MINUTES;
          const result = {
            ...o,
            time_start: timeStart.format('YYYY-MM-DD HH:mm:ss'),
            service_variant_id: o.service_variant_id,
            name: o.name,
            serviceVariantName: o.service_variant_id ? o.service_variants?.find((item) => (item.id === o.service_variant_id))?.name : o.name,
          };
          timeStart.add(duration_time, 'minute');
          
          return result;
        }) ?? [];      
      })
      .addCase(actions.setInvoiceResult, (state, { payload }) => {
        state.invoiceResult = payload;
      });
  },
});
const salesServiceReducer = Slice.reducer;
export default salesServiceReducer;
