import fetch from 'services/request';
import { IApiProgramReq } from './types/program';
import { IApiRewardReq } from './types/reward';
import { ILoyalSettingResData } from './types/setting';

const BASE_URL = 'api/v1/merchants/:merchant_code/loyals';

const getListLoyalProgram = (params?: any) => {
  return fetch({
    method: 'get',
    url: `${BASE_URL}/programs`,
    params: { ...params },
  });
};

const getListLoyalReward = (params?: any) => {
  return fetch({
    method: 'get',
    url: `${BASE_URL}/rewards`,
    params: { ...params }
  });
};

const createProgram = (body?: IApiProgramReq) => {
  return fetch({
    method: 'post',
    url: `${BASE_URL}/programs/create`,
    body: body as any,
  });
};

const updateProgram = (id: string, body: IApiProgramReq) => {
  return fetch({
    method: 'post',
    url: `${BASE_URL}/programs/update/` + id,
    body: body as any,
  });
};

const removeProgram = (id: string) => {
  return fetch({
    method: 'delete',
    url: `${BASE_URL}/programs/delete/` + id,
  });
};

const updateStatusProgram = (id: string, status: boolean) => {
  return fetch({
    method: 'put',
    url: `${BASE_URL}/programs/update-status`,
    body: { id: +id, status },
  });
};

const createReward = (body: IApiRewardReq) => {
  return fetch({
    method: 'post',
    url: `${BASE_URL}/rewards/create`,
    body: body as any,
  });
};

const updateReward = (id: string, body: IApiRewardReq) => {
  return fetch({
    method: 'put',
    url: `${BASE_URL}/rewards/update/` + id,
    body: body as any,
  });
};

const removeReward = (id: string) => {
  return fetch({
    method: 'delete',
    url: `${BASE_URL}/rewards/delete/` + id,
  });
};

const updateStatusReward = (id: string, status: boolean) => {
  return fetch({
    method: 'put',
    url: `${BASE_URL}/rewards/update-status`,
    body: {
      id: +id,
      status,
    }
  });
};

const getLocations = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/location'
  });
};

const getServices = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/service',
    params: {
      per_page: Number.MAX_SAFE_INTEGER,
    }
  });
};

const getLoyalSetting = () => {
  return fetch({
    method: 'get',
    url: `${BASE_URL}/setting`,
  });
};

const updateLoyalSetting = (body: ILoyalSettingResData) => {
  return fetch({
    method: 'put',
    url: `${BASE_URL}/setting`,
    body: body as any,
  });
};

const apisLoyal = {
  getLocations,
  getServices,
  getListLoyalProgram,
  getListLoyalReward,
  createProgram,
  updateProgram,
  updateStatusProgram,
  removeProgram,
  createReward,
  updateReward,
  removeReward,
  updateStatusReward,
  getLoyalSetting,
  updateLoyalSetting,
};

export default apisLoyal;
