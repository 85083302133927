import { Popover } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import StatusPicker from './StatusPicker';
import ZoomCalendar from './ZoomCalendar';
import IconFilter from 'assets/svg/IconFilter';

const ZoomView: React.FC = () => {
  const content = useMemo(() => {
    return (
      <ZoomViewStyled>
        <div className='filter-menu'>
          <p className='menu-title'>Status</p>
          <StatusPicker />
          <div className='divider'></div>
          <ZoomCalendar />
        </div>
      </ZoomViewStyled>
    );
  }, []);
  return (
    <Popover
      arrow={false}
      overlayInnerStyle={{ padding: 0 }}
      placement='bottom'
      content={content}
      trigger='click'
    >
      <ZoomViewStyled>
        <button className='filter-btn'>
          <IconFilter />
        </button>
      </ZoomViewStyled>
    </Popover>
  );
};
export default ZoomView;

const ZoomViewStyled = styled.div`
  overflow-x: hidden;
  .filter-menu {
    width: 220px;
    min-height: 184px;
    background: var(--color-white);
    box-shadow: 0px 4px 24px rgba(225, 225, 239, 0.6);
    border-radius: 6px;
    z-index: 111;
    padding: 12px;
    & > div:first-child {
      border-bottom: 2px solid #f0f2f7;
      margin-bottom: 12px;
      padding-bottom: 12px;
    }
    .ant-select {
      border: 1px solid #c3c2e0;
      border-radius: 6px;
    }
  }
  .slider-control {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 140%;
    color: #949bda;
    padding-bottom: 8px;
    li {
      min-width: 30%;
      &:nth-child(2) {
        text-align: center;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
  .divider {
    background-color: #f0f2f7;
    width: 100%;
    height: 1px;
    margin: 12px 0;
    margin-bottom: 9px;
  }
  .menu-title {
    font-weight: 600;
    font-size: 14px;
    color: var(--color-primary);
    margin-bottom: 8px;
  }
`;
