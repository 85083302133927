import { Empty, Spin } from 'antd';
import bookingSelectors from 'features/bookings/services/selectors';
import { IServiceQuickBookingItem } from 'features/bookings/services/types/quickBooking';
import { findIndex } from 'lodash';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import InputSearchServices from '../InputSearchServices';
import ListCategory from '../ListCategory';
import ServiceItem, { IFuncGetIsCheck, ServiceGroup } from './ServiceItem';

type Props = {
  handleChooseService?: any;
  currChooseServices?: any;
};

const ServicesSelection = ({
  handleChooseService,
}: Props) => {
  const dataConfigs = bookingSelectors.quickBooking.getDataConfigs();
  const selected = bookingSelectors.quickBooking.getSelectedServices();
  const isLoading = bookingSelectors.quickBooking.getLoadingDataConfigs();
  const merchant_location_id = bookingSelectors.quickBooking.getMerchantLocationId();

  const services = useMemo(() => {
    if (!dataConfigs?.services || dataConfigs?.services.length === 0) return [];
    return dataConfigs?.services.filter(o => {
      if (!o.merchant_location_id || o.merchant_location_id?.length === 0) return true;
      return !!o.merchant_location_id?.find(d => d?.toString() === merchant_location_id?.toString());
    }).map(o => ({
      ...o,
      merchant_employees: o.merchant_employees.filter(o => {
        if (!o.id) return true;
        return o.merchant_location_id?.toString() === merchant_location_id?.toString();
      })
    }));
  }, [dataConfigs?.services, merchant_location_id]);

  const categories = useMemo(() => {
    if(services.length === 0) return [];

    return [
      {
        id: 'favorite',
        color: '#4A9D77',
        name: 'Favorite',
        total_sale: Number.MAX_SAFE_INTEGER
      },
    //   {
    //   id: 'general',
    //   color: '#2f54eb',
    //   name: 'General',
    //   total_sale: Number.MAX_SAFE_INTEGER
    // }
  ].concat(dataConfigs?.categories.filter(o => services.find(e => e.category.id === o.id)) as []);
  }, [services]);

  const [search, setSearch] = useState('');
  const [category, setCategory] = useState<string>('general');

  const _services = useMemo(() => {
    const _search = search.toLowerCase();
    return services.filter(o =>
      category === 'general' ? true 
      : category === 'favorite'? o.is_favourite === 1 
      : String(o.category.id) === category
    ).filter(o => {
      if (o?.name?.toLowerCase().includes(_search)) {
        return true;
      }
      return !!o?.service_variants?.some(s => s?.name?.toLowerCase().includes(_search));
    });
  }, [services, category, search, selected]);


  const handleInputChange = (value: string) => {
    setSearch(value);
  };

  const handleServiceChange = (
    isChecked: boolean,
    serviceItem: IServiceQuickBookingItem
  ) => {
    handleChooseService(serviceItem, isChecked);
  };

  const getIsChecked: IFuncGetIsCheck = ({ service_variant_id, service_id }) => {
    const currentIdx = findIndex(selected, (o) => o.id === service_id && o.service_variant_id === service_variant_id);

    return currentIdx !== -1;
  };

  if (isLoading) return <CenterDiv><Spin /></CenterDiv>;

  if (services.length === 0) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No services'} />;

  return (
    <>
      <InputSearchServices handleChange={handleInputChange} isGray={true} />
      <ListCategory
        data={categories}
        onChange={setCategory}
        value={category}
        disableScroll
      />
      <ServicesSelectionStyled>
        <table>
          <tbody>
            {
              _services.length > 0 ?
                _services?.map((service) => {
                  if (
                    service.service_variants &&
                    service.service_variants?.length > 0
                  ) {
                    return (
                      <ServiceGroup
                        key={service.id}
                        service={service}
                        getIsChecked={getIsChecked}
                        onServiceChange={handleServiceChange}
                      />
                    );
                  }
                  return (
                    <ServiceItem
                      key={service.id}
                      service={service}
                      getIsChecked={getIsChecked}
                      onServiceChange={handleServiceChange}
                    />
                  );
                }) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No services'} />}
          </tbody>
        </table>
      </ServicesSelectionStyled>
    </>
  );
};

export default ServicesSelection;
const CenterDiv = styled.div`
  height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`;
const ServicesSelectionStyled = styled.div`
  @media (max-width: 992.98px) {
    max-height: 230px;
  }
  max-height: 360px;
  overflow-y: scroll;
  table {
    width: 100%;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d9d9d9;
  }

  @media (max-width: 767.98px) {
    min-height: 300px;
  }
`;

