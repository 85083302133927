import IconCheckOverview from 'assets/svg/IconCheckOverview';
import dayjs from 'dayjs';
import checkoutSelectors from 'features/checkout/services/selectors';
import storage from 'utils/sessionStorage';


const CustomerInfoStepSuccess = () => {
  const customerInfo = checkoutSelectors.getCustomerInfo();

  return (
    <div className='complete-order'>
      <div className='complete-icon'>
        <IconCheckOverview />
      </div>
      <div className='complete-content'>
        <p className='complete-title'>Sale Overview</p>
        <p className='complete-text'>
          Payment received on <span>Today, {dayjs(new Date()).format('YYYY.MM.DD')}</span> at{' '}
          <span>{storage.merchantName.get()}</span> with <span className='customer-name'> {customerInfo?.name} </span>
          <span></span>.
        </p>
      </div>
    </div>
  );
};

export default CustomerInfoStepSuccess;
