const IconCheckOverview = () => {
  return (
    <svg
      width='68'
      height='67'
      viewBox='0 0 68 67'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.1'
        d='M67.3327 33.5013C67.3327 51.9108 52.4088 66.8346 33.9994 66.8346C15.5899 66.8346 0.666016 51.9108 0.666016 33.5013C0.666016 15.0918 15.5899 0.167969 33.9994 0.167969C52.4088 0.167969 67.3327 15.0918 67.3327 33.5013Z'
        fill='#008F5D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M48.8676 21.5267C49.9574 22.3744 50.1538 23.9451 49.3061 25.035L35.9494 42.2078C33.8704 44.8809 29.9597 45.2278 27.4426 42.9624L18.9936 35.3584C17.9674 34.4347 17.8842 32.854 18.8078 31.8277C19.7314 30.8014 21.3122 30.7182 22.3384 31.6419L30.7874 39.2459C31.147 39.5695 31.7056 39.52 32.0027 39.1381L45.3593 21.9653C46.207 20.8754 47.7777 20.6791 48.8676 21.5267Z'
        fill='#008F5D'
      />
    </svg>
  );
};

export default IconCheckOverview;
