import { Form, InputNumber } from 'antd';
import salesActions from 'features/sales/services/actions';
import salesSelectors from 'features/sales/services/selectors';
import { useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatMoney, getCashOption } from 'utils/unit';
import { EPaymentMethod } from 'features/checkout/services/types/checkout';
import salesHook from 'features/sales/hooks/salesHook';
import { BlockPaymentProps } from '.';
import { useCashErrors } from '../ButtonHandler/Purchase/Cash';
// import BoxDenyPromo from 'components/common/Box/BoxDenyPromo';

const CashPayment = ({
  action = salesActions, 
  selector = salesSelectors, 
  hook = salesHook
}: BlockPaymentProps) => {
  // const { t: checkoutLang } = useTranslation('checkout');

  const dispatch = useAppDispatch();
  const currCash = selector.getPurchaseCurrentCash();
  const amount = selector.getOriginTotalPrice();
  const [ ,setMethod ] = hook.usePaymentMethod();
  const [error, setError] = useCashErrors();
  // const isDisablePromo = selector.disablePromo();


  const data = useMemo(() => {
    return getCashOption(amount);
  }, [amount]);

  const change = useMemo(() => {
    return (currCash ?? 0) - amount;
  }, [amount, currCash]);

  const applyCash = () => {
    if (currCash === amount) {
      setError('The current cash amount is equal to or greater than the amount specified, so cash cannot be applied with the voucher.');
      return;
    }
    setMethod(EPaymentMethod.CARD);
    dispatch(action.setPurchaseCash(currCash));
  };

  const setCurrCash = (val: any) => {
    dispatch(action.setPurchaseCash(val));
  };

  return (
    <CashPaymentStyled>
      <Form 
      layout='vertical'>
        <p>Amount given</p>
        <div className='form-row'>
          <Form.Item >
            <InputNumber addonBefore='$'
              precision={2}
              // formatter={(value: any) => {
              //   if (value === undefined) {
              //     return '';
              //   }
              //   return parseFloat(value)?.toFixed(2);
              // }}
              min={0}
              value={currCash} onChange={(value) => {
              setError(''); 
                setCurrCash(value);
              }} />
          </Form.Item>
          {/* <BoxDenyPromo isOpen={isDisablePromo} onClick={applyCash} >  */}
            <button
              type='submit'
              className={'form-booking-submit'}
              onClick={applyCash}
              // disabled={!customerInfo || (selectedServices.length === 0 && selectedVouchers.length == 0)}
            >
              Apply
            </button>
          {/* </BoxDenyPromo> */}

        </div>
      </Form>
      {error && <p className='text-error'>{error}</p>}

      {<div className="form-note">{formatMoney(change > 0 ? change : 0)} change</div>}
      <ul className='list-cash'>
        {data.map((item, index) => {
          const { value, label } = item;
          return (
            <li
              key={index}
              className={`${currCash === value && 'is-active'}`}
              onClick={() => setCurrCash(value)}
            >
              {label}
            </li>
          );
        })}
      </ul>
    </CashPaymentStyled>
  );
};

export default CashPayment;

const CashPaymentStyled = styled.div`
  position: relative;
  color: var(--color-primary);
  margin-top: 24px;
  
  .form-note {
    margin: 8px 0px 16px;
    color: var(--color-green);
  }
  .text-error {
    color: #ff4d4f;
    margin-top: 5px;
  }

  .form-booking-submit {
    height: 64px !important; 
    padding: 0px 24px;
  }

  .list-cash {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 24px;
    @media only screen and (max-width: 767.98px) {
      flex-wrap: inherit;
    }
    li {
      border: 1px solid var(--color-purple);
      width: 160px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-transform: capitalize;
      height: 40px;
      border-radius: 6px;
      cursor: pointer;
      &.is-active {
        background: var(--color-purple-03);
      }
    }
  }
`;
