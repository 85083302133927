import ProgramAdd from '../widgets/Program/Add';
import ProgramsTable from './ProgramsTable';

const ProgramsTab = () => {
  return (
    <>
      <div className='cards-wrapper'>
        <div className='cards-col'>
          <div className='common-card'>
            <div className='card-heading'>
              <div className='card-title is-small'>
                <div>
                  <p>Earn Points</p>
                  <span>All ways that your customers can earn points.</span>
                </div>
                <ProgramAdd />
              </div>
            </div>
            <ProgramsTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgramsTab;
