import { DatePicker, Space } from 'antd';
import dayjs from 'utils/dayjs';
import moment from 'moment';
import { useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch } from 'store/hooks';
import timeScheduleActions from 'features/timeSchedule/services/actions';
import timeScheduleSelectors from 'features/timeSchedule/services/selectors';

function TimeScheduleDateView() {
  const params = timeScheduleSelectors.getParams();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [dateState, setDateState] = useState(dayjs(moment().format()));

  const onPressPrev = () => {
    const prevWeek = moment(params.start_date).subtract(1, 'week');

    const _params = {
      ...params,
      start_date: prevWeek.startOf('week').add(1, 'd').format('YYYY-MM-DD'),
      end_date: prevWeek.endOf('week').add(1, 'd').format('YYYY-MM-DD'),
      page: 1,
    };
    dispatch(timeScheduleActions.setListTimeScheduleParams(_params));
  };

  const onPressNext = () => {
    const nextWeek = moment(params.start_date).add(1, 'week');

    const _params = {
      ...params,
      start_date: nextWeek.startOf('week').add(1, 'd').format('YYYY-MM-DD'),
      end_date: nextWeek.endOf('week').add(1, 'd').format('YYYY-MM-DD'),
      page: 1,
    };
    dispatch(timeScheduleActions.setListTimeScheduleParams(_params));
  };

  const handleDatePickerChange = (day: any) => {
    setDateState(day);
    const dayConvert = day.format('YYYY-MM-DD');
    if (
      moment(dayConvert).isSameOrAfter(moment(params?.start_date)) &&
      moment(dayConvert).isSameOrBefore(moment(params?.end_date))
    ) {
      return;
    }

    const startWeek = moment(dayConvert)
      .subtract(1, 'd')
      .startOf('week')
      .add(1, 'd')
      .format('YYYY-MM-DD');
    const endWeek = moment(dayConvert)
      .subtract(1, 'd')
      .endOf('week')
      .add(1, 'd')
      .format('YYYY-MM-DD');

    const _params = {
      ...params,
      start_date: startWeek,
      end_date: endWeek,
      page: 1,
    };
    dispatch(timeScheduleActions.setListTimeScheduleParams(_params));
  };

  return (
    <DateViewPickerStyled>
      <Space direction='horizontal'>
        <DatePicker
          className='is-normal'
          allowClear={false}
          open={open}
          onOpenChange={setOpen}
          suffixIcon={false}
          value={dateState}
          inputRender={(props: any) => (
            <InputPickerStyled>
              <button onClick={onPressPrev}>
                <IconPrev />
              </button>
              <RangePickerStyled {...props}>
                <p>
                  {moment(params.start_date).format('DD.MM')} -{' '}
                  {moment(params.end_date).format('DD.MM.YYYY')}
                </p>
              </RangePickerStyled>
              <button onClick={onPressNext}>
                <IconNext />
              </button>
            </InputPickerStyled>
          )}
          onChange={(_dayjs) => {
            if (!_dayjs) return;
            handleDatePickerChange(_dayjs);
          }}
        />
      </Space>
    </DateViewPickerStyled>
  );
}

export default TimeScheduleDateView;

const IconPrev = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.3101 18L9.31006 12L15.3101 6'
        stroke='#363565'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const IconNext = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.31006 18L15.3101 12L9.31006 6'
        stroke='#363565'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
const InputPickerStyled = styled.div`
  display: flex;
  align-items: center;
  button {
    width: 50px;
  }
`;
const RangePickerStyled = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const DateViewPickerStyled = styled.div`
  .ant-input,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-number .ant-input-number-input,
  .ant-select-single .ant-select-selector,
  .ant-picker {
    padding: 0;
  }

  
`;
