import fetch from 'services/request';
import { IProfileRequest } from './types/request';

const updateProfileGeneral = (body: IProfileRequest) => {
    return fetch({
        method: 'post',
        url: 'api/v1/auth/update-profile',
        body: {...body},
      });
};

const updateProfilePassword = (body: IProfileRequest) => {
    return fetch({
        method: 'post',
        url: 'api/v1/auth/update-password',
        body: {...body},
      });
};

const uploadAvatar = (body: any) => {
    return fetch({
        method: 'post',
        url: 'api/v1/auth/update-avatar',
        body: {...body},
    });
};

const deleteAvatar = () => {
    return fetch({
        method: 'delete',
        url: 'api/v1/auth/delete-avatar',
    });
};

const apisEditProfile = {
    updateProfileGeneral,
    updateProfilePassword,
    uploadAvatar,
    deleteAvatar
};

export default apisEditProfile;