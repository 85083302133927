import { useAppSelector } from 'store/hooks';
import { get } from 'lodash';
import { RootState } from 'store';
import { INotificationParam } from './types/notification';
import uiSelector from 'services/UI/selectors';
import { PATH_LOADING } from './constants';

type MyState = RootState['notification'];
const getCurrentState = (state: RootState): MyState => state.notification;
const selector = <KEY = keyof MyState>(key: KEY, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key as any, defaultValue));

const getNoificationList = () => selector('notificationList') as MyState['notificationList'];
const getParams = () => selector('params') as INotificationParam;
const loadingNotification = () => uiSelector.getLoading(PATH_LOADING.getListNotification);

const notificationSelectors = {
    getNoificationList,
    getParams,
    loadingNotification
  };
  
  export default notificationSelectors;