import { Form, Modal } from 'antd';
import OTP from 'components/common/OTP';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';

type IconType = 'delete';

type IConfigs = { title: string, msg: string, submit: any, icon?: JSX.Element | null | IconType };
export type ModalConfirmSignInRef = {
  show: (configs: IConfigs) => void
  hide: () => void;
}
type Props = {
  usePinVerify?: boolean;
  titleClose?: string;
  showClose?: boolean;
};
/* eslint-disable-next-line */
const ModalConfirmSignIn = forwardRef<ModalConfirmSignInRef, Props>(({ usePinVerify = true, titleClose = '', showClose = true }, ref) => {

  const [visible, setVisible] = useState(false);
  const memoRef = useRef<IConfigs>({
    title: '', msg: '', submit: () => undefined,
    icon: null,
  });

  const hide: ModalConfirmSignInRef['hide'] = () => setVisible(false);
  const show: ModalConfirmSignInRef['show'] = (configs) => {
    memoRef.current = {
      ...configs,
    };
    setVisible(true);
  };

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const { submit = () => undefined, msg, title } = memoRef.current ?? {};  

  const onSubmit = (values: any) => {    

    const pinValue = `${values._one}${values._two}${values._three}${values._four}`;

    submit({
      pin: pinValue
    });

    hide();
  };

  return (
    <>
      <Modal
        maskClosable={false}
        open={visible}
        onCancel={hide}
        okButtonProps={{ style: { display: 'none' } }}
        width={574}
        footer={null}
        centered
        forceRender
      >
        <Form 
          layout='vertical'
          onFinish={onSubmit}
        >
          <div className='modal-delete-confirm'>
            <h2 className='modal-title'>{title}</h2>
            <p className='modal-text'>{msg}</p>
            <OTP size='big' />

            <div className='form-submit-wrapper'>

              {showClose && <button
                onClick={hide}
                type='button'
                className='common-btn is-white'
              >
                {titleClose || 'Close'}
              </button>}
              <button
                // onClick={onSubmit}
                
                type='submit'
                className='common-btn'
              >
                Confirm
              </button>
            </div>
          </div>
        </Form>
        
      </Modal>

    </>
  );
});

export default ModalConfirmSignIn;
