import { VIEW } from 'constants/view.enum';
import bookingSelectors from 'features/bookings/services/selectors';
import React, { memo } from 'react';
import BookingsGrid from '../BookingsGrid';
import BookingsTable from '../BookingsTable';
import BookingCalendar from '../BookingCalendar';

const BookingLayout: React.FC = () => {
  const currentView = bookingSelectors.getBookingLayout();
  
  return (
    <>
      {currentView === VIEW.GRID && <BookingsGrid />}
      {currentView === VIEW.LIST && <BookingsTable />}
      {currentView === VIEW.CALENDAR && <BookingCalendar />}
    </>
  );
};

export default memo(BookingLayout);
