import styled from 'styled-components';

export const HBox = styled.div`
  display:flex;
  gap: 12px;
`;

export const ButtonIconStyled = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 20px;
  border: 1px solid #4A9D77;
  border-radius: 6px;
`;