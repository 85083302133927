import { IconRemove } from 'features/checkout/components/FeeButton';
import salesActions from 'features/sales/services/actions';
import salesSelectors from 'features/sales/services/selectors';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import { formatMoney } from 'utils/unit';

const CashResultRow = ({viewOnly}: {viewOnly: boolean}) => {

  const cash = salesSelectors.getPurchaseCurrentCash() ?? 0;
  const total = salesSelectors.getOriginTotalPrice();
  const voucherValue = salesSelectors.getVoucherValue();

  const amount = total - voucherValue;

  const dispatch = useAppDispatch();
  
  
  const onRemove = () => {
    dispatch(salesActions.setPurchaseCash(0));
  };  

  return (
    <div>
      {(cash ?? 0) <= 0 ? <></> :
         cash && <div className='extra-price-row'>
          <div className='label'>
            {!viewOnly &&
            <button type='button' className='button' onClick={onRemove}><IconRemove /></button>}
            Cash
          </div>

          <span> {formatMoney(cash > amount ? amount : cash)}</span>
        </div>
      }
    </div>
  );
};

export default CashResultRow;