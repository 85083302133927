import moment, { Moment } from 'moment';

const getSingleDateFormat = (_date: Moment) => {
    const today = moment();
    if (_date.isSame(today, 'day')) {
      return 'Today, ' + _date.format('DD.MM.YYYY');
    }

    if (_date.isSame(today.clone().subtract(1, 'days'), 'day')) {
      return 'Yesterday, ' + _date.format('DD.MM.YYYY');
    }

    if (_date.isSame(today.clone().add(1, 'days'), 'day')) {
      return 'Tomorrow, ' + _date.format('DD.MM.YYYY');
    }

    return _date.format('dddd, DD.MM.YYYY');
};

export default getSingleDateFormat;