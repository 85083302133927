import { Space } from 'antd';
import { EBookingStatus } from 'constants/index';
import bookingActions from 'features/bookings/services/actions';
import { IBookingItemResData } from 'features/bookings/services/types/booking';
import { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { ANONYMOUS_NAME, CUSTOMER_WALKIN_INDEX, formatMoney, roundNumber, statusTagBackground } from 'utils/unit';
import PopoverEdit from '../BookingCalendar/Table/PopoverEdit';
import { ButtonSuccess } from '../BookingCalendar/Table/UIBookingItem/styles';
import { sumBy } from 'lodash';
import getSingleDateShortFormat from 'utils/moment/getSingleDateShortFormat';
import moment from 'moment';
import ModalSendInvoiceEmail, { ICustomerSendEmail } from 'components/common/Modal/ModalSendInvoiceEmail';
import invoiceActions from 'features/invoices/services/actions';
import ModalRefund from 'components/common/Modal/ModalRefund';
import styled from 'styled-components';
import { IResultItem } from 'features/invoices/components/Forms/ServiceDetails';

type Props = {
  data: IBookingItemResData;
};

const BookingCard = (props: Props) => {
  const { data } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenHandInvoice, setIsOpenHandInvoice] = useState<boolean>(false);
  const [isOpenRefund, setIsOpenRefund] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleCancelSendInvoice = () => {
    setIsOpenHandInvoice(false);
  };

  const {
    book_status,
    book_assignment_services = [],
    id,
  } = data ?? {};
  const dispatch = useAppDispatch();
  const checkIn = () => {
    if(data?.book_assignment_services?.length === 0) {
      navigate(`/private/bookings/edit-booking?bookId=${id}`);
      return;
    }
    dispatch(bookingActions.checkInBooking(String(id)));
  };

  const clickRunf = () => {
    setIsOpenRefund(true);
    dispatch(invoiceActions.setInvoiceDetailId(data?.invoice_code ?? ''));
  };

  const getButton = () => {
    // if (book_assignment_services.length === 0) return (
    //   <Link to={`/private/bookings/edit-booking?bookId=${id}`}>
    //     <ButtonSuccess type='button'>Edit Booking</ButtonSuccess>
    //   </Link>
    // );

    switch (book_status) {
      case EBookingStatus.OPEN:
        return (
          <ButtonSuccess type='button' onClick={checkIn}>
            Check-in
          </ButtonSuccess>
        );
      case EBookingStatus.IN_PROGRESS: {
        return (
          <Link to={`/private/bookings/checkout/${id}`}>
            <ButtonSuccess type='button'>Check-out</ButtonSuccess>
          </Link>
        );
      }
      case EBookingStatus.COMPLETED:
        return (
          <Space direction='horizontal'>
            <ButtonRefundStyled>
              {data?.refunds?.length === 0 && data.customer.is_walkin_in !== CUSTOMER_WALKIN_INDEX && <div className='common-btn is-outlined' style={{flex: '1'}} onClick={() => clickRunf()}>Refund</div>}
              <ButtonSuccess type='button' onClick={() => setIsOpenHandInvoice(true)}>Send Invoice</ButtonSuccess>
            </ButtonRefundStyled>
          </Space>
        );
      case EBookingStatus.CANCELLED:
      case EBookingStatus.NO_SHOW:
        return null;
      case EBookingStatus.DRAFT:
        return (
          <Link to={`/private/bookings/edit-booking?bookId=${id}`}>
            <ButtonSuccess type='button'>Edit Booking</ButtonSuccess>
          </Link>
        );
      default:
        return null;
    }
  };

  const total = useMemo(() => {
    return sumBy(data.book_assignment_services, o => o.quantity * o.service_price);
  }, [data]);

  const currentDateBooking = useMemo(() => {
    return moment(data?.time_start, 'YYYY-MM-DD HH:mm:ss');
  }, [data?.time_start]);

  const ItemTotals = () => {

    const dataInvoice = data.book_invoice;


    const getValuePercent = (percent: number) => {    

      return roundNumber((dataInvoice?.subtotal ?? 0)*(percent/100));
    }; 
    
    const extraFeeValue = (dataInvoice?.extra_fee?.fee_type ?? 'amount') === 'percent' ? getValuePercent(Number(dataInvoice?.extra_fee?.fee_value ?? 0)) : Number(dataInvoice?.extra_fee?.fee_value) ?? 0;
    const discountFeeValue = (dataInvoice?.discount_type ?? 'amount') === 'percent' ? 
      ((dataInvoice?.subtotal ?? 0) + extraFeeValue)*( Number(dataInvoice?.discount_value ?? 0 )/100):
      Number(dataInvoice?.discount_value) ?? 0;    

    return <>
      {
        [
          {
            label: 'Extra fee',
            value: extraFeeValue,
            isPlus: true,
            customName: dataInvoice?.extra_fee?.name
          },
          {
            label: 'Extra discount',
            value: discountFeeValue,
            isMinus: true,
            // customName: data?.extra_fee?.name
          },
          {
            label: 'Reward',
            value: dataInvoice?.reward?.total_amount_reward,
            isMinus: true,
          },
          {
            label: 'Promotion',
            value: dataInvoice?.total_apply_promotion,
            isMinus: true,
          },
          {
            label: 'Public Holiday charge',
            value: roundNumber(dataInvoice?.holiday_public?.total_amount_surcharge ?? 0),
          },
          {
            label: 'Card surcharge',
            value: roundNumber(dataInvoice?.card_surcharge_price ?? 0),
          },
          {
            label: 'Voucher',
            value: dataInvoice?.total_amount_apply_voucher,
            isMinus: true,
          },
          {
            label: 'Refund' + (dataInvoice?.refunds && dataInvoice?.refunds?.length > 0 ? ' - ' + dataInvoice?.refunds[0]?.refund_method : ''),
            value: dataInvoice?.refunds && dataInvoice?.refunds?.length > 0 ? dataInvoice?.refunds[0].amount_refund : 0,
            isMinus: true,
          },
        ].map( ({ label, value, isMinus = false, isPlus = false, isFinalTotal = false, customName = undefined }: IResultItem) => {
          return value > 0 || isFinalTotal ? (
            <div>
              <p className='subtotal-label'>{customName ?? label}</p>
              <p className='subtotal-price'>{isMinus ? '-' : ''} {isPlus ? '+' : ''} {formatMoney(value)}</p>
            </div>
          ) : <></>;
        })
      }
    </>;
  //   <div key={index}>
  //   <p className={'subtotal-label'}>{label}</p>
  //   <p className={'subtotal-price'}>{formatMoney(value)}</p>
  // </div>
  };

  return (
    <BookingCardStyled className={`common-booking-card ${book_status}`}>
      <div className={'item-heading'}>
        <div className={'heading-avatar'}>{data?.customer?.name[0].toUpperCase()}</div>
        <div className={'heading-detail'}>
          <p className={'heading-title'}>{data?.customer?.name}</p>
          {/* <p>
            Customer ID: <span>{data?.customer?.customer_code}</span>
          </p> */}
          <p>
            Start time: <span>{getSingleDateShortFormat(currentDateBooking)} &bull;  {currentDateBooking.format('HH:mm')}</span>
          </p>
        </div>
        <div className={`status-tag ${statusTagBackground(data?.book_status)}`}>
          {data?.book_status.replace('_', ' ')}
        </div>
        <PopoverEdit
          bookId={data?.id}
          bookStatus={data.book_status}
          data={data}
        />
      </div>
      <div className={'item-services'}>
        <div className='view-span'>
          <ul className={`services-list ${!isOpen ? 'is-close' : ''}`}>
            {book_assignment_services.length > 0 ? (
              book_assignment_services.map((book) => {

                const {
                  serivce_variant_name,
                  service_name,
                  // duration_time,
                  assigned_employee,
                  quantity,
                  service_price,
                } = book;
                return (
                  <li key={service_name}>
                    <div className='service-left'>
                      <span
                        className='service-type'
                        style={{ backgroundColor: book.category_service_color }}
                      ></span>
                      <div>
                        <p className={'service-name'}>{service_name}</p>
                        <p className={'service-time'}>
                          <span>{serivce_variant_name || service_name}&nbsp;</span>
                          {/* <span>{formatTimeMinutes(duration_time)}</span>  */}
                          with
                          <span>&nbsp; {assigned_employee?.employee?.full_name ||ANONYMOUS_NAME }</span>
                        </p>
                      </div>
                    </div>
                    <span className='service-quantity'>{quantity}</span>
                    
                    <span className='service-price'>{formatMoney(service_price)}</span>
                  </li>
                );
              })
            ) : (
              <div className='service-item'>
                <span className='name'>No services</span>
              </div>
            )}
          </ul>

          {book_assignment_services.length > 1 && <div className='accordion-control'>
            <span className='control-btn' onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? 'Close' : 'View more'}
            </span>
          </div>}
        </div>
        <div className="booking-summary">
          {data.book_status === EBookingStatus.COMPLETED && 
            <>
              <div>
                <p className={'subtotal-label'}>Subtotal</p>
                <p className={'subtotal-price'}>{data.book_invoice ? formatMoney(data.book_invoice.subtotal) : formatMoney(total)}</p>
              </div>
              <ItemTotals />
            </>}
          <div>
            <p className={'total-label'}>TOTAL</p>
            <p className={'total-price'}>{formatMoney(data.book_status === EBookingStatus.COMPLETED ? data.book_invoice.total_price : total)}</p>
          </div>
        </div>
        <div className='card-btn'>
          {getButton()}
          {isOpenHandInvoice && <ModalSendInvoiceEmail
            handleCancel={handleCancelSendInvoice}
            isOpen={isOpenHandInvoice}
            customer={data?.customer as ICustomerSendEmail}
            invoice_id={data?.invoice_code ?? ''} />}

          <ModalRefund open={isOpenRefund} total={total} onClose={() => setIsOpenRefund(false)} />
        </div>
      </div>
    </BookingCardStyled >
  );
};

export default BookingCard;


const ButtonRefundStyled = styled.div`
display: flex;
gap: 8px;
.common-btn {
  min-width: 0px;
  padding: 10px 24px;
}
`;

const BookingCardStyled = styled.li`
  .booking-summary {
    border-top: 1px solid var(--color-white-01);
    padding: 15px 0;
    .total-label {
      font-weight: 500;
      font-size: 16px;
    }
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .total-price {
      font-weight: 600;
      font-size: 24px;
      color: var(--color-green);
    }
  }
`;