import { DatePicker, Select, Space } from 'antd';
import { CalendarViewType } from 'constants/index';
import dayjs from 'utils/dayjs';
import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
import { IApiBookingParams } from 'features/bookings/services/types/booking';
import { IOption } from 'models/config';
import moment from 'moment';
import { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import getArrayDatesOfWeek from 'utils/moment/getDatesOfWeek';
import { VIEW } from 'constants/view.enum';

const viewOptions: IOption[] = [
  {
    label: 'Week view',
    value: CalendarViewType.DaysView,
  },
  {
    label: 'Day view',
    value: CalendarViewType.MemberView,
  },
];

type Props = {
  isNoSelect?: boolean;
  isNoDatePicker?: boolean;
};

function DateViewPicker(props: Props) {
  const { isNoSelect, isNoDatePicker } = props;
  const viewType = bookingSelectors.getCalendarViewType();
  const dispatch = useAppDispatch();
  const dateStore = bookingSelectors.getCalendarParamValue('date') as number;
  const previousDate = bookingSelectors.getCalendarViewPreviousDate() as number;
  const bookingLayout = bookingSelectors.getBookingLayout();
  const [date, setDateState] = useState(dateStore);

  const activeLocation = bookingSelectors.getCalendarCurrentLocation();

  const startDateStore = bookingSelectors.getCalendarParamValue(
    'start_date'
  ) as number;
  const endDateStore = bookingSelectors.getCalendarParamValue(
    'end_date'
  ) as number;

  useEffect(() => {
    if(bookingLayout !== VIEW.GRID && bookingLayout !== VIEW.LIST) return;

    if(viewType !== CalendarViewType.DaysView ) return;

      const current = moment().valueOf();
      dispatch(
        bookingActions.setCalendarViewType(CalendarViewType.MemberView)
      );  
      dispatch(
        bookingActions.setCalendarBookingParams({
          start_date: undefined,
          end_date: undefined,
          date: current,
        })
      );
      dispatch(
        bookingActions.setListTableBookingParams({
          start_date: undefined,
          end_date: undefined,
          date: current,
        })
      );
      setDateState(current);
  }, [bookingLayout]);

  const onChangeDate = (_date: number, addOnParams?: IApiBookingParams) => {
    dispatch(
      bookingActions.setCalendarBookingParams({
        start_date: undefined,
        end_date: undefined,
        date: _date,
        ...(addOnParams ?? {}),
      })
    );
    dispatch(
      bookingActions.setListTableBookingParams({
        start_date: undefined,
        end_date: undefined,
        date: _date,
        ...(addOnParams ?? {}),
      })
    );
    setDateState(_date);
  };

  const onChangeDateRange = (_date: number) => {
    if (!_date) return;
    const currentDate = moment(_date);
    if (currentDate.isoWeekday() === 7) {
      currentDate.subtract(1, 'day');
    }
    const _daysOfWeek = getArrayDatesOfWeek(currentDate.format());
    dispatch(bookingActions.setCalendarDaysOfWeek(_daysOfWeek));
  };



  const onChangeViewType = (value: CalendarViewType) => {
    switch (value) {
      case CalendarViewType.MemberView: {
        let _date = (dateStore ?? 0) as number;
        if (!_date) {
          _date = previousDate ?? (startDateStore ?? moment().valueOf());
        }
        dispatch(
          bookingActions.setCalendarViewType(CalendarViewType.MemberView)
        );
        localStorage.setItem('calendarViewType', value);
        onChangeDate(_date, {
          employee_id: undefined,
        });
        break;
      }
      case CalendarViewType.DaysView: {
        dispatch(bookingActions.setCalendarViewType(CalendarViewType.DaysView));
        localStorage.setItem('calendarViewType', value);
        onChangeDateRange(dateStore);
        break;
      }
      default:
        break;
    }
  };

  const getSingleDateFormat = () => {
    const _date = moment(date);
    const today = moment();
    if (_date.isSame(today, 'day')) {
      return 'Today, ' + _date.format('DD.MM.YYYY');
    }

    if (_date.isSame(today.clone().subtract(1, 'days'), 'day')) {
      return 'Yesterday, ' + _date.format('DD.MM.YYYY');
    }

    if (_date.isSame(today.clone().add(1, 'days'), 'day')) {
      return 'Tomorrow, ' + _date.format('DD.MM.YYYY');
    }

    return _date.format('dddd, DD.MM.YYYY');
  };

  const date_closed = useMemo(() => {
    return activeLocation?.date_closed.map(o => ({
      start: dayjs(o.start_date, 'YY-MM-DD'),
      end: dayjs(o.end_date, 'YY-MM-DD'),
    }));
  }, [activeLocation?.date_closed]);

  const [open, setOpen] = useState(false);

  const onPressPrev: MouseEventHandler<HTMLButtonElement> = (e) => {
    setOpen(false);
    e.stopPropagation();
    if (viewType === CalendarViewType.MemberView) {
      onChangeDate(moment(date).subtract(1, 'day').valueOf());
      return;
    }
    onChangeDateRange(moment(startDateStore).subtract(7, 'day').valueOf());
  };

  const onPressNext: MouseEventHandler<HTMLButtonElement> = (e) => {
    setOpen(false);
    e.stopPropagation();
    if (viewType === CalendarViewType.MemberView) {
      onChangeDate(moment(date).add(1, 'day').valueOf());
      return;
    }
    onChangeDateRange(moment(startDateStore).add(7, 'day').valueOf());
  };

  return (
    <DateViewPickerStyled>
      <Space direction='horizontal'>
        {!isNoDatePicker && (
          <>
            {viewType === CalendarViewType.MemberView && (
              <DatePicker
                className='is-normal'
                allowClear={false}
                open={open}
                onOpenChange={setOpen}
                value={dayjs(moment(date).format())}
                suffixIcon={false}
                inputRender={(props: any) => (
                  <InputPickerStyled>
                    <button onClick={onPressPrev}><IconPrev /></button>
                    <RangePickerStyled {...props}>
                      <p>{getSingleDateFormat()}</p>
                    </RangePickerStyled>
                    <button onClick={onPressNext}><IconNext /></button>
                  </InputPickerStyled>
                )}
                onChange={(_dayjs) => {
                  if (!_dayjs) return;
                  onChangeDate(_dayjs.valueOf());
                }}
                disabledDate={d => {
                  const isDateClosed = !!date_closed?.some(o => d.format('YY-MM-DD') == o.start.format('YY-MM-DD') || d.isBetween(o.start, o.end));
                  if (isDateClosed) return true;

                  return !activeLocation?.time_opening.some(o => o?.weekday === d?.format('dddd')?.toLowerCase());
                }}
              />
            )}

            {viewType === CalendarViewType.DaysView && (
              <DatePicker
                className='is-normal'
                allowClear={false}
                open={open}
                onOpenChange={setOpen}
                suffixIcon={false}
                value={dayjs(moment().format())}
                inputRender={(props: any) => (
                  <InputPickerStyled>
                    <button onClick={onPressPrev}><IconPrev /></button>
                    <RangePickerStyled {...props}>
                      <p>
                        {moment(startDateStore).format('DD.MM')} -{' '}
                        {moment(endDateStore).format('DD.MM.YYYY')}
                      </p>
                    </RangePickerStyled>
                    <button onClick={onPressNext}><IconNext /></button>
                  </InputPickerStyled>
                )}
                onChange={(_dayjs) => {
                  if (!_dayjs) return;
                  onChangeDateRange(_dayjs.valueOf());
                }}
              />
            )}
          </>
        )}
        {!isNoSelect && (
          <Select
            value={viewType}
            options={viewOptions}
            onChange={onChangeViewType}
            className='is-normal is-white'
          />
        )}
      </Space>
    </DateViewPickerStyled>
  );
}

export default DateViewPicker;

const IconPrev = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.3101 18L9.31006 12L15.3101 6'
        stroke='#363565'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const IconNext = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.31006 18L15.3101 12L9.31006 6'
        stroke='#363565'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
const InputPickerStyled = styled.div`
  display:flex;
  align-items:center;
  button {
    width:50px;
  }
`;
const RangePickerStyled = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const DateViewPickerStyled = styled.div`
  .ant-input,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-number .ant-input-number-input,
  .ant-select-single .ant-select-selector,
  .ant-picker {
    padding: 0;
  }
`;
