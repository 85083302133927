import { VIEW } from 'constants/view.enum';
import bookingSelectors from 'features/bookings/services/selectors';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import DateViewPicker from './DateViewPicker';
import LocationPicker from './LocationPicker';
import StatusPicker from './StatusPicker';
import TeamMemberSelect from './TeamMemberSelect';
import ZoomCalendar from './ZoomCalendar';

type Props = {
  isShow?: boolean;
  onClose?: () => void;
};

const FilterModal = (props: Props) => {
  const navigate = useNavigate();
  const { isShow, onClose } = props;
  const currentView = bookingSelectors.getBookingLayout();

  const FilterElements = useMemo(() => {
    switch (currentView) {
      case VIEW.GRID:
      case VIEW.LIST:
        return (
          <>
            <div className='item-filter'>
              <p className='filter-label'>Location</p>
              <LocationPicker />
            </div>
            <div className='item-filter'>
              <p className='filter-label'>Status</p>
              <StatusPicker />
            </div>
          </>
        );
      case VIEW.CALENDAR:
        return (
          <>
            <div className='item-filter'>
              <p className='filter-label'>Location</p>
              <LocationPicker />
            </div>
            <div className='item-filter'>
              <p className='filter-label'>Calendar View</p>
              <DateViewPicker isNoDatePicker={true} />
            </div>
            <div className='item-filter'>
              <p className='filter-label'>Team member</p>
              <TeamMemberSelect />
            </div>
            <div className='item-filter'>
              <ZoomCalendar />
            </div>
          </>
        );
      default:
        return <></>;
    }
  }, [currentView, navigate]);

  return (
    <FilterModalStyled
      className={`${isShow ? 'is-show' : ''}`}
      onClick={onClose}
    >
      <div className='modal-inner' onClick={(e) => e.stopPropagation()}>
        <div className='heading'>
          <h2 className='modal-title'>Filter</h2>
          <span className='modal-close' onClick={onClose}></span>
        </div>
        <div className='body'>{FilterElements}</div>
        <div className='footer'>
          <div className='common-btn is-white'>Clear</div>
          <button type='submit' className='common-btn'>
            Search
          </button>
        </div>
      </div>
    </FilterModalStyled>
  );
};

export default FilterModal;

const FilterModalStyled = styled.div`
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  &.is-show {
    opacity: 1;
    z-index: 1000;
    visibility: visible;
  }
  .modal-inner {
    display: flex;
    flex-direction: column;
    width: 296px;
    background: #fff;
    margin-left: auto;
    height: 100%;
    padding-bottom: 24px;
    @media only screen and (max-width: 767.98px) {
      padding-bottom: 80px;
    }
  }
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #f0f0f0;
  }
  .modal-title {
    margin-bottom: 0;
    border-bottom: none !important;
  }
  .modal-close {
    display: block;
    width: 16px;
    height: 16px;
    background: var(--icon-menu-close) center/contain no-repeat;
    border: none;
    top: 50px;
    right: 36px;
    cursor: pointer;
  }
  .body {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
  }
  .item-filter {
  }
  .filter-label,
  .menu-title {
    font-weight: 600;
    font-size: 14px;
    color: #363565;
    margin-bottom: 8px;
  }
  .ant-select.is-normal.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    min-width: 248px;
    min-height: 64px;
    background: #f0f2f7;
    border-radius: 6px;
  }
  .footer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    flex: 1;
    position: relative;
    margin-top: auto;
    padding: 0 24px;
  }
  .common-btn {
    min-width: 126px;
  }
`;
