export const NAME_REDUCER = 'loyal';

export const PREFIX_ACTIONS = 'LOYAL_FEATURE_';

export const PATH_LOADING = {
  getLoyalProgram: `loading.${NAME_REDUCER}.getLoyalProgram`,
  getLoyalReward: `loading.${NAME_REDUCER}.getLoyalReward`,
};

export const EARN_POINT_PROGRAM_TYPE = {
  VISIT: 'visit',
  AMOUNT_SPENT: 'amount_spent',
};

export const CHOOSE_CATEGORY_REWARD = {
  FREE_SERVICE: 'free_service',
  REWARD: 'reward'
};

export const CHOOSE_METHOD_NOTIFY = {
  SMS: 'sms',
  EMAIL: 'email'
};