import { Switch } from 'antd';
import message from 'components/common/Message';
import { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import loyalActions from 'features/loyaltyProgram/services/actions';
import apisLoyal from 'features/loyaltyProgram/services/apis';
import { IRewardItemResData } from 'features/loyaltyProgram/services/types/reward';
import { get } from 'lodash';
import React from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
type Props = {
  data: IRewardItemResData;
  confirmRef?: React.RefObject<ModalConfirmRef>;
};
const RewardSwitchStatus = ({ data, confirmRef }: Props) => {
  const dispatch = useAppDispatch();
  const setLoadingPage = useSetLoadingPage();
  const handleUpdateStatus = async () => {
    setLoadingPage(true);
    try {
      const res = await apisLoyal.updateStatusReward(data?.id?.toString(), !data.status);
      const msg = get(res, 'data.message', '');
      if (msg) {
        dispatch(loyalActions.getListLoyalReward.fetch({}));
        message.success(msg);
      } else {
        const msgError = get(res, 'data.error.message', '');
        if (msgError) message.error(msgError);
        else throw 'fail';
      }
    } catch (error) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setLoadingPage(false);
    }
  };

  const onClick = () => {
    if (!confirmRef) return;
    confirmRef.current?.show({
      title: 'Are you sure',
      msg: `Are you sure ${!data.status ? 'enable' : 'disable'} this reward?`,
      submit: handleUpdateStatus,
    });
  };
  return (
    <Switch onClick={onClick} checked={!!data.status} />
  );
};

export default RewardSwitchStatus;
