import { Button, Form, Modal, Rate, UploadFile } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Upload, { RcFile, UploadProps } from 'antd/es/upload';
import ReviewImg from 'assets/images/customers/review_img.svg';
import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import Header from 'features/bookings/components/BookingForm/Information/Header';
import StickyPromotion from 'features/bookings/components/BookingForm/StickyPromotion';
import { get, set } from 'lodash';
import React, { memo, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import styled from 'styled-components';
import { formOptionalText } from 'utils/unit';
import reviewApis from '../services/apis';
import { PlusOutlined } from '@ant-design/icons';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const ForCustomerPage: React.FC = () => {
  const { customer_code = '', merchant_code = '', invoice_code = '' } = useParams();
  const [form] = Form.useForm();
  const setLoadingPage = useSetLoadingPage();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSuccess, setSuccess] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage ] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const handleSubmit = async (values: any) => {
    const params = { merchant_code, customer_code, invoice_code };
    const formData = new FormData();
    formData.append('rating', values?.rating ?? 0);
    formData.append('content', values?.content ?? '');
    fileList?.map((file) => {
      formData.append('images[]', file.originFileObj as RcFile);
    });
    setLoadingPage(true);
    try {
      const rest: AxiosResponse<{ message: string }> = await reviewApis.makeReview(params, formData);
      const msg = get(rest,'data.message','');
      if (msg) {
        // message.success(msg);
        setSuccess(true);
        return true;
      } else {
        throw 'fail';
      }
    } catch (error: any) {
      const textError = get(error, 'response.data.error.message', '');
      if (textError) {
        message.error(textError);
      }

      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.entries(response);
      const _errors = {};
      errors.map(([key, value]) => {
        const msg = get(value, [0], '');
        if (['merchant_code', 'customer_code', 'invoice_id'].includes(key as string))
          message.error(msg);
        else {
          set(_errors, key, msg);
        }
      });

      setErrors(_errors);

      return false;
    } finally {
      setLoadingPage(false);
    }
  };

  // const handlePreview = async (file: UploadFile) => {
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj as RcFile);
  //   }

    
  //   setPreviewImage(file.url || (file.preview as string));
  //   setPreviewOpen(true);
  //   setPreviewTitle(
  //     // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //     file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
  //   );
  // };


  const errorsText = useMemo(() => ({
    rating: get(errors, 'rating', ''),
    content: get(errors, 'content', ''),
  }), [errors]);

  return (
    <ForCustomerPageStyled className=''>
      <StickyPromotion label='Some promotion - book something get another thing free!' />
      <div className='form_container'>
        {/* {getTab()} */}
        <Form
          className='form-info'
          form={form}
          onFinish={handleSubmit}
          scrollToFirstError
          disabled={isSuccess}
          onValuesChange={() => setErrors({})}
          layout='vertical'>
          <Header
            title={'Thank you for visiting our store'}
            text={
              'We are pleased to receive your feedback. This is important to us.'
            }
          />
          <div className='review-block'>
            <div className='block-inner'>
              <div className='block-image'>
                <img src={ReviewImg} alt='ReviewImg' />
              </div>
              <h2 className='block-title'>Rating your exerience with us</h2>
              <Form.Item name={'rating'}
                validateStatus={errorsText?.rating ? 'error' : undefined}
                help={errorsText?.rating ? errorsText?.rating : undefined}
                rules={[
                  {
                    validator(rule, value) {
                      if (value > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The rating must be between 1 and 5.'));
                    },
                  }
                ]}
              >
                <Rate className='rate-review' disabled={isSuccess} />
              </Form.Item>
              {isSuccess ? <>
                <p className='label'>{form.getFieldValue('content')}</p>
              </> : <Form.Item
                label={
                  <p>
                    Your message<span>{formOptionalText}</span>
                  </p>
                }
                name={'content'}
                validateStatus={errorsText?.content ? 'error' : undefined}
                help={errorsText?.content ? errorsText?.content : undefined}
              >
                <TextArea
                  placeholder='Enter your message to improve our service'
                  rows={4}
                ></TextArea>
              </Form.Item>}
              <Form.Item className='form-upload' name={'images'}>
                <UploadStyled
                  beforeUpload={() => false}
                  multiple
                  className='reviews-tab'
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  disabled={isSuccess}
                >
                  {!isSuccess && <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Attachment</div>
                  </div>}
                </UploadStyled>
              </Form.Item>
            </div>
          </div>
          {!isSuccess && <Button className='review-btn' htmlType='submit'>Review</Button>}
        </Form>
      </div>
      <Modal centered open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </ForCustomerPageStyled>
  );
};

export default memo(ForCustomerPage);

const UploadStyled = styled(Upload)`
  .ant-upload-list-item-container {
    width: 120px !important;
    height: 120px !important;
    margin-right: 10px;

    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
`;

const ForCustomerPageStyled = styled.div`
  background: var(--color-white-01);
  min-height: 100vh;
  font-family: var(--font-base);
  @media only screen and (min-width: 1025px) {
    padding-bottom: 80px;
  }
  @media only screen and (max-width: 767.98px) {
    padding-bottom: 20px;
  }
  .form_container {
    padding-top: 24px;
    max-width: 1166px;
    margin: 0 auto;
    padding: 24px;

    @media only screen and (min-width: 768px) and (max-width: 1024.98px) {
      padding: 40px 58px;
    }

    @media only screen and (max-width: 767.98px) {
      padding: 40px 12px 0;
    }
  }
  .hide {
    display: none;
  }

  .review-block {
    max-width: 1166px;
    background: #ffffff;
    border-radius: 6px;
    padding: 48px 24px;
    text-align: center;
  }
  .block-image {
  }
  .block-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    color: #363565;
    margin: 65px 0 25px;
  }
  .block-inner {
    max-width: 526px;
    margin: 0 auto;
  }
  .review-btn {
    max-width: 502px;
    width: 100%;
    height: 64px;
    color: #fff;
    background: #363565;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin: 70px auto 0;
    border: 2px solid #363565;
    &:hover {
      color: #363565;
      background: #fff;
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .form-upload {
    text-align: left;
    .ant-btn-default {
      border: none;
      width: 140px;
      height: 42px;
      padding: 0 10px;
      display: flex;
      gap: 10px;
      box-shadow: none;
    }
    .ant-upload-wrapper {
      display: flex;
      align-items: center;
      .ant-upload-list .ant-upload-list-item {
        margin-top: 0;
        height: 100%;
        .ant-upload-list-item-name {
          font-weight: 500;
          font-size: 16px;
          line-height: 140%;
          color: #363565;
        }
      }
      .ant-upload-icon {
        display: none;
      }
    }
  }

  .ant-upload-wrapper .ant-upload-list {
    height: auto;
    width: auto;
  }


  .rate-review {
    &.ant-rate .ant-rate-star:not(:last-child) {
      margin-right: 12px;
    }
    .anticon {
      width: 48px;
      height: 48px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .label {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: var(--color-primary);
    @include mobile {
      font-size: 14px;
      text-align: left;
      margin-right: 20px;
    }
  }
`;
