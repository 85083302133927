import { createSlice } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import moment from 'moment';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types';
import { IInvoiceResData, IResInvoiceItem } from './types/invoice';
import authActions from 'features/auth/services/actions';

const initialState: IState = {
  invoices: [],
  categories: [],
  pagination: null,
  invoiceParams: {
    category_id: undefined,
  },
  detail: {
    data: null,
    id: null,
  }
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActions.logout.success, (state) => {
        state.invoices = [];
        state.categories = [];
        state.invoiceParams = {
          category_id: undefined,
        };
        state.detail = {
          data: null,
          id: null,
        };
      })
      .addCase(actions.getInvoices.success, (state, { payload }) => {
        const data = payload as IInvoiceResData;
        const invoices = (data.invoices.data ?? []);
        state.pagination = data.invoices.pagination;
        state.invoices = orderBy(invoices, o => moment(o.date_book).valueOf(), 'desc');
        state.categories = data.categories ?? [];
      })
      .addCase(actions.setInvoiceParams, (state, { payload }) => {
        state.invoiceParams = {
          ...state.invoiceParams,
          ...payload,
        };
      })
      .addCase(actions.getInvoiceDetail.success, (state, { payload }) => {
        const data = payload as IResInvoiceItem;
        state.detail.data = data;
      })
      .addCase(actions.setInvoiceDetailId, (state, { payload }) => {
        state.detail.id = payload;
      })
      ;
  },
});
const invoiceServiceReducer = Slice.reducer;
export default invoiceServiceReducer;
