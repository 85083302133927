import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IApiAnalyticParam } from './types/api';


const getAnalytics = createAsyncAction<IApiAnalyticParam>(PREFIX_ACTIONS + 'GET_ANALYTICS');


const analyticActions = {
  getAnalytics,
};

export default analyticActions;
