import React from 'react';

export enum BOOKING_FORM {
  INFORMATION = 1,
  CONFIRM = 2,
  SUCCESS = 3,
  INVALID_LOCATION = 4,
}

export type BookingFormProps = {
  setFormActive: React.Dispatch<React.SetStateAction<BOOKING_FORM>>;
};

export type IServiceVariant = {
  id?: number;
  name?: string;
  service_name?: string;
  price: number;
  service_id: number;
  duration: number;
};

export type IMerchantEmployee = {
  id: number;
  full_name: string;
  title_name?: string;
  is_receive_book?: number | boolean;
  merchant_location_id: number;
};

export type IService = {
  category: {
    id: number;
    name: string;
    color: string;
    total_sale: number;
    total_services?: number;
  };
  enable_discount: number;
  enable_voucher_sale: number;
  id: number;
  index?: number;
  shift_one_time?: number;
  service_variant_id?: number;
  merchant_employees: IMerchantEmployee[];
  regular_price: number;
  sale_price: number;
  service_variants: IServiceVariant[];
  name?: string;
  service_name?: string;
  service_price?: number;
  duration_time?: number;
  is_favourite?: boolean;
  description?: string;
  assigned_employee: {
    employee: {
      id: number;
      full_name: string;
      email: string;
      phone: string;
      merchant_location_id: number;
    };
    time_start: string;
    duration: string;
    time_end: string;
    status: string;
  };

  checked?: boolean;
  time_start?: string;
  employee_id?: number | string;
  quantity?: number;
  serviceVariantName?: string;
  serviceVariantPrice?: number;
  service_id?: number;
};

export type IBookAssignmentsResponse = {
  code: number;
  data: {
    book_assignment_code: string;
    categories: any;
    customers: any;
    services: IService[];
  };
};
