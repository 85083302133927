import { Avatar } from 'antd';
import { CalendarViewType } from 'constants/index';
import SYSTEM_VARIABLES from 'constants/variables';
import { ICalendarBookingHeader } from 'features/bookings/components/BookingCalendar/Table';
import { SchemaCalendar } from 'features/bookings/components/BookingCalendar/Table/types';
import bookingSelectors from 'features/bookings/services/selectors';
import { IApiBookingParams, IBookingItemResData, IEmployeeItemResData } from 'features/bookings/services/types/booking';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { IDaysOfWeekItem } from 'utils/moment/getDatesOfWeek';
export type keyFilter = 'LOCATIONS' | 'SEARCH' | 'TIME_RANGE' | 'DATE' | 'TYPE_VIEW' | 'ZOOM_TIME';

export type IOnChangeFilter = (key: keyFilter) => (value: unknown) => void;

const useCalendarFilter = (): SchemaCalendar => {
  const data = bookingSelectors.getBookings();
  const teamMembersStore = bookingSelectors.getActiveTeamMembers();
  const daysOfWeek = bookingSelectors.getDaysOfWeek();
  const zoomCalendar = bookingSelectors.getZoomView();
  const typeView = bookingSelectors.getCalendarViewType();
  const employee_id = bookingSelectors.getCalendarParamValue('employee_id') as IApiBookingParams['employee_id'];
  const merchant_location_id = bookingSelectors.getCalendarParamValue('merchant_location_id') as IApiBookingParams['merchant_location_id'];
  const teamMembers = useMemo(() => {
    const haveUnassigned = data.find(o => o.book_assignment_services.length === 0 || o.book_assignment_services.find(i => !i.assigned_employee?.employee));
    const _teamMembersStore: IEmployeeItemResData[] = haveUnassigned ? [
      {
        email: '',
        full_name: 'Unassigned',
        id: SYSTEM_VARIABLES.UNASSIGNED,
        phone: '',
      }
    ] : [];

    teamMembersStore
      .filter(o => o.merchant_location_id?.toString() === merchant_location_id?.toString())
      .forEach(o => {
        _teamMembersStore.push(o);
      });

    return _teamMembersStore.filter(item => {
      if (item.id === SYSTEM_VARIABLES.UNASSIGNED) return true;

      if (employee_id !== undefined) {
        return employee_id.includes(item.id);
      }

      return true;
    });
  }, [teamMembersStore, employee_id]);


  const isSingleCol = () => {
    return teamMembers.length === 1 && typeView === CalendarViewType.MemberView;
  };

  const isToday = (date: any) => {
    if (!moment.isMoment(date) && !(date instanceof Date)) return false;
    
    const today = moment();
    const targetDate = moment(date);
    
    return targetDate.isSame(today, 'day');
  };

  const renderHeader = (o: ICalendarBookingHeader<any>) => {
    if (typeView === CalendarViewType.DaysView)
      return (
        <>
          <b style={isToday(o?.value) ? { color: '#419d77' } : {}}>{o?.value?.format('DD.MM')}</b>
          <p style={isToday(o?.value) ? { color: '#419d77' } : {}} className='subtext'>{o?.value?.format('ddd')}</p>
        </>
      );

    return (
      <>
        {o.id !== SYSTEM_VARIABLES.UNASSIGNED && <Avatar size={'small'}>{!!o?.label && o.label[0]}</Avatar>}
        <b style={{ marginLeft: '4px' }}>{o.label}</b>
      </>

    );
  };

  const getHeaders = () => {
    if (typeView === CalendarViewType.DaysView) {
      return daysOfWeek.map((item) => {
        const _date = moment(item.value);
        return ({
          id: item.id,
          value: _date,
          label: _date.format('DD.MM'),
        });
      });
    }

    if (typeView === CalendarViewType.MemberView) {
      return teamMembers.map((item) => ({
        id: item.id?.toString(),
        value: item.id?.toString(),
        label: item.full_name,
      }));
    }
    return [];
  };

  const filterBooking = (order: IBookingItemResData, _col: ICalendarBookingHeader<any>) => {
    if (typeView === CalendarViewType.DaysView) {
      const col = _col as ICalendarBookingHeader<IDaysOfWeekItem['value']>;
      return moment(order.time_start).isSame(col?.value, 'day');
    }

    if (typeView === CalendarViewType.MemberView) {
      const col = _col as ICalendarBookingHeader<any>;
      if (col.id === SYSTEM_VARIABLES.UNASSIGNED) {
        if (order.book_assignment_services.length === 0) return true;
        else return !!order.book_assignment_services.find(service => !service?.assigned_employee || isEmpty(service?.assigned_employee) || !service?.assigned_employee?.employee);
      }
      return !!order.book_assignment_services.find(service => {
        const orderTeamMemberId = service?.assigned_employee?.employee?.id?.toString();
        return col.id?.toString() === orderTeamMemberId;
      });
    }
    return false;
  };

  const getSchema = (): SchemaCalendar => ({
    distanceMinute: zoomCalendar,
    headers: getHeaders(),
    renderHeader,
    filterBooking,
    timeHourEnd: 23,
    timeHourStart: 0,
    timeMinuteEnd: 59,
    timeMinuteStart: 0,
    isSingleCol: isSingleCol(),
  });

  return getSchema();
};

export default useCalendarFilter;
