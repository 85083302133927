import { IResInvoiceItem } from 'features/invoices/services/types/invoice.js';
import InvoiceInfoItem from './InvoiceInfoItem.tsx';
import { getFormatSingeDate } from 'utils/moment/getSingleDateShortFormat';
import InvoiceInfoPaymentItem from './InvoiceInfoPaymentItem.tsx';

const InfoIssueDate = ({data} : {data?: IResInvoiceItem | null}) => {
  
  return (
    <table className='invoice-info'>
        <tr>
            <th className='invoice-info-title'>ISSUED DATE</th>
            <td className='invoice-info-title'>PAYMENT METHOD</td>
        </tr>
        <tr>
            <InvoiceInfoItem content={getFormatSingeDate(data?.date_time_book ?? '') ?? ''}/>
            <InvoiceInfoPaymentItem content={data?.payment_methods === 'mixin' ? 'Mixed' : data?.payment_methods ?? ''}/>
        </tr>
    </table>
  );
};

export default InfoIssueDate;