const IconVouchers = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.7337 10.6654L21.3337 12.2654L12.267 21.332L10.667 19.732L19.7337 10.6654ZM5.33366 5.33203H26.667C28.147 5.33203 29.3337 6.5187 29.3337 7.9987V13.332C28.6264 13.332 27.9481 13.613 27.448 14.1131C26.9479 14.6132 26.667 15.2915 26.667 15.9987C26.667 16.7059 26.9479 17.3842 27.448 17.8843C27.9481 18.3844 28.6264 18.6654 29.3337 18.6654V23.9987C29.3337 25.4787 28.147 26.6654 26.667 26.6654H5.33366C4.62641 26.6654 3.94814 26.3844 3.44804 25.8843C2.94794 25.3842 2.66699 24.7059 2.66699 23.9987V18.6654C4.14699 18.6654 5.33366 17.4787 5.33366 15.9987C5.33366 15.2915 5.05271 14.6132 4.55261 14.1131C4.05251 13.613 3.37424 13.332 2.66699 13.332V7.9987C2.66699 7.29145 2.94794 6.61318 3.44804 6.11308C3.94814 5.61298 4.62641 5.33203 5.33366 5.33203ZM5.33366 7.9987V11.3854C6.1439 11.8526 6.8168 12.5249 7.28467 13.3348C7.75253 14.1446 7.99885 15.0634 7.99885 15.9987C7.99885 16.934 7.75253 17.8528 7.28467 18.6626C6.8168 19.4725 6.1439 20.1448 5.33366 20.612V23.9987H26.667V20.612C25.8568 20.1448 25.1838 19.4725 24.716 18.6626C24.2481 17.8528 24.0018 16.934 24.0018 15.9987C24.0018 15.0634 24.2481 14.1446 24.716 13.3348C25.1838 12.5249 25.8568 11.8526 26.667 11.3854V7.9987H5.33366ZM12.667 10.6654C13.7737 10.6654 14.667 11.5587 14.667 12.6654C14.667 13.772 13.7737 14.6654 12.667 14.6654C11.5603 14.6654 10.667 13.772 10.667 12.6654C10.667 11.5587 11.5603 10.6654 12.667 10.6654ZM19.3337 17.332C20.4403 17.332 21.3337 18.2254 21.3337 19.332C21.3337 20.4387 20.4403 21.332 19.3337 21.332C18.227 21.332 17.3337 20.4387 17.3337 19.332C17.3337 18.2254 18.227 17.332 19.3337 17.332Z'
        fill='#363565'
      />
    </svg>
  );
};

export default IconVouchers;
