import { createSlice } from '@reduxjs/toolkit';
import { NAME_REDUCER } from './constants';
import { IState, ITeamMemberItemResData } from '../types/reducer';
import authActions from 'features/auth/services/actions';
import actions from './actions';
import { set } from 'lodash';
import { IResTimeScheduleList } from '../types/timeSchedule';
import moment from 'moment';

const initialState: IState = {
  params: {
    merchant_location_id: 0,
    merchant_employee_id: [],
    start_date: moment().startOf('week').add(1, 'd').format('YYYY-MM-DD'),
    end_date: moment().endOf('week').add(1, 'd').format('YYYY-MM-DD'),
  },
  pagination: null,
  timeSchedules: [],
  teamMembers: [],
  merchantLocations: [],
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(authActions.logout.success, (state) => {
        state.params = {};
        state.pagination = null;
        state.timeSchedules = [];
        state.teamMembers = [];
        state.merchantLocations = [];
      })
      .addCase(actions.setListTimeScheduleParams, (state, { payload }) => {
        const oldParams = state.params;
        const _params = { ...oldParams, ...(payload ?? {}) };

        if (!_params?.keyword) {
          delete _params?.keyword;
        }

        if (!_params?.merchant_employee_id) {
          if (_params.merchant_location_id){
            const employeeList = [...state.teamMembers]?.filter(
              (item: ITeamMemberItemResData) => {
                return item?.location_id === _params.merchant_location_id;
              }
            );
            const employeeId = employeeList?.map((it) => it?.id);        
            _params.merchant_employee_id = [...employeeId];
          }else {
            _params.merchant_employee_id = [];
          }
          
        }

        if (_params.per_page === undefined || _params.per_page === null) {
          set(_params, 'per_page', 20);
        }
        state.params = _params;
      })
      .addCase(actions.getListTimeSchedules.success, (state, { payload }) => {
        const res = payload as IResTimeScheduleList;
        state.timeSchedules = res?.data;
        state.pagination = res?.pagination;
      })
      .addCase(actions.setTimeScheduleFilterOpts, (state, { payload }) => {
        const { location, teamMember }: any = payload;

        state.merchantLocations = location ?? [];
        state.teamMembers = teamMember ?? [];

        if (location?.length) {
          state.params.merchant_location_id = location?.[0].id;

          const memberWithLocation = teamMember?.filter(
            (item: ITeamMemberItemResData) => {
              return item?.location_id === location?.[0].id;
            }
          );
          if (memberWithLocation) {
            state.params.merchant_employee_id = memberWithLocation?.map(
              (item: ITeamMemberItemResData) => item?.id
            );
          }
        }
      });
  },
});
const timeScheduleServiceReducer = Slice.reducer;
export default timeScheduleServiceReducer;
