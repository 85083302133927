import React from 'react';
import styled from 'styled-components';
import salesSelectors from 'features/sales/services/selectors';
import RewardResultRow from '../RewardList/ResultRow';
import PromotionRow from './Promotion';
import SubTotalAmount from './SubTotalAmount';
import DiscountFee from '../DiscountFee';
import ExtraFee from '../ExtraFee';
import CashResultRow from './CashPayment';
import VoucherResultRow from './VoucherPayment';
import CardResultRow from './CardPayment';
import HolidaySurchargeResultRow from './HolidaySurchageResultRow';

const DetailAmountRow = ({ step }: { step: number }) => {
  const extraFeeValue = salesSelectors.getExtraFeeValue();
  const discountFeeValue = salesSelectors.getDiscountFeeValue();
  const selectedService = salesSelectors.getSelectedServices();  


  

  // const AmountWithPaymentRow = () => {
    
  //   switch (method) {
  //     case EPaymentMethod.VOUCHER: return <VoucherResultRow />;
  //     case EPaymentMethod.CASH: return <CashResultRow />;
  //     case EPaymentMethod.MIX: return <MixPaymentResultRow />;
  //     case EPaymentMethod.CARD:
  //     default: return null;
  //   }
  // };

  const isHiddenFee = (value: number) => {
    return selectedService.length === 0 || (step === 3 && value === 0);
  };

  return (
    <DetailAmountRowStyled>
      <SubTotalAmount />
      {isHiddenFee(extraFeeValue) ? <></> :  <ExtraFee viewOnly={step === 3} />}
      {isHiddenFee(discountFeeValue) ? <></> : <DiscountFee viewOnly={step === 3} />}
      <RewardResultRow />
      <PromotionRow /> 
      <HolidaySurchargeResultRow />
      <VoucherResultRow viewOnly={step === 3} />
      <CashResultRow viewOnly={step === 3} />
      <CardResultRow viewOnly={step === 3} />
      {/* <MixPaymentResultRow /> */}
    </DetailAmountRowStyled>
  );
};

export default DetailAmountRow;

const DetailAmountRowStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .label {
    display: flex;
    align-items: center;
    gap: 2px;
  }
`;

