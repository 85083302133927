import { Spin } from 'antd';
import { AxiosResponse } from 'axios';
import settingActions from 'features/settings/services/actions';
import settingApis from 'features/settings/services/apis';
import settingSelectors from 'features/settings/services/selectors';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from '../../pages/index.module.scss';
import FormModal from './FormModal';
import message from 'components/common/Message';
import CancellationReasonItem from './CancellationReasonItem';

export interface ICancellationReasonItem {
    id: number;
    name: string;
}

export interface ICancellationReasonRes {
    id: number;
    name: string;
}

interface ICancellationReasonForm {
    name: string;
}

const CancellationsReasonsTab = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [idField, setIdField] = useState<number>(0);
    const [isDisableSubmit, setIsDisableSubmit] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>();
    const dispatch = useDispatch();
  
  
    useEffect(() => {
        dispatch(settingActions.getListCancellationReason.fetch());
    }, []); 


    const cancellationReasons = settingSelectors.getListCancellationReason();  
    const loadingCancellationReason = settingSelectors.loadListCancellationReason();

    const showModal = () => {
      setIsEdit(false);
      setIsModalOpen(true);
      setIsDisableSubmit(false);
    //   setFormData(undefined);
    };
  
  
  
    const handleCancel = () => {
      setIsModalOpen(false);
      setFormData(undefined);
    };
  
  
    const getPayload = (values: any) => {
      const name = values?.name;
      return {
        name
      };
    };
  
    const handleSubmitEdit = async (values: ICancellationReasonForm) => {
      setIsDisableSubmit(true);
      const payload = getPayload(values);
  
      try {
        const rest: AxiosResponse<{ message: string }> = await settingApis.updateCancellationReason(idField, payload);
  
        const msg = get(rest, 'data.message', '');
        if (msg) {
          message.success(msg);
          dispatch(settingActions.getListCancellationReason.fetch());
          handleCancel();
          return true;
        }
        return false;
      } catch (error: any) {
        const response = get<Record<string, string[]>>(
          error,
          'response.data.errors',
          {}
        );
  
        const errors = Object.values(response);
        errors.map((value) => {
          message.error(get(value, [0], ''));
        });
  
        return false;
      }
    };
  
    const handleSubmit = async (values: ICancellationReasonForm) => {
      setIsDisableSubmit(true);
      const payload = getPayload(values);

      try {
        const rest: AxiosResponse<{ message: string }> = await settingApis.createCancellationReason(payload);
  
        const msg = get(rest, 'data.message', '');
        if (msg) {
          message.success(msg);
          dispatch(settingActions.getListCancellationReason.fetch());
          handleCancel();
        setIsDisableSubmit(false);

          return true;

        }
        setIsDisableSubmit(false);
        return false;
      } catch (error: any) {
        const response = get<Record<string, string[]>>(
          error,
          'response.data.errors',
          {}
        );
  
        const errors = Object.values(response);
        errors.map((value) => {
          message.error(get(value, [0], ''));
        });
        setIsDisableSubmit(false);
        return false;
      }
    };
  
    const handleEdit = (item: ICancellationReasonItem) => {
      showModal();
      setIsEdit(true);
      setIdField(item.id);
      setFormData({
        name: item.name,
      });
    };

    return (
        <>
        <Spin spinning={loadingCancellationReason}>
        <div className='cards-wrapper'>
          <div className='cards-col'>
            <div className='common-card'>
              <div className='card-title'>
                <div className='d-flex'>
                  <div>
                    <div className='d-flex' style={{ gap: '10px' }}>
                      <p className='form-label is-biggest'>Cancellation Reasons </p>
                    </div>
                    <p className='form-note'>
                      Set the cancellation reasons.
                    </p>
                  </div>
                  <button
                    className='common-btn'
                    style={{ marginLeft: 'auto' }}
                    onClick={showModal}
                  >
                    Add New
                  </button>
                </div>
              </div>
              <div className='card-body'>
                <ul className={styles.periods_list}>
                  {cancellationReasons.map((item: any, key: number) => (
                    <li key={key}>
                      <CancellationReasonItem {...item}
                        handleEdit={() => handleEdit(item)} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && <FormModal
          isDisableSubmit={isDisableSubmit}
          formData={formData}
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          handleSubmit={isEdit ? handleSubmitEdit : handleSubmit}
        />}
      </Spin>
        </>
    );
};

export default CancellationsReasonsTab;