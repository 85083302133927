import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import BusinessDetails from '../components/Forms/BusinessDetails';
import CustomMessage from '../components/Forms/CustomMessage';
import ServiceDetails from '../components/Forms/ServiceDetails';
import invoiceActions from '../services/actions';
import styles from './index.module.scss';
import ModalRefund from 'components/common/Modal/ModalRefund';
import invoiceSelectors from '../services/selectors';
import { Spin } from 'antd';
import ModalSendInvoiceEmail, { ICustomerSendEmail } from 'components/common/Modal/ModalSendInvoiceEmail';

const InvoicesDetailPage = () => {
  const { invoice_no = '' } = useParams();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenRefund, setIsOpenRefund] = useState<boolean>(false);

  useEffect(() => {
    dispatch(invoiceActions.setInvoiceDetailId(invoice_no));
  }, []);

  const data = invoiceSelectors.detailInvoice.getData();
  const loading = invoiceSelectors.detailInvoice.getLoading();
  

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Spin spinning={loading}>
      <div className={styles.customers_detail_page}>
        <div className="cards-wrapper">
          <div className="cards-col">
            <BusinessDetails />
            <ServiceDetails />
            <CustomMessage />
          </div>
        </div>

        {data?.customer.name !== 'Walk-in customer' ? <div className={styles.general_footer}>
          { data?.refunds?.length === 0 && data?.total_price === 0 ? null : <div className="common-btn is-outlined" onClick={() => setIsOpenRefund(true)}>Refund</div>}
          <div onClick={() => setIsOpen(true)} className="common-btn">Send Invoice</div>
        </div> : <></>}

        {/* <ModalConfirmEmail handleCancel={handleCancel} isOpen={isOpen}/> */}
        {isOpen && <ModalSendInvoiceEmail
          type='invoice_detail'
          handleCancel={handleCancel}
          isOpen={isOpen}
          customer={data?.customer as ICustomerSendEmail}
          invoice_id={data?.invoice_no ?? ''} />}

        <ModalRefund total={0} open={isOpenRefund} onClose={() => setIsOpenRefund(false)} />
      </div>
    </Spin>
  );
};

export default InvoicesDetailPage;
