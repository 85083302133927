import styled from 'styled-components';

interface TopUpKindProps {
  text: string;
  icon: string;
  description: string;
  isActive: boolean;
  handleClick?: () => void;
}

const TopUpKind = ({
  text,
  icon,
  description,
  isActive,
  handleClick,
}: TopUpKindProps) => {
  return (
    <TopUpTypeStyled
      onClick={() => handleClick?.()}
      className='topup-kind-wrap'
    >
      <div className='cards-wrapper'>
        <div className='cards-col'>
          <div className='common-card'>
            <div
              className='d-flex no-wrap type-wrap'
              style={{ background: isActive ? '#F8F8FF' : '' }}
            >
              <div>
                <p className='type-title'>{text}</p>
                <p className='type-desc'>{description}</p>
              </div>
              <div className='img-wrap'>
                <img src={icon} alt='top-up-type-icon' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </TopUpTypeStyled>
  );
};

const TopUpTypeStyled = styled.div`
  margin-top: 24px;
  cursor: pointer;

  @media (max-width: 1180px) {
    display: flex;
    justify-content: center;
    width: 100%;

    .cards-wrapper {
      max-width: 648px;
      width: 100%;
    }
  }

  .cards-col {
    max-width: none;
  }
  .common-card {
    padding: 0;
  }

  .type-wrap {
    border-radius: 10px;
    border: 1px solid var(--grayish-navy-30, #C3C2E0);
    width: 100%;
    padding: 16px;
    justify-content: space-between;
    align-items: center;

    .type-title {
      color: var(--grayish-navy-main, #363565);
      font-size: 24px;
      font-weight: 600;
      line-height: 140%;
      text-align: left;
    }

    .type-desc {
      color: var(--grayish-navy-60, #7980BC);
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
    }

    .img-wrap {
      height: fit-content;
    }
  }
`;

export default TopUpKind;
