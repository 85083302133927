import { createAction, createAsyncAction } from 'services/actionConfigs';
import { EPaymentMethod, PREFIX_ACTIONS } from './constants';
import { IServiceSelectedItem } from './types/service';
import { IVoucherDataItem, IVoucherForSalesSelectedItem } from './types/voucher';
import { ICustomerItemResData } from 'features/customers/services/types/cutomer';
import { IInvoiceResult, IMixPaymentItem } from './types/checkout';
import { ILocationDataConfig } from 'features/bookings/services/types/quickBooking';
import { IRewardItemResData } from 'features/loyaltyProgram/services/types/reward';
import { IApiUpdateCusInfoBody } from 'features/checkout/services/types/customer';
import { IPromotionDetailInput } from 'features/promotions/services/types/promotion';
import { IPromotionErrorBoundary } from 'features/checkout/services/types/promotion';
import { IModalFeeFormPayload } from 'features/checkout/services/types/fee';

const fetchConfigsData = createAction(PREFIX_ACTIONS + 'fetchConfigsData');
const getConfigData = createAsyncAction(PREFIX_ACTIONS + 'getConfigData');
const getVouchersList = createAsyncAction(PREFIX_ACTIONS + 'getVouchersList');
const getCheckoutList = createAsyncAction(PREFIX_ACTIONS + 'getCheckoutList');

const setServiceSelected = createAction<IServiceSelectedItem[]>(PREFIX_ACTIONS + 'setServiceSelected');
const setQuantitySelectedService = createAction<{ id: IServiceSelectedItem['id'], service_variant_id: IServiceSelectedItem['service_variant_id'], quantity: IServiceSelectedItem['quantity'] }>(PREFIX_ACTIONS + 'setQuantitySelectedService');
const removeSelectedService = createAction<{ id: IServiceSelectedItem['id'], service_variant_id: IServiceSelectedItem['service_variant_id'] }>(PREFIX_ACTIONS + 'removeSelectedService');

const setSelectedVouchers = createAction<IVoucherForSalesSelectedItem[]>(PREFIX_ACTIONS + 'setSelectedVouchers');

const setCustomer = createAction<ICustomerItemResData | null>(PREFIX_ACTIONS + 'setCustomer');

const getVoucherOfCustomer = createAsyncAction<{ id: string }>(PREFIX_ACTIONS + 'getVoucherOfCustomer');

const setPaymentMethod = createAction<EPaymentMethod | null>(PREFIX_ACTIONS + 'setPaymentMethod');

const setPurchaseMethodErrors = createAction<{ method: EPaymentMethod, errors: Record<string, string> }>(PREFIX_ACTIONS + 'setPurchaseMethodErrors');

const setPurchaseCash = createAction<number | null>(PREFIX_ACTIONS + 'setPurchaseCash');

const setPurchaseCard = createAction<number | null>(PREFIX_ACTIONS + 'setPurchaseCard');

const setSelectedVoucherUsed = createAction<IVoucherDataItem | null>(PREFIX_ACTIONS + 'setSelectedVoucherUsed');

const setMixPayments = createAction<IMixPaymentItem[]>(PREFIX_ACTIONS + 'setMixPayments');

const setMerchantLocationId = createAction<ILocationDataConfig>(PREFIX_ACTIONS + 'setMerchantLocationId');

const setBookingDate = createAction<string>(PREFIX_ACTIONS + 'setBookingDate');

const editSelectedService = createAction<IServiceSelectedItem>(PREFIX_ACTIONS + 'editSelectedService');

const resetFormPayment = createAction(PREFIX_ACTIONS + 'resetFormPayment');

const reviewPointCustomerWillReceive = createAsyncAction<number>(PREFIX_ACTIONS + 'reviewPointCustomerWillReceive');

const getRewardOfCustomer = createAsyncAction<any>(PREFIX_ACTIONS + 'GET_REWARD_OF_CUSTOMER');

const setSelectedReward = createAction<IRewardItemResData | null>(PREFIX_ACTIONS + 'SET_SELECT_REWARD');

const updateCusInfo = createAsyncAction<{ id: string, data: IApiUpdateCusInfoBody }>(PREFIX_ACTIONS + 'UPDATE_CUSTOMER_INFO');

const setPromotionInfo = createAction<IPromotionDetailInput | null>(PREFIX_ACTIONS + 'setPromotionInfo');

const setPromotionErrorBoundary = createAction<IPromotionErrorBoundary | null>(PREFIX_ACTIONS + 'setPromotionErrorBoundary');

const setSoftPromotionCode = createAction<string | null>(PREFIX_ACTIONS + 'setSoftPromotionCode');

const setInvoiceResult = createAction<IInvoiceResult | null>(PREFIX_ACTIONS + 'setInvoiceResult');

const getTotalPointCustomer = createAsyncAction<{ is_walkin_in: boolean, customer_code: string }>(PREFIX_ACTIONS + 'getTotalPointCustomer');

const setExtraFee = createAction<IModalFeeFormPayload | null>(PREFIX_ACTIONS + 'setExtraFee');

const setDiscountFee = createAction<IModalFeeFormPayload | null>(PREFIX_ACTIONS + 'setDiscountFee');

const salesActions = {
  getConfigData,
  getVouchersList,
  fetchConfigsData,
  setServiceSelected,
  setSelectedVouchers,
  setSelectedReward,
  setCustomer,
  setQuantitySelectedService,
  removeSelectedService,
  getCheckoutList,
  getVoucherOfCustomer,
  setPaymentMethod,
  setPurchaseMethodErrors,
  setPurchaseCash,
  setSelectedVoucherUsed,
  setMixPayments,
  setMerchantLocationId,
  setBookingDate,
  editSelectedService,
  resetFormPayment,
  reviewPointCustomerWillReceive,
  getRewardOfCustomer,
  updateCusInfo,
  setPromotionInfo,
  setPromotionErrorBoundary,
  setSoftPromotionCode,
  setInvoiceResult,
  getTotalPointCustomer,
  setExtraFee,
  setDiscountFee,
  setPurchaseCard,
};

export default salesActions;
