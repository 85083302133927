import message from 'components/common/Message';
import loyalActions from 'features/loyaltyProgram/services/actions';
import apisLoyal from 'features/loyaltyProgram/services/apis';
import { get } from 'lodash';
import { useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import { RewardFormProps } from './Form';
import RewardModal from './Modal';

const RewardAdd = () => {
  const [isOpen, setIsOpen] = useState(false);
  const showModal = () => setIsOpen(true);
  const hideModal = () => setIsOpen(false);
  const setLoadingPage = useSetLoadingPage();
  const dispatch = useAppDispatch();

  const handleSubmit: RewardFormProps['onSubmit'] = async (payload) => {
    setLoadingPage(true);
    try {
      const rest = await apisLoyal.createReward(payload);
      const msg = get(rest, 'data.message', '');
      if (msg) {
        hideModal();
        dispatch(loyalActions.getListLoyalReward.fetch({}));
        message.success(msg);
      } else {
        const msgError = get(rest, 'data.error.message', '');
        if (msgError) message.error(msgError);
        else throw 'fail';
      }
    } catch (error) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setLoadingPage(false);
    }
  };


  return (
    <>
      <button onClick={showModal} className={'common-btn'}>
        Add New
      </button>
      <RewardModal visible={isOpen} onCancel={hideModal} onSubmit={handleSubmit} />
    </>
  );
};

export default RewardAdd;
