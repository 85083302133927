import { Form } from 'antd';
import StaffFormContent, { IStaffFormInput } from 'features/users/components/StaffFormContent';
import userApis from 'features/users/services/apis';
import userSelectors from 'features/users/services/selectors';
import { get, set } from 'lodash';
import React, { useMemo } from 'react';
import StringValidations from 'utils/validations/string';
import { IFormAddStaffData } from '.';
import { useAppDispatch } from 'store/hooks';
import userActions from 'features/users/services/actions';

type Props = {
  errors: any;
  onCancel: () => void;
  onNextStep: () => void;
  isEdit?: boolean;
  defaultData?: IFormAddStaffData;
}
const UserFormFirstStep = ({
  errors = {},
  onCancel = () => undefined,
  onNextStep = () => undefined,
  isEdit,
  defaultData
}: Props) => {

  const getShowCreateStaffs = userSelectors.getShowCreateStaffs();
  const locationOptions = getShowCreateStaffs?.merchant_locations;
  const form = Form.useFormInstance();
  const accessLevelOptions = getShowCreateStaffs?.access_levels;
  const dispatch = useAppDispatch();

  const checkValid = async () => {
    try {
      await form.validateFields([
        'name',
        'accessLevel',
        'phone',
        'pinNumber',
        'location'
      ]);
      if (isEdit) {
        if (defaultData?.pinNumber !== form.getFieldValue('pinNumber'))
          await userApis.checkPinNumberExist({
            pin_number: form.getFieldValue('pinNumber')
          });
      } else {
        await userApis.checkPinNumberExist({
          pin_number: form.getFieldValue('pinNumber')
        });
      }

      return true;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );
      const errors = Object.entries(response);
      const errorsObj = {};
      errors.forEach(([key, value]) => {
        set(errorsObj, [key], value?.[0]);
      });

      errors.map(([, value]) => {
        form.setFields([
          {
            name: 'pinNumber',
            errors: [get(value, [0], '')]
          }
        ]);

      });
      return false;
    }
  };



  const handleNextStep: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    const isFormValid = await checkValid();
    if (!isFormValid) return;
    onNextStep();
  };


  const getFeedback = (text: string) => {
    if (!text) return {};
    return ({
      validateStatus: text ? 'error' : undefined,
      help: text ? text : undefined,
    });
  };

  // @ts-ignore
  const inputs: IStaffFormInput[] = useMemo(() => [
    {
      label: 'Name',
      name: 'name',
      placeholder: 'Enter user name',
      type: 'text',
      rules: [{ required: true }, StringValidations,],
      ...getFeedback(get(errors, ['full_name'])),
    },
    {
      label: 'Phone Number',
      name: 'phone',
      type: 'phone'
    },
    // {
    //   label: 'Email',
    //   name: 'email',
    //   placeholder: 'Enter user\'s email',
    //   rules: [
    //     { required: true },
    //   ]
    // },
    {
      label: 'Pin number',
      name: 'pinNumber',
      placeholder: 'Enter pin number',
      type: 'password',
      rules: [{
        validator(_, value,) {
          if (!value) return Promise.reject(new Error('Pin number is required!'));
          if (value.length < 4) return Promise.reject(new Error('The pin number must be 4 digits'));
          return Promise.resolve();
        },
      }],
      ...getFeedback(get(errors, ['pin_number'])),
    },
    {
      label: 'Access Level',
      name: 'accessLevel',
      placeholder: 'Select access level',
      options: accessLevelOptions,
      type: 'select',
      ...getFeedback(get(errors, ['access_level'])),
    },
    {
      label: 'Locations',
      name: 'location',
      placeholder: 'Select location',
      options: locationOptions,
      type: 'select',
      onChange: (value: any) => {
        dispatch(userActions.setLocationSelectedId(value));
      },
      rules: [{ required: true }],
    },
    {
      label: 'Booking',
      title: 'Allow booking calendar',
      name: 'isReceiveBook',
      subTitle: 'Allow this team member to receive booking notification.',
      type: 'switch'
    }
  ], [accessLevelOptions, errors]);

  return (
    <>
      <StaffFormContent inputs={inputs} />

      <div className='form-submit-wrapper'>
        <button
          className='common-btn is-white'
          onClick={onCancel}
          type='button'>
          {'Cancel'}
        </button>
        <button
          className='common-btn'
          onClick={handleNextStep}
        >
          {'Continue'}
        </button>
      </div>
    </>
  );
};

export default UserFormFirstStep;
