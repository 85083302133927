import InputSearchText from 'components/common/Input/InputSearchDebounce';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import userActions from '../services/actions';
import userSelectors from '../services/selectors';
import AddNewUser from '../widgets/AddNewUser';

function StaffFilter() {
  const dispatch = useAppDispatch();
  const params = userSelectors.getParams();
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    // @ts-ignore
    setSearchParams(params);
  }, [params]);

  const onChangeSearch = (val: string) => {
    dispatch(userActions.setParams({ keyword: val || undefined }));
  };
  return (
    <div className={'private-filter'}>
      <InputSearchText onSearchText={onChangeSearch} />
      {/* <button className={'export-btn'}></button> */}
      <AddNewUser />
    </div>
  );
}

export default StaffFilter;