import { Form, Modal } from 'antd';
import styled from 'styled-components';
import TimeOffForm from '../components/TimeOffForm';
import {
  ITimeOffFormValue,
  ITimeTimeOffFormValue,
  TimeOffModalType,
} from '../types/timeSchedule';
import { DateOffRepeatType, IApiAddDateOff } from '../types/api';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { TIME_OFF_REPEAT_OPTS } from '../services/constants';
interface TimeOffModalProps {
  isModalOpen?: boolean;
  handleClose?: () => void;
  handleSubmit?: (values: IApiAddDateOff) => Promise<boolean | void>;
  initData?: ITimeTimeOffFormValue;
  modalType?: TimeOffModalType;
}

const TimeOffModal = ({
  isModalOpen,
  handleClose,
  handleSubmit,
  initData,
  modalType,
}: TimeOffModalProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (modalType === TimeOffModalType.CREATE) {
      form.setFieldValue('dayOff', dayjs());
    }
    if (initData) {
      form.setFieldValue('dayOff', dayjs(initData?.dayOff));
      form.setFieldValue('teamMember', initData?.teamMember);
    }
  }, []);

  const checkValid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };

  const getPayload = (values: ITimeOffFormValue) => {
    const result: IApiAddDateOff = {
      merchant_employee_id: values?.teamMember,
      day_off: (values?.dayOff as any).format('YYYY-MM-DD'),
      one_day_leave: values.oneDayLeave,
    };

    if (values.startTime) {
      result.time_start = (values.startTime as any).format('HH:mm:ss');
    }

    if (values.endTime) {
      result.time_end = (values.endTime as any).format('HH:mm:ss');
    }

    if (values.repeatCheck) {
      result.repeat = values.timeOffRepeatType as DateOffRepeatType;
    }
    return result;
  };

  const handleForm = async () => {
    const valid = await checkValid();
    if (!valid) return;
    form?.submit();
  };

  const submitForm = async (value: ITimeOffFormValue) => {
    const payload = await getPayload(value);
    handleSubmit?.(payload);
  };

  return (
    <Modal
      maskClosable={false}
      open={isModalOpen}
      onCancel={handleClose}
      okButtonProps={{ style: { display: 'none' } }}
      footer={null}
      forceRender
      centered
    >
      <TimeOffModalStyled>
        <div className='form-content'>
          <h2 className='modal-title'>
            {modalType === TimeOffModalType.UPDATE
              ? 'Edit Day Off'
              : 'Add Day Off'}
          </h2>

          <Form
            form={form}
            layout='vertical'
            onFinish={submitForm}
            initialValues={{
              oneDayLeave: false,
              repeatType: false,
              timeOffRepeatType: TIME_OFF_REPEAT_OPTS[0].value,
            }}
          >
            <TimeOffForm modalType={modalType} />
          </Form>
        </div>

        <div className='form-submit-wrapper'>
          <div onClick={handleClose} className='common-btn is-white'>
            Cancel
          </div>
          <button type='submit' className='common-btn' onClick={handleForm}>
            Save
          </button>
        </div>
      </TimeOffModalStyled>
    </Modal>
  );
};

const TimeOffModalStyled = styled.div`
  .modal-title {
    border-bottom: none;
  }
`;

export default TimeOffModal;
