import { Dayjs } from 'dayjs';
import moment, { Moment } from 'moment';

export const getDisabledHours = (hourOpen: number, hourClose: number, acceptNowDate: boolean) => {
  const hours = [];

  for (let i = 0; i < hourOpen; i++) {
    hours.push(i);
  }

  for (let i = hourClose + 1; i < 24; i++) {
    hours.push(i);
  }

  if (acceptNowDate) {
    for (let i = hourOpen; i < moment().hour(); i++) {
      hours.push(i);
    }
  }
  return hours;
};

export const getDisabledMinutes = (selectedHour: number) => {
  const minutes = [];
  if (selectedHour === moment().hour()) {
    for (let i = 0; i < moment().minute(); i++) {
      minutes.push(i);
    }
  }
  return minutes;
};

/**
 * isDisableDateByClosedDate Check if that date is in closed period or not.
 * @param date: Moment
 * @returns 
 */

export const isDisableDateByClosedPeriod = (date: Moment | Dayjs, date_closed: {start: Moment | Dayjs, end: Moment | Dayjs}[]) => {
  const isDateClosed = !!date_closed?.some((o: any) => date.format('YY-MM-DD') == o.start.format('YY-MM-DD') || date.isBetween(o.start, o.end.add(1, 'day')));
  return isDateClosed;
};