import { createSlice } from '@reduxjs/toolkit';
import { ILocationItemResData } from 'features/loyaltyProgram/services/types/response';
import { ITeamMemberItemResData } from 'features/users/services/types/teamMember';
import actions from './actions';
import { NAME_REDUCER, TypeDetailView } from './constants';
import { IState } from './types/reducer';
import { IWorkingHourResData } from './types/workingHour';

const initialState: IState = {
  params: {},
  pagination: null,
  staffList: [],
  totalTopAnalytic: null,
  locations: [],
  lstTeamMembers: [],
  detail: {
    params: {},
    typeView: TypeDetailView.DailyView,
  }
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.detail.setTypeView, (state, { payload }) => {
        state.detail.typeView = payload;
      })
      // .addCase(actions.detail.getData.success, () => {
      //   // const data = payload as ITeamMemberItemResData[];
      //   // state.lstTeamMembers = data;
      // })
      .addCase(actions.detail.setParams, (state, { payload }) => {
        const _params = {
          ...state.detail.params ?? {},
          ...payload ?? {},
        };
        state.detail.params = _params;
      })
      .addCase(actions.getListTeamMembers.success, (state, { payload }) => {
        const data = payload as ITeamMemberItemResData[];
        state.lstTeamMembers = data;
      })
      .addCase(actions.getListLocations.success, (state, { payload }) => {
        const data = payload as ILocationItemResData[];
        state.locations = data.filter(o => !!o?.status);
      })
      .addCase(actions.getData.success, (state, { payload }) => {
        const data = (payload ?? {}) as IWorkingHourResData;        
        state.pagination = data?.list_staff_analytic?.pagination ?? null;
        state.staffList = data?.list_staff_analytic?.data ?? [];
        state.totalTopAnalytic = data.total_top_analytic ?? null;        
      })
      .addCase(actions.setParams, (state, { payload }) => {
        const _params = {
          ...state.params ?? {},
          ...payload ?? {},
        };
        state.params = _params;
      });
  },
});
const workingHourServiceReducer = Slice.reducer;
export default workingHourServiceReducer;
