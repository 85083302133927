import { DatePicker, Form, Input, InputNumber, Select, Spin } from 'antd';
import { TopUpType } from 'features/settings/services/types/topUp';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CardNumberIcon from 'assets/images/setting/card-number-icon.png';
import dayjs, { Dayjs } from 'dayjs';
import { disabledDateSameOrBefore } from 'utils/unit';
import { TOPUP_OPTION } from 'features/settings/services/constants';

interface TopUpFormProps {
  topUpType: TopUpType;
  loading?: boolean;
  merchantBalance?: number;
}

const TopUpForm = ({ topUpType, loading, merchantBalance }: TopUpFormProps) => {
  const { t: topUpLang } = useTranslation('top_up');
  const form = Form.useFormInstance();

  return (
    <Spin spinning={loading}>
      <TopUpFormStyled>
        <div className='box-wrap'>
          <p className='box-title'>
            {topUpLang(
              topUpType === TopUpType.AUTOMATIC
                ? 'automaticTopUpType.text'
                : 'manuallyChargeType.text'
            )}
          </p>
          <p className='box-desc'>{topUpLang('automaticTopUpType.formDesc')}</p>
          {topUpType === TopUpType.MANUALLY ? (
            <div className='current-balance'>
              <p className='current-balance-text'>Current balance</p>
              <p className='current-balance-amount'>{merchantBalance || 0}</p>
            </div>
          ) : null}
          <div>
            <Form.Item
              label={topUpLang('topUpForm.formLabel.topUp')}
              name='topUp'
              rules={[
                {
                  required: true,
                  message: 'Please select top up',
                },
              ]}
            >
              <Select options={TOPUP_OPTION} />
            </Form.Item>
            <div className='form-help-text'>
              {topUpLang('topUpForm.formHelp.topUp', { amount: 600 })}
            </div>
          </div>
          <div>
            {topUpType === TopUpType.AUTOMATIC ? (
              <div>
                <Form.Item
                  label={topUpLang('topUpForm.formLabel.autoChargeWhen')}
                  name='autoChargeWhen'
                  rules={[
                    {
                      required: true,
                    },
                    {
                      validator(_, value) {
                        const topupValue = form.getFieldValue('topUp');
                        if (value && !/^\d+$/.test(value)) {
                          return Promise.reject(
                            new Error('Wrong number format')
                          );
                        }

                        if (Number(value) > Number(topupValue)) {
                          return Promise.reject(
                            new Error(
                              'The number of the Auto charge when field cannot bigger Topup'
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  dependencies={['topUp']}
                >
                  <InputNumber
                    addonBefore='$'
                    inputMode='numeric'
                    placeholder={
                      topUpLang('topUpForm.formPlaceholder.autoChargeWhen') ||
                      ''
                    }
                  />
                </Form.Item>
                <div className='form-help-text'>
                  {topUpLang('topUpForm.formHelp.autoChargeWhen')}
                </div>
              </div>
            ) : (
              <div>
                <Form.Item
                  label={topUpLang('topUpForm.formLabel.notifyYourWhen')}
                  name='notifyYourWhen'
                  rules={[
                    {
                      required: true,
                    },
                    {
                      validator(_, value) {
                        const topupValue = form.getFieldValue('topUp');

                        if (value && !/^\d+$/.test(value)) {
                          return Promise.reject(
                            new Error('Wrong number format')
                          );
                        }

                        if (Number(value) > Number(topupValue)) {
                          return Promise.reject(
                            new Error(
                              'The number of the Notify your when field cannot bigger Topup'
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  dependencies={['topUp']}
                >
                  <InputNumber
                    addonBefore='$'
                    inputMode='numeric'
                    placeholder={
                      topUpLang('topUpForm.formPlaceholder.notifyYourWhen') ||
                      ''
                    }
                  />
                </Form.Item>
                <div className='form-help-text'>
                  {topUpLang('topUpForm.formHelp.notifyYourWhen')}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='box-wrap'>
          <p className='box-title'>
            {topUpLang('topUpForm.paymentMethod.text')}
          </p>
          <p className='box-desc'>
            {topUpLang('topUpForm.paymentMethod.desc')}
          </p>
          <div>
            <Form.Item
              label={topUpLang('topUpForm.formLabel.cardHolderFullName')}
              name='cardHolderFullName'
              rules={[{ required: true }]}
            >
              <Input
                placeholder={
                  topUpLang('topUpForm.formPlaceholder.cardHolderFullName') ||
                  ''
                }
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label={topUpLang('topUpForm.formLabel.cardNumber')}
              name='cardNumber'
              rules={[
                {
                  required: true,
                },
                {
                  validator(_, value) {
                    if (value && !/^\d+$/.test(value)) {
                      return Promise.reject(new Error('Wrong number format'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                suffix={<img src={CardNumberIcon} alt='cardnumber-icon' />}
                placeholder={
                  topUpLang('topUpForm.formPlaceholder.cardNumber') || ''
                }
                className='cardNumber'
              />
            </Form.Item>
          </div>
          <div className='form-row'>
            <Form.Item
              label={topUpLang('topUpForm.formLabel.expiryDate')}
              name='expiryDate'
              rules={[{ required: true, message: 'Please select expiry date' }]}
            >
              <DatePicker
                disabledDate={(current: Dayjs) =>
                  disabledDateSameOrBefore(current, dayjs())
                }
                format={'MM/YY'}
                picker='month'
                placeholder={
                  topUpLang('topUpForm.formPlaceholder.expiryDate') || ''
                }
                className='card-expired-picker'
              />
            </Form.Item>
            <Form.Item
              label={topUpLang('topUpForm.formLabel.cvv')}
              name='cvv'
              rules={[
                {
                  required: true,
                },
                {
                  validator(_, value) {
                    if (value && !/^\d+$/.test(value)) {
                      return Promise.reject(new Error('Wrong number format'));
                    }
                    if (value?.toString()?.length > 3) {
                      return Promise.reject(new Error('CVC limit 3 numbers'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                placeholder={topUpLang('topUpForm.formPlaceholder.cvv') || ''}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label={
                <p>
                  {topUpLang('topUpForm.formLabel.abnNumber')}
                  <span>(Optional)</span>
                </p>
              }
              name='abnNumber'
            >
              <InputNumber
                style={{ width: '100%' }}
                inputMode='numeric'
                placeholder={
                  topUpLang('topUpForm.formPlaceholder.abnNumber') || ''
                }
                className='abnNumber'
              />
            </Form.Item>
          </div>
        </div>
      </TopUpFormStyled>
    </Spin>
  );
};

const TopUpFormStyled = styled.div`
  .box-title {
    color: #363565;
    font-size: 24px;
    font-weight: 600;
  }

  .box-desc {
    color: #7980bc;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 24px;
  }

  .box-wrap {
    margin-top: 24px;
    border-radius: 10px;
    border: 1px solid #c3c2e0;
    padding: 16px;
  }

  .current-balance {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .current-balance-text,
    .current-balance-amount {
      color: #363565;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .abnNumber,
  .cardNumber {
    border: unset;
    box-shadow: none;

    input {
      border-radius: 6px !important;
    }
  }

  .form-help-text {
    color: #7980bc;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    margin-top: -20px;
    margin-bottom: 24px;
  }

  .card-expired-picker {
    background: #f0f2f6 !important;
  }

  @media (max-width: 1180px) {
    .box-wrap {
      max-width: 648px;
      width: 100%;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export default TopUpForm;
