import { RcFile } from 'antd/es/upload';
import { AxiosResponse } from 'axios';
import InputSearchText from 'components/common/Input/InputSearchDebounce';
import message from 'components/common/Message';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import downloadFile from 'utils/downloadFile';
import CustomersTable from '../components/CustomersTable';
import ImportModal, { ImportModalProps } from '../components/ImportModal';
import actions from '../services/actions';
import apisCustomer from '../services/apis';
import AddNewCustomer from '../widgets/AddNewCustomer';
import { useSetLoadingPage } from 'services/UI/LoadingPage';

const CustomersPage = () => {
  const dispatch = useAppDispatch();

  const [isOpenCustomerModal, setIsOpenCustomerModal] =
    useState<boolean>(false);
  const { t: commonLang } = useTranslation('common');

  const [searchParams, setSearchParams] = useSearchParams();
  const setLoadingPage = useSetLoadingPage();

  const dispatchApp = useAppDispatch();

  useEffect(() => {
    dispatchApp(
      actions.setCustomerListParams({
        page: Number(searchParams.get('page') ?? 1),
        per_page: Number(searchParams.get('per_page') ?? 10),
        keyword: searchParams.get('keyword') ?? '',
      })
    );

    dispatchApp(actions.getListCustomers.fetch());
  }, []);  

  const onSearch = (keyword: string) => {
    const params: URLSearchParamsInit = {
      keyword,
      page: '1',
      per_page: '10',
    };
    setSearchParams(params);
    dispatch(actions.getListCustomersPaginate.fetch(params));
  };

  const handleSubmitImport: ImportModalProps['onSubmit'] = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file as RcFile);

      const rest: AxiosResponse<{ message: string }> =
        await apisCustomer.importCustomerInfo(formData);

      const msg = `${(rest as any).data.data.customerSuccess} record inserted`;
      if (msg) {
        message.success(msg);
        setIsOpenCustomerModal(false);
        dispatchApp(
          actions.setCustomerListParams({ page: 1, per_page: 10 })
        );
        return true;
      } else {
        throw 'fail';
      }
    } catch (error: any) {
      const textError = get(error, 'response.data.error.message', '');
      if (textError) {
        message.error(textError);
      }

      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });

      return false;
    }
  };

  const onClickExport = async () => {
    setLoadingPage(true);
    try {
      const res = await apisCustomer.exportData();
      const file_export_url = get(res, ['data', 'data', 'file_export'], '');
      if (!file_export_url) return;
      downloadFile(file_export_url);
    } catch (error) {
      const errorText = get(error, 'response.data.error.message', '');
      if (errorText) {
        message.error(errorText);
        return;
      }
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setLoadingPage(false);
    }
  };

  return (
    <>
      <div className={'private-filter'}>
        <InputSearchText value={searchParams.get('keyword') ?? ''} onSearchText={onSearch} />
        <button
          className='common-btn-import'
          onClick={() => setIsOpenCustomerModal(true)}
        >
          <span>{commonLang('Import')}</span>
        </button>
        <button className='common-btn-export' onClick={onClickExport}>
          <span>{commonLang('Export')}</span>
        </button>
        <AddNewCustomer />
      </div>
      <CustomersTable />
      <ImportModal
        isOpenModal={isOpenCustomerModal}
        handleCancel={() => setIsOpenCustomerModal(false)}
        onSubmit={handleSubmitImport}
      />
    </>
  );
};

export default CustomersPage;
