import React from 'react';
import { Modal } from 'antd';
import ProgramForm, { ProgramFormProps } from './Form';

interface Props extends ProgramFormProps {
  visible?: boolean;
  isEdit?: boolean;
}
const ProgramModal = ({ visible, ...rest }: Props) => {
  if (!visible) return null;

  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={rest.onCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <h2 className='modal-title'>
        <p>{!rest?.isEdit ? 'Add Program' : 'Edit Program'} </p>
      </h2>
      <ProgramForm {...rest} />
    </Modal>
  );
};

export default ProgramModal;