import { CalendarViewType, EBookingStatus } from 'constants/index';
import { BREAK_TIME_MINUTES } from 'features/bookings/services/constants';
import bookingSelectors from 'features/bookings/services/selectors';
import { IBookAssignmentServiceResData } from 'features/bookings/services/types/booking';
import moment, { Moment } from 'moment';
import React, { useMemo, useState } from 'react';
import DraggableItem from '../DnD/DraggableItem';
import PopoverEdit from '../PopoverEdit';
import TeamMemberItem, { IServiceFilter, TeamMemberItemStyled } from '../UITeamMemberItem';
import { PositionStyled } from '../UITeamMemberMultiItem';
import { BookingItem } from '../types';
import UIPopover from './Popover';
import UIBookingItemStyled, { IconCheckCircle, IconLock, TeamMemberItemStyledOld } from './styles';
import { first } from 'lodash';
import useOpenPopover from '../useOpenPopover';
type Props = {
  col_id?: string;
  data: BookingItem;
  rowTime: Moment;
  isChildMultiple?: boolean;
  multiViewPopup?: boolean;
  forceRender?: boolean;
  height?: number;
};

const UIBookingItem: React.FC<Props> = (props: Props) => {
  const viewType = bookingSelectors.getCalendarViewType();
  const isMemberCalendarView = useMemo(() => viewType === CalendarViewType.MemberView, [viewType]);
  if (!isMemberCalendarView || !!props.forceRender) return <BookingItemLayout {...props} />;
  const {
    time_start,
    book_assignment_services = [],
  } = props.data;

  const book_services_filter = useMemo(() => {
    const _time_start = moment(time_start);
    const _book_assignment_services = book_assignment_services.map((o) => {
      const breakTimeMinutes = BREAK_TIME_MINUTES;
      const prevServiceMinutes = (o.duration_time ?? 0) * (o.quantity ?? 0);

      const result: IServiceFilter = {
        id: o.id,
        category_service_color: o.category_service_color,
        service_name: o.service_name,
        service_id: o?.service_id,
        time_start: _time_start.format('HH:mm'),
        time_end: _time_start.clone().add(prevServiceMinutes, 'minute').format('HH:mm'),
        duration_time: o?.duration_time,
        quantity: o?.quantity,
        employee_id: o?.assigned_employee?.employee?.id ?? 0,
        sale_price: o?.service_price,
        service_variant_id: o?.service_variant_id,
      };
      _time_start.add(breakTimeMinutes + prevServiceMinutes, 'minute');
      return result;
    });
    return _book_assignment_services;
  }, [isMemberCalendarView, props.col_id, props.data]);
  if (book_services_filter.length > 0)
    return (
      <TeamMemberItemStyledOld className='TeamMemberItemStyledOld'>
        {book_services_filter.map((s, i) => <TeamMemberItem multiViewPopup={props.multiViewPopup} rowTime={props.rowTime} key={s.id + i} parentData={props.data} service={s} />)}
      </TeamMemberItemStyledOld >
    );
  return <BookingItemLayout {...props} />;
};
export default UIBookingItem;

const BookingItemLayout = ({ data, isChildMultiple = false, forceRender, multiViewPopup, rowTime, height }: Props) => {
  const [open, setOpen] = useOpenPopover();
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    customer,
    time_start,
    time_end,
    book_status,
    book_assignment_services = [],
    id,
  } = data;

  const pixelPerMinute = bookingSelectors.getPixelPerMinute();

  const customerName = book_status === EBookingStatus.DRAFT ? 'Draft booking' : customer?.name ?? '';

  const IconStatus = useMemo(() => {
    switch (book_status) {
      case EBookingStatus.NO_SHOW:
      case EBookingStatus.IN_PROGRESS:
      case EBookingStatus.OPEN: {
        if (
          book_assignment_services.every(
            (ser) =>
              !ser?.assigned_employee || !ser?.assigned_employee?.employee
          )
        ) {
          return null;
        }
        return <IconLock />;
      }

      case EBookingStatus.COMPLETED:
        return <IconCheckCircle />;
      default:
        return null;
    }
  }, [book_status]);

  const onClickExpand: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const offset = useMemo(() => {
    const time = moment(time_start, 'YYYY-MM-DD HH:mm:ss');
    const timeStart = moment(rowTime).set({ hour: +time.get('hour'), minute: +time.get('minute') });
    const top = timeStart.diff(rowTime, 'minute');
    const bottom = +data.duration;
    return ({
      top, bottom
    });
  }, [data, rowTime]);

  const groupContent = () => {
    return (
      <TeamMemberItemStyled distance={multiViewPopup ? 50 : height ?? 0} pixelPerMinute={pixelPerMinute} className={`BookingItemLayout_TeamMemberItemStyled ${multiViewPopup ? 'multiViewPopup' : ''} ${forceRender ? 'forceRender' : ''}`}>
        <UIBookingItemStyled
          bookStatus={book_status}
          isChildMultiple={isChildMultiple}
          className={'memberView'}
        >
          <div className='item-container' onClick={() => setOpen(true)}>
            <div className='item-inner'>
              <p className='item-title'>{customerName}</p>
              <p className='item-time'>
                {moment(time_start).format('HH:mm')} -{' '}
                {moment(time_end).format('HH:mm')}
              </p>
              <div className={`services ${isExpanded ? 'is-expanded' : ''}`}>
                {book_assignment_services.length > 0 ? (
                  <>
                    {book_assignment_services.map((ser) => (
                      <ServiceItem key={ser.id + '_' + data.id} data={ser} />
                    ))}
                    {book_assignment_services.length > 1 && (
                      <button className='expanded-btn' onClick={onClickExpand}>
                        <span>Close</span>
                      </button>
                    )}
                  </>
                ) : (
                  <div className='service-item'>
                    <span className='name'>No services</span>
                  </div>
                )}
              </div>
            </div>
            <div className='dash' onClick={e => e.stopPropagation()}>
              {IconStatus}
              <PopoverEdit bookId={id} bookStatus={book_status} data={data} />
            </div>
          </div>
        </UIBookingItemStyled>
      </TeamMemberItemStyled>
    );
  };

  const key = useMemo(() => {
    const serviceId = first(data.book_assignment_services)?.service_id ?? '';
    const service_variant_id = first(data.book_assignment_services)?.service_variant_id ?? '';
    return id + '_' + serviceId + '_' + service_variant_id;
  }, [data]);

  return (
    <UIPopover open={open} setOpen={setOpen} data={data}>
      <DraggableItem
        key={key}
        id={key}
        data={{ bookingId: data.id, serviceId: first(data.book_assignment_services)?.service_id, service_variant_id: first(data.book_assignment_services)?.service_variant_id, assigned_employee: first(data.book_assignment_services)?.assigned_employee }}
      >

        {forceRender ? groupContent() :
          <PositionStyled className={`box BookingItemLayout_TeamMemberItemStyled_PositionStyled ${multiViewPopup ? 'multiViewPopup' : ''} ${forceRender ? 'forceRender' : ''}`} top={offset.top} bottom={offset.bottom} pixelPerMinute={pixelPerMinute}>
            {groupContent()}
          </PositionStyled>
        }
      </DraggableItem>
    </UIPopover>
  );
};

const ServiceItem = ({ data }: { data: IBookAssignmentServiceResData }) => {
  return (
    <div className='service-item'>
      <div
        className='circle'
        style={{ backgroundColor: data.category_service_color }}
      />
      <span className='name'>{data.service_name}</span>
    </div>
  );
};
