const IconPlusCircle = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.7544 12C22.7544 6.47715 18.2772 2 12.7544 2C7.23155 2 2.75439 6.47715 2.75439 12C2.75439 17.5228 7.23155 22 12.7544 22C18.2772 22 22.7544 17.5228 22.7544 12Z" stroke="#4A9D77" strokeWidth="1.5" />
      <path d="M12.7544 8V16" stroke="#4A9D77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.7544 12L8.75439 12" stroke="#4A9D77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default IconPlusCircle;
