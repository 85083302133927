import React, { useState } from 'react';
import styled from 'styled-components';
import { ICategoryFormProps } from './Form';
import CategoryModal from './Modal';
import apisService from 'features/services/services/apis';
import { get } from 'lodash';
import message from 'components/common/Message';
import { useDispatch } from 'react-redux';
import serviceActions from 'features/services/services/actions';
import serviceSelectors from 'features/services/services/selectors';
type ICategoryAddProps = {};
const CategoryAdd: React.FC<ICategoryAddProps> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errors] = useState<ICategoryFormProps['errors']>(null);
  const showModal = () => setIsModalOpen(true);
  const hideModal = () => setIsModalOpen(false);
  const dispatch  = useDispatch();
  const params = serviceSelectors.category.getParams();


  const handleSubmit: ICategoryFormProps['onSubmit'] = async (payload) => {
    hideModal();
    try {
      const res = await apisService.category.create(payload);
      const msg = get(res, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(serviceActions.category.getLstData.fetch(params));
        
      } else {
        throw 'fail';
      }

    } catch (error) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors?.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      // setPageLoading(false);
    }
  };

  return (
    <CategoryAddStyled>
      <button onClick={showModal} className={'common-btn'}>
        Add new
      </button>
      {isModalOpen && <CategoryModal
        visible={isModalOpen}
        isEdit={false}
        onClose={hideModal}
        onSubmit={handleSubmit}
        errors={errors}
      />}
    </CategoryAddStyled>
  );
};

export default CategoryAdd;
const CategoryAddStyled = styled.div``;
