import StaffFormInputSelect from './StaffFormInputSelect';
import StaffFormInputSwitch from './StaffFormInputSwitch';
import StaffFormInputText from './StaffFormInputText';
import StaffFormInputPassword from './StaffFormInoutPassword';
import { Rule } from 'antd/es/form';
import PhoneNumberInput from 'components/common/PhoneNumberInput';
import {Form} from 'antd';

interface IOption {
    label: string;
    value: string;
}

export interface IStaffFormInput {
    label?: string;
    name?: string;
    rules?: Rule[];
    placeholder?: string;
    defaultValue?: string;
    options?: IOption[];
    type?: 'text' | 'select' | 'switch' | 'password' | 'phone';
    title?: string;
    subTitle?: string;
}

type Props = {
    inputs: IStaffFormInput[];
}

const StaffFormContent = ({ inputs }: Props) => {
    return (
        <>
            {inputs.map(function (input, i) {
                switch (input.type) {
                    case 'select':
                        return (<StaffFormInputSelect
                            {...input}
                            key={`${input.name}_${i}`}
                            label={input.label}
                            placeholder={input.placeholder}
                            name={input.name}
                            defaultValue={input.defaultValue}
                            options={input.options}
                        />);
                    case 'switch':
                        return (<StaffFormInputSwitch
                            {...input}
                            key={`${input.name}_${i}`}
                            label={input.label}
                            title={input.title}
                            subTitle={input.subTitle}
                        />);
                    case 'password':
                        return (
                            <StaffFormInputPassword {...input} key={`${input.name}_${i}`} />
                        );
                    case 'phone':
                        return (
                            <Form.Item name='phone' label='Phone number'>
                                <PhoneNumberInput key={`${input.name}_${i}`}/>
                            </Form.Item>

                        );
                    case 'text':
                    default:
                        return (<StaffFormInputText
                            {...input}
                            key={`${input.name}_${i}`}
                            label={input.label}
                            rules={input.rules}
                            placeholder={input.placeholder}
                            name={input.name}
                        />);
                }
            })}
        </>
    );
};

export default StaffFormContent;