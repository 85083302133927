import { Select } from 'antd';
import workingHourActions from 'features/workingHour/services/actions';
import workingHourSelectors from 'features/workingHour/services/selectors';
import React, { useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
type ILocationPickerProps = {};
const LocationPicker: React.FC<ILocationPickerProps> = () => {
  const dispatch = useAppDispatch();
  const storeLocations = workingHourSelectors.getLocations();
  const locationOpts = useMemo(() => {
    const lst: { value: number | string, label: string }[] = [{
      value: 'all',
      label: 'All locations'
    }];
    storeLocations.forEach(item => {
      const result = ({
        value: item.id,
        label: item.name,
      });
      lst.push(result);
    });
    return lst;
  }, [storeLocations]);

  const location = (workingHourSelectors.getParamValue('merchant_location_id') ?? 'all') as (string | number);
  const onChangeLocation = (val: string | number | undefined) => {
    dispatch(workingHourActions.setParams({
      // @ts-ignore
      merchant_location_id: val !== 'all' ? val : undefined,
      employee_id: undefined,
    }));
  };

  if (storeLocations.length <= 1) return null;

  return (
    <LocationPickerStyled>
      <div className="common-select">
        <Select
          value={location}
          options={locationOpts}
          className='is-normal'
          onChange={onChangeLocation}
        />
      </div>
    </LocationPickerStyled>
  );
};

export default LocationPicker;
const LocationPickerStyled = styled.div``;
