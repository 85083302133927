import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IApiServiceParams } from './types/services';
import { createAction } from '@reduxjs/toolkit';
import { IApiCategoryParams } from './types/category';

const getListPaginateServices = createAsyncAction<IApiServiceParams>(PREFIX_ACTIONS + 'GET_LIST_PAGINATE_SERVICE');

const setServiceListParams = createAction<IApiServiceParams>(PREFIX_ACTIONS + 'SET_SERVICE_LIST_PARAMS');

const getShowCreateService = createAsyncAction(PREFIX_ACTIONS + 'GET_SHOW_CREATE_SERVICE');

const setServiceDetail = createAction<any>(PREFIX_ACTIONS + 'SET_SERVICE_DETAIL');

const category = {
    initData: createAction(PREFIX_ACTIONS + 'CATEGORY' + 'initData'),
    getLstData: createAsyncAction<IApiCategoryParams>(PREFIX_ACTIONS + 'CATEGORY' + 'getLstData'),
    setParams: createAction<IApiCategoryParams>(PREFIX_ACTIONS + 'CATEGORY' + 'setParams'),
};

const serviceActions = {
    setServiceListParams,
    getListPaginateServices,
    getShowCreateService,
    setServiceDetail,
    category,
};

export default serviceActions;