import { DatePicker, Space } from 'antd';
import dayjs from 'utils/dayjs';
import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
import { IApiBookingParams } from 'features/bookings/services/types/booking';
import moment from 'moment';
import { MouseEventHandler, useMemo, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { TIME_DATE_TIMEZONE } from 'features/bookings/services/constants';

function DateViewPicker() {
  const dispatch = useAppDispatch();
  const dateStore = bookingSelectors.getLstTableParamValue('date') as number;
  const [date, setDateState] = useState(dateStore);

  const activeLocation = bookingSelectors.getCalendarCurrentLocation();
  

  const onChangeDate = (_date: number, addOnParams?: IApiBookingParams) => {
    
    dispatch(
      bookingActions.setListTableBookingParams({
        date: dayjs(dayjs(_date).tz().format(TIME_DATE_TIMEZONE.replace('Z', ''))).valueOf(),
        ...(addOnParams ?? {}),
      })
    );
    setDateState(_date);
  };

  const getSingleDateFormat = () => {
    const _date = moment(date);
    const today = moment();
    if (_date.isSame(today, 'day')) {
      return 'Today, ' + _date.format('DD.MM.YYYY');
    }

    if (_date.isSame(today.clone().subtract(1, 'days'), 'day')) {
      return 'Yesterday, ' + _date.format('DD.MM.YYYY');
    }

    if (_date.isSame(today.clone().add(1, 'days'), 'day')) {
      return 'Tomorrow, ' + _date.format('DD.MM.YYYY');
    }

    return _date.format('dddd, DD.MM.YYYY');
  };

  const date_closed = useMemo(() => {
    return activeLocation?.date_closed.map(o => ({
      start: dayjs(o.start_date, 'YY-MM-DD'),
      end: dayjs(o.end_date, 'YY-MM-DD'),
    }));
  }, [activeLocation?.date_closed]);

  const [open, setOpen] = useState(false);

  const onPressPrev: MouseEventHandler<HTMLButtonElement> = (e) => {
    setOpen(false);
    e.stopPropagation();
    onChangeDate(moment(date).subtract(1, 'day').valueOf());
  };

  const onPressNext: MouseEventHandler<HTMLButtonElement> = (e) => {
    setOpen(false);
    e.stopPropagation();
    onChangeDate(moment(date).add(1, 'day').valueOf());
  };


  return (
    <DateViewPickerStyled>
      <Space direction='horizontal'>
        <DatePicker
          className='is-normal'
          allowClear={false}
          open={open}
          onOpenChange={setOpen}
          value={dayjs(moment(date).format())}
          suffixIcon={false}
          inputRender={(props: any) => (
            <InputPickerStyled>
              <button onClick={onPressPrev}><IconPrev /></button>
              <RangePickerStyled {...props}>
                <p>{getSingleDateFormat()}</p>
              </RangePickerStyled>
              <button onClick={onPressNext}><IconNext /></button>
            </InputPickerStyled>
          )}
          onChange={(_dayjs) => {
            if (!_dayjs) return;
            onChangeDate(_dayjs.valueOf());
          }}
          disabledDate={d => {
            const isDateClosed = !!date_closed?.some(o => d.format('YY-MM-DD') == o.start.format('YY-MM-DD') || d.isBetween(o.start, o.end));
            if (isDateClosed) return true;

            return !activeLocation?.time_opening.some(o => o?.weekday === d?.format('dddd')?.toLowerCase());
          }}
        />
      </Space>
    </DateViewPickerStyled>
  );
}

export default DateViewPicker;

const IconPrev = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.3101 18L9.31006 12L15.3101 6'
        stroke='#363565'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const IconNext = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.31006 18L15.3101 12L9.31006 6'
        stroke='#363565'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
const InputPickerStyled = styled.div`
  display:flex;
  align-items:center;
  button {
    width:50px;
  }
`;
const RangePickerStyled = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const DateViewPickerStyled = styled.div`
  .ant-input,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-number .ant-input-number-input,
  .ant-select-single .ant-select-selector,
  .ant-picker {
    padding: 0;
  }
`;
