import BlockInformation from 'features/sales/components/BlockInformation';
import ButtonHandler from 'features/sales/components/ButtonHandler';
import CheckoutPageStyled, { CheckoutWrapPageStyled } from 'features/checkout/pages/CheckoutPage/styles';
import BlockPayment from 'features/sales/components/BlockPayment';
import CustomerInfoStepSuccess from 'features/sales/components/InfoStepSuccess';
// import LocationResult from 'features/sales/components/LocationPicker/LocationResult';
import PromotionCode from 'features/sales/components/PromotionCode';
import ResultAmountRow from 'features/sales/components/ResultAmountRow';
import DetailAmountRow from 'features/sales/components/ResultAmountRow/DetailAmount';
import RewardList from 'features/sales/components/RewardList';
import ServiceDetails from 'features/sales/components/ServiceDetails';
import salesHook from 'features/sales/hooks/salesHook';
import salesActions from 'features/sales/services/actions';
import userActions from 'features/users/services/actions';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import CustumerDetail from 'features/sales/components/CustumerDetail';
import { Divider } from 'antd';
import settingSelectors from 'features/settings/services/selectors';
import salesSelectors from 'features/sales/services/selectors';
import settingActions from 'features/settings/services/actions';
import moment from 'moment';
import { CUSTOMER_WALKIN_INDEX } from 'utils/unit';


type ISalesPageProps = {};
const SalesPage: React.FC<ISalesPageProps> = () => {
  const dispatch = useAppDispatch();
  const [currCustomer] = salesHook.useSetCustomerBooking();
  const [step, setStep] = useState<number>(1);
  const selectedService = salesSelectors.getSelectedServices(); 
  const merchantLocationId = salesSelectors.getMerchantLocationId();
  const bookingDate = salesSelectors.getBookingDate();

  useEffect(() => {
    dispatch(salesActions.fetchConfigsData());
    dispatch(userActions.getListPaginateStaffs.fetch({ per_page: 99999 }));
  }, []);
  
  useEffect(() => {
    dispatch(settingActions.getHolidayInfoToday.fetch({today: moment(bookingDate).unix(), merchant_location_id: Number(merchantLocationId)}));
  }, [merchantLocationId]);


  useEffect(() => {
    dispatch(salesActions.getTotalPointCustomer.fetch({
      is_walkin_in: !!currCustomer?.is_walkin_in,
      customer_code: currCustomer?.customer_code?.replace('#', '') ?? '',
    }));
  }, [currCustomer]);

  useEffect(() => {
    step === 1 && dispatch(salesActions.setSelectedReward(null));
  }, [step]);
  
  const setting = settingSelectors.getSetting();  

  return (
    <CheckoutWrapPageStyled>
      <CheckoutPageStyled>
        {/* <div className='block-heading'>
          <ul>
            <li>
              <span className={`${step !== 1 && 'is-finish'}`}>1</span>
              Information
            </li>
            <li>
              <span className={`${step === 3 && 'is-finish'}`}>2</span>
              Payment Details
            </li>
            <li>
              <span className={`${step === 3 && 'is-finish'}`}>3</span>
              Complete Order
            </li>
          </ul>
          {step === 3}
        </div> */}
        <div className='body'>
          {step === 1 && <BlockInformation step={step} />}
          {step === 2 && <BlockPayment />}
          {/* <CustomerDetail
              viewOnly={step !== 1}
              isInProgress={step !== 1}
              isTotalWillPointEarn={step === 3}
              currCustomer={currCustomer}
              setCurrCustomer={setCurrCustomer}
            /> */}
          <div className='block'>
            {step === 3 &&
              <>
                <CustomerInfoStepSuccess />
                <CustumerDetail step={step} />
              </>
            }
            {step !== 2 && <div className={`${step === 3 && 'is-complete'}`}>
              <ServiceDetails step={step} viewOnly={step !== 1} />
            </div>}
            {step === 2 && (
              <>
                <Divider className='divider' />
                {currCustomer?.is_walkin_in !== CUSTOMER_WALKIN_INDEX ? <RewardList /> : <></>}
                {setting.booking.is_promotion_redeem && selectedService?.length > 0 && <PromotionCode />}
              </>
            )}

            {/* <div className='form-row-textarea'>
                <Form.Item
                  name={'note'}
                  label={
                    <p>
                      Important customer’s note<span>{formOptionalText}</span>
                    </p>
                  }
                >
                  <TextArea
                    placeholder={'Enter Important customer’s note' || ''}
                    rows={4}
                    className='is-white'
                  ></TextArea>
                </Form.Item>
              </div> */}

            
            <div className='block-fixed'>
              <DetailAmountRow step={step} />
              <Divider style={{margin: '8px 0px'}}/>
              <ResultAmountRow step={step} />
              <ButtonHandler setStep={setStep} step={step} />
            </div>
          </div>
        </div>
      </CheckoutPageStyled>
    </CheckoutWrapPageStyled>
  );
};

export default SalesPage;
