import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'utils/dayjs';
import workingHourSelectors from 'features/workingHour/services/selectors';
import React from 'react';
import styled from 'styled-components';
type IDateRangePickerProps = {
  onChangeDateRange: RangePickerProps['onChange']
};
const DateRangePicker: React.FC<IDateRangePickerProps> = ({
  onChangeDateRange
}) => {
  const params = workingHourSelectors.getParams();  

  // const onChangeDateRange: RangePickerProps['onChange'] = (values) => {
  //   const startDate = first(values);
  //   const endDate = last(values);
  //   dispatch(workingHourActions.setParams({
  //     start_date: startDate?.unix(),
  //     end_date: endDate?.unix(),
  //   }));
  // };  

  return (
    <DateRangePickerStyled>
      <div className="common-select">
      <DatePicker.RangePicker
        allowClear={false}
        suffixIcon={false}
        value={[dayjs((params?.start_date ?? params.date ?? 0)*1000).tz(), dayjs((params?.end_date ?? params.date ?? 0)*1000).tz()]}
        onChange={onChangeDateRange}
        inputReadOnly
      />
    </div>
    </DateRangePickerStyled>
  );
};

export default DateRangePicker;
const DateRangePickerStyled = styled.div``;
