import { Form, Radio, Spin, Switch } from 'antd';
import MultipleSelect, { IOptionMulti } from 'components/common/MultipleSelect';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import PromotionsFormFirstStep from '../components/PromotionsFormFirstStep';
import promotionSelectors from '../services/selectors';
import { IApiPromotionBodyReq } from '../types/api';
import { IPromotionFormValue } from '../types/promotion';
import { PROMOTION_TYPES } from '../services/constants';
import { FormInstance } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useAppDispatch } from 'store/hooks';
import promotionActions from '../services/actions';

export type IPromotionFormProps = {
  onClose?: () => void;
  isEdit?: boolean;
  formData?: IPromotionFormValue | null;
  onSubmit?: (payload: IApiPromotionBodyReq) => void;
  errors?: Record<string, string> | null;
  form: FormInstance;
  formType?: 'CREATE' | 'UPDATE';
  handleUpdateError?: (data: Record<string, string>) => void;
};
const PromotionForm: React.FC<IPromotionFormProps> = ({
  onClose = () => undefined,
  onSubmit = () => undefined,
  formData = null,
  isEdit = false,
  errors = null,
  form,
  formType,
  handleUpdateError,
}) => {
  // const [form] = Form.useForm();
  const [selectedLocations, setSelectedLocations] = useState<
    (string | number)[]
  >([]);
  const [selectedServices, setSelectedServices] = useState<(string | number)[]>(
    []
  );
  const { t: formLang } = useTranslation('form');
  const dispatch = useAppDispatch();
  const loadingPromotionCode =
    promotionSelectors.getLoadingGeneratePromotionCodeActions();
  const promotionCode = promotionSelectors.generatePromotionCode();


  useEffect(() => {
    setSelectedLocations(formData?.locations?.map((i) => Number(i)) ?? []);
  }, [formData]);

  const configsData = promotionSelectors.getConfigsData();
  const locationOpts = useMemo(
    () =>
      configsData?.locarions
        ?.filter((o) => o.status === 1)
        ?.map((o) => ({ title: o.name, value: o.id } as IOptionMulti)) ?? [],
    [configsData]
  );

  const serviceOpts = useMemo(() => {
    const result: IOptionMulti[] = [];
    configsData?.services
      .filter((o) => {
        return !!o?.merchant_location_id?.find(
          (d) => !!selectedLocations.find((v) => v.toString() === d.toString())
        );
      })
      ?.forEach((service) => {
        if (service?.service_variants?.length > 0) {
          service?.service_variants.forEach((serviceVariant) => {
            result.push({
              value: service?.id + '-' + serviceVariant.id,
              title: service.name + ' - ' + serviceVariant.name,
            });
          });
        } else {
          result.push({
            value: service.id + '',
            title: service.name,
          });
        }
      });
    return result;
  }, [configsData, selectedLocations]);

  useEffect(() => {
    form.setFieldValue('services', selectedServices);
  }, [selectedServices]);

  useEffect(() => {
    form.setFieldValue('locations', selectedLocations);
  }, [selectedLocations]);

  useEffect(() => {
    form.setFieldValue('code', promotionCode);
  }, [promotionCode]);

  useEffect(() => {
    if (!isEdit) return;
    form.setFieldsValue(formData);
  }, [formData]);

  const handleCancel = () => {
    onClose();
    handleUpdateError?.({ code: '', name: '', value: '' });
  };

  const checkValid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };

  const submit = async () => {
    if (!(await checkValid())) return;
    form.submit();
  };

  const getPayload = (values: IPromotionFormValue) => {
    let value: IApiPromotionBodyReq['value'] = 0;
    let type: IApiPromotionBodyReq['type'] = '';
    if (values.amountValue) {
      type = PROMOTION_TYPES.PRICE;
      value = values.amountValue;
    } else if (values.percentValue) {
      type = PROMOTION_TYPES.PERCENT;
      value = values.percentValue;
    }
    const services: IApiPromotionBodyReq['services'] = [];
    values.services?.forEach((s) => {
      if (!s) return;
      const arr = s.split('-');
      const serviceParentId = get(arr, [0], '')?.toString();
      const serviceExtraId = get(arr, [1], '')?.toString();

      const parent = services.find((o) => o.id?.toString() === serviceParentId);
      if (parent) {
        parent.service_variant_ids.push(+serviceExtraId);
      } else {
        if (serviceExtraId) {
          services.push({
            id: +serviceParentId,
            service_variant_ids: [+serviceExtraId],
          });
        } else {
          services.push({
            id: +serviceParentId,
            service_variant_ids: [],
          });
        }
      }
    });

    const location_ids = values.locations?.map((o) => +o) ?? null;

    const result: IApiPromotionBodyReq = {
      promotion_type: values?.promotionType,
      name: values.name ?? '',
      type,
      value,
      location_ids,
      services,
      status: values.enablePromotion ? true : false,
      enable_voucher_sales: values.enableVoucherSale ? true : false,
    };

    if (values?.promotionType === 'auto_offer') {
      result.start_date = values?.startDate
        ? dayjs(values?.startDate).format('YYYY-MM-DD')
        : '';
      result.end_date = values?.endDate
        ? dayjs(values?.endDate).format('YYYY-MM-DD')
        : '';
      result.apply_for_walkin = values?.enableApplyWalkIn ? true : false;
    } else {
      result.code = values?.code || '';
      result.enable_booking_online = values?.enableBookingOnline ? true : false;
    }

    return result;
  };

  const onFinish = (values: IPromotionFormValue) => {
    const payload = getPayload(values);
    if (onSubmit) onSubmit(payload);
  };

  const handleFormChangeValue = (changedValues: any) => {
    if (changedValues?.promotionType === 'code') {
      if (formType === 'CREATE' || (formType === 'UPDATE' && !formData?.code)) {
        dispatch(promotionActions.generatePromotionCode.fetch());
      } else if (formType === 'UPDATE') {
        const currentPromotionCode = form.getFieldValue('code');

        if (currentPromotionCode !== formData?.code) {
          form.setFieldValue('code', formData?.code);
        }
      }
    }
  };

  const errorsText = useMemo(() => {
    return {
      name: get(errors, 'name', ''),
      code: get(errors, 'code', ''),
      value: get(errors, 'value', ''),
      services: get(errors, 'services', ''),
      location_ids: get(errors, 'location_ids', ''),
    };
  }, [errors]);

  return (
    <PromotionFormStyled>
      <Spin spinning={loadingPromotionCode}>
        <Form
          className='modal-form'
          autoComplete='off'
          form={form}
          layout='vertical'
          onKeyUp={(e) => {
            if (e.code === 'Enter') {
              submit();
            }
          }}
          initialValues={{
            promotionType: !isEdit ? 'auto_offer' : formData?.promotionType,
            enableApplyWalkIn: true,
            enablePromotion: true,
            enableVoucherSale: true,
            enableBookingOnline: true,
          }}
          onFinish={onFinish}
          onValuesChange={handleFormChangeValue}
        >
          <Form.Item
            name='promotionType'
            label={formLang('promotion.promotionType.text')}
          >
            <Radio.Group
              onChange={() => {
                handleUpdateError?.({ code: '', name: '', value: '' });
              }}
            >
              <Radio value='auto_offer'>
                {formLang('promotion.promotionType.autoOffer')}
              </Radio>
              <Radio value='code'>
                {formLang('promotion.promotionType.promotionCode')}
              </Radio>
            </Radio.Group>
          </Form.Item>
          <PromotionsFormFirstStep errorsText={errorsText} />

          <div className='form-row'>
            <Form.Item
              label={
                <p>
                  {formLang('promotion.restrictLocation')}{' '}
                  <span>(optional)</span>
                </p>
              }
              name='locations'
              validateStatus={errorsText?.location_ids ? 'error' : undefined}
              help={
                errorsText?.location_ids ? errorsText?.location_ids : undefined
              }
            >
              <MultipleSelect
                placeholder='Select Locations'
                options={locationOpts}
                onChange={setSelectedLocations}
                value={selectedLocations}
                maxTagPlaceholder='locations'
              />
            </Form.Item>
          </div>
          <div className='form-row'>
            <Form.Item
              label={
                <p>
                  {formLang('promotion.restrictServices')}{' '}
                  <span>(optional)</span>
                </p>
              }
              name='services'
              validateStatus={errorsText?.services ? 'error' : undefined}
              help={errorsText?.services ? errorsText?.services : undefined}
            >
              <MultipleSelect
                onChange={setSelectedServices}
                value={selectedServices}
                options={serviceOpts}
                maxTagPlaceholder='services'
                placeholder='Select services'
              />
            </Form.Item>
          </div>

          <br />

          <Form.Item
            dependencies={['promotionType']}
            noStyle
          >
            {({ getFieldValue }: any) => {
              // getFieldValue defined here
              return getFieldValue('promotionType') === 'code' ? (
                <div className='form-row switch-check'>
                  <p className='form-label'>
                    {formLang('promotion.availableForBookingOnline')}
                  </p>
                  <Form.Item
                    noStyle
                    name={'enableBookingOnline'}
                    valuePropName='checked'
                  >
                    <Switch defaultChecked />
                  </Form.Item>
                </div>
              ) : null;
            }}
          </Form.Item>

          <div className='form-row switch-check'>
            <p className='form-label'>
              {formLang('promotion.enableVoucherSales')}
            </p>
            <Form.Item
              noStyle
              name={'enableVoucherSale'}
              valuePropName='checked'
            >
              <Switch defaultChecked />
            </Form.Item>
          </div>

          <Form.Item
            dependencies={['promotionType']} 
            noStyle
          >
            {({ getFieldValue }: any) => {
              // getFieldValue defined here
              return getFieldValue('promotionType') === 'auto_offer' ? (
                <div className='form-row switch-check'>
                  <p className='form-label'>
                    {formLang('promotion.applyForWalkin')}
                  </p>
                  <Form.Item
                    noStyle
                    name={'enableApplyWalkIn'}
                    valuePropName='checked'
                  >
                    <Switch defaultChecked />
                  </Form.Item>
                </div>
              ) : null;
            }}
          </Form.Item>

          <div className='form-row switch-check'>
            <p className='form-label'>
              {formLang('promotion.enablePromotion')}
            </p>
            <Form.Item noStyle name={'enablePromotion'} valuePropName='checked'>
              <Switch defaultChecked />
            </Form.Item>
          </div>

          <div className='form-submit-wrapper'>
            <div onClick={handleCancel} className='common-btn is-white'>
              Cancel
            </div>
            <button type='button' onClick={submit} className='common-btn'>
              Save
            </button>
          </div>
        </Form>
      </Spin>
    </PromotionFormStyled>
  );
};

export default PromotionForm;
const PromotionFormStyled = styled.div`
  .hide {
    display: none;
  }
  .form-row {
    margin-bottom: 0;
    &.switch-check {
      margin-bottom: 15px;
    }
    &.groupPrice {
      margin-bottom: 0;
      .center-text {
        margin: 0;
        margin-bottom: 18px;
      }
      .ant-col {
        &.ant-form-item-control {
          margin-bottom: 0;
        }
      }
    }
    .promotion-start-date,
    .promotion-end-date,
    .promotion-start-date:hover,
    .promotion-end-date:hover {
      background: #f0f2f6 !important;
    }
  }
  .ant-form-item {
    &.messageError .ant-form-item-control-input {
      display: none;
      min-height: 0 !important;
    }
  }
`;
