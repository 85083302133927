import { Rate, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getFormatShortDate } from 'utils/moment/getSingleDateShortFormat';
import reviewActions from '../services/actions';
import reviewSelectors from '../services/selectors';
import { IApiReviewResponse } from '../services/types/review';

type Props = {
  handleDetailClick: (value: string) => void;
};

const ReviewsTable = (props: Props) => {
  const { handleDetailClick } = props;

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  
  useEffect(() => {
    dispatch(reviewActions.setReviewParams({}));
  }, []);

  const params = reviewSelectors.getReviewParam();

  useEffect(() => {
    dispatch(reviewActions.getListReview.fetch(params));
  }, [params]); 

  const reviews = reviewSelectors.getListReview();
  const loadingListReview = reviewSelectors.getLoadingListReview();

  const dataSource = reviews?.data?.map((o) => ({
    invoiceNo: o?.invoice_no,
    customerName: o?.customer?.name,
    reviewDate: o?.review_date,
    ...o
  }));  
  
  const columns: ColumnsType<IApiReviewResponse> = [
    {
      title: 'Invoice no',
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
      fixed: 'left',
      className: 'nowrap',
    },
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: 'Review Date',
      dataIndex: 'reviewDate',
      key: 'reviewDate',
      className: 'nowrap',
      render(value) {
        return <span>{getFormatShortDate(value,)}</span>;
      },
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      render: (rate: number) => {
        return <Rate value={rate} disabled={true} />;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: string, record: any) => {
        return (
          <ul className={'common-action'}>
            <li
              className='action-info'
              onClick={() => handleDetailClick(record.id.toString())}
            ></li>
          </ul>
        );
      },
    },
  ];

  return (
    <>
      <Spin spinning={loadingListReview}>
        <Table
          className={'custom-table'}
          dataSource={dataSource}
          columns={columns}
          scroll={{
            x: 900,
          }}
          pagination={{
            total: reviews?.pagination?.total_record,
            defaultCurrent: params.page ?? 1,
            defaultPageSize: params.per_page ?? 10,
            onChange: (pageChange, sizeChange) => {
              const params: any = {
                page: pageChange == 0 ? 1 : pageChange,
                per_page: sizeChange,
                keyword: searchParams.get('keyword')
              };
              dispatch(reviewActions.setReviewParams(params));
            },
            showSizeChanger: true,
            showTotal(total) {
              return `Total ${total} items`;
            },
          }}
        />
      </Spin>
    </>
  );
};

export default ReviewsTable;
