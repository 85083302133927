import { Checkbox, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
import settingSelectors from 'features/settings/services/selectors';
import { get } from 'lodash';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { DividerStyled, LabelStyled } from './BasicContent';
const { TextArea } = Input;
const ImportantNote: React.FC<{
  onlyView?: boolean;
}> = ({ onlyView = false }) => {
  const formValue = bookingSelectors.bookingOnline.getFormValues();
  const dispatch = useAppDispatch();
  const setting = settingSelectors.getSetting();
  const _setAccept = (e: CheckboxChangeEvent) => {
    dispatch(bookingActions.setBookingOnlineFormValueItem({
      key: 'notifyMarket',
      value: e.target.checked,
    }));
  };

  const setPlatform = (platform: string) => {
    dispatch(bookingActions.setBookingOnlineFormValueItem({
      key: 'platform',
      value: platform,
    }));
  };

  const merchantNote = get(setting,'booking.merchant_note','');

  return (
    <ImportantNoteStyled>
      {merchantNote && 
      <>
        <DividerStyled />
        <LabelStyled>Store’s note</LabelStyled>
        <div className="spacing">
          <TextArea rows={5}
            value={get(setting,'booking.merchant_note','')}
            placeholder={'This is a \'terms and conditions\' set by the merchant/user in their online booking settings.'}
            disabled
          />
        </div>
      </>
      }
      {!onlyView && <>
        <div className="spacing">
          <Checkbox checked={formValue?.notifyMarket} onChange={_setAccept}>I want to receive special offers from Store.</Checkbox>
        </div>
        <div className='spacing group_platforms'>
          <PlatformButton
            onClick={() => setPlatform('all')}
            className={`platform_button ${formValue?.platform === 'all' ? 'active' : ''}`}
          >
            All
          </PlatformButton>
          <PlatformButton
            onClick={() => setPlatform('email')}
            className={`platform_button ${formValue?.platform === 'email' ? 'active' : ''}`}
          >
            Email
          </PlatformButton>
          <PlatformButton
            onClick={() => setPlatform('sms')}
            className={`platform_button ${formValue?.platform === 'sms' ? 'active' : ''}`}
          >
            SMS
          </PlatformButton>
        </div>
      </>}
      {!onlyView && <p className='text_note'>Select the platform where you’d like to receive our notifications.</p>}
    </ImportantNoteStyled>
  );
};

export default ImportantNote;

const ImportantNoteStyled = styled.div`
.spacing{
  margin-bottom:16px;
}
  .group_platforms {
    margin: 0 -4px;
    .platform_button {
      margin: 0 4px
    }
  }
  .text_note {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    margin-top: 10px;
    color: var(--color-purple-04);
  }
`;
const PlatformButton = styled.button`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9px 24px;
  background: var(--color-white-01);
  border: 1px solid var(--color-white-01);
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: var(--color-purple-04);
  &.active {
    color:var(--color-white);
    background: var(--color-primary);
  }
`;

