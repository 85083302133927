import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import store from 'store';
import './App.scss';

import NotFoundPage from 'features/NotFoundPage';
import AnalyticsPage from 'features/analytics/pages/AnalyticsPage';
import BookingForm from 'features/bookings/pages/BookingForm';
import EditBookingPage from 'features/bookings/pages/EditBookingPage';
import QuickBookingPage from 'features/bookings/pages/QuickBookingPage';
import CheckoutPage from 'features/checkout/pages/CheckoutPage';
import EditProfilePage from 'features/editProfile/pages/EditProfilePage';
import LocationsEditPage from 'features/locations/pages/LocationsEditPage';
import LoyaltyProgramPage from 'features/loyaltyProgram/pages/LoyaltyProgramPage';
import NotificationPage from 'features/notification/pages/NotificationPage';
import PromotionsPage from 'features/promotions/pages/PromotionsPage';
import ReviewsPage from 'features/reviews/pages/ReviewsPage';
import SettingsPage from 'features/settings/pages/SettingsPage';
import VouchersPage from 'features/vouchers/pages/VouchersPage';
import useRefreshToken from 'hooks/useRefreshToken';
import { ToastContainer } from 'react-toastify';
import LoadingPage from 'services/UI/LoadingPage';
import BaseLayout from './components/layout/BaseLayout';
import SignInPage from './features/auth/pages/SignInPage';
import BookingsPage from './features/bookings/pages/BookingsPage';
import CustomersDetailPage from './features/customers/pages/CustomersDetailPage';
import CustomersPage from './features/customers/pages/CustomersPage';
import HomePage from './features/home/pages/HomePage';
import InvoicesDetailPage from './features/invoices/pages/InvoicesDetailPage';
import InvoicesPage from './features/invoices/pages/InvoicesPage';
import LocationsAddNewPage from './features/locations/pages/LocationsAddNewPage';
import LocationsPage from './features/locations/pages/LocationsPage';
import ServicesPage from './features/services/pages/ServicesPage';
import UsersPage from './features/users/pages/UsersPage';
import ForCustomerPage from 'features/reviews/pages/ForCustomer';
import RefundPage from 'features/refund/page/RefundPage';
import CheckInPage from 'features/checkin/pages/CheckInPage';

// import './assets/i18n/i18n';
import { useTranslation } from 'react-i18next';
import ResetPasswordPage from 'features/auth/pages/ResetPasswordPage';
import TemporaryClosedPage from 'features/temporaryClosedPage/pages/TemporaryClosedPage';
import SalesPage from 'features/sales/pages/SalesPage';
import CategoriesPage from 'features/services/pages/CategoriesPage';
import WorkingHourPage from 'features/workingHour/pages/workingHourPage';
import UserCheckInPage from 'features/users/pages/UserCheckIn';
import WorkingHourDetail from 'features/workingHour/pages/workingHourDetail';
import moment from 'moment-timezone';
import TimeSchedulePage from 'features/timeSchedule/pages/TimeSchedulePage';
import PaymentPage from 'features/payment/page';


const CalendarBooking = React.lazy(
  () => import('./features/bookings/components/BookingCalendar')
);

function AppUI() {
  const { loading, shield } = useRefreshToken();
  if (loading) return null;

  return (
    <React.Fragment>
      <Routes>
        <Route path='/private' element={shield(BaseLayout)}>
          <Route path='bookings' element={<BookingsPage />} />
          <Route path='bookings/quick-booking' element={<QuickBookingPage />} />
          <Route path='bookings/edit-booking' element={<EditBookingPage />} />
          <Route
            path='calendar-bookings'
            element={
              <React.Suspense>
                <CalendarBooking />
              </React.Suspense>
            }
          /> 
          <Route
            path='bookings/checkout/:book_assignment_id'
            element={<CheckoutPage />}
          />
          <Route path='sales' element={<SalesPage />} />
          <Route path='customers' element={<CustomersPage />} />
          <Route path='customers/:customer_code' element={<CustomersDetailPage />} />
          <Route path='services' element={<ServicesPage />} />
          <Route path='services/categories' element={<CategoriesPage />} />
          <Route path='analytics' element={<AnalyticsPage />} />
          <Route path='invoices' element={<InvoicesPage />} />
          <Route path='invoices/:invoice_no' element={<InvoicesDetailPage />} />
          <Route path='reviews' element={<ReviewsPage />} />
          <Route path='promotions' element={<PromotionsPage />} />
          <Route path='vouchers' element={<VouchersPage />} />
          <Route path='locations' element={<LocationsPage />} />
          <Route path='locations/add-new' element={<LocationsAddNewPage />} />
          <Route path='locations/edit/:id' element={<LocationsEditPage />} />
          <Route path='loyalty-program' element={<LoyaltyProgramPage />} />
          <Route path='users' element={<UsersPage />} />
          <Route path='settings' element={<SettingsPage />} />
          <Route path='edit-profile' element={<EditProfilePage />} />
          <Route path='notification' element={<NotificationPage />} />
          <Route path='working-hour' element={<WorkingHourPage />} />
          <Route path='working-hour/detail' element={<WorkingHourDetail />} />
          <Route path='time-schedule' element={<TimeSchedulePage />} />
        </Route>
        <Route path='/' element={<HomePage />} />
        <Route path='/sign-in' element={<SignInPage />} />
        <Route path='/reset-password' element={<ResetPasswordPage />} />
        <Route
          path='/:store_name/booking-form/:merchant_code'
          element={<BookingForm />}
        />
        <Route
          path='/:store_name/check-in/:merchant_code'
          element={<BookingForm isCheckIn />}
        />
        <Route
          path='/:store_name/checkin/:merchant_code/:location_id'
          element={<BookingForm isCheckIn />}
        />
        <Route
          path='/check-in'
          element={<CheckInPage />}
        />
        <Route
          path='/staff-check-in'
          element={shield(UserCheckInPage)}
        />
        <Route
          path='/:store_name/reviews/:merchant_code/:customer_code/:invoice_code'
          element={<ForCustomerPage />}
        />
        <Route path='/refund' element={<RefundPage />} />
        <Route
          path='booking-form/:merchant_code'
          element={<BookingForm />}
        />
        <Route path='*' element={<NotFoundPage />} />
        <Route path='temporary' element={<TemporaryClosedPage />} />
        <Route path='/create-intent' element={<PaymentPage/>}/>
      </Routes>
    </React.Fragment>
  );
}
const App = () => {
  // =============== Change language ========================
  const { t, i18n } = useTranslation();
  moment.tz.setDefault('Australia/Sydney');
  // dayjs.tz.setDefault('America/New_York');  
  
  const changeLanguage = (languageValue: any) => {
    i18n.changeLanguage(languageValue);
    document.title = t('HEYA');
    localStorage.setItem('selectedLanguage', languageValue);
  };
  
  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    const defaultLanguage = 'en';
    const selectedLanguage = storedLanguage || defaultLanguage;

    if (selectedLanguage !== i18n.language) {
      changeLanguage(selectedLanguage);
    }
  }, []);
 
  useEffect(() => {
    document.title = t('HEYA');
  }, [t]);
  // ========================================

  return (
    <Provider store={store}>
      <AppUI />
      <LoadingPage />
      <ToastContainer />
    </Provider>
  );
};
export default App;
