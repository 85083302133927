import { Form, Input } from 'antd';
import TimePickerAutoChange from 'components/common/TimePickerAutoChange';
import { timeFormat } from 'utils/unit';

interface TimePickerHourElementProps {
  field: any,
  index: any,
  remove: any,
  weekday: string;
}

const TimePickerHourElement = (
  {
    field,
    index,
    remove,
    weekday,
  }: TimePickerHourElementProps
) => {
  return (
    <>
      <Form.Item name={[index, 'weekday']} initialValue={weekday} noStyle>
        <Input type='hidden' />
      </Form.Item>
      <li key={index} className='pick-item'>
        <Form.Item
          name={[index, 'time_start']}
          validateTrigger={['onSelect']}
          noStyle>
          <TimePickerAutoChange
            placeholder='__:__'
            format={timeFormat}
          />
        </Form.Item>
        to
        <Form.Item
          name={[index, 'time_end']}
          noStyle>

          <TimePickerAutoChange
            placeholder='__:__'
            format={timeFormat}
          />
        </Form.Item>
        <span className='btn-delete' onClick={() => remove(field.name)}></span>
      </li>

    </>
  );
};

export default TimePickerHourElement;
