import { Button, Empty, Form, Modal, TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { IService } from '../../types';
import DetailItem from './DetailItem';
// import deleteImage from 'images/booking/BookingForm/delete_img.png';
import DatePicker from 'components/common/DatePickerInput';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import { EBookingStatus } from 'constants/index';
import quickBookingHooks from 'features/bookings/hooks/useQuickBooking';
import { TIME_START_FORMAT, TIME_START_FORMAT_RESPONSE } from 'features/bookings/services/constants';
import bookingSelectors from 'features/bookings/services/selectors';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import BookingDate from 'components/common/BookingDate';
import { isDisableDateByClosedPeriod } from 'features/bookings/hooks/disableTime';


type Props = {
  listServices: IService[];
  handleRemoveService: any;
  handleEditService: any;
  handleEditQuantityService: any;
  bookingData: any;
  isEdit?: boolean;
  isInProgress?: boolean;
  isBookingOnline?: boolean;
};

const ServiceDetails = (props: Props) => {
  const {
    listServices,
    handleRemoveService,
    handleEditService,
    handleEditQuantityService,
    bookingData,
    isEdit,
    isInProgress = false,
    // isBookingOnline = false
  } = props;
  const { t: bookingLang } = useTranslation('booking');
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [bookingDate, setBookingDate] = quickBookingHooks.useSetDateBooking();
  const busyEmployees = bookingSelectors.quickBooking.getBusyEmployees();

  const modalConfirmRef = useRef<ModalConfirmRef>(null);
  const currentDateBooking = useMemo(() => {
    return moment.tz(bookingDate, moment.tz.guess());
  }, [bookingDate]);

  const handleModalCancel = () => {
    setIsOpenModal(false);
  };

  const handleSubmit = (date: string) => {
    setBookingDate(date);
    handleModalCancel();
    return false;
  };


  const onOpenConfirmModal = (id: number, service_variant_id?: number, index?: number) => {
    if (!isEdit) return handleRemoveService(id, service_variant_id, index);
    modalConfirmRef.current?.show({
      title: bookingLang('modalConfirm.deleteService.title'),
      msg: bookingLang('modalConfirm.deleteService.message'),
      submit: () => handleRemoveService(id, service_variant_id, index),
    });
  };

  const isServiceBusy = (service: IService) => {
    return !!busyEmployees?.find(o => {
      const isSameServiceId = o?.service_id?.toString() === service?.id?.toString();
      const isSameEmployee = o?.employee_id?.toString() === service?.employee_id?.toString();
      if (o?.service_variant_id === null || o?.service_variant_id === undefined) {
        return isSameServiceId && isSameEmployee;
      }
      // @ts-ignore
      const isSameServiceVariant = o?.service_variant_id?.toString() === service?.service_variant_id?.toString();
      return isSameServiceId && isSameServiceVariant && isSameEmployee;
    });
  };

  return (
    <ServiceDetailsStyled>
      <div className='heading'>
        <div>
          <div className='title'>Service Details</div>
          <BookingDate 
            bookingDate={moment(currentDateBooking).format()}  
            isEdit={bookingData?.book_status !== EBookingStatus.IN_PROGRESS}
            setIsOpenModal={setIsOpenModal}
          />
        </div>
      </div>
      {listServices.length > 0 ? (
        <div>
          <div className='list-detail-items common-scrollbar'>
            {listServices?.map((service, index) => {
              return (
                <DetailItem
                  service={service}
                  key={index}
                  isEdit={isEdit}
                  isBusy={isServiceBusy(service)}
                  isInProgress={isInProgress}
                  onOpenConfirmModal={onOpenConfirmModal}
                  handleEditService={handleEditService}
                  handleEditQuantityService={handleEditQuantityService}
                  modalConfirmRef={modalConfirmRef}
                />
              );
            })}
          </div>
          {busyEmployees?.length > 0 && <SvgInfoStyled>
            <div className='icon'><SvgInfo /></div>
            <p className='msg'>ATTENTION: That team member is busy at that time</p>
          </SvgInfoStyled>}
        </div>
      ) : (
        <div className='empty-service'>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No selected'} />
        </div>
      )}
      {isOpenModal && <ModalEditBookingTime
        visible={isOpenModal}
        onCancel={handleModalCancel}
        date={currentDateBooking.format(TIME_START_FORMAT)}
        onSubmit={handleSubmit}
      />}
      <ModalConfirm ref={modalConfirmRef} usePinVerify={isInProgress} />
    </ServiceDetailsStyled>
  );
};

export default ServiceDetails;

const SvgInfo = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.3535 21.6797C17.8764 21.6797 22.3535 17.2025 22.3535 11.6797C22.3535 6.15684 17.8764 1.67969 12.3535 1.67969C6.83067 1.67969 2.35352 6.15684 2.35352 11.6797C2.35352 17.2025 6.83067 21.6797 12.3535 21.6797Z" stroke="#FA8C16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.3535 15.6797V11.6797" stroke="#FA8C16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.3535 7.67969H12.3635" stroke="#FA8C16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

type IModalEditBookingTimeProps = {
  visible?: boolean;
  onCancel?: () => void;
  onSubmit?: (dateFormat: string) => void;
  date?: string;
}

export const ModalEditBookingTime = ({
  visible = false,
  onCancel = () => undefined,
  onSubmit = () => undefined,
  date = '',
}: IModalEditBookingTimeProps) => {
  const { t: formLang } = useTranslation('form');
  const activeLocation = bookingSelectors.quickBooking.getMerchantLocationActive();

  const [form] = Form.useForm();

  const currentTimeDate = useMemo(() => {
    const _date = moment(date, TIME_START_FORMAT).format(TIME_START_FORMAT_RESPONSE);
    return dayjs(_date, 'YYYY-MM-DD HH:mm:ss', TIME_START_FORMAT_RESPONSE);
  }, [date]);

  useEffect(() => {
  
    form.setFieldValue('bookingTime', currentTimeDate);
  }, [date]);

  const _onSubmit = (e: {
    bookingDate: Dayjs,
    bookingTime: Dayjs,
  }) => {
    const bookingDate = moment(e.bookingDate.format());
    const bookingTime = moment(e.bookingTime.format());

    bookingDate.set({
      hour: bookingTime.get('hour'),
      minute: bookingTime.get('minute'),
      second: bookingTime.get('second')
    });
    onSubmit(bookingDate.format());
  };

  const date_closed = useMemo(() => {
    return activeLocation?.date_closed?.map(o => ({
      start: dayjs(o.start_date, 'YY-MM-DD'),
      end: dayjs(o.end_date, 'YY-MM-DD'),
    }));
  }, [activeLocation?.date_closed]);

  const [_bookingTime, setBookingTime] = useState<Dayjs | null>(currentTimeDate);

  useEffect(() => {
    if (!_bookingTime) return;
    form.setFieldValue('bookingTime', _bookingTime.tz());
  }, [_bookingTime]);

  const onPickDate = (d: Dayjs) => {
    const isDateClosed = !!date_closed?.some(o => d.format('YY-MM-DD') == o.start.format('YY-MM-DD') || d.isBetween(o.start, o.end));
    if (isDateClosed) return true;

    return !activeLocation?.time_opening?.some(o => o?.weekday === d?.format('dddd')?.toLowerCase());
  };

  const _submit = async () => {
    try {
      await form.validateFields();
      form.submit();
    } catch (error) {
    }
  };

  if (!visible) return null;

  return (
    <Modal
      maskClosable={false}
      open
      onCancel={onCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <h2 className='modal-title'>
        <p>Choose booking date</p>
      </h2>
      <Form
        name='basic'
        className='modal-form'
        onFinish={_onSubmit}
        autoComplete='off'
        form={form}
        layout='vertical'
        initialValues={{
          bookingDate: currentTimeDate,
          bookingTime: currentTimeDate,
        }}
      >
        <ModalStyled>
          <Form.Item name='bookingDate' label='Booking Date'
            rules={[
              {
                validator(_, value) {
                  const isValid = onPickDate(value);
                  if (isValid) {
                    return Promise.reject(new Error('You cannot select a date that is not within open time hours'));
                  }
                  return Promise.resolve();
                },
              }
            ]}
          >
            <DatePicker
              placeholder={formLang('SelectBookingDatePlaceholder') || ''}
              clearIcon={false}
              disabledDate={d => {

                if (!d || d.isBefore(dayjs(new Date()).subtract(1, 'days'))) return true;
                return isDisableDateByClosedPeriod(d, date_closed ?? []);

              }}
            // onChange={d => {
            // if (!d) return;
            // const timeOpening = getTimeOpening(d?.format(), activeLocation);
            // const isBetween = moment(_bookingTime?.format()).isBetween(timeOpening?.timeStart, timeOpening?.timeEnd);
            // if (!isBetween) {
            //   setBookingTime(dayjs(timeOpening?.timeStart.format()));
            // }
            // }}
            ></DatePicker>
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {() => {
              // const _bookingDate = form.getFieldValue('bookingDate').format();
              // const _date = moment(_bookingDate);
              // const today = moment();
              // const isNow = _date.isSame(today, 'day');

              // const timeOpening = getTimeOpening(_bookingDate, activeLocation);
              // const _timeStart = timeOpening?.timeStart.get('hour') || workingHour.open;
              // const _timeEnd = timeOpening?.timeEnd.get('hour') || workingHour.close;

              return <Form.Item name='bookingTime' label='Booking Time'>
                <TimePicker
                  format={'HH:mm'}
                  allowClear={false}
                  className='is-time-picker'
                  minuteStep={5}
                  showNow={false}
                  // disabledHours={() => getDisabledHours(_timeStart, _timeEnd, isNow)}
                  // disabledMinutes={isNow ? getDisabledMinutes : undefined}
                  onSelect={setBookingTime}
                  dropdownClassName='time-picker-custom'
                />
              </Form.Item>;
            }}
          </Form.Item>
        </ModalStyled>

        <div className='form-row form-submit-wrapper'>
          <button className='common-btn is-white' onClick={onCancel}>
            Cancel
          </button>
          <Button
            className='common-btn'
            htmlType='button'
            onClick={_submit}
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>

  );
};
const ModalStyled = styled.div`
  .ant-picker-status-error {
    background-color: var(--color-white-01) !important;
  }
`;
const ServiceDetailsStyled = styled.div`
  .heading {
    display: flex;
    gap: 24px;
    align-items: flex-start;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    color: var(--color-primary);
  }

  .btn-delete-all {
    display: inline-flex;
    align-items: center;
    padding: 4px 12px;
    min-width: 88px;
    height: 32px;
    background: #f0f2f7;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    color: var(--color-primary);
    margin-left: auto;
  }

  .empty-service {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #363565;
  }

  .list-detail-items {
    margin: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-height: 267px;
    max-height: 267px;
    overflow: auto;
    padding-right: 12px;
  }
`;
const SvgInfoStyled = styled.div`
  padding: 0;
  align-items: center;
  background: #FFF7E6;
  border-radius: 8px;
  display:flex;

  .icon {
    display: flex;
    padding-left: 10px;
  }

  .msg {
    font-size: 12px;
    padding: 10px;
  }
`;