import message from 'components/common/Message';
import apisInvoice from 'features/invoices/services/apis';
import { EPaymentMethod } from 'features/sales/services/constants';
import { get } from 'lodash';


export const useHandleSubmit = async (values: any, invoiceNo: string, method?: EPaymentMethod.CARD | EPaymentMethod.CARD) => {
  
    try {
      const res = await apisInvoice.refundInvoice(String(invoiceNo?.replaceAll('#', '') ?? ''), { 
        amount_refund: values.amount,
        note: values.note,
        refund_method: method ?? EPaymentMethod.CASH
       });
      const msg = get(res, 'data.data.message', '');
      if (msg) {
        message.success(msg);
      } else {
        throw 'fail';
      }

    } catch (error) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    }
};