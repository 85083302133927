import { Col, Empty, Row } from 'antd';
import { useMemo } from 'react';
import customerSelectors from '../services/selectors';
import BookingCard from './BookingCard';
import styled from 'styled-components';
import useSendCustomerInvoice from 'hooks/useSendCustomerInvoice';

const AppointmentsTab = () => {
  const detail = customerSelectors.getCustomerDetail();
  const appointments = customerSelectors.getAppointmentsCustomerDetail();

  const cusInfo = useMemo(() => ({
    customerCode: detail?.customer_code ?? '',
    name: detail?.name ?? '',
  }), [detail]);

  const _sendCustomerInvoice = useSendCustomerInvoice();
  

  const handleSendInvoice = (invoiceId: string) => {
    _sendCustomerInvoice({ invoiceId, email: detail?.email?.toString() ?? '', customerCode: detail?.customer_code?.toString() ?? '' });
  };

  return (
    <AppointmentStyled>
      <div className="cards-col">
        <div className="common-card">
          <h2 className="card-title">{'Sales'}</h2>
          {/* <div className="card-body"> */}
          {/* <ul className={styles.appointments_list}> */}
          <div>
            
          </div>
          <Row className='block-body common-scrollbar'>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>

                {appointments.length > 0 ?
                  <Row className="gutter-row" gutter={24}>
                    {appointments.map(ap => (
                      <Col className='gutter-col' key={ap.id} span={12} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                        <BookingCard
                          key={ap.id}
                          data={ap}
                          cusInfo={cusInfo}
                          onSendInvoice={handleSendInvoice}
                        />
                      </Col>
                    ))}
                  </Row>
                  : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No appointment'} />
                }
            </Col>
          </Row>

          {/* </ul> */}
          {/* </div> */}
        </div>
      </div>
    </AppointmentStyled>
  );
};

export default AppointmentsTab;

const AppointmentStyled = styled.div`
  .block-body {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: auto;
    .gutter-row {
      /* width: 80%; */
      /* margin: auto; */
      /* gap: 12px; */
      @media only screen and (max-width: 1312.98px){
        display: flex;
        justify-content: center;

      }

      .gutter-col {
        min-width: 500px;
        margin-bottom: 24px;
        @media only screen and (max-width: 767.98px){
          min-width: auto;
          margin: auto;
        }
      }
    }
  }
`;
