import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { createAction } from '@reduxjs/toolkit';
import { IApiUpdateCustomerBody, ICustomerParam } from './types/cutomer';

const getListCustomers = createAsyncAction<ICustomerParam | undefined>(PREFIX_ACTIONS + 'GET_LIST_CUSTOMERS');

const getListCustomersPaginate = createAsyncAction<ICustomerParam | undefined>(PREFIX_ACTIONS + 'GET_LIST_CUSTOMERS_PAGINATE');

const setCustomerCodeDetail = createAction<any>(PREFIX_ACTIONS + 'SET_CUSTOMER_CODE_DETAIL');

const deleteCustomer = createAsyncAction<string>(PREFIX_ACTIONS + 'DELETE_CUSTOMER');

const createCustomer = createAsyncAction<string>(PREFIX_ACTIONS + 'CREATE_CUSTOMER');

const getCustomerDetail = createAsyncAction<string>(PREFIX_ACTIONS + 'GET_CUSTOMER_DETAIL');
const getCustomerDetailAppointment = createAsyncAction<any>(PREFIX_ACTIONS + 'GET_CUSTOMER_DETAIL_APPOINTMENT');
const updateCustomerInfo = createAsyncAction<{ id: string, data: IApiUpdateCustomerBody }>(PREFIX_ACTIONS + 'UPDATE_CUSTOMER_INFO');

const setCustomerListParams = createAction<ICustomerParam>(PREFIX_ACTIONS + 'SET_CUSTOMER_LIST_PARAMS');

const customerActions = {
  getListCustomers,
  getListCustomersPaginate,
  getCustomerDetail,
  getCustomerDetailAppointment,
  setCustomerCodeDetail,
  updateCustomerInfo,
  deleteCustomer,
  createCustomer,
  setCustomerListParams,
};

export default customerActions;
