import workingHourSelectors from 'features/workingHour/services/selectors';
import { uniqueId } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import DaysViewTable from './DaysViewTable';
import { Empty, Spin } from 'antd';

type IListViewProps = {};
const ListView: React.FC<IListViewProps> = () => {
  const loading = workingHourSelectors.getStaffListLoading();
  const listDetail = workingHourSelectors.getStaffList();
  const teamMembers = workingHourSelectors.getLstTeamMembers();

  

  const mockData = listDetail.map((o) => ({
    ...o,     
    id: o.id,
    name: o.full_name,
    total_worked: o?.total_worked,
    days: o.timing.map(date => ({
      id: uniqueId(),
      name: o.full_name,
      date: date.date,
      duration: date?.total_worked_date,
      time: date.timing.map(time => ({
        start: time.time_checkin,
        end: time.time_checkout
      }))  
    })),
  }));  
  
  return (
    <ListViewStyled>
        <Spin spinning={teamMembers.length === 0 || loading}>
        {
          mockData.length > 0 ? mockData.map((o, key) => (
            <DaysViewTable data={o} key={key}/>
          )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No Working hour'} />
        }
      </Spin>
    </ListViewStyled>
  );
};

export default ListView;
const ListViewStyled = styled.div`
  display: flex;
   flex-direction: column;
   gap: 24px;
`;

