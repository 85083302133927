import useDoRedeem from 'features/checkout/components/PromotionCode/useDoRedeem';
// import { EPaymentMethod } from '../services/constants';
import salesSelectors from '../services/selectors';
import { IBookAssignmentServiceItem, ICommonBodyApiCreateSale, IVoucherSalesItem } from '../services/types/checkout';
import { useAppDispatch } from 'store/hooks';
import salesActions from '../services/actions';
import { IFuncPromotionAccept } from 'features/checkout/services/types/promotion';
import apisPromotion from 'features/promotions/services/apis';
import message from 'components/common/Message';
import { TIME_START_FORMAT_RESPONSE } from 'features/bookings/services/constants';
import moment from 'moment';
import settingSelectors from 'features/settings/services/selectors';

const useSalesPayment = () => {
  const dispatch = useAppDispatch();
  const customerInfo = salesSelectors.getCustomer();
  const merchant_location_id = salesSelectors.getMerchantLocationId();
  // const voucherUsed = salesSelectors.getSelectedVoucherUsed();
  const selectedServices = salesSelectors.getSelectedServices();
  const selectedVouchers = salesSelectors.getSelectedVouchers();
  const selectedReward = salesSelectors.getSelectedReward();
  // const curMixPayments = salesSelectors.getMixPayments();
  const promotionInfo = salesSelectors.getPromotionInfo();
  const softPromotionCode = salesSelectors.getSoftPromotionCode();
  const onPromotionAccept: IFuncPromotionAccept = (val) => dispatch(salesActions.setPromotionInfo(val));
  const amountDiscount = salesSelectors.getDiscountFeeValue();
  const holiday = settingSelectors.getHolidayInfoToday();
  const extraFee = salesSelectors.getExtraFee();
  const discount = salesSelectors.getDiscountFee();

  const bookingSetting = settingSelectors.getSetting()?.booking;
  const cardSurchargeValue = bookingSetting?.value_card_surchange;
  const isCardSurcharge = bookingSetting?.is_card_surchange;

  const cardValue = salesSelectors.getPurchaseCurrentCard();

  const doSoftRedeem = useDoRedeem(merchant_location_id || '', {
    servicesSelected: selectedServices,
    setErrorBoundary: (val) => dispatch(salesActions.setPromotionErrorBoundary(val)),
  });

  const getPaymentInfo = () => {
    const customer: ICommonBodyApiCreateSale['customer'] = {
      customer_code: customerInfo?.customer_code ?? '',
      is_walkin_in: !!customerInfo?.is_walkin_in,
    };

    const book_assignment_services = selectedServices.map(o => ({
      employee_id: o.employee?.id,
      quantity: o.quantity,
      service_id: o.id,
      service_variant_id: o?.service_variant_id ?? null,
      time_start: moment(o.time_start).format(TIME_START_FORMAT_RESPONSE),
    } as IBookAssignmentServiceItem
    ));

    const voucher_sales = selectedVouchers.map(o => ({
      quantity: o.quantity,
      voucher_id: o.id,
    } as IVoucherSalesItem
    ));

    const result: ICommonBodyApiCreateSale = {
      customer,
      merchant_location_id,
      // voucher_code: voucherUsed?.voucher_code || curMixPayments.find(o => o.type === EPaymentMethod.VOUCHER)?.data?.voucher_code,
      book_assignment_services,
      voucher_sales,
      loyalty_reward_id: selectedReward?.id,
      promotion_code: promotionInfo?.code || null,
      amount_discount: amountDiscount || undefined,
      discount_extra: discount?.value ? {
        discount_value: discount?.value  ?? undefined,
        discount_type: discount?.type === 'price' ? 'amount' : 'percent' ?? undefined,
        discount_name: discount?.name ?? '',
      }: undefined,
      extra_fee: extraFee?.value ? {
        name: extraFee?.name ?? '',
        fee_type: extraFee?.type === 'price' ? 'amount' : 'percent' ?? undefined,
        fee_value: extraFee?.value ?? 0,
      } : undefined,
      holiday_surcharge_id: selectedServices.length > 0 ? holiday?.id : undefined,
      card_surcharge_percent: isCardSurcharge && cardValue > 0 ? cardSurchargeValue : undefined
    };

    return result;
  };

  const getPaymentInfoAsync = async () => {

    if (!promotionInfo && softPromotionCode) {
      try {
        await apisPromotion.checkPromotionOfflineValid(softPromotionCode, merchant_location_id ?? '');
    } catch (error: any) {
        message.error('Promotion not invalid');
        return;

      }
      onPromotionAccept(await doSoftRedeem(softPromotionCode));
      return null;
    }

    return getPaymentInfo();
  };

  return ({
    getPaymentInfo, getPaymentInfoAsync,
  });
};

export default useSalesPayment;
