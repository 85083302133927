import IconRoundedClose from 'assets/svg/IconRoundedClose';
import { IVoucherDataItem } from 'features/checkout/services/types/voucher';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { formatMoney } from 'utils/unit';
type Props = {
  data: IVoucherDataItem;
  onChoose?: () => void;
  active?: boolean;
  onRemove?: () => void;
  isWrap?: boolean;
};
const PaymentVoucherPurchasedItem = ({ data, onChoose = () => undefined, active, isWrap, onRemove = () => undefined }: Props) => {
  const { name, voucher_code, expiry_date, value_remaining } = data;
  return (
    <Styled onClick={!active ? onChoose : () => undefined} className={active ? 'active' : ''} style={isWrap ? { width: '100%' } : {}}>
      {active && <button className='button-remove' onClick={e => {
        e.stopPropagation();
        onRemove();
      }}><IconRoundedClose />
      </button>}
      <p className="name">{name}</p>
      <p className='info_code'>{voucher_code} - {expiry_date ? 'Exp ' + moment(expiry_date).format('DD.MM.YYYY') : null}</p>
      <p className="price">{formatMoney(value_remaining)}</p>
    </Styled>
  );
};

export default PaymentVoucherPurchasedItem;

const Styled = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 24px;
  gap: 12px;

  background: #FFFFFF;
  position: relative;
  .button-remove {
    position: absolute;
    top:0;
    right:0;
    transform: translate(10px, -10px);
  }
  &:hover {
    cursor: default;
    
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    background: #F0F2F6;
  }
  &:not(.active):hover {
    cursor: pointer;
  }

  &.active {
    background: #F8F8FF;
    border: 1px solid #60669F;
  }

  border: 1px solid #C3C2E0;
  border-radius: 6px;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    color: #363565;

    &.name {
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      text-align: center;
    }
    &.price {
      font-weight: 600;
      font-size: 20px;
      line-height: 140%;
    }
  }
`;