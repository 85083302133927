import {  Form, Input, Select, TimePicker } from 'antd';
import DatePicker from 'components/common/DatePickerInput';
import FormRow from 'components/common/Form/FormRow';
import { Dayjs } from 'dayjs';
import { getDisabledHours, getDisabledMinutes } from 'features/bookings/hooks/disableTime';
import getTimeOpening from 'features/bookings/hooks/getTimeOpening';
import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
import { IMerchantLocationItemResData } from 'features/bookings/services/types/booking';
import settingSelectors from 'features/settings/services/selectors';
import { first } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import dayjs from 'utils/dayjs';
import { formatTimeMinutes, getTimeRound5Minute } from 'utils/unit';
const { TextArea } = Input;
const BookingDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const form = Form.useFormInstance();
  const locationsStore = bookingSelectors.bookingOnline.getLocations();
  const workingHour = bookingSelectors.getWorkingHour();
  const setting = settingSelectors.getSettingBookingForm();
  const [isFirst, setIsFirst] = useState(true);
  


  // useEffect(() => {
  //   dispatch(settingActions.getSetting.fetch());
  // }, []);
  const activeLocation = bookingSelectors.bookingOnline.getActiveLocation();
  const setActiveLocation = (val: IMerchantLocationItemResData | null) => {
    dispatch(bookingActions.setBookingOnlineActiveLocation(val));
  };

  const { t: formLang } = useTranslation('form');

  const locations = useMemo(() => {
    return locationsStore.map(item => ({
      value: item.id,
      label: item.name,
    }));
  }, [locationsStore]);

  useEffect(() => {
    
    if (locationsStore.length > 0) {
      const defaultItem = first(locationsStore);
      form.setFieldValue('location', defaultItem?.id);
      setActiveLocation(defaultItem ?? null);
    }
  }, [locationsStore]);

  const { closeWorkingTime, openWorkingTime } = useMemo(() => {
    const closeWorkingTime = moment().set({
      'hour': workingHour.close,
      'minute': 0,
      'second': 0,
    });
    const openWorkingTime = moment().set({
      'hour': workingHour.open,
      'minute': 0,
      'second': 0,
    });
    return ({
      closeWorkingTime,
      openWorkingTime
    });
  }, [workingHour]);  

  const bookingDate = useMemo(() => {
    
    let bookingTime = moment().add(5, 'minute');
    let _bookingDate = getTimeRound5Minute(moment());
    
    if (bookingTime > closeWorkingTime) {
      bookingTime = openWorkingTime;
      _bookingDate = _bookingDate.clone().add(1, 'days');
    } else if (bookingTime < openWorkingTime) {
      bookingTime = openWorkingTime;
    }    

    const bookingDateAvailable = _bookingDate;
    form.setFieldValue('bookingTime' , dayjs(bookingDateAvailable.format()).tz());    
    return bookingDateAvailable;
  }, [workingHour, setting]);

  const date_closed: any = useMemo(() => {
    return activeLocation?.date_closed.map(o => ({
      start: dayjs(o.start_date, 'YY-MM-DD'),
      end: dayjs(o.end_date, 'YY-MM-DD'),
    }));
  }, [activeLocation?.date_closed]);  

  const isDisableDate = (d: Dayjs) => {
    
    if (!d || d.isSameOrBefore(dayjs(bookingDate.format()).tz().subtract(1, 'days'))) return true;

    const isDateClosed = !!date_closed?.some((o: any) => d.format('YY-MM-DD') == o.start.format('YY-MM-DD') || d.isBetween(o.start, o.end.add(1, 'day')));
    if (isDateClosed) return true;

    return !activeLocation?.time_opening.some(o => o?.weekday === d?.format('dddd')?.toLowerCase());
  };

  const [_bookingTime, setBookingTime] = useState<Dayjs | null>(null);
  useEffect(() => {
    if (!_bookingTime) return;

    form.setFields([
      {
        name: 'bookingTime',
        errors: []
      }
    ]);
    setIsFirst(false);

    form.setFieldValue('bookingTime', dayjs.tz(_bookingTime));
  }, [_bookingTime]);

  const checkBookingTimeValid = () => {
    const date = form.getFieldValue('bookingDate') as Dayjs;
    const time = form.getFieldValue('bookingTime') as Dayjs;    

    if(!time || !date) return;
    
    const times = time.set('date', date.date()).set('month', date.month()).set('year', date.year());
    
    
    if(moment(times.format()).isBefore(moment().add(setting?.cancel_reschedule ?? 0, 'minutes')) && !isFirst) {
      form.setFields([
        {
          name: 'bookingTime',
          errors: [`Sorry for inconvenience. We will serve better if have a booking before ${formatTimeMinutes(setting?.cancel_reschedule ?? 0)}`]
        }
      ]);
      return;
    }

    form.setFields([
      {
        name: 'bookingTime',
        errors: []
      }
    ]);
  };

  useEffect(() => {
    checkBookingTimeValid();
  }, [form.getFieldValue('bookingTime'), form.getFieldValue('bookingDate')]);

  useEffect(() => {
    form.setFields([
      {
        name: 'bookingTime',
        errors: []
      }
    ]);

  },[]);

  return (
    <BookingDetailsStyled>
      <div className='row'>
        <FormRow containerClassName='form_item' label="Booking date" name="bookingDate"
          rules={[
            { required: true, message: 'Please select your booking date' },
            {
              validator(rule, value, callback) {
                if (isDisableDate(value)) callback('Booking date invalid with current location');
                else callback(undefined);
              },
            }
          ]}
        >
          <DatePicker
            clearIcon={false}
            showToday={false}
            className='picker-date'
            name='bookingDate'
            disabledDate={isDisableDate}
            onChange={(d) => {
              if (!d) return;

              const timeOpening = getTimeOpening(d?.format(), activeLocation);
              const isBetween = moment(_bookingTime?.format()).isBetween(timeOpening?.timeStart, timeOpening?.timeEnd);
              
              if (!isBetween) {
                setBookingTime(dayjs(timeOpening?.timeStart.format()));
              }
              checkBookingTimeValid();
            }}
          />
        </FormRow>
        <Form.Item shouldUpdate>
          {() => {
            const _date = form.getFieldValue('bookingDate')?.format();
            const today = moment();
            const isNow = moment(_date).isSame(today, 'day');

            const timeOpening = getTimeOpening(_date, activeLocation);
            const _timeStart = timeOpening?.timeStart.get('hour') || workingHour.open;
            const _timeEnd = timeOpening?.timeEnd.get('hour') || workingHour.close;

            return (
              <FormRow containerClassName='form_item' label="Booking Time"
              >
                <Form.Item name="bookingTime" rules={[
                  { required: true, message: 'Please select your booking time' }
                ]}>
                  <TimePicker
                    placeholder='__:__' format={'HH:mm'}
                    inputReadOnly
                    minuteStep={5}
                    hourStep={1}
                    clearIcon={false}
                    showNow={isNow}
                    className='is-time-picker'
                    disabledHours={() => getDisabledHours(_timeStart, _timeEnd, isNow)}
                    disabledMinutes={isNow ? getDisabledMinutes : undefined}
                    onSelect={setBookingTime}
                    dropdownClassName='time-picker-custom'
                  /></Form.Item>
              </FormRow>
            );
          }}
        </Form.Item>

      </div>
      {locations.length !== 1 ? <FormRow label="Location" name="location">
        <Select
          placeholder={formLang('SelectLocationLabel')}
          options={locations}
          onChange={id => {
            const _activeLocation = locationsStore.find(o => o.id.toString() === id?.toString());
            setActiveLocation(_activeLocation ?? null);
            form.validateFields(['bookingDate']);
          }}
        />
      </FormRow> : <Form.Item name={'location'} initialValue={locations[0]} /> }
      <FormRow label={'Appointment note'} name={'note'} optional>
        <TextArea rows={6} placeholder={'Type some notes for our store'} />
      </FormRow>
    </BookingDetailsStyled>
  );
};

export default BookingDetails;

const BookingDetailsStyled = styled.div`
  .row {
    display: flex;
    justify-content: space-between;
    margin: 0 -6px;
    @media only screen and (max-width: 767.98px) {
      display: block;
    }
    .form_item {
      flex:1;
      margin: 0 6px;
    }
  }
  .ant-picker-status-error {
    background-color: var(--color-white-01) !important;
  }
`;