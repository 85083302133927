import { createAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IApiDataWorkingHourParams, IApiDetailWorkingHourParams } from './types/workingHour';

const getData = createAsyncAction<IApiDataWorkingHourParams>(PREFIX_ACTIONS + 'getData');

const setParams = createAction<IApiDataWorkingHourParams>(PREFIX_ACTIONS + 'setParams');

const getListLocations = createAsyncAction(PREFIX_ACTIONS + 'getListLocations');

const getListTeamMembers = createAsyncAction(PREFIX_ACTIONS + 'getListTeamMembers');

const initData = createAction(PREFIX_ACTIONS + 'initData');

const detail = {
  setParams: createAction<IApiDetailWorkingHourParams>(PREFIX_ACTIONS + 'detail' + 'setParams'),
  getData: createAsyncAction<IApiDetailWorkingHourParams>(PREFIX_ACTIONS + 'detail' + 'getData'),
  setTypeView: createAction<string>(PREFIX_ACTIONS + 'detail' + 'setTypeView'),
};

const workingHourActions = {
  getData,
  setParams,
  initData,
  getListLocations,
  getListTeamMembers,
  detail,
};

export default workingHourActions;
