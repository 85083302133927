import { IconRemove } from 'features/checkout/components/FeeButton';
import salesHook from 'features/sales/hooks/salesHook';
import salesActions from 'features/sales/services/actions';
import { EPaymentMethod } from 'features/sales/services/constants';
import salesSelectors from 'features/sales/services/selectors';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import { formatMoney } from 'utils/unit';

const CardResultRow = ({viewOnly}: {viewOnly: boolean}) => {

  const card = salesSelectors.getPurchaseCurrentCard() ?? 0;
  const cash = salesSelectors.getPurchaseCurrentCash() ?? 0;
  const [method] = salesHook.usePaymentMethod();
  const dispatch = useAppDispatch();

  const onRemove = () => {
    dispatch(salesActions.setPurchaseCard(0));
    if(cash > 0) {
      dispatch(salesActions.setPaymentMethod(EPaymentMethod.CASH));
    }
  };

  return (
    <div>

      {method === EPaymentMethod.CARD && card > 0 && 
        <p className='extra-price-row'>
          <div className='label'>
          {!viewOnly &&
            <button type='button' onClick={onRemove}><IconRemove /></button>}
            Card
          </div>
      
          <span> {formatMoney(card)}</span>
        </p>}
    </div>
  );
};

export default CardResultRow;