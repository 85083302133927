
interface FormBlockProps {
    children?: any;
    label?: string;
}

const FormBlock = ({
    children,
    label = ''
}: FormBlockProps) => {
    return (
        <div className='form-block'>
            <div className='form-row form-title'>
              <p className='form-label is-big'>{label}</p>
            </div>
            {children}
        </div>
    );
};

export default FormBlock;