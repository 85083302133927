import checkoutSelectors from 'features/checkout/services/selectors';
import settingSelectors from 'features/settings/services/selectors';

export const useSurcharge = () => {

  const holiday = settingSelectors.getHolidayInfoToday();
  const bookingSetting = settingSelectors.getSetting()?.booking;
  const cardSurchargeValue = bookingSetting?.value_card_surchange;
  const isCardSurcharge = bookingSetting?.is_card_surchange;
  const cardValue = checkoutSelectors.getPurchaseCurrentCard();
  const selectedServices = checkoutSelectors.getSelectedServices();

  return ({
    holiday_surcharge_id: selectedServices.length > 0 ? holiday?.id : undefined,
    card_surcharge_percent: isCardSurcharge && cardValue > 0 ? cardSurchargeValue : undefined
  });
};