import { Form, Input, Modal, Select } from 'antd';
import IconDecrease from 'assets/svg/IconDecrease';
import IconIncrease from 'assets/svg/IconIncrease';
import dayjs from 'utils/dayjs';
// import { getDisabledHours, getDisabledMinutes } from 'features/bookings/hooks/disableTime';
// import getTimeOpening from 'features/bookings/hooks/getTimeOpening';
import { BREAK_TIME_MINUTES, TIME_START_FORMAT } from 'features/bookings/services/constants';
import bookingSelectors from 'features/bookings/services/selectors';
import { IServiceQuickBookingItem } from 'features/bookings/services/types/quickBooking';
import { findIndex, first, get, last, uniqBy } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// import formatNumber from 'utils/formatNumber';
import { ANONYMOUS_NAME, formatMoney } from 'utils/unit';
import VerifyPin from 'widgets/VerifyPin';
import { IServiceItem } from 'features/bookings/services/types/service';
import { useParams } from 'react-router-dom';


type Props = {
  isOpenModal: boolean;
  handleCancel: any;
  dataService: IServiceItem | null;
  handleAddServices?: any;
  currServices?: IServiceItem[];
};

const NewServicesModal = (props: Props) => {
  const {
    isOpenModal,
    handleCancel = () => undefined,
    dataService,
    handleAddServices = () => undefined,
    currServices = [],
  } = props;
  const [quantity, setQuantity] = useState<number>(1);
  const [form] = Form.useForm();
  const formInstance = Form.useFormInstance();
  
  const bookingDate = bookingSelectors.quickBooking.getBookingDate();
  // const workingHour = bookingSelectors.getWorkingHour();
  const { t: formLang } = useTranslation('form');  

  const activeLocation = bookingSelectors.quickBooking.getMerchantLocationActive();
  const params = useParams();
  const merchant_location_id = bookingSelectors.quickBooking.getMerchantLocationId() ?? params.location_id ?? formInstance.getFieldValue('location');
  const [visibleVerifyPin, setVisibleVerifyPin] = useState(false);
  const actionSubmitRef = useRef<() => void>(() => undefined);

  const { isEdit, currentService } = useMemo(() => {
    const currentIdx = findIndex(currServices, (o) => o?.id === dataService?.id);
    return {
      isEdit: currentIdx !== -1,
      isFirstItem: currentIdx === 0,
      currentIdx,
      currentService: get(currServices, [currentIdx]) as (IServiceQuickBookingItem | undefined),
    };
  }, [currServices, dataService]);

  const memberOpts = useMemo(() => {

    const result = [{
      value: '',
      label: ANONYMOUS_NAME
    }].concat(dataService?.merchant_employees?.filter(o => {
      if (o.merchant_location_id?.toString() !== merchant_location_id?.toString()) return false;
      return o.is_receive_book === 1;

    }).map((employe: any) => {
      const { full_name, id } = employe;
      return {
        value: id,
        label: full_name,
      };
    }) ?? []);    
    
    return uniqBy(result, o => o.value);
  }, [dataService, activeLocation]);

  // const currentTotalService = useMemo(() => {
  //   const price = (dataService?.sale_price ?? 0) || (dataService?.regular_price ?? 0) || (dataService?.service_price ?? 0);
  //   return +(price * quantity).toFixed(2);
  // }, [dataService, quantity]);


  const handleDecrease = () => {
    if (quantity === 1) {
      return;
    }
    setQuantity(quantity - 1);
  };

  const handleIncrease = () => {
    setQuantity(quantity + 1);
  };

  useEffect(() => {    
    form.resetFields();
    setQuantity(1);
    form.setFieldValue('quantity', 1);
  }, [dataService]);

  useEffect(() => {
    form.setFieldValue('quantity', quantity);
  }, [quantity]);  

  const getTimeStart = () => {
    let timePicker;
    if (!isEdit) {
      if (currServices.length === 0) {
        timePicker = dayjs(bookingDate);
      } else {
        const lastItem = last(currServices);
        const breakTimeMinutes = BREAK_TIME_MINUTES;
        const prevServiceMinutes = (lastItem?.duration_time ?? 0) * (lastItem?.quantity ?? 0);
        const nextTimeStart = moment(lastItem?.time_start, TIME_START_FORMAT).add(breakTimeMinutes + prevServiceMinutes, 'minute');
        timePicker = dayjs(nextTimeStart.format());
      }
    } else {
      const curTimeStart = moment(currentService?.time_start, TIME_START_FORMAT);
      timePicker = dayjs(curTimeStart.format());
    }
    return timePicker;
  };
  const getSelectedMember = () => {
    if (!isEdit) {
      return get(first(memberOpts), 'value');
    }
    return dataService?.employee_id || dataService?.assigned_employee?.employee?.id;

  };


  useEffect(() => {    
    form.setFieldsValue({
      duration: dataService?.duration_time,
      time_start: getTimeStart(),
      members: getSelectedMember(),
    });
  }, [dataService, isOpenModal, currServices]);  

  // const handleFinish = (valueForm: any) => {
    
  // };

  // const { disabledHours, disabledMinutes } = useMemo(() => {
  //   const _date = moment(bookingDate);
  //   const today = moment();
  //   const isNow = _date.isSame(today, 'day');

  //   const timeOpening = getTimeOpening(bookingDate, activeLocation);
  //   const _timeStart = timeOpening?.timeStart.get('hour') || workingHour.open;
  //   const _timeEnd = timeOpening?.timeEnd.get('hour') || workingHour.close;

  //   const disabledHours = () => getDisabledHours(_timeStart, _timeEnd, isNow);
  //   const disabledMinutes = isNow ? getDisabledMinutes : undefined;

  //   return { disabledHours, disabledMinutes };
  // }, [bookingDate, activeLocation]);  
  
  return (
    <>
      <Modal
        maskClosable={false}
        open={isOpenModal}
        onCancel={(e) => handleCancel(e)}
        okButtonProps={{ style: { display: 'none' } }}
        width={574}
        footer={null}
        forceRender
        centered
      >
        <NewServicesModalStyled>
          <h2 className='modal-title'>
            <p>Service details</p>
          </h2>
          <Form
            name='basic'
            form={form}
            className='modal-form'
            autoComplete='off'
            onFinish={handleAddServices}
            layout='vertical'
          >
            <div className='form-label'>{dataService?.name}</div>
            <div>{dataService?.description}</div>
            <div className='form-row'></div>
            <div className='form-price'>
              <span className='price-value'>
                {formatMoney((dataService?.sale_price !== 0 ?  dataService?.sale_price : dataService?.regular_price) ?? 0)}
              </span>
              <div className='quantity-control'>
                <Form.Item name='quantity' className='hidden'>
                  <Input defaultValue={1} disabled={true} value={quantity}></Input>
                </Form.Item>
                <span
                  onClick={handleDecrease}
                  className={`control-btn ${quantity === 1 && 'is-disabled'}`}>
                  <IconDecrease />
                </span>
                <span className='quantity-value'>{quantity}</span>
                <span onClick={handleIncrease} className='control-btn'>
                  <IconIncrease />
                </span>
              </div>
            </div>
            <Form.Item
              label='Team member'
              name='members'
              rules={[{ required: false, message: 'Member is required' }]}
            >
              <Select placeholder={formLang('SelectTeamMemberPlaceholder') || ''} defaultValue={''} options={memberOpts} />
            </Form.Item>
            <Form.Item name={'time_start'}></Form.Item>
            <Form.Item name={'duration'}></Form.Item>
            <div className='form-row form-submit-wrapper'>
              <button className='common-btn is-white' onClick={handleCancel}>
                Cancel
              </button>
              <button className='common-btn' type='submit'>
                Save
              </button>
            </div>
          </Form>
        </NewServicesModalStyled>
      </Modal>
      <VerifyPin visible={visibleVerifyPin} onCancel={() => setVisibleVerifyPin(false)} onSubmit={actionSubmitRef.current} />
    </>
  );
};

export default NewServicesModal;

const NewServicesModalStyled = styled.div`
  @media (max-width: 767.98px) {
    .form-label {
      margin-top: 24px;
    }
  }
`;
