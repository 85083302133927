const IconCustomerCircle = () => {
  return (
    <svg
      width='40'
      height='41'
      viewBox='0 0 40 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect y='0.5' width='40' height='40' rx='20' fill='#F0F2F7' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 24.5H25C27.7614 24.5 30 26.7386 30 29.5C30 30.0523 29.5523 30.5 29 30.5C28.4872 30.5 28.0645 30.114 28.0067 29.6166L27.9949 29.3237C27.907 27.8072 26.6928 26.593 25.1763 26.5051L25 26.5H15C13.3431 26.5 12 27.8431 12 29.5C12 30.0523 11.5523 30.5 11 30.5C10.4477 30.5 10 30.0523 10 29.5C10 26.8112 12.1223 24.6182 14.7831 24.5046L15 24.5H25H15ZM20 10.5C23.3137 10.5 26 13.1863 26 16.5C26 19.8137 23.3137 22.5 20 22.5C16.6863 22.5 14 19.8137 14 16.5C14 13.1863 16.6863 10.5 20 10.5ZM20 12.5C17.7909 12.5 16 14.2909 16 16.5C16 18.7091 17.7909 20.5 20 20.5C22.2091 20.5 24 18.7091 24 16.5C24 14.2909 22.2091 12.5 20 12.5Z'
        fill='#363565'
      />
    </svg>
  );
};

export default IconCustomerCircle;
