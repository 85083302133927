import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import uiSelector from 'services/UI/selectors';
import { PATH_LOADING } from './constants';
import { ILocationDetailRes } from './types/location';


type MyState = RootState['location'];

const getCurrentState = (state: RootState): MyState => state.location;

const selector = (key: keyof MyState, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getPaginateListLocations = () => selector('listPaginateLocation') as MyState['listPaginateLocation'];

const loadingLocationsPaginateList = () => uiSelector.getLoading(PATH_LOADING.getListPaginateLocations);

const getLocationDetail = () => selector('getLocationDetail') as ILocationDetailRes;

const loadingLocationsDetail = () => uiSelector.getLoading(PATH_LOADING.getLocationDetail);

const getLocationDetailPublic = () => selector('getLocationDetailPublic') as ILocationDetailRes;

const loadingLocationsDetailPublic = () => uiSelector.getLoading(PATH_LOADING.getLocationDetailPublic);


const locationSelectors = {
  getPaginateListLocations,
  loadingLocationsPaginateList,
  getLocationDetail,
  loadingLocationsDetail,
  getLocationDetailPublic,
  loadingLocationsDetailPublic
};

export default locationSelectors;
