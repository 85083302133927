import { DatePicker, Form, Input, InputNumber } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { disabledDateSameOrBefore } from 'utils/unit';

type Props = {
  errorsText?: Record<string, string>;
};
const PromotionsFormFirstStep = ({ errorsText }: Props) => {
  const { t: formLang } = useTranslation('form');
  const form = Form.useFormInstance();
  const [price, setPrice] = useState(form.getFieldValue('amountValue'));
  const [percent, setPercent] = useState(form.getFieldValue('percentValue'));

  useEffect(() => {
    setPrice(form.getFieldValue('amountValue'));
    setPercent(form.getFieldValue('percentValue'));
  }, [form]);

  const myFocus = useRef<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);

  return (
    <>
      <div className='form-row'>
        <Form.Item
          label={formLang('promotion.promotionMame')}
          name='name'
          validateStatus={errorsText?.name ? 'error' : undefined}
          help={errorsText?.name ? errorsText?.name : undefined}
          rules={[{ required: true }]}
        >
          <Input
            ref={myFocus}
            placeholder={formLang('EnterPromotionNamePlaceholder') || ''}
          />
        </Form.Item>
      </div>

      <Form.Item
        dependencies={['promotionType']} // if using dependencies
        noStyle
      >
        {({ getFieldValue }: any) => {
          // getFieldValue defined here
          return getFieldValue('promotionType') === 'code' ? (
            <div className='form-row'>
              <Form.Item
                label={formLang('promotion.promotionCode')}
                name='code'
                validateStatus={errorsText?.code ? 'error' : undefined}
                help={errorsText?.code ? errorsText?.code : undefined}
                rules={[
                  { required: true },
                  {
                    validator: (_, value) => {
                      if (value && value.length !== 8) {
                        return Promise.reject(
                          'Please enter a string with a length of 8 characters.'
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder={formLang('EnterPromotionCodePlaceholder') || ''}
                />
              </Form.Item>
            </div>
          ) : null;
        }}
      </Form.Item>

      <div className='form-row groupPrice items-end'>
        <Form.Item label={formLang('promotion.moneyValue')} name='amountValue'>
          <InputNumber
            addonBefore='$'
            placeholder='Price'
            onChange={(val: any) => {
              setPrice(val);
              setPercent(null);
              form.setFieldValue('percentValue', null);
            }}
          />
        </Form.Item>
        <p className='center-text'>or</p>
        <Form.Item
          label={formLang('promotion.percentValue')}
          name='percentValue'
        >
          <InputNumber
            addonBefore='%'
            placeholder={'Percent'}
            max={100}
            onChange={(val: any) => {
              setPercent(val);
              setPrice(null);
              form.setFieldValue('amountValue', null);
            }}
            min={0.01}
          />
        </Form.Item>
      </div>

      <Form.Item
        className='messageError'
        name={'priceOrPercent'}
        rules={[
          {
            validateTrigger: 'onChange',
            validator() {
              const priceValue = price ?? form.getFieldValue('amountValue');
              const percentValue =
                percent ?? form.getFieldValue('percentValue');

              if (
                (!priceValue && !percentValue) ||
                (!!priceValue && !!percentValue)
              ) {
                return Promise.reject(
                  new Error('Please fill in value price or percent a field')
                );
              } else if (
                percentValue &&
                (percentValue < 0.01 || percentValue > 100)
              ) {
                return Promise.reject(
                  new Error(
                    'Percent value need to be greater than 0.01 and less than 100'
                  )
                );
              }
              return Promise.resolve();
            },
          },
        ]}
        validateStatus={errorsText?.value ? 'error' : undefined}
        help={errorsText?.value ? errorsText?.value : undefined}
        dependencies={['amountValue', 'percentValue']}
      />

      <Form.Item
        dependencies={['promotionType']}
        noStyle
      >
        {({ getFieldValue }: any) => {
          // getFieldValue defined here
          return getFieldValue('promotionType') === 'auto_offer' ? (
            <div className='form-row'>
              <Form.Item
                label={formLang('promotion.startDate')}
                name='startDate'
                rules={[
                  { required: true, message: 'Please select start date' },
                  {
                    validator: (_, value) => {
                      const currentDate = moment();

                      if (value && value.isBefore(currentDate, 'day')) {
                        return Promise.reject(
                          'Start date must be greater than or equal to the current date'
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current: Dayjs) =>
                    disabledDateSameOrBefore(current, dayjs())
                  }
                  format={'DD.MM.YYYY'}
                  picker='date'
                  className='promotion-start-date'
                />
              </Form.Item>
              <Form.Item
                label={
                  <p>
                    {formLang('promotion.endDate')}
                    <span>(Optional)</span>
                  </p>
                }
                name='endDate'
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const startDate = getFieldValue('startDate');

                      if (value && startDate && value.isBefore(startDate)) {
                        return Promise.reject(
                          'End date must be greater than or equal to the start date'
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker
                  disabledDate={(current: Dayjs) =>
                    disabledDateSameOrBefore(current, dayjs())
                  }
                  format={'DD.MM.YYYY'}
                  picker='date'
                  className='promotion-end-date'
                />
              </Form.Item>
            </div>
          ) : null;
        }}
      </Form.Item>
    </>
  );
};

export default PromotionsFormFirstStep;
