import IconCash from 'assets/svg/IconCash';
import { EPaymentMethod } from './types/checkout';
import IconVouchers from 'assets/svg/IconVouchers';
import IconCard from 'assets/svg/IconCard';

export const NAME_REDUCER = 'checkout';

export const PREFIX_ACTIONS = 'CHECKOUT_FEATURE_';


export const PATH_LOADING = {
  getBookingDetail: `loading.${NAME_REDUCER}.getBookingDetail`,
  getServiceConfigs: `loading.${NAME_REDUCER}.getServiceConfigs`,
  updateCusInfo: `loading.${NAME_REDUCER}.updateCusInfo`,
  checkout: `loading.${NAME_REDUCER}.checkout`,
  getRewardOfCustomer: `loading.${NAME_REDUCER}.getRewardOfCustomer`,
  getVoucherOfCustomer: `loading.${NAME_REDUCER}.getVoucherOfCustomer`,
};

export const EErrorMethodKeys = {
  cash: {
    amount: 'amount',
  },
  voucher: 'voucher',
  mix: 'mix'
};


export const ICONS = {
  [EPaymentMethod.VOUCHER]: <IconVouchers />,
  [EPaymentMethod.CASH]: <IconCash />,
  [EPaymentMethod.CARD]: <IconCard />

};
export const MIX_PAYMENT_METHODS = [
  {
    icon: ICONS[EPaymentMethod.CASH],
    label: 'Cash',
    value: EPaymentMethod.CASH,
  },
  {
    icon: ICONS[EPaymentMethod.VOUCHER],
    label: 'Voucher',
    value: EPaymentMethod.VOUCHER,
  },
  {
    icon: ICONS[EPaymentMethod.CARD],
    label: 'Card',
    value: EPaymentMethod.CARD,
  },
];