import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { IApiDataWorkingHourParams, IApiDetailWorkingHourParams } from './types/workingHour';
import uiSelector from 'services/UI/selectors';
import { PATH_LOADING } from './constants';

type MyState = RootState['workingHour'];

const getCurrentState = (state: RootState): MyState => state.workingHour;

const selector = <Modal = keyof MyState>(key: Modal, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key as any, defaultValue));

export const getPureParams = (root: RootState) => {
  const state = getCurrentState(root);
  return state.params ?? {} as IApiDataWorkingHourParams;
};

export const getPureDetailParams = (root: RootState) => {
  const state = getCurrentState(root);
  return state.detail.params ?? {} as IApiDetailWorkingHourParams;
};

const getParams = () => selector('params') as IApiDataWorkingHourParams;

const getParamValue = (key: keyof IApiDataWorkingHourParams) => selector(`params.${key}`) as IApiDataWorkingHourParams[typeof key];

const getStaffList = () => selector('staffList') as MyState['staffList'];

const getStaffListLoading = () => uiSelector.getLoading(PATH_LOADING.getData) as boolean;

const getPagination = () => selector('pagination') as MyState['pagination'];

const getTotalTopAnalytic = () => selector('totalTopAnalytic') as MyState['totalTopAnalytic'];

const getLocations = () => selector('locations') as MyState['locations'];

const getLstTeamMembers = () => selector('lstTeamMembers') as MyState['lstTeamMembers'];

const detail = {
  getParams: () => selector('detail.params') as (IApiDetailWorkingHourParams | null),
  getParamValue: (key: keyof IApiDetailWorkingHourParams) => selector(`detail.params.${key}`) as IApiDetailWorkingHourParams[typeof key],
  getTypeView: () => selector('detail.typeView') as string,
  getLoading: () => uiSelector.getLoading(PATH_LOADING.getDetailData) as boolean,
};

const workingHourSelectors = {
  getParams,
  getStaffList,
  getPagination,
  getTotalTopAnalytic,
  getStaffListLoading,
  getLocations,
  getParamValue,
  getLstTeamMembers,
  detail,
};

export default workingHourSelectors;
