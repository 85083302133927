import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IApiProgramParams } from './types/program';
import { IApiRewardParams } from './types/reward';
import { createAction } from '@reduxjs/toolkit';

const getLoyalConfigs = createAction(PREFIX_ACTIONS + 'GET_LOYAL_CONFIGS');

const getListLocations = createAsyncAction(PREFIX_ACTIONS + 'GET_LIST_LOCATIONS');

const getListServices = createAsyncAction(PREFIX_ACTIONS + 'GET_LIST_SERVICES');

const getListLoyalProgram = createAsyncAction<IApiProgramParams>(PREFIX_ACTIONS + 'GET_LIST_LOYAL_PROGRAM');

const getListLoyalReward = createAsyncAction<IApiRewardParams>(PREFIX_ACTIONS + 'GET_LIST_LOYAL_REWARD');

const getLoyalSetting = createAsyncAction(PREFIX_ACTIONS + 'GET_LOYAL_SETTING');

const loyalActions = {
  getLoyalConfigs,
  getListLocations,
  getListServices,
  getListLoyalProgram,
  getListLoyalReward,
  getLoyalSetting,
};

export default loyalActions;