import React, { useState } from 'react';
import styled from 'styled-components';
import ModalFee, { IModalFeeSubmitFunc } from './Modal';
import { feeType } from 'features/checkout/services/types/fee';
import { getAddLabel } from './hook';
export type IAddFeeProps = {
  onAdd?: IModalFeeSubmitFunc;
  type?: feeType;
};

const AddFee: React.FC<IAddFeeProps> = ({ onAdd = () => undefined, type = 'extra' }) => {
  const [visible, setVisible] = useState(false);
  const handleClose = () => setVisible(false);
  const handleOpen = () => setVisible(true);
  const handleSubmit: IModalFeeSubmitFunc = (values) => {
    onAdd(values);
    handleClose();
  };

  return (
    <>
      <AddFeeStyled type='button' onClick={handleOpen}>
        <IconPlus />
        <span>{getAddLabel(type)}</span>
      </AddFeeStyled>
      {visible && <ModalFee type={type} visible onCancel={handleClose} onSubmit={handleSubmit} />}
    </>
  );
};

export default AddFee;
const AddFeeStyled = styled.button`
  display: flex;
  align-items: center;
  gap: 2px;
  span {
    color: var(--green-1, #4A9D77);
    font-variant-numeric: slashed-zero;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }
  &:hover {
    background:var(--color-overlay);
  }
`;

export const IconPlus = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon/Plus">
      <path id="Vector" d="M8.5 3.33203V12.6654" stroke="#4A9D77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Vector_2" d="M3.8335 8H13.1668" stroke="#4A9D77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>

);