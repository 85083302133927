import bookingActions from 'features/bookings/services/actions';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import StaffFilter from '../components/StaffFilter';
import StaffTable from '../components/StaffTable';
import actions from '../services/actions';

const UserPage = () => {
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(bookingActions.quickBooking.getDataConfigs.fetch());
    dispatch(actions.setParams({
      page: Number(searchParams.get('page') ?? 1),
      per_page: Number(searchParams.get('per_page') ?? 10),
      keyword: searchParams.get('keyword') ?? undefined,
    }));
    dispatch(actions.getShowCreateStaffs.fetch());
  }, []);

  return (
    <>
      <StaffFilter />
      <StaffTable />
    </>
  );
};

export default UserPage;
