import { Form, Input, Modal } from 'antd';
import FormRow from 'components/common/Form/FormRow';

type ModalFormProps = {
    visible?: boolean | undefined;
    onCancel?: (() => void) | undefined;
    onsubmit: () => any;
    errors?: any;
}

const ModalPasswordConfirm = ({ visible, onCancel = () => undefined, onsubmit = () => undefined }: ModalFormProps) => {
    return (
        <>
            <Modal
                open={visible}
                onCancel={onCancel}
                maskClosable={false}
                okButtonProps={{ style: { display: 'none' } }}
                width={574}
                footer={null}
                forceRender
                centered
                >
      <h2 className='modal-title'>Confirm Password</h2>
            <Form
            name='basic'
            className='modal-form'
            onFinish={onsubmit}
            autoComplete='off'
            layout='vertical'
            >
            <FormRow label='Password' name='Enter your login password'>
                <Input.Password />
            </FormRow>
            </Form>
        </Modal>
        </>
    );
};

export default ModalPasswordConfirm;