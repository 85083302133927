import { IconRemove } from 'features/checkout/components/FeeButton';
import salesActions from 'features/sales/services/actions';
import salesSelectors from 'features/sales/services/selectors';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import { formatMoney } from 'utils/unit';

const VoucherResultRow = ({viewOnly}: {viewOnly: boolean}) => {
  
  const result = salesSelectors.getVoucherValue();
  const totalOriginal = salesSelectors.getOriginTotalPrice();
  const dispatch = useAppDispatch();
  
  
  const onRemove = () => {
    dispatch(salesActions.setSelectedVoucherUsed(null));
  };
  
  if (!result) return null;
  
  return (
    <div className='extra-price-row'>
      <div className='label'>
        {!viewOnly &&
        <button type='button' onClick={onRemove}><IconRemove /></button>}
        Voucher
      </div>

      <span>- {formatMoney(totalOriginal <= result ? totalOriginal : result ?? 0)}</span>
    </div>
  );
};

export default VoucherResultRow;
