import message from 'components/common/Message';
import salesActions from 'features/sales/services/actions';
import { EErrorMethodKeys, EPaymentMethod } from 'features/sales/services/constants';
import salesSelectors from 'features/sales/services/selectors';
import { get } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import { IPurchaseProps } from './type';
import apisSales from 'features/sales/services/apis';
import { IBodyApiCreateSaleByCash } from 'features/sales/services/types/checkout';
import useSalesPayment from 'features/sales/hooks/useSalesPayment';
import { useDispatch } from 'react-redux';
import { roundNumber } from 'utils/unit';

export const useVoucherErrors = (): [string, (val: string) => void] => {
  const dispatch = useAppDispatch();
  const errors = salesSelectors.getPurchaseMethodErrors(EPaymentMethod.VOUCHER);
  const errorText = useMemo(() => get(errors, EErrorMethodKeys.voucher, ''), [errors]);
  const setErrorValid = (msg: string) => {
    dispatch(salesActions.setPurchaseMethodErrors({
      method: EPaymentMethod.VOUCHER,
      errors: {
        [EErrorMethodKeys.voucher]: msg,
      }
    }));
  };
  return [errorText, setErrorValid];
};

const Voucher = ({ onSuccess, isActive }: IPurchaseProps) => {
  const { t: checkoutLang } = useTranslation('checkout');

  const setLoadingPage = useSetLoadingPage();
  const selected = salesSelectors.getSelectedVoucherUsed();
  const originTotalPrice = salesSelectors.getOriginTotalPrice() ?? 0;
  const voucherValue = salesSelectors.getVoucherValue();
  const dispatch  = useDispatch();

  const salesPayment = useSalesPayment();
  const [, setErrorValid] = useVoucherErrors();

  const onPurchase = async () => {

    setErrorValid('');
    if (!selected?.voucher_code) {
      setErrorValid(checkoutLang('validate.voucher.empty'));
      return;
    }
    const amount = originTotalPrice - roundNumber(voucherValue);    

    if (amount > 0) {
      setErrorValid(checkoutLang('validate.voucher.invalid'));
      return;
    }

    setLoadingPage(true);
    try {
      const paymentInfo = await salesPayment.getPaymentInfoAsync();
      if (!paymentInfo) return;

      const payload: IBodyApiCreateSaleByCash = {
        ...paymentInfo,
        amount_given: selected?.value,
        voucher_code: selected?.voucher_code
      };
      const res = await apisSales.apiPaymentSale.byVoucher(payload);
      const invoiceResult = get(res, 'data.data.notify_invoice', '');
      dispatch(salesActions.setInvoiceResult(invoiceResult));
      const msg = get(res, 'data.data.message', '');
      if (msg) {
        message.success(msg);
        onSuccess();
      } else {
        const error = get(res, 'data.error.message', '');
        if (error) {
          message.error(error);
        } else
          throw 'fail';
      }
    } catch (error) {
      message.error('An error occurred. Please try again');
    } finally {
      setLoadingPage(false);
    }
  };
  return (
    <button
      type='button'
      className='form-booking-submit'
      onClick={onPurchase}
      disabled={isActive}
    >
      Check-out
    </button>
  );
};

export default Voucher;
