import moment from 'moment';

export type IDaysOfWeekItem = {
  id: string;
  value: string;
};

const getArrayDatesOfWeek = (date?: string) => {
  const _date = date || undefined;
  const startOfWeek = moment(_date).startOf('week').add(1, 'd'); // Monday of currentWeek
  const endOfWeek = moment(_date).endOf('week').add(1, 'd'); // Sunday of currentWeek

  const days: IDaysOfWeekItem[] = [];
  let day = startOfWeek;

  while (day <= endOfWeek) {
    days.push({ value: day.format(), id: day.format('DD/MM/YYYY') });
    day = day.clone().add(1, 'd');
  }
  return days;
};

export default getArrayDatesOfWeek;