const IconFacebook = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Facebook">
                <path id="Path" d="M20 10C20 4.47658 15.5234 0 10 0C4.47658 0 0 4.47658 0 10C0 14.9922 3.65625 19.1289 8.4375 19.8789V12.8906H5.89842V10H8.4375V7.79688C8.4375 5.291 9.92971 3.90625 12.2148 3.90625C13.3086 3.90625 14.4531 4.10156 14.4531 4.10156V6.5625H13.1914C11.9492 6.5625 11.5625 7.334 11.5625 8.125V10H14.3359L13.8926 12.8906H11.5625V19.8789C16.3438 19.1289 20 14.9922 20 10Z" fill="#1877F2" />
                <path id="Path_2" d="M13.8926 12.8906L14.3359 10H11.5625V8.125C11.5625 7.334 11.9492 6.5625 13.1914 6.5625H14.4531V4.10156C14.4531 4.10156 13.3086 3.90625 12.2149 3.90625C9.92973 3.90625 8.43752 5.291 8.43752 7.79687V10H5.89844V12.8906H8.43752V19.8789C8.94727 19.959 9.46877 20 10 20C10.5313 20 11.0528 19.959 11.5625 19.8789V12.8906H13.8926Z" fill="white" />
            </g>
        </svg>
    );
};

export default IconFacebook;
