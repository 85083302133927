import { Spin, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import ProfileTab from '../components/ProfileTab';
import PasswordTab from '../components/PasswordTab';
// import NotificationsTab from '../components/NotificationsTab';

import userSelectors from '../../users/services/selectors';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import userActions from 'features/users/services/actions';

import settingActions from 'features/settings/services/actions';
import settingSelectors from 'features/settings/services/selectors';



const EditProfilePage = () => {
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.getProfileStaffDetail.fetch());
    dispatch(settingActions.getSetting.fetch());
  }, []);

  const detail = userSelectors.getProfileStaffDetail();
  const getSetting = settingSelectors.getSetting();
  
  const loading = userSelectors.loadingProfileStaffDetail(); 


  
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Profile',
      children: <ProfileTab detail={detail} getSetting={getSetting} />,
    },
    {
      key: '2',
      label: 'Password',
      children: <PasswordTab />,
    },
    // {
    //   key: '3',
    //   label: 'Notifications',
    //   children: <NotificationsTab/>,
    // },
  ];

  return (
    <>
      <Spin spinning={loading}>
        <div className='profile-page'>
          <Tabs defaultActiveKey='1' items={items} />
        </div>
      </Spin>
    </>
  );
};

export default EditProfilePage;
