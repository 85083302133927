import checkoutActions from 'features/checkout/services/actions';
import checkoutSelectors from 'features/checkout/services/selectors';
import { EPaymentMethod, IMixPaymentItem } from 'features/checkout/services/types/checkout';
import { findIndex } from 'lodash';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { formatMoney } from 'utils/unit';

const ResultAmountRow = ({} : {step: number}) => {
  const totalOriginal = checkoutSelectors.getOriginTotalPrice();
  const voucherSelectedValue = checkoutSelectors.getVoucherValue();
  const method = checkoutSelectors.getPaymentMethod();

  const currMixVoucher = checkoutSelectors.getMixPayments().find(o => o.type === EPaymentMethod.VOUCHER);
  const curMixPayments = checkoutSelectors.getMixPayments();
  const dispatch = useDispatch();

  const getTotal = useMemo(() => {    
    const voucherValue = voucherSelectedValue;
    const idx = findIndex(curMixPayments, o => o.type === EPaymentMethod.CASH);
    const data: IMixPaymentItem = {
      data: totalOriginal - voucherValue,
      type: EPaymentMethod.CASH,
      value: totalOriginal - voucherValue ?? 0,
    };
    if(idx) {
      const newMixPayments = curMixPayments.map(o => {
        if (o.type === EPaymentMethod.CASH) {
          return ({
            ...o,
            ...data,
          });
        }
        return o;
      });
      dispatch(checkoutActions.setMixPayments(newMixPayments));
    }
    return totalOriginal - voucherValue;
  }, [method, totalOriginal, voucherSelectedValue, currMixVoucher]);


  return (
    <>
      <p className='total'>
        TOTAL
        <span>{formatMoney(getTotal > 0 ? getTotal : 0)}</span>
      </p>
      <div className='form-note'>*All prices include GST</div>
    </>
  );
};

export default ResultAmountRow;
