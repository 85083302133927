
const purchasedVouchers = [
    {
        'id': 46,
        'voucher_code': 'VWBQHWEU',
        'name': '123123',
        'value': 20,
        'retail_price': 1,
        'redeemed_value': 0,
        'expiry_date': '2023-08-04T00:00:00+07:00',
        'validity_period': 'years_3',
        'validity_period_label': '3 Year',
        'description': 'kha kha',
        'status': 'active',
        'services': [],
        'customer': null
    },
    {
        'id': 41,
        'voucher_code': 'VFKMSTVED',
        'name': 'Kha',
        'value': 120,
        'retail_price': 11,
        'redeemed_value': 0,
        'expiry_date': '2023-08-05T00:00:00+07:00',
        'validity_period': 'years_3',
        'validity_period_label': '3 Year',
        'description': 'ád',
        'status': 'active',
        'services': [],
        'customer': null
    },
];

export default purchasedVouchers;
