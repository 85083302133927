import { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import PromotionsTable from '../components/PromotionsTable';
import promotionActions from '../services/actions';
import { useSearchParams } from 'react-router-dom';
import PromotionFilter from '../widgets/Filter';

const PromotionsPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    dispatch(promotionActions.init());
    dispatch(promotionActions.setListPromotionParams({
      page: Number(searchParams.get('page') ?? 1),
      per_page: Number(searchParams.get('per_page') ?? 10),
      keyword: searchParams.get('keyword') ?? undefined,
      // type: searchParams.get('type') ?? PROMOTION_TYPES.PERCENT,
    }));
  }, []);

  return (
    <>
      <PromotionFilter />
      <PromotionsTable />
    </>
  );
};

export default PromotionsPage;
