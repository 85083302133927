import RewardAdd from '../widgets/Reward/Add';
import RewardsTable from './RewardsTable';

const RewardsTab = () => {
  return (
    <>
      <div className='cards-wrapper'>
        <div className='cards-col'>
          <div className='common-card'>
            <div className='card-heading'>
              <div className='card-title is-small'>
                <div>
                  <p>Earn Points</p>
                  <span>All ways that your customers can earn points.</span>
                </div>
                <RewardAdd />
              </div>
            </div>
            <RewardsTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardsTab;
