import { Tabs } from 'antd';
import ProgramsTab from '../components/ProgramsTab';
import RewardsTab from '../components/RewardsTab';
// import SettingsTab from '../components/SettingsTab';
import { useAppDispatch } from 'store/hooks';
import { useEffect } from 'react';
import loyalActions from '../services/actions';

const LoyaltyProgramPage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loyalActions.getLoyalConfigs());
  }, []);

  const items = [
    {
      key: '1',
      label: 'Programs',
      children: <ProgramsTab />,
    },
    {
      key: '2',
      label: 'Rewards',
      children: <RewardsTab />,
    },
    // {
    //   key: '3',
    //   label: 'Settings',
    //   children: <SettingsTab />,
    // },
  ];

  return (
    <div>
      <Tabs defaultActiveKey='1' items={items} />
    </div>
  );
};

export default LoyaltyProgramPage;
