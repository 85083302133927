import { Divider, Popover, Tooltip } from 'antd';
import { IconDash } from 'features/bookings/components/BookingCalendar/Table/Icons';
import { useState } from 'react';
import styled from 'styled-components';

interface Props {
    quantity: number;
    handleEditService: () => void;
    onOpenConfirmModal: () => void;
    handleSplitService?: () => void;
    isSliptService?: boolean
 
}

const PopoverEdit = ({
    quantity,
    handleEditService,
    onOpenConfirmModal,
    handleSplitService = () => undefined,
    isSliptService = true,
}: Props) => {

    const [open, setOpen] = useState(false);

    const onClickButton = (e: any) => {
      e.stopPropagation();
      setOpen(! open);
    };

    const onClose = () => {
      setOpen(false);

    };

    return (
        <>
        <Popover
          arrow={false}
          trigger='click'
          placement='bottomRight'
          open={open}
          onOpenChange={setOpen}
          content={
            <ActionsStyled>
              <ul className='more-settings'>
                <li className='is-edit' onClick={() => {handleEditService(); onClose();}}>Edit</li>
                {quantity > 1 && isSliptService ? <li className='is-split' onClick={() => {
                  handleSplitService(); 
                  onClose();}} style={{justifyContent: 'space-between'}}>
                    Split service
                    <Tooltip 
                        placement="left" 
                        title={'Split up a service to assign another team member'}>
                        <span>
                            <IconHelper/>
                        </span> 
                    </Tooltip>
                </li> : <></>}
                <Divider style={{
                    borderWidth: 'revert',
                    margin: '4px 0px'
                }}/>
                <li className='is-cancel' onClick={(e: any) => {e.stopPropagation(); onOpenConfirmModal(); onClose();}}>Delete</li>
                
              </ul>
            </ActionsStyled >
          }
        >
          <button type='button' onClick={onClickButton}>
            <IconDash />
          </button>
        </Popover >
  
      </>
    );
};

export default PopoverEdit;


const ActionsStyled = styled.div`
  .more-settings {
    display: block;
    position: static;
    transform: none;
    padding: 0;
    box-shadow: none;
  }
  button {
    border: none;
    background: transparent;
    display: block;
  }
`;

const IconHelper = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: '4px'}}>
            <g id="Icon/help-circle" clipPath="url(#clip0_7309_179430)">
            <path id="Vector" d="M9.99984 18.3334C14.6022 18.3334 18.3332 14.6025 18.3332 10.0001C18.3332 5.39771 14.6022 1.66675 9.99984 1.66675C5.39746 1.66675 1.6665 5.39771 1.6665 10.0001C1.6665 14.6025 5.39746 18.3334 9.99984 18.3334Z" stroke="#363565" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_2" d="M7.5752 7.49999C7.77112 6.94304 8.15782 6.47341 8.66682 6.17426C9.17583 5.87512 9.77427 5.76577 10.3562 5.86558C10.9381 5.96539 11.4659 6.26792 11.8461 6.71959C12.2263 7.17126 12.4344 7.74292 12.4335 8.33332C12.4335 9.99999 9.93353 10.8333 9.93353 10.8333" stroke="#363565" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_3" d="M10 14.1667H10.0083" stroke="#363565" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_7309_179430">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};
