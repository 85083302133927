import { message } from 'antd';
import { IMessageProps } from '../type';

const CustomSuccessMessage = (props: IMessageProps) => {
  const _text = typeof props === 'string' ? props : props?.text;
  return message.success(
    <p>
      <span>Success:</span>{_text}
    </p>
  );
};

export default CustomSuccessMessage;
