import { Dayjs } from 'dayjs';
import DateRangePicker from './DateRangePicker';
import OptionPicker from './OptionPicker';
import { useState } from 'react';
import { EDateOption } from 'features/workingHour/services/types/workingHour';

interface GroupFilterDateProps {
  onChangeOptionPicker: (values: string) => void
  onChangeDateRangePicker: (values: Dayjs[]) => void
  optionDate: {dateStart: number, dateEnd: number}
}

const GroupFilterDate = ({onChangeOptionPicker, onChangeDateRangePicker, optionDate}: GroupFilterDateProps) => {

  const [valueOption, setValueOption] = useState<string>();

  const _onChangeDateRangePicker = (values: Dayjs[]) => {
    onChangeDateRangePicker(values);
    setValueOption(EDateOption.CUSTOM);
  };

  const _onChangeOptionPicker = (values: string) => {
    onChangeOptionPicker(values);
    setValueOption(values);
  };

  return (
    <>
      <div className='common-select'>
        <OptionPicker onChange={_onChangeOptionPicker} value={valueOption} />
      </div>
      <div className='common-select'>
        <DateRangePicker onChange={_onChangeDateRangePicker} start_date={optionDate.dateStart} end_date={optionDate.dateEnd}/>
      </div>
    </>
  );
};

export default GroupFilterDate;