const IconCash = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2229_433402)'>
        <path
          d='M29.5 5H2.5C1.83696 5 1.20107 5.26339 0.732233 5.73223C0.263392 6.20107 0 6.83696 0 7.5L0 24.5C0 25.163 0.263392 25.7989 0.732233 26.2678C1.20107 26.7366 1.83696 27 2.5 27H29.5C30.163 27 30.7989 26.7366 31.2678 26.2678C31.7366 25.7989 32 25.163 32 24.5V7.5C32 6.83696 31.7366 6.20107 31.2678 5.73223C30.7989 5.26339 30.163 5 29.5 5ZM2.5 7.5H6.5C6.47219 7.99422 6.34602 8.47792 6.12889 8.92276C5.91177 9.36759 5.60804 9.76463 5.23551 10.0906C4.86299 10.4165 4.42915 10.6649 3.95943 10.821C3.4897 10.9772 2.99354 11.038 2.5 11V7.5ZM2.5 24.5V21C2.99354 20.962 3.4897 21.0228 3.95943 21.179C4.42915 21.3351 4.86299 21.5835 5.23551 21.9094C5.60804 22.2354 5.91177 22.6324 6.12889 23.0772C6.34602 23.5221 6.47219 24.0058 6.5 24.5H2.5ZM29.5 24.5H25.6C25.6568 23.5201 26.0986 22.6023 26.8291 21.9468C27.5597 21.2912 28.5197 20.9509 29.5 21V24.5ZM29.5 18.5C28.6857 18.4733 27.8741 18.6074 27.1117 18.8945C26.3492 19.1817 25.6508 19.6163 25.0564 20.1735C24.4621 20.7308 23.9833 21.3997 23.6476 22.1421C23.3119 22.8844 23.1258 23.6857 23.1 24.5H8.9C8.87416 23.6857 8.68806 22.8844 8.35236 22.1421C8.01666 21.3997 7.53794 20.7308 6.94356 20.1735C6.34917 19.6163 5.65079 19.1817 4.88833 18.8945C4.12588 18.6074 3.3143 18.4733 2.5 18.5V13.5C3.3143 13.5267 4.12588 13.3926 4.88833 13.1055C5.65079 12.8183 6.34917 12.3837 6.94356 11.8265C7.53794 11.2692 8.01666 10.6003 8.35236 9.85794C8.68806 9.11558 8.87416 8.31433 8.9 7.5H23.1C23.1258 8.31433 23.3119 9.11558 23.6476 9.85794C23.9833 10.6003 24.4621 11.2692 25.0564 11.8265C25.6508 12.3837 26.3492 12.8183 27.1117 13.1055C27.8741 13.3926 28.6857 13.5267 29.5 13.5V18.5ZM29.5 11C28.5197 11.0491 27.5597 10.7088 26.8291 10.0532C26.0986 9.39765 25.6568 8.47991 25.6 7.5H29.5V11Z'
          fill='#363565'
        />
        <path
          d='M15.9996 9.99942C15.1853 9.97273 14.3737 10.1068 13.6113 10.394C12.8488 10.6811 12.1504 11.1157 11.5561 11.673C10.9617 12.2302 10.4829 12.8991 10.1472 13.6415C9.81155 14.3838 9.62545 15.1851 9.59961 15.9994C9.62545 16.8138 9.81155 17.615 10.1472 18.3574C10.4829 19.0997 10.9617 19.7686 11.5561 20.3259C12.1504 20.8831 12.8488 21.3177 13.6113 21.6049C14.3737 21.892 15.1853 22.0261 15.9996 21.9994C16.8139 22.0261 17.6255 21.892 18.3879 21.6049C19.1504 21.3177 19.8488 20.8831 20.4432 20.3259C21.0375 19.7686 21.5163 19.0997 21.852 18.3574C22.1877 17.615 22.3738 16.8138 22.3996 15.9994C22.3738 15.1851 22.1877 14.3838 21.852 13.6415C21.5163 12.8991 21.0375 12.2302 20.4432 11.673C19.8488 11.1157 19.1504 10.6811 18.3879 10.394C17.6255 10.1068 16.8139 9.97273 15.9996 9.99942ZM15.9996 19.4994C15.0193 19.5486 14.0593 19.2083 13.3287 18.5527C12.5982 17.8971 12.1564 16.9793 12.0996 15.9994C12.1564 15.0195 12.5982 14.1018 13.3287 13.4462C14.0593 12.7906 15.0193 12.4503 15.9996 12.4994C16.4931 12.4614 16.9893 12.5222 17.459 12.6784C17.9288 12.8345 18.3626 13.0829 18.7351 13.4088C19.1076 13.7348 19.4114 14.1318 19.6285 14.5767C19.8456 15.0215 19.9718 15.5052 19.9996 15.9994C19.9718 16.4936 19.8456 16.9773 19.6285 17.4222C19.4114 17.867 19.1076 18.264 18.7351 18.59C18.3626 18.916 17.9288 19.1643 17.459 19.3205C16.9893 19.4766 16.4931 19.5375 15.9996 19.4994Z'
          fill='#363565'
        />
      </g>
      <defs>
        <clipPath id='clip0_2229_433402'>
          <rect width='32' height='32' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconCash;
