const IconMap = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0078 1.5C9.82054 1.50248 7.72356 2.37247 6.17692 3.91911C4.63029 5.46575 3.76029 7.56273 3.75781 9.75C3.75781 16.8094 11.2578 22.1437 11.5766 22.3687C11.7043 22.4538 11.8543 22.4992 12.0078 22.4992C12.1613 22.4992 12.3113 22.4538 12.4391 22.3687C12.7578 22.1437 20.2578 16.8094 20.2578 9.75C20.2553 7.56273 19.3853 5.46575 17.8387 3.91911C16.2921 2.37247 14.1951 1.50248 12.0078 1.5V1.5ZM12.0078 6.75C12.6012 6.75 13.1812 6.92595 13.6745 7.25559C14.1679 7.58524 14.5524 8.05377 14.7795 8.60195C15.0065 9.15013 15.0659 9.75333 14.9502 10.3353C14.8344 10.9172 14.5487 11.4518 14.1291 11.8713C13.7096 12.2909 13.175 12.5766 12.5931 12.6924C12.0111 12.8081 11.4079 12.7487 10.8598 12.5216C10.3116 12.2946 9.84305 11.9101 9.5134 11.4167C9.18376 10.9234 9.00781 10.3433 9.00781 9.75C9.00781 8.95435 9.32388 8.19129 9.88649 7.62868C10.4491 7.06607 11.2122 6.75 12.0078 6.75V6.75Z"
        fill="#363565"
      />
    </svg>
  );
};

export default IconMap;
