const IconCard = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M29.3337 7.9987V23.9987C29.3337 24.732 29.0728 25.36 28.551 25.8827C28.0283 26.4045 27.4003 26.6654 26.667 26.6654H5.33366C4.60033 26.6654 3.97277 26.4045 3.45099 25.8827C2.92833 25.36 2.66699 24.732 2.66699 23.9987V7.9987C2.66699 7.26536 2.92833 6.63781 3.45099 6.11603C3.97277 5.59336 4.60033 5.33203 5.33366 5.33203H26.667C27.4003 5.33203 28.0283 5.59336 28.551 6.11603C29.0728 6.63781 29.3337 7.26536 29.3337 7.9987ZM5.33366 10.6654H26.667V7.9987H5.33366V10.6654ZM5.33366 15.9987V23.9987H26.667V15.9987H5.33366Z'
        fill='#363565'
      />
    </svg>
  );
};

export default IconCard;
