import { Empty, Form, Input, Radio, Spin, Switch } from 'antd';
import DatePicker from 'components/common/DatePickerInput';
import FormRow from 'components/common/Form/FormRow';
import message from 'components/common/Message';
import { Dayjs } from 'dayjs';
import { get } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import styled from 'styled-components';
import { getFormatShortDate } from 'utils/moment/getSingleDateShortFormat';
import storage from 'utils/sessionStorage';
import styles from '../pages/index.module.scss';
import apisCustomer from '../services/apis';
import customerSelectors from '../services/selectors';
import { IApiUpdateCustomerBody } from '../services/types/cutomer';
import PhoneNumberInput from 'components/common/PhoneNumberInput';
import EditCustomer from '../widgets/EditCustomer';
import { formatMoney } from 'utils/unit';
import dayjs from 'utils/dayjs';

const { TextArea } = Input;

export interface IFormGeneralValue {
  name: string;
  phoneNumber: string;
  email: string;
  birthDate: Dayjs;
  gender: number | null;
  clientNotify: boolean;
  marketingNotify: boolean;
  importantClientInfo: string | null;
}
type Props = {
  fetchDetailData?: () => void
}
const GeneralTab = ({ fetchDetailData = () => undefined }: Props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const onChange = () => undefined;
  const { t: customerLang } = useTranslation('customer');
  const { t: commonLang } = useTranslation('common');

  const detail = customerSelectors.getCustomerDetail();
  const id = customerSelectors.getIdCustomerDetail();
  const isWalkin = customerSelectors.getCustomerIsWalkinIn();

  const setPageLoading = useSetLoadingPage();

  const loading = customerSelectors.loadingCustomerDetail();

  useEffect(() => {
    form.setFieldsValue({
      name: detail?.name,
      email: detail?.email,
      gender: detail?.gender,
      birthDate: detail?.date_of_birth ? dayjs(detail?.date_of_birth) : null,
      phoneNumber: detail?.phone_number,
      numberVisits: detail?.total_visited,
      cancellations: detail?.total_cancelled,
      noShows: detail?.total_no_show,
      loyaltyPoints: detail?.loyalty_point,
      amountSpent: detail?.amount_spent,
      clientNotify: !!detail?.notification_customer,
      marketingNotify: !!detail?.notify_market,
      importantClientInfo: detail?.important_client_info,
    });
  }, [detail]);

  if (loading) {
    return (
      <Spin />
    );
  }

  const checkValid = async () => {
    try {
      await form.validateFields([
        'name',
        'email',
        'phoneNumber',
      ]);
      return true;
    } catch (error) {
      return false;
    }
  };

  const _onCancel: React.MouseEventHandler<HTMLButtonElement> = () => {
    // form.resetFields();
    navigate(-1);
  };

  const _onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    const isFormValid = await checkValid();
    if (!isFormValid) return;
    form.submit();
  };

  const handleSubmit = async (values: IFormGeneralValue) => {
    const merchantId = storage.merchantId.get();
    if (!merchantId) return;
    const payload: IApiUpdateCustomerBody = {
      merchant_id: merchantId,
      email: values.email ?? '',
      name: values.name ?? '',
      gender: values.gender ?? null,
      date_of_birth: values.birthDate ? values.birthDate.format('YYYY-MM-DD') : null,
      notification_customer: values.clientNotify,
      notify_market: values.marketingNotify,
      phone_number: values.phoneNumber ?? '',
      important_client_info: values?.importantClientInfo ?? '',
    };

    setPageLoading(true);
    try {
      const res = await apisCustomer.updateCustomerInfo(String(id ?? ''), { ...payload, is_walkin_in: isWalkin });
      const msg = get(res, 'data.message', '');
      if (msg) {
        message.success(msg);
        fetchDetailData();
      } else {
        throw 'fail';
      }

    } catch (error) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setPageLoading(false);
    }

  };

  return (
    <GeneralTabStyled>
      <Form
        form={form}
        initialValues={{
          name: detail?.name,
          email: detail?.email ?? '',
          birthDate: detail?.date_of_birth ? dayjs(detail?.date_of_birth) : null,
          phoneNumber: detail?.phone_number ?? '',
          numberVisits: detail?.total_visited,
          cancellations: detail?.total_cancelled,
          importantClientInfo: detail?.important_client_info,
          noShows: detail?.total_no_show,
          loyaltyPoints: detail?.loyalty_point,
          amountSpent: detail?.amount_spent,
          clientNotify: !!detail?.notification_customer,
          marketingNotify: !!detail?.notify_market,
          gender: detail?.gender || 0,
        }}
        onKeyUp={async (e) => {
          if (e.key === 'Enter') {
            const isFormValid = await checkValid();
            if (!isFormValid) return;
            form.submit();
          }
        }}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <div className="cards-wrapper">
          <div className="cards-col">
            <div className="common-card">
              <h2 className="card-title">{customerLang('General.DetailsTitle')}</h2>
              <div className="card-body">
                <div className="form-row">
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      { required: true, message: 'Please input your name!' }
                    ]}
                  >
                    <Input autoFocus />
                  </Form.Item>
                </div>
                <div className="form-row items-end ">
                  {/* <Form.Item
                    label="Phone number"
                    name="phoneNumberCode"
                    className="is-small"
                  >
                    <Select
                      className="is-small"
                      options={[
                        {
                          value: '+92',
                          label: '+92',
                        },
                        {
                          value: '+94',
                          label: '+94',
                        },
                      ]}
                    />
                  </Form.Item> */}
                  <Form.Item label="Phone number" name="phoneNumber"
                    rules={detail?.phone_number ? [
                      {
                        required: true,
                        message: 'Please input your phone number!'
                      },
                    ] : []}
                  >
                    <PhoneNumberInput />
                  </Form.Item>
                </div>
                <div className="form-row">
                  <Form.Item label="Email" name="email"
                    rules={detail?.email ? [
                      { type: 'email', message: 'is not a valid email!' },
                    ] : []}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <FormRow label='Gender' name='gender' optional>
                  <Radio.Group>
                    <Radio value={0}>Female</Radio>
                    <Radio value={1}>Male</Radio>
                    <Radio value={2}>Non-binary</Radio>
                  </Radio.Group>
                </FormRow>
                <div className="form-row">
                  <Form.Item
                    name="birthDate"
                    label={
                      <p>
                        Birth Date <span>(Optional)</span>
                      </p>
                    }
                  >
                    <DatePicker onChange={onChange} clearIcon={false} />
                  </Form.Item>
                </div>
                <div className='form-row'>
                  <Form.Item
                    label={
                      <p>
                        Important client info
                        <span>(Optional)</span>
                      </p>
                    }
                    name='importantClientInfo'
                    extra={'This note will be displayed on all bookings. '}
                  >
                    <TextArea
                      rows={5}
                      placeholder={'Eg. customer allergic to fragrance'}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <StatisticsCardStyled className="common-card w-t-50">
              <div className='header'>
                <h2 className="card-title">{customerLang('General.Statistics.Title')}</h2>
                <EditCustomer fetchDetailData={fetchDetailData} />
              </div>
              <div className="card-body">
                <div className={styles.statistics_wrapper}>
                  <div className={styles.statistics_left}>
                    <ul>
                      <li>
                        <span>{detail?.total_visited ?? 0}</span>
                        <p>{customerLang('General.Statistics.TotalVisits')}</p>
                      </li>
                      <li>
                        <span>{detail?.total_completed ?? 0}</span>
                        <p>{customerLang('General.Statistics.Completed')}</p>
                      </li>
                      <li>
                        <span>{detail?.total_cancelled ?? 0}</span>
                        <p>{customerLang('General.Statistics.Cancelled')}</p>
                      </li>
                      <li>
                        <span>{detail?.total_no_show ?? 0}</span>
                        <p>{customerLang('General.Statistics.NoShows')}</p>
                      </li>
                    </ul>
                  </div>
                  <div className={styles.statistics_right}>
                    <ul>
                      <li>
                        <span>{formatMoney(detail?.amount_spent ?? 0)}</span>
                        <p>{customerLang('General.Statistics.TotalSales(AUD)')}</p>
                      </li>
                      <li>
                        <span>{detail?.loyalty_point ?? 0}</span>
                        <p>{customerLang('General.Statistics.LoyaltyPoints')}</p>
                      </li>
                      <li>
                        <span className={moment(detail?.last_visit).isValid() ? '' : 'empty'}>{getFormatShortDate(detail?.last_visit) || 'EMPTY'}</span>
                        <p>{customerLang('General.Statistics.LastVisits')}</p>
                      </li>
                      <li>
                        <span className={detail?.last_location ? '' : 'empty'}>{detail?.last_location || 'EMPTY'}</span>
                        <p>{customerLang('General.Statistics.LastLocation')}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </StatisticsCardStyled>
            <div className="common-card w-t-50">
              <h2 className="card-title">{customerLang('General.Notifications.Title')}</h2>
              <div className="card-body">
                <div className={styles.notifications_wrapper}>
                  <ul>
                    <li>
                      <div className={styles.notifications_label}>
                        <p>{customerLang('General.Notifications.Client')}</p>
                        <Form.Item
                          name="clientNotify"
                          noStyle
                          valuePropName="checked"
                        >
                          <Switch />
                        </Form.Item>
                      </div>
                      {detail?.notification_customer === 0 && <p className={styles.notifications_note}>
                        {customerLang('General.Notifications.ClientNote')}
                      </p>}
                    </li>
                    <li>
                      <div className={styles.notifications_label}>
                        <p>{customerLang('General.Notifications.Marketing')}</p>
                        <Form.Item
                          name="marketingNotify"
                          noStyle
                          valuePropName="checked"
                        >
                          <Switch />
                        </Form.Item>
                      </div>
                      {detail?.notify_market === 0 && <p className={styles.notifications_note}>
                        {customerLang('General.Notifications.MarketingNote')}

                      </p>}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* TODO: NEXT PHRASE */}
          <div className="cards-col">
            <div className="common-card">
              <h2 className="card-title">Activity</h2>
              <div className="card-body">
                <ul className={styles.activity_list}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No activity'} />
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="cards-col">
          <div className="common-card">
            <h2 className="card-title">{customerLang('General.ActivityTitle')}</h2>
            <div className="card-body">
              <div className="form-row">
                <Form.Item label="Add Note" name="note">
                  <Input className="input-send" placeholder="This is a note." />
                </Form.Item>
              </div>
              <ul className={styles.activity_list}>
                <li>
                  <div className={styles.item_user}>
                    <Image src={AuthAvatar} alt="AuthAvatar" />
                  </div>
                  <ul className={styles.item_detail}>
                    <li>
                      <p>Note added</p>
                      <p className={styles.detail_time}>
                        13 Dec 2022 at 12:12am by User
                      </p>
                    </li>
                    <li>
                      <p>This is a note.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <div className={styles.item_user}>
                    <Image src={AuthAvatar} alt="AuthAvatar" />
                  </div>
                  <ul className={styles.item_detail}>
                    <li>
                      <p>New appointment booked</p>
                      <p className={`${styles.detail_time}`}>
                        13 Dec 2022 at 12:12am by User
                      </p>
                    </li>
                    <li>
                      <p>
                        Massage, Relaxation (AUD 20) with Team Member on {''}
                        <span
                          className={`${styles.detail_time} ${styles.is_green}`}
                        >
                          15 Dec 2022 at 12:00pm
                        </span>
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <div className={styles.item_user}>
                    <Image src={AuthAvatar} alt="AuthAvatar" />
                  </div>
                  <ul className={styles.item_detail}>
                    <li>
                      <p>Team member created</p>
                      <p className={`${styles.detail_time}`}>
                        13.02.2023 12:12 - by Team member name
                      </p>
                    </li>
                    <li>
                      <p>
                        Massage, Relaxation (AUD 20) with Team Member on {''}
                        <span
                          className={`${styles.detail_time} ${styles.is_green}`}
                        >
                          15 Dec 2022 at 12:00pm
                        </span>
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        </div>
        <div className={styles.general_footer}>
          <button type='button' className="common-btn is-white" onClick={_onCancel}>{commonLang('Cancel')}</button>
          <button type='button' className="common-btn" onClick={_onSubmit}>{commonLang('Save')}</button>
        </div>
      </Form>
    </GeneralTabStyled>
  );
};

export default GeneralTab;

const GeneralTabStyled = styled.div`
  .ant-form-item-extra {
    font-style: italic;
    color:#7980BC;
  }
  .empty {
    opacity:0;
  }
`;

const StatisticsCardStyled = styled.div`
  h2 {
    border: none !important;
  }
  .header {
    height: 52px;
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid var(--color-purple);
  }
  span {
    @media only screen and (max-width: 767.98px) {
      font-size: 18px;
    }
  }
`;
