import { Form, Input } from 'antd';
import { AxiosResponse } from 'axios';
import FormRow from 'components/common/Form/FormRow';
import bookingSelectors from 'features/bookings/services/selectors';
import apisPromotion from 'features/promotions/services/apis';
import { get } from 'lodash';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import styled from 'styled-components';
import { HBox } from '../UIBase';
import { ButtonSuccessBig, LabelStyled } from './BasicContent';
import { useAppDispatch } from 'store/hooks';
import bookingActions from 'features/bookings/services/actions';
import { IBookingOnlineFormInfoValue } from 'features/bookings/services/types/bookingOnline';
import { mappingPromotionInfo } from 'features/checkout/components/PromotionCode/PromotionInput';
import useCheckServicesPromotion from 'features/checkout/components/PromotionCode/useCheckServicesPromotion';
import { IService } from '../types';

const PromotionInfo = () => {
  const { merchant_code = '' } = useParams();
  const [form] = Form.useForm();

  const [helperText, setHelperText] = useState<string>('');
  const formValue = bookingSelectors.bookingOnline.getFormValues();
  const serviceSelected = bookingSelectors.bookingOnline.getSelectedServices();
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  const setLoadingPage = useSetLoadingPage();
  const dispatch = useAppDispatch();
  const [isApplied, setIsApplied] = useState<boolean>();


  const onchange = () => {
    setHelperText('');
    const payload = {
      ...formValue,
      promotionCode: form.getFieldValue('promotionCode'),
    } as IBookingOnlineFormInfoValue;

    dispatch(bookingActions.setBookingOnlineFormValues(payload));
  };

  // @ts-ignore
  const checkSuitableServices = useCheckServicesPromotion(serviceSelected as IService[]);


  const checkPromotion = async () => {
    const promotion_code = form.getFieldValue('promotionCode');
    const location_id = formValue?.location || '';
    setLoadingPage(true);

    setError('');
    setHelperText('');
    setWarning('');

    try {
      const res: AxiosResponse<any> = await apisPromotion.checkPromotionOnlineValid(promotion_code, { merchant_code, location_id });
      const resData = res?.data?.data;
      if (resData) {
        const dataMapping = mappingPromotionInfo(resData);
        const isNoSuitableServices = !checkSuitableServices(dataMapping);

        if (isNoSuitableServices) {
          setWarning('Please check again, there are currently no services that match the service you selected');
          return;
        }

        setIsApplied(true);
        setHelperText('Promotion code is valid');
        dispatch(bookingActions.setBookingOnlineActivePromotion(res.data.data));
      } else {
        setError(res?.data?.error.message);
        return;
      }
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        setError(get(value, [0], ''));
      });
    } finally {
      setLoadingPage(false);
    }

  };
  
  const errorBoundary = useMemo(() => {
    if (helperText) {
      return ({
        validateStatus: 'success',
        help: helperText,
      });
    }

    if (error) {
      return ({
        validateStatus: 'error',
        help: error,
      });
    }

    if(warning) {
      return ({
        validateStatus: 'warning',
        help: warning,
      });
    }

    return ({
      validateStatus: '',
      help: undefined,
    });
  }, [helperText, error]);

  return (
    <>
      <PromotionInfoStyled>
        <LabelStyled>Have promotion</LabelStyled>
        <Form
          form={form}
        >
          <FormRow
            label='Promotion Code'
            name='promotionCode'
            // @ts-ignore
            validateStatus={errorBoundary?.validateStatus}
            help={errorBoundary?.help}
          >
            <HBox>
              <Form.Item
                label='Promotion code'
                name='promotionCode'
                noStyle
              >
                <Input disabled={isApplied} placeholder={'Enter your promotion'} onChange={onchange} />
              </Form.Item>
              {/* <div className='btn-list-search' onClick={checkCustomerInfo}></div> */}
              <ButtonSuccessBig className='checkout-form-btn' style={isApplied ? {
              border: '1px solid #f5222d',
              color: '#f5222d'}: undefined} onClick={isApplied ? () => setIsApplied(false) : checkPromotion}> {isApplied ? 'Cancel' : 'Redeem'}</ButtonSuccessBig>
            </HBox>
          </FormRow>
        </Form>
      </PromotionInfoStyled>
    </>
  );
};

export default PromotionInfo;

const PromotionInfoStyled = styled.div`
 p.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--color-primary);
 }
`;