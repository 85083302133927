import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, Modal } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import Upload from 'antd/es/upload/Upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { useEffect, useState } from 'react';
import AuthAvatar from '../../../assets/images/auth/auth_avatar.jpg';
import styles from '../pages/index.module.scss';
import NumberValidations from 'utils/validations/number';
import { IProfileForm, IProfileRequest } from '../service/types/request';
import { AxiosResponse } from 'axios';
import apisEditProfile from '../service/apis';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import userActions from 'features/users/services/actions';
import storage from 'utils/sessionStorage';
import message from 'components/common/Message';
import { useTranslation } from 'react-i18next';
import IconFacebook from 'assets/svg/IconFacebook';
import IconInstagram from 'assets/svg/IconInstagram';
import settingApis from 'features/settings/services/apis';
import settingActions from 'features/settings/services/actions';


const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const ProfileTab = ({ detail, getSetting }: { detail: any, getSetting: any }) => {

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const authToken = storage.token.get();

  const dispatch = useDispatch();
  const getAvatar = () => {
    return detail?.avatar_url != '' ? detail?.avatar_url : AuthAvatar;
  };
  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: getAvatar(), //note_img
    },
  ]);
  const [form] = Form.useForm();
  const handleCancel = () => setPreviewOpen(false);

  useEffect(() => {
    setFileList(
      [{
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: getAvatar(), //note_img
      },]
    );
  }, [detail]);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }


    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    );
  };

  const handleRemove = () => {

    setFileList([
      {
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: AuthAvatar,
      },
    ]);
  };

  const _getInitialValue = (values: IProfileRequest) => {
    return {
      name: values?.full_name,
      email: values?.email,
      licenseNumber: values?.license_number,
      phoneNumber: values?.phone,
      facebook_page: values?.facebook_page,
      instagram_page: values?.instagram_page
    } as IProfileForm;
  };

  // useEffect(() => {
  //   form.setFieldsValue(_getInitialValue(detailgetSetting?.online_links));
  // }, [detail, getSetting]);

  useEffect(() => {
    if (detail && getSetting && getSetting.online_links) {
      const initialValues = _getInitialValue(detail);
      if (getSetting.online_links) {
        initialValues.facebook_page = getSetting.online_links.facebook_page;
        initialValues.instagram_page = getSetting.online_links.instagram_page;
      }
      form.setFieldsValue(initialValues);
    }
  }, [detail, getSetting]);

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const isJpgOrPng = newFileList.every(file => file.type === 'image/jpeg' || file.type === 'image/png');

    if (!isJpgOrPng) {
      return;
    } else {
      setFileList(newFileList);
      dispatch(userActions.getProfileStaffDetail.fetch());
      dispatch(settingActions.getSetting.fetch());
    }
  };

  const getPayload = (values: IProfileForm) => {
    return {
      full_name: values?.name,
      email: values?.email,
      phone: values?.phoneNumber,
      license_number: values?.licenseNumber,
      confirm_password: values?.confirmPassword,
      old_password: values?.password,
      new_password: values?.newPassword,
      facebook_page: values?.facebook_page,
      instagram_page: values?.instagram_page
    } as IProfileRequest;
  };


  const handleSubmit = async (values: IProfileForm) => {
    const payload = getPayload(values);


    try {
      const rest: AxiosResponse<{ message: string }> =
        await apisEditProfile.updateProfileGeneral(payload);
      await settingApis.updateGeneral(payload);
      const msg = get(rest, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(settingActions.getSetting.fetch());
        dispatch(userActions.getProfileStaffDetail.fetch());
        
        return true;
      } else {

      }
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );
      message.error(Object.values(response)[0][0]);

      return false;
    }

  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const { t: headerLang } = useTranslation('header');
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{headerLang('Upload')}</div>
    </div>
  );
  return (
    <Form initialValues={{
      name: detail?.full_name,
      email: detail?.email,
      licenseNumber: detail?.license_number,
      phoneNumber: detail?.phone,
      facebook_page: getSetting?.online_links?.facebook_page,
      instagram_page: getSetting?.online_links?.instagram_page
    }}
      form={form}
      layout='vertical'
      onFinish={handleSubmit}
    >
      <div className='cards-wrapper'>
        <div className='cards-col'>
          <div className='common-card'>
            <div className={styles.profile_heading}>
              <h2 className={styles.heading_title}>{headerLang('Avatar')}</h2>
              <div>

                <div className={styles.heading_detail}>
                  <Upload
                    action='http://api.heyapos.com/api/v1/auth/update-avatar'
                    method='post'
                    name='avatar_file'
                    headers={{
                      'Accept-Heya': 'application/myheya.heyapos',
                      'Authorization': 'Bearer ' + authToken,
                    }}
                    listType='picture-circle'
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    beforeUpload={beforeUpload}
                    maxCount={1}
                  >
                    {fileList.length >= 8 ? null : uploadButton}
                  </Upload>
                  <button
                    onClick={handleRemove}
                    className='btn-delete-file'
                  ></button>
                </div>
              </div>
              <Modal
                maskClosable={false}
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
                className='is-small'
                centered
              >
                <img alt='example' style={{ width: '100%' }} src={previewImage} />
              </Modal>
            </div>
            <div className='card-body' style={{ margin: 0 }}>
              <div className={styles.profile_inner}>
                <div>
                  <Form.Item
                    label={headerLang('Name')}
                    name='name'
                    rules={[
                      { required: true, message: headerLang('PleaseEnterName') || '' }
                    ]}
                  >
                    <Input placeholder={headerLang('EnterName') || ''} />
                  </Form.Item>
                  <Form.Item
                    label='Email'
                    name='email'
                    rules={[
                      { required: true, message: headerLang('PleaseEnterEmail') || '' },
                      { type: 'email', message: headerLang('IsNotAValidEmail') || '' },
                    ]}
                  >
                    <Input disabled placeholder={headerLang('EnterEmail') || ''} />

                  </Form.Item>
                  <div className='card-title'>
                    <span>
                      * {headerLang('ThisIsTheEmailUsedToLogInPleaseRememberThisEmail')}
                    </span>
                  </div>
                  <Form.Item
                    label={headerLang('PhoneNumber')}
                    name='phoneNumber'
                    rules={[
                      { required: true, message: headerLang('PleaseEnterPhoneNumber') || '' },
                      NumberValidations.PHONE
                    ]}
                  >
                    <Input placeholder={headerLang('EnterYourPhoneNumber') || ''} />
                  </Form.Item>
                  <Form.Item label={headerLang('LicenseNumber')} name='licenseNumber' rules={[
                    { required: true, message: headerLang('PleaseEnterLicenseNumber') || '' },
                  ]}>
                    <Input style={{ color: '#949BDA' }} placeholder={headerLang('EnterLicenseNumber') || ''} />
                  </Form.Item>
                  <Form.Item label={headerLang('PIN Number')} name='pin_number' rules={[
                    { required: true, message: headerLang('Please Enter Pin Number') || '' },
                  ]}>
                    <Input type='password' placeholder={headerLang('Enter Pin number') || ''} />
                  </Form.Item>
                </div>
              </div>
            </div>

          </div>
          <div className='common-card'>
            <div className='card-title'>
              <div>
                <div>
                  <div className='d-flex' style={{ gap: '10px' }}>
                    <p className='form-label is-biggest'>Social logins</p>
                  </div>
                  <p className='form-note'>
                    Manage the social links you use.
                  </p>
                </div>
              </div>
            </div>
            <div className='card-body'>
              <Form.Item
                label={headerLang('Facebook')}
                name='facebook_page'
              // rules={[
              //   { required: true, message: headerLang('PleaseEnterName') || '' }
              // ]}
              >
                <Input prefix={<IconFacebook />} placeholder={headerLang('Enter Facebook') || ''} />
              </Form.Item>

              <Form.Item
                label={headerLang('Instagram')}
                name='instagram_page'
              // rules={[
              //   { required: true, message: headerLang('PleaseEnterName') || '' }
              // ]}
              >
                <Input prefix={<IconInstagram />} placeholder={headerLang('Enter Instagram') || ''} />
              </Form.Item>
            </div>
          </div>
          <div className='form-submit-wrapper fixed-bottom'>
            <button className='common-btn' type='submit'>{headerLang('Save')}</button>
          </div>
          <div style={{ height: 40, width: 1 }} />
        </div>
      </div>
    </Form>
  );
};

export default ProfileTab;
