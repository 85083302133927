const IconHelpCircle = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#4A9D77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.08997 9.00057C9.32507 8.33224 9.78912 7.76868 10.3999 7.4097C11.0107 7.05073 11.7289 6.91951 12.4271 7.03928C13.1254 7.15906 13.7588 7.52209 14.215 8.0641C14.6713 8.6061 14.921 9.29209 14.92 10.0006C14.92 12.0006 11.92 13.0006 11.92 13.0006" stroke="#4A9D77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12 17H12.01" stroke="#4A9D77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  );
};

export default IconHelpCircle;