import { Tabs, TabsProps } from 'antd';
import styled from 'styled-components';
import ForSalesTab from './ForSalesTab';
import PurchasedTab from './PurchasedTab';

const VouchersTab = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'For sales',
      children: <ForSalesTab />,
    },
    {
      key: '2',
      label: 'Purchased',
      children: <PurchasedTab />,
    },
  ];

  return (
    <VouchersTabStyled>
      <Tabs defaultActiveKey="1" items={items} className='voucherTab' />
    </VouchersTabStyled>
  );
};

export default VouchersTab;

const VouchersTabStyled = styled.div`
  position: relative;
  color: var(--color-primary);
  margin-bottom: 16px;
  .common-input-search {
    margin-bottom: 16px;
  }
  table {
    border-collapse: separate;
    border-spacing: 0 25px;
  }
  .voucher_content {
    padding-left: 8px;
    width: 100%;
    border-spacing: 0;
    .checkbox {
      margin-right: 16px;
    }
    .ant-form-item-row {
      display: block;
    }
    .ant-form-item-control {
      margin-bottom: 0 !important;
    }
    .group_name {
      display: flex;
      align-items: center;
    }
    .voucher_name {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      display: flex;
      align-items: center;
    }
    .voucher_time {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
    }
  }

  .voucher_amount {
    display: block;
    padding-right: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: right;
    span {
      display: block;
      font-weight: 400;
      font-size: 12px;
    }
  }
  .voucherTab {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        background:transparent !important;
        padding: 0;
        margin-right:32px;
        .ant-tabs-tab-btn {
          color:#7980BC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
          padding: 10px 0;
          border-bottom: 2.5px solid transparent;
        }
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #363565;
            border-bottom: 2.5px solid #363565;
          }
        }

      }
    }
  }
  
`;
