import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ISagaFunc } from 'services/actionConfigs';
import uiActions from 'services/UI/actions';
import message from 'components/common/Message';
import { first, get } from 'lodash';
import { IApiAnalyticParam, IApiAnalyticRes } from './types/api';
import apisAnalytic from './apis';
import analyticActions from './actions';

const getAnalytic: ISagaFunc<IApiAnalyticParam> = function* ({ payload }) {
  yield put(uiActions.setLoadingPage(true));
  try {
    const res: AxiosResponse<{ data: IApiAnalyticRes }> = yield call(apisAnalytic.getAnalytic, payload);
    
    if (res?.data?.data) {
      yield put(analyticActions.getAnalytics.success(res?.data?.data));
    } else {
      message.error('An error occurred. Please try again');
    }
  } catch (error) {
    const errors = Object.values(get(error, 'response.data.errors', {}));
    const msg = get(first(errors), 0, '');
    message.error(msg);
    yield put(analyticActions.getAnalytics.fail({}));
  } finally {
    yield put(uiActions.setLoadingPage(false));
  }
};



export default function* analyticServiceSaga() {
  yield takeLatest(analyticActions.getAnalytics.fetch, getAnalytic);
}
