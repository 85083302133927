import styled from 'styled-components';

const TemporaryClosedPage = () => {
    return (
        <TemporaryClosedPageStyled>
            <IconTemporaryClosed/>
            <p className='header'>
            Sorry! Our store has temporary closed for maintenance.
            </p>
            <p className='description'>
                Our store is temporarily closed for renovations, creating an even more serene and luxurious retreat for your relaxation. We &apos ll be back soon, offering an new place of tranquility and bliss.
            </p>
        </TemporaryClosedPageStyled>
    );
};

const TemporaryClosedPageStyled = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    .header {
        margin-top: 46px;
        font-weight: 600;
        font-size: 32px;
        line-height: 140%;
        text-align: center;
        width: 562px;;
    }

    .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        width: 562px;
    }
`;


export default TemporaryClosedPage;

const IconTemporaryClosed = () => {
    return (
        <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6553_179797)">
            <path d="M70 140C108.66 140 140 108.66 140 70C140 31.3401 108.66 0 70 0C31.3401 0 0 31.3401 0 70C0 108.66 31.3401 140 70 140Z" fill="#FF6B6B"/>
            <path d="M17.2722 108.07L44.3554 135.153C52.2966 138.282 60.9476 140 70 140C107.942 140 138.831 109.812 139.964 72.1439L124.162 56.3416C124.162 56.3416 120.999 53.9641 118.032 53.6606L79.7508 15.4209L34.4274 58.5048L17.2722 108.07Z" fill="#EA1C81"/>
            <path d="M105.578 61.7855L70 29.2921L34.4217 61.7855L28.8898 55.7283L70 18.1826L111.11 55.7283L105.578 61.7855Z" fill="white"/>
            <path d="M111.111 55.7293L105.577 61.7859L95.2301 52.3359L76.2016 34.9562L70 29.2934V18.1836L81.7359 28.9023L107.395 52.3359L111.111 55.7293Z" fill="#D8D8D8"/>
            <path d="M115.622 110.641H24.3778C18.2419 110.641 13.2678 105.667 13.2678 99.5306V63.447C13.2678 57.311 18.2419 52.3369 24.3778 52.3369H115.622C121.758 52.3369 126.732 57.311 126.732 63.447V99.5303C126.732 105.666 121.758 110.641 115.622 110.641Z" fill="#FFE278"/>
            <path d="M126.733 63.4457V99.5312C126.733 105.667 121.759 110.641 115.623 110.641H70V52.3359H115.623C121.759 52.3359 126.733 57.3098 126.733 63.4457Z" fill="#F4B94E"/>
            <path d="M70 36.5564C77.0796 36.5564 82.8188 30.8173 82.8188 23.7377C82.8188 16.6581 77.0796 10.9189 70 10.9189C62.9204 10.9189 57.1813 16.6581 57.1813 23.7377C57.1813 30.8173 62.9204 36.5564 70 36.5564Z" fill="#474D54"/>
            <path d="M50.2936 70.4707H46.1921V92.5065H56.5789V88.4049H50.2936V70.4707Z" fill="#5F40D3"/>
            <path d="M115.01 74.6465V70.5449H103.414V79.4377V83.5392V92.4319H115.01V88.3304H107.516V83.5392H114.457V79.4377H107.516V74.6465H115.01Z" fill="#4540C6"/>
            <path d="M38.1817 86.6117C38.019 86.8059 37.8435 86.9869 37.6603 87.1501C36.7197 87.9879 35.5215 88.4129 34.0988 88.4129C30.281 88.4129 27.1748 85.3069 27.1748 81.4889C27.1748 77.6709 30.2808 74.5652 34.0988 74.5652C35.4865 74.5652 36.8247 74.9737 37.9684 75.7464L39.6679 76.8946L41.9639 73.496L40.2648 72.3481C38.4404 71.1155 36.3081 70.4639 34.099 70.4639C28.0197 70.4639 23.0735 75.4098 23.0735 81.4891C23.0735 87.5685 28.0194 92.5147 34.099 92.5147C36.5233 92.5147 38.698 91.719 40.3886 90.2132C40.7195 89.9184 41.0342 89.5938 41.3241 89.2485L42.6423 87.6773L39.5002 85.0408L38.1817 86.6117Z" fill="#5F40D3"/>
            <path d="M90.0561 74.7596C92.6737 73.9719 95.0822 75.8643 95.1719 75.936L96.7335 77.2485L99.3727 74.1089L97.8029 72.7892C97.6366 72.6495 93.6775 69.3847 88.873 70.8322C86.4678 71.5569 84.7665 73.4496 84.4329 75.7719C84.1206 77.9465 85.0632 80.0381 86.8922 81.2305C86.983 81.2896 87.0781 81.3413 87.1766 81.3856C87.2802 81.4318 89.7463 82.5294 92.5242 83.5542C93.3565 83.8613 95.2646 84.7349 95.0007 86.2128C94.8076 87.2943 93.511 88.4126 91.6754 88.4126C89.8379 88.4126 88.0677 87.6697 86.9403 86.4256L85.563 84.9058L82.5237 87.6601L83.9008 89.1796C85.793 91.2676 88.6993 92.5139 91.6754 92.5139C95.3646 92.5139 98.461 90.167 99.0385 86.9333C99.4717 84.5074 98.3607 81.335 93.9439 79.7058C91.7069 78.8806 89.6266 77.9813 89.0228 77.7169C88.4363 77.2627 88.4549 76.6206 88.4932 76.3549C88.5437 76.0016 88.7975 75.1389 90.0561 74.7596Z" fill="#4540C6"/>
            <path d="M69.417 70.4634C63.3377 70.4634 58.3915 75.4093 58.3915 81.4887C58.3915 87.568 63.3374 92.5142 69.417 92.5142C75.4966 92.5142 80.4423 87.5683 80.4423 81.4887C80.4423 75.409 75.4964 70.4634 69.417 70.4634ZM69.417 88.4126C65.5993 88.4126 62.493 85.3067 62.493 81.4887C62.493 77.6706 65.599 74.5649 69.417 74.5649C73.2348 74.5649 76.3407 77.6709 76.3407 81.4887C76.3407 85.3064 73.2348 88.4126 69.417 88.4126Z" fill="#5F40D3"/>
            <path d="M82.8188 23.7372C82.8188 25.5747 82.4332 27.322 81.7359 28.9024C80.6012 31.4728 78.6461 33.6028 76.2016 34.9563C74.3641 35.9763 72.2504 36.556 70 36.556V10.9185C77.0793 10.9185 82.8188 16.6579 82.8188 23.7372Z" fill="#33373A"/>
            <path d="M70 70.4761V74.5886C73.5465 74.8839 76.341 77.8671 76.341 81.4901C76.341 85.1104 73.5465 88.0937 70 88.389V92.5015C75.8078 92.1952 80.4426 87.3745 80.4426 81.4901C80.4426 75.6058 75.8078 70.7823 70 70.4761Z" fill="#4540C6"/>
            </g>
            <defs>
            <clipPath id="clip0_6553_179797">
            <rect width="140" height="140" fill="white"/>
            </clipPath>
            </defs>
        </svg>

    );
};
