import message from 'components/common/Message';
import useDoRedeem from 'features/checkout/components/PromotionCode/useDoRedeem';
import checkoutActions from 'features/checkout/services/actions';
import apisCheckout from 'features/checkout/services/apis';
import { EErrorMethodKeys } from 'features/checkout/services/constants';
import checkoutSelectors from 'features/checkout/services/selectors';
import { EPaymentMethod } from 'features/checkout/services/types/checkout';
import { IFuncPromotionAccept } from 'features/checkout/services/types/promotion';
import { get } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import { IPurchaseProps } from './type';
import useFee from './useFee';
import { useSurcharge } from './useSurcharge';

export const useCashErrors = (): [string, (val: string) => void] => {
  const dispatch = useAppDispatch();
  const errors = checkoutSelectors.getPurchaseMethodErrors(EPaymentMethod.CASH);
  const errorText = useMemo(() => get(errors, EErrorMethodKeys.cash.amount, ''), [errors]);
  const setErrorValid = (msg: string) => {
    dispatch(checkoutActions.setPurchaseMethodErrors({
      method: EPaymentMethod.CASH,
      errors: {
        [EErrorMethodKeys.cash.amount]: msg,
      }
    }));
  };
  return [errorText, setErrorValid];
};

const Cash = ({ onSuccess, isActive }: IPurchaseProps) => {
  const dispatch = useAppDispatch();
  const { t: checkoutLang } = useTranslation('checkout');
  const selectedReward = checkoutSelectors.getSelectedReward();
  const detail = checkoutSelectors.getBookingDetail();
  const id = checkoutSelectors.getIdBookingDetail();
  const setLoadingPage = useSetLoadingPage();
  const currCash = checkoutSelectors.getPurchaseCurrentCash() ?? 0;
  const amount = checkoutSelectors.getOriginTotalPrice();
  const selected = checkoutSelectors.getSelectedServices();

  const promotionInfo = checkoutSelectors.getPromotionInfo();
  const softPromotionCode = checkoutSelectors.getSoftPromotionCode();

  const obFeeValues = useFee();
  const obSurcharge = useSurcharge();
  

  const onPromotionAccept: IFuncPromotionAccept = (val) => dispatch(checkoutActions.setPromotionInfo(val));

  const doSoftRedeem = useDoRedeem(detail?.merchant_location_id || '', {
    servicesSelected: selected,
    setErrorBoundary: (val) => dispatch(checkoutActions.setPromotionErrorBoundary(val)),
  });

  const [, setErrorValid] = useCashErrors();

  const voucherSalesSelected = checkoutSelectors.getSelectedVouchersForSales() ?? [];


  const onPurchase = async () => {
    setErrorValid('');
    if (amount > currCash) {
      setErrorValid(checkoutLang('validate.cash.errorAmount'));
      return;
    }

    setLoadingPage(true);
    try {
      if (!promotionInfo && softPromotionCode) {
        onPromotionAccept(await doSoftRedeem(softPromotionCode));
        return;
      }
      const res = await apisCheckout.apiCheckoutByCash(String(id), {
        amount_given: (currCash > amount ? amount : currCash),
        // rewardInfo: selectedReward,
        // rewardUsedPoint: 30,
        voucher_sales: voucherSalesSelected.map(({ id, quantity }) => ({ voucher_id: id, quantity })),
        loyalty_reward_id: selectedReward?.id,
        promotion_code: promotionInfo?.code || null,
        ...obFeeValues ?? undefined,
        ...obSurcharge ?? undefined
      });

      const msg = get(res, 'data.data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(checkoutActions.setResultTotal(res?.data?.data?.notify_invoice));
        dispatch(checkoutActions.setLoyaltyPoint(res?.data?.data?.notify_invoice?.loyalty_point));
        onSuccess();
      } else {
        const error = get(res, 'data.error.message', '');
        if (error) {
          message.error(error);
        } else
          throw 'fail';
      }
    } catch (error) {
      message.error('An error occurred. Please try again');
    } finally {
      setLoadingPage(false);
    }
  };
  return (
    <button
      type='button'
      className='form-booking-submit'
      onClick={onPurchase}
      disabled={isActive}
    >
      Confirm
    </button>
  );
};

export default Cash;
