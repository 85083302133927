import message from 'components/common/Message';
import loyalActions from 'features/loyaltyProgram/services/actions';
import apisLoyal from 'features/loyaltyProgram/services/apis';
import { IRewardFormValues, IRewardItemResData } from 'features/loyaltyProgram/services/types/reward';
import { get } from 'lodash';
import { useState, useTransition } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import { RewardFormProps } from './Form';
import RewardModal from './Modal';
type Props = {
  data: IRewardItemResData;
};
const RewardEdit = ({ data }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState<IRewardFormValues>();
  const [, startTransition] = useTransition();

  const showModal = () => {
    startTransition(() => {
      const free_service: string[] = [];
      if (typeof data.free_service !== 'string')
        data.free_service?.forEach((service) => {
          if (service?.extend_services?.length > 0) {
            service?.extend_services.forEach(serviceVariantId => {
              free_service.push(service?.id + '-' + serviceVariantId);
            });
          } else {
            free_service.push(service.id + '');
          }
        });

      setFormData({
        category: data.category,
        percent: data.value_reward || null,
        price: data.amount_reward || null,
        rewardName: data.name,
        services: free_service,
        pointsToEarnReward: data.points_to_earn_reward ?? 0,
        resetPoint: data.reset_point
      });
      setIsOpen(true);
    });
  };
  const hideModal = () => {
    startTransition(() => {
      setFormData(undefined);
      setIsOpen(false);
    });
  };
  const setLoadingPage = useSetLoadingPage();
  const dispatch = useAppDispatch();

  const handleSubmit: RewardFormProps['onSubmit'] = async (payload) => {
    setLoadingPage(true);

    try {
      const rest = await apisLoyal.updateReward(data.id?.toString(), payload);
      const msg = get(rest, 'data.message', '');
      if (msg) {
        hideModal();
        dispatch(loyalActions.getListLoyalReward.fetch({}));
        message.success(msg);
      } else {
        const msgError = get(rest, 'data.error.message', '');
        if (msgError) message.error(msgError);
        else throw 'fail';
      }
    } catch (error) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setLoadingPage(false);
    }
  };


  return (
    <>
      <li className='action-edit' onClick={showModal}></li>
      <RewardModal isEdit formData={formData} visible={isOpen} onCancel={hideModal} onSubmit={handleSubmit} />
    </>
  );
};

export default RewardEdit;
