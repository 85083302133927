import { Form, Modal } from 'antd';
import { useRef, useState } from 'react';
import { AddNewCustomerFormRef, IFormAddCustomerData, IFuncCancel, IFuncSubmit } from '../AddNewCustomer/CustomersForm';
// import authSelectors from 'features/auth/services/selectors';
import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import apisCustomer from 'features/customers/services/apis';
import { IApiUpdateCustomerBody } from 'features/customers/services/types/cutomer';
import { get, set } from 'lodash';
import storage from 'utils/sessionStorage';
import EditCustomerForm from './CustomersForm';
import customerSelectors from 'features/customers/services/selectors';
import styled from 'styled-components';

interface EditCustomerProps {
  fetchDetailData: () => void
}

const EditCustomer = ({fetchDetailData}: EditCustomerProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formRef = useRef<AddNewCustomerFormRef>(null);
  const detail = customerSelectors.getCustomerDetail();

  const [errors, setErrors] = useState<Record<string, string> | null>(null);
  // const merchantInfo = authSelectors.getMerchantInfo();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel: IFuncCancel = () => {
    setIsModalOpen(false);
    setErrors(null);
  };

  

  const getPayload = (data: IFormAddCustomerData) => {
    const merchant_id = storage.merchantId.get() ?? '';
    const name = detail?.name ?? '';
    const phone_number = detail?.phone_number ?? '';
    const email = data?.email ?? '';
    const date_of_birth = data.birthDate ? data.birthDate.format('YYYY-MM-DD') : null;
    const total_visited = parseInt(data?.numberVisits ?? 0);
    const total_cancelled = parseInt(data?.cancellations ?? 0);
    const total_no_show = parseInt(data?.noShows ?? 0);
    const loyalty_point = parseInt(data?.loyaltyPoints ?? 0);
    const important_client_info = data?.importantClientInfo ?? null;
    const gender = data?.gender ?? null;
    const amount_spent = data?.amountSpent ?? 0;
    const payload: IApiUpdateCustomerBody = {
      merchant_id,
      name,
      phone_number,
      email,
      gender,
      date_of_birth,
      total_visited,
      total_cancelled,
      total_no_show,
      loyalty_point,
      amount_spent,
      important_client_info,
    };
    return payload;
  };
  const handleSubmit: IFuncSubmit = async (data) => {
    handleCancel();
    const payload = getPayload(data);
    try {
      const rest: AxiosResponse<{ message: string }> = await apisCustomer.updateCustomerInfo(detail?.id.toString() ?? '', payload);
      const msg = get(rest, 'data.message', '');
      if (msg) {
        message.success(msg);
        handleCancel();
        fetchDetailData();
        return true;
      }
      return false;
    } catch (error: any) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      const errorsData = {};
      if (errors.length > 0) {
        errors.map(([key, text]) => {
          const msg = get(text, [0]);
          message.error(msg);
          set(errorsData, [key], msg);
        });
        if (errors.some(([key]) => {
          if (['phone_number', 'name', 'email', 'date_of_birth'].includes(key)) return true;
          return false;
        })) {
          formRef.current?.prevStep();
        }
        setErrors(errorsData);
      }
      else {
        message.error('An error occurred. Please try again');
      }
      return false;
    }
  };

  return ( 
    <EditCustomerFormStyled>
      <button className='common-btn is-white' type='button' onClick={() => showModal()} >
        {'Edit'}
      </button>
      {isModalOpen &&
        <EditCustomerModal
          visible
          errors={errors}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
        />}
    </EditCustomerFormStyled>
  );
};

type EditCustomerModalProps = {
  visible?: boolean;
  handleCancel?: () => void;
  handleSubmit?: IFuncSubmit;
  errors?: Record<string, string> | null;
};
const EditCustomerModal = ({ visible, handleCancel, handleSubmit, errors }: EditCustomerModalProps) => {
  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <h2 className="modal-title">
        <p>{'Edit'}</p>
      </h2>
      <Form
        name="basic"
        className="modal-form"
        onFinish={handleSubmit}
        autoComplete="off"
        initialValues={{
          phoneNumberCode: '+92',
        }}
        layout="vertical"
      >
        <EditCustomerForm
          // ref={formRef}
          errors={errors}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      </Form>
    </Modal>
  );
};

export default EditCustomer;

const EditCustomerFormStyled = styled.div`
  .button {
    border-radius: 8px;
    padding: 8px 16px;
    min-width: 100px !important;
    /* background-color: #F3F3F3; */
  }
  .common-btn {
    border-radius: 8px;
    padding: 8px 16px;
    min-width: 100px !important;
  }
`;
