import { Select } from 'antd';
import React, { useMemo } from 'react';
import invoiceSelectors from '../services/selectors';
import { useAppDispatch } from 'store/hooks';
import invoiceActions from '../services/actions';

const Category = () => {
  const dispatch = useAppDispatch();
  const categories = invoiceSelectors.getCategories();
  const params = invoiceSelectors.getInvoiceAllParam();
  
  const _categories = useMemo(() => {
    const result = categories.map(o => ({ value: o.id.toString(), label: o.name }));
    result.unshift({
      value: 'all',
      label: 'All categories',
    });
    return result;
  }, [categories]);

  const category_id = invoiceSelectors.getInvoiceParam('category_id') as string ?? 'all';

  const setCategory = (val: string) => {
    dispatch(invoiceActions.setInvoiceParams({
      ...params,
       category_id: val !== 'all' ? +val : undefined 
      }));
  };

  return (
    <div className="common-select">
      <Select
        value={category_id?.toString()}
        options={_categories}
        onChange={setCategory}
      />
    </div>
  );
};

export default Category;