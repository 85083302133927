import { Checkbox, Form, InputNumber, Radio, Select, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { AxiosResponse } from 'axios';
import SwitchInput from 'components/common/Input/SwitchInput';
import message from 'components/common/Message';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import settingActions from '../services/actions';
import settingApis from '../services/apis';
import settingSelectors from '../services/selectors';
import FormTabSetting from './FormTabSetting';
import IconInfo from 'assets/svg/IconInfo';
import styled from 'styled-components';
import { CHOOSE_METHOD_NOTIFY } from 'features/loyaltyProgram/services/constants';
import loyalSelectors from 'features/loyaltyProgram/services/selectors';
import loyalActions from 'features/loyaltyProgram/services/actions';
import apisLoyal from 'features/loyaltyProgram/services/apis';
interface IOptional {
    value: number;
    label: string;
}

interface IFormNotification {
    appointments: boolean;
    rattings_and_reviews: boolean;
    remider?: number;
    discount_review?: number;
    loyalty_notify_after_visit: boolean;
    loyalty_notify_method: string;
    loyalty_content_message: string;
}


const NotificationTab = () => {

    const getBookOption = settingSelectors.getOptionBooking();
    const getSetting = settingSelectors.getSetting();
    const loyalSetting = loyalSelectors.getLoyalSetting();



    const [form] = Form.useForm();
    const dispatch = useDispatch();


    const getSelectValue = (value: number, options: IOptional[]) => {
        const valueCustom = options.some((item: IOptional) => item?.value === value) ? value : options[0]?.value;
        
        return Number(valueCustom);
    };

    const getBoolean = (value: boolean | string | undefined) => {
        return (typeof value === 'string') ? value?.toLowerCase() === 'true'.toLowerCase() : value;
    };

    const getFieldsValue = () => {
        const notification = getSetting?.notification_setting;
        const review = getSetting?.review;

        return {
            appointments: getBoolean(notification.appointments),
            rattings_and_reviews: getBoolean(notification.rattings_and_reviews),
            remider: getSelectValue(notification?.remider, getBookOption?.m_option_remiders) === 0 ? '' : getSelectValue(notification?.remider, getBookOption?.m_option_remiders),
            discount_review: review?.discount_review,
            loyalty_notify_method: loyalSetting?.loyalty_notify_method,
            loyalty_notify_after_visit: loyalSetting?.loyalty_notify_after_visit,
            loyalty_content_message: loyalSetting?.loyalty_content_message,
        };
    };
    useEffect(() => {
        if (!loyalSetting) {
            dispatch(loyalActions.getLoyalSetting.fetch());
        }


        form.setFieldsValue(getFieldsValue());
    }, [getSetting, loyalSetting]);



    const getPayload = (values: IFormNotification) => {
        const rattings_and_reviews = getBoolean(values?.rattings_and_reviews);
        const appointments = getBoolean(values?.appointments);
        const remider = values?.remider;
        const discount_review = values?.discount_review === undefined ? 5 : values?.discount_review;
        const loyalty_notify_method = values?.loyalty_notify_method;
        const loyalty_notify_after_visit = values?.loyalty_notify_after_visit;
        const loyalty_content_message = values?.loyalty_content_message;
        return {
            ...getSetting?.booking,
            rattings_and_reviews,
            appointments,
            remider,
            discount_review,
            loyalty_notify_method,
            loyalty_notify_after_visit,
            loyalty_content_message
        };
    };

    const handleSubmit = async (values: IFormNotification) => {
        const payload = getPayload(values);

        try {
            const rest: AxiosResponse<{ message: string }> =
                await settingApis.updateGeneral(payload);
            await apisLoyal.updateLoyalSetting(payload);
            await settingApis.updateCustomerLink(payload);
            // await settingApis.updateBooking(payload);
            await settingApis.updateNotification(payload);
            const msg = get(rest, 'data.message', '');
            if (msg) {
                message.success(msg);
                dispatch(settingActions.getSetting.fetch());
                dispatch(loyalActions.getLoyalSetting.fetch());
                form.setFieldsValue(getFieldsValue());
                return true;
            }
            return false;
        } catch (error: any) {
            const response = get<Record<string, string[]>>(
                error,
                'response.data.errors',
                {}
            );

            const errors = Object.values(response);
            errors.map((value) => {
                message.error(get(value, [0], ''));
            });

            return false;
        }
    };


    const checkValid = async () => {
        try {
            await form.validateFields([
                'appointments',
                'noShowStatus',
                'rattings_and_reviews',
                'remider',
                'discount_review',
                'loyalty_content_message'
            ]);
            return true;
        } catch (error) {
            return false;
        }
    };

    return (
        <>
            <FormTabSetting
                form={form}
                handleSubmit={handleSubmit}
                checkValid={checkValid}
                initialValues={getFieldsValue()}
            >
                <div className='cards-wrapper'>
                    <div className='cards-col'>
                        <div className='common-card'>
                            <h2 className='card-title'>Reminders
                                <p className='form-note'>
                                    Sends notifications to all customers prior to their bookings via email. If no email, send via SMS.
                                </p>
                            </h2>

                            <div className='card-body'>
                                <div className='form-row'>
                                    <SwitchInput
                                        label='Reminder to customers'
                                        name='noShowStatus'
                                        form={form}
                                    />
                                </div>
                                <div >
                                    <Form.Item
                                        label='Reminder timeframe'
                                        name='remider'

                                    >
                                        <Select
                                            defaultValue={form.getFieldValue('remider')}
                                            options={getBookOption?.m_option_remiders}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className='common-card'>
                            <h2 className='card-title'>Your notifications
                                <p className='form-note'>
                                    Choose which types of notifications you want to be notified about via email.
                                </p>
                            </h2>
                            <div className='card-body'>
                                <div style={{ alignItems: 'center', background: '#F8F8FF' }} className='form-row'>
                                    <IconInfo />
                                    <p>All notifications will be deleted at 12:00 every day.</p>
                                </div>
                                <CheckBoxStyled>
                                    <div className='form-row'>
                                        <Form.Item name='appointments' valuePropName='checked'>
                                            <Checkbox defaultChecked={getSetting?.booking?.is_team_member_booking}><p className='form-label'>Appointments</p></Checkbox>
                                        </Form.Item>
                                    </div>
                                    <div className='form-row'>
                                        <Form.Item name='rattings_and_reviews' valuePropName='checked'>
                                            <Checkbox defaultChecked={getSetting?.booking?.is_team_member_booking}><p className='form-label'>Ratings & reviews</p></Checkbox>
                                        </Form.Item>
                                    </div>
                                </CheckBoxStyled>
                            </div>
                        </div>
                        <div className='common-card'>
                            <h2 className='card-title'>Loyalty Program Settings
                                <p className='form-note'>
                                    Notify customers via email after each visit with loyalty points summary and next tier of rewards. If no email, send SMS.
                                </p>
                            </h2>
                            <div className='card-body'>
                                <li className='d-flex' style={{ gap: '8px', alignItems: 'center' }}>
                                    <p className='form-label'>Notify Customers After A Visit</p>
                                    <Form.Item
                                        name={'loyalty_notify_after_visit'}
                                        noStyle
                                        valuePropName="checked"
                                    >
                                        <Switch />
                                    </Form.Item>
                                </li>
                                <div className='form-row'>
                                    <Form.Item
                                        label='Choose method to notify your customers'
                                        name='loyalty_notify_method'
                                    >
                                        <Radio.Group>
                                            <Radio value={CHOOSE_METHOD_NOTIFY.EMAIL}>Email</Radio>
                                            <Radio value={CHOOSE_METHOD_NOTIFY.SMS}>SMS</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <Form.Item label='Google review discount' name='discount_review'>
                                    <InputNumber
                                        addonBefore='%'
                                        max={100}
                                        inputMode='numeric'
                                    />

                                </Form.Item>
                                <p className='form-note'>
                                    This promotional offer will be sent to your customers when they leave a review of their experience.
                                </p>
                                <div className='form-row'>
                                    <Form.Item
                                        label={
                                            <p>
                                                Loyalty program message
                                            </p>
                                        }
                                        name='loyalty_content_message'
                                    >
                                        <TextArea placeholder='Enter your note here' rows={15} />
                                    </Form.Item>
                                </div>
                                <div style={{ alignItems: 'center' }} className='form-row'>
                                    <IconInfo />
                                    <p>You have the option to reach out to Heya to modify the email template.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FormTabSetting>
        </>

    );
};

export default NotificationTab;

const CheckBoxStyled = styled.div`
display: grid;
gap: 8px;
.form-row {
    padding: 14px 0 0 12px;
    background: #F0F2F7;
    border-radius: 6px;
}
.ant-form-item-control {
    margin-bottom: 14px !important;
}

@media only screen and (max-width: 767.98px) {
    .form-label {
        overflow: inherit;
    }
}
`;
