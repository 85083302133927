import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IApiLocationParams } from './types/location';

const getListPaginateLocations = createAsyncAction<IApiLocationParams>(PREFIX_ACTIONS + 'GET_LIST_PAGINATE_LOCATION');
const getLocationDetail = createAsyncAction<string>(PREFIX_ACTIONS + 'GET_LOCATION_DETAIL');
const getLocationDetailPublic = createAsyncAction<any>(PREFIX_ACTIONS + 'GET_LOCATION_DETAIL_PUBLIC');
const deleteLocation = createAsyncAction<string>(PREFIX_ACTIONS + 'DELETE_LOCATION');


const locationActions = { 
    getListPaginateLocations,
    getLocationDetail,
    deleteLocation,
    getLocationDetailPublic
};

export default locationActions;