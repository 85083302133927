import { IModalFeeFormPayload, feeType } from 'features/checkout/services/types/fee';
import formatNumber from 'utils/formatNumber';

export const getValueFeeLabel = (data: IModalFeeFormPayload) => {
  if (data.type === 'percent') return formatNumber(data.value) + '%';
  return '$' + formatNumber(data.value);
};

export const getCalcValueFeeLabel = (type: feeType) => {
  switch (type) {
    case 'discount':
      return '-';
    case 'extra': return '+';
    default:
      return null;
  }
};

export const getTitleModal = (type: feeType) => {
  switch (type) {
    case 'extra':
      return 'Add service charge';
    case 'discount':
      return 'Add discount';
    default:
      return null;
  }
};

export const getTitleInput = (type: feeType) => {
  switch (type) {
    case 'extra':
      return 'Name extra charge';
    case 'discount':
      return 'Name';
    default:
      return null;
  }
};

export const getNameInitialValue = (type: feeType) => {
  switch (type) {
    case 'extra':
      return 'Extra service';
    case 'discount':
      return 'Discount service';
    default:
      return null;
  }
};

export const getAddLabel = (type: feeType) => {
  switch (type) {
    case 'extra':
      return 'Add extra';
    case 'discount':
      return 'Add discount';
    default:
      return null;
  }
};