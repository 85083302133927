import { Spin, Table, message } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import EditShiftModal from '../widgets/EditShiftModal';
import TimeOffModal from '../widgets/TimeOffModal';
import IconEdit from 'assets/svg/IconEdit';
import timeScheduleSelectors from '../services/selectors';
import { getDaysBetweenDates } from 'utils/unit';
import moment from 'moment';
import {
  DATE_FORMAT_RESPONSE,
  DATE_FORMAT_TIME_SCHEDULE,
  DAY_OF_WEEK_FORMAT,
} from 'features/bookings/services/constants';
import {
  ETimeSchedule,
  EditShiftInitValue,
  ITimeTimeOffFormValue,
  TimeOffModalType,
  TimingType,
} from '../types/timeSchedule';
import { IApiAddDateOff, IApiTimeShift } from '../types/api';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import apisTimeSchedule from '../services/api';
import { useAppDispatch } from 'store/hooks';
import timeScheduleActions from '../services/actions';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';

const timeScheduleValueEdit = [ETimeSchedule.ON, ETimeSchedule.DAY_OFF];

interface TimeScheduleTableProps {
  handleAddTimeOff: (values: IApiAddDateOff) => Promise<boolean>;
}

const TimeScheduleTable = ({ handleAddTimeOff }: TimeScheduleTableProps) => {
  const [editShiftModalOpen, setEditShiftModalOpen] = useState(false);
  const [isTimeOffModalOpen, setIsTimeOffModalOpen] = useState(false);
  const [mouseHoverData, setMouseHoverData] = useState({
    date: '',
    key: '',
  });
  const [editShiftInitData, setInitShiftInitData] =
    useState<EditShiftInitValue>({
      date: '',
      time: [],
      merchantEmployeeId: '',
    });
  const [editTimeOffInitData, setEnitTimeOffInitData] =
    useState<ITimeTimeOffFormValue>({});

  const param = timeScheduleSelectors.getParams();
  const setLoadingPage = useSetLoadingPage();

  const dayBetweenDate = getDaysBetweenDates(
    moment(param.start_date, DATE_FORMAT_RESPONSE),
    moment(param.end_date, DATE_FORMAT_RESPONSE)
  );
  const timeSchedule = timeScheduleSelectors.getLstTimeSchedule();
  const dispatch = useAppDispatch();

  const loadingTimeSchedules =
    timeScheduleSelectors.getLoadingLstTimeSchedules();

  const COLUMN_DATA = dayBetweenDate.map((o) => ({
    key: o.format(DATE_FORMAT_TIME_SCHEDULE).replace(' ', ''),
    label: o.format(DATE_FORMAT_TIME_SCHEDULE),
    subLabel: o.format(DAY_OF_WEEK_FORMAT),
    dateValue: o.format(DATE_FORMAT_RESPONSE),
  }));

  const TABLE_DATA = timeSchedule.map((o, index) => {
    const weekWorkingTime: any = {};
    Object.values(o.timing).forEach((workTime: TimingType) => {
      if (workTime?.shift_type === ETimeSchedule.ON) {
        weekWorkingTime[moment(workTime.date).format('MMMDD')] =
          workTime.timing_schedules?.map((o) => ({
            text:
              `${
                o.time_start ? dayjs(o.time_start, 'HH:mm').format('HH:mm') : ''
              } - ${
                o.time_end ? dayjs(o.time_end, 'HH:mm').format('HH:mm') : ''
              }` + (o?.is_work ? '' : ' OFF'),
            type: workTime.shift_type,
            date: workTime?.date,
            is_work: o?.is_work,
            repeat_every_day: workTime?.repeat_every_day
          }));
      } else {
        weekWorkingTime[moment(workTime.date).format('MMMDD')] = [
          {
            text: workTime?.content,
            type: workTime.shift_type,
            date: workTime?.date,
            is_work: false,
          },
        ];
      }
    });

    return {
      key: index,
      teamMember: o.full_name,
      employeeId: o.id,
      ...weekWorkingTime,
    };
  });

  const handleCloseEditTimeShiftModal = () => {
    setEditShiftModalOpen(false);
  };

  const handleCloseTimeOffModal = () => {
    setIsTimeOffModalOpen(false);
  };

  const submitEditTimeShift = async (payload: IApiTimeShift) => {
    setLoadingPage(true);
    try {
      const rest = await apisTimeSchedule.editTimeShift(
        editShiftInitData.merchantEmployeeId,
        payload
      );

      const code = rest?.data?.code;

      if (code === 1000) {
        handleCloseEditTimeShiftModal();
        dispatch(
          timeScheduleActions.setListTimeScheduleParams({
            page: 1,
          })
        );
        message.success(rest?.data?.message || '');
      } else {
        const msgError = rest?.data?.error?.message;
        if (msgError) message.error(msgError);
        else throw 'fail';
      }
    } catch (error: any) {
      const errorText = error?.response?.data?.error?.message;
      if (errorText) {
        message.error(errorText);
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setLoadingPage(false);
    }
  };

  const scheduleColumns: ColumnsType<any> = [
    {
      title: 'Team Member',
      dataIndex: 'teamMember',
      key: 'teamMember',
      className: 'nowrap schedule-table-row',
      fixed: 'left',
    },
    ...(COLUMN_DATA?.map((item: any) => {
      const headerColor =
        moment().format(DATE_FORMAT_RESPONSE) === item?.dateValue
          ? '#409E78'
          : '';

      return {
        title: (
          <div className='table-header'>
            <p style={{ color: headerColor }}>{item.label}</p>
            <p style={{ color: headerColor }} className='table-subheader'>
              {item?.subLabel}
            </p>
          </div>
        ),
        dataIndex: item.key,
        key: item.key,
        className: 'nowrap schedule-table-row',
        render: (value: any[], record: any) => {
          return (
            <div
              className='time-schedule-value'
              onMouseEnter={() => {
                if (
                  mouseHoverData?.date !== item?.key &&
                  mouseHoverData?.key !== record?.employeeId
                ) {
                  if (
                    value?.[0]?.type !== ETimeSchedule.CLOSED_DAY &&
                    value?.[0]?.type !== ETimeSchedule.HOLIDAY_OFF
                  ) {
                    setMouseHoverData({
                      date: item?.key,
                      key: record?.employeeId,
                    });
                  }
                }
              }}
              onMouseLeave={() => {
                setMouseHoverData({
                  date: '',
                  key: '',
                });
              }}
            >
              {value?.map((it: any, id) => {
                return (
                  <div
                    key={'time-schedule-column-' + id}
                    className={`${
                      (!it?.is_work ? 'time-schedule-value-off ' : '') +
                      (timeScheduleValueEdit.indexOf(it?.type) >= 0
                        ? 'time-schedule-value-edit'
                        : '')
                    }`}
                  >
                    {it?.text}
                  </div>
                );
              })}
              <div
                className={`${
                  mouseHoverData?.date === item?.key &&
                  mouseHoverData?.key === record?.employeeId
                    ? 'wrap-edit-value'
                    : 'wrap-value-none'
                }`}
                onClick={() => {
                  if (
                    value?.[0]?.type !== ETimeSchedule.CLOSED_DAY &&
                    value?.[0]?.type !== ETimeSchedule.HOLIDAY_OFF
                  ) {
                    if (value?.[0]?.type === ETimeSchedule.DAY_OFF) {
                      setIsTimeOffModalOpen(true);
                      setEnitTimeOffInitData({
                        teamMember: record?.employeeId,
                        dayOff: value?.[0]?.date,
                      });
                    } else {
                      setInitShiftInitData({
                        date: value?.[0]?.date,
                        time: value?.filter((item) => item?.is_work) || [],
                        merchantEmployeeId: record?.employeeId,
                        repeat_every_week: value?.filter((item) => item?.repeat_every_day)?.length ? true : false
                      });
                      setEditShiftModalOpen(true);
                    }
                  }
                }}
              >
                <IconEdit />
              </div>
            </div>
          );
        },
      };
    }) ?? []),
  ];

  return (
    <Spin spinning={loadingTimeSchedules}>
      <TimeScheduleTableStyled>
        <Table
          className={'custom-table'}
          dataSource={TABLE_DATA}
          columns={scheduleColumns}
          rowKey={(item) => item.key}
          scroll={{
            x: 900,
          }}
        />

        {editShiftModalOpen ? (
          <EditShiftModal
            isModalOpen={editShiftModalOpen}
            handleClose={handleCloseEditTimeShiftModal}
            handleSubmit={submitEditTimeShift}
            initData={editShiftInitData}
          />
        ) : null}

        {isTimeOffModalOpen ? (
          <TimeOffModal
            handleClose={handleCloseTimeOffModal}
            isModalOpen={isTimeOffModalOpen}
            initData={editTimeOffInitData}
            handleSubmit={async (payload) => {
              const res = await handleAddTimeOff?.(payload);
              if (res) {
                handleCloseTimeOffModal();
              }
            }}
            modalType={TimeOffModalType.UPDATE}
          />
        ) : null}
      </TimeScheduleTableStyled>
    </Spin>
  );
};

const TimeScheduleTableStyled = styled.div`
  .ant-table-cell {
    vertical-align: middle;
  }
  .table-subheader {
    color: #949bda;
    font-size: 14px;
    font-weight: 400;
  }
  .table-header {
    text-align: center;
  }
  .time-schedule-value {
    text-align: center;
  }
  .time-schedule-value-off {
    color: #949bda;
    font-size: 16px;
    font-weight: 500;
  }
  .time-schedule-value-edit {
    cursor: pointer;
  }

  .ant-table-cell {
    position: relative;
  }

  .wrap-edit-value {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(54, 53, 101, 0.4);
    backdrop-filter: blur(4px);
    cursor: pointer;
    width: 100%;

    svg {
      margin-left: -13px;
    }
  }

  .wrap-value-none {
    display: none;
  }

  @media (max-width: 1180px) {
    .time-schedule-value-edit {
      white-space: nowrap;
    }

    .schedule-table-row {
      width: 160px;
    }
  }
`;

export default TimeScheduleTable;
