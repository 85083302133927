const IconFilter = () => {
  return (
    <svg
      width='42'
      height='42'
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='42' height='42' rx='6' fill='white' />
      <path
        d='M30 16H27M12 16H21'
        stroke='#363565'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M24 19C25.6569 19 27 17.6569 27 16C27 14.3431 25.6569 13 24 13C22.3431 13 21 14.3431 21 16C21 17.6569 22.3431 19 24 19Z'
        stroke='#363565'
        strokeWidth='1.5'
      />
      <path
        d='M30 26H21M12 26H15'
        stroke='#363565'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M18 29C19.6569 29 21 27.6569 21 26C21 24.3431 19.6569 23 18 23C16.3431 23 15 24.3431 15 26C15 27.6569 16.3431 29 18 29Z'
        stroke='#363565'
        strokeWidth='1.5'
      />
    </svg>
  );
};

export default IconFilter;
