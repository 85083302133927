import { Form, SwitchProps } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import FormSwitch from 'components/common/Form/FormSwitch';

interface Props extends SwitchProps {
    form?: FormInstance;
    label?: string;
    name?: string;
}



const SwitchInput = ({
    label = '',
    name = '',
    form = Form.useFormInstance(),
    ...props
}: Props) => {
    return (
        <Form.Item valuePropName='checked' initialValue={ form?.getFieldValue(name) ?? true } name={name} noStyle>
            <FormSwitch {...props} label={label} defaultChecked={ form?.getFieldValue(name) } />
        </Form.Item>
    );
};

export default SwitchInput;