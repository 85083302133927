import { EBookingStatus } from 'constants/index';
import { first } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { TeamMemberItemStyled } from '../UITeamMemberItem';
import { BookingItem } from '../types';
import UIBookingItemStyled from './styles';
import bookingSelectors from 'features/bookings/services/selectors';

type Props = {
  col_id?: string;
  data: BookingItem;
  isChildMultiple?: boolean;
};




const UIBookingItemSimple = ({ data, isMemberView }: Props & { isMemberView?: boolean }) => {
  const pixelPerMinute = bookingSelectors.getPixelPerMinute();
  if (!data) return null;
  const {
    customer,
    time_start,
    time_end,
    book_status,
    book_assignment_services = [],
  } = data;
  const customerName = book_status === EBookingStatus.DRAFT ? 'Draft booking' : customer?.name ?? '';
  const [isExpanded, setIsExpanded] = useState(false);

  const onClickExpand: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const getBody = () => {
    return (
      <div className='item-container'>
        <div className='item-inner'>
          <p className='item-title'> {customerName}</p>
          <p className='item-time'>
            {moment(time_start).format('HH:mm')} -{' '}
            {moment(time_end).format('HH:mm')}
          </p>
          <div className={`services ${isExpanded ? 'is-expanded' : ''}`}>
            {book_assignment_services.length > 0 ? (
              <>
                {book_assignment_services.map((ser) => (
                  <div key={ser.id + '_' + data.id} className='service-item'>
                    <div
                      className='circle'
                      style={{ backgroundColor: ser.category_service_color }}
                    />
                    <span className='name'>{ser.service_name}</span>
                  </div>
                ))}
                {book_assignment_services.length > 0 && (
                  <button className='expanded-btn' onClick={onClickExpand}>
                    <span>Close</span>
                  </button>
                )}
              </>
            ) : (
              <div className='service-item'>
                <span className='name'>No services</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  if (isMemberView) {
    const service = first(book_assignment_services);
    return (
      <TeamMemberItemStyled distance={(service?.duration_time ?? 0) * (service?.quantity ?? 0)} pixelPerMinute={pixelPerMinute} >
        <UIBookingItemStyled
          bookStatus={book_status}
          className={'memberView'}
        >{getBody()}</UIBookingItemStyled>
      </TeamMemberItemStyled>

    );
  }
  return (
    <UIBookingItemStyled
      bookStatus={book_status}
    >
      {getBody()}
    </UIBookingItemStyled>
  );
};

export default UIBookingItemSimple;
