import { Form, Input, Switch } from 'antd';
import PickerColor from 'components/PickerColor';
import MultipleSelect from 'components/common/MultipleSelect';
import locationSelectors from 'features/locations/services/selectors';
import { IApiCategoryBodyReq, ICategoryFormValue } from 'features/sales/services/types/category';
import { get } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
export interface ICategoryFormProps {
  onClose?: () => void;
  isEdit?: boolean;
  formData?: ICategoryFormValue;
  onSubmit?: (payload: IApiCategoryBodyReq) => Promise<void>;
  errors?: Record<string, string> | null;
}

const CategoryForm: React.FC<ICategoryFormProps> = ({
  onClose = () => undefined,
  onSubmit = () => undefined,
  formData = null,
  isEdit = false,
  errors = null,
}) => {
  const [form] = Form.useForm();
  const [defaultColorCode, setDefaultColor] = useState<string | undefined>();

  const locationOptions = (locationSelectors.getPaginateListLocations()?.data ?? []).map((o: any) => ({
    value: o.id,
    label: o.name
  }));

  useEffect(() => {
    if (!isEdit) return;
    form.setFieldsValue(formData);
    setDefaultColor(formData?.color);
  }, [formData]);

  const getPayload = (values: ICategoryFormValue) => {
    return {
      name: values.name,
      color_id: Number(values.color),
      status: values.enable,
      location_ids: values.location,
    };
  };

  const checkValid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };

  const submit = async () => {
    if (!await checkValid()) return;
    form.submit();
  };

  const onFinish = (values: ICategoryFormValue) => {
    const payload = getPayload(values);
    if (onSubmit) onSubmit(payload);
  };

  const handleCancel = () => onClose();

  const errorsText = useMemo(() => {
    return ({
      name: get(errors, 'name', ''),
      color: get(errors, 'color', ''),
    });
  }, [errors]);

  const myFocus = useRef<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);

  return (
    <CategoryFormStyled>
      <Form
        className='modal-form'
        autoComplete='off'
        form={form}
        layout='vertical'
        onKeyDown={(e) => {
          if(e.key === 'Enter') {
            submit();
          }
        }}
        initialValues={{
          enable: true,
        }}
        onFinish={onFinish}
      >
        <div className='form-row'>
          <Form.Item
            label='Category name'
            name='name'
            validateStatus={errorsText?.name ? 'error' : undefined}
            help={errorsText?.name ? errorsText?.name : undefined}
            rules={[{ required: true, }]}
          >
            <Input ref={myFocus} autoFocus placeholder='Enter category name' />
          </Form.Item>
        </div>
        <div className="form-row">
          <Form.Item
            label='Choose color'
            name='color'
            validateStatus={errorsText?.color ? 'error' : undefined}
            help={errorsText?.color ? errorsText?.color : undefined}
            rules={[{ required: true, }]}
          >
            <PickerColor useSelect defaultValue={defaultColorCode} />
          </Form.Item>
        </div>
        <div className='form-row'>
          <Form.Item label='Location Applied' name='location'
          >
            <MultipleSelect
              onChange={() => undefined}
              value={[]}
              options={locationOptions}
              maxTagPlaceholder='location'
              placeholder='Select location'
            />
          </Form.Item>
        </div>
        <div className='form-row'>
          <p className='form-label'>Enable category</p>
          <Form.Item noStyle name={'enable'} valuePropName='checked'>
            <Switch defaultChecked />
          </Form.Item>
        </div>
        <div className='form-submit-wrapper'>
          <div onClick={handleCancel} className='common-btn is-white'>
            Cancel
          </div>
          <button type='button' onClick={submit} className='common-btn'>
            Save
          </button>

        </div>
      </Form>
    </CategoryFormStyled >
  );
};

export default CategoryForm;
const CategoryFormStyled = styled.div``;
