import { CopyOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { AxiosResponse } from 'axios';
import SwitchInput from 'components/common/Input/SwitchInput';
import message from 'components/common/Message';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import storage from 'utils/sessionStorage';
import settingActions from '../services/actions';
import settingApis from '../services/apis';
import settingSelectors from '../services/selectors';
import FormTabSetting from './FormTabSetting';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

interface ICustomerLink {
  fullDomain: string;
  checkinQR: boolean;
  selectServive: boolean;
  online_promotionText: string;
  online_headerText: string;
  online_descriptionText: string;
  checkIn_promotionText: string;
  checkIn_headerText: string;
  checkIn_descriptionText: string;
  viewBirthday?: boolean;
  urlView?: string;
  isPromotion?: boolean;
}

const CustomerLinksTab = () => {

  const getSetting = settingSelectors.getSetting();

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    form.setFieldsValue(getInitValues());
  }, [getSetting]);

  const getInitValues = () => {
    const data = getSetting?.customer_link;
    const merchantCode: string = storage.merchantCode.get();
    const merchantName: string = storage.merchantName.get();

    return {
      fullDomain: `${window.location.href.replace(location?.pathname, '')}/${merchantName.replaceAll(' ','-')}/booking-form/${merchantCode}`.toLowerCase(),
      checkinQR: data?.checkin_qr,
      selectServive: data?.customer_services,
      online_promotionText: getSetting?.booking_online_text?.promotion_text,
      online_headerText: getSetting?.booking_online_text?.header_text,
      online_descriptionText: getSetting?.booking_online_text?.description_text,
      checkIn_promotionText: getSetting?.checkin_text?.promotion_text,
      checkIn_headerText: getSetting?.checkin_text?.header_text,
      checkIn_descriptionText: getSetting?.checkin_text?.description_text,
      viewBirthday: !!getSetting?.booking?.view_birthday,
      urlView: getSetting?.review?.url_review,
      isPromotion: data?.is_promotion
    };
  };

  const getPayload = (values: ICustomerLink) => {
    return {
      checkin_qr: values?.checkinQR,
      customer_services: values?.selectServive,
      promotion_text_booking: values?.online_promotionText,
      herder_text_booking: values?.online_headerText,
      description_text_booking: values?.online_descriptionText,
      promotion_text_checkin: values?.checkIn_promotionText,
      herder_text_checkin: values?.checkIn_headerText,
      description_text_checkin: values?.checkIn_descriptionText,
      view_birthday: !!values?.viewBirthday,
      url_review: values?.urlView,
      is_promotion: values?.isPromotion
    };
  };

  const handleSubmit = async (values: ICustomerLink) => {

    const payload = getPayload(values);
    try {
      const rest: AxiosResponse<{ message: string }> = await settingApis.updateCustomerLink(payload);

      const msg = get(rest, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(settingActions.getSetting.fetch());
        form.setFieldsValue(getInitValues());
        return true;
      }
      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });

      return false;
    }
  };

  const checkValid = async () => {
    try {
      await form.validateFields([
        // 'phoneNumber',
        // 'name',
        // 'email',
        // 'service',
        // 'bookingDate',
        // 'bookingTime',
      ]);
      return true;
    } catch (error) {
      return false;
    }
  };

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return await document.execCommand('copy', true, text);
    }
  };

  const handleCopy = async (text: string) => {
    try {
      await copyTextToClipboard(text);
      message.success('Copied');
    } catch (error) { }
  };

  return (
    <FormTabSetting
      form={form}
      handleSubmit={handleSubmit}
      checkValid={checkValid}
      initialValues={getInitValues()}
    >
      <div className='cards-wrapper'>
        <div className='cards-col'>
          <div className='common-card'>
            <div className='card-title'>
              <p>Booking Online</p>
            </div>
            <div className='card-body'>
              <p className='form-note'>
                Link which customers can use to make online bookings.
              </p>
              <Form.Item name='fullDomain'>
                <Input className='is-blur' id="myInput" disabled suffix={<Button onClick={() => handleCopy(getInitValues().fullDomain)} icon={<CopyOutlined />} />}></Input>
              </Form.Item>

              <div className='form-row'>
                <Form.Item noStyle name='checkinQR' />
                <div>
                  <div className='d-flex' style={{ gap: '10px' }}>
                    <SwitchInput
                      name='isPromotion'
                      label='Enable promotion'
                    />
                  </div>
                  <p className='form-note'>
                    Enable promotion code on booking form
                  </p>
                </div>
              </div>

              <Form.Item name='urlView' label='Google review URL'>
                <Input className='is-blur' id="myInput" suffix={<Button onClick={() => handleCopy(form.getFieldValue('urlView'))} icon={<CopyOutlined />} />}></Input>
              </Form.Item>

              <Form.Item label={'Promotion text'} name='online_promotionText'>
                <Input />
              </Form.Item>
              <Form.Item label={'Header text'} name='online_headerText'>
                <Input />
              </Form.Item>
              <Form.Item label={'Description text'} name='online_descriptionText'>
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className='common-card'>
            <h2 className='card-title'>Check In</h2>
            <div className='card-body'>
              <div className='form-row'>
                <Form.Item noStyle name='checkinQR' />
                <div>
                  <div className='d-flex' style={{ gap: '10px' }}>
                    <SwitchInput
                      name='selectServive'
                      label='Select services'
                    />
                  </div>
                  <p className='form-note'>
                    Customers can choose services on the booking online.
                  </p>
                </div>
              </div>
              <Form.Item label={'Promotion text'} name='checkIn_promotionText'>
                <Input />
              </Form.Item>
              <Form.Item label={'Header text'} name='checkIn_headerText'>
                <Input />
              </Form.Item>
              <Form.Item label={'Description text'} name='checkIn_descriptionText'>
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className='common-card'>
            <h2 className='card-title'>Configs</h2>
            <div className='card-body'>
              <div>
                <SwitchInput
                  label='Enable show input birthday'
                  name='viewBirthday'
                />
                <p className='form-note'>
                  Allow entering input date of birth on the checkin and booking form page
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormTabSetting>
  );
};

export default CustomerLinksTab;
