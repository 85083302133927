import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import uiSelector from 'services/UI/selectors';
import { PATH_LOADING } from './constants';
import { IApiServiceParams } from './types/services';
import { IApiCategoryParams, ICategoryResItem } from './types/category';
import { IPagination } from 'models/config';


type MyState = RootState['service'];

const getCurrentState = (state: RootState): MyState => state.service;

export const getPureParams = (root: RootState) => {
  const state = getCurrentState(root);
  return state.params ?? {} as IApiServiceParams;
};

const selector = <Modal = keyof MyState>(key: Modal, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key as any, defaultValue));

const getPaginateListServices = () => selector('listPaginateService') as MyState['listPaginateService'];

const loadingStaffPaginateList = () => uiSelector.getLoading(PATH_LOADING.getListPaginateServices);

const getShowCreateService = () => selector('showCreateService') as MyState['showCreateService'];

const loadingShowCreateService = () => uiSelector.getLoading(PATH_LOADING.getShowCreateService);

const getServiceDetail = () => selector('serviceDetail') as (any);

const getDurations = () => selector('durations') as { label: string, value: number }[];

const getColorCategory = () => selector('showCreateService.colors');

const getParams = () => selector('params') as IApiServiceParams;

export const getPureCategoryParams = (root: RootState) => {
  const state = getCurrentState(root);
  return state.category.params ?? {} as IApiCategoryParams;
};

const category = {
  getParams: () => selector('category.params') as IApiCategoryParams,
  getData: () => selector('category.data') as ICategoryResItem[],
  getLoading: () => uiSelector.getLoading(PATH_LOADING.getCategoryData) as boolean,
  getPagination: () => selector('category.pagination') as (IPagination | null),
};

const serviceSelectors = {
  getPaginateListServices,
  loadingStaffPaginateList,
  getShowCreateService,
  loadingShowCreateService,
  getServiceDetail,
  getDurations,
  getColorCategory,
  getParams,
  category,
};
export default serviceSelectors;
