import { createAction, createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IApiReviewParam } from './types/review';


const getListReview = createAsyncAction<IApiReviewParam>(PREFIX_ACTIONS + 'GET_LIST_REVIEW');

const getDetailReview = createAsyncAction<string>(PREFIX_ACTIONS + 'GET_DETAIL_REVIEW');

const clearDetail = createAction(PREFIX_ACTIONS + 'CLEAR_DETAIL');

const setReviewParams = createAction<IApiReviewParam>(PREFIX_ACTIONS + 'SET_REVIEW_PARAMS');


const reviewActions = {
  getListReview,
  getDetailReview,
  setReviewParams,
  clearDetail,
};

export default reviewActions;
