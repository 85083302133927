import { AxiosResponse } from 'axios';
import { IVoucherItemResData, IVoucherListResData } from 'features/vouchers/services/types/voucher';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { setLoading } from 'services/UI/sagas';
import actions from './actions';
import apis from './apis';
import { PATH_LOADING } from './constants';
import { IServiceConfigsResData } from './types/service';
import { ISagaFunc } from 'services/actionConfigs';
import voucherActions from 'features/vouchers/services/actions';
import { getMerchantLocationIdPure } from './selectors';
import { ICustomerDetailResData } from 'features/customers/services/types/cutomer';
import apisCustomer from 'features/customers/services/apis';
const getConfigData = function* () {
  yield setLoading(PATH_LOADING.getConfigData, true);
  try {
    const res: AxiosResponse<{ data: IServiceConfigsResData }> = yield call(apis.getServiceConfigs);
    if (res?.data?.data) {
      yield put(actions.getConfigData.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.getConfigData.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getConfigData, false);
  }
};

const getVouchersList = function* () {
  yield setLoading(PATH_LOADING.getVouchersList, true);
  try {
    const res: AxiosResponse<IVoucherListResData> = yield call(apis.getListVouchers);
    if (res?.data?.data) {
      yield put(actions.getVouchersList.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.getVouchersList.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getVouchersList, false);
  }
};

const fetchConfigsData = function* () {
  yield all([
    put(actions.getConfigData.fetch()),
    put(actions.getVouchersList.fetch()),
    put(voucherActions.getLstPeriodOpts.fetch()),
    put(voucherActions.getAllServices.fetch()),
  ]);
};

const getVoucherOfCustomer: ISagaFunc<{ id: string }> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getVoucherOfCustomer, true);
  const { id } = payload;

  try {
    const res: AxiosResponse<{ data: IVoucherItemResData[] }> = yield call(apis.getVoucherOfCustomer, id);
    if (res?.data?.data) {
      yield put(actions.getVoucherOfCustomer.success(res?.data?.data));
    }
  } catch (error) {
    yield put(actions.getVoucherOfCustomer.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getVoucherOfCustomer, false);
  }
};

const reviewPointCustomerWillReceive: ISagaFunc<number> = function* ({ payload }) {
  try {
    const merchant_location_id = yield select(getMerchantLocationIdPure);
    if (merchant_location_id === null || merchant_location_id === undefined) return;

    const res = yield call(apis.reviewPointCustomerWillReceive, payload, merchant_location_id);
    if (res.data.data) {
      yield put(actions.reviewPointCustomerWillReceive.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.reviewPointCustomerWillReceive.fail({}));
  }
};

const getRewardOfCustomer: ISagaFunc<any> = function* ({ payload }) {
  const { id } = payload;

  yield setLoading(PATH_LOADING.getRewardOfCustomer, true);
  try {
    const pointEnough = yield call(apis.getRewardOfCustomer, id, { view: 'point_enough' });    
    const pointLeft = yield call(apis.getRewardOfCustomer, id, { view: 'point_left' });

    yield put(actions.getRewardOfCustomer.success({ pointLeft: pointLeft?.data?.data, pointEnough: pointEnough?.data?.data }));
  } catch (error) {
    yield put(actions.getRewardOfCustomer.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getRewardOfCustomer, false);
  }
};

const getTotalPointCustomer: ISagaFunc<{ is_walkin_in: boolean, customer_code: string }> = function* ({ payload }) {
  const param = payload;
  try {
    const res: AxiosResponse<{ data: ICustomerDetailResData }> = yield call(apisCustomer.getCustomerDetail, param);
    
    if (res?.data?.data) {
      const customerDetail = res.data.data;      
      yield put(actions.getTotalPointCustomer.success(customerDetail.loyalty_point));
    } else {
      throw 'fail';
    }
  } catch (error) {
  }
};

export default function* salesServiceSaga() {
  yield takeLatest(actions.getConfigData.fetch, getConfigData);
  yield takeLatest(actions.getVouchersList.fetch, getVouchersList);
  yield takeLatest(actions.fetchConfigsData, fetchConfigsData);
  yield takeLatest(actions.getVoucherOfCustomer.fetch, getVoucherOfCustomer);
  yield takeLatest(actions.reviewPointCustomerWillReceive.fetch, reviewPointCustomerWillReceive);
  yield takeLatest(actions.getRewardOfCustomer.fetch, getRewardOfCustomer);
  yield takeLatest(actions.getTotalPointCustomer.fetch, getTotalPointCustomer);
}
