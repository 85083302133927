export const NAME_REDUCER = 'workingHour';

export const PREFIX_ACTIONS = 'WORKING_HOUR_FEATURE_';

export const PATH_LOADING = {
    getData: `loading.${NAME_REDUCER}.getData`,
    getDetailData: `loading.${NAME_REDUCER}.getDetailData`,
};

export const TypeDetailView = {
    // MembersView: 'member',
    DailyView: 'daily',
    WeeklyView: 'weekly',
    CustomView: 'custom',
    OptionView: 'option'
};