import message from 'components/common/Message';
import _, { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import apisBooking from '../services/apis';
import QuickBookingPage from './QuickBookingPage';
import moment from 'moment';

const EditBookingPage = () => {
  const search = useLocation().search;
  const bookId = new URLSearchParams(search).get('bookId') || '';

  const [bookingData, setBookingData] = useState();

  const getBookingData = async () => {
    try {
      const response = await apisBooking.findBookingById(bookId);
      const error = get(response, 'data.error.message');
      if (error) {
        message.error(error);
      } else if (response.data.data) {
        setBookingData({
          ...response.data.data,
          time_start: moment(response.data.data.time_start, 'YYYY-MM-DD HH:mm:ss').format()
        });
      } else {
        throw 'fail';
      }
    } catch (error) {
      message.error();
    }
  };

  useEffect(() => {
    getBookingData();
  }, [bookId]);

  return (
    <div>
      {!_.isEmpty(bookingData) && (
        <QuickBookingPage bookId={bookId}  bookingData={bookingData} />
      )}
    </div>
  );
};

export default EditBookingPage;
