import { get } from 'lodash';
import uiSelector from 'services/UI/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { PATH_LOADING } from './constants';
import { IServiceChargeDetailResData } from './types/serviceCharge';
import { IHolidayRes } from './types/holiday';


type MyState = RootState['setting'];

const getCurrentState = (state: RootState): MyState => state.setting;

const selector = (key: keyof MyState, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getSetting = () => selector('listSetting') as MyState['listSetting'];

const getPin = () => selector('listSetting.general.pin_number') as string;

const getIsRequirePin = () => selector('listSetting.general.is_pin_number') as boolean;

const loadSetting = () => uiSelector.getLoading(PATH_LOADING.getSetting);

const getSettingBookingForm = () => selector('listSettingBookingForm');

const getClosedDateBookingForm = () => selector('listSettingBookingForm.merchant_closed');

const loadSettingBookingForm = () => uiSelector.getLoading(PATH_LOADING.getSettingBookingForm);

const getOptionBooking = () => selector('listOptionBooking') as (any | null);

const loadOptionBooking = () => uiSelector.getLoading(PATH_LOADING.getOpionBooking);

const getListClosedDate = () => selector('listClosedDate') as (any | null);

const loadListClosedDate = () => uiSelector.getLoading(PATH_LOADING.getListClosedDate);

const getListCancellationReason = () => selector('listCancellationReason') as (any | null);

const loadListCancellationReason = () => uiSelector.getLoading(PATH_LOADING.getListCancellationReason);

const getNumberOfNotification = () => selector('listNumberOfNotification') as MyState['listNumberOfNotification'];

const getListServiceCharge = () => selector('listServiceCharge') as (any | null);

const loadListServiceCharge = () => uiSelector.getLoading(PATH_LOADING.getListServiceCharge);

const getDetailServiceCharge = () => selector('reviewDetail') as IServiceChargeDetailResData;

const getLoadingDetailServciceCharge = () => uiSelector.getLoading(PATH_LOADING.getDetailServiceCharge) as boolean;

const getShowServiceCharge = () => selector('getShowServiceCharge') as (any | null);

const loadShowServiceCharge = () => uiSelector.getLoading(PATH_LOADING.getShowServiceCharge);

const getTopUpHistory = () => selector('listTopUpHistory') as (any | null);

const loadTopUpHistory = () => uiSelector.getLoading(PATH_LOADING.getTopUpHistory);

const getTopUpSMSHistory = () => selector('listTopUpSMSHistory') as (any | null);

const loadTopUpSMSHistory = () => uiSelector.getLoading(PATH_LOADING.getTopUpSMSHistory);

const getMerchantStripeBalance = () => selector('merchantStripeBalance') as (any | null);

const loadMerchantBalance = () => uiSelector.getLoading(PATH_LOADING.getMerchantStripeBalance);


const getHolidayInfoToday = () => selector('holidayInfoToday') as IHolidayRes;

const settingSelectors = {
  getPin,
  getSetting,
  loadSetting,
  getOptionBooking,
  loadOptionBooking,
  getListClosedDate,
  loadListClosedDate,
  getNumberOfNotification,
  getSettingBookingForm,
  loadSettingBookingForm,
  getClosedDateBookingForm,
  getIsRequirePin,
  getListCancellationReason,
  loadListCancellationReason,

  getListServiceCharge,
  loadListServiceCharge,
  getDetailServiceCharge,
  getLoadingDetailServciceCharge,
  getShowServiceCharge,
  loadShowServiceCharge,

  getTopUpHistory,
  loadTopUpHistory,
  getTopUpSMSHistory,
  loadTopUpSMSHistory,
  getMerchantStripeBalance,
  loadMerchantBalance,
  getHolidayInfoToday
};
export default settingSelectors;
