import auth from './auth';
import customer from './customers';
import booking from './booking';
import bigLogo from './big-logo.png';
import success from './success.png';
const images = {
  auth,
  customer,
  booking,
  bigLogo,
  success,
};

export default images;
