import { Form, FormInstance, Progress } from 'antd';
import styles from '../index.module.scss';
import LocationsFormFirstStep from './LocationsFormFirstStep';
import React, { useEffect, useState } from 'react';
import LocationsFormSecondStep from './LocationsFormSecondStep';
import LocationsFormThirdStep from './LocationsFormThirdStep';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import userActions from 'features/users/services/actions';
import { dayOfWeek } from 'features/users/widgets/ModalUserForm/FouthStep';
import dayjs from 'utils/dayjs';

interface LocationFormProps {
  form: FormInstance,
  handleSubmit?: (values: any) => Promise<boolean>,
}

const LocationForm = ({
  form,
  handleSubmit,
}: LocationFormProps) => {
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.getListPaginateStaffs.fetch({ per_page: 9999 }));
  }, []);

  const _onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    // const isFormValid = await checkValid();
    // if (!isFormValid) return;
    form.submit();
  };

  const onNext = async () => {

    let checkValid = true;

    switch(page) {
      case 1:
        checkValid = await form.validateFields([
          'locationName',
          'address',
          'locationPhoneNumber',
        ]);
        break;
      case 2:
        checkValid = await form.validateFields([
          'manager'
        ]);
        break;
      default:
        checkValid = true;
    }
    if(! checkValid)
      return;
    setPage((currPage) => currPage + 1);
  };

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={handleSubmit}
      initialValues={{
        workingTime: dayOfWeek.reduce(
          (obj, item) => Object.assign(obj, {
            [item.value]: [{
              time_start: dayjs('09:00:00', 'H:m:s'),
              time_end: dayjs('18:00:00', 'H:m:s'),
              weekday: item.value
            }]
          }), {})
      }}
    >
      <div className={styles.add_new_page}>
        <div className={styles.progress_bar}>
          <Progress
            percent={page === 1 ? 33 : page === 2 ? 66 : 100}
            showInfo={false}
          />
          <p
            className={styles.progress_text}
            style={{
              left: `${page === 1 ? '33%' : page === 2 ? '66%' : '100%'}`,
              paddingRight: `${page === 3 ? '4px' : '0'}`,
            }}
          >
            {page === 1 ? 'BASICS' : page === 2 ? 'CONTACT' : 'ACTIVE HOURS'}
          </p>
        </div>
        <div className={styles.form_inner}>
          <>
            <div style={page !== 1 ? { display: 'none' } : undefined}>
              <LocationsFormFirstStep />
            </div>
            <div style={page !== 2 ? { display: 'none' } : undefined}>
              <LocationsFormSecondStep form={form} />
            </div>
            <div style={page !== 3 ? { display: 'none' } : undefined}>
              <LocationsFormThirdStep form={form} />
            </div>
          </>
        </div>
        <div className={styles.form_bottom}>
          {page === 1 ? (
            <Link to='/private/locations' className='common-btn is-white'>
              Cancel
            </Link>
          ) : (
            <div
              onClick={() => setPage((currPage) => currPage - 1)}
              className='common-btn is-white'
            >
              Back
            </div>
          )}
          {page !== 3 ? (
            <div
              onClick={onNext}
              className='common-btn'
            >
              Continue
            </div>
          ) : (
            <button type='submit' className='common-btn' onClick={_onSubmit}>
              Save
            </button>
          )}
        </div>
        {/* <Progress percent={50} showInfo={false} />; */}
      </div>
    </Form>
  );
};

export default LocationForm;