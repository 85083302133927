import { setLoading } from 'services/UI/sagas';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PATH_LOADING } from './constants';
import { INotificationItemResData } from './types/notification';
import { AxiosResponse } from 'axios';
import { default as apis } from './apis';
import actions from './actions';

const getListNotification = function* ({ payload }: any) {    
    try {
        yield setLoading(PATH_LOADING.getListNotification, true);
        const res: AxiosResponse<{ data: INotificationItemResData[] }> = yield call(
            apis.getListNotification,
            payload
        );
        if (res?.data?.data) {
            const notificationList = res?.data?.data;
            yield put(actions.getListNotification.success(notificationList));
        } else {
            throw 'fail';
        }
    } catch (error) {
        yield put(actions.getListNotification.fail({}));
    } finally {
        yield setLoading(PATH_LOADING.getListNotification, false);
    }
};

export default function* notificationServiceSaga() {
    yield takeLatest(actions.getListNotification.fetch, getListNotification);
}
