import RewardItem from 'features/checkout/components/RewardList/Item';
import { IRewardItemResData } from 'features/loyaltyProgram/services/types/reward';
import salesActions from 'features/sales/services/actions';
import salesSelectors from 'features/sales/services/selectors';
import { useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

const RewardList = () => {
  const dispatch = useAppDispatch();
  const selectedReward = salesSelectors.getSelectedReward();
  const customer = salesSelectors.getCustomer();
  const selectedService = salesSelectors.getSelectedServices();
  const amount = salesSelectors.getOriginTotalPrice();
  const voucherValue = salesSelectors.getVoucherValue() ?? 0;
  const rewards = salesSelectors.getRewardOfCustomer();

  const isDisable = useMemo(() => {
    return amount - voucherValue === 0;
  }, [amount, voucherValue]);

  const rewardAvailable = useMemo(() => rewards.filter((o: any) => {
    if(o.category !== 'free_service') return true;
    for(const service of selectedService) {
      const haveServiceReward = o.free_service.find((item: any) => {
        return service.service_variant_id === undefined || service.service_variant_id === null ? item.id === service.id : item.id === service.id && item.extend_services.find((i: number) => i === service.service_variant_id);
      });
      
      if(haveServiceReward)
        return true;
    }
  }), [rewards]);

  const onChooseReward = (o: IRewardItemResData) => {
    
    if (selectedReward?.id !== o.id) {
      dispatch(salesActions.updateCusInfo.success({
        reward_info: {
          point_left_to_earn_a_reward: (customer?.reward_info?.current_point ?? 0) < o?.points_to_earn_reward ? o?.points_to_earn_reward - (customer?.reward_info?.current_point ?? 0) : 0,
          current_point: customer?.reward_info?.current_point
        }
      }));
      return dispatch(salesActions.setSelectedReward(o));
    }
    dispatch(salesActions.setSelectedReward(null));
    dispatch(salesActions.updateCusInfo.success({
      reward_info: {
        point_left_to_earn_a_reward: 0,
        current_point: customer?.reward_info?.current_point
      }
    }));
  };

  return (
    <RewardListStyled>
      {rewardAvailable.map((o: any) => (<RewardItem
        disabled={isDisable}
        key={o.id}
        data={o}
        active={o.id === selectedReward?.id}
        onClick={() => onChooseReward(o)}
      />))}
    </RewardListStyled>
  );
};

export default RewardList;

const RewardListStyled = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 8px;
  gap:12px;

`;