import { EBookingStatus } from 'constants/index';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import { get } from 'lodash';
import moment, { Moment } from 'moment';
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);


export const dateFormat = 'DD.MM.YYYY';

export const dateFormatPlaceholder = 'DD.MM.YYYY';

export const timeFormat = 'HH:mm';

export const formOptionalText = '(Optional)';

export const valueAllOptions = 99999;

export const HOURS_OF_DAY = 24;

export const MINUTES_OF_DAY = 60;

export const MINUTE_ROUNDED = 5;

export const ANONYMOUS_NAME = 'Any team member';

export const CUSTOMER_WALKIN_INDEX = 2;

export enum EPromotionType {
  PRICE = 'price',
  PERCENT = 'percent'
}

const getHours = (hours: number) => {
  if (hours !== 0) {
    if (hours === 1) return hours + ' hr';
    return hours + ' hrs';
  }
  return '';
};

const getMinutes = (minutes: number) => {
  if (minutes !== 0) {
    if (minutes === 1) return minutes + ' min';
    return minutes + ' mins';
  }
  return '';
};

export const formatTimeMinutes = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);

  const minutes = totalMinutes % 60;

  return `${getHours(hours)} ${getMinutes(minutes)}`;
};

export const parseDurationMinute = (duration_time_text: string) => {
  const arr = duration_time_text.split(':') ?? [];
  const hour = +get(arr, [0], '');
  const minute = +get(arr, [1], '');

  const durationMinute = hour * 60 + minute;
  return formatTimeMinutes(durationMinute);
};

export const disabledDateSameOrBefore = (current: Dayjs, from: Dayjs) => {
  return current?.isSameOrBefore(from?.format('YYYY-MM-DD'));
};

export const formatDurationTime = (duration: number) => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  return `${hours === 0 ? '' : hours > 1 ? `${hours} hrs` : `${hours} hr`} ${minutes > 0 ? `${minutes} mins` : ''}`;
};

export const tagStatusColor = (status: string) => {
  switch (status.toLocaleLowerCase()) {
    case 'open':
      return '#363565';
    case 'cancelled':
      return '#F5222D';
    case 'in_progress':
      return '#FAAD14';
    case 'completed':
      return '#4A9D77';
    case 'no_show':
      return '#C3C2E0';
    case 'multiple':
      return '#FADB14';
    case 'active':
      return '#4A9D77';
    case 'disable':
      return '#D0D0D0';
    case 'draft':
      return '#C4C4C4';
    default:
      return;
  }
};

export const roundNumber = (number: number) => {
  return Math.round(number * 100) / 100;
};

export const tagStatusBackgroundColor = (status: string) => {
  switch (status.toLocaleLowerCase()) {
    case 'open':
      return '#F4FFFA';
    case 'cancelled':
      return '#FFF1F0';
    case 'in_progress':
      return '#F4FFFA';
    case 'completed':
      return '#4A9D77';
    case 'no_show':
      return '#F4FFFA';
    case 'multiple':
      return '#F4FFFA';
    case 'active':
      return '#F4FFFA';
    case 'disable':
      return '#F4FFFA';
    case 'draft':
      return '#F4FFFA';
    default:
      return;
  }
};

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export const disabledDateTime = () => ({
  disabledHours: () => [...range(0, 6), ...range(18, 24)],
});

export const statusTagBackground = (status: string) => {
  switch (status.toLocaleLowerCase()) {
    case EBookingStatus.OPEN:
      return 'is-light-green';
    case EBookingStatus.IN_PROGRESS:
      return 'is-yellow';
    case EBookingStatus.COMPLETED:
      return 'is-green';
    case EBookingStatus.CANCELLED:
      return 'is-red';
    case EBookingStatus.NO_SHOW:
      return 'is-purple';
    case EBookingStatus.DRAFT:
      return 'is-gray';
    default:
      return;
  }
};

export const formatMoney = (value: number) => {
  return `$${(value ?? 0)?.toFixed(2)}`;
};

export const formatMoneyInput = (value: number) => {
  return `${(value ?? 0)?.toFixed(2)}`;
};

interface IPluralDict {
  singularWord: 'point' | 'service'
  pluralWord: string
}

const pluralDict: IPluralDict[] = [
  {
    singularWord: 'point',
    pluralWord: 'points'
  },
  {
    singularWord: 'service',
    pluralWord: 'services'
  }
];

export const pluralizeWord = (singularWord: IPluralDict['singularWord'], value: number) => {
  return value > 1 ? pluralDict.find(o => o.singularWord === singularWord)?.pluralWord ?? singularWord : singularWord;
};

export const getCashOption = (amount: number) => {
  const valueRound = amount % 10 === 0 ? amount + 10 : Math.floor(amount / 10) * 10 + 10;
    
    return [
      {
        value: amount,
        label: formatMoney(amount)
      },
      {
        value: valueRound,
        label: formatMoney(valueRound)
      },
      {
        value: valueRound + 10,
        label: formatMoney(valueRound + 10)
      },
    ];
};

export const getUniqueTransactionId = () => {
  return `transaction_${moment().format('DDMMYYYY')}`;
};

export function isNumeric(value: any) {
  return /^-?\d+$/.test(value);
}

export const getDeviceId = () => {
  const fingerprint = [];

  // Thu thập thông tin về trình duyệt và hệ điều hành
  fingerprint.push(navigator.userAgent);
  fingerprint.push(navigator.language);
  fingerprint.push(screen.colorDepth);
  fingerprint.push(new Date().getTimezoneOffset());
  fingerprint.push(typeof sessionStorage !== 'undefined');
  fingerprint.push(typeof localStorage !== 'undefined');
  fingerprint.push(typeof indexedDB !== 'undefined');
  
  const hash = btoa(fingerprint.join(''));  

  return hash;
};

export const getValueNotLower0 = (value: number) => {
  return value > 0 ? value : 0;
};



export const getTimeRound5Minute = (time: Moment) => {
  const minute = Math.ceil(time.minute() / MINUTE_ROUNDED) * MINUTE_ROUNDED;
  return time.set({
    minute: minute
  });
};

export function isNaNOrInfinity(variable: any) {
  return !Number.isNaN(variable) || !Number.isFinite(variable);
}

export const checkStatusCanEditBooking = (status: EBookingStatus) => {
  return status !== EBookingStatus.CANCELLED && status !== EBookingStatus.COMPLETED && status !== EBookingStatus.NO_SHOW;
};

export const getDaysBetweenDates = (start: Moment, end: Moment) => {
  const day = start.subtract(1, 'day');
  const dates: Moment[] = [];

  while(day.isBefore(end)) {        
    dates.push(day.add(1, 'day').clone());
  }

  return dates;
};
