import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { setLoading } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import actions from './actions';
import apis from './apis';
import { PATH_LOADING } from './constants';
import { IApiReviewParam } from './types/review';


const getListReview: ISagaFunc<IApiReviewParam> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getListReview, true);
  try {
    const res: AxiosResponse<{ data: any }> = yield call(apis.getListReview, payload);
    if (res?.data) {
      yield put(actions.getListReview.success(res.data));
    }
  } catch (error) {
    yield put(actions.getListReview.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getListReview, false);
  }
};

const getDetailReview: ISagaFunc<string> = function* ({ payload }) {
    yield setLoading(PATH_LOADING.getDetailReview, true);
    try {
      const res: AxiosResponse<{ data: any }> = yield call(apis.getDetailReview, payload);
      if (res?.data?.data) {
        yield put(actions.getDetailReview.success(res.data.data));
      }
    } catch (error) {
      yield put(actions.getDetailReview.fail({}));
    }
    yield setLoading(PATH_LOADING.getDetailReview, false);
  };


export default function* reviewServiceSaga() {
  yield takeLatest(actions.getListReview.fetch, getListReview);
  yield takeLatest(actions.getDetailReview.fetch, getDetailReview);
}

