import RewardResultRow from 'features/checkout/components/RewardList/ResultRow';
import checkoutSelectors from 'features/checkout/services/selectors';
import styled from 'styled-components';
import PromotionRow from './Promotion';
import SubTotalAmount from './SubTotalAmount';
import ExtraFee from 'features/checkout/components/ExtraFee';
import DiscountFee from 'features/checkout/components/DiscountFee';
import VoucherResultRow from './VoucherPayment';
import CashPaymentResultRow from './CashPayment';
import CardResultRow from './CardPayment';
import HolidaySurchargeResultRow from './HolidaySurchageResultRow';

const DetailAmountRow = ({ step }: { step: number }) => {
  const extraFeeValue = checkoutSelectors.getExtraFeeValue();
  const discountFeeValue = checkoutSelectors.getDiscountFeeValue();
  const selectedService = checkoutSelectors.getSelectedServices();  

  
  
  // const AmountWithPaymentRow = () => {
  //   switch (method) {
  //     case EPaymentMethod.VOUCHER: return <VoucherResultRow />;
  //     case EPaymentMethod.MIX: return <MixPaymentResultRow />;
  //     case EPaymentMethod.CASH: return <CashPaymentResultRow />;
  //     case EPaymentMethod.CARD: return <CardPaymentResultRow />;
  //     default: return null;
  //   }
  // };

  const isHiddenFee = (value: number) => {
    return selectedService.length === 0 || (step === 3 && value === 0);
  };

  return (
    <DetailAmountRowStyled>
      <SubTotalAmount />
      {isHiddenFee(extraFeeValue) ? <></> : <ExtraFee viewOnly={step === 3} />}
      {isHiddenFee(discountFeeValue) ? <></> : <DiscountFee viewOnly={step === 3} />}
      <RewardResultRow />
      <PromotionRow />
      <HolidaySurchargeResultRow />
      <VoucherResultRow viewOnly={step === 3} />
      <CashPaymentResultRow viewOnly={step === 3} />
      <CardResultRow viewOnly={step === 3} />

      {/* <MixPaymentResultRow/> */}
    </DetailAmountRowStyled>
  );
};

export default DetailAmountRow;

const DetailAmountRowStyled = styled.div`
display: grid;
gap: 8px;

.label {
  display: flex;
  gap: 2px;
  align-items: center;
}
`;

