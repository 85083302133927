import salesSelectors from 'features/sales/services/selectors';
import settingSelectors from 'features/settings/services/selectors';
import { formatMoney } from 'utils/unit';

const HolidaySurchargeResultRow = () => {

  const getHolidaySurchargeValue = salesSelectors.getHolidayValue();
  const getCardSurchargeValue = salesSelectors.getCardSurcharge();
  const publicHoliday = settingSelectors.getHolidayInfoToday();


  return (
    <div>
      {getHolidaySurchargeValue > 0 && <div className='extra-price-row'>
          <div className='label'>
            Public Holiday charge ({publicHoliday.is_each_service ? 'Each service' : 'Total booking'})
          </div>
          <span> +{formatMoney(getHolidaySurchargeValue)}</span>
      </div>}
      {getCardSurchargeValue > 0 && <div className='extra-price-row'>
          <div className='label'>
            Card surcharge
          </div>
          <span> +{formatMoney(getCardSurchargeValue)}</span>
      </div>}
    </div>
  );
};

export default HolidaySurchargeResultRow;
