import { ICategoryFormValue, ICategoryItemResData } from 'features/sales/services/types/category';
import React, { useState, useTransition } from 'react';
import styled from 'styled-components';
import { ICategoryFormProps } from './Form';
import CategoryModal from './Modal';
import serviceSelectors from 'features/services/services/selectors';
import apisService from 'features/services/services/apis';
import { useDispatch } from 'react-redux';
import serviceActions from 'features/services/services/actions';
import message from 'components/common/Message';
import { get } from 'lodash';
type ICategoryEditProps = {
  data: ICategoryItemResData;
};
const CategoryEdit: React.FC<ICategoryEditProps> = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errors] = useState<ICategoryFormProps['errors']>(null);
  const [formData, setFormData] = useState<ICategoryFormValue>();
  const [, startTransition] = useTransition();
  const params = serviceSelectors.category.getParams();
  const dispatch = useDispatch();


  const showModal = () => {
    startTransition(() => {
      setFormData({
        color: data.color,
        name: data.name,
        enable: data.status === 1,
        location: data.location_ids,
      });
      setIsModalOpen(true);
    });
  };

  const hideModal = () => setIsModalOpen(false);



  const handleSubmit: ICategoryFormProps['onSubmit'] = async (payload) => {
    hideModal();
    try {
      const res = await apisService.category.update(Number(data.id), payload);
      const msg = get(res, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(serviceActions.category.getLstData.fetch(params));

      } else {
        throw 'fail';
      }

    } catch (error) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    }
  };

  return (
    <CategoryEditStyled>
      <li className='action-edit' onClick={showModal}></li>
      {isModalOpen && <CategoryModal
        visible={isModalOpen}
        isEdit
        formData={formData}
        onClose={hideModal}
        onSubmit={handleSubmit}
        errors={errors}
      />}
    </CategoryEditStyled>
  );
};

export default CategoryEdit;
const CategoryEditStyled = styled.div``;
