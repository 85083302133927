import fetch from 'services/request';
import { IApiBookingParams, IParamOptionCalendarBody } from './types/booking';
import { IBookAssignmentsResponse } from '../components/BookingForm/types';
import { AxiosResponse } from 'axios';
import { IApiCreateBookingOnlineBody } from './types/bookingOnline';

export interface BaseResponse<T> {
  status: number;
  data: T;
}

const apiGetBookAssignments = (): Promise<
  AxiosResponse<IBookAssignmentsResponse>
> => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/book-assignments/create',
  });
};

const storeBookAssignments = (body: any) => {
  return fetch({
    method: 'post',
    url: 'api/v1/merchants/:merchant_code/book-assignments/store',
    body,
  });
};

const updateBooking = (id: string, body: any) => {
  return fetch({
    method: 'put',
    url: `api/v1/merchants/:merchant_code/book-assignments/${id}`,
    body,
  });
};

const getListBooking = (params: IApiBookingParams) => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/book-assignments',
    params: {
      ...params,
      employee_id: [...params.employee_id as (string | number)[] ?? [], 0]
    } as IApiBookingParams ?? {},
  });
};

const findBookingById = (id: string) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/:merchant_code/book-assignments/${id}`,
  });
};

export const getCustomerDetailWithPhoneNumber = (
  merchant_code: string,
  phone_number: string
) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/${merchant_code}/customers/detail`,
    params: { phone_number },
  });
};

export const getBookingServicesOnline = (merchant_code: string) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/${merchant_code}/book-assignments/online/create`,
  });
};

export const createBookingOnline = (
  merchant_code: string,
  body: IApiCreateBookingOnlineBody,
  isCheckIn?: boolean
) => {  
  return fetch({
    method: 'post',
    url: !isCheckIn ?
      `api/v1/merchants/${merchant_code}/book-assignments/online/store` :
      `api/v1/merchants/${merchant_code}/book-assignments/checkin/store`
    ,
    body: (body) as any,
  });
};

const checkIn = (book_assignment_id: string) => {
  return fetch({
    method: 'post',
    url: `api/v1/merchants/:merchant_code/book-assignments/${book_assignment_id}/checkin`,
    body: {},
  });
};

const cancelBooking = (book_assignment_id: string, body: { note: string }) => {
  return fetch({
    method: 'put',
    url: `api/v1/merchants/:merchant_code/book-assignments/${book_assignment_id}/cancel`,
    body,
  });
};

const duplicateBooking = (book_assignment_id: number) => {
  return fetch({
    method: 'post',
    url: 'api/v1/merchants/:merchant_code/book-assignments/duplicate',
    body: { book_assignment_id },
  });
};

const setBookingNoShow = (book_assignment_id: number) => {
  return fetch({
    method: 'put',
    url: `api/v1/merchants/:merchant_code/book-assignments/${book_assignment_id}/no-show`,
  });
};

const deleteBooking = (book_assignment_id: number) => {
  return fetch({
    method: 'delete',
    url: `api/v1/merchants/:merchant_code/book-assignments/${book_assignment_id}`,
  });
};

const getLocation = (merchant_code?: string) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/${merchant_code || ':merchant_code'}/location`,
  });
};

const getServiceByLocationPublic = (locationId?: string) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/:merchant_code/locations/${locationId}/services`,
  });
};

const checkPromotionValid = (promotion_code?: string) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/:merchant_code/promotion/check/${promotion_code}`
  });
};

const getActivities = (book_assignment_id: string) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/:merchant_code/books/${book_assignment_id}/activities`
  });
};

const getOptionFilter = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/books/calendar/filter'
  });
};

const getParamOptionCalendar = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/books/booking-behavior/index'

  });
};

const storeParamOptionCalendar = (body: IParamOptionCalendarBody) => {
  return fetch({
    method: 'put',
    url: 'api/v1/merchants/:merchant_code/books/booking-behavior/store',
    body: body as any
  });
};

const apisBooking = {
  getListBooking,
  apiGetBookAssignments,
  storeBookAssignments,
  findBookingById,
  updateBooking,
  getCustomerDetailWithPhoneNumber,
  getBookingServicesOnline,
  createBookingOnline,
  checkIn,
  cancelBooking,
  duplicateBooking,
  setBookingNoShow,
  deleteBooking,
  getLocation,
  getServiceByLocationPublic,
  checkPromotionValid,
  getActivities,
  getOptionFilter,
  getParamOptionCalendar,
  storeParamOptionCalendar
};

export default apisBooking;
