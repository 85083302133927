
import salesHook from 'features/sales/hooks/salesHook';
import ServicesSelection from './ServicesSelection';
import { IServiceSelectedItem } from 'features/sales/services/types/service';
// import userSelectors from 'features/users/services/selectors';
import ModalEditService, { IModalEditServiceProps } from '../ServiceDetails/ModalEditService';
import { useEffect, useState } from 'react';
import { get, last } from 'lodash';
import { useDispatch } from 'react-redux';
import salesActions from 'features/sales/services/actions';
import moment from 'moment';
import { BREAK_TIME_MINUTES } from 'features/bookings/services/constants';
import salesSelectors from 'features/sales/services/selectors';
import settingActions from 'features/settings/services/actions';
import settingSelectors from 'features/settings/services/selectors';

const ServicesTab = ({isFavorite = false}: {isFavorite?: boolean}) => {
  const [currServices, setCurrServices] = salesHook.useSelectedServices();
  const [draffService, setDraffService] = useState<IServiceSelectedItem | null>();
  const bookingDate = salesSelectors.getBookingDate();
  const location = salesSelectors.getMerchantLocationActive();

  useEffect(() => {
    dispatch(settingActions.getSetting.fetch());
  }, []);

  const setting = settingSelectors.getSetting();
  
  // const merchant_locatio

  const dispatch = useDispatch();

  const findServiceVariant = (service: IServiceSelectedItem) => {
    return service?.service_variants?.find((item) => (service.service_variant_id === item.id));
  };

  // const users = userSelectors.getStaffPaginateList()?.data?.map((o: any) =>({
  //   ...o,
  //   is_received_book: 1,
  // }));
  
  const onEditService: IModalEditServiceProps['onSubmit'] = (formValue) => {      
      const temp = [...currServices];
      temp.push(draffService as IServiceSelectedItem);      
      setCurrServices(temp);


      dispatch(salesActions.editSelectedService({
        ...draffService as IServiceSelectedItem,
        employee: formValue.employee,
        quantity: formValue.quantity,
      }));
  };

  const handleChooseService = (item: IServiceSelectedItem, isChecked: boolean) => {
    
    const haveService = currServices.find((service) => {
      return service.service_variant_id ? (service.id === item.id && service.service_variant_id === item.service_variant_id) : (service.id === item.id);
    });

    isChecked = haveService ? true : false;

    if (isChecked) {
      const newServices = currServices.filter((service) => {
        return !(service.service_variant_id ? (service.id === item.id && service.service_variant_id === item.service_variant_id) : (service.id === item.id));
      });
      setCurrServices(newServices);
    } else {
      
      item.merchant_employees = [...(item?.merchant_employees ?? [])].filter((o: any) => o.is_receive_book === 1);
      
      const isAnonymous = item?.merchant_employees?.length === 0;
      
      if (isAnonymous) {
        item.employee_id = undefined;
      }

      const price = (item.service_variant_id === null || item.service_variant_id === undefined) ?
        (item.sale_price || item.regular_price)
        : findServiceVariant(item)?.price;      
      const lastService = last(currServices);
      
      const time_start = get(lastService, 'time_start');

      const dataService = {
        ...item,
          quantity: 1,
          employee: item?.merchant_employees?.map((item) => ({
            id: item.id,
            name: item.full_name
          }))[0],
          time_start: !time_start ? bookingDate : moment(time_start).add((lastService?.duration_time ?? 0) + BREAK_TIME_MINUTES, 'minutes').format(),
          price: price ?? 0,
          categoryName: item.category?.name,
      };

      if(setting?.booking?.is_team_member_booking) {
        setDraffService({
          ...dataService,
        });
      } else {
        const manager = location?.manager;
        const temp = [...currServices];
        temp.push(dataService as IServiceSelectedItem);      
        setCurrServices(temp);

        dispatch(salesActions.editSelectedService({
          ...dataService as IServiceSelectedItem,
          employee: {
            id: manager?.id,
            name: manager?.full_name
          },
          quantity: 1,
        }));
      }

      // setCurrServices(temp);
    }
  };

  return (
    <>
      <ServicesSelection
        isFavorite={isFavorite}
        handleChooseService={handleChooseService}
        currChooseServices={currServices}
      />
      {!!draffService && <ModalEditService
        isOpenModal={!!draffService}
        handleCancel={() => setDraffService(null)}
        onSubmit={onEditService}
        data={draffService as any}
      />}
    </>
  );
};

export default ServicesTab;
