import { IServiceSelectedItem as CheckoutSer } from 'features/checkout/services/types/service';
import { IServiceSelectedItem as SalesSer } from 'features/sales/services/types/service';
import { IPromotionDetailInput } from 'features/promotions/services/types/promotion';

export type IService = CheckoutSer | SalesSer;

const useCheckServicesPromotion = (selected: IService[]) => {
  return (info: IPromotionDetailInput | null) => {
    if (!info) return false;

    const services = info.services;

    const validServices = selected.filter(o => {
      const id = o.id + '_' + (o.service_variant_id ?? '');
      return services.indexOf(id) !== -1;
    });

    return validServices.length > 0;
  };
};

export default useCheckServicesPromotion;
