import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import { first, get, last } from 'lodash';
import moment from 'moment';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import uiActions from 'services/UI/actions';
import { setLoading } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import { IDaysOfWeekItem } from 'utils/moment/getDatesOfWeek';
import { IBookAssignmentsResponse } from '../components/BookingForm/types';
import actions from './actions';
import apis from './apis';
import { BREAK_TIME_MINUTES, PATH_LOADING } from './constants';
import { geBookingLayoutPure, getCalendarParams, getLstTableParams, getPureCalendarCurrentLocation, getQuickBookingPureBookingDate, getQuickBookingPureIsEdit } from './selectors';
import { IApiBookingParams, IBookingItemResData, IBookingStatusItemResData, IEmployeeItemResData, IMerchantLocationItemResData, OptionFilter } from './types/booking';
import { IBookingOnlineResData } from './types/bookingOnline';
import { IPagination } from 'models/config';
import { VIEW } from 'constants/view.enum';
// import storage from 'utils/sessionStorage';

const getBookingFirstInit = function* () {
  try {
    const currId: number | string = yield select(getPureCalendarCurrentLocation);
    if (currId) return;
    const res: AxiosResponse<{ data: IMerchantLocationItemResData[] }> = yield call(apis.getLocation);
    const ls = res?.data?.data;
    if (ls) {
      yield put(actions.getOptionFilter.fetch());
      yield put(actions.getParamOptionCalendar.fetch());

    }
  } catch (error) { }
};

const getBookings: ISagaFunc<IApiBookingParams> = function* ({ payload }) {
  try {
    yield setLoading(PATH_LOADING.getBookings, true);
    const currentParams = yield select(getCalendarParams);
    const params: IApiBookingParams = { ...currentParams ?? {}, ...payload ?? {} };

    if (params?.date) {
      params.date = Math.floor(params.date / 1000);
    }
    if (params?.start_date) {
      params.start_date = Math.floor(params.start_date / 1000);
    }
    if (params?.end_date) {
      params.end_date = Math.floor(params.end_date / 1000);
    }
    yield delay(500);
    const res: AxiosResponse<{
      data: {
        books: IBookingItemResData[],
        employess: IEmployeeItemResData[],
        book_statuses: IBookingStatusItemResData[],
        merchant_locations: IMerchantLocationItemResData[],
      }
    }> = yield call(apis.getListBooking, params);
    if (res?.data?.data) {
      const { books = [] } = res.data.data ?? {};
      yield put(actions.getBookings.success(books));
      // yield put(actions.setTeamMembersBooking(employess));
      // yield put(actions.setLstBookingStatus(book_statuses));
      // yield put(actions.setLstMerchantLocation(merchant_locations));
    }
  } catch (error) {
    yield put(actions.getBookings.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getBookings, false);
  }
};

const setCalendarBookingParams: ISagaFunc<IApiBookingParams> = function* ({ payload }) {
  const currentParams = yield select(getCalendarParams);
  const params = { ...currentParams ?? {}, ...payload ?? {} };
  yield put(actions.getBookings.fetch(params));
};

const getLstTableBookings: ISagaFunc<IApiBookingParams> = function* ({ payload }) {
  try {
    yield setLoading(PATH_LOADING.getBookings, true);
    const currentParams = yield select(getLstTableParams);
    const params: IApiBookingParams = { ...currentParams ?? {}, ...payload ?? {} };

    if (params?.date) {
      params.date = Math.floor(params.date / 1000);
    }
    if (params?.start_date) {
      params.start_date = Math.floor(params.start_date / 1000);
    }
    if (params?.end_date) {
      params.end_date = Math.floor(params.end_date / 1000);
    }
    yield delay(500);
    const res: AxiosResponse<{
      data: {
        books: {
          data: IBookingItemResData[],
          pagination: IPagination | null,
        },
        employess: IEmployeeItemResData[],
        book_statuses: IBookingStatusItemResData[],
        merchant_locations: IMerchantLocationItemResData[],
      }
    }> = yield call(apis.getListBooking, params);
    if (res?.data?.data) {
      const { books = {} } = res.data.data ?? {};
      yield put(actions.getLstTableBookings.success(books));
      // yield put(actions.setTeamMembersBooking(employess));
      // yield put(actions.setLstBookingStatus(book_statuses));
      // yield put(actions.setLstMerchantLocation(merchant_locations));
    }
  } catch (error) {
    yield put(actions.getBookings.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getBookings, false);
  }
};

const setListTableBookingParams: ISagaFunc<IApiBookingParams> = function* ({ payload }) {
  const currentParams = yield select(getLstTableParams);
  const params = { ...currentParams ?? {}, ...payload ?? {} };
  yield put(actions.getLstTableBookings.fetch(params));
};

const setCalendarDaysOfWeek: ISagaFunc<IDaysOfWeekItem[]> = function* ({ payload }) {
  const start_date = first(payload)?.value ?? undefined;
  const end_date = last(payload)?.value ?? undefined;
  yield put(actions.setCalendarBookingParams({
    start_date: start_date ? moment(start_date).valueOf() : undefined,
    end_date: end_date ? moment(end_date).valueOf() : undefined,
    date: undefined,
  }));
};

const getBookingOnlineData: ISagaFunc<string> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getBookingOnlineData, true);
  yield put(uiActions.setLoadingPage(true));
  const merchantCode = payload;
  try {
    const res: AxiosResponse<{ data: IBookingOnlineResData }> = yield call(apis.getBookingServicesOnline, merchantCode);
    if (res?.data?.data) {
      const data = res.data.data ?? {};
      yield put(actions.getBookingOnlineData.success(data));
    }
  } catch (error) {
    yield put(actions.getBookingOnlineData.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getBookingOnlineData, false);
    yield put(uiActions.setLoadingPage(false));
  }
};


const setQuickBookingBookingDateWithRealTime: ISagaFunc<string> = function* ({ payload }) {
  const isEdit = yield select(getQuickBookingPureIsEdit);
  if (isEdit) return;
  const currentTime = moment(payload);
  const curBookingDateStore = yield select(getQuickBookingPureBookingDate);
  const curBookingDate = moment(curBookingDateStore);
  if (currentTime.isSame(curBookingDate, 'day')) {
    if (curBookingDate.isBefore(currentTime)) {
      yield put(actions.quickBooking.setBookingDate(currentTime.add(BREAK_TIME_MINUTES, 'minute').format()));
    }
  }
};

const checkInBooking: ISagaFunc<string> = function* ({ payload }) {
  const layout = yield select(geBookingLayoutPure);
  const id = payload;
  yield put(uiActions.setLoadingPage(true));
  try {
    const res = yield call(apis.checkIn, id);
    const msg = get(res, 'data.data.message', '');
    if (msg) {
      message.success(msg);
      if (layout === VIEW.LIST) {
        yield put(actions.setListTableBookingParams({}));
      } else {
        yield put(actions.getBookings.fetch({}));
      }
    } else {
      const errorMsg = get(res, 'data.error.message');
      if (errorMsg) {
        message.error(errorMsg);
      } else {
        throw 'fail';
      }
    }
  } catch (error) {
    message.error('An error occurred. Please try again');
  } finally {
    yield put(uiActions.setLoadingPage(false));
  }
};

const cancelBooking: ISagaFunc<{ id: string, body: { note: string } }> = function* ({ payload }) {
  const layout = yield select(geBookingLayoutPure);
  const { id, body } = payload ?? {};
  yield put(uiActions.setLoadingPage(true));
  try {
    const res = yield call(apis.cancelBooking, String(id), body);
    const msg = get(res, 'data.message', '');
    if (msg) {
      message.success(msg);
      if (layout === VIEW.LIST) {
        yield put(actions.setListTableBookingParams({}));
      } else {
        yield put(actions.getBookings.fetch({}));
      }
    } else {
      const errorMsg = get(res, 'data.error.message');
      if (errorMsg) {
        message.error(errorMsg);
      } else {
        throw 'fail';
      }
    }
  } catch (error) {
    message.error('An error occurred. Please try again');
  } finally {
    yield put(uiActions.setLoadingPage(false));
  }
};

const duplicateBooking: ISagaFunc<string> = function* ({ payload }) {
  const layout = yield select(geBookingLayoutPure);
  yield put(uiActions.setLoadingPage(true));
  try {
    const res = yield call(apis.duplicateBooking, +payload);
    const msg = get(res, 'data.data.message', '');
    if (msg) {
      message.success(msg);
      if (layout === VIEW.LIST) {
        yield put(actions.setListTableBookingParams({}));
      } else {
        yield put(actions.getBookings.fetch({}));
      }
    } else {
      const errorMsg = get(res, 'data.error.message');
      if (errorMsg) {
        message.error(errorMsg);
      } else {
        throw 'fail';
      }
    }
  } catch (error) {
    message.error('An error occurred. Please try again');
  } finally {
    yield put(uiActions.setLoadingPage(false));
  }
};

const setBookingNoShow: ISagaFunc<string> = function* ({ payload }) {
  const layout = yield select(geBookingLayoutPure);
  yield put(uiActions.setLoadingPage(true));
  try {
    const res = yield call(apis.setBookingNoShow, +payload);
    const msg = get(res, 'data.message', '');
    if (msg) {
      message.success(msg);
      if (layout === VIEW.LIST) {
        yield put(actions.setListTableBookingParams({}));
      } else {
        yield put(actions.getBookings.fetch({}));
      }
    } else {
      const errorMsg = get(res, 'data.error.message');
      if (errorMsg) {
        message.error(errorMsg);
      } else {
        throw 'fail';
      }
    }
  } catch (error) {
    message.error('An error occurred. Please try again');
  } finally {
    yield put(uiActions.setLoadingPage(false));
  }
};

const deleteBooking: ISagaFunc<string> = function* ({ payload }) {
  const layout = yield select(geBookingLayoutPure);
  yield put(uiActions.setLoadingPage(true));
  try {
    const res = yield call(apis.deleteBooking, +payload);
    const msg = get(res, 'data.message', '');
    if (msg) {
      message.success(msg);
      if (layout === VIEW.LIST) {
        yield put(actions.setListTableBookingParams({}));
      } else {
        yield put(actions.getBookings.fetch({}));
      }
    } else {
      const errorMsg = get(res, 'data.error.message');
      if (errorMsg) {
        message.error(errorMsg);
      } else {
        throw 'fail';
      }
    }
  } catch (error) {
    const errors = Object.values(get(error, 'response.data.errors', {}));
    const msg = get(first(errors), [0], '');
    if (msg)
      message.error(msg);
    else
      message.error('An error occurred. Please try again');
  } finally {
    yield put(uiActions.setLoadingPage(false));
  }
};

const getOnlineLocations: ISagaFunc<string> = function* ({ payload }) {
  const merchant_code = payload;
  try {
    const res: AxiosResponse<{ data: IMerchantLocationItemResData[] }> = yield call(apis.getLocation, merchant_code);
    const ls = res?.data?.data;
    if (ls) {
      yield put(actions.getOnlineLocations.success(ls));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getOnlineLocations.fail({}));
  }
};

const getQuickBookingDataConfigs = function* () {
  yield setLoading(PATH_LOADING.getDataConfigs, true);
  try {
    const res: AxiosResponse<IBookAssignmentsResponse> = yield call(apis.apiGetBookAssignments);
    if (res?.data?.data) {
      const configs = res.data.data;
      yield put(actions.quickBooking.getDataConfigs.success(configs));
    }
  } catch (error) {
    yield put(actions.quickBooking.getDataConfigs.fail(error));
  } finally {
    yield setLoading(PATH_LOADING.getDataConfigs, false);
  }
};

const getActivities: ISagaFunc<string> = function* ({payload}) {

  const book_assignment_id = payload;

  yield setLoading(PATH_LOADING.getActivities, true);
  try {
    const res: AxiosResponse<IBookAssignmentsResponse> = yield call(apis.getActivities, book_assignment_id);    
    if (res?.data?.data) {
      const configs = res?.data?.data;
      yield put(actions.getActivities.success(configs));
    }
  } catch (error) {
    yield put(actions.getActivities.fail(error));
  } finally {
    yield setLoading(PATH_LOADING.getActivities, false);
  }
};

const getOptionFilter = function* () {
  yield put(uiActions.setLoadingPage(true));
  try {
    const res: AxiosResponse<{ data: OptionFilter }> = yield call(apis.getOptionFilter);
    
    if (res?.data?.data) {
      const data = res.data.data ?? {};
      const {employess, book_statuses, merchant_locations, } = data;
      // const id = merchant_locations?.find(o => o.status === 1)?.id;
      
      yield put(actions.setLstMerchantLocation(merchant_locations));
      yield put(actions.setTeamMembersBooking(employess));
      yield put(actions.setLstBookingStatus(book_statuses));
      // yield put(actions.setCalendarBookingParams({ merchant_location_id: id }));
      // yield put(actions.setListTableBookingParams({ merchant_location_id: id }));
      
      // yield put(actions.getOptionFilter.success(data));
    }
  } catch (error) {
    yield put(actions.getOptionFilter.fail({}));
  } finally {
    yield put(uiActions.setLoadingPage(false));
  }
};

const getParamOptionCalendar = function* () {
  yield put(uiActions.setLoadingPage(true));
  try {
    const res: AxiosResponse<{ data: OptionFilter }> = yield call(apis.getParamOptionCalendar);
    
    if (res?.data?.data) {
      const data = res.data.data;      
      yield put(actions.getParamOptionCalendar.success(data));
    }

  } catch (error) {
    yield put(actions.getParamOptionCalendar.fail({}));
  } finally {
    yield put(uiActions.setLoadingPage(false));
  }
};

export default function* bookingServiceSaga() {
  yield takeLatest(actions.getBookingFirstInit, getBookingFirstInit);
  yield takeLatest(actions.getBookings.fetch, getBookings);
  yield takeLatest(actions.getLstTableBookings.fetch, getLstTableBookings);
  yield takeLatest(actions.setCalendarBookingParams, setCalendarBookingParams);
  yield takeLatest(actions.setListTableBookingParams, setListTableBookingParams);
  yield takeLatest(actions.setCalendarDaysOfWeek, setCalendarDaysOfWeek);
  yield takeLatest(actions.getBookingOnlineData.fetch, getBookingOnlineData);
  yield takeLatest(actions.setCurrentTime, setQuickBookingBookingDateWithRealTime);
  yield takeLatest(actions.checkInBooking, checkInBooking);
  yield takeLatest(actions.cancelBooking, cancelBooking);
  yield takeLatest(actions.duplicateBooking, duplicateBooking);
  yield takeLatest(actions.setBookingNoShow, setBookingNoShow);
  yield takeLatest(actions.deleteBooking, deleteBooking);
  yield takeLatest(actions.getOnlineLocations.fetch, getOnlineLocations);
  yield takeLatest(actions.quickBooking.getDataConfigs.fetch, getQuickBookingDataConfigs);
  yield takeLatest(actions.getActivities.fetch, getActivities);
  yield takeLatest(actions.getOptionFilter.fetch, getOptionFilter);
  yield takeLatest(actions.getParamOptionCalendar.fetch, getParamOptionCalendar);
  
} 
