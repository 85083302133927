import { IconRemove } from 'features/checkout/components/FeeButton';
import checkoutActions from 'features/checkout/services/actions';
import checkoutSelectors from 'features/checkout/services/selectors';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import { formatMoney } from 'utils/unit';

const VoucherResultRow = ({ viewOnly }: {viewOnly: boolean}) => {
  const result = checkoutSelectors.getVoucherValue();
  const totalOriginal = checkoutSelectors.getOriginTotalPrice();  
  const dispatch = useAppDispatch();



  const onRemove = () => {
    dispatch(checkoutActions.setSelectedVoucherUsed(null));
  };

  if (!result) return null;

  return result > 0 ? (
    <div className='extra-price-row'>
      <div className='label'>
        {!viewOnly &&
        <button type='button' onClick={onRemove}><IconRemove /></button>}
        Voucher
      </div>

      <span>- {formatMoney(totalOriginal <= result ? totalOriginal : result ?? 0)}</span>
    </div>
  ) : <></>;
};

export default VoucherResultRow;
