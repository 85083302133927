import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep, find, first, get, merge, remove } from 'lodash';
import moment from 'moment';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IBookingDetailResData } from './types/bookingDetail';
import { EPaymentMethod } from './types/checkout';
import { IState } from './types/reducer';
import { IServiceConfigsResData, IServiceSelectedItem } from './types/service';
import { IVoucherItemResData } from 'features/vouchers/services/types/voucher';
import authActions from 'features/auth/services/actions';
import { BREAK_TIME_MINUTES, TIME_START_FORMAT_RESPONSE } from 'features/bookings/services/constants';

const initialState: IState = {
  id: null,
  bookingDetail: null,
  categories: [],
  services: [],
  rewards: [],
  vouchers: [],
  customerInfo: null,
  selectedServices: [],
  selectedReward: null,
  selectedVoucher: null,
  selectedVouchersForSales: [],
  initSelectedVouchersForSales: [],
  initSelectedServices: [],
  bookingDate: '',
  paymentMethod: EPaymentMethod.CASH,
  mixPayments: [],
  resultTotal: null,
  purchaseInfo: {
    currentCash: null,
    currentCard: null
  },
  purchaseErrors: {
    cash: null,
    card: null,
    mix: null,
  },
  loyaltyPoint: 0,
  rewardOfCustomer: [],
  voucherOfCustomer: [],
  promotionInfo: null,
  total_point_program_will_earn: null,
  locations: [],
  promotionErrorBoundary: null,
  softPromotionCode: null,
  total_point: null,
  extraFee: null,
  discountFee: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.setDiscountFee, (state, { payload }) => {
        state.discountFee = payload;
      })
      .addCase(actions.setExtraFee, (state, { payload }) => {
        state.extraFee = payload;
      })
      .addCase(actions.updateInitialSelectedServices, (state) => {
        state.initSelectedServices = cloneDeep(state.selectedServices);
        state.initSelectedVouchersForSales = cloneDeep(state.selectedVouchersForSales);
      })
      .addCase(actions.getTotalPointCustomer.success, (state, { payload }) => {
        state.total_point = payload;
      })
      .addCase(actions.setSoftPromotionCode, (state, { payload }) => {
        state.softPromotionCode = payload;
      })
      .addCase(actions.setPromotionErrorBoundary, (state, { payload }) => {
        state.promotionErrorBoundary = payload;
      })
      .addCase(actions.setPurchaseCard, (state, { payload }) => {
        state.purchaseInfo.currentCard = payload;
      })
      .addCase(actions.resetFormPayment, (state) => {
        state.selectedReward = null;
        state.selectedVoucher = null;
        state.paymentMethod = EPaymentMethod.CASH;
        state.mixPayments = [];
        state.purchaseInfo = {
          currentCash: null,
          currentCard: null
        };
        state.purchaseErrors = {
          cash: null,
          card: null,
          mix: null,
        };
        state.promotionInfo = null;
        state.promotionErrorBoundary = null;
        state.softPromotionCode = null;
        state.extraFee = null;
        state.discountFee = null;

      })
      .addCase(authActions.logout.success, (state) => {
        state.id = null;
        state.bookingDetail = null;
        state.categories = [];
        state.services = [];
        state.rewards = [];
        state.vouchers = [];
        state.customerInfo = null;
        state.bookingDate = '';
        state.selectedServices = [];
        state.selectedReward = null;
        state.selectedVoucher = null;
        state.selectedVouchersForSales = [];
        state.initSelectedServices = [];
        state.initSelectedVouchersForSales = [];
        state.paymentMethod = EPaymentMethod.CASH;
        state.mixPayments = [];
        state.resultTotal = null;
        state.purchaseInfo = {
          currentCash: null,
          currentCard: null
        };
        state.purchaseErrors = {
          cash: null,
          card: null,
          mix: null,
        };
        state.loyaltyPoint = 0;
        state.rewardOfCustomer = [];
        state.voucherOfCustomer = [];
        state.total_point_program_will_earn = null;
        state.locations = [];

        state.promotionInfo = null;
        state.promotionErrorBoundary = null;
        state.softPromotionCode = null;
      })
      .addCase(actions.reviewPointCustomerWillReceive.success, (state, { payload }) => {
        if (payload.total_point_program_earn) {
          state.total_point_program_will_earn = payload.total_point_program_earn;
        }
      })
      .addCase(actions.setPromotionInfo, (state, { payload }) => {
        state.promotionInfo = payload;
      })
      .addCase(actions.setMixPayments, (state, { payload }) => {
        state.mixPayments = payload;
      })
      .addCase(actions.setSelectedVoucher, (state, { payload }) => {
        state.selectedVoucher = payload;
        const isExist = state.voucherOfCustomer.findIndex(o => o.voucher_code === payload?.voucher_code) !== -1;
        if (!isExist && payload) {
          state.voucherOfCustomer = [payload, ...state.voucherOfCustomer];
        }
      })
      .addCase(actions.setSelectedVoucherUsed, (state, { payload }) => {
        state.selectedVoucher = payload;
        const isExist = state.voucherOfCustomer.findIndex(o => o.voucher_code === payload?.voucher_code) !== -1;
        if (!isExist && payload) {
          state.voucherOfCustomer = [payload, ...state.voucherOfCustomer];
        }
      })
      .addCase(actions.setSelectedVouchersForSales, (state, { payload }) => {
        state.selectedVouchersForSales = payload;
      })
      .addCase(actions.setSelectedReward, (state, { payload }) => {
        state.selectedReward = payload;
      })
      .addCase(actions.setCheckoutBookingId, (state, { payload }) => {
        state.id = payload;
        state.bookingDetail = null;
        state.categories = [];
        state.services = [];
        state.rewards = [];
        state.vouchers = [];
        state.customerInfo = null;
        state.selectedServices = [];
        state.selectedReward = null;
        state.selectedVoucher = null;
        state.selectedVouchersForSales = [];
        state.initSelectedServices = [];
        state.initSelectedVouchersForSales = [];
        state.paymentMethod = EPaymentMethod.CASH;
        state.mixPayments = [];
        state.purchaseInfo = {
          currentCash: null,
          currentCard: null
        };
        state.purchaseErrors = {
          cash: null,
          card: null,
          mix: null,
        };
        state.loyaltyPoint = 0;
        state.rewardOfCustomer = [];
        state.voucherOfCustomer = [];

        state.promotionInfo = null;
        state.promotionErrorBoundary = null;
        state.softPromotionCode = null;
      })
      .addCase(actions.getBookingDetail.success, (state, { payload }) => {
        const bookingDetail = payload as IBookingDetailResData;
        state.bookingDetail = bookingDetail;
        state.customerInfo = bookingDetail.customer;
        state.bookingDate = bookingDetail.time_start;

        const timeStart = moment(bookingDetail.time_start);

        const selected = bookingDetail.book_assignment_services.map(o => {
          const duration_time = (o.duration_time ?? 0) * (o.quantity ?? 1) + BREAK_TIME_MINUTES;


          const result = {
            name: o?.service_name,
            serviceVariantName: o.serivce_variant_name,
            id: o?.service_id,
            service_variant_id: o.service_variant_id,
            duration_time: o?.duration_time,
            price: o?.service_price,
            quantity: o?.quantity,
            categoryName: o.category_service_name,
            employee: {
              id: o?.assigned_employee?.employee?.id,
              name: o?.assigned_employee?.employee?.full_name,
            },
            time_start: o?.assigned_employee.time_start ? moment(o?.assigned_employee.time_start, TIME_START_FORMAT_RESPONSE).format() : timeStart.format(TIME_START_FORMAT_RESPONSE),
            merchant_employees: o.merchant_employees,
          };

          timeStart.add(duration_time, 'minute');

          return result;
      });        


        state.selectedServices = selected;
        state.initSelectedServices = cloneDeep(selected);

      })
      .addCase(actions.setServiceSelected, (state, { payload }) => {
        state.selectedServices = payload ?? [];
        const timeStart = moment(state.bookingDate);
        state.selectedServices = state.selectedServices.map(o => {
          const duration_time = (o.duration_time ?? 0) * (o.quantity ?? 1) + BREAK_TIME_MINUTES;
          const result = {
            ...o,
            time_start: timeStart.format('YYYY-MM-DD HH:mm:ss'),
            service_variant_id: o.service_variant_id,
          };
          timeStart.add(duration_time, 'minute');
          return result;
        }) ?? [];
      })
      .addCase(actions.getVouchersConfig.success, (state, { payload }) => {
        const vouchers = payload as IVoucherItemResData[];
        state.vouchers = vouchers.map(v => {
          const result: string[] = [];
          v.services.forEach(o => {
            if (o.service_variants.length > 0) {
              o.service_variants.forEach(s => {
                const id = o?.id + '_' + s.id;
                result.push(id);
              });
            } else {
              result.push(o?.id?.toString());
            }
          });
          return ({
            ...v,
            services_flatten_ids: result,
            value_remaining: v.value - v.redeemed_value,
          });
        });
      })
      .addCase(actions.getServiceConfigs.success, (state, { payload }) => {
        const { categories = [], services = [], rewards = [], locations = [] } = (payload ?? {}) as IServiceConfigsResData;
        state.categories = categories;
        state.services = services;
        state.rewards = rewards;
        state.locations = locations;
      })
      .addCase(actions.updateCusInfo.success, (state, { payload }) => {
        merge(state.customerInfo, payload);
      })
      .addCase(actions.setPurchaseCash, (state, { payload }) => {
        state.purchaseInfo.currentCash = payload;
      })
      .addCase(actions.setPurchaseMethodErrors, (state, { payload }) => {
        const { method, errors } = payload;
        merge(state.purchaseErrors, {
          [method]: errors,
        });
      })
      .addCase(actions.setQuantitySelectedService, ((state, { payload }) => {
        const { quantity, id, service_variant_id } = payload;
        const item = find(state.selectedServices, (o: IServiceSelectedItem) => o.id === id && o.service_variant_id === service_variant_id);
        merge(item, { quantity });

        const timeStart = moment(get(first(state.selectedServices), 'employee.time_start'));
        state.selectedServices = state.selectedServices?.map(o => {
          const duration_time = (o.duration_time ?? 0) * (o.quantity ?? 1);
          const result = {
            ...o,
            time_start: timeStart.format('YYYY-MM-DD HH:mm:ss'),
          };
          timeStart.add(duration_time, 'minute');
          return result;
        }) ?? [];

      }))
      .addCase(actions.removeSelectedService, ((state, { payload }) => {
        const { id, service_variant_id } = payload;
        remove(state.selectedServices, o => o.id === id && o.service_variant_id === service_variant_id);

        const timeStart = moment(get(first(state.selectedServices), 'employee.time_start'));
        state.selectedServices = state.selectedServices?.map(o => {
          const duration_time = (o.duration_time ?? 0) * (o.quantity ?? 1);
          const result = {
            ...o,
            time_start: timeStart.format('YYYY-MM-DD HH:mm:ss'),
          };
          timeStart.add(duration_time, 'minute');
          return result;
        }) ?? [];

      }))
      .addCase(actions.setPaymentMethod, ((state, { payload }) => {
        state.paymentMethod = payload;
      }))
      .addCase(actions.setLoyaltyPoint, ((state, { payload }) => {
        state.loyaltyPoint = payload;
      }))
      .addCase(actions.setResultTotal, ((state, { payload }) => {
        state.resultTotal = payload;
        state.loyaltyPoint = payload.loyalty_point;
      }))
      .addCase(actions.addSelectedService, ((state, { payload }) => {
        const item = find(state.selectedServices, (o: IServiceSelectedItem) => o.id === payload.id);
        if (item) {
          const isExistInInitialLst = !!find(state.initSelectedServices, (o: IServiceSelectedItem) => o.id === payload.id);
          if (!isExistInInitialLst) remove(state.selectedServices, o => o.id === payload.id);
        } else {
          state.selectedServices.push(payload);
        }

        const timeStart = moment(get(first(state.selectedServices), 'employee.time_start'));
        state.selectedServices = state.selectedServices?.map(o => {
          const duration_time = (o.duration_time ?? 0) * (o.quantity ?? 1);
          const result = {
            ...o,
            time_start: timeStart.format('YYYY-MM-DD HH:mm:ss'),
          };
          timeStart.add(duration_time, 'minute');
          return result;
        }) ?? [];

      }))
      .addCase(actions.getRewardOfCustomer.success, (state, { payload }) => {
        const { pointEnough } = payload;
        // const pointLeftNearest = pointLeft.filter((o: any) => (state?.customerInfo?.current_point ?? 0) - o.points_to_earn_reward < 10);
        state.rewardOfCustomer = [...pointEnough];
      })
      .addCase(actions.getVoucherOfCustomer.success, ((state, { payload }) => {        
        state.voucherOfCustomer = payload.map((o: IVoucherItemResData) => ({ value_remaining: o.value - o.redeemed_value, ...o }));
      }));
  },
});
const checkoutServiceReducer = Slice.reducer;
export default checkoutServiceReducer;
