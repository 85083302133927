import { Avatar } from 'antd';
import bookingSelectors from 'features/bookings/services/selectors';
import React from 'react';
import styled from 'styled-components';
const CustomerInfo: React.FC = () => {
  const formValue = bookingSelectors.bookingOnline.getFormValues();
  return (
    <CustomerInfoStyled>
      <Avatar className='avatar'
        size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
      >
        {formValue?.name ? formValue?.name[0].toUpperCase() : ''}
      </Avatar>
      <div className="content">
        <p className="name">{formValue?.name}</p>
        <div className="sub_info">
          <p>{formValue?.email}</p>
          <p>{formValue?.phoneNumber}</p>
        </div>
      </div>
    </CustomerInfoStyled>
  );
};

export default CustomerInfo;

const CustomerInfoStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  .avatar{
    margin-right: 24px;
    background: linear-gradient(315deg, #6587FF 0%, #C883FF 100%);
    display: flex;
    align-items: center;
    @media only screen and (max-width: 767.98px) {
      width: 80px !important;
      height: 80px !important;
    }
    .ant-avatar-string {
      font-weight: 800;
      font-size: 60px;
      display: flex;
      line-height: 1;
      transform: scale(1) translateX(-50%) translateY(-5px) !important;
    }
  }

  .content {
    .name {
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: var(--color-primary);
    }
    .sub_info {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      /* identical to box height, or 20px */


      /* Grayish Navy/60 */

      color: #7980BC;

    }
  }
  
`;