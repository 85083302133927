import { Empty } from 'antd';
import { TIME_DATE_TIMEZONE} from 'features/bookings/services/constants';
import DetailItem, { EDetailActions, IDetailActions } from 'features/checkout/components/ServiceDetails/Item';
import ForSalesSelectedItem from 'features/checkout/components/VouchersTabs/ForSalesSelectedItem';
import salesHook from 'features/sales/hooks/salesHook';
import salesActions from 'features/sales/services/actions';
import salesSelectors from 'features/sales/services/selectors';
import React, { useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ModalEditBookingTime from './ModalEditBookingTime';
import ModalEditService, { IModalEditServiceProps } from './ModalEditService';
import { IServiceSelectedItem } from 'features/sales/services/types/service';
import { CHOOSE_CATEGORY_REWARD } from 'features/loyaltyProgram/services/constants';
import moment from 'moment';
import BookingDate from 'components/common/BookingDate';
// import FeeItem from 'features/checkout/components/FeeButton/Item';
type IServiceDetailsProps = {
  viewOnly: boolean;
  step?: number;
};
const ServiceDetails: React.FC<IServiceDetailsProps> = ({ viewOnly, step }) => {

  const dispatch = useAppDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [bookingDate, setBookingDate] = salesHook.useSetDateBooking();
  // const currentDateBooking = useMemo(() => convertNotSetTimezone(bookingDate, TIME_DATE_TIMEZONE), [bookingDate]);
  const [selectedVouchers, actionsSelectedVouchersForSales] = salesHook.useVoucher();
  const [selectedServices] = salesHook.useSelectedServices();
  const currCustomer = salesSelectors.getCustomer();
  const selectedReward = salesSelectors.getSelectedReward();
  

  // const extraFee = salesSelectors.getExtraFee();
  // const extraFeeValue = salesSelectors.getExtraFeeValue();

  const [currEditService, setCurrEditService] = useState<IServiceSelectedItem | null | undefined>(null);

  const setQuantity = (service: IServiceSelectedItem): IDetailActions['SET_QUANTITY'] => (quantity) => {
    dispatch(salesActions.setQuantitySelectedService({ id: service.id, service_variant_id: service.service_variant_id, quantity }));
  };

  const onEditService: IModalEditServiceProps['onSubmit'] = (formValue, service) => {    
    if (!service) return;
    dispatch(salesActions.editSelectedService({
      ...service,
      employee: formValue.employee,
      quantity: formValue.quantity,
    }));
  };

  const remove = (service: IServiceSelectedItem): IDetailActions['REMOVE'] => () => {
    dispatch(salesActions.removeSelectedService({ id: service.id, service_variant_id: service.service_variant_id }));
  };

  const disabledActions = (service: IServiceSelectedItem): EDetailActions[] => {
    const result: EDetailActions[] = [];

    if (viewOnly) {
      result.push(EDetailActions.REMOVE);
      result.push(EDetailActions.DECREASE);
      result.push(EDetailActions.INCREASE);
      return result;
    }

    if (service.quantity === 1) {
      result.push(EDetailActions.DECREASE);
    }

    return result;
  };

  const handleModalCancel = () => {
    setIsOpenModal(false);
  };

  const handleSubmit = (date: string) => {
    
    setBookingDate(date);
    handleModalCancel();
    return false;
  };

  const checkApplyReward = (service: IServiceSelectedItem) => {
    if (!viewOnly) return false;
    if (!selectedReward) return false;
    if (selectedReward.category !== CHOOSE_CATEGORY_REWARD.FREE_SERVICE) return false;
    if (selectedReward.free_service.length === 0) return true;


    return !!selectedReward.free_service.find(o => {
      if (o.extend_services.length === 0) return o.id.toString() === service.id?.toString();
      return o.id.toString() === service.id?.toString() && !!o.extend_services.find(s => s.toString() === service.service_variant_id?.toString());
    });
  };
  const resultTotal = salesSelectors.getInvoiceResult();

  return (
    <ServiceDetailsStyled>
      <div className='heading'>
        <div>
          <BookingDate 
            bookingDate={bookingDate}  
            isEdit={!viewOnly}
            setIsOpenModal={setIsOpenModal}
          />
        </div>
      </div>
      {(selectedServices.length > 0 || selectedVouchers.length > 0) ? (
        <div className='list-detail-items common-scrollbar'>
          {selectedServices?.map((service) => {
            return (
              <DetailItem
                step={step ?? 0}
                data={service}
                key={service.id + '-' + service.service_variant_id}
                setQuantity={setQuantity(service)}
                remove={remove(service)}
                disabledActions={disabledActions(service)}
                isApplyReward={checkApplyReward(service)}
                onEditService={() => setCurrEditService(service)}
              />
            );
          })}
          {(step === 3 ? (resultTotal?.list_voucher_sell ?? selectedVouchers) : selectedVouchers)?.map(voucher =>
            <ForSalesSelectedItem
              key={voucher.id}
              data={voucher}
              setQuantity={actionsSelectedVouchersForSales.setQuantity}
              remove={actionsSelectedVouchersForSales.remove}
              viewOnly={viewOnly}
              customer={currCustomer}
              step={step}
            />
          )}
          {/* {step === 3 && extraFee && <FeeItem data={extraFee} amount={extraFeeValue} />} */}
        </div>
      ) : (
        <div className='empty-service'>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No selected'} />
        </div>
      )}

      {!!currEditService && <ModalEditService
        isOpenModal={!!currEditService}
        handleCancel={() => setCurrEditService(null)}
        onSubmit={onEditService}
        data={currEditService}
      />}


      {isOpenModal && <ModalEditBookingTime
        visible={isOpenModal}
        onCancel={handleModalCancel}
        date={moment(bookingDate).format(TIME_DATE_TIMEZONE)}
        onSubmit={handleSubmit}
      />}
    </ServiceDetailsStyled>
  );
};

export default ServiceDetails;
const ServiceDetailsStyled = styled.div`
.heading {
  display: flex;
  gap: 24px;
  align-items: flex-start;
}

.title {
  font-weight: 600;
  font-size: 18px;
  color: var(--color-primary);
}

.btn-delete-all {
  display: inline-flex;
  align-items: center;
  padding: 4px 12px;
  min-width: 88px;
  height: 32px;
  background: #f0f2f7;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-primary);
  margin-left: auto;
}

.empty-service {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30  0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #363565;
}

.list-detail-items {
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 267px;
  max-height: 405px;
  overflow: auto;
  padding-right: 12px;
}
.booking_date {
  display: flex;
  align-items: center;
  .booking_date_value {
    font-size: 18px;
    color:#4A9D77 !important;
  }
  span {
    display: flex;
    align-items: center;
  }
  &.viewOnly {
    display: flex;
    font-weight: 600;
    font-size: 18px;
    color: rgb(96, 102, 159);
    gap: 4px;
    .booking_date_value {
      font-weight: 600;
      color: var(--color-primary);
    }
  }
}
`;
