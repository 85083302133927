import Information from './Information';
import Confirm from './Confirm';
import BookingDone from './Confirm/BookingDone';
import InValidLocation from './Confirm/InValidLocation';

const BookingForms = {
    Information,
    Confirm,
    BookingDone,
    InValidLocation
};

export default BookingForms;
