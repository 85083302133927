import { Form, FormInstance, Input, Select, Spin } from 'antd';
import userSelectors from 'features/users/services/selectors';
import { ITeamMemberItemResData } from 'features/users/services/types/teamMember';
import React from 'react';

type Props = {
  form?: FormInstance
}

const LocationsFormSecondStep = ({
  form
}: Props) => {

  const staffOptions: ITeamMemberItemResData[]  = userSelectors.getStaffPaginateList().data;
  const staffLoading = userSelectors.loadingStaffPaginateList();

  const onChangeStaff = (value: number) => {
    const staffSelected = staffOptions.find((item: ITeamMemberItemResData) => item?.user_id == value);
    

    form?.setFieldsValue({
      email: staffSelected?.email,
      managerPhoneNumber: staffSelected?.phone
    });
  };

  return (
    <>
      <Spin spinning={staffLoading}>

        <Form.Item 
          label='Manager Name' 
          name='manager'
          rules={[
            {required: true},
          ]}
          >
          <Select
            placeholder='Select team member'
            onChange={onChangeStaff}
            options={staffOptions?.map((item: ITeamMemberItemResData) => ({
              label: item.full_name,
              value: item.user_id,
            })) ?? []}
          />
        </Form.Item>
        {/* <Form.Item label='Email' name='email'>
          <Input type='email' placeholder='storeone@nails.com' disabled/>
        </Form.Item> */}
        <div className='form-row items-end '>
          {/* <Form.Item
            label='Phone number'
            name='phoneNumberCode'
            className='is-small'
          >
            <Select
              className='is-small'
              options={[
                {
                  value: '+92',
                  label: '+92',
                },
                {
                  value: '+94',
                  label: '+94',
                },
              ]}
            />
          </Form.Item> */}
          <Form.Item label='Phone number' name='managerPhoneNumber'>
            <Input placeholder='Enter your phone number' disabled/>
          </Form.Item>
        </div>
      </Spin>
    </>
  );
};

export default LocationsFormSecondStep;
