import moment from 'moment';

export const useRangeDate = ( unit: string, amount: number) => {
  const dateStart = unit === 'days' ? moment().unix() : moment().subtract(amount, unit as moment.unitOfTime.DurationConstructor).startOf(unit as moment.unitOfTime.DurationConstructor).add(1, 'day').unix();
  const dateEnd = unit === 'day' ? dateStart : moment(moment()).subtract(amount, unit as moment.unitOfTime.DurationConstructor).endOf(unit as moment.unitOfTime.DurationConstructor).unix();
  
  if(unit === 'days')
    return [dateEnd, dateStart];

  return [dateStart, dateEnd];
};