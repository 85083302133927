import { Empty, Form, Input, Modal, Popover, Spin } from 'antd';
import AuthAvatar from 'assets/images/auth/auth_avatar.jpg';
import { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import AvatarUser from './widgets/AvatarUser';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import userSelectors from 'features/users/services/selectors';
import notificationSelectors from './service/selectors';
import { useDispatch } from 'react-redux';
import notificationActions from './service/actions';
import getSingleDateShortFormat from 'utils/moment/getSingleDateShortFormat';
import moment from 'moment';
import apisNotification from './service/apis';
import styled from 'styled-components';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import { get } from 'lodash';
import message from 'components/common/Message';

export const getAvatar = () => {
  const detail = userSelectors.getProfileStaffDetail();
  return detail?.avatar_url !== '' ? detail?.avatar_url : AuthAvatar;
};

const Header = () => {
  const selectors = notificationSelectors.getNoificationList();
  const loadingListNotification = notificationSelectors.loadingNotification();
  const [isShowSearchModal, setIsShowSearchModal] = useState(false);
  const { t: formLang } = useTranslation('form');
  const [open, setOpen] = useState(false);
  const modalConfirmRef = useRef<ModalConfirmRef>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(notificationActions.getListNotification.fetch());
  }, []);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const onclickCheckView = async (e: number) => {
    await apisNotification.getCheckViewNotification(e);
    dispatch(notificationActions.getListNotification.fetch());
  };

  // =============== Change language ====================
  // const { t, i18n } = useTranslation();

  // const changeLanguage = (languageValue: any) => {
  //   i18n.changeLanguage(languageValue);
  //   document.title = t('common.appTitle');
  //   localStorage.setItem('selectedLanguage', languageValue);
  // };

  // const handleLanguageChange = (e: any) => {
  //   const languageValue = e.target.value;
  //   changeLanguage(languageValue);
  // };
  const { t: headerLang } = useTranslation('header');
  // =============== end ====================

  const onClickDeleteNotification = () => {
    setOpen(!open);
    modalConfirmRef.current?.show({
      title: headerLang('DeleteThisNotifications'),
      msg: headerLang('AreYouSureYouWantToDeleteThisNotifications'),
      submit: async () => {
        try {
          const res = await apisNotification.deleteNotification();
          const msg = get(res, 'data.message', '');
          if (msg) {
            message.success(msg);
            dispatch(notificationActions.getListNotification.fetch());
          } else {
            const msgError = get(res, 'data.error.message');
            if (msgError) message.error(msgError);
          }
        } catch (error) {
          message.error('An error occurred. Please try again');
        }
      },
      icon: 'delete',
    });
  };

  const ContentNotification = () => (
    <NotificationListStyled>
      <div>
        <button className={selectors.length > 0 ? 'notification-remove-btn' : 'dl-none'} onClick={() => onClickDeleteNotification()}></button>
        <p className='notification-title'>{headerLang('Notifications')}</p>
        <Spin spinning={loadingListNotification}>
          <ul className='notification-list'>
            {
              selectors.length > 0 ?
                selectors?.map((o, index) =>
                  <>
                    <li onClick={() => onclickCheckView(o.id)} key={index}>
                      <div className={o.viewed == 0 ? 'icon' : 'no_icon'}></div>
                      <NotificationImageStyled>
                        <div className='notification-avatar'>

                          <div className='notification-img common-avatar'>
                            {o.full_name && Array.from(o.full_name)[0]}
                          </div>
                        </div>
                      </NotificationImageStyled>
                      <div>
                        <p className='notification-text'>
                          <span>{o.full_name}</span> {o.notify_type} {' '}
                          {/* <span>{e.service}</span> */}
                        </p>
                        <p className='notification-time'>{getSingleDateShortFormat(moment(o.time))} {headerLang('at')} {moment(o.time).format('HH:mm')}</p>
                      </div>
                    </li>
                  </>
                ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No notification'} />
            }
          </ul>
        </Spin>
        <NavLink to={'notification'}>
          <div className={selectors.length > 0 ? 'notification-footer' : 'dl-none'}>
            <span>
              View all{' '}
              <span
                style={{
                  marginLeft: '8px',
                  fontSize: '24px',
                  fontWeight: '400',
                }}
              >
                <IconRight />
              </span>
            </span>
          </div>
        </NavLink>
      </div>
    </NotificationListStyled>
  );
  const checkView = selectors.find(o => o.viewed === 0);

  return (
    <>
      <header>
        <div className={styles.header_wrapper}>
          {/* <div className="form-input-search">
            <span onClick={() => setIsShowSearchModal(true)}></span>
            <input type="text"></input>
          </div> */}

          {/* <select value={i18n.language} onChange={handleLanguageChange}>
            <option value="en">English</option>
            <option value="vi">Vietnamese</option>
          </select> */}

          <NavLink to={'/staff-check-in'}>
            <button className={'common-btn'}>
              {headerLang('TeamCheckIn')}
            </button>
          </NavLink>
          <div
            className={`${styles.header_notification} ${styles.active
              }`}
          >
            <Popover
              content={<ContentNotification />}
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
              placement="bottomRight"
              className={`
                btn-noti 
                ${styles.notification_icon} 
                ${checkView ? styles.notification_icon_active : ''} 
                `}
            >
            </Popover>
          </div>
          <AvatarUser />
        </div>
        <Modal
          maskClosable={false}
          open={isShowSearchModal}
          onCancel={() => setIsShowSearchModal(false)}
          okButtonProps={{ style: { display: 'none' } }}
          width={574}
          footer={null}
          forceRender
          centered
          className='search-modal'
        >
          <h2 className='modal-title'>
            <p>Search</p>
          </h2>
          <Form name='basic' className='modal-form' autoComplete='off'>
            <Input placeholder={formLang('SearchProfileLabel') || ''} />
            <p className='search-result-text'>
              Can not find the item you are searching
            </p>
          </Form>
        </Modal>
        <ModalConfirm ref={modalConfirmRef} />
      </header>
    </>
  );
};

export default Header;

const IconRight = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.31006 18L15.3101 12L9.31006 6'
        stroke='#363565'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const NotificationListStyled = styled.div`
  width: 440px;
  background-color: var(--color-white);
  filter: drop-shadow(0px 4px 24px rgba(225, 225, 239, 0.6));
  border-radius: 6px;
  top: calc(100% + 8px);
  right: 0;
  padding: 12px;
  z-index: 101;

  @media only screen and (max-width: 767.98px) {
    right: 12px;
    width: calc(100% - 24px);
    top: 90px;
    width: 100%;
  }

  .dl-none {
    display: none;
  }

  .notification-remove-btn {
    display: block;
    width: 24px;
    height: 24px;
    background: var(--icon-remove-red) center/contain no-repeat;
    position: absolute;
    left: 16px;
    top: 12px;
  }

  .notification-title {
    text-align: center;
    padding: 12px 16px;
    font-weight: 600;
    font-size: 16px;
    color: var(--color-primary);
  }

  :where(.css-dev-only-do-not-override-1qkz0az).ant-popover {
    @media only screen and (max-width: 767.98px) {
      left: 0 !important;
      right: 0;
    }
  }

  .notification-list {
    overflow: scroll;
    /* height: 500px; */
    max-height: 500px;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #c3c2e0;
    }
    li {
      display: flex;
      gap: 16px;
      padding: 16px;
      border-bottom: 1px solid var(--color-white-02);
      position: relative;
      transition: all 0.3s ease;
      cursor: pointer;

      &:last-child {
        border-bottom: 1px solid var(--color-purple);
      }

      &:hover {
        opacity: 0.7;
      }

      .no_icon {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        left: 8px;
        top: 8px;
        border-radius: 50%;
      }

      .icon {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        background: var(--color-blue-02);
        border: 1px solid var(--color-blue-01);
        position: absolute;
        left: 8px;
        top: 8px;
        border-radius: 50%;
      }
    }
  }

  .notification-text {
    color: var(--color-primary);

    span {
      font-weight: 600;
    }
  }

  .notification-time {
    color: var(--color-purple-07);
    margin-top: 28px;
  }

  .notification-avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;

    &+div {
      flex: 1;
    }
  }

  .notification-footer {
    text-align: center;
    padding: 10px 16px 14px;

    span {
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      color: var(--color-primary);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.is-all {
    position: static;
    width: 566px;
    padding: 0 20px;

    @include mobile {
      width: 100%;
    }

    .notification-title {
      text-align: left;
      border-bottom: 1px solid var(--color-white-01);
      padding: 20px 0;
      margin-bottom: 20px;
    }

    .notification-remove-btn {
      left: auto;
      right: 20px;
      top: 20px;
    }

    .notification-list {
      li {
        border-bottom: none;
      }
    }
  }
`;

const NotificationImageStyled = styled.div`
.notification-img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
}
`;
