const IconBill = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1429_62604)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 2C17.7956 2 18.5587 2.31607 19.1213 2.87868C19.6839 3.44129 20 4.20435 20 5V21C19.9999 21.1883 19.9466 21.3728 19.8462 21.5322C19.7459 21.6916 19.6025 21.8194 19.4327 21.9009C19.2629 21.9824 19.0736 22.0143 18.8864 21.9929C18.6993 21.9715 18.522 21.8977 18.375 21.78L16.5 20.28L14.625 21.78C14.4329 21.9339 14.1908 22.0115 13.945 21.9981C13.6993 21.9846 13.4671 21.881 13.293 21.707L12 20.414L10.707 21.707C10.533 21.8811 10.3009 21.9849 10.0551 21.9986C9.80938 22.0122 9.56721 21.9348 9.375 21.781L7.5 20.28L5.625 21.78C5.47797 21.8977 5.30069 21.9715 5.11356 21.9929C4.92643 22.0143 4.73707 21.9824 4.56727 21.9009C4.39747 21.8194 4.25414 21.6916 4.15378 21.5322C4.05342 21.3728 4.00012 21.1883 4 21V5C4 4.20435 4.31607 3.44129 4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2H17ZM12 12H9C8.73478 12 8.48043 12.1054 8.29289 12.2929C8.10536 12.4804 8 12.7348 8 13C8 13.2652 8.10536 13.5196 8.29289 13.7071C8.48043 13.8946 8.73478 14 9 14H12C12.2652 14 12.5196 13.8946 12.7071 13.7071C12.8946 13.5196 13 13.2652 13 13C13 12.7348 12.8946 12.4804 12.7071 12.2929C12.5196 12.1054 12.2652 12 12 12ZM15 8H9C8.74512 8.00028 8.49997 8.09788 8.31463 8.27285C8.1293 8.44782 8.01776 8.68695 8.00283 8.94139C7.98789 9.19584 8.07067 9.44638 8.23426 9.64183C8.39785 9.83729 8.6299 9.9629 8.883 9.993L9 10H15C15.2549 9.99972 15.5 9.90212 15.6854 9.72715C15.8707 9.55218 15.9822 9.31305 15.9972 9.05861C16.0121 8.80416 15.9293 8.55362 15.7657 8.35817C15.6021 8.16271 15.3701 8.0371 15.117 8.007L15 8Z"
          fill="#363565"
        />
      </g>
      <defs>
        <clipPath id="clip0_1429_62604">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconBill;
