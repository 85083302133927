import FeeButton from 'features/checkout/components/FeeButton';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import salesActions from '../services/actions';
import { IModalFeeSubmitFunc } from 'features/checkout/components/FeeButton/Modal';
import salesSelectors from '../services/selectors';
type IExtraFeeProps = {
  viewOnly?: boolean;
};
const ExtraFee: React.FC<IExtraFeeProps> = ({ viewOnly }) => {
  const dispatch = useAppDispatch();
  const data = salesSelectors.getExtraFee();
  const value = salesSelectors.getExtraFeeValue();
  const subTotal = salesSelectors.getSubTotal();

  const handleAdd: IModalFeeSubmitFunc = (val) => {
    dispatch(salesActions.setExtraFee(val));
  };

  const handleRemove = () => {
    dispatch(salesActions.setExtraFee(null));
  };

  if (!subTotal) return null;

  return (
    <ExtraFeeStyled>
      <FeeButton
        type='extra'
        data={data}
        viewOnly={viewOnly}
        amount={value}
        onAdd={handleAdd}
        onRemove={handleRemove} />
    </ExtraFeeStyled>
  );
};

export default ExtraFee;
const ExtraFeeStyled = styled.div``;
