import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NotFoundPage = () => {
  return (
    <NotFoundPageStyled>
      <h2 className='title'>404</h2>
      <p className='text'>
        We are sorry, but the page you requested was not found
      </p>
      <div className='btn-wrapper'>
        <Link to='/sign-in'>
          <button className='btn is-white'>Go home</button>
        </Link>
        {/* <Link to="#">
          <button className="btn">Contact us</button>
        </Link> */}
      </div>
    </NotFoundPageStyled>
  );
};

export default NotFoundPage;

const NotFoundPageStyled = styled.div`
  display: flex;
  background: #363565;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-bottom: 10%;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  padding: 0 20px;
  .btn-wrapper {
    display: flex;
    justify-content: center;
    gap: 30px;
  }
  .title {
    font-size: 200px;
    line-height: 1;
  }
  .text {
    font-size: 30px;
    margin: 30px 0;
  }
  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 138px;
    height: 42px;
    border: 2px solid #b1b8f7;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    background: #fff;
    color: #b1b8f7;
    transition: all 0.3s ease;
    cursor: pointer;
    transition: all .3s ease;
    a {
      display: inline-block;
    }
    &.is-white {
      color: #fff;
      background: #363565;
      border-color: #fff
    }
  }
`;
