import { createSlice } from '@reduxjs/toolkit';
import { NAME_REDUCER } from './constants';
import actions from './actions';
import { IState } from './types/reducer';
import { ILocationResDataItem } from 'features/bookings/services/types/bookingOnline';
import { IServiceItemResData } from './types/service';
import { ILoyalSettingResData } from './types/setting';
import authActions from 'features/auth/services/actions';

const initialState: IState = {
  listLoyalPrograms: [],
  listLoyalRewards: [],
  locations: [],
  services: [],
  loyalSetting: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActions.logout.success, (state) => {
        state.listLoyalPrograms = [];
        state.listLoyalRewards = [];
        state.locations = [];
        state.services = [];
        state.loyalSetting = null;
      })
      .addCase(actions.getLoyalSetting.success, (state, { payload }) => {
        const data = payload as ILoyalSettingResData;
        state.loyalSetting = data;
      })
      .addCase(actions.getListServices.success, (state, { payload }) => {
        const data = payload as IServiceItemResData[];
        state.services = data;
      })
      .addCase(actions.getListLocations.success, (state, { payload }) => {
        const data = payload as ILocationResDataItem[];
        state.locations = data;
      })
      .addCase(actions.getListLoyalProgram.success, (state, { payload }) => {
        const data = payload as any[];
        state.listLoyalPrograms = data;
      })
      .addCase(actions.getListLoyalReward.success, (state, { payload }) => {
        const data = payload as any[];
        state.listLoyalRewards = data;
      });
  },
});
const loyalServiceReducer = Slice.reducer;
export default loyalServiceReducer;
