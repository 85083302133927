import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ISagaFunc } from 'services/actionConfigs';
import actions from './actions';
import apis from './apis';
import { AxiosResponse } from 'axios';
import { APIListInvoicesParams, IInvoiceResData, IResInvoiceItem } from './types/invoice';
import { setLoading } from 'services/UI/sagas';
import { PATH_LOADING } from './constants';
import { getPureInvoiceParam } from './selectors';

const actionGetInvoices: ISagaFunc<APIListInvoicesParams> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getInvoices, true);
  const oldParams = yield select(getPureInvoiceParam);

  const params = {
    ...oldParams,
    ...payload
  };

  try {
    const res: AxiosResponse<{ data: IInvoiceResData }> = yield call(apis.apiGetInvoices, params);
    if (res?.data?.data) {
      yield put(actions.getInvoices.success(res.data.data));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getInvoices.fail(error));
  } finally {
    yield setLoading(PATH_LOADING.getInvoices, false);
  }
};

const setInvoiceParams: ISagaFunc<APIListInvoicesParams> = function* ({ payload }) {
  yield put(actions.getInvoices.fetch(payload));
};

const getInvoiceDetail: ISagaFunc<string> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getDetail, true);
  try {
    const res: AxiosResponse<{ data: IResInvoiceItem }> = yield call(apis.getInvoiceDetail, payload);
    if (res?.data?.data) {
      yield put(actions.getInvoiceDetail.success(res.data.data));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getInvoiceDetail.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getDetail, false);

  }
};

const setInvoiceDetailId: ISagaFunc<string> = function* ({ payload }) {
  yield put(actions.getInvoiceDetail.fetch(payload));
};

export default function* invoicesServiceSaga() {
  yield takeLatest(actions.getInvoices.fetch, actionGetInvoices);
  yield takeLatest(actions.setInvoiceParams, setInvoiceParams);
  yield takeLatest(actions.getInvoiceDetail.fetch, getInvoiceDetail);
  yield takeLatest(actions.setInvoiceDetailId, setInvoiceDetailId);
}
