import styled from 'styled-components';
export const LabelStyled = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: var(--color-primary);
  margin-bottom: 12px;
`;
export const DividerStyled = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 0px;
  background: var(--color-purple)
`;

export const ButtonSuccess = styled.button`
    outline: none; 
    border: none;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    background: var(--color-green);
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    text-transform: capitalize;
    color: var(--color-white);
`;

export const ButtonSuccessBig = styled.button`
    outline: none; 
    border: none;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 24px;
    border: 1px solid #4A9D77;
    color: #4A9D77;
    border-radius: 6px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    text-transform: capitalize;
    align-self: flex-end;
    margin-bottom: 8px;
    &.blue {
      border: 1px solid var(--color-primary);
      background: var(--color-primary);
      color: #fff
    }
`;