import { Modal } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import FormFirstStep from '../components/FormFirstStep';
import FormSecondStep from '../components/FormSecondStep';

const RefundPagePage: React.FC = () => {
  const [page, setPage] = useState(1);

  const FormDisplay = () => {
    switch (page) {
      case 1:
        return <FormFirstStep />;
      case 2:
        return <FormSecondStep />;
      default:
        return null;
    }
  };

  return (
    <Modal
      maskClosable={false}
      open={true}
      // onCancel={hide}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <RefundStyled>
        <FormDisplay />
        <div className='form-submit-wrapper'>
          {page === 1 ? (
            <>
              <button type='button' className='common-btn is-white'>
                Cancel
              </button>
              <button
                type='button'
                className='common-btn'
                onClick={() => setPage(2)}
              >
                Refund
              </button>
            </>
          ) : (
            <>
              <button
                type='button'
                className='common-btn is-white'
                onClick={() => setPage(1)}
              >
                Back
              </button>
              <button type='button' className='common-btn'>
                Confirm
              </button>
            </>
          )}
        </div>
      </RefundStyled>
    </Modal>
  );
};

export default RefundPagePage;

const RefundStyled = styled.div`
  * {
    font-family: var(--font-base);
  }
  .refund-heading {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 140%;
    text-transform: uppercase;
    color: #363565;
    margin-bottom: 30px;
    @media only screen and (max-width: 767.98px) {
      margin-top: 42px;
    }
  }
  .heading-price {
    font-weight: 600;
    font-size: 24px;
    text-align: right;
    text-transform: uppercase;
    color: #4a9d77;
  }

  .PIN_form {
    text-align: center;
    margin-bottom: 120px;
    @media only screen and (max-width: 767.98px) {
      margin-top: 42px;
    }

    p {
      color: #363565;
    }
    .label-highlight {
      color: #4a9d77;
    }
  }
  .PIN_group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .PIN_input {
    flex: 1;
    background: none;
    border: unset;
    border-radius: 0;
    border-bottom: 14px solid #c3c2de;
    input {
      padding: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 140%;
      text-align: center;
      color: #363565;
      background: none;
    }
    &.ant-input-number {
      width: 40px;
    }
  }
`;
