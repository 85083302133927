import { Empty, Spin } from 'antd';
import InputSearchServices from 'features/bookings/components/BookingForm/QuickBooking/InputSearchServices';
import ListCategory from 'features/bookings/components/BookingForm/QuickBooking/ListCategory';
import { IServiceQuickBookingItem } from 'features/bookings/services/types/quickBooking';
import ServiceItem, { IFuncGetIsCheck, ServiceGroup } from 'features/checkout/components/ServiceSelection/ServiceItem';
import salesSelectors from 'features/sales/services/selectors';
import { findIndex } from 'lodash';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

type Props = {
  handleChooseService?: any;
  currChooseServices?: any;
  isFavorite?: boolean;
};

const ServicesSelection = ({
  handleChooseService,
  isFavorite
}: Props) => {

  const dataConfigs = salesSelectors.getConfigsData();
  const selected = salesSelectors.getSelectedServices();
  const loadingConfigs = salesSelectors.getLoadingConfigsData();
  const merchant_location_id = salesSelectors.getMerchantLocationId();
  const services = useMemo(() => {
    if (!dataConfigs?.services) return [];

    const services = dataConfigs.services.filter(o => {
      if (!o.merchant_location_id || o.merchant_location_id?.length === 0) return true;
      return !!o.merchant_location_id?.find(d => d?.toString() === merchant_location_id?.toString());
    }).map(o => ({
      ...o,
      merchant_employees: o.merchant_employees.filter(o => {
        if (!o.id) return true;
        return o.merchant_location_id?.toString() === merchant_location_id?.toString();
      })
    }));    

    if (isFavorite) {
      return services.filter(o => o.is_favourite === 1);
    }

    return services;
  }, [dataConfigs?.services, merchant_location_id]);


  const categories = useMemo(() => {
    if (services.length === 0) return [];
    return (services.filter(o => o.is_favourite === 1).length > 0 ? [
      {
        id: 'favorite',
        color: '#4A9D77',
        name: 'Favorite',
        total_sale: Number.MAX_SAFE_INTEGER
      },
      // {
      //   id: 'general',
      //   color: '#2f54eb',
      //   name: 'General',
      //   total_sale: Number.MAX_SAFE_INTEGER
      // },
    ] : []).concat(dataConfigs?.categories.filter(o => services.find(e => e.category.id === o.id)) as []);
  }, [services]);

  const [search, setSearch] = useState('');
  const [category, setCategory] = useState<string>('general');


  const _services = useMemo(() => {
    const _search = search.toLowerCase();
    return services.filter(o =>
      category === 'general' ? true 
      : category === 'favorite'? o.is_favourite === 1 
      : String(o.category.id) === category
    ).filter(o => {
      if (o?.name?.toLowerCase().includes(_search)) {
        return true;
      }
      return !!o?.service_variants?.some(s => s.name?.toLowerCase().includes(_search));
    });
  }, [services, category, search, selected]);


  const handleInputChange = (value: string) => {
    setSearch(value);
  };


  const handleServiceChange = (
    isChecked: boolean,
    serviceItem: IServiceQuickBookingItem
  ) => {    
    handleChooseService(serviceItem, isChecked);
  };

  const getIsChecked: IFuncGetIsCheck = ({ service_variant_id, service_id }) => {
    const currentIdx = findIndex(selected, (o) => o.service_variant_id !== null ? o.id === service_id && o.service_variant_id === service_variant_id : o.id === service_id);
    return currentIdx !== -1;
  };  


  return (
    <>
      <Spin spinning={loadingConfigs}>
        <InputSearchServices handleChange={handleInputChange} isGray={true} />
        <ListCategory
          data={categories}
          onChange={setCategory}
          value={category}
          disableScroll
        />
        {_services.length === 0 ?
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No service'} />
          : <>
            <ServicesSelectionStyled>
              <table>
                <tbody>
                  {_services?.map((service) => {
                    if (
                      service.service_variants &&
                      service.service_variants?.length > 0
                    ) {
                      return (
                        <ServiceGroup
                          key={service.id}
                          // @ts-ignore
                          service={service}
                          getIsChecked={getIsChecked}
                          onServiceChange={handleServiceChange}
                        />
                      );
                    }
                    return (
                      <ServiceItem
                        key={service.id}
                        // @ts-ignore
                        service={service}
                        getIsChecked={getIsChecked}
                        onServiceChange={handleServiceChange}
                      />
                    );
                  })}
                </tbody>
              </table>
            </ServicesSelectionStyled>
          </>}
      </Spin>
    </>
  );
};

export default ServicesSelection;

const ServicesSelectionStyled = styled.div`
  @media (max-width: 992.98px) {
    max-height: 230px;
  }
  max-height: 360px;
  overflow-y: scroll;
  table {
    width: 100%;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d9d9d9;
  }

  @media (max-width: 767.98px) {
    min-height: 300px;
  }
`;

