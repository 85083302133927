import React, { useState } from 'react';
import styled from 'styled-components';
import PromotionModal from './Modal';
import { IPromotionFormProps } from './Form';
import apisPromotion from '../services/apis';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { get, set } from 'lodash';
import message from 'components/common/Message';
import { useAppDispatch } from 'store/hooks';
import promotionActions from '../services/actions';
import { Form } from 'antd';
type IAddPromotionProps = {};
const AddPromotion: React.FC<IAddPromotionProps> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => setIsModalOpen(true);
  const hideModal = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();

  const setLoadingPage = useSetLoadingPage();
  const dispatch = useAppDispatch();

  const [errors, setErrors] = useState<IPromotionFormProps['errors']>(null);

  const handleSubmit: IPromotionFormProps['onSubmit'] = async (payload) => {
    setLoadingPage(true);
    try {
      const rest = await apisPromotion.createPromotion(payload);
      const msg = get(rest, 'data.message', '');
      if (msg) {
        hideModal();
        dispatch(promotionActions.setListPromotionParams({
          page: 1,
        }));
        message.success(msg);
      } else {
        const msgError = get(rest, 'data.error.message', '');
        if (msgError) message.error(msgError);
        else throw 'fail';
      }
    } catch (error: any) {
      const errorText = get(error, 'response.data.error.message', '');
      if (errorText) {
        message.error(errorText);
        return;
      }
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.entries(response);
      if (errors.length > 0) {
        const errorsObj = {};
        errors.forEach(([key, value]) => {
          set(errorsObj, [key.includes('services') ? 'services' : key], value?.[0]);
        });
        setErrors(errorsObj);
        errors.map(([, value]) => {
          message.error(get(value, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setLoadingPage(false);
    }

  };

  return (
    <>
      <AddPromotionStyled>
        <button onClick={showModal} className={'common-btn'}>
          Add New
        </button>
      </AddPromotionStyled>
      {isModalOpen && <PromotionModal
        form={form}
        visible={isModalOpen}
        isEdit={false}
        onClose={hideModal}
        onSubmit={handleSubmit}
        errors={errors}
        formType='CREATE'
        handleUpdateError={(data: any) => {
          setErrors({
            ...errors,
            ...data
          });
        }}
      />}
    </>
  );
};

export default AddPromotion;
const AddPromotionStyled = styled.div``;
