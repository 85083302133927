import MultipleSelect from 'components/common/MultipleSelect';
import { memo, useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import timeScheduleSelectors from 'features/timeSchedule/services/selectors';
import { IApiTimeScheduleListReq } from 'features/timeSchedule/types/api';
import timeScheduleActions from 'features/timeSchedule/services/actions';

const TeamMemberSelect = () => {
  const dispatch = useAppDispatch();
  const teamMembers = timeScheduleSelectors.getMerchantTeamMember();
  const value = timeScheduleSelectors.getTimeScheduleParamValue(
    'merchant_employee_id'
  ) as IApiTimeScheduleListReq['merchant_employee_id'];
  const locationValue = (timeScheduleSelectors.getTimeScheduleParamValue('merchant_location_id') ?? 'All') as IApiTimeScheduleListReq['merchant_location_id'];

  const options = useMemo(() => {
    return teamMembers?.filter(it => it?.location_id === locationValue).map((item) => {
      return {
        value: item.id,
        title: item.full_name,
      };
    });
  }, [teamMembers, locationValue]);

  const allValue = options.map((o) => o.value);

  const onChange = async (val: (string | number)[]) => {
    dispatch(
      timeScheduleActions.setListTimeScheduleParams({
        merchant_employee_id: val || [],
      })
    );
  };
  
  return (
    <MultipleSelect
      onChange={onChange}
      disabled={options.length < 1}
      value={
        options.length < 1
          ? ['No employee']
          : value === undefined
          ? allValue
          : value
      }
      options={options}
      isWhiteInput
      maxTagPlaceholder='employees'
      placeholder='Select employees'
      showArrow={true}
    />
  );
};

export default memo(TeamMemberSelect);
