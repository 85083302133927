import salesSelectors from 'features/sales/services/selectors';
import { formatMoney } from 'utils/unit';

const SubTotalAmount = () => {
  const result = salesSelectors.getSubTotal();

  if (!result) return null;

  return (
    <p className='extra-price-row'>
      Subtotal
      <span>{formatMoney(result ?? 0)}</span>
    </p>
  );
};

export default SubTotalAmount;
