import React from 'react';
import bookingFormImage from 'assets/images/booking/bookingForm';
import styled from 'styled-components';

type Props = {
  title?: string;
  text?: string;
};

const Header: React.FC<Props> = (props: Props) => {
  const {
    title = 'Welcome!',
    text = '',
  } = props;

  return (
    <HeaderStyled>
      <div className='header_container'>
        <img className='header_img' src={bookingFormImage.welcome} />
        <div className='header_content'>
          <p className='header_content_title'>{title}</p>
          {text && <p>{text}</p>}
        </div>
      </div>
    </HeaderStyled>
  );
};

export default Header;

const HeaderStyled = styled.div`
  flex-direction: column;
  margin-bottom: 48px;
  position: relative;
  margin-top: 25px;
  @media only screen and (max-width: 767.98px) {
    margin-bottom: 24px;
  }

  .header_container {
    top: 10px;
    right: 0;
    left: 0;
    bottom: 0;
    background: var(--color-white);
    border-radius: 6px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 232px;
    @media only screen and (max-width: 767.98px) {
      display: block;
      height: auto;
    }
  }
  .header_img {
    width: 370px;
    max-width: 40%;
    display: flex;
    align-self: flex-end;
    @media only screen and (max-width: 767.98px) {
      display: block;
      max-width: 100%;
      transform: translateY(-20px);
      margin: 0 auto;
    }
  }
  .header_img_overlay {
    opacity: 0;
    width: 25%;
    margin-right: 5%;
  }
  .header_content {
    display: block;
    padding: 0 20px;
    width: max-content;
    margin: 0 auto;
    max-width: 100%;
    @media only screen and (max-width: 767.98px) {
      text-align: center;
      padding-bottom: 20px;
    }
  }
  .header_content_title {
    font-weight: 600;
    font-size: 32px;
    line-height: 140%;
    color: var(--color-primary);
    @media only screen and (max-width: 767.98px) {
      margin-bottom: 10px;
    }
  }
`;
