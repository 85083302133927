import { message } from 'antd';
import { IMessageProps } from '../type';

const CustomInfoMessage = (props: IMessageProps) => {
  const _text = typeof props === 'string' ? props : props?.text;
  return message.info({
    className: 'is-info',
    content: (
      <p>
        <span>Info :</span>
        {_text}
      </p>
    ),
  });
};

export default CustomInfoMessage;
