import auth_avatar from './auth_avatar.jpg';
import auth_bg from './auth_bg.png';
import logout_image from './logout_image.png';

const auth = {
  auth_avatar,
  auth_bg,
  logout_image,
};

export default auth;
