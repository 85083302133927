import { IModalFeeFormPayload, feeType } from 'features/checkout/services/types/fee';
import React, { useState } from 'react';
import styled from 'styled-components';
import AddFee, { IAddFeeProps } from './Add';
import { getCalcValueFeeLabel } from './hook';
import ModalFee, { IModalFeeSubmitFunc } from './Modal';
import { formatMoney } from 'utils/unit';
export type IExtraServiceData = IModalFeeFormPayload;
type IExtraFeeProps = {
  total?: number
  onAdd?: IAddFeeProps['onAdd'];
  onRemove?: () => void;
  data?: IExtraServiceData | null;
  viewOnly?: boolean;
  type?: feeType;
  amount?: number;
};
const FeeButton: React.FC<IExtraFeeProps> = ({
  // total,
  data = null,
  viewOnly = false,
  onAdd = () => undefined,
  onRemove = () => undefined,
  type = 'extra',
  amount,
}) => {
  if (!data) return <AddFee onAdd={onAdd} type={type} />;
  const [visible, setVisible] = useState(false);
  const handleClose = () => setVisible(false);
  const handleOpen = () => setVisible(true);
  const handleSubmit: IModalFeeSubmitFunc = (values) => {
    onAdd(values);
    handleClose();
  };

  const getFormData = () => {
    const amount = data.type === 'price' ? data.value : undefined;
    const percent = data.type === 'percent' ? data.value : undefined;
    return {
      name: data.name,
      amountValue: amount,
      percentValue: percent,
    };
  };

  return (
    <>
      <FeeStyled>
        {!viewOnly &&
          <button type='button' onClick={onRemove}><IconRemove /></button>}
        <span className='name'>{viewOnly ? data.name : <button onClick={viewOnly ? undefined : handleOpen}>{data.name}</button>}</span>
        <span className='price'>{getCalcValueFeeLabel(type)}{formatMoney(amount ?? 0)}</span>
      </FeeStyled>
      {visible && <ModalFee formData={getFormData()} type={type} visible onCancel={handleClose} onSubmit={handleSubmit} />}

    </>
  );
};

export default FeeButton;
  const FeeStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  flex: 1 0 0;
  button:hover {
    background:var(--color-overlay);
  }
  span {
    color: #363565;
    font-variant-numeric: slashed-zero;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    &.name {
      flex:1;
    }
    &.price {
      text-align: right;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
`;

export const IconRemove = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 8H7.83333H18.5" stroke="#F5222D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.1666 7.9987V17.332C17.1666 17.6857 17.0261 18.0248 16.7761 18.2748C16.526 18.5249 16.1869 18.6654 15.8333 18.6654H9.16659C8.81296 18.6654 8.47382 18.5249 8.22378 18.2748C7.97373 18.0248 7.83325 17.6857 7.83325 17.332V7.9987M9.83325 7.9987V6.66536C9.83325 6.31174 9.97373 5.9726 10.2238 5.72256C10.4738 5.47251 10.813 5.33203 11.1666 5.33203H13.8333C14.1869 5.33203 14.526 5.47251 14.7761 5.72256C15.0261 5.9726 15.1666 6.31174 15.1666 6.66536V7.9987" stroke="#F5222D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);