import React, { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import LocationsTable from '../components/LocationsTable';
import locationActions from '../services/actions';
import { useAppDispatch } from 'store/hooks';

const LocationsPage = () => {

  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(locationActions.getListPaginateLocations.fetch({
      page: Number(searchParams.get('page') ?? 1),
      per_page: Number(searchParams.get('per_page') ?? 10)
    }));
  }, []);

  return (
    <>
      <div className={'private-filter'}>
        <Link to="add-new" className={'common-btn'}>
          Add New
        </Link>
      </div>
      <LocationsTable />
    </>
  );
};

export default LocationsPage;
