const downloadFile = (file_url: string) => {
  try {
    const link = document.createElement('a');
    link.href = file_url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) { }
};

export default downloadFile;
