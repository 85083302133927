import { Spin, Switch } from 'antd';
import PairButton from './Tyro/PairButton';
import settingSelectors from '../services/selectors';

const TyroTab = () => {

  const getSetting = settingSelectors.getSetting();
  const loading = settingSelectors.loadSetting();

  

  return (
    <Spin spinning={loading}>

    <div className='cards-wrapper'>
      <div className='cards-col'>
        <div className='common-card'>
          <div className='form-row'>
            <p className='form-label'>Set up quick sale items</p>
            <Switch defaultChecked />
          </div>
          <div className='form-row'>
            <PairButton 
              tyroMID={getSetting?.general.merchant_id}
              tyroTID={getSetting?.general.teminal_id}
            />
          </div>
          <div className='form-row'>
            <button className='common-btn is-big no-flex' >Request iClient Logs</button>
          </div>
          {/* <div className="form-row">
            <p className='form-label'>For test Tyro Transactions</p>
          </div>
          <div className='form-row'>
            <Form
              autoComplete="off"
              layout="vertical"
              className="modal-form" form={form} initialValues={{ amount: 1 }}>
              <Form.Item label='Amount' name={'amount'}>
                <Input />
              </Form.Item>
            </Form>
          </div>
          <div className='form-row is-wrap' style={{ justifyContent: 'center' }}>
            <button className='common-btn is-big' onClick={onCheckout} >checkout</button>
            <button className='common-btn is-big' onClick={onRefund} >Refund</button>
          </div> */}
        </div>
      </div>
    </div>
    </Spin>
  );
};

export default TyroTab;
