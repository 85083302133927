import { Checkbox, Form } from 'antd';
import IconDecrease from 'assets/svg/IconDecrease';
import IconIncrease from 'assets/svg/IconIncrease';
import { IServiceItem } from 'features/bookings/services/types/service';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { ANONYMOUS_NAME, formatMoney, formatTimeMinutes } from 'utils/unit';
export type IFuncGetIsCheck = (o: { service_id: number, service_variant_id?: number }) => boolean;
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data: IServiceItem;
  onServiceChange: (data: IServiceItem) => void;
  updateQuantity: (id: IServiceItem['id'], service_variant_id: IServiceItem['service_variant_id'], quantity: number) => void;
  selectedList?: IServiceItem[];
}

const ServiceItem: React.FC<Props> = ({ data, className, onServiceChange, updateQuantity, selectedList }) => {
  if (data.subServices && data.subServices?.length > 0) {
    return <ServiceGroup data={data} onServiceChange={onServiceChange} updateQuantity={updateQuantity} selectedList={selectedList} />;
  }
  const selectedItem = selectedList?.find(o => o.id === data.id && o.service_variant_id === data.service_variant_id);
  const isChecked = !!selectedItem;
  const employeeName = useMemo(() => {    
    return selectedItem ? (!selectedItem?.employee_id ? ANONYMOUS_NAME : data.merchant_employees?.find(o => o.id === Number(selectedItem?.employee_id))?.full_name) : '';
  }, [selectedItem]);
  const quantity = selectedItem?.quantity ?? 1;

  const increase: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();
    updateQuantity(data.id, data.service_variant_id, quantity + 1);
  };

  const decrease: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();
    if (quantity === 1) return;
    updateQuantity(data.id, data.service_variant_id, quantity - 1);
  };

  return (
    <ServiceItemStyled className={className} onClick={() => onServiceChange(data)}>
      <td className='service_content'>
        <div className='group_name'>
          <div className='checkbox'>
            <Form.Item name='service'>
              <Checkbox checked={isChecked} />
            </Form.Item>
          </div>
          <div className='flex-spacing' >
            <div>
              <p className='service_name'>{data.name} </p>
              <p className='service_time'>
                {(data.duration ? formatTimeMinutes(data.duration) : '') + (employeeName !== '' ? ` with ${employeeName}` : '')}
              </p>
            </div>
            <div className={`quantity-control ${isChecked ? 'checked' : 'none-checked'}`} onClick={e => e.stopPropagation()}>
              <span className={'control-btn'} onClick={decrease}>
                <IconDecrease />
              </span>
              <span className='quantity-value'>{selectedItem?.quantity}</span>
              <span className='control-btn' onClick={increase}>
                <IconIncrease />
              </span>
            </div>
          </div>
        </div>
      </td>
      <td className='service_amount'>
        <p>{formatMoney(data?.price ?? 0)}</p>
      </td>
    </ServiceItemStyled>
  );
};

export default ServiceItem;

const ServiceGroup: React.FC<Props> = ({ data, className, onServiceChange, updateQuantity, selectedList }) => {
  const [open, setOpen] = useState(true);
  const toggle = () => setOpen(!open);
  if (data?.subServices && data.subServices?.length === 0) return null;  

  return (
    <React.Fragment>
      <ServiceItemStyled className={`have_child ${className}`} onClick={toggle}>
        <td className='service_content'>
          <div className='group_name'>
            <button type='button' className='checkbox'>
              {open ? <IconUp /> : <IconDown />}
            </button>
            <div>
              <p className='service_name'>{data.name}</p>
            </div>
          </div>
        </td>
        <td className='service_amount'>
        </td>
      </ServiceItemStyled>
      {open &&
        data?.subServices?.map((item) => (
          <ServiceItem
            className='is_child'
            key={item.id + '-' + item.service_variant_id}
            data={item}
            onServiceChange={onServiceChange}
            updateQuantity={updateQuantity}
            selectedList={selectedList}
          />
        ))}
    </React.Fragment>
  );
};

const IconDown = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.26562 6.94238L8.26563 10.9424L12.2656 6.94238" stroke="#363565" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

const IconUp = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.2656 10.9424L8.26563 6.94238L4.26562 10.9424" stroke="#363565" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const ServiceItemStyled = styled.tr`
  &:hover {
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  &:not(.have_child):hover {
    cursor: pointer;
    background: #F0F2F7;
  }
  .flex-spacing {
    display:flex;
    flex: 1;
    justify-content: space-between;
  }
  .quantity-control {
    &.none-checked {
      display:none;
    }
    &.checked {
      display:flex;
    }
  }
  &.is_child {
    background-color: var(--color-white-01);
    padding-left: 10px;
    .group_name {
      padding-left: 28px;
    }
    &:hover {
      background-color:#e1e2e6;
    }
  }
  button {
    background: transparent;
    outline: none;
    border: none;
  }
  td {
    vertical-align: middle;
    padding: 10px 0px;
    color: var(--color-primary);
  }

  .service_content {
    padding-left: 8px;
    width: 100%;
    .checkbox {
      margin-right: 16px;
    }
    .group_name {
      display: flex;
      align-items: center;
      @media only screen and (max-width: 767.98px) {
        width: 150px
      }
    }
    .service_name {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #363565;
    }
    .service_time {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      color: #363565;
    }
  }
  .service_amount {
    padding-right: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: right;
    .label {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: #363565;
    }
  }
`;
