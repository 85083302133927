import { call, put, select, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import userApis from './apis';
import { AxiosResponse } from 'axios';
import { ISagaFunc } from 'services/actionConfigs';
import { IApiTeamMembersParams, ITeamMemberItemResData } from './types/teamMember';
import { setLoading } from 'services/UI/sagas';
import { PATH_LOADING } from './constants';
import apis from './apis';
import { IReqApiListUser } from './types/reducer';
import { getPureParams } from './selectors';


const getTeamMembers: ISagaFunc<IApiTeamMembersParams> = function* ({ payload }) {
  try {
    const res: AxiosResponse<{ data: ITeamMemberItemResData[] }> = yield call(userApis.getTeamMembers, payload);
    if (res?.data?.data) {
      yield put(actions.getTeamMembers.success(res.data.data));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getTeamMembers.fail({}));
  }
};

const getListPaginateStaffs = function* (payload: any) {
  try {

    const params = payload.payload;

    yield setLoading(PATH_LOADING.getListPaginateStaffs, true);
    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getListPaginateStaffs, params);


    if (res?.data) {
      const customerList = res?.data;
      yield put(actions.getListPaginateStaffs.success(customerList));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getListPaginateStaffs.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getListPaginateStaffs, false);
  }
};

const getShowCreateStaffs = function* () {
  try {

    yield setLoading(PATH_LOADING.getShowCreateStaffs, true);
    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getShowCreateStaffs);

    if (res?.data?.data) {
      const customerList = res?.data?.data;
      yield put(actions.getShowCreateStaffs.success(customerList));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getShowCreateStaffs.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getShowCreateStaffs, false);
  }
};

const getProfileStaffDetail: ISagaFunc<any> = function* () {
  try {

    yield setLoading(PATH_LOADING.getProfileStaffDetail, true);
    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getProfileStaffDetail);
    if (res?.data?.data) {
      yield put(actions.getProfileStaffDetail.success(res.data.data));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getProfileStaffDetail.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getProfileStaffDetail, false);
  }
};

const setParams: ISagaFunc<IReqApiListUser> = function* ({ payload }) {
  const storeParams = yield select(getPureParams);
  const _params = {
    ...storeParams,
    ...payload ?? {},
  };

  if (_params['keyword'] === undefined) {
    delete _params.keyword;
  }

  yield put(actions.getListPaginateStaffs.fetch(_params));
};

export default function* userServiceSaga() {
  yield takeLatest(actions.getTeamMembers.fetch, getTeamMembers);

  yield takeLatest(actions.getListPaginateStaffs.fetch, getListPaginateStaffs);
  yield takeLatest(actions.getShowCreateStaffs.fetch, getShowCreateStaffs);

  yield takeLatest(actions.getProfileStaffDetail.fetch, getProfileStaffDetail);
  yield takeLatest(actions.setParams, setParams);
}
