import { Tag } from 'antd';
import topUpSetting from 'assets/images/setting/top_up_setting.png';
import { useState } from 'react';
import styled from 'styled-components';
import TopUpModal from './TopupModal';
import { useTranslation } from 'react-i18next';
import { TopUpType } from 'features/settings/services/types/topUp';
import settingApis from 'features/settings/services/apis';
import { useDispatch } from 'react-redux';
import settingActions from 'features/settings/services/actions';
import settingSelectors from 'features/settings/services/selectors';

export interface ITopUpChargeForm {
  topUp: number;
  autoChargeWhen?: number;
  notifyYourWhen?: number;
  cardHolderFullName: string;
  cardNumber: number;
  expiryDate: string;
  cvv: number;
  abnNumber: number;
}

const SetupTopUpCard = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [progressNumber, setProgressNumber] = useState<number>(0);
  const [topUpType, setTopUpType] = useState<TopUpType | ''>('');
  const [isDisableSubmit, setIsDisableSubmit] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { t: topUpLang } = useTranslation('top_up');
  const loadingGetMerchantBalance = settingSelectors.loadMerchantBalance();
  const merchantBalance = settingSelectors.getMerchantStripeBalance();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancelWithStep = () => {
    if (progressNumber === 0 || progressNumber === 2) {
      setProgressNumber(0);
      setIsModalOpen(false);
    } else {
      setProgressNumber(progressNumber - 1);
    }
  };

  const handleClose = () => {
    setTopUpType('');
    setProgressNumber(0);
    setIsModalOpen(false);
  };

  const handleChangePopUpType = (type: TopUpType) => {
    setTopUpType(type);
  };

  const getPayload = (values: any) => {
    return {
      topUp: values?.topUp,
      autoChargeWhen: values?.autoChargeWhen,
      notifyYourWhen: values?.notifyYourWhen,
      cardHolderFullName: values?.cardHolderFullName,
      cardNumber: values?.cardNumber,
      expiryDate: values?.expiryDate,
      cvv: values?.cvv,
      abnNumber: values?.abnNumber,
    };
  };

  const handleModalStep = () => {
    if (progressNumber === 0 && topUpType) {
      if (topUpType === TopUpType.MANUALLY) {
        dispatch(settingActions.getMerchantStripeBalance.fetch());
      }

      setProgressNumber(1);
    }
  };

  const handleSubmit = async (values: ITopUpChargeForm) => {
    setIsDisableSubmit(true);
    const payload = getPayload(values);
    try {
      setProgressNumber(2);
      const rest = await settingApis.postTopUpCharge(payload);
      setIsDisableSubmit(false);

      if (rest) {
        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      setIsDisableSubmit(false);
      return false;
    }
  };

  return (
    <SetupTopUpCardStyled>
      <div className='cards-wrapper'>
        <div className='cards-col'>
          <div className='common-card'>
            <div className='d-flex no-wrap'>
              <div className='top-up-left'>
                <p className='setup-topup-title'>
                  {topUpLang('topUpCard.title')}
                </p>
                <p className='setup-topup-desc'>
                  You get <span>100 free text messages</span> monthly to use for
                  automated client messages. Use automatic top-ups to make sure
                  your texts always keep sending.
                </p>
                <div>
                  <Tag color='green' className='top-up-money'>
                    $0.05 per text message
                  </Tag>
                </div>

                <button
                  className='common-btn is-big no-flex'
                  onClick={showModal}
                >
                  Top up
                </button>
              </div>
              <div className='top-up-right'>
                <img src={topUpSetting} alt='topup-setting' />
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <TopUpModal
          isModalOpen={isModalOpen}
          handleCancelWithStep={handleCancelWithStep}
          handleClose={handleClose}
          isDisableSubmit={isDisableSubmit}
          handleChangePopUpType={handleChangePopUpType}
          topUpType={topUpType}
          progressNumber={progressNumber}
          handleSubmit={handleSubmit}
          handleModalStep={handleModalStep}
          loading={loadingGetMerchantBalance}
          merchantBalance={merchantBalance}
        />
      )}
    </SetupTopUpCardStyled>
  );
};

const SetupTopUpCardStyled = styled.div`
  .setup-topup-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .setup-topup-desc {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .top-up-money {
    border: none;
    margin-bottom: 24px;
  }

  .d-flex {
    gap: 40px;
    justify-content: flex-start;
    .top-up-left {
      width: 70%;
    }

    .top-up-right {
      width: 30%;
    }
  }
`;

export default SetupTopUpCard;
