import dayjs from 'utils/dayjs';
import { EditWorkingHourFormValue, IMemberViewItem } from 'features/workingHour/services/types/member';
import React, { useState } from 'react';
import EditWorkingHourModal from './Modal';
import moment from 'moment';
import { get, set } from 'lodash';
import { useDispatch } from 'react-redux';
import apisWorkingData from 'features/workingHour/services/apis';
import workingHourActions from 'features/workingHour/services/actions';
import workingHourSelectors from 'features/workingHour/services/selectors';
import message from 'components/common/Message';
type IEditWorkingTimeProps = {
  data?: IMemberViewItem;
  employee_id?: number;
};
const EditWorkingTime: React.FC<IEditWorkingTimeProps> = ({ data, employee_id }) => {
  const [formData, setFormData] = useState<EditWorkingHourFormValue | null>(null);
  const params = workingHourSelectors.getParams();
  const dispatch = useDispatch();
  const handleOpen = () => {
    
    setFormData({
      date: dayjs(data?.date),
      times: data?.time?.map(o => ({ start: dayjs(moment(o?.start).format()), end: dayjs(moment(o?.end).format()) })) ?? [],
    });
  };
  const handleClose = () => setFormData(null);
  const handleSubmit = async (values: any) => {
    handleClose();
    const payload = {
      date: values.date.format('YYYY-MM-DD'),
      work_times: values.times.map((o: any) => ({
        check_in: o.start.format('HH:mm:ss'),
        check_out: o.end.format('HH:mm:ss')
      }))
    };
    
    try {
      const res = await apisWorkingData.updateWorkingTime(employee_id ?? 0, payload);
      const msg = get(res, 'data.message');
      if (msg) {
        message.success(msg);
        dispatch(workingHourActions.setParams(params));
      } else {
        const errorMsg = get(res, 'data.error.message');
        if (errorMsg) message.error(errorMsg);
        else throw 'fail';
      }

    } catch (error) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));

      if (errors.length > 0) {
        const _errors = {};
        errors.map(([key, valueArr]) => {
          const msg = get(valueArr, [0], '');
          message.error(msg);
          set(_errors, [key], msg);
        });
        // setErrors(_errors);
        return;
      }
      message.error('An error occurred. Please try again');
    }
    
  };
  return (
    <>
      <li className='action-edit' onClick={handleOpen}></li>
      {formData && <EditWorkingHourModal formData={formData} visible onSubmit={handleSubmit} onCancel={handleClose} />}
    </>
  );
};

export default EditWorkingTime;
