import { useEffect } from 'react';
import CategoryFilter from '../components/category/CategoryFilter';
import CategoryTable from '../components/category/CategoryTable';
import { useDispatch } from 'react-redux';
import serviceActions from '../services/actions';
import { useSearchParams } from 'react-router-dom';
import locationActions from 'features/locations/services/actions';

const CategoriesPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(serviceActions.getShowCreateService.fetch());
    dispatch(locationActions.getListPaginateLocations.fetch({ per_page: 9999 }));
    const params = {
      page: Number(searchParams.get('page') ?? 1),
      per_page: Number(searchParams.get('per_page') ?? 10),
      keyword: searchParams.get('keyword') ?? undefined,
    };

    if (params.keyword === undefined || params.keyword === null) {
      delete params.keyword;
    }

    dispatch(serviceActions.category.setParams(params));
  }, []);
  return (
    <>
      <CategoryFilter />
      <CategoryTable />
    </>
  );
};

export default CategoriesPage;