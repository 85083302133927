import { Image, Spin } from 'antd';
import IconProgram from 'assets/icons/ico_programs.svg';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import { useRef } from 'react';
import styles from '../pages/index.module.scss';
import loyalSelectors from '../services/selectors';
import ProgramEdit from '../widgets/Program/Edit';
import ProgramRemove from '../widgets/Program/Remove';
import ProgramSwitchStatus from '../widgets/Program/SwitchStatus';
import formatNumber from 'utils/formatNumber';

const ProgramsTable = () => {
  const confirmRef = useRef<ModalConfirmRef>(null);
  const loadingListLoyalProgram = loyalSelectors.loadingListLoyalProgram();
  const listLoyalProgram = loyalSelectors.getListLoyalProgram();
  return (
    <Spin spinning={loadingListLoyalProgram}>
      <div className='card-body'>
        <ul className={styles.programs_list}>
          {listLoyalProgram?.map((o, index) => (
            <li key={index}>
              <div>
                <Image preview={false} src={IconProgram} alt='icon-program'></Image>
              </div>
              <div className={styles.item_heading}>
                <p className={styles.item_title}>
                  {o.earn_point === 'amount_spent' ? `Spend $${formatNumber(o.amount_spent)} or more in one visit` : `Visit ${o.name_location.toLocaleLowerCase()}`}
                </p>
                <p className={styles.item_point}>{o.point} point(s)</p>
              </div>
              <ProgramSwitchStatus data={o} confirmRef={confirmRef} />
              <ul className={'common-action'}>
                <ProgramEdit data={o} />
                <ProgramRemove data={o} confirmRef={confirmRef} />
              </ul>
            </li>
          ))}
        </ul>
      </div>
      <ModalConfirm ref={confirmRef} />
    </Spin>
  );
};

export default ProgramsTable;
