import { AxiosResponse } from 'axios';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import apis from './apis';
import { setLoading } from 'services/UI/sagas';
import { PATH_LOADING } from './constants';
import actions from './actions';
import { ISagaFunc } from 'services/actionConfigs';
import { IApiServiceParams } from './types/services';
import { getPureCategoryParams, getPureParams } from './selectors';
import { IApiCategoryParams, ICategoryResData } from './types/category';

const getListPaginateServices = function* (payload: any) {
  try {
    const params = payload.payload;
    yield setLoading(PATH_LOADING.getListPaginateServices, true);
    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getListPaginateServices, params);

    if (res?.data) {
      const customerList = res?.data;
      yield put(actions.getListPaginateServices.success(customerList));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getListPaginateServices.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getListPaginateServices, false);
  }
};

const getShowCreateService = function* (payload: any) {
  try {

    const params = payload.payload;
    yield setLoading(PATH_LOADING.getShowCreateService, true);
    yield delay(200);
    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getShowCreateServices, params);

    if (res?.data?.data) {
      const customerList = res?.data.data;
      yield put(actions.getShowCreateService.success(customerList));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getShowCreateService.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getShowCreateService, false);
  }
};

const setServiceListParams: ISagaFunc<IApiServiceParams> = function* ({ payload }) {
  const _params: IApiServiceParams = yield select(getPureParams);
  const params: IApiServiceParams = {
    ..._params,
    ...payload ?? {},
  };

  if (params.keyword === undefined || params.keyword === null) {
    delete params.keyword;
  }
  if (params.page === undefined || params.page === null) {
    params.page = 1;
  }

  if (params.per_page === undefined || params.per_page === null) {
    params.per_page = 10;
  }
  yield put(actions.getListPaginateServices.fetch(params));
};

const getLstDataCategory: ISagaFunc<IApiCategoryParams> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getCategoryData, true);
  try {
    const res: AxiosResponse<{ data: ICategoryResData }> = yield call(apis.category.getData, payload);
    if (res?.data?.data) {
      yield put(actions.category.getLstData.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.category.getLstData.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getCategoryData, false);
  }
};

const setParamsCategory: ISagaFunc<IApiCategoryParams> = function* ({ payload }) {
  const stateParams = yield select(getPureCategoryParams);
  const _params = {
    ...stateParams ?? {},
    ...payload ?? {},
  };

  if (_params.keyword === undefined || _params.keyword === null) {
    delete _params.keyword;
  }

  if (_params.location_id === undefined || _params.location_id === null) {
    delete _params.location_id;
  }

  yield put(actions.category.getLstData.fetch(_params));
};

export default function* serviceServiceSaga() {
  yield takeLatest(actions.setServiceListParams, setServiceListParams);
  yield takeLatest(actions.getListPaginateServices.fetch, getListPaginateServices);
  yield takeLatest(actions.getShowCreateService.fetch, getShowCreateService);
  yield takeLatest(actions.category.setParams, setParamsCategory);
  yield takeLatest(actions.category.getLstData.fetch, getLstDataCategory);
}

