export const PATH = {
  bookings: '/private/bookings',
  quick_booking: '/private/bookings/quick-booking',
  edit_booking: '/private/bookings/edit-booking',
  checkout: '/private/bookings/checkout/',
  sales: '/private/sales',
  customers: '/private/customers',
  customersDetail: '/private/customers/',
  services: '/private/services',
  categories: '/private/services/categories',
  analytics: '/private/analytics',
  invoices: '/private/invoices',
  invoicesDetail: '/private/invoices/',
  more: '/private/more',
  reviews: '/private/reviews',
  promotions: '/private/promotions',
  vouchers: '/private/vouchers',
  locations: '/private/locations',
  locations_addnew: '/private/locations/add-new',
  locations_edit: '/private/locations/edit/',
  loyaltyProgram: '/private/loyalty-program',
  users: '/private/users',
  workingHour: '/private/working-hour',
  timeSchedule: '/private/time-schedule',
  workingHourDetail: '/private/working-hour/detail',
  placeholder: '/private/placeholder',
  settings: '/private/settings',
  editProfile: '/private/edit-profile',
  notification: '/private/notification',
};