
import { EPaymentMethod } from 'features/sales/services/constants';
import salesSelectors from 'features/sales/services/selectors';
import React, { useMemo } from 'react';
import { formatMoney } from 'utils/unit';

const ResultAmountRow = ({}: {step: number}) => {
  const totalOriginal = salesSelectors.getOriginTotalPrice();
  const vouchersValue = salesSelectors.getVoucherValue();
  const voucherMix = salesSelectors.getMixPayments().find(o => o.type === EPaymentMethod.VOUCHER);
  const method = salesSelectors.getPaymentMethod();

  const getTotal = useMemo(() => {
    const voucherValue = method === EPaymentMethod.VOUCHER ? vouchersValue ?? 0 : voucherMix?.value ?? 0;
    return totalOriginal - voucherValue;
  }, [method, totalOriginal, vouchersValue, voucherMix]);

  return (
    <>
      <p className='total'>
        TOTAL
        <span>{formatMoney(getTotal > 0 ? getTotal : 0)}</span>
      </p>
      <div className='form-note'>*All prices include GST</div>
    </>
  );
};

export default ResultAmountRow;
