import { Tabs } from 'antd';
import LocationPicker from '../LocationPicker';
import ServicesTab from './ServicesTab';
import VouchersTab from './VouchersTab';
import CustomerDetail from 'features/bookings/components/BookingForm/QuickBooking/CustomerDetail';
import salesHook from 'features/sales/hooks/salesHook';

const BlockInformation = ({step}: {step: number}) => {
  const [currCustomer, setCurrCustomer] = salesHook.useSetCustomerBooking();

  const items = [
    // TODO: NEXT PHRASE
    // {
    //   key: '1',
    //   label: 'Favorite',
    //   children: <ServicesTab isFavorite={true}/>,
    // },
    {
      key: '2',
      label: 'Services',
      children: <ServicesTab/>,
    },
    {
      key: '3',
      label: 'Vouchers',
      children: <VouchersTab />,
    },
  ];



  return (
    <div className='block'>
      <LocationPicker />
      <CustomerDetail
        viewOnly={true}
        isInProgress={step !== 1}
        isTotalWillPointEarn={step === 3}
        currCustomer={currCustomer}
        setCurrCustomer={setCurrCustomer}
      />
      <div className='block-tabs'>
        <Tabs defaultActiveKey='1' items={items} />
      </div>
    </div >
  );
};

export default BlockInformation;
