import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { IStateMerchant, IStateUser } from './types/reducer';


type MyState = RootState['auth'];

const getCurrentState = (state: RootState): MyState => state.auth;

const selector = (key: keyof MyState, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getUserInfo = () => selector('user') as (IStateUser | null);
const getMerchantInfo = () => selector('merchant') as (IStateMerchant | null);

const getToken = () => selector('access_token') as string;

const authSelectors = {
  getUserInfo,
  getMerchantInfo,
  getToken,
};
export default authSelectors;