import fetch from 'services/request';
import { IApiAnalyticParam } from './types/api';

const baseURL = 'api/v1/:merchant_code/analytic';

export const getAnalytic = (params: IApiAnalyticParam) => {
  return fetch({
    method: 'get',
    url: `${baseURL}/all-analytic`,
    params: params as any
  });
};

export const exportAnalytic = (param: IApiAnalyticParam) => {
  return fetch({
    method: 'get',
    url: `${baseURL}/export`,
    params: param as any
  });
};


const apisAnalytic = {
  getAnalytic,
  exportAnalytic,
};

export default apisAnalytic;
