import salesSelectors from 'features/sales/services/selectors';
import { formatMoney } from 'utils/unit';

const RewardResultRow = () => {
  const result = salesSelectors.getRewardValue();

  if (!result) return null;

  return (
    <p className='extra-price-row'>
      Reward
      <span>- {formatMoney(result)}</span>
    </p>
  );
};

export default RewardResultRow;
