import { Form, Input, Modal } from 'antd';
import invoiceSelectors from 'features/invoices/services/selectors';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InvoiceReviewEmail from '../InvoiceReviewEmail';

const { TextArea } = Input;
const CustomMessage = () => {
  const [form] = Form.useForm();
  const data = invoiceSelectors.detailInvoice.getData();  
  const [isOpenModalReview, setIsOopenModalReview] = useState(false);

  useEffect(() => {
    form.setFieldValue('message', data?.noted_merchant);
  }, [data]);

  const onOpenReview = () => {
    setIsOopenModalReview(true);
  };

  const onCloseReview = () => {
    setIsOopenModalReview(false);
  };

  return (
    <>
      <div className="common-card">
        <h2 className="card-title">Appointment Note</h2>
        
        <div className="card-body">
          <Form
            form={form}
            initialValues={{
              message: data?.noted_merchant ?? '',
            }}
          >
            <div className="form-row">
              <Form.Item name="message">
                <TextArea disabled rows={6} value={data?.noted_merchant} placeholder={'Enter a message...'} />
              </Form.Item>
            </div>
          </Form>
          <ReviewEmailStyled onClick={onOpenReview}>
            <IconHelper/>              
            <p>You can review the email content before sending it to the customer.</p>
            <IconRightArrow/>
          </ReviewEmailStyled>
        </div>
      </div>
      <Modal
        maskClosable={false}
        open={isOpenModalReview}
        onCancel={onCloseReview}
        okButtonProps={{ style: { display: 'none' } }}
        width={'95%'}
        footer={null}
        centered
        forceRender
      >
        <InvoiceReviewEmail data={data}/>
      </Modal>
    </>
  );
};

export default CustomMessage;

const ReviewEmailStyled = styled.div`
  background-color: #F4FFFA;
  color: #4A9D77;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
  cursor: pointer;
`;

const IconHelper = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#4A9D77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.08984 9.00057C9.32495 8.33224 9.789 7.76868 10.3998 7.4097C11.0106 7.05073 11.7287 6.91951 12.427 7.03928C13.1253 7.15906 13.7587 7.52209 14.2149 8.0641C14.6712 8.6061 14.9209 9.29209 14.9198 10.0006C14.9198 12.0006 11.9198 13.0006 11.9198 13.0006" stroke="#4A9D77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 17H12.01" stroke="#4A9D77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

const IconRightArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M9 18L15 12L9 6" stroke="#4A9D77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
