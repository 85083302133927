import { Table } from 'antd';
import analyticSelectors from '../services/selectors';
import { IService } from '../services/types/api';
import { ColumnsType } from 'antd/es/table';
import styled from 'styled-components';
import { formatMoney } from 'utils/unit';

const SalesTable = () => {

  const categoryOfSale = analyticSelectors.getListService();

  const dataSource = categoryOfSale;


  const columns: ColumnsType<any> = [
    {
      title: 'Service Category Name',
      dataIndex: 'name',
      key: 'name',
      width: '35%'
    },
    {
      title: 'Quantity',
      dataIndex: 'total_deal',
      key: 'total_deal',
      width: '35%',
      render: (text: string) => {
        return (<p> {text}</p>);
      }
    },
    {
      title: 'Amount',
      dataIndex: 'total_amount',
      key: 'total_amount',
      width: 120,
      className: 'total_amount',
      render: (text: string, o: IService) => {
        return (
          <p>
            <span>{formatMoney(+text)}</span>
            <span className={`common-growth ${o.percent < 0 ? 'is-reduce' : ''}`}>{o.percent}%</span>
          </p>
        );
      },
    },
  ];

  return (
    <SalesTableStyled>
      <Table
        className={'custom-table'}
        dataSource={dataSource}
        columns={columns}
        // scroll={{
        //   x: 900,
        // }}
        pagination={false}
      />
    </SalesTableStyled>
  );
};

export default SalesTable;

const SalesTableStyled = styled.div`
  .ant-table-cell.total_amount {
    
    p {
      display: flex;
      flex-wrap: nowrap;
    }
  }
`;
