const IconDecrease = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.1665 12.0002C17.1665 12.3684 16.868 12.6668 16.4998 12.6668L8.49984 12.6668C8.13165 12.6668 7.83317 12.3684 7.83317 12.0002C7.83317 11.632 8.13165 11.3335 8.49984 11.3335L16.4998 11.3335C16.868 11.3335 17.1665 11.632 17.1665 12.0002Z'
        fill='currentColor'
      />
      <rect x='1' y='0.5' width='23' height='23' rx='3.5' stroke='currentColor' />
    </svg>
  );
};

export default IconDecrease;
