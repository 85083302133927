import { Form, Input, Modal, Radio, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import DatePicker from 'components/common/DatePickerInput';
import FormRow from 'components/common/Form/FormRow';
import message from 'components/common/Message';
import PhoneNumberInput from 'components/common/PhoneNumberInput';
import customerActions from 'features/customers/services/actions';
import apisCustomer from 'features/customers/services/apis';
import { IApiCreateNewCustomerBody, ICustomerItemResData } from 'features/customers/services/types/cutomer';
import {
  IFormAddCustomerData,
  IFuncSubmit,
} from 'features/customers/widgets/AddNewCustomer/CustomersForm';
import { get, set } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import NumberValidations from 'utils/validations/number';
export type InitialDataFormAdd = {
  phoneNumber?: IFormAddCustomerData['phoneNumber'],
  name?: IFormAddCustomerData['name'],
  email?: IFormAddCustomerData['email'],
  importantClientInfo?: IFormAddCustomerData['importantClientInfo'],
  walkIn?: boolean,
} | undefined;
const { TextArea } = Input;
type Props = {
  isOpenModal: boolean;
  handleCancel: any;
  initialData?: InitialDataFormAdd,
  setCustomer: (val: ICustomerItemResData | null) => void;
};

const NewCustomerModal = ({ isOpenModal, handleCancel, initialData, setCustomer }: Props) => {
  const dispatch = useAppDispatch();
  const [isWalkIn, setIsWalkIn] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { t: formLang } = useTranslation('form');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const momentDate = moment().utcOffset(10).format('YYMMDDHHmmss');

  useEffect(() => {
    if (initialData?.phoneNumber) {
      form.setFieldValue('phoneNumber', initialData.phoneNumber);
    }

    form.setFieldValue('name', isWalkIn ? 'walkin' + momentDate : '');
    form.setFieldValue('phoneNumber', isWalkIn ? '04' + momentDate.toString().substring(4, 12) : '');
    
  }, [isWalkIn]);

  useEffect(() => {
    form.setFieldsValue(initialData ?? null);
    setIsWalkIn(!!initialData?.walkIn);
  }, [initialData]);

  const getPayload = (data: IFormAddCustomerData) => {
    const merchant_id = storage.merchantId.get() ?? '';
    const name = data?.name;
    const phone_number = data?.phoneNumber ?? '';
    const email = data?.email ?? '';
    const gender = data?.gender ?? null;
    const date_of_birth = data.birthDate ? data.birthDate.format('YYYY-MM-DD') : null;

    const payload: IApiCreateNewCustomerBody = {
      merchant_id,
      name,
      phone_number,
      email,
      gender,
      is_walkin_in: isWalkIn,
      date_of_birth,
      important_client_info: data.importantClientInfo ?? '',
    };
    return payload;
  };

  const handleSubmit: IFuncSubmit = async (data) => {
    const payload = getPayload(data);
    setLoading(true);
    try {
      const rest: AxiosResponse<{ message: string, data: ICustomerItemResData }> = await apisCustomer.createNewCustomer(payload);
      if (rest.data) {
        message.success(rest?.data?.message);
        const params = {
          keyword: payload.phone_number
        };
        const res: AxiosResponse<{ data: ICustomerItemResData[] }> = await apisCustomer.getListCustomers(params);
        if (res?.data?.data) {
          const customerList = res?.data?.data;
          dispatch(customerActions.getListCustomers.success(customerList));
          const currentCus = customerList.find(o => o.phone_number === payload.phone_number) ?? null;
          const walkInCus = { ...rest?.data?.data ?? {}, is_walkin_in: true };
          setCustomer(currentCus || walkInCus);
        }
        handleCancel();
        return true;
      }
      return false;
    } catch (error: any) {
      const textError = get(error, 'response.data.error.message', '');
      if (textError) {
        message.error(textError);
      }

      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.entries(response);
      const errorsObj = {};
      errors.forEach(([key, value]) => {
        set(errorsObj, [key], value?.[0]);
      });
      setErrors(errorsObj);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleValueChange = (value: any) => {
    // eslint-disable-next-line no-prototype-builtins
    if (value.hasOwnProperty('walkIn')) {
      setIsWalkIn(value.walkIn);
    }
  };

  const errorsText = useMemo(() => {
    return ({
      name: get(errors, 'name', ''),
      phone_number: get(errors, 'phone_number', ''),
      email: get(errors, 'email', ''),
      date_of_birth: get(errors, 'date_of_birth', ''),
      important_client_info: get(errors, 'important_client_info', ''),
    });
  }, [errors]);


  const myFocus = useRef<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);

  return (
    <ModalStyled
      maskClosable={false}
      open={isOpenModal}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <h2 className='modal-title'>
        <p>Add New Customer</p>
      </h2>
      <Spin spinning={loading}>
        <Form
          name='basic'
          className='modal-form'
          onFinish={handleSubmit}
          autoComplete='off'
          form={form}
          onValuesChange={handleValueChange}
          onKeyUp={(e) => {
            e.preventDefault();
            if(e.key === 'Enter') {
              form.submit();
            }
          }}
          initialValues={{
            phoneNumberCode: '+61',
            walkIn: false,
            gender: 0,
          }}
          layout='vertical'
        >

          {/* <Form.Item name='walkIn' valuePropName='checked'>
            <div className='form-row'>
              <p className='form-label'>Walk-In Customer</p>
              <Switch defaultChecked={isWalkIn} onChange={() => setIsWalkIn(!isWalkIn)} />
            </div>
          </Form.Item> */}
          <Form.Item
            name='name'
            label='Name'
            requiredMark={false}
            rules={[{ required: true, message: 'Name is required' }]}
            validateStatus={errorsText.name ? 'error' : undefined}
            help={errorsText.name ? errorsText.name : undefined}
          >
            <Input ref={myFocus} autoFocus placeholder={formLang('EnterCustomerNamePlaceholder') || ''}></Input>
          </Form.Item>

          <Form.Item
            label={'Phone number'}
            name='phoneNumber'
            requiredMark={false}
            rules={[
              {
                required: true,
                message: 'Phone number is required',
              },
              NumberValidations.PHONE
            ]}
            validateStatus={errorsText.phone_number ? 'error' : undefined}
            help={errorsText.phone_number ? errorsText.phone_number : undefined}
          >
            <PhoneNumberInput placeholder={formLang('EnterNumberPlaceholder') || ''} />
          </Form.Item>

          <FormRow
            name='email'
            label={'Email'}
            rules={[{ type: 'email', message: 'is not a valid email!' },]}
            requiredMark={false}
            optional
            validateStatus={errorsText.email ? 'error' : undefined}
            help={errorsText.email ? errorsText.email : undefined}
          >
            <Input placeholder={formLang('EnterCustomerEmailPlaceholder') || ''} />
          </FormRow>

          <FormRow label='Gender' name='gender' optional>
            <Radio.Group>
              <Radio value={0}>Female</Radio>
              <Radio value={1}>Male</Radio>
              <Radio value={2}>Non-binary</Radio>
            </Radio.Group>
          </FormRow>

          <FormRow
            name="birthDate"
            label='Birth Date'
            optional
            validateStatus={errorsText.date_of_birth ? 'error' : undefined}
            help={errorsText.date_of_birth ? errorsText.date_of_birth : undefined}
          >
            <DatePicker clearIcon={false} />
          </FormRow>

          <Form.Item
            className='important-note'
            label={
              <p>
                {formLang('ImportantClientInfoLabel')}
                <span>(Optional)</span>
              </p>
            }
            name='importantClientInfo'
            extra={'This note will be displayed on all bookings. '}
            validateStatus={errorsText.important_client_info ? 'error' : undefined}
            help={errorsText.important_client_info ? errorsText.important_client_info : undefined}
          >
            <TextArea
              rows={5}
              placeholder={'Eg. customer allergic to fragrance'}
            />
          </Form.Item>
          <div className='form-row form-submit-wrapper'>
            <button className='common-btn is-white' onClick={handleCancel}>
              Cancel
            </button>
            <button type='submit' className='common-btn'>Save</button>
          </div>
        </Form>
      </Spin>
    </ModalStyled>
  );
};

export default NewCustomerModal;

const ModalStyled = styled(Modal)`
.ant-modal-content{
  padding: 46px 20px 0px;
}
`;
