import { createSlice } from '@reduxjs/toolkit';
import { NAME_REDUCER } from './constants';
import actions from './actions';
import authActions from 'features/auth/services/actions';

const initialState: any = {
  listSetting: null,
  listOptionBooking: null,
  listClosedDate: [],
  listNumberOfNotification: null,
  listCancellationReason: [],
  listSettingBookingForm: {
    customer_services: false,
    is_team_member_booking: false,
    is_promotion: false,
    enable_booking_tax: false,
    value_booking_tax: null,
    merchant_closed: []
  },
  holidayInfoToday: null
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActions.logout.success, (state) => {
        state.listSetting = null;
        state.listOptionBooking = null;
        state.listClosedDate = [];
        state.listNumberOfNotification = null;
        state.listCancellationReason = [];
        state.listSettingBookingForm = {
          customer_services: false,
          is_team_member_booking: false,
          is_promotion: false,
          enable_booking_tax: false,
          value_booking_tax: null,
          merchant_closed: []
        };
      })
      .addCase(actions.getSetting.success, (state, { payload }) => {
        const data = payload as any[];
        state.listSetting = data;
      })
      .addCase(actions.getSettingBookingForm.success, (state, { payload }) => {
        const data = payload as any[];
        state.listSettingBookingForm = data;
      })
      .addCase(actions.getOptionBooking.success, (state, { payload }) => {
        const data = payload;
        state.listOptionBooking = data;
      })
      .addCase(actions.getListClosedDate.success, (state, { payload }) => {
        const data = payload;
        state.listClosedDate = data;
      })
      .addCase(actions.getNumberOfNotification.success, (state, { payload }) => {
        const data = payload as any[];
        state.listNumberOfNotification = data;
      })
      .addCase(actions.getListCancellationReason.success, (state, { payload }) => {
        const data = payload as any[];
        state.listCancellationReason = data;
      })
      .addCase(actions.getListServiceCharge.success, (state, { payload }) => {
        const data = payload as any[];
        state.listServiceCharge = data;
      })
      .addCase(actions.getDetailServiceCharge.success, (state, { payload }) => {
        state.serviceChargeDetail = payload;
      })
      .addCase(actions.getShowServiceCharge.success, (state, { payload }) => {
        const data = payload as any[];
        state.getShowServiceCharge = data;
      })
      .addCase(actions.getTopUpHistory.success, (state, { payload }) => {
        const data = payload as any[];
        state.listTopUpHistory = data;
      })
      .addCase(actions.getTopUpSMSHistory.success, (state, { payload }) => {
        const data = payload as any[];
        state.listTopUpSMSHistory = data;
      })
      .addCase(actions.getMerchantStripeBalance.success, (state, { payload }) => {
        const data = payload as any;
        state.merchantStripeBalance = data;
      }).addCase(actions.getHolidayInfoToday.success, (state, { payload }) => {
        state.holidayInfoToday = payload;
      });
  },
});
const settingServiceReducer = Slice.reducer;
export default settingServiceReducer;
