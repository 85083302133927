import { createSlice } from '@reduxjs/toolkit';
import { NAME_REDUCER } from './constants';
import { IState } from '../types/reducer';
import actions from './actions';
import { set } from 'lodash';
import { IResPromotionList } from '../types/promotion';
import authActions from 'features/auth/services/actions';
const initialState: IState = {
  configsData: null,
  params: {},
  pagination: null,
  promotions: [],
  detail: null,
  promotionCode: ''
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(authActions.logout.success, (state) => {
        state.configsData = null;
        state.params = {};
        state.pagination = null;
        state.promotions = [];
      })
      .addCase(actions.getConfigs.success, (state, { payload }) => {
        state.configsData = payload;
      })
      .addCase(actions.setListPromotionParams, (state, { payload }) => {
        const oldParams = state.params;
        const _params = { ...oldParams, ...payload ?? {} };
        if (_params.keyword === undefined || _params.keyword === null) {
          delete _params.keyword;
        }
        if (_params.type === undefined || _params.type === null) {
          delete _params.type;
        }
        if (_params.per_page === undefined || _params.per_page === null) {
          set(_params, 'per_page', 20);
        }
        state.params = _params;
      })
      .addCase(actions.getListPromotions.success, (state, { payload }) => {
        const res = payload as IResPromotionList;
        state.promotions = res.data;
        state.pagination = res.pagination;
      })
      .addCase(actions.getDetailPromotion.success, (state, { payload }) => {
        state.detail = payload;
      })
      .addCase(actions.generatePromotionCode.success, (state, { payload }) => {
        state.promotionCode = payload;
      });
  },
});
const promotionServiceReducer = Slice.reducer;
export default promotionServiceReducer;
