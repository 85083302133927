import closed_period from './closed_period.png';
import welcome from './welcome.png';
import closed_tag from './closed_tag.png';

const bookingForm = {
    welcome,
    closed_period,
    closed_tag
};

export default bookingForm;
