import { Button, Switch, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getFormatShortDateAndTime } from 'utils/moment/getSingleDateShortFormat';
import TableFailedIcon from 'assets/images/setting/table-status-failed.png';
import { useState } from 'react';
import MessageHistoryModal from './MessageHistoryModal';
import { useDispatch } from 'react-redux';
import settingActions from 'features/settings/services/actions';
import settingSelectors from 'features/settings/services/selectors';

interface topUpHistoryColumnType {
  transactionID: string;
  amount: string;
  date: string;
  status: string;
}

const TopUpStoty = () => {
  const [isMessageModalOpen, setIsMessageModalOpen] = useState<boolean>(false);
  const { t: topUpLang } = useTranslation('top_up');
  const dispatch = useDispatch();
  const listTopUpHistory = settingSelectors.getTopUpHistory();
  const loadingTopUpSMSHistory = settingSelectors.loadTopUpSMSHistory();
  const listTopUpSMSHistory = settingSelectors.getTopUpSMSHistory();

  const columns: ColumnsType<topUpHistoryColumnType> = [
    {
      title: topUpLang('topUpHistory.table.column.transactionID'),
      dataIndex: 'transactionID',
      key: 'transactionID',
    },
    {
      title: topUpLang('topUpHistory.table.column.amount'),
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: topUpLang('topUpHistory.table.column.date'),
      dataIndex: 'date',
      key: 'date',
      render: (value) => {
        const booking_time = getFormatShortDateAndTime(value);
        return <span>{booking_time}</span>;
      },
    },
    {
      title: topUpLang('topUpHistory.table.column.status'),
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => {
        return (
          <div className='table-status-column d-flex no-wrap'>
            <div className={text.toLowerCase() + '_dot status_dot'} />
            <div className={text.toLowerCase() + '_text status_text'}>
              {text}
            </div>
            <div className={text.toLowerCase() + '_img status_img'}>
              <img src={TableFailedIcon} alt='failed-icon' />
            </div>
          </div>
        );
      },
    },
  ];

  const handleOpenHistoryModal = () => {
    dispatch(settingActions.getTopUpSMSHistory.fetch());
    setIsMessageModalOpen(!isMessageModalOpen);
  };

  return (
    <SetupTopUpCardStyled>
      <div className='cards-wrapper'>
        <div className='cards-col'>
          <div className='common-card'>
            <div className='message-remaining-wrap'>
              <p className='remaining-title'>
                {topUpLang('messageRemaining.text')}
              </p>
              <div className='money-remaining d-flex no-wrap'>
                <p>150</p>
                <Tag color='#E6F4FF' className='money-remaining-tag'>
                  $10.00 remaining
                </Tag>
              </div>
              <p className='remaining-desc'>
                {topUpLang('messageRemaining.desc')}
              </p>
              <div className='form-row auto-switch-wrap'>
                <p className='form-label'>
                  {topUpLang('messageRemaining.automaticTopUpSwitch')}
                </p>
                <div className='auto-switch'>
                  <Switch defaultChecked />
                </div>
              </div>
            </div>
            <div className='auto-to-charge'>
              600 texts will be added to your balance and $30.00 will be charged
              to your card each time your text balance drops to $10.
            </div>
            <div className='topup-history-wrap'>
              <div className='d-flex no-wrap history-title-wrap'>
                <p className='topup-histoty-title'>Top-up history</p>
                <Button
                  className='message-histoty-btn'
                  onClick={handleOpenHistoryModal}
                >
                  Message history
                </Button>
              </div>
              <div className='history-table-wrap'>
                <Table
                  className={'top-up-histoty-table'}
                  dataSource={listTopUpHistory}
                  columns={columns}
                  rowKey={(item) => item.transactionID}
                  scroll={{
                    x: 900,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMessageModalOpen ? (
        <MessageHistoryModal
          isModalOpen={isMessageModalOpen}
          handleCancel={() => setIsMessageModalOpen(!isMessageModalOpen)}
          listTopUpSMS={listTopUpSMSHistory}
          loading={loadingTopUpSMSHistory}
        />
      ) : null}
    </SetupTopUpCardStyled>
  );
};

const SetupTopUpCardStyled = styled.div`
  .message-remaining-wrap {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #c3c2e0;

    .remaining-title {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
    }

    .money-remaining {
      margin-top: 12px;

      .money-remaining-tag {
        color: #1677ff;
        margin-left: 12px;
      }
    }

    .remaining-desc {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      margin-top: 12px;
      color: var(--grayish-navy-60, #7980bc);
    }
  }

  .topup-history-wrap {
    margin-top: 24px;

    .topup-histoty-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 140%;
    }

    .history-title-wrap {
      justify-content: space-between;
      align-items: center;
    }

    .history-table-wrap {
      margin-top: 33px;

      .top-up-histoty-table {
        .ant-table-cell {
          font-weight: 500;
          font-size: 14px;
        }

        .table-status-column {
          align-items: center;
          gap: 8px;

          .status_dot {
            width: 10px;
            height: 10px;
            border-radius: 5px;
          }

          .success_dot {
            background: #95de64;
          }

          .pending_dot {
            background: #fadb14;
          }

          .failed_dot {
            background: #bfbfbf;
          }

          .success_img,
          .pending_img {
            display: none;
          }
        }
      }
    }
  }

  .auto-to-charge {
    color: #7980bc;
    font-size: 14px;
    line-height: 140%;
    margin-top: 16px;
  }

  .auto-switch-wrap {
    margin-top: 12px;
    .auto-switch {
      margin-left: 12px;
    }
  }

  .message-histoty-btn {
    border-radius: 6px;
    background: #f8f8ff;
    border: none;
  }
`;

export default TopUpStoty;
