import { AxiosResponse } from 'axios';
import { redirect } from 'react-router-dom';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { setLoading } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import storage from 'utils/sessionStorage';
import actions from './actions';
import apis from './apis';
import { IApiLoginBody, IApiLoginResData } from './types/login';
import uiActions from 'services/UI/actions';
import settingActions from 'features/settings/services/actions';

const login: ISagaFunc<IApiLoginBody> = function* ({ payload }) {
  const body = payload;
  try {
    const res = yield call(apis.login, body);
    const resData = res?.data?.data as (IApiLoginResData | null);
    if (!resData) throw 'fail';

    yield storage.token.set(resData.access_token);
    const merchant_code = resData.user?.merchant?.merchant_code ?? '';
    yield storage.merchantCode.set(merchant_code);
    const merchant_id = resData.user?.merchant?.merchant_id ?? '';
    yield storage.merchantId.set(merchant_id);

    yield put(actions.login.success(resData));


  } catch (error) {
    yield put(actions.login.fail({}));
  }
};

const logout = function* () {
  yield put(uiActions.setLoadingPage(true));
  try { yield call(apis.logout); } catch (error) { } finally {
    yield put(uiActions.setLoadingPage(false));
  }
  yield storage.token.set('');
  yield storage.merchantCode.set('');
  yield storage.merchantId.set('');
  yield put(actions.logout.success({}));
};

const refreshToken: ISagaFunc<{ pathname: string }> = function* ({ payload }) {
  const { pathname } = payload;
  const token: string = yield storage.token.get();
  yield setLoading('refreshToken', true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: {
        access_token: string;
        token_type: string;
      }
    }> = yield call(apis.refreshToken, token);
    const newToken = res?.data?.data?.access_token;
    if (newToken) {
      yield put(actions.setToken(newToken));
      yield put(settingActions.getSetting.fetch());
      redirect(pathname);
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.setToken(''));
    redirect('/sign-in');
  } finally {
    yield setLoading('refreshToken', false);
  }
};

export default function* authServiceSaga() {
  yield takeLatest(actions.login.fetch, login);
  yield takeLatest(actions.refreshToken, refreshToken);
  yield takeLatest(actions.logout.fetch, logout);
}
