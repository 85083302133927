import message from 'components/common/Message';
import apisInvoice from 'features/invoices/services/apis';
import { get } from 'lodash';
import { useSetLoadingPage } from 'services/UI/LoadingPage';

const useSendCustomerInvoice = () => {
  const setPageLoading = useSetLoadingPage();
  return async ({ email = '', invoiceId = '', customerCode = '' }: {
    email?: string;
    invoiceId: string;
    customerCode: string;
  }) => {
    setPageLoading(true);
    try {
      const body = {
        email_customer: email
      };
      const res = await apisInvoice.sendInvoiceEmail(invoiceId?.replace('#', '')?.toString(), customerCode?.replace('#', '') ?? '', body);

      const msg = get(res, 'data.message', '');
      if (msg) {
        message.success(msg);
      } else {
        throw 'fail';
      }

    } catch (error) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setPageLoading(false);
    }
  };
};

export default useSendCustomerInvoice;
