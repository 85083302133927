import { IMerchantEmployee, IServiceVariant } from 'features/bookings/components/BookingForm/types';
import { IRewardItemResData } from 'features/loyaltyProgram/services/types/reward';
import { IVoucherForSalesSelectedItem } from './voucher';

interface IApiCheckoutBody {
  voucher_sales?: IApiCheckoutSaleVoucherItemBody[];
  loyalty_reward_id?: number,
  amount_given?: number,
  promotion_code?: string | null,
  amount_discount?: number;
  discount_extra?: {
    discount_value?: number;
    discount_type?:string;
    discount_name?: string;
  }
  extra_fee?: {
    name: string;
    fee_type?:string;
    fee_value?: number;
  };
}

interface IApiCheckoutSaleVoucherItemBody {
  voucher_id: number | string,
  quantity: number
}
export interface IApiCheckoutByCashBody extends IApiCheckoutBody {
  rewardInfo?: IRewardItemResData | null
  rewardUsedPoint?: number | null,

}



export interface IApiCheckoutByCardBody extends IApiCheckoutBody {
  transaction_id: string;
  TID?: string;
  MID?: string;
  amount_pay?: number;
  rewardInfo?: IRewardItemResData | null
  rewardUsedPoint?: number | null,
}

export interface IApiCheckoutByVoucherBody extends IApiCheckoutBody {
  voucher_code: string;
  rewardInfo?: IRewardItemResData | null
  rewardUsedPoint?: number | null,
}

export interface IApiCheckoutWithMixinBody extends IApiCheckoutBody {
  mixin_payment: {
    cash?: {
      amount_pay?: number;
      amount_received?: number;
    };
    voucher?: {
      voucher_code: string,
      amount_pay: number
    };
    card?: IApiCheckoutByCardBody;
  }
  voucher_code?: string;
  rewardInfo?: IRewardItemResData | null
  rewardUsedPoint?: number | null,
}

export interface IResultCheckoutRes {
  id: number;
  created_at: string;
  customer_name: string;
  merchant_name: string;
  loyalty_point: number;
  invoice_no: string;
  sum_reward: number;
  sum_promotion: number;
  sum_voucher: number;
  sum_total_not_vat: number;
  sum_total_vat: number;
  list_voucher_sell: IVoucherForSalesSelectedItem[];
}

export enum EPaymentMethod {
  CASH = 'cash',
  VOUCHER = 'voucher',
  CARD = 'card',
  MIX = 'mix'
}

export type IServiceCheckoutItem = {
  category?: {
    id?: number;
    name?: string;
    color?: string;
  };
  enable_discount?: number;
  enable_voucher_sale?: number;
  id?: string | number;
  merchant_employees?: IMerchantEmployee[];
  service_variants?: IServiceVariant[];
  regular_price?: number;
  sale_price?: number;
  name?: string;
  service_name?: string;
  service_price?: number;
  duration_time: number;

  assigned_employee?: {
    employee?: {
      id?: number;
      full_name?: string;
      email?: string;
      phone?: string;
      merchant_location_id?: number;
    };
    time_start?: string;
    duration?: string;
    time_end?: string;
    status?: string;
  };

  checked?: boolean;
  time_start?: string;
  employee_id?: number;
  quantity?: number;
  serviceVariantName?: string;
  serviceVariantPrice?: number;
  service_id?: number;
  service_variant_id?: number;
};

export type IMixPaymentItem = {
  type: EPaymentMethod;
  data: any;
  value: number;
};
