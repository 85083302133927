import { createSlice } from '@reduxjs/toolkit';
import { NAME_REDUCER } from './constants';
import actions from './actions';
import { IApiReviewParam } from './types/review';

const initialState = {
  reviewList: [],
  reviewDetail: {},
  params: {},
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(actions.clearDetail, (state) => {
        state.reviewDetail = {};
      })
      .addCase(actions.getListReview.success, (state, { payload }) => {
        state.reviewList = payload;
      })
      .addCase(actions.setReviewParams, (state, { payload }) => {
        const data = payload as IApiReviewParam;
        const params: IApiReviewParam = {
          ...state.params ?? {},
          ...data ?? {},
        };

        if (params.keyword === undefined || params.keyword === null) {
          delete params.keyword;
        }
        if (params.page === undefined || params.page === null) {
          params.page = 1;
        }

        if (params.per_page === undefined || params.per_page === null) {
          params.per_page = 10;
        }
        state.params = params;
      })
      .addCase(actions.getDetailReview.success, (state, { payload }) => {
        state.reviewDetail = payload;
      });

  },
});
const reviewServiceReducer = Slice.reducer;
export default reviewServiceReducer;
