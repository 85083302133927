import checkoutSelectors from 'features/checkout/services/selectors';
import { formatMoney } from 'utils/unit';

const RewardResultRow = () => {
  const result = checkoutSelectors.getRewardValue();
  const invoice = checkoutSelectors.getBookingDetail();

  if (!result) return null;

  const rewardValue = (result ?? 0) - Number(invoice?.total_price ?? 0) > 0 ? +(invoice?.total_price ?? '') : result;
  return (
    <p className='extra-price-row'>
      Reward
      <span>- {formatMoney(+rewardValue ?? 0)}</span>
    </p>
  );
};

export default RewardResultRow;
