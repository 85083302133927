import IconClose from 'assets/svg/IconClose';
import IconVoucher from 'assets/svg/IconVoucher';
import { ICustomerResData } from 'features/checkout/services/types/bookingDetail';
import { IVoucherForSalesSelectedActions, IVoucherForSalesSelectedItem } from 'features/checkout/services/types/voucher';
import { ICustomerItemResData } from 'features/customers/services/types/cutomer';
import styled from 'styled-components';
type Props = {
  data: IVoucherForSalesSelectedItem;
  setQuantity?: IVoucherForSalesSelectedActions['setQuantity'];
  remove?: IVoucherForSalesSelectedActions['remove'];
  viewOnly?: boolean;
  customer?: ICustomerItemResData | null | ICustomerResData,
  step?: number;
};
const ForSalesSelectedItem = ({
  data,
  remove = () => undefined,
  viewOnly,
  customer,
  step = 1
}: Props) => {
  const { voucher_code, name, id } = data ?? {};
  const _remove = () => remove(id);

  return (
    <ForSalesSelectedItemStyled>
      <div className='item-info'>
        <p className='item-title service_name'>
          {name} <IconVoucher />
        </p>
        <p className='item-text'>
          {step === 3 && <p className='voucher_time'>
            Code: {voucher_code} {customer?.name && < >- for {customer.name}</>}
          </p>}
        </p>
      </div>
      <div className='gr-actions'>
        <div>
        </div>

        {!viewOnly && <div className='item-control'>
          <button
            type='button'
            onClick={_remove}
          >
            <IconClose />
          </button>
        </div>}
      </div>
    </ForSalesSelectedItemStyled>
  );
};

export default ForSalesSelectedItem;

const ForSalesSelectedItemStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  gap: 24px;
  border: 1px solid var(--color-purple-05);
  border-radius: 6px;
  align-items: center;
  .item-info {
    flex: 1;
    min-width: 40%;
    & + div {
      display: flex;
      gap: 24px;
      margin-left: auto;
    }
  }
  .item-title {
    font-weight: 600;
    font-size: 16px;
    display:flex;
    gap:8px;
    align-items: center;
  }
  .item-text {
    font-size: 12px;
    span {
      font-weight: 600;
    }
  }
  .item-control {
    display: flex;
    gap: 8px;
    align-items: center;
    button {
      display: flex;
      align-items: center;
    }
  }
  .control-btn{
    &.is-disabled {
      opacity:0.3;
    }
  }
  .gr-actions {
    display:flex;
    align-items:center;
  }
  .info-price {
    display:flex;
  }
`;