import { Empty, Spin } from 'antd';
import apisCustomer from 'features/customers/services/apis';
import customerSelectors from 'features/customers/services/selectors';
import { ICustomerItemResData } from 'features/customers/services/types/cutomer';
import React, { useEffect, useState, useTransition } from 'react';
import useSearchDebounce from 'hooks/useSearchDebounce';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { InitialDataFormAdd } from '../NewCustomerModal';
import dayjs from 'utils/dayjs';
import { IconAvatarWalkIn } from '../CustomerDetail';
import { CUSTOMER_WALKIN_INDEX } from 'utils/unit';

type Props = {
  onChooseCustomer?: any;
  currCustomer: ICustomerItemResData | null;
  setDataFormAdd?: (val: InitialDataFormAdd) => void;
};

const InputSearchCustomer: React.FC<Props> = ({ onChooseCustomer, setDataFormAdd = () => undefined }) => {
  const data = customerSelectors.getCustomerList();

  const [isShow, setIsShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataCustomers, setDataCustomers] = useState<ICustomerItemResData[]>(data);
  
  const [, startTransition] = useTransition();
  const { t: formLang } = useTranslation('form');  

  const handleFocus = () => { setIsShow(true); };
  const handleBlur = () => setTimeout(() => setIsShow(false), 200);

  const [text, onDebounce] = useSearchDebounce(async (value: string) => {
    const search = async () => {
      const textSearch = value.toLowerCase();
      if (textSearch === '') {
        setDataCustomers(data);
      } else {
        setIsLoading(true);
      }

      try {
        const response = await apisCustomer.getListCustomers({
          keyword: textSearch,
        });
        const resData: ICustomerItemResData[] = (response.data.data as ICustomerItemResData[]).sort((o1, o2) => Number(o2.is_walkin_in) - Number(o1.is_walkin_in)) ?? [];
        setDataCustomers(resData);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    startTransition(() => {
      search();
    });
  }, data);
 
  useEffect(() => {
    if (!text) {
      setDataFormAdd(undefined);
      return;
    }
    if (dataCustomers?.length === 0) {
      if (/^[0-9]*$/.test(text))
        setDataFormAdd({ phoneNumber: text });
    } else {
      // isNumber phone
      if (/^[0-9]*$/.test(text)) {
        const cus = dataCustomers?.find(o => o.phone_number === text);
        if (cus) {
          setDataFormAdd({
            phoneNumber: cus.phone_number,
            email: cus.email,
            importantClientInfo: cus.important_client_info,
            name: cus.name,
            walkIn: !!cus.is_walkin_in,
            birthDate: cus?.date_of_birth ? dayjs(cus?.date_of_birth) : null,
          } as InitialDataFormAdd);
        }
      }
    }
  }, [dataCustomers, setDataFormAdd]);


  return (
    <>
      <InputSearchCustomerStyled>
        <div className='common-input-search'>
          <input
            type='text'
            placeholder={formLang('SearchCustomerPlaceholder') || ''}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={onDebounce}
          ></input>
        </div>
        {isShow && (
          <div className='search-result common-scrollbar'>
            <Spin spinning={isLoading}>
              {!!dataCustomers && dataCustomers?.length > 0 ? dataCustomers?.map((customer: ICustomerItemResData, index) => {
                const { name, customer_code, phone_number, is_walkin_in } = customer;
                                
                return (
                  <div
                    key={index}
                    className='result-item'
                    onClick={() => onChooseCustomer(customer)}
                  >
                    {Number(is_walkin_in) !== CUSTOMER_WALKIN_INDEX ? <p className='item-avatar common-avatar'>
                      {name && Array.from(name)[0]}
                    </p> : <IconAvatarWalkIn/> }
                    <div className='item-detail'>
                      <p className={'detail-name'} >{name}</p>
                      {Number(is_walkin_in) !== CUSTOMER_WALKIN_INDEX ?
                      (<>
                        <p>
                          Customer ID: <span> {customer_code}</span>
                        </p>
                        <p>
                          Phone number: <span>{phone_number}</span>
                        </p>
                      </>
                      )  : <></>}
                    </div>
                  </div>
                );
              })
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No result'} />
              }
            </Spin>
          </div>
        )}
      </InputSearchCustomerStyled>
    </>
  );
};

export default InputSearchCustomer;

const InputSearchCustomerStyled = styled.div`
  position: relative;
  flex: 1;
  color: var(--color-primary);

  .search-result {
    z-index: 500;
    position: absolute;
    top: calc(100% + 4px);
    width: 100%;
    left: 0;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background: var(--color-white);
    box-shadow: 0px 16px 24px #e1e1ef;
    border-radius: 6px;
    max-height: 420px;
    overflow: auto;
    @media (max-width: 767.98px) {
      max-height: 250px;
    }
  }

  .result-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    transition: all 0.3s ease;
    cursor: pointer;
    border-radius: 6px;

    &:hover,
    &.is-active {
      background: #f0f2f7;
    }
  }

  .item-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 600;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
    color: #fff;
    @media (max-width: 767.98px) {
      width: 60px;
      height: 60px;
      font-size: 30px;
      margin-left: 8px;
    }
  }

  .result-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    transition: all 0.3s ease;
    cursor: pointer;
    border-radius: 6px;
  
    @media (max-width: 767.98px) {
      padding: 12px 0;
    }
  
    &:hover,
    &.is-active {
      background: #f0f2f7;
    }
  }  

  .item-detail {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: hidden;
    p {
      font-size: 12px;
      span {
        font-weight: 600;
      }
    }
    .detail-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      font-size: 16px;
    }
    .customer-link-disable {
        pointer-events: none;
    }
  }
`;
