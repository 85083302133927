import { Table } from 'antd';
import analyticSelectors from '../services/selectors';


const WorkingTable = () => {

  const workingHour = analyticSelectors.getWorkingHours();

  const dataSource = workingHour;  

  const columns = [
    {
      title: 'Staff',
      dataIndex: 'full_name',
      key: 'full_name',
      width: '35%',
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      width: '35%',
    },
    {
      title: (
        <p>
          Total hours <span className='optional-text'>(hrs)</span>
        </p>
      ),
      dataIndex: 'total_hours',
      key: 'total_hours',
    },
  ];

  return (
    <>
      <Table
        className={'custom-table'}
        dataSource={dataSource}
        columns={columns}
        // scroll={{
        //   x: 900,
        // }}
        pagination={false}
      />
    </>
  );
};

export default WorkingTable;
