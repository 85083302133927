const IconVoucher = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V10C2.53043 10 3.03914 10.2107 3.41421 10.5858C3.78929 10.9609 4 11.4696 4 12C4 12.5304 3.78929 13.0391 3.41421 13.4142C3.03914 13.7893 2.53043 14 2 14V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V14C21.4696 14 20.9609 13.7893 20.5858 13.4142C20.2107 13.0391 20 12.5304 20 12C20 11.4696 20.2107 10.9609 20.5858 10.5858C20.9609 10.2107 21.4696 10 22 10V6C22 5.46957 21.7893 4.96086 21.4142 4.58579C21.0391 4.21071 20.5304 4 20 4H4ZM15.5 7L17 8.5L8.5 17L7 15.5L15.5 7ZM8.81 7.04C9.79 7.04 10.58 7.83 10.58 8.81C10.58 9.27943 10.3935 9.72964 10.0616 10.0616C9.72964 10.3935 9.27943 10.58 8.81 10.58C7.83 10.58 7.04 9.79 7.04 8.81C7.04 8.34057 7.22648 7.89036 7.55842 7.55842C7.89036 7.22648 8.34057 7.04 8.81 7.04M15.19 13.42C16.17 13.42 16.96 14.21 16.96 15.19C16.96 15.6594 16.7735 16.1096 16.4416 16.4416C16.1096 16.7735 15.6594 16.96 15.19 16.96C14.21 16.96 13.42 16.17 13.42 15.19C13.42 14.7206 13.6065 14.2704 13.9384 13.9384C14.2704 13.6065 14.7206 13.42 15.19 13.42V13.42Z"
        fill="#363565"
      />
    </svg>
  );
};

export default IconVoucher;
