import { Form, InputNumber, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { AxiosResponse } from 'axios';
import SwitchInput from 'components/common/Input/SwitchInput';
import message from 'components/common/Message';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import settingActions from '../services/actions';
import settingApis from '../services/apis';
import settingSelectors from '../services/selectors';
import FormTabSetting from './FormTabSetting';
interface IOptional {
  value: number;
  label: string;
}

interface IFormBooking {
  reschedule1?: string;
  noShow?: string;
  slot?: string;
  sendMethod?: string;
  notice?: string;
  isDisplayNote?: boolean;
  isNotifyNewBooking?: boolean;
  noShowStatus?: boolean;
  isTeamMemberBooking?: boolean;
  enablePromotionRedeem?: boolean;
  termsAndConditions?: string;
  valueBookingTax?: number;
  value_card_surchange?: number;
  bothPromotionTypeAllow?: boolean;
  isCardSurchange: boolean;
}

const BookingsTab = () => {

  const getBookOption = settingSelectors.getOptionBooking();
  const getSetting = settingSelectors.getSetting();

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const getSelectValue = (value: number, options: IOptional[]) => {
    const valueCustom = options.some((item: IOptional) => item?.value == value)
      ? value
      : options[0]?.value;
    return Number(valueCustom);
  };

  const getBoolean = (value: boolean | string | undefined) => {
    return typeof value === 'string'
      ? value?.toLowerCase() === 'true'.toLowerCase()
      : value;
  };

  const getFieldsValue = () => {
    const booking = getSetting?.booking;

    return {
      reschedule1: getSelectValue(
        booking?.cancel_reschedule,
        getBookOption?.m_option_cancel_reschedule
      ),
      reschedule2: getSelectValue(
        booking?.reschedule_online,
        getBookOption.m_option_cancel
      ),
      noShow: getSelectValue(
        booking?.no_show,
        getBookOption?.m_option_no_shows
      ),
      slot: getSelectValue(
        booking?.time_slot,
        getBookOption?.m_option_time_slots
      ),
      sendMethod: booking?.send_method,
      noShowStatus: getBoolean(booking?.no_show_status),
      isTeamMemberBooking: getBoolean(booking?.is_team_member_booking),
      notice: booking?.merchant_note,
      isDisplayNote: getBoolean(booking?.is_view_merchant_note),
      isNotifyNewBooking: getBoolean(booking?.is_notify_new_book),
      enablePromotionRedeem: booking?.is_promotion_redeem,
      termsAndConditions: booking?.terms_conditions ?? '',
      valueBookingTax: booking?.value_booking_tax,
      value_card_surchange: booking?.value_card_surchange,
      bothPromotionTypeAllow: booking?.both_promotion_types_allow
    };
  };

  useEffect(() => {
    form.setFieldsValue(getFieldsValue());
  }, [getSetting]);

  const getPayload = (values: IFormBooking) => {
    const cancel_reschedule = values?.reschedule1;
    const no_show = values?.noShow;
    const time_slot = values?.slot;
    const send_method = values?.sendMethod;
    const merchant_note = values?.notice;
    const is_view_merchant_note = getBoolean(values?.isDisplayNote);
    const is_notify_new_book = getBoolean(values?.isNotifyNewBooking);
    const no_show_status = getBoolean(values?.noShowStatus);
    const team_member_booking = getBoolean(values?.isTeamMemberBooking);
    const is_promotion_redeem = values?.enablePromotionRedeem;
    const value_booking_tax = values?.valueBookingTax;
    const value_card_surchange = values?.value_card_surchange;
    const both_promotion_types_allow = values?.bothPromotionTypeAllow;
    const is_card_surchange = values?.isCardSurchange;

    return {
      cancel_reschedule,
      no_show,
      time_slot,
      send_method,
      merchant_note,
      is_view_merchant_note,
      is_notify_new_book,
      no_show_status,
      team_member_booking,
      terms_conditions: values.termsAndConditions,
      is_promotion_redeem,
      value_booking_tax,
      value_card_surchange,
      both_promotion_types_allow,
      is_card_surchange
    };
  };

  const handleSubmit = async (values: IFormBooking) => {
    const payload = getPayload(values);

    try {
      const rest: AxiosResponse<{ message: string }> =
        await settingApis.updateBooking(payload);

      const msg = get(rest, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(settingActions.getSetting.fetch());
        form.setFieldsValue(getFieldsValue());
        return true;
      }
      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });

      return false;
    }
  };




  const checkValid = async () => {
    try {
      await form.validateFields([
        // 'phoneNumber',
        // 'name',
        // 'email',
        // 'service',
        // 'bookingDate',
        // 'bookingTime',
      ]);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <FormTabSetting
        form={form}
        handleSubmit={handleSubmit}
        checkValid={checkValid}
        initialValues={getFieldsValue()}
      >
        <div className='cards-wrapper'>
          <div className='cards-col'>
            <div className='common-card'>
              <h2 className='card-title'>Booking settings</h2>
              <div className='card-body'>
                <div className='form-row'>
                  <Form.Item
                    label='Customers book via Booking online'
                    name='reschedule1'
                  >
                    <Select
                      options={getBookOption?.m_option_cancel_reschedule.filter(
                        (o: any) => o.value !== ''
                      )}
                    />
                  </Form.Item>
                </div>
                {/* <div className='form-row'>
                  <Form.Item label='' name='reschedule2'>
                    <Select
                      options={getBookOption?.m_option_cancel}
                    />
                  </Form.Item>
                </div> */}

                <div>
                  <SwitchInput
                    label='Enable No-show status'
                    name='noShowStatus'
                    form={form}
                  />
                  <p className='form-note'>
                    The ’no-show’ status will appear if the customer does not
                    arrive after the default booking time.
                  </p>
                </div>

                <div className='form-row'>
                  <Form.Item label='Setting No-show status' name='noShow'>
                    <Select options={getBookOption?.m_option_no_shows} />
                  </Form.Item>
                </div>

                <div className='form-row'>
                  <Form.Item label='Time Slot Interval' name='slot'>
                    <Select options={getBookOption?.m_option_time_slots} />
                  </Form.Item>
                </div>

                {/* <div>
                  <div className='form-row'>
                    <Form.Item name='isTeamMemberBooking' valuePropName='checked'>
                      <Checkbox defaultChecked={getSetting?.booking?.is_team_member_booking}><p className='form-label'>Allow select team member when making a booking</p></Checkbox>
                    </Form.Item>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className='common-card'>
              <h2 className='card-title'>Voucher</h2>
              <div className='card-body'>
                <div className='form-row'>
                  <Form.Item label='Terms & Conditions' name='termsAndConditions'>
                    <TextArea
                      placeholder='Enter terms & conditions'
                      rows={4}
                    />
                  </Form.Item>
                </div>
              </div>
            </div> */}
            <div className='common-card'>
              <h2 className='card-title'>Sales settings</h2>
              <div className='card-body'>
                <div>
                  <SwitchInput
                    label='Promotion redemption'
                    name='enablePromotionRedeem'
                    form={form}
                  />
                  <p className='form-note'>
                    Allow redeem promotion on the check-out screen.
                  </p>
                </div>
              </div>
              <div
                className='card-body'
                style={{
                  borderBottom: '1px solid var(--color-purple)',
                  marginTop: '20px',
                  paddingBottom: '20px',
                }}
              >
                <div>
                  <SwitchInput
                    label='Both promotion types allow'
                    name='bothPromotionTypeAllow'
                    form={form}
                  />
                  <p className='form-note'>
                    Allow 2 types of promotions you would like to apply to your
                    sales: Automatic and Manual.
                  </p>
                </div>
              </div>
              <div className='card-body' style={{ marginTop: '22px' }}>
                <div>
                  <SwitchInput
                    label='Card surcharge enablement'
                    name='isCardSurchange'
                    form={form}
                  />
                  <Form.Item label={'Value'} name={'value_card_surchange'}>
                    <InputNumber addonBefore='%' inputMode='numeric' />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className='common-card'>
              <h2 className='card-title'>
                Team member availability
                <p className='form-note'>
                  You can set team member availability when making a booking.
                </p>
              </h2>
              <div className='card-body'>
                <div>
                  <SwitchInput
                    label='Require choose team member'
                    name='isTeamMemberBooking'
                    form={form}
                  />
                </div>
              </div>
            </div>

            <div className='common-card'>
              <h2 className='card-title'>
                Displayed store’s note
                <p className='form-note'>
                  Customize the displayed content for your customers.
                </p>
              </h2>
              <div className='card-body'>
                <div>
                  <SwitchInput
                    label='Displayed store’s note'
                    name='isDisplayNote'
                  />
                </div>
                <div className='form-row'>
                  <Form.Item label={<p>Store’s note</p>} name='notice'>
                    <TextArea placeholder='Enter your note here' rows={4} />
                  </Form.Item>
                </div>
                <div className='form-row'>
                  <Form.Item label='Voucher Terms' name='termsAndConditions'>
                    <TextArea placeholder='Enter Voucher Terms' rows={4} />
                  </Form.Item>
                </div>
                <p className='form-note'>
                  You can set up the voucher terms and conditions here.
                </p>
              </div>
            </div>
            {/* <div className='common-card'>
              <h2 className='card-title'>Taxes</h2>
              <div className='card-body'>
                <div>
                  <SwitchInput
                    label='Enable Notifications To Taxes'
                    name='isDisplayNote'
                  />
                  <p className='form-note'>
                    Customers will see the notice at checkout when booking and
                    appointment or buying a voucher.
                  </p>
                </div>
                <div className='form-row'>
                  <Form.Item label='Taxes' name='valueBookingTax'>
                    <Input placeholder='Enter taxes' />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className='common-card'>
              <h2 className='card-title'>Promotion</h2>
              <div className='card-body'>
                <div>
                  <SwitchInput
                    label='Enabled promotion Redeem'
                    name='enablePromotionRedeem'
                    form={form}
                  />
                  <p className='form-note'>
                    Allow redeem promotion on the checkout form page
                  </p>
                </div>
              </div>
            </div> */}
            {/* <div className='common-card'>
              <h2 className='card-title'>Notifications</h2>
              <div className='card-body'>
                <div className='form-row'>
                  <Form.Item label='Reminder for bookings' name='reminder'>
                    <Select
                      options={getBookOption?.m_option_remiders}
                    />
                  </Form.Item>
                </div> */}
            {/* <div className='form-row is-wrap'>
                  <Form.Item
                    label='Notifications To Customers By'
                    name='notifications'
                  >
                    <Form.Item name='sendMethod' noStyle>
                      <Radio.Group>
                        {getBookOption?.m_option_send_methods?.map((item: IOptional, index: number) => (
                          <Radio key={index} value={item.value}>{item.label}</Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Form.Item>
                  <p className='form-note'>
                    Enabling this setting will send customers email and SMS
                    notifications on the provided contact details.
                  </p>
                </div> */}
            {/* <div>
                  <SwitchInput
                    label='Enable Notifications To New Bookings'
                    name='isNotifyNewBooking'
                  />
                  <p className='form-note'>
                    Enabling this setting will send notifications of new bookings to
                    the team member’s email.
                  </p>

                </div>
              </div>
            </div> */}
          </div>
        </div>
      </FormTabSetting>
    </>

  );
};

export default BookingsTab;
