import { Form, FormItemProps } from 'antd';
import { icons } from 'assets';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

export type IHelperLabelType = 'warning' | 'info' | 'success';
const getIconHelper = (type: IHelperLabelType) => {
  switch (type) {
    case 'info':
    case 'warning':
      return icons.ico_info;
    default: return icons.ico_check_circle;
  }
};

interface Props extends FormItemProps {
  children?: ReactElement;
  helperText?: string;
  helperLabelType?: IHelperLabelType;
  optional?: boolean;
  containerClassName?: string;
}
const FormRow: React.FC<Props> = ({
  children = null,
  helperText = '',
  helperLabelType = 'success',
  name = '',
  label = '',
  optional = false,
  containerClassName,
  ...props
}) => {
  return (
    <FormRowStyled className={containerClassName}>
      {label && <p className='label'>
        <span className='labelText'>{label}</span>
        {optional && <span className='optional'>{'(optional)'}</span>}
      </p>}
      <Form.Item {...props} className={`wrap-input ${props.className}`} name={name}>
        {children}
      </Form.Item>
      {helperText && <div className='helperText'>
        <img className='icon' src={getIconHelper(helperLabelType)} />
        <p className={helperLabelType}>{helperText}</p>
      </div>}
    </FormRowStyled>
  );
};

export default FormRow;

const FormRowStyled = styled.div`
  .label {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-transform: capitalize;
    color: var(--color-primary);
    .labelText{
      margin-right: 4px;
      font-weight: 500;
    }
    .optional {
      color: #C3C2E0;
    }
  }
  .wrap-input {
    margin-bottom: 8px;
  }  
  .helperText{
    background-color: transparent;
    display:flex; 
    align-items: flex-start;
    .icon {
      height:16px;
      width:16px;
    }
    p {
      margin-left: 6px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      &.success {
        color: var(--color-green);
      }
      &.warning {
        color :#FA8C16;
      }
    }
  }
`;