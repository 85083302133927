import { Form, Input } from 'antd';
import invoiceSelectors from 'features/invoices/services/selectors';
import { useEffect } from 'react';
import { getFormatShortDateAndTime } from 'utils/moment/getSingleDateShortFormat';

const BusinessDetails = () => {
  const [form] = Form.useForm();
  const data = invoiceSelectors.detailInvoice.getData();
  useEffect(() => {
    form.setFieldsValue({
      locationName: data?.location.name ?? '',
      address: data?.location.address,
      contactNumber: data?.location.contact_number,
      abn: data?.location.abn,
      bookingDate: getFormatShortDateAndTime(data?.date_time_book ?? ''),
    });
  }, [data]);

  return (
    <>
      <div className="common-card">
        <h2 className="card-title">Business Details</h2>
        <div className="card-body">
          <Form
            form={form}
            layout="vertical"
            className='business-form-invoice'
          >
            <div className="form-row">
              <Form.Item label="Location Name" name="locationName">
                <Input value={form.getFieldValue('locationName')} disabled />
              </Form.Item>
            </div>
            <div className="form-row">
              <Form.Item label="Booking Date" name="bookingDate">
                <Input value={form.getFieldValue('bookingDate')} disabled />
              </Form.Item>
            </div>

          </Form>
        </div>
      </div>
    </>
  );
};

export default BusinessDetails;
