import bookingActions from 'features/bookings/services/actions';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import BookingForms from '../../components/BookingForm';
import StickyPromotion from '../../components/BookingForm/StickyPromotion';
import { BOOKING_FORM } from '../../components/BookingForm/types';
import { AxiosResponse } from 'axios';
import apisLocation from 'features/locations/services/apis';
import { get } from 'lodash';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import locationActions from 'features/locations/services/actions';
import locationSelectors from 'features/locations/services/selectors';
import dayjs from 'dayjs';
import moment from 'moment';
import { TIME_DATE_MERCHANT } from 'features/bookings/services/constants';
const BookingForm: React.FC<{ isCheckIn?: boolean }> = ({ isCheckIn }) => {
  const { location_id = '', merchant_code = '' } = useParams();
  const [formActive, setFormActive] = React.useState<BOOKING_FORM>(
    BOOKING_FORM.INFORMATION
  );
  const setLoadingPage = useSetLoadingPage();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const activeLocation = locationSelectors.getLocationDetailPublic();

  const dispatch = useAppDispatch();



  const checkLocationValid = async () => {
    if (!isCheckIn) return true;
    try {
      const res: AxiosResponse<{ data: any[] }> = await apisLocation.getLocationDetailPublic(merchant_code, location_id);
      if (res?.data?.data) {
        const status = get(res.data, 'data.status', 1);
        if (!status) {
          setFormActive(BOOKING_FORM.INVALID_LOCATION);
          setLoadingPage(false);
          return false;
        }
        return true;
      }
    } catch (error) {
      return true;
    }
  };

  useEffect(() => {
    isCheckIn && dispatch(locationActions.getLocationDetailPublic.fetch({merchant_code ,location_id}));
    checkLocationValid();
    dispatch(bookingActions.getBookingOnlineData.fetch(merchant_code));
  }, []);

  const date_closed: any = useMemo(() => {

    if(!activeLocation) return null;

    return activeLocation?.dateClosed?.map(o => ({
      start: dayjs(o.start_date, 'YY-MM-DD'),
      end: dayjs(o.end_date, 'YY-MM-DD'),
    }));
  }, [activeLocation?.dateClosed]);



  const getDisableDateByClosedPeriod = () => {
    return date_closed?.find((o: any) => moment().format('YY-MM-DD') == o.start.format('YY-MM-DD') || moment().isBetween(o.start, o.end.add(1, 'day')));
  };

  const getDatePeriod = () => {
    return getDisableDateByClosedPeriod().start.format(TIME_DATE_MERCHANT) === getDisableDateByClosedPeriod().end.format(TIME_DATE_MERCHANT) ?
      getDisableDateByClosedPeriod().start.format(TIME_DATE_MERCHANT) :
      `${getDisableDateByClosedPeriod().start.format(TIME_DATE_MERCHANT)} to ${getDisableDateByClosedPeriod().end.format(TIME_DATE_MERCHANT)}`;

  };

  if(isCheckIn && !!getDisableDateByClosedPeriod()) {
    return <BookingForms.InValidLocation 
      title='Sorry! Our store has temporary closed.'
      subtitle={`Our store is temporarily closed on ${getDatePeriod()}. We'll be back soon, giving a new offer of tranquility and bliss.`}
      setFormActive={setFormActive} 
      isCheckIn={isCheckIn} />;
  }


  return (
    <BookingFormStyled>
      <StickyPromotion isCheckIn={isCheckIn} label='Some promotion - book something get another thing free!' />
      <div className='form_container'>
        {/* {getTab()} */}
        <div
          className={` ${formActive === BOOKING_FORM.INFORMATION ? '' : ' hide'
            } `}
        >
          <BookingForms.Information
            errors={errors}
            setFormActive={setFormActive}
            isCheckIn={isCheckIn}
          />
        </div>
        <div
          className={` ${formActive === BOOKING_FORM.CONFIRM ? '' : ' hide'} `}
        >
          <BookingForms.Confirm
            setErrors={setErrors}
            setFormActive={setFormActive}
            isCheckIn={isCheckIn}
          />
        </div>
        <div
          className={` ${formActive === BOOKING_FORM.SUCCESS ? '' : ' hide'} `}
        >
          <BookingForms.BookingDone setFormActive={setFormActive} isCheckIn={isCheckIn} />
        </div>
        <div
          className={` ${formActive === BOOKING_FORM.INVALID_LOCATION ? '' : ' hide'} `}
        >
          <BookingForms.InValidLocation setFormActive={setFormActive} isCheckIn={isCheckIn} />
        </div>
      </div>
    </BookingFormStyled>
  );
};

export default memo(BookingForm);

const BookingFormStyled = styled.div`
  background: var(--color-white-01);
  min-height: 100vh;
  font-family: var(--font-base);
  @media only screen and (min-width: 1025px) {
    padding-bottom: 80px;
  }
  @media only screen and (max-width: 767.98px) {
    padding-bottom: 20px;
  }
  .form_container {
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 24px;

    @media only screen and (min-width: 768px) and (max-width: 1024.98px) {
      padding: 40px 58px;
    }

    @media only screen and (max-width: 767.98px) {
      padding: 40px 12px 0;
    }
  }
  .hide {
    display: none;
  }
`;
