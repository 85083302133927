import { Checkbox, DatePicker, Form } from 'antd';
import IconPlusCircle from 'assets/svg/IconPlusCircle';
import TimePickerAutoChange from 'components/common/TimePickerAutoChange';
import dayjs from 'dayjs';
import { IconRemove } from 'features/checkout/components/FeeButton';
import { ButtonStyled } from 'features/workingHour/widgets/WorkingTime/Modal';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { timeFormat } from 'utils/unit';

const EditShiftForm = () => {
  const { t: formLang } = useTranslation('form');

  const form = Form.useFormInstance();

  const handleRepeatTextClick = () => {
    const repeatCheck = form.getFieldValue('repeatEveryWeek');
    form.setFieldValue('repeatEveryWeek', !repeatCheck);
  };

  return (
    <EditShiftFormStyled>
      <div className='edit-shift-form-wrap'>
        <div className='form-row'>
          <Form.Item
            label={formLang('timeSchedule.editShiftForm.selectDay')}
            name='dayOff'
          >
            <DatePicker
              format={'DD.MM.YYYY'}
              picker='date'
              placeholder={'DD.MM.YYYY'}
              className='editshift-datepicker'
              disabled={true}
            />
          </Form.Item>
        </div>

        <Form.List name={'times'}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div
                  className='form-row group-time items-end time-form-item'
                  key={index}
                >
                  <Form.Item
                    label='Start time'
                    name={[index, 'start']}
                    rules={[
                      { required: true, message: 'Please select start time' },
                      {
                        validator(_, value) {
                          const timeValue = form.getFieldValue('times');
                          const findTimeExist = timeValue?.find(
                            (item: any, id: number) =>
                              id !== index &&
                              dayjs(item?.start, 'HH:mm').format('HH:mm') ===
                                dayjs(value, 'HH:mm').format('HH:mm') &&
                              dayjs(item?.end, 'HH:mm').format('HH:mm') ===
                                dayjs(timeValue[index]?.end, 'HH:mm').format(
                                  'HH:mm'
                                )
                          );

                          if (findTimeExist) {
                            return Promise.reject(
                              new Error(
                                'Start time is not equal and duplicate record'
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <TimePickerAutoChange
                      placeholder='__:__'
                      allowClear={false}
                      className='is-time-picker edit-shift-time-picker'
                      showNow={false}
                      format={timeFormat}
                      minuteStep={15}
                    />
                  </Form.Item>
                  <p className='center-text'>to</p>
                  <Form.Item
                    label='End time'
                    name={[index, 'end']}
                    rules={[
                      { required: true, message: 'Please select end time' },
                      {
                        validator(_, value) {
                          const timeValue = form.getFieldValue('times');
                          const findStarExist = timeValue?.find(
                            (item: any, id: number) =>
                              id !== index &&
                              dayjs(item?.end, 'HH:mm').format('HH:mm') ===
                                dayjs(value, 'HH:mm').format('HH:mm') &&
                              dayjs(item?.start, 'HH:mm').format('HH:mm') ===
                                dayjs(timeValue[index]?.start, 'HH:mm').format(
                                  'HH:mm'
                                )
                          );

                          if (findStarExist) {
                            return Promise.reject(
                              new Error(
                                'End time is not equal and duplicate record'
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <TimePickerAutoChange
                      placeholder='__:__'
                      allowClear={false}
                      className='is-time-picker edit-shift-time-picker'
                      showNow={false}
                      format={timeFormat}
                      minuteStep={15}
                    />
                  </Form.Item>
                  <ButtonStyled
                    className='remove'
                    type='button'
                    onClick={() => remove(field.name)}
                  >
                    <IconRemove />
                  </ButtonStyled>
                </div>
              ))}
              <div className='form-row center-items'>
                <ButtonStyled
                  type='button'
                  onClick={() => {
                    form.getFieldValue(['times']);
                    add();
                  }}
                >
                  <IconPlusCircle />
                  <span>Add shift</span>
                </ButtonStyled>
              </div>
            </>
          )}
        </Form.List>

        <div className='time-shift-error'>
          <Form.Item
            className='messageError'
            name={'timeShift'}
            rules={[
              {
                validateTrigger: 'onChange',
                validator() {
                  const timeList = form.getFieldValue('times');
                  if (!timeList || timeList.length === 0) {
                    return Promise.reject(
                      new Error('Time shift can not blank')
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            dependencies={['times']}
          />
        </div>

        <div className='form-row repeat-everyweek-check'>
          <Form.Item noStyle name={'repeatEveryWeek'} valuePropName='checked'>
            <Checkbox />
          </Form.Item>

          <p
            className='form-label is-center'
            style={{ cursor: 'pointer' }}
            onClick={() => handleRepeatTextClick()}
          >
            {formLang('timeSchedule.editShiftForm.repeatEveryWeek')}
          </p>
        </div>
      </div>
    </EditShiftFormStyled>
  );
};

const EditShiftFormStyled = styled.div`
  .edit-shift-form-wrap {
    margin-top: 28px;
  }

  .add-shift-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  .edit-shift-datepicker {
    background: #f0f2f6 !important;
  }

  .ant-picker {
    min-width: unset;
  }

  .repeat-everyweek-check {
    margin-bottom: 16px;
  }

  .center-items {
    display: flex;
    justify-content: center;
  }
  .group-time {
    position: relative;
  }
  .editshift-datepicker,
  .editshift-datepicker:hover {
    background: #f0f2f6 !important;
  }

  .messageError {
    margin-top: 16px;
    text-align: center;
  }

  .time-shift-error .ant-form-item-control-input {
    display: none;
  }

  .edit-shift-time-picker,
  .edit-shift-time-picker:hover {
    background: #f0f2f6 !important;
  }

  .time-form-item {
    margin-bottom: 30px;
  }
`;

export default EditShiftForm;
