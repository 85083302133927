import { Form, FormInstance } from 'antd';
import React from 'react';
import ActiveHoursElement from '../pages/widgets/ActiveHoursElement';

interface Props {
  form?: FormInstance
}

const LocationsFormThirdStep: React.FC<Props> = ({ form }: Props) => {
  // const onChange = () => undefined;

  // const range = (start: number, end: number) => {
  //   const result = [];
  //   for (let i = start; i < end; i++) {
  //     result.push(i);
  //   }
  //   return result;
  // };

  // const disabledDateTime = () => ({
  //   disabledHours: () => [...range(0, 6), ...range(18, 24)],
  // });

  const dayOfWeek = [
    {
      'label': 'SUN',
      'value': 'sunday',
    },
    {
      'label': 'MON',
      'value': 'monday',
    },
    {
      'label': 'TUE',
      'value': 'tuesday',
    },
    {
      'label': 'WED',
      'value': 'wednesday',
    },
    {
      'label': 'THU',
      'value': 'thursday',
    },
    {
      'label': 'FRI',
      'value': 'friday',
    },
    {
      'label': 'SAT',
      'value': 'saturday'
    }
  ];

  return (
    <>
      <p className='form-label'>Active Hours</p>
      <div className='form-calendar'>
        <Form.Item name='calenda'>

          {dayOfWeek.map((item: any, index) => (
            <ActiveHoursElement form={form} name={item.value} key={index} label={item.label} />
          ))
          }
        </Form.Item>
      </div>
    </>
  );
};

export default LocationsFormThirdStep;
