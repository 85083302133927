const IconEdit = ({stroke = '#363565'} : {stroke?: string}) => {
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.3906 6.44291C15.5657 6.26781 15.7736 6.12892 16.0024 6.03416C16.2311 5.9394 16.4763 5.89063 16.724 5.89062C16.9716 5.89062 17.2168 5.9394 17.4456 6.03416C17.6743 6.12892 17.8822 6.26781 18.0573 6.44291C18.2324 6.61801 18.3713 6.82587 18.466 7.05465C18.5608 7.28342 18.6096 7.52862 18.6096 7.77624C18.6096 8.02387 18.5608 8.26906 18.466 8.49784C18.3713 8.72661 18.2324 8.93448 18.0573 9.10958L9.05729 18.1096L5.39062 19.1096L6.39063 15.4429L15.3906 6.44291Z'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconEdit;
