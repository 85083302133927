import { Form, Spin } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Dayjs } from 'dayjs';
import CustomersFormSecondStep from './SecondStep';
import customerSelectors from 'features/customers/services/selectors';

const DEFAULT_STEP = 1;
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};


export type IFuncCancel = () => void;

export interface IFormAddCustomerData {
  name: string;
  phoneNumberCode: string;
  phoneNumber: string;
  email: string;
  birthDate: Dayjs;
  numberVisits: string;
  cancellations: string;
  noShows: string;
  loyaltyPoints: string;
  amountSpent: number;
  contact: string;
  importantClientInfo: string;
  gender?: number | null;
}

export type IFuncSubmit = (data: IFormAddCustomerData) => Promise<boolean>;

type Props = {
  onCancel?: () => void;
  onSubmit?: IFuncSubmit;
  errors?: Record<string, string> | null;
};
export type EditCustomerFormRef = {
  prevStep: () => void;
  nextStep: () => void;
  // setErrors: (errors: Record<string, string>) => void;
};
/* eslint-disable-next-line */
const EditCustomerForm = forwardRef<EditCustomerFormRef, Props>((({
  onCancel = () => undefined,
  onSubmit = () => undefined,
  errors,
}, ref) => {
  // const [errors, setErrors] = useState<Record<string, string>>({});
  const [, setStep] = useState(DEFAULT_STEP);
  const [form] = Form.useForm();
  const detail = customerSelectors.getCustomerDetail();

  useEffect(() => {
    form.setFieldsValue({
      name: detail?.name,
      phone_number: detail?.phone_number,
      numberVisits: detail?.total_visited,
      cancellations: detail?.total_cancelled,
      noShows: detail?.total_no_show,
      loyaltyPoints: detail?.loyalty_point,
      amountSpent: detail?.amount_spent,
    });
  }, [detail]);

  const handleCancel = () => {
    onCancel();
    setStep(DEFAULT_STEP);
    form.resetFields();
  };

  const nextStep = () => {
    setStep((currPage) => currPage + 1);
  };

  const prevStep = () => {
    setStep((currPage) => currPage - 1);
  };

  useImperativeHandle(ref, () => ({
    nextStep,
    prevStep,
    // setErrors,
  }));

  const _onSubmit = () => form.submit();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: IFormAddCustomerData) => {
    setLoading(true);
    try {

      const success = await onSubmit(values);
      if (success) {
        form.resetFields();
      }
    } catch (error) {

    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          phoneNumberCode: '+92',
          phoneNumber: null,
          email: null,
          birthDate: null,
          numberVisits: 0,
          cancellations: 0,
          noShows: 0,
          loyaltyPoints: 0,
          amountSpent: 0,
          contact: null,
          importantClientInfo: null,
          gender: 0,
        }}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        scrollToFirstError
      >
        <CustomersFormSecondStep errors={errors} onCancel={handleCancel} onSubmit={_onSubmit} />
      </Form>
    </Spin>
  );
}));

export default EditCustomerForm;

// const FormStyled = styled.div`
//   .ant-form-item-extra {
//     font-style: italic;
//     color:#7980BC;
//   }
// `;
