export const NAME_REDUCER = 'promotion';

export const PREFIX_ACTIONS = 'PROMOTION_FEATURE_';

export const PATH_LOADING = {
    getListPromotions: `loading.${NAME_REDUCER}.getListPromotions`,
    promotionActions: `loading.${NAME_REDUCER}.promotionActions`,
    getDetailPromotion: `loading.${NAME_REDUCER}.getDetailPromotion`,
    generatePromotionCode: `loading.${NAME_REDUCER}.generatePromotionCode`,
};

export const PROMOTION_TYPES = {
    PRICE: 'price',
    PERCENT: 'percent'
};