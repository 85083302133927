import { findIndex, remove } from 'lodash';
import { useAppDispatch } from 'store/hooks';
import checkoutActions from '../services/actions';
import checkoutSelectors from '../services/selectors';
import { IVoucherForSalesSelectedActions, IVoucherForSalesSelectedItem } from '../services/types/voucher';

const useVoucherForSalesFeature = (): [IVoucherForSalesSelectedItem[], IVoucherForSalesSelectedActions] => {
  const selected = checkoutSelectors.getSelectedVouchersForSales() ?? [];
  const dispatch = useAppDispatch();

  const _setQuantity: IVoucherForSalesSelectedActions['setQuantity'] = (id, quantity) => {
    dispatch(checkoutActions.setSelectedVouchersForSales(selected.map(o => {
      if (o.id?.toString() === id?.toString()) return ({
        ...o,
        quantity
      });
      return o;
    })));
  };

  const _remove: IVoucherForSalesSelectedActions['remove'] = (id) => {
    const idx = findIndex(selected, (o: IVoucherForSalesSelectedItem) => o.id?.toString() === id?.toString());
    if (idx === -1) return;

    const newResult = [...selected];
    remove(newResult, o => o.id?.toString() === id?.toString());
    dispatch(checkoutActions.setSelectedVouchersForSales(newResult));
  };
  return [selected, { setQuantity: _setQuantity, remove: _remove }];
};

export default useVoucherForSalesFeature;
