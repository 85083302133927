import { Input } from 'antd';
import FormRow from 'components/common/Form/FormRow';
import message from 'components/common/Message';
import { ButtonSuccessBig } from 'features/bookings/components/BookingForm/Confirm/BasicContent';
import { IFuncPromotionAccept, IFuncSetErrorBoundary, IFuncSoftOnChangeText, IPromotionErrorBoundary } from 'features/checkout/services/types/promotion';
import apisPromotion from 'features/promotions/services/apis';
import { IPromotionDetailInput, IPromotionDetailResData } from 'features/promotions/services/types/promotion';
import settingSelectors from 'features/settings/services/selectors';
import { get } from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import styled from 'styled-components';
import useCheckServicesPromotion, { IService } from './useCheckServicesPromotion';
import checkoutSelectors from 'features/checkout/services/selectors';

export type IPromotionInputRef = {
  setNoSuitableServices: (val: boolean) => void;
  redeem: () => Promise<void>
};
type IPromotionInputProps = {
  location_id?: number | string | null;
  onPromotionAccept?: IFuncPromotionAccept;
  errorBoundary?: IPromotionErrorBoundary | null,
  softOnChangeText?: IFuncSoftOnChangeText;
  setErrorBoundary?: IFuncSetErrorBoundary;
  selectedServices?: IService[]
};
/* eslint-disable-next-line */
const PromotionInput = forwardRef<IPromotionInputRef, IPromotionInputProps>(({
  location_id,
  onPromotionAccept = () => undefined,
  softOnChangeText = () => undefined,
  setErrorBoundary = () => undefined,
  errorBoundary,
  selectedServices = [],
}, ref) => {
  const checkSuitableServices = useCheckServicesPromotion(selectedServices);
  const [text, setText] = useState<string>('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isNoSuitableServices, setNoSuitableServices] = useState(false);
  const [isApplied, setIsApplied] = useState<boolean>();
  const setLoadingPage = useSetLoadingPage();
  const detail = checkoutSelectors.getBookingDetail();

  useEffect(() => {
    if(detail?.promotion.code) {
      setText(detail?.promotion.code);
      const dataMapping = mappingPromotionInfo(detail.promotion);
      const isNoSuitableServices = !checkSuitableServices(dataMapping);
      if (isNoSuitableServices) {
        setNoSuitableServices(true);
        onPromotionAccept(null);
      } else {
        onPromotionAccept(dataMapping);
        setSuccess(true);
      }
      setIsApplied(true);
    }

  }, []);

  const getSetting = settingSelectors.getSetting();
  if (!getSetting?.booking?.is_promotion_redeem) return null;

  if (location_id === null || location_id === undefined) return null;

  const onRedeem = async () => {
    if (!text) return setError('Please input promotion code');
    onPromotionAccept(null);
    setErrorBoundary(null);
    setLoadingPage(true);
    try {
      const res = await apisPromotion.checkPromotionOfflineValid(text, location_id);
      const errorMsg = get(res, 'data.error.message');
      if (errorMsg) {
        setError(errorMsg);
      } else {
        const resData: IPromotionDetailResData = res?.data?.data;
        if (resData) {
          const dataMapping = mappingPromotionInfo(resData);
          const isNoSuitableServices = !checkSuitableServices(dataMapping);
          if (isNoSuitableServices) {
            setNoSuitableServices(true);
            onPromotionAccept(null);
          } else {
            onPromotionAccept(dataMapping);
            setSuccess(true);
          }
          setIsApplied(true);
        }
      }
    } catch (error: any) {
          message.error('Promotion not invalid');
    } finally {
      setLoadingPage(false);
    }
  };

  const textValid = useMemo(() => {
    const successText = 'Redeem promotion code success';
    const noSuitableText = 'Please check again, there are currently no services that match the service you selected';
    if (errorBoundary) {
      if (errorBoundary.error) {
        return ({
          validateStatus: 'error',
          help: errorBoundary.error
        });
      }

      if (errorBoundary.isNoSuitableServices) {
        return ({
          validateStatus: 'warning',
          help: noSuitableText,
        });
      }

      if (errorBoundary.success) {
        return ({
          validateStatus: 'success',
          help: successText,
        });
      }
    }

    if (error) {
      return ({
        validateStatus: 'error',
        help: error
      });
    }

    if (isNoSuitableServices) {
      return ({
        validateStatus: 'warning',
        help: noSuitableText,
      });
    }

    if (success) {
      return ({
        validateStatus: 'success',
        help: successText,
      });
    }

    return ({
      validateStatus: undefined,
      help: undefined
    });
  }, [error, isNoSuitableServices, errorBoundary, success]);

  useImperativeHandle(ref, () => ({
    redeem: () => onRedeem(),
    setNoSuitableServices: (val: boolean) => setNoSuitableServices(val)
  }));

  return (
    <Styled>
      <div className='form-group'>
        <FormRow
          containerClassName='form_item'
          name="promotion"
          // @ts-ignore
          validateStatus={textValid.validateStatus}
          help={textValid.help}
        >
          <div className='form-row' style={{ marginTop: 0 }}>
            <Input placeholder='Enter promotion code' className='checkout-form-btn' value={text} 
              disabled={ !!isApplied }

              onChange={e => {
                if (errorBoundary) setErrorBoundary(null);
              setError('');
              setSuccess(false);
              setNoSuitableServices(false);
              setText(e.target.value.trim());
              softOnChangeText(e.target.value);
              onPromotionAccept(null);
            }} />
            <ButtonSuccessBig className='checkout-form-btn' style={isApplied ? {
              border: '1px solid #f5222d',
              color: '#f5222d'}: undefined} onClick={isApplied ? () => setIsApplied(false) : onRedeem}> {isApplied ? 'Cancel' : 'Redeem'}</ButtonSuccessBig>
          </div>
        </FormRow>
      </div>
    </Styled>

  );
});

export default PromotionInput;

const Styled = styled.div`
margin: 16px 0px;
.form-group {
  display: flex;
  align-items: flex-start;
  .form_item {
    flex:1;
    margin-right: 16px;
  }
}
  .title {
    font-weight: 600;
    font-size: 18px;
    color: var(--color-primary);
  }
`;


export const mappingPromotionInfo = (resData: IPromotionDetailResData) => {  
  const result: string[] = [];
  resData.services.map(o => {
    const parent_id = o.id + '_';
    if (o.service_variants.length > 0)
      o.service_variants.map(f => {
        const id = parent_id + f.id;
        if (result.indexOf(id) === -1)
          result.push(id);
      });
    else {
      if (result.indexOf(parent_id) === -1)
        result.push(parent_id);
    }
  });
  
  return (
    {
      ...resData,
      services: result,
    } as IPromotionDetailInput
  );
};