import { Select } from 'antd';
import { EDateOption } from 'features/workingHour/services/types/workingHour';
import React from 'react';
import styled from 'styled-components';
type ITypeViewPickerProps = {
  onChange: (val: string) => void;
  _options?: {value: string, label: string}[]
  value?: string;
};

const OptionPicker: React.FC<ITypeViewPickerProps> = ({onChange, _options, value}) => {
  const options : {value: string, label: string}[] = _options ?? [
    { value: EDateOption.TODAY, label: 'Today' },
    { value: EDateOption.YESTERDAY, label: 'Yesterday' },
    { value: EDateOption.LAST_7_DAYS, label: 'Last 7 days' },
    { value: EDateOption.LAST_30_DAYS, label: 'Last 30 days' },
    { value: EDateOption.LAST_QUARTER, label: 'Last quarter' },
    { value: EDateOption.LAST_YEAR, label: 'Last year' },
    { value: EDateOption.CUSTOM, label: 'Custom range' },
  ];

  return (
    <TypeViewPickerStyled>
      <div className="common-select">
        <Select
          defaultValue={options[0]?.value ?? ''}
          options={options}
          className='is-normal'
          onChange={onChange}
          value={value}
        />
      </div>
    </TypeViewPickerStyled>
  );
};

export default OptionPicker;
const TypeViewPickerStyled = styled.div``;
