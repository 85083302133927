import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import ReviewsDetail from '../components/ReviewsDetail';
import ReviewsTable from '../components/ReviewsTable';
import InputSearchText from 'components/common/Input/InputSearchDebounce';
import reviewSelectors from '../services/selectors';
import { useDispatch } from 'react-redux';
import reviewActions from '../services/actions';

const ReviewsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reviewActions.setReviewParams({}));
  }, []);

  const params = reviewSelectors.getReviewParam();
  const handleCancel = () => {
    dispatch(reviewActions.clearDetail());
    setIsModalOpen(false);
  };

  const handleDetailClick = (id: string) => {
    dispatch(reviewActions.getDetailReview.fetch(id.toString()));
    setIsModalOpen(true);
  };

  const onSearchText = (value: string) => {
    dispatch(reviewActions.setReviewParams({
      ...params,
      keyword: value,
    }));
  };

  return (
    <>
      <div className={'private-filter'}>
        <InputSearchText value={params?.keyword ?? ''} onSearchText={onSearchText} />
      </div>
      <ReviewsTable handleDetailClick={handleDetailClick} />

      <Modal
        maskClosable={false}
        open={isModalOpen}
        onCancel={handleCancel}
        width={'100%'}
        footer={null}
        className='modal-big'
        forceRender
        centered
        okButtonProps={{ style: { display: 'none' } }}
      >
        <ReviewsDetail handleCancel={handleCancel} />
      </Modal>
    </>
  );
};

export default ReviewsPage;
