import { createAsyncAction } from 'services/actionConfigs';
import {INotificationParam } from './types/notification';
import { PREFIX_ACTIONS } from './constants';

const getListNotification = createAsyncAction<INotificationParam | undefined>(PREFIX_ACTIONS + 'GET_LIST_NOTIFICATION');

const notificationActions = {
    getListNotification
  };
  
  export default notificationActions;