import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import uiSelector from 'services/UI/selectors';
import { PATH_LOADING } from './constants';
import { IProgramItemResData } from './types/program';
import { IRewardItemResData } from './types/reward';
import { ILocationItemResData } from './types/response';
import { IServiceItemResData } from './types/service';
import { ILoyalSettingResData } from './types/setting';


type MyState = RootState['loyal'];

const getCurrentState = (state: RootState): MyState => state.loyal;

const selector = (key: keyof MyState, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getListLoyalProgram = (): IProgramItemResData[] => selector('listLoyalPrograms') as MyState['listLoyalPrograms'];

const loadingListLoyalProgram = (): boolean => uiSelector.getLoading(PATH_LOADING.getLoyalProgram);

const getListLoyalReward = (): IRewardItemResData[] => selector('listLoyalRewards') as MyState['listLoyalRewards'];

const loadingListLoyalReward = (): boolean => uiSelector.getLoading(PATH_LOADING.getLoyalReward);

const getListLocations = () => (selector('locations') ?? []) as ILocationItemResData[];

const getListServices = () => (selector('services') ?? []) as IServiceItemResData[];

const getLoyalSetting = () => (selector('loyalSetting') ?? null) as ILoyalSettingResData | null;

const loyalSelectors = {
  getListLoyalProgram,
  loadingListLoyalProgram,
  getListLoyalReward,
  loadingListLoyalReward,
  getListLocations,
  getListServices,
  getLoyalSetting,
};

export default loyalSelectors;
