import FeeButton from 'features/checkout/components/FeeButton';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { IModalFeeSubmitFunc } from 'features/checkout/components/FeeButton/Modal';
import checkoutSelectors from '../services/selectors';
import checkoutActions from '../services/actions';
type IDiscountFeeProps = {
  viewOnly?: boolean;
};
const DiscountFee: React.FC<IDiscountFeeProps> = ({ viewOnly }) => {
  const dispatch = useAppDispatch();
  const data = checkoutSelectors.getDiscountFee();
  const subTotal = checkoutSelectors.getSubTotal();
  const value = checkoutSelectors.getDiscountFeeValue();

  const handleAdd: IModalFeeSubmitFunc = (val) => {
    dispatch(checkoutActions.setDiscountFee(val));
  };

  const handleRemove = () => {
    dispatch(checkoutActions.setDiscountFee(null));
  };

  if (!subTotal) return null;

  return (
    <DiscountFeeStyled>
      <FeeButton
        type='discount'
        data={data}
        amount={value}
        viewOnly={viewOnly}
        onAdd={handleAdd}
        onRemove={handleRemove} />
    </DiscountFeeStyled>
  );
};

export default DiscountFee;
const DiscountFeeStyled = styled.div``;
