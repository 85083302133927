import fetch from 'services/request';

const BASE_URL = 'api/v1/merchants/:merchant_code';
const setPayment = ( body: any) => {  
    return fetch({
      method: 'post',
      url: `${BASE_URL}/stripe/store`,
      // url: `${BASE_URL}create-intent`,
      body: { ...body }
    });
  };

  const apisPayment = {
    setPayment
  };
  
  export default apisPayment;