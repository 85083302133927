import { Modal } from 'antd';
import message from 'components/common/Message';
import { get, set } from 'lodash';
import { useState, useTransition } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import voucherActions from '../services/actions';
import vouchersApis from '../services/apis';
import { IVoucherItemResData } from '../services/types/voucher';
import VoucherForm, { IVoucherFormValue, VoucherFormProps } from './VoucherForm';
import getMappingDataForm from './hooks/getMappingDataForm';
import voucherSelectors from '../services/selectors';
type EditVoucherProps = {
  data: IVoucherItemResData;
};
function EditVoucher({ data }: EditVoucherProps) {
  const [formData, setFormData] = useState<IVoucherFormValue>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const setPageLoading = useSetLoadingPage();
  const [errors, setErrors] = useState<any>({});
  const [, startTransition] = useTransition();
  const view = voucherSelectors.getLstVoucherParams().view;
  

  const showModal = () => {
    startTransition(() => {
      const _dataMapping = getMappingDataForm(data, view);
      setFormData(_dataMapping);
      setIsModalOpen(true);
    });
  };

  const hideModal = () => {
    setFormData(undefined);
    setIsModalOpen(false);
  };

  const handleSubmit: VoucherFormProps['onSubmit'] = async (payload) => {
    setPageLoading(true);
    try {
      const res = await vouchersApis.editVoucher(data?.id?.toString(), payload);
      const msg = get(res, 'data.message');
      if (msg) {
        message.success(msg);
        dispatch(voucherActions.setVoucherListParams.fetch({}));
        hideModal();
      } else {
        const errorMsg = get(res, 'data.error.message');
        if (errorMsg) message.error(errorMsg);
        else throw 'fail';
      }

    } catch (error) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));

      if (errors.length > 0) {
        const _errors = {};
        errors.map(([key, valueArr]) => {
          const msg = get(valueArr, [0], '');
          message.error(msg);
          set(_errors, [key], msg);
        });
        setErrors(_errors);
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <>
      <li onClick={showModal} className='action-edit'></li>
      <ModalForm errors={errors} formData={formData} visible={isModalOpen} onCancel={hideModal} onsubmit={handleSubmit} />
    </>
  );
}

export default EditVoucher;

type ModalFormProps = {
  visible?: boolean;
  onCancel?: () => void;
  onsubmit?: VoucherFormProps['onSubmit'],
  formData?: IVoucherFormValue;
  errors?: any;
};
const ModalForm = ({ visible, errors, formData, onCancel = () => undefined, onsubmit = () => undefined }: ModalFormProps) => {
  if (!visible) return null;
  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={onCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <h2 className='modal-title'>Edit Voucher</h2>
      <VoucherForm errors={errors} isEdit formData={formData} onCancel={onCancel} onSubmit={onsubmit} />
    </Modal>
  );
};
