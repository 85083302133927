import { useAppDispatch } from 'store/hooks';
import salesActions from '../services/actions';
import salesSelectors from '../services/selectors';
import { IServiceSelectedItem } from '../services/types/service';
import { ICustomerItemResData } from 'features/customers/services/types/cutomer';
import { IVoucherForSalesSelectedActions, IVoucherForSalesSelectedItem } from '../services/types/voucher';
import { findIndex, remove } from 'lodash';
import { EPaymentMethod } from '../services/constants';
export const useSelectedServices = (): [IServiceSelectedItem[], (a: IServiceSelectedItem[]) => void] => {
  const dispatch = useAppDispatch();

  const data = salesSelectors.getSelectedServices();
  const setData = (a: IServiceSelectedItem[]) => {
    dispatch(salesActions.setServiceSelected(a));
  };
  return [data, setData];
};
type Customer = ICustomerItemResData | null;
const useSetCustomerBooking = (): [Customer, (a: Customer) => void] => {
  const dispatch = useAppDispatch();
  const data = salesSelectors.getCustomer();
  const setData = (a: Customer) => {
    dispatch(salesActions.setCustomer(a));
  };

  return [data, setData];
};

const useVoucher = (): [IVoucherForSalesSelectedItem[], IVoucherForSalesSelectedActions] => {
  const selected = salesSelectors.getSelectedVouchers() ?? [];
  const dispatch = useAppDispatch();

  const _setQuantity: IVoucherForSalesSelectedActions['setQuantity'] = (id, quantity) => {
    dispatch(salesActions.setSelectedVouchers(selected.map(o => {
      if (o.id?.toString() === id?.toString()) return ({
        ...o,
        quantity
      });
      return o;
    })));
  };

  const _remove: IVoucherForSalesSelectedActions['remove'] = (id) => {
    const idx = findIndex(selected, (o: IVoucherForSalesSelectedItem) => o.id?.toString() === id?.toString());
    if (idx === -1) return;

    const newResult = [...selected];
    remove(newResult, o => o.id?.toString() === id?.toString());
    dispatch(salesActions.setSelectedVouchers(newResult));
  };
  return [selected, { setQuantity: _setQuantity, remove: _remove }];
};

const useSetDateBooking = (): [string, (a: string) => void] => {
  const dispatch = useAppDispatch();

  const data = salesSelectors.getBookingDate();

  const setData = (a: string) => {  
    dispatch(salesActions.setBookingDate(a));
  };

  return [data, setData];
};

const usePaymentMethod = (): [EPaymentMethod, (e: EPaymentMethod) => void] => {
  const dispatch = useAppDispatch();
  const method = salesSelectors.getPaymentMethod();

  const setMethod = (_method: EPaymentMethod) => {
    dispatch(salesActions.setPaymentMethod(_method));
  };
  return [method, setMethod];
};


const salesHook = {
  useSelectedServices,
  useSetCustomerBooking,
  useVoucher,
  useSetDateBooking,
  usePaymentMethod
};

export default salesHook;
