import { all } from 'redux-saga/effects';
import invoicesServiceSaga from 'features/invoices/services/sagas';
import authServiceSaga from 'features/auth/services/sagas';
import customerServiceSaga from 'features/customers/services/sagas';
import bookingServiceSaga from 'features/bookings/services/sagas';
import userServiceSaga from 'features/users/services/sagas';
import checkoutServiceSaga from 'features/checkout/services/sagas';
import serviceServiceSaga from 'features/services/services/sagas';
import locationServiceSaga from 'features/locations/services/sagas';
import settingServiceSaga from 'features/settings/services/sagas';
import voucherServiceSaga from 'features/vouchers/services/sagas';
import LoyalServiceSaga from 'features/loyaltyProgram/services/sagas';
import analyticServiceSaga from 'features/analytics/services/sagas';
import salesServiceSaga from 'features/sales/services/sagas';
import promotionServiceSaga from 'features/promotions/services/sagas';
import reviewServiceSaga from 'features/reviews/services/sagas';
import workingHourServiceSaga from 'features/workingHour/services/sagas';
import notificationServiceSaga from 'components/layout/Header/service/sagas';
import timeScheduleServiceSaga from 'features/timeSchedule/services/sagas';
export default function* rootSaga() {
  yield all([
    // define module saga here
    authServiceSaga(),
    invoicesServiceSaga(),
    customerServiceSaga(),
    bookingServiceSaga(),
    userServiceSaga(),
    checkoutServiceSaga(),
    serviceServiceSaga(),
    locationServiceSaga(),
    settingServiceSaga(),
    voucherServiceSaga(),
    LoyalServiceSaga(),
    analyticServiceSaga(),
    salesServiceSaga(),
    promotionServiceSaga(),
    reviewServiceSaga(),
    workingHourServiceSaga(),
    notificationServiceSaga(),
    timeScheduleServiceSaga()
  ]);
}
