import { Tabs } from 'antd';
import reviewActions from 'features/reviews/services/actions';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import AppointmentsTab from '../components/AppointmentsTab';
import GeneralTab from '../components/GeneralTab';
import ReviewsTab from '../components/ReviewsTab';
import customerActions from '../services/actions';
import customerSelectors from '../services/selectors';
import styles from './index.module.scss';

const CustomersDetailPage = () => {
  const onChange = () => undefined;
  const { customer_code = '', } = useParams();
  const [params] = useSearchParams();
  
  const dispatch = useDispatch();
  const { t: customerLang } = useTranslation('customer');

  const fetchDetailData = () => {
    const payload = {
      is_walkin_in: !!Number(params.get('is_walkin_in') ?? ''),
      customer_code: customer_code || '',
    };
    dispatch(customerActions.setCustomerCodeDetail(payload));
  };

  useEffect(() => {
    fetchDetailData();
  }, []);
  const customer = customerSelectors.getCustomerDetail();

  useEffect(() => {
    dispatch(reviewActions.getListReview.fetch({
      per_page: 9999,
      keyword: customer?.customer_code.replace('#', '') ?? '',
    }));
  }, [customer]);

  const items = [
    {
      key: '1',
      label: customerLang('Tabs.General'),
      children: <GeneralTab fetchDetailData={fetchDetailData} />,
    },
    {
      key: '2',
      label: customerLang('Tabs.Appointments'),
      children: <AppointmentsTab />,
    },
    // TODO: NEXT PHRASE
    {
      key: '3',
      label: customerLang('Tabs.Reviews'),
      children: <ReviewsTab />,
    },
  ];

  return (
    <div className={styles.customers_detail_page}>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </div>
  );
};

export default CustomersDetailPage;
