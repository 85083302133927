import React, { MouseEventHandler } from 'react';
import { Form, Image, Input, Select } from 'antd';
import ListServices from 'components/common/ListServices';
import AuthAvatar from 'assets/images/auth/auth_avatar.jpg';
type Props = {
  handleCancel?: MouseEventHandler<HTMLDivElement>
}
const ModalBookingsContent = ({ handleCancel = () => undefined }: Props) => {
  const handleSubmit = () => undefined;

  return (
    <>
      <h2 className="modal-title">
        <p>Add New Booking</p>
      </h2>
      <Form
        name="basic"
        className="modal-form"
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <div className="form-inner">
          <div className="form-block">
            <div className="form-row is-wrap">
              <p className="form-label">Services</p>
              <div className="w-full">
                <div className="form-tags-wrapper">
                  <div className="form-tag is-green">GENERAL</div>
                  <div className="form-tag is-red">WAXING</div>
                  <div className="form-tag is-purple">HAND &amp; FEET</div>
                  <div className="form-tag is-yellow">SNS DIPPING</div>
                </div>
              </div>
              <div className="form-input-search">
                <input type="text" placeholder="Search services..." />
              </div>
              <ListServices />
            </div>
          </div>
          <div className="form-block">
            <div>
              <div className="form-row">
                <p className="form-label">Appointment Settings</p>
              </div>
              <div className="form-row">
                <Form.Item label="Service" name="service">
                  <Input placeholder="Waxing - Face" />
                </Form.Item>
                <Form.Item label="Team Member" name="teamMember">
                  <Select
                    placeholder="Select team member"
                    options={[
                      {
                        value: 'team member 1',
                        label: 'team member 1',
                      },
                      {
                        value: 'team member 2',
                        label: 'team member 2',
                      },
                    ]}
                  />
                </Form.Item>
              </div>
              <div className="form-row">
                <Form.Item label="Start Time" name="startTime">
                  <Select
                    placeholder="Select Start Time"
                    options={[
                      {
                        value: '12:00:00',
                        label: '12:00:00',
                      },
                      {
                        value: '13:00:00',
                        label: '13:00:00',
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item label="Duration" name="duration">
                  <Select
                    placeholder="Select Duration"
                    options={[
                      {
                        value: 'Duration 1',
                        label: 'Duration 1',
                      },
                      {
                        value: 'Duration 2',
                        label: 'Duration 2',
                      },
                    ]}
                  />
                </Form.Item>
              </div>
              <div className="form-row">
                <span className="form-btn-add">Add</span>
              </div>
            </div>
            <div>
              <div className="form-row is-wrap">
                <p className="form-label">Appointment</p>
                <ol className="appointment-list">
                  <li>
                    <div>
                      <p>WAXING - Face</p>
                      <p>10:00 - 10:20</p>
                    </div>
                    <div className="appointment-staff">
                      <div className="staff-image">
                        <Image src={AuthAvatar} alt="AuthAvatar" />
                      </div>
                      <p>Abdullah W</p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <p>HAND & FEET - Nail Color </p>
                      <p>10:30 - 11:00</p>
                    </div>
                    <div className="appointment-staff">
                      <div className="staff-image">
                        <Image src={AuthAvatar} alt="AuthAvatar" />
                      </div>
                      <p>Abdullah W</p>
                    </div>
                  </li>
                </ol>
              </div>
              <div className="form-row">
                <p className="form-label">Customer Details</p>
              </div>
              <div className="form-row">
                <Form.Item label="Name" name="name">
                  <Input placeholder="Enter customer name" />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
        <div className="form-submit-wrapper">
          <div onClick={handleCancel} className="common-btn is-white">
            Cancel
          </div>
          <button type="submit" className="common-btn">
            Done
          </button>
        </div>
      </Form>
    </>
  );
};

export default ModalBookingsContent;
