import { get } from 'lodash';
import uiSelector from 'services/UI/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { PATH_LOADING } from './constants';
import { IApiReviewPaginateResponse, IApiReviewParam, IReviewDetailResData } from './types/review';

type MyState = RootState['review'];

const getCurrentState = (state: RootState): MyState => state.review;

const selector = <T = keyof MyState>(key: T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key as any, defaultValue));

const getListReview = () => selector('reviewList') as IApiReviewPaginateResponse;

const getLoadingListReview = () => uiSelector.getLoading(PATH_LOADING.getListReview) as boolean;

const getDetailReview = () => selector('reviewDetail') as IReviewDetailResData;

const getLoadingDetailReview = () => uiSelector.getLoading(PATH_LOADING.getDetailReview) as boolean;

const getReviewParam = () => selector('params') as IApiReviewParam;



const reviewSelectors = {
    getListReview,
    getReviewParam,
    getLoadingListReview,
    getDetailReview,
    getLoadingDetailReview
};

export default reviewSelectors;
