import styled from 'styled-components';
import CateBox from './CateBox';

export interface IServiceCategoryItem {
  id: number | string;
  name: string;
  color: string;
  total_sale: number;
  total_services?: number;
  location_ids?: number[];
}
type Props = {
  data?: IServiceCategoryItem[];
  onChange?: (id: string) => void;
  value?: string;
  disableScroll?: boolean;
};

const ListCategory = (props: Props) => {
  const { data = [], onChange = () => undefined, value, disableScroll = false } = props;

  return (
    <ListCategoryStyled className={`${disableScroll ? 'disableScroll' : ''}`}>
      {data.map((item) => (
        <CateBox
          key={item.id}
          label={item.name}
          className='service_item'
          onClick={() => onChange(String(item?.id))}
          selected={String(item.id) === String(value)}
          color={item.color}
        />
      ))}
    </ListCategoryStyled>
  );
};

export default ListCategory;

const ListCategoryStyled = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  margin-bottom: 8px;
   &.disableScroll {
    display: flex;
  }
  .service_item {
    margin-right: 12px;
    margin-bottom: 12px;
    min-width: max-content;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #d9d9d9;
  }

  ul {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 8px;
    max-width: 100%;
    gap: 8px;
  }
  .item-category {
    position: relative;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    padding: 8px 20px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 6px;
  }
  .category-background {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.1;
    border-radius: 6px;
  }
  .category-name {
    position: relative;
    z-index: 1;
    white-space: nowrap;
  }
`;
