import { Form, Input, Modal, Radio, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import DatePicker from 'components/common/DatePickerInput';
import FormRow from 'components/common/Form/FormRow';
import message from 'components/common/Message';
import PhoneNumberInput from 'components/common/PhoneNumberInput';
import dayjs from 'utils/dayjs';
import customerActions from 'features/customers/services/actions';
import apisCustomer from 'features/customers/services/apis';
import { IApiUpdateCustomerBody } from 'features/customers/services/types/cutomer';
import { get, set } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import storage from 'utils/sessionStorage';
import { formOptionalText } from 'utils/unit';
import NumberValidations from 'utils/validations/number';
const { TextArea } = Input;
type Props = {
  isOpenModal: boolean;
  handleCancel: () => void;
  customer: any;
  setCurrCustomer: any;
  viewOnly?: boolean;
};


const DetailCustomerModal = (props: Props) => {
  const { isOpenModal, handleCancel, customer,
    setCurrCustomer,
    // viewOnly,
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setLoading] = useState(false);

  const getPayload = (data: any) => {
    const merchant_id = storage.merchantId.get() ?? '';
    const name = data?.name;
    const phone_number = data?.phone_number ?? '';
    const email = data?.email ?? '';
    const gender = data?.gender ?? null;
    const date_of_birth = data?.birthDate?.format('YYYY-MM-DD') ?? undefined;
    const payload: IApiUpdateCustomerBody = {
      merchant_id,
      name,
      phone_number,
      email,
      gender,
      date_of_birth,
      is_walkin_in: customer.is_walkin_in,
      important_client_info: data.importantClientInfo ?? '',
    };
    return payload;
  };

  const handleFinish = async (values: any) => {
    setLoading(true);
    const payload = getPayload(values);
    try {
      const rest: AxiosResponse<{ message: string }> =
        await apisCustomer.updateCustomerInfo(customer.id, payload);

      if (rest.data) {
        message.success(rest.data.message);
        dispatch(customerActions.getListCustomers.fetch());
        setCurrCustomer({ ...customer, ...values, important_client_info: payload.important_client_info, date_of_birth: payload.date_of_birth });

        handleCancel();
      }
    } catch (error: any) {
      const textError = get(error, 'response.data.error.message', '');
      if (textError) {
        message.error(textError);
      }

      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.entries(response);
      const errorsObj = {};
      errors.forEach(([key, value]) => {
        set(errorsObj, [key], value?.[0]);
      });
      setErrors(errorsObj);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpenModal) {
      form.setFieldsValue({
        name: customer?.name,
        email: customer?.email,
        birthDate:
          (customer?.date_of_birth && dayjs(customer?.date_of_birth)) ||
          (customer?.birthday && dayjs(customer?.birthday)) ||
          null,
        phone_number: customer?.phone_number,
        importantClientInfo: customer?.important_client_info || null,
      });
    }
  }, [isOpenModal]);


  const errorsText = useMemo(() => {
    return ({
      name: get(errors, 'name', ''),
      phone_number: get(errors, 'phone_number', ''),
      email: get(errors, 'email', ''),
      date_of_birth: get(errors, 'date_of_birth', ''),
      important_client_info: get(errors, 'important_client_info', ''),
    });
  }, [errors]);

  const myFocus = useRef<any>();
  
  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);

  return (
    <Modal
      maskClosable={false}
      open={isOpenModal}
      okButtonProps={{ style: { display: 'none' } }}
      onCancel={handleCancel}
      width={574}
      footer={null}
      forceRender
      centered
    >

      <h2 className='modal-title'>
        <p>Customer Details</p>
      </h2>
      <Spin spinning={isLoading}>
        <Form
          name='basic'
          className='modal-form'
          autoComplete='off'
          onFinish={handleFinish}
          form={form}
          // disabled={viewOnly}
          initialValues={{
            name: customer?.name,
            phone_number: customer?.phone_number,
            email: customer?.email,
            birthDate: customer?.date_of_birth ? dayjs(customer?.date_of_birth) : null,
            importantClientInfo: customer?.important_client_info || null,
            gender: customer?.gender || 0
          }}
          layout='vertical'
        >
          <Form.Item name='name' label='Name'
            rules={[{ required: true }]}
            validateStatus={errorsText.name ? 'error' : undefined}
            help={errorsText.name ? errorsText.name : undefined}
          >
            <Input ref={myFocus} />
          </Form.Item>
          <Form.Item name='phone_number' label='Phone number'
            rules={[
              { required: true },
              NumberValidations.PHONE
            ]}
            validateStatus={errorsText.phone_number ? 'error' : undefined}
            help={errorsText.phone_number ? errorsText.phone_number : undefined}
          >
            <PhoneNumberInput />
          </Form.Item>
          <FormRow optional name='email' label='Email' rules={[{ type: 'email', message: 'is not a valid email!' },]}
            validateStatus={errorsText.email ? 'error' : undefined}
            help={errorsText.email ? errorsText.email : undefined}
          >
            <Input />

          </FormRow>
          <FormRow label='Gender' name='gender' optional>
            <Radio.Group>
              <Radio value={0}>Female</Radio>
              <Radio value={1}>Male</Radio>
              <Radio value={2}>Non-binary</Radio>
            </Radio.Group>
          </FormRow>
          <Form.Item
            name='birthDate'
            label={
              <p>
                Birth Date<span>{formOptionalText}</span>
              </p>
            }
            validateStatus={errorsText.date_of_birth ? 'error' : undefined}
            help={errorsText.date_of_birth ? errorsText.date_of_birth : undefined}
          >
            <DatePicker clearIcon={false} />
          </Form.Item>
          <Form.Item
            className='important-note'
            label={
              <p>
                Appointment notes
                <span>(Optional)</span>
              </p>
            }
            name='importantClientInfo'
            extra={'This note will be displayed on all bookings. '}
            validateStatus={errorsText.important_client_info ? 'error' : undefined}
            help={errorsText.important_client_info ? errorsText.important_client_info : undefined}
          >
            <TextArea
              rows={5}
              placeholder={'Include comments for your bookings'}
            />
          </Form.Item>
          <div className='form-row form-submit-wrapper'>
            <button className='common-btn is-white' onClick={handleCancel}>
              Cancel
            </button>
            {/* {!viewOnly && <button className='common-btn' type='submit'>
              Save
            </button>
            } */}
            <button className='common-btn' type='submit'>
              Save
            </button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default DetailCustomerModal;
