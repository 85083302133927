import InputSearchText from 'components/common/Input/InputSearchDebounce';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import InvoicesTable from '../components/InvoicesTable';
import invoiceActions from '../services/actions';
import Category from '../widgets/Category';
import { first, last } from 'lodash';
import moment from 'moment';
import { Dayjs } from 'dayjs';
import invoiceSelectors from '../services/selectors';
import { useRangeDate } from 'components/common/GroupFilter/hook/useRangeDate';
import GroupFilterDate from 'components/common/GroupFilter';

const InvoicesPage = () => {
  const dispatch = useAppDispatch();
  const params = invoiceSelectors.getInvoiceAllParam();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    dispatch(invoiceActions.setInvoiceParams({
      page: Number(searchParams.get('page') ?? 1),
      per_page: Number(searchParams.get('per_page') ?? 10),
      start_date: moment().unix(),
      end_date: moment().unix(),
    }));
  }, []);


  const handleSearchInvoice = (keyword: string) => {
    dispatch(invoiceActions.setInvoiceParams({
      keyword
    }));
  };

  const getRangeDate = useRangeDate;

  const onChangeOptionPicker = (val:string) => {
      // if(val === EDateOption.CUSTOM)
      //   return;
      const [unit, amount] = val.split('_');
      const [dateStart, dateEnd] = getRangeDate(unit, Number(amount));    
    
      dispatch(invoiceActions.setInvoiceParams({
        start_date: dateStart,
        end_date: dateEnd,
        page: 1,
        // date: undefined,
      }));    
    };


  const onChangeDateRangePicker = (values: Dayjs[]) => {
    const startDate = first(values);
    const endDate = last(values);
    dispatch(invoiceActions.setInvoiceParams({
      start_date: startDate?.unix(),
      end_date: endDate?.unix(),
      page: 1
    }));
  };

  return (
    <>
      <div className="private-filter">    
        <GroupFilterDate onChangeOptionPicker={onChangeOptionPicker} onChangeDateRangePicker={onChangeDateRangePicker} optionDate={{dateEnd: params.end_date, dateStart: params.start_date}}/>
        {/* <TimeFilter /> */}
        <Category />
        <InputSearchText onSearchText={handleSearchInvoice} />
      </div>
      <InvoicesTable />
    </>
  );
};

export default InvoicesPage;
