import { Popover } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation, useResolvedPath } from 'react-router-dom';
import styles from '../index.module.scss';
type INavItem = {
  text: string;
  path: string;
  icon: JSX.Element;
  childrens: {
    text: string;
    path: string;
    icon: JSX.Element;
  }[];
};
type Props = {
  data: INavItem;
  isNavShow: boolean;
  openSub: boolean;
  setOpenSub: (val: boolean) => void;
};
const NavItem = ({ data, isNavShow, setOpenSub, openSub }: Props) => {
  const [isActive, setIsActive] = useState(false);
  const [isParentActive, setIsParentActive] = useState(false);
  const { text, icon, childrens } = data;
  const pathname = useResolvedPath('');
  const location = useLocation();

  useEffect(() => {
    if (!isNavShow) {
      setIsActive(false);
    }
  }, [isNavShow]);

  useEffect(() => {
    const isActive = childrens.some((children) => {
      return window.location.pathname.includes(children?.path);
    });
    function handleClickOutside() {
      setIsActive(false);
    }
    setIsParentActive(isActive);
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [pathname, childrens]);

  useEffect(() => {
    setIsActive(false);
  }, [location]);

  const activeStyle = {
    background: 'rgba(195, 194, 224, 0.2)',
    opacity: '1',
  };

  const subMenu = useMemo(() => {
    return (
      <div className={styles.navbar_item}>
        <ul className={styles.navbar_sub_menu}>
          {childrens.map((children, index) => {
            const { text, path, icon } = children;
            return (
              <li key={index}>
                <NavLink
                  to={path}
                  style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                  }
                  className={({ isActive }) =>
                    isActive ? styles.navbar_link_active : undefined
                  }
                  onClick={() => setOpenSub(false)}
                >
                  {icon}
                  <span>{text}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }, [childrens, setOpenSub]);

  return (
    <Popover
      arrow={false}
      trigger='click'
      placement='rightBottom'
      overlayInnerStyle={{ padding: 0, borderRadius: 0 }}
      open={openSub}
      onOpenChange={setOpenSub}
      align={{ offset: [14, 0] }}
      content={subMenu}
    >
      <span
        className={`${(isActive || openSub) ? styles.nav_item_active : undefined} ${(isParentActive || openSub) ? styles.nav_item_parent_active : undefined
          }`}
        style={(isParentActive || openSub) ? activeStyle : undefined}
      >
        {icon}
        <span>{text}</span>
      </span>
    </Popover >
  );
};

export default NavItem;
