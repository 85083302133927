import { createSlice } from '@reduxjs/toolkit';
import { CalendarViewType } from 'constants/index';
import SYSTEM_VARIABLES from 'constants/variables';
import { VIEW } from 'constants/view.enum';
import authActions from 'features/auth/services/actions';
import settingActions from 'features/settings/services/actions';
import { cloneDeep, find, findIndex, first, get, last, maxBy, merge, minBy, remove, set, sumBy } from 'lodash';
import { IPagination } from 'models/config';
import moment, { Moment } from 'moment';
import getArrayDatesOfWeek from 'utils/moment/getDatesOfWeek';
import actions from './actions';
import { BREAK_TIME_MINUTES, NAME_REDUCER, TIME_DATE_TIMEZONE, zoomViewOptionData } from './constants';
import { IBookingItemResData, IParamOptionCalendar } from './types/booking';
import { IBookingOnlineResData } from './types/bookingOnline';
import { IBookAssignmentsResponse, ILocationDataConfig, IServiceQuickBookingItem, TimeOpening } from './types/quickBooking';
import { IState } from './types/reducer';
import { IServiceItem } from './types/service';
import bookingActions from './actions';
import { getTimeRound5Minute } from 'utils/unit';

const nowWeekDays = getArrayDatesOfWeek(moment().format());
const breakTimeMinutes = BREAK_TIME_MINUTES;

const initialState: IState = {
  bookings: [],
  teamMembers: [],
  lstBookingStatus: [],
  merchantLocations: [],
  hasScrolled: false,
  listTable: {
    params: {
      merchant_location_id: undefined,
      date: moment().valueOf(),
    },
    data: [],
    pagination: null,
  },
  calendarTable: {
    params: {
      merchant_location_id: undefined,
      date: moment().valueOf(),
    },
    previousDate: moment().valueOf(),
    zoomView: zoomViewOptionData.ONE_HOUR,
    zoomViewOptions: [],
    daysOfWeek: nowWeekDays,
    viewType: CalendarViewType.MemberView,
    activeLocation: null,
    workingHour: {
      open: 9,
      close: 18,
      openMinute: 0,
      closeMinute: 0,
    },
    pixelPerMinute: 2.25,
  },
  bookingLayout: VIEW.GRID,
  bookingOnline: {
    categories: [],
    services: [],
    locations: [],
    selectedServices: [],
    formValue: null,
    promotionActive: null,
    bookingTime: {
      finish: '',
      start: '',
    },
    merchant_location_id: null,
  },
  quickBooking: {
    selectedServices: [],
    initialServices: [],
    bookingTime: getTimeRound5Minute(moment()).format(),
    customer: null,
    bookingStatus: '',
    isEdit: false,
    detail: null,
    dataConfigs: null,
    busyEmployees: [],
    merchant_location_id: null,
  },
  currentTime: moment().format(),
  workingHour: {
    open: 9,
    close: 18,
  },
  activePopover: null,
  listActivity: []
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(bookingActions.setHasScrolled, (state, { payload }) => {
        state.hasScrolled = payload ?? false;
      })
      .addCase(authActions.logout.success, (state) => {
        state.bookings = [];
        state.teamMembers = [];
        state.lstBookingStatus = [];
        state.merchantLocations = [];
        state.listTable = {
          params: {
            merchant_location_id: undefined,
            date: moment().valueOf(),
          },
          data: [],
          pagination: null,
        };
        state.calendarTable = {
          params: {
            merchant_location_id: undefined,
            date: moment().valueOf(),
          },
          previousDate: moment().valueOf(),
          zoomView: zoomViewOptionData.ONE_HOUR,
          zoomViewOptions: [],
          daysOfWeek: nowWeekDays,
          viewType: CalendarViewType.MemberView,
          activeLocation: null,
          workingHour: {
            open: 9,
            close: 18,
            openMinute: 0,
            closeMinute: 0,
          },
          pixelPerMinute: 2.25,
        };
        state.bookingLayout = VIEW.CALENDAR;
        state.bookingOnline = {
          categories: [],
          services: [],
          locations: [],
          selectedServices: [],
          formValue: null,
          promotionActive: null,
          bookingTime: {
            finish: '',
            start: '',
          },
          merchant_location_id: null,
        };
        state.quickBooking = {
          selectedServices: [],
          initialServices: [],
          bookingTime: getTimeRound5Minute(moment()).format(),
          customer: null,
          bookingStatus: '',
          isEdit: false,
          detail: null,
          dataConfigs: null,
          busyEmployees: [],
          merchant_location_id: null,
        };
        state.currentTime = moment().format();
        state.workingHour = {
          open: 9,
          close: 18,
        };
        state.activePopover = null;
      })
      .addCase(actions.setActivePopover, (state, { payload }) => {
        state.activePopover = payload;
      })
      .addCase(actions.getLstTableBookings.success, (state, { payload }) => {
        const resData = payload as {
          data: IBookingItemResData[],
          pagination: IPagination | null,
        };

        state.listTable.data = resData.data;
        state.listTable.pagination = resData.pagination;
      })
      .addCase(actions.setListTableBookingParams, (state, { payload }) => {
        const status = state.calendarTable.params.book_status?.length === 0 ? state.lstBookingStatus.map(o => o.value) : state.calendarTable.params.book_status;
        const employee_id = state.teamMembers.map(o => o.id);
        
        state.listTable.params = {
          ...state.calendarTable.params,
          book_status: status,
          employee_id:  [...employee_id as (string | number)[] ?? []],
          page: 1,
          per_page: 10,
          ...payload ?? {},
        };        
      })
      .addCase(actions.setBookingOnlineActiveLocation, (state, { payload }) => {
        state.bookingOnline.merchant_location_id = payload?.id || null;
      })
      .addCase(actions.getBookings.success, (state, { payload }) => {
        const data = payload as IBookingItemResData[];
        state.bookings = data.sort((o1, o2) => moment(o2.time_start).unix() - moment(o1.time_start).unix());
      })
      .addCase(actions.setTeamMembersBooking, (state, { payload }) => {
        state.teamMembers = (payload ?? []).filter(o => {
          if ((o?.is_receive_book === undefined || o?.is_receive_book === null) && o.merchant_location_id === state.calendarTable.params.merchant_location_id )
            return true;
          return !!o.is_receive_book && o.merchant_location_id === state.calendarTable.params.merchant_location_id;
        });
        
      })
      .addCase(actions.setLstBookingStatus, (state, { payload }) => {
        state.lstBookingStatus = payload ?? [];
      })
      .addCase(actions.setLstMerchantLocation, (state, { payload }) => {        
        state.merchantLocations = payload ?? [];
        
        const location_id = state.calendarTable.params.merchant_location_id;
        const _activeLocation = payload.find(o => o.id?.toString() === location_id?.toString()) ?? payload.length > 0 ? payload[0] : null;
        if (_activeLocation) {

          const locationNew = {
            ..._activeLocation,
            time_opening: _activeLocation?.time_opening?.map(o => {
              const timeStart = o?.time_start?.split(':');
              const start = +(first(timeStart) ?? '');
              const timeEnd = o?.time_end?.split(':');
              const end = +(first(timeEnd) ?? '');
              const end24 = moment(o?.time_end + ' pm', 'hh:mm:ss a').get('hour');
              return ({
                start,
                end: start >= end ? end24 : end,
                startMinute: +get(timeStart, 1, 0),
                endMinute: +get(timeEnd, 1, 0),
                weekday: o.weekday,
              });
            }),
          };
          state.calendarTable.activeLocation = locationNew;
          state.calendarTable.params.merchant_location_id = locationNew.id;
          
          if (state.calendarTable.viewType === CalendarViewType.MemberView) {
            const _calendarDate = state.calendarTable.params.date;
            const weekday = moment(_calendarDate).format('dddd').toLocaleLowerCase();
            const activeDate = locationNew.time_opening.filter(o => o.weekday === weekday);

            if (activeDate) {
              const timeStart = minBy(activeDate, o => o.start);
              const timeEnd = maxBy(activeDate, o => o.end);

              state.calendarTable.workingHour.open = timeStart?.start ?? state.workingHour.open;
              state.calendarTable.workingHour.openMinute = (timeEnd?.startMinute ?? 0);
              state.calendarTable.workingHour.close = timeEnd?.end || state.workingHour.close;
              // state.calendarTable.workingHour.closeMinute = (timeEnd?.endMinute ?? 0);
            }
          } else {
            const timeStart = minBy(locationNew.time_opening, o => o.start);
            const timeEnd = maxBy(locationNew.time_opening, o => o.end);
            state.calendarTable.workingHour.open = timeStart?.start ?? state.workingHour.open;
            state.calendarTable.workingHour.openMinute = (timeStart?.startMinute ?? 0);
            state.calendarTable.workingHour.close = timeEnd?.end || state.workingHour.close;
            // state.calendarTable.workingHour.closeMinute = (timeEnd?.endMinute ?? 0);
          }
        }

      })
      .addCase(actions.setCalendarBookingParams, (state, { payload }) => {
        const status = state.calendarTable.params.book_status?.length === 0 ? state.lstBookingStatus.map(o => o.value) : state.calendarTable.params.book_status;
        const employee_id = state.bookingLayout === VIEW.GRID ? state.teamMembers.map(o => o.id) : state.calendarTable.params.employee_id;
      
        state.calendarTable.params = {
          ...state.calendarTable.params,
          book_status: status,
          employee_id: [...employee_id as (string | number)[] ?? []],
          ...payload ?? {},
        };
        if (payload.date)
          state.calendarTable.previousDate = payload.date;
      })
      .addCase(actions.setCalendarDaysOfWeek, (state, { payload }) => {
        state.calendarTable.daysOfWeek = payload ?? [];
        const start_date = first(payload)?.value ?? undefined;
        const end_date = last(payload)?.value ?? undefined;
        state.calendarTable.params.start_date = start_date ? moment(start_date).valueOf() : undefined;
        state.calendarTable.params.end_date = end_date ? moment(end_date).valueOf() : undefined;
      })
      .addCase(actions.setCalendarViewType, (state, { payload }) => {
        state.calendarTable.viewType = payload;
      })
      .addCase(actions.setCalendarZoomView, (state, { payload }) => {
        state.calendarTable.zoomView = payload;
        switch (payload) {
          case zoomViewOptionData.QUARTER_HOUR: {
            state.calendarTable.pixelPerMinute = 5;
            break;
          }
          case zoomViewOptionData.HAlF_HOUR: {
            state.calendarTable.pixelPerMinute = 3.5;
            break;
          }
          case zoomViewOptionData.ONE_HOUR:
          default:
            state.calendarTable.pixelPerMinute = 2.25;
        }
      })
      .addCase(actions.setBookingLayout, (state, { payload }) => {
        state.bookingLayout = payload === 'Gird' ? VIEW.GRID : payload;
        delete state.listTable.params?.keyword;
        delete state.calendarTable.params?.keyword;
      })
      .addCase(settingActions.getSetting.success, (state, { payload }) => {
        state.bookingLayout = (get(payload, 'general.booking_layout', '') === 'Gird' ? VIEW.GRID : get(payload, 'general.booking_layout', '')) || VIEW.CALENDAR;
      })
      .addCase(actions.getBookingOnlineData.success, (state, { payload }) => {
        const { categories = [], services = [], locations = [] } = payload as IBookingOnlineResData;
        state.bookingOnline.categories = categories;
        state.bookingOnline.services = services;
        state.bookingOnline.locations = locations;
      })
      .addCase(actions.bookingOnlineServiceSelected.toggle, (state, { payload }) => {
        const serviceItem = payload;
        const idx = findIndex(state.bookingOnline.selectedServices, (o) => o.id === serviceItem.id && o?.service_variant_id === serviceItem.service_variant_id);
        if (idx !== -1) {
          remove(state.bookingOnline.selectedServices, (n) => n.id === serviceItem.id && n.service_variant_id === serviceItem.service_variant_id);
        } else {
          state.bookingOnline.selectedServices.push(serviceItem);
        }
      })
      .addCase(actions.setBookingOnlineFormValues, (state, { payload }) => {
        state.bookingOnline.formValue = payload;
        const totalPrice = +sumBy(state.bookingOnline.selectedServices, (o) => (o.price || o.sale_price) * o.quantity)?.toFixed(2);
        const timeStart = moment(payload?.bookingTime);
        
        state.bookingOnline.bookingTime.start = moment(payload?.bookingTime).format();
        const book_assignment_services = state.bookingOnline.selectedServices?.map(o => {
          const { merchant_employees } = (o.payload) ?? {};
          const duration_time = o.duration ?? 0;
          const result = {
            service_id: o.id,
            service_variant_id: o.service_variant_id,
            time_start: timeStart.format('YYYY-MM-DD HH:mm:ss'),
            duration_time,
            quantity: o.quantity ?? 0,
            employee_id: o.employee_id ?? get(first(merchant_employees), 'id') ?? null,
            sale_price: o.price ?? 0,
          };
          const quantity = (o.quantity ?? 0);
          const prevServiceMinutes = (duration_time * quantity) + (quantity) * breakTimeMinutes;
          timeStart.add( prevServiceMinutes, 'minute' );
          return result;
        }) ?? [];

        state.bookingOnline.selectedServices.length > 0 ?
          state.bookingOnline.bookingTime.finish = timeStart.subtract(breakTimeMinutes, 'minute').format() :
          state.bookingOnline.bookingTime.finish = timeStart.add(10, 'minute').format();

        merge(state.bookingOnline.formValue, {
          ['totalPrice']: totalPrice,
          ['platform']: 'all',
          book_assignment_services,
        });
      })
      .addCase(actions.setBookingOnlineFormValueItem, (state, { payload }) => {
        const { key, value } = payload;
        merge(state.bookingOnline.formValue, { [key]: value });
      })
      .addCase(actions.setCurrentTime, (state, { payload }) => {
        state.currentTime = payload;
      })
      .addCase(actions.quickBooking.setServiceLst, (state, { payload }) => {
        const timeStart = moment(state.quickBooking.bookingTime);
        let shiftOneTime = first(payload)?.shift_one_time;
        let quantityMax = 0;        
        state.quickBooking.selectedServices = payload.map((o, index) => {
          const result = {
            ...o,
            time_start: timeStart.format()
          } as IServiceQuickBookingItem;
          const nextIndex = index < payload.length ? index + 1 : index;
          const quantity = (o.quantity ?? 1);
          quantityMax = quantityMax < quantity ? quantity : quantityMax;

          if (shiftOneTime !== payload[nextIndex]?.shift_one_time) {

            shiftOneTime = o?.shift_one_time ?? - 1;
            const prevServiceMinutes = ((o.duration_time ?? 0) * quantityMax) + (quantityMax - 1) * breakTimeMinutes;
            timeStart.add(breakTimeMinutes + prevServiceMinutes, 'minute');
          }
          return result;
        });
      })
      .addCase(actions.quickBooking.setBookingDate, (state, { payload }) => {
        const currentTime = moment(state.currentTime);
        const curBookingDate = moment(payload);
        let bookingTime = payload;
        if (currentTime.isSame(curBookingDate, 'day')) {
          if (curBookingDate.isBefore(currentTime)) {
            bookingTime = currentTime.add(BREAK_TIME_MINUTES, 'minute').format(TIME_DATE_TIMEZONE);
          }
        }
        state.quickBooking.bookingTime = bookingTime;
        const timeStart = moment(bookingTime);
        state.quickBooking.selectedServices = state.quickBooking.selectedServices.map(o => {
          const result = {
            ...o,
            time_start: timeStart.format()
          } as IServiceQuickBookingItem;
          const breakTimeMinutes = BREAK_TIME_MINUTES;
          const quantity = (o.quantity ?? 0);
          const prevServiceMinutes = ((o.duration_time ?? 0) * quantity) + (quantity - 1) * breakTimeMinutes;
          timeStart.add(breakTimeMinutes + prevServiceMinutes, 'minute');
          return result;
        });
      })
      .addCase(actions.bookingOnlineSetQuantityServiceItem, (state, { payload }) => {
        const { id, value, service_variant_id } = payload;
        const item = find(state.bookingOnline.selectedServices, (o: IServiceItem) => o.id === id && o.service_variant_id === service_variant_id);
        if (item) {
          item.quantity = value;
        }
      })
      .addCase(actions.quickBooking.setEditData, (state, { payload }) => {
        state.quickBooking.isEdit = true;
        const { bookingDate, services, bookingStatus, customer, merchantLocationId } = payload;
        state.quickBooking.bookingStatus = bookingStatus;
        state.quickBooking.merchant_location_id = merchantLocationId;

        

        state.quickBooking.bookingTime = bookingDate;
        const timeStart = moment(bookingDate);
        const selectedServices = services.map(o => {
          const result = {
            ...o,
            id: o.service_id,
            service_variant_id: o.service_variant_id ?? undefined,
            time_start: timeStart.format(),
            serviceVariantName: o.serivce_variant_name,
          } as IServiceQuickBookingItem;
          const breakTimeMinutes = BREAK_TIME_MINUTES;
          const quantity = (o.quantity ?? 0);
          const prevServiceMinutes = ((o.duration_time ?? 0) * quantity) + (quantity - 1) * breakTimeMinutes;
          timeStart.add(breakTimeMinutes + prevServiceMinutes, 'minute');
          return result;
        });
        state.quickBooking.selectedServices = selectedServices;
        state.quickBooking.initialServices = cloneDeep(selectedServices);

        state.quickBooking.customer = customer;
        state.quickBooking.busyEmployees = [];
      })
      .addCase(actions.quickBooking.setCustomer, (state, { payload }) => {
        state.quickBooking.customer = payload;
      })
      .addCase(actions.getOnlineLocations.success, (state, { payload }) => {
        state.bookingOnline.locations = payload;
      })
      .addCase(actions.quickBooking.setBusyEmployees, (state, { payload }) => {
        state.quickBooking.busyEmployees = payload;
      })
      .addCase(actions.quickBooking.setMerchantLocationId, (state, { payload }) => {
        state.quickBooking.merchant_location_id = payload.value;
        state.quickBooking.selectedServices = [];
      })
      .addCase(actions.quickBooking.reset, (state, { payload }) => {
        let bookingTime = moment();
        const merchant_location_id: number | string | null = payload?.merchant_location_id ?? null;
        if (payload) {
          if (payload.bookingTime > bookingTime.valueOf()) {
            bookingTime = moment(payload.bookingTime);
          }
        }

        state.quickBooking = {
          selectedServices: [],
          initialServices: [],
          bookingTime: getTimeRound5Minute(bookingTime).format(),
          customer: null,
          isEdit: false,
          detail: null,
          bookingStatus: '',
          dataConfigs: null,
          busyEmployees: [],
          merchant_location_id,
        };
      })
      .addCase(actions.getBookingFirstInit, (state) => {
        state.calendarTable.previousDate = moment().valueOf();
        state.calendarTable.viewType = CalendarViewType.MemberView;
                
        // state.calendarTable.params.book_status = state.lstBookingStatus.map(o => o.value);
        // state.listTable.params.book_status = state.lstBookingStatus.map(o => o.value);
      })
      .addCase(actions.quickBooking.getDataConfigs.success, (state, { payload }) => {
        const data = payload as IBookAssignmentsResponse['data'];
        state.quickBooking.dataConfigs = data;
        state.quickBooking.dataConfigs.categories = data.categories.sort((o1, o2) => o2.total_sale - o1.total_sale);
        const _location = data.locations.filter(o => o.value?.toString() !== SYSTEM_VARIABLES.ALL_LOCATIONS?.toString());
        set(state.quickBooking.dataConfigs, 'locations', _location);
        let merchant_location_id = state.quickBooking.merchant_location_id;
        let curLocation: ILocationDataConfig | null = _location.find(o => o.value?.toString() === merchant_location_id?.toString()) ?? null;
        if (!merchant_location_id) {
          const firstLocation = first(_location);
          merchant_location_id = get(firstLocation, 'value') ?? null;
          curLocation = firstLocation ?? null;
        }

        state.quickBooking.merchant_location_id = get(curLocation, 'value') ?? null;

        if (!state.quickBooking.isEdit) {
          const date = moment(state.quickBooking.bookingTime);
          const isAfterNow = date.isAfter(moment());
          if (isAfterNow) {
            state.quickBooking.bookingTime = getValidBookingDate(state.quickBooking.bookingTime, curLocation);
          }

          state.quickBooking.selectedServices = state.quickBooking.selectedServices.map(o => {
            if (o['service_variant_id'] === null || o['service_variant_id'] === undefined) {
              const item = find(data.services, (se: IServiceQuickBookingItem) => se.id === o.id);
              if (item) return { ...item, ...o, };
              return o;
            }

            const item = find(data.services, (se: IServiceQuickBookingItem) => {
              if (se.id === o.id) {
                return !!se.service_variants.some(x => x.id === o.service_variant_id);
              }
              return false;
            });

            if (!item) return o;

            return ({
              ...item,
              ...o,
              serviceVariantName: item.service_variants.find(x => x.id === o.service_variant_id)?.name,
            });
          });
        }
      })
      .addCase(actions.getActivities.success, (state, { payload }) => {
        state.listActivity = payload;
      })
      .addCase(actions.setBookingOnlineActivePromotion, (state, { payload }) => {
        state.bookingOnline.promotionActive = payload;
      })
      .addCase(actions.getParamOptionCalendar.success, (state, { payload }) => {
        switch (payload.calendar_zoom) {
          case zoomViewOptionData.QUARTER_HOUR: {
            state.calendarTable.pixelPerMinute = 5;
            break;
          }
          case zoomViewOptionData.HAlF_HOUR: {
            state.calendarTable.pixelPerMinute = 3.5;
            break;
          }
          case zoomViewOptionData.ONE_HOUR:
          default:
            state.calendarTable.pixelPerMinute = 2.25;
        }

        const bookStatus = (payload as IParamOptionCalendar).booking_status_filter;
        const employee = (payload as IParamOptionCalendar).employee_filter;
        const zoomView = (payload as IParamOptionCalendar).calendar_zoom; 

        
        const merchantLocationId = payload.merchant_location_id;
        const locationDefaultId = state.merchantLocations.length > 0 ? state.merchantLocations[0].id : 0;        
        const employee_filter = employee?.length === 0 || state.bookingLayout === VIEW.GRID ? [...state.teamMembers.filter(o => o.merchant_location_id === state.calendarTable.params.merchant_location_id).filter(o => o.is_receive_book === 1).map(o => o.id)] : employee;
        const booking_status_filter = bookStatus?.length === 0 ? state.lstBookingStatus.map(o => o.value) : bookStatus;
                      
        state.calendarTable.params.merchant_location_id = typeof merchantLocationId === 'number' && merchantLocationId ? (merchantLocationId ?? locationDefaultId) :locationDefaultId;
        state.calendarTable.zoomView = typeof zoomView === 'number' && zoomView? zoomView : 60;
        state.calendarTable.params.employee_id = [...employee_filter as (string | number )[]];
        state.calendarTable.params.book_status = booking_status_filter;
        state.listTable.params.book_status = booking_status_filter;
      });
  },
});
const bookingServiceReducer = Slice.reducer;
export default bookingServiceReducer;

const getValidDateData = (date: Moment, time_opening?: TimeOpening[] | undefined): Moment => {
  const openNextDayList = time_opening?.filter(o => o.weekday?.toLocaleLowerCase() === date.format('dddd').toLocaleLowerCase()) ?? [];
  if (openNextDayList?.length > 0) {
    const time = get(openNextDayList, [0, 'time_start'])?.split(':');
    return date.clone().set({
      hour: +get(time, [0], 0),
      minute: +get(time, [1], 0),
      second: 0,
    });
  }
  return getValidDateData(date.clone().add(1, 'day'), time_opening);
};

const checkValidDateBooking = (date: Moment, time_opening?: TimeOpening[] | undefined): string => {
  if (time_opening?.length === 0) return date.format();
  const openDayList = (time_opening?.filter(o => o.weekday?.toLocaleLowerCase() === date.format('dddd').toLocaleLowerCase()) ?? []).map(o => {
    const time_start = o?.time_start?.split(':');
    const time_end = o?.time_end?.split(':');
    const _timeStart = date.clone().set({
      hour: +get(time_start, [0], 0),
      minute: +get(time_start, [1], 0),
      second: 0,
    });
    const _timeEnd = date.clone().set({
      hour: +get(time_end, [0], 0),
      minute: +get(time_end, [1], 0),
      second: 0,
    });

    return ({
      weekday: o.weekday,
      timeStart: _timeStart,
      timeEnd: _timeEnd,
    });
  });
  if (openDayList?.length > 0) {
    const time_start = minBy(openDayList, o => o.timeStart.valueOf())?.timeStart;
    const time_end = maxBy(openDayList, o => o.timeEnd.valueOf())?.timeEnd;
    if (!time_start || !time_end) return date.format();

    const isLessThanTimeStart = date < time_start;
    if (isLessThanTimeStart) {
      return time_start?.format();
    }

    const isBetween = date.isBetween(time_start, time_end);
    if (isBetween) {
      return date.format();
    }
    const nextDay: Moment = date.clone().add(1, 'day');
    return getValidDateData(nextDay, time_opening)?.format();
  } else {
    return getValidDateData(date.clone().add(1, 'day'), time_opening).format();
  }
};
const getValidBookingDate = (date?: string | null, firstLocation?: ILocationDataConfig | null) => {
  const now = date ? moment(date) : moment();
  return checkValidDateBooking(now, firstLocation?.time_opening);
}; 