import MultipleSelect from 'components/common/MultipleSelect';
import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
import { IApiBookingParams } from 'features/bookings/services/types/booking';
import { memo, useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import apisBooking from 'features/bookings/services/apis';

const TeamMemberSelect = ({isCalendar = false}: {isCalendar?: boolean}) => {
  const dispatch = useAppDispatch();
  const teamMembers = bookingSelectors.getTeamMembers();
  const value = bookingSelectors.getCalendarParamValue('employee_id') as IApiBookingParams['employee_id'];
  const merchant_location_id = bookingSelectors.getCalendarParamValue('merchant_location_id') as IApiBookingParams['merchant_location_id'];  


  const options = useMemo(() => {
    return teamMembers.filter(o => o.merchant_location_id?.toString() === merchant_location_id?.toString()).map(item => {
      return ({
        value: item.id,
        title: item.full_name,
      });
    });
    
  }, [teamMembers, merchant_location_id]);

  const allValue = options.map(o => o.value);  

  // if(options.length < 1) return null;

  // useEffect(() => {
  //   dispatch(bookingActions.setCalendarBookingParams({
  //     employee_id: allValue,
  //   }));
  // }, [options]);
  

  const onChange = async (val: (string | number)[]) => {
    dispatch(bookingActions.setCalendarBookingParams({
      employee_id: val.length === options.length ? undefined : val,
    }));
    isCalendar ? await apisBooking.storeParamOptionCalendar({action_behavior: 'employee_filter', action_behavior_value: val}) : undefined;
  };



  return (
    <MultipleSelect
      onChange={onChange}
      disabled={options.length < 1}
      value={options.length < 1 ? ['No employee'] : (value === undefined ? allValue : value)}
      options={options}
      isWhiteInput
      maxTagPlaceholder='employees'
      placeholder='Select employees'
      showArrow={true}
    />
  );
};

export default memo(TeamMemberSelect);
