import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import authActions from 'features/auth/services/actions';

const initialState = {
  analytic: {
    booking: {},
    new_customer: {},
    service: [],
    working_hours: [],
    booking_cancel: {},
    voucher: {},
    promotion: {},
    review: {},
    revenue_service: [],

  },
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.getAnalytics.success, (state, { payload }) => {
        state.analytic = payload;
      })
      .addCase(authActions.logout.success, (state) => {
        state.analytic = {
          booking: {},
          new_customer: {},
          service: [],
          working_hours: [],
          booking_cancel: {},
          voucher: {},
          promotion: {},
          review: {},
          revenue_service: [],
        };
      })
      ;

  },
});

const analyticServiceReducer = Slice.reducer;
export default analyticServiceReducer;
