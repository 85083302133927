import fetch from 'services/request';
import { IApiCheckoutByCardBody, IApiCheckoutByCashBody, IApiCheckoutByVoucherBody, IApiCheckoutWithMixinBody } from './types/checkout';
import { IApiUpdateCusInfoBody } from './types/customer';
import { IApiUpdateBookingBody } from './types/service';

const getBookingDetail = (id: string) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/:merchant_code/book-assignments/${id}`,
  });
};

const getServiceConfigs = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/book-assignments/online/create',
  });
};

const updateCusInfo = (customerId: string, body: IApiUpdateCusInfoBody) => {
  return fetch({
    method: 'put',
    url: 'api/v1/merchants/customers/update/' + customerId,
    body: body as any,
  });
};

const apiCheckoutByCash = (book_assignment_id: string, body: IApiCheckoutByCashBody) => {
  return fetch({
    method: 'post',
    url: `api/v1/merchants/:merchant_code/book-assignments/${book_assignment_id}/checkout/cash`,
    body: body as any,
  });
};

const apiCheckoutByCard = (book_assignment_id: string, body: IApiCheckoutByCardBody) => {
  return fetch({
    method: 'post',
    url: `api/v1/merchants/:merchant_code/book-assignments/${book_assignment_id}/checkout/card`,
    body: body as any,
  });
};

const apiCheckoutByVoucher = (book_assignment_id: string, body: IApiCheckoutByVoucherBody) => {
  return fetch({
    method: 'post',
    url: `api/v1/merchants/:merchant_code/book-assignments/${book_assignment_id}/checkout/voucher`,
    body: body as any,
  });
};

const apiCheckoutWithMixin = (book_assignment_id: string, body: IApiCheckoutWithMixinBody) => {
  return fetch({
    method: 'post',
    url: `api/v1/merchants/:merchant_code/book-assignments/${book_assignment_id}/checkout/mixin`,
    body: body as any,
  });
};

export const apiUpdateBookingServices = (book_assignment_id: string, body: IApiUpdateBookingBody) => {
  return fetch({
    method: 'put',
    url: `api/v1/merchants/:merchant_code/book-assignments/${book_assignment_id}`,
    body: body as any,
  });
};

const getRewardOfCustomer = (customer_code: string, param: any) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/:merchant_code/customers/${customer_code}/list-reward`,
    params: param as any,
  });
};

const getVoucherOfCustomer = (customer_code: string) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/:merchant_code/vouchers/customer/${customer_code.replace('#', '')}`,
  });
};

const reviewPointCustomerWillReceive = (sub_total: number, merchant_location_id: number) => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/loyals/programs/point-customer',
    params: { sub_total, merchant_location_id }
  });
};

const apisCheckout = {
  getBookingDetail,
  getServiceConfigs,
  updateCusInfo,
  apiCheckoutByCash,
  apiCheckoutByCard,
  apiCheckoutByVoucher,
  apiCheckoutWithMixin,
  apiUpdateBookingServices,
  getRewardOfCustomer,
  getVoucherOfCustomer,
  reviewPointCustomerWillReceive,
};

export default apisCheckout;
