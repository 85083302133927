import { EBookingStatus } from 'constants/index';
import apisBooking from 'features/bookings/services/apis';
import fetch from 'services/request';
import { IBodyApiCreateSaleByCard, IBodyApiCreateSaleByCash, IBodyApiCreateSaleByMixin } from './types/checkout';

const getServiceConfigs = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/book-assignments/online/create',
  });
};


const getListVouchers = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/vouchers',
    params: { view: 'sale', per_page: 99999, status: 'active' },
  });
};

const getCheckoutList = (date: number, merchant_location_id: number | string) => {
  return apisBooking.getListBooking({
    book_status: [EBookingStatus.IN_PROGRESS],
    date,
    merchant_location_id,
  });
};

const getVoucherOfCustomer = (customer_code: string) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/:merchant_code/vouchers/customer/${customer_code.replace('#', '')}`,
  });
};

const apiPaymentSale = {
  byCash: (body: IBodyApiCreateSaleByCash) => {
    return fetch({
      method: 'post',
      url: 'api/v1/merchants/:merchant_code/sales/checkout/cash',
      body: body as any,
    });
  },
  byVoucher: (body: IBodyApiCreateSaleByCash) => {
    return fetch({
      method: 'post',
      url: 'api/v1/merchants/:merchant_code/sales/checkout/voucher',
      body: body as any,
    });
  },
  byCard: (body: IBodyApiCreateSaleByCard) => {
    return fetch({
      method: 'post',
      url: 'api/v1/merchants/:merchant_code/sales/checkout/card',
      body: body as any,
    });
  },
  byMixin: (body: IBodyApiCreateSaleByMixin) => {
    return fetch({
      method: 'post',
      url: 'api/v1/merchants/:merchant_code/sales/checkout/mixin',
      body: body as any,
    });
  },
};

const reviewPointCustomerWillReceive = (sub_total: number, merchant_location_id: number) => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/loyals/programs/point-customer',
    params: { sub_total, merchant_location_id }
  });
};

const getRewardOfCustomer = (customer_code: string, param: any) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/:merchant_code/customers/${customer_code}/list-reward`,
    params: param as any,
  });
};

const apisSales = {
  getServiceConfigs,
  getListVouchers,
  getCheckoutList,
  getVoucherOfCustomer,
  apiPaymentSale,
  reviewPointCustomerWillReceive,
  getRewardOfCustomer,
};

export default apisSales;
