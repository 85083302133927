import { formatMoney, roundNumber } from 'utils/unit';
import { IResultItem } from '../Forms/ServiceDetails';
import { Fragment } from 'react';
import { IResInvoiceItem } from 'features/invoices/services/types/invoice';
import styled from 'styled-components';

const InvoiceServiceDetail = ({ data }: { data?: IResInvoiceItem | null }) => {

  const getValuePercent = (percent: number) => {
    return roundNumber((data?.subtotal ?? 0) * (percent / 100));
  };

  const extraFeeValue = (data?.extra_fee?.fee_type ?? 'amount') === 'percent' ? getValuePercent(Number(data?.extra_fee?.fee_value ?? 0)) : Number(data?.extra_fee?.fee_value) ?? 0;
  const discountFeeValue = (data?.discount_type ?? 'amount') === 'percent' ?
    ((data?.subtotal ?? 0) + extraFeeValue) * (Number(data?.discount_value ?? 0) / 100) :
    Number(data?.discount_value) ?? 0;
    

  const resultsInvoice: IResultItem[] = [
    {
      label: 'Subtotal',
      value: data?.subtotal ?? 0,
      isMinus: false,
    },
    {
      label: 'Reward',
      value: data?.reward?.total_amount_reward ?? 0,
      isMinus: true,
    },
    {
      label: 'Voucher',
      value: data?.total_amount_apply_voucher ?? 0,
      isMinus: true,
    },
    {
      label: data?.extra_fee?.name || '' && 'Extra fee',
      value: extraFeeValue,
      isPlus: true,
    },
    {
      label: data?.discount_name || '' && 'Extra discount',
      value: discountFeeValue,
      isMinus: true,
    },
    {
      label: 'Promotion',
      value: data?.total_apply_promotion ?? 0,
      isMinus: true,
    },
    {
      label: 'Public Holiday charge',
      value: roundNumber(data?.holiday_public?.total_amount_surcharge ?? 0),
    },
    {
      label: 'Card surcharge',
      value: roundNumber(data?.card_surcharge_price ?? 0),
    },
    {
      label: 'Refund' + (data?.refunds && data?.refunds?.length > 0 ? ' - ' + data?.refunds[0]?.refund_method : ''),
      value: data?.refunds && data?.refunds?.length > 0 ? data?.refunds[0].amount_refund : 0,
      isMinus: true,
    },
    {
      label: 'TOTAL',
      value: (data?.total_price ?? 0) - (data?.refunds && data?.refunds?.length > 0 ? data?.refunds[0]?.amount_refund : 0) ?? 0,
      isMinus: false,
      isFinalTotal: true,
    },
  ];

  const ResultItem = ({ label, value, isMinus = false, isPlus = false, isFinalTotal = false }: IResultItem) => {
    return value > 0 || isFinalTotal ? (
      <tr className={isFinalTotal ? 'tr-total' : ''}>
        <td colSpan={4} className='table-total'>{label}</td>
        <td><span style={{ whiteSpace: 'nowrap' }}>{isMinus ? '-' : ''} {isPlus ? '+' : ''} {formatMoney(value)}</span></td>
      </tr>
    ) : <></>;
  };

  return (
    <InvoiceServiceDetailStyled className="invoice-service-details-card">
      <table className="services-table">
        <thead>
          <tr className='tr-service'>
            {/* <th></th> */}
            <th>ITEM</th>
            <th className="text-price">QTY</th>
            {/* <th>PRICE</th> */}
            <th className="text-price">PRICE</th>
          </tr>
          <tr>
            <td colSpan={5} className="divider"></td>
          </tr>
        </thead>
        <tbody>
          {data?.merchant_invoice_details.map((row, idx) => {
            // const index = idx + 1;
            return (
              <Fragment key={idx}>
                <tr>
                  {/* <td>
                    {index >= 10 ? index : '0' + index}
                  </td> */}
                  <td>
                    <p className='service_name'>{row.service_name || row.service_variant_name}</p>
                    <p className='service_by'>{row.service_variant_name || row.service_name} with {row.employee_name}</p>
                  </td>
                  <td className="text-price">{row.quantity}</td>
                  {/* <td>{formatMoney(row?.service_price ?? 0)}</td> */}
                  <td className="text-price">{formatMoney((row?.service_price ?? 0) * row.quantity)}</td>
                </tr>
                <tr>
                  <td colSpan={5} className="divider"></td>
                </tr>
              </Fragment>
            );
          })}
          {data?.voucher_sales.map((row, idx) => {
            // const index = data?.merchant_invoice_details.length + idx + 1;
            return (
              <Fragment key={idx}>
                <tr>
                  {/* <td>{index >= 10 ? index : '0' + index}</td> */}
                  <td>
                    <p className='service_name'>{row.voucher_name}</p>
                    {/* <p className='service_by'>{row.voucher_code}</p> */}
                  </td>
                  <td className="text-price">{row.quantity}</td>
                  {/* <td className="text-price">{formatMoney(row?.retail_price ?? 0)}</td> */}
                  <td className="text-price">{formatMoney((row?.retail_price ?? 0) * row.quantity)}</td>
                </tr>
                <tr>
                  <td colSpan={3} className="divider"></td>
                </tr>
              </Fragment>
            );
          })}
          <tr>
            <td colSpan={3} className="space"></td>
          </tr>

          {resultsInvoice.map((o, index) => (
            <ResultItem key={index} {...o} />
          ))}

          {/* <tr className='tr-payment-method'>
                <td colSpan={4}>Payment method</td>
                <td className="text">{data?.payment_methods}</td>
              </tr>
              <tr className='tr-payment-method'>
                <td colSpan={4}> <IconReward /> <span>Earned ({data?.earned_point} {pluralizeWord('point', data?.earned_point ?? 0)})</span></td>
              </tr> */}
        </tbody>
      </table>
    </InvoiceServiceDetailStyled>
  );
};

export default InvoiceServiceDetail;

const InvoiceServiceDetailStyled = styled.div`
  margin-top: 80px;
  .table-total {
    text-transform: uppercase;
  }
  .tr-service {
    border-bottom: 1px solid #363565; 
    height: 35px;
    max-width: 24px;
    th {
      width: 25%;
    }
    ul > li:first-child {
      width: 30px;
    }
    ul > li:last-child {
      width: 30%;
    }
  }
  .tr-total {
    font-weight: 600;
  }
`;