export enum CalendarDateViewType {
    DayView = 'dayView',
    WeekView = 'weekView',
}
export enum CalendarViewType {
    DaysView = 'daysView',
    MemberView = 'MemberView',
}

export enum EBookingStatus {
    OPEN = 'open',
    IN_PROGRESS = 'in_progress',
    COMPLETED = 'completed',
    CANCELLED = 'cancelled',
    NO_SHOW = 'no_show',
    DRAFT = 'draft',
}