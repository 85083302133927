import { maxBy, minBy } from 'lodash';
import { ILocationDataConfig } from '../services/types/quickBooking';
import moment from 'moment';
import { IMerchantLocationItemResData } from '../services/types/booking';

const getTimeOpening = (date: string, activeLocation?: ILocationDataConfig | IMerchantLocationItemResData | undefined | null) => {
    if (!date || !activeLocation) return null;

    const lst = activeLocation?.time_opening?.filter(o => o?.weekday === moment(date)?.format('dddd')?.toLowerCase());

    const time_start = minBy(lst, o => moment(o.time_start, 'HH:mm:ss').valueOf())?.time_start;
    const time_end = maxBy(lst, o => moment(o.time_end, 'HH:mm:ss').valueOf())?.time_end;

    const timeStart = moment(time_start, 'HH:mm:ss');
    const timeEnd = moment(time_end, 'HH:mm:ss');

    return { timeStart, timeEnd };
};

export default getTimeOpening;
