import { IVoucherItemResData } from 'features/vouchers/services/types/voucher';
import { IVoucherFormValue } from '../VoucherForm';
import { getFormatShortDate } from 'utils/moment/getSingleDateShortFormat';

const getMappingDataForm = (data: IVoucherItemResData, view?: string) => {
  const restrictService: string[] = [];
  data.services?.forEach((service) => {
    if (service?.service_variants?.length > 0) {
      service?.service_variants.forEach(serviceVariant => {
        restrictService.push(service?.id + '-' + serviceVariant.id);
      });
    } else {
      restrictService.push(service.id + '');
    }
  });

  const result: IVoucherFormValue = ({
    restrictService,
    retailPrice: +(data?.retail_price ?? 0),
    terms: data?.description ?? '',
    validityPeriod: data?.validity_period,
    value: +(data.value ?? 0),
    voucherName: data.name,
    enable: view === 'purchased' ? data.is_enable : data.status !== 'deactive',
    voucherCode: data.voucher_code,
    expiryDate: data?.expiry_date ? (getFormatShortDate(data.expiry_date, 'YYYY-MM-DD HH:mm:ss') ?? '') : '',
    redeemed: Number(data?.redeemed_value?.toFixed(2)) ?? 0,
    remaining: Number(data?.remaining?.toFixed(2)) ?? 0,
    customerName: data?.customer?.name,
    targetQuantity: (data?.target_quantity ?? 0) > 0 ? data?.target_quantity : 0,
    unlimited: data?.unlimited
  });
  return result;
};

export default getMappingDataForm;
