import { AxiosResponse } from 'axios';
import { set } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { setLoading } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import { IApiTimeScheduleListReq } from '../types/api';
import { PATH_LOADING } from './constants';
import { IResTimeScheduleList } from '../types/timeSchedule';
import apis from './api';
import actions from './actions';
import { getPureParams } from './selectors';
import uiActions from 'services/UI/actions';
import { ITeamMemberItemResData, MerchantLocation } from '../types/reducer';
import moment from 'moment';

const getListTimeSchedules: ISagaFunc<IApiTimeScheduleListReq> = function* ({
  payload,
}) {
  yield setLoading(PATH_LOADING.getListTimeSchedules, true);
  try {
    const res: AxiosResponse<{ data: IResTimeScheduleList }> = yield call(
      apis.getListTimeSchedule,
      payload
    );
    if (res?.data) {
      yield put(actions.getListTimeSchedules.success(res.data));
    }
  } catch (error) {
    yield put(actions.getListTimeSchedules.fail({}));
  }
  yield setLoading(PATH_LOADING.getListTimeSchedules, false);
};

const setListTimeScheduleParams: ISagaFunc<IApiTimeScheduleListReq> =
  function* () {

    const currentParams = yield select(getPureParams);

    const _params: IApiTimeScheduleListReq = {
      ...currentParams,
    };
    _params.end_date = moment(_params.end_date).unix();
    _params.start_date = moment(_params.start_date).unix();

    if (_params.per_page === undefined || _params.per_page === null) {
      set(_params, 'per_page', 20);
    }
    yield put(actions.getListTimeSchedules.fetch(_params));
  };

const getInit = function* () {

  yield put(uiActions.setLoadingPage(true));
  try {
    const locationRes: AxiosResponse<{data: MerchantLocation[]}> = yield call(apis.getLocations);
    const teamMemberRes: AxiosResponse<{data: ITeamMemberItemResData[]}> = yield call(apis.getTeamMembers);
    
    const dataRes: any = {
      location: [],
      teamMember: []
    };

    if (locationRes?.data?.data) {
      const locationData = locationRes.data.data ?? [];
      dataRes.location = locationData;
    }

    if (teamMemberRes?.data?.data) {
      const memberData = teamMemberRes.data.data ?? [];
      dataRes.teamMember = memberData;
    }

    yield put(actions.setTimeScheduleFilterOpts(dataRes));
    yield put(actions.setListTimeScheduleParams({}));

  } catch (error) {
    yield put(actions.getInit.fail({}));
  } finally {
    yield put(uiActions.setLoadingPage(false));
  }
};

export default function* timeScheduleServiceSaga() {
  yield takeLatest(actions.getListTimeSchedules.fetch, getListTimeSchedules);
  yield takeLatest(
    actions.setListTimeScheduleParams,
    setListTimeScheduleParams
  );
  yield takeLatest(
    actions.getInit.fetch,
    getInit
  );
}
