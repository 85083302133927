import styled from 'styled-components';

export const IconTrash = ({stroke = '#363565'}: {stroke?: string}) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4066_31817)">
        <path d="M2.26562 4.94238H3.59896H14.2656" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.9325 4.94206V14.2754C12.9325 14.629 12.792 14.9682 12.5419 15.2182C12.2919 15.4682 11.9527 15.6087 11.5991 15.6087H4.93245C4.57883 15.6087 4.23969 15.4682 3.98965 15.2182C3.7396 14.9682 3.59912 14.629 3.59912 14.2754V4.94206M5.59912 4.94206V3.60872C5.59912 3.2551 5.7396 2.91596 5.98965 2.66591C6.23969 2.41587 6.57883 2.27539 6.93245 2.27539H9.59912C9.95274 2.27539 10.2919 2.41587 10.5419 2.66591C10.792 2.91596 10.9325 3.2551 10.9325 3.60872V4.94206" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_4066_31817">
          <rect width="16" height="16" fill="white" transform="translate(0.265625 0.942383)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconDuplicate = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4066_31864)">
        <path d="M13.599 6.94238H7.59896C6.86258 6.94238 6.26562 7.53934 6.26562 8.27572V14.2757C6.26562 15.0121 6.86258 15.609 7.59896 15.609H13.599C14.3353 15.609 14.9323 15.0121 14.9323 14.2757V8.27572C14.9323 7.53934 14.3353 6.94238 13.599 6.94238Z" stroke="#363565" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.59912 10.9421H2.93245C2.57883 10.9421 2.23969 10.8016 1.98965 10.5515C1.7396 10.3015 1.59912 9.96235 1.59912 9.60872V3.60872C1.59912 3.2551 1.7396 2.91596 1.98965 2.66591C2.23969 2.41587 2.57883 2.27539 2.93245 2.27539H8.93245C9.28608 2.27539 9.62521 2.41587 9.87526 2.66591C10.1253 2.91596 10.2658 3.2551 10.2658 3.60872V4.27539" stroke="#363565" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_4066_31864">
          <rect width="16" height="16" fill="white" transform="translate(0.265625 0.942383)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconNote = () => {
  return (
    <svg width="30" height="17" viewBox="0 -2 1 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.33366 2.16634H10.0003C11.4731 2.16634 12.667 3.36025 12.667 4.83301V11.4997C12.667 12.9724 11.4731 14.1663 10.0003 14.1663H3.33366C1.8609 14.1663 0.666992 12.9724 0.666992 11.4997V4.83301C0.666992 3.36025 1.8609 2.16634 3.33366 2.16634H4.00033M9.33366 2.16634C9.33366 2.90272 8.73671 3.49967 8.00033 3.49967H5.33366C4.59728 3.49967 4.00033 2.90272 4.00033 2.16634M9.33366 2.16634C9.33366 1.42996 8.73671 0.833008 8.00033 0.833008H5.33366C4.59728 0.833008 4.00033 1.42996 4.00033 2.16634M4.00033 6.16634H9.33366M4.00033 8.83301H9.33366M4.00033 11.4997H6.66699" stroke="#363565" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export const IconDash = () => {
  return (
    <IconDashStyled>
      <svg
        width='17'
        height='17'
        viewBox='0 0 17 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.26579 9.60872C8.63398 9.60872 8.93245 9.31025 8.93245 8.94206C8.93245 8.57387 8.63398 8.27539 8.26579 8.27539C7.8976 8.27539 7.59912 8.57387 7.59912 8.94206C7.59912 9.31025 7.8976 9.60872 8.26579 9.60872Z'
          stroke='#363565'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.9323 9.60872C13.3005 9.60872 13.599 9.31025 13.599 8.94206C13.599 8.57387 13.3005 8.27539 12.9323 8.27539C12.5641 8.27539 12.2656 8.57387 12.2656 8.94206C12.2656 9.31025 12.5641 9.60872 12.9323 9.60872Z'
          stroke='#363565'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.5988 9.60872C3.96699 9.60872 4.26546 9.31025 4.26546 8.94206C4.26546 8.57387 3.96699 8.27539 3.5988 8.27539C3.23061 8.27539 2.93213 8.57387 2.93213 8.94206C2.93213 9.31025 3.23061 9.60872 3.5988 9.60872Z'
          stroke='#363565'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </IconDashStyled>
  );
};
const IconDashStyled = styled.div`
  height: 20px;
  width: 20px;
  margin-left: 12px;
  border-radius: 4px;
  &:hover {
    background-color: var(--color-gray);
  }
`;
