export type IApiDataWorkingHourParams = {
    per_page?: number;
    page?: number;
    merchant_location_id?: number;
    merchant_employee_id?: (string | number)[];
    end_date?: number;
    start_date?: number;
    date?: number;
};

export type IApiDetailWorkingHourParams = {
    merchant_location_id?: number;
    merchant_employee_id?: (string | number)[];
    start_date?: number;
    end_date?: number;
};


export interface IWorkingHourResData {
    total_top_analytic: ITotalTopAnalyticData;
    list_staff_analytic: IListStaffAnalytic;
}

export interface IListStaffAnalytic {
    data: IStaffAnalyticDataItem[];
    pagination: IStaffAnalyticPagination;
}

export interface IStaffAnalyticDataItem {
    id: number;
    full_name: string;
    phone: string;
    email: null | string;
    total_sales: number;
    no_of_customer: number;
    total_worked: number | string;
    timing: {
        total_worked_date: string | number;
        date: string,
        timing: {id: number, time_checkin: string, time_checkout: string}[]
    }[]
}
export interface IBodyWorkingHour {
    date: string;
    work_times: IWorkTime[];
}

interface IWorkTime {
    check_in?: string;
    check_out?: string;
  }

export interface IStaffAnalyticPagination {
    current_page: number;
    from_record: number;
    to_record: number;
    total_record: number;
    record_per_page: number;
    total_page: number;
}

export interface ITotalTopAnalyticData {
    total_worked: string;
    total_employee_actived: number;
    total_customer_serviced: number;
    total_holiday: number;
}

export enum EDateOption {
    TODAY = 'day_0',
    YESTERDAY = 'day_1',
    LAST_7_DAYS = 'days_7',
    LAST_30_DAYS = 'days_30',
    LAST_QUARTER = 'quarter_1',
    LAST_YEAR = 'year_1',
    CUSTOM = 'custom'
  }