import { RuleObject } from 'antd/es/form';
import { formatMoney } from 'utils/unit';

const ONLY_NUMBER = { pattern: /^[0-9]*$/, message: 'is not a valid number!' };
const PHONE = { pattern: /^04+[0-9]{8}$/, message: 'The phone number is 04 and has 10 digits' };
const CURRENCY = {
  validator(rule: RuleObject, value: any, callback: (error?: string | undefined) => void) {
    if(! value) {
      callback(undefined);
      return;
    }
    const valueSplit = value.toString().split('.');
    if(! (valueSplit.length === 1 || valueSplit.length === 2 && valueSplit[1].length <= 2)) callback('The amount contains a decimal number with no more than 2 digits');
    else callback(undefined);
  },
};

const max = (valueMax: number) => {
  return {
    validator(rule: RuleObject, value: any, callback: (error?: string | undefined) => void) {
      if(! value) {
        callback(undefined);
        return;
      }
      if( value > valueMax) callback('The value must not exceed ' + formatMoney(valueMax));
      else callback(undefined);
    },
  };
};

const NumberValidations = {
  ONLY_NUMBER,
  PHONE,
  CURRENCY,
  max
};
export default NumberValidations;
