import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import checkoutSelectors from 'features/checkout/services/selectors';
import { Empty, Input, Spin } from 'antd';
import ForSalesItem from 'features/checkout/components/VouchersTabs/ForSalesItem';
import { IVoucherDataItem, IVoucherForSalesSelectedItem } from 'features/checkout/services/types/voucher';
import { useAppDispatch } from 'store/hooks';
import checkoutActions from 'features/checkout/services/actions';
import { remove } from 'lodash';
import useSearchDebounce from 'hooks/useSearchDebounce';

const ForSalesTab = () => {
  const vouchersStore = checkoutSelectors.getVouchers().filter(o => o.unlimited || (o.remaining_quantity ?? 0) > 0);
  const [searchText, setSearchText] = useState('');
  const [text, onDebounce] = useSearchDebounce(async (value: string) => {
    const textSearch = value.toLowerCase();
    setSearchText(textSearch);
  }, vouchersStore);

  const vouchers = useMemo(() => vouchersStore.filter(o =>
    o.name?.toLocaleLowerCase().includes(searchText?.toLocaleLowerCase()) ||
    o.voucher_code?.toLocaleLowerCase().includes(searchText?.toLocaleLowerCase())
  ), [vouchersStore, searchText]);

  const selected = checkoutSelectors.getSelectedVouchersForSales() ?? [];
  const dispatch = useAppDispatch();

  const onChooseVoucher = (o: IVoucherDataItem) => () => {
    const isExist = !!selected.find(s => s.id?.toString() === o.id?.toString());
    const newResult = [...selected];
    if (isExist) {
      // remove
      remove(newResult, s => s.id?.toString() === o.id?.toString());
    } else {
      // add new
      const result: IVoucherForSalesSelectedItem = {
        ...o,
        quantity: 1,
      };
      newResult.push(result);
    }
    dispatch(checkoutActions.setSelectedVouchersForSales(newResult));
  };

  const getChecked = (o: IVoucherDataItem) => !!selected.find(s => s.id?.toString() === o.id?.toString());

  return (
    <>
      <Spin spinning={ !vouchers }>
        <div className='common-input-search'>
          <Input
            type='text'
            value={text}
            placeholder="Search voucher code or voucher's name"
            onChange={onDebounce}
          ></Input>
        </div>
        <ForSalesTabStyled>
          {vouchers?.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No voucher'} /> :
            vouchers.map(o => (
              <ForSalesItem
                key={o.id}
                data={o}
                onChoose={onChooseVoucher(o)}
                checked={getChecked(o)}
              />
            ))
          }
        </ForSalesTabStyled>
      </Spin>
    </>
  );
};

export default ForSalesTab;

const ForSalesTabStyled = styled.div`
  max-height:40vh;
  overflow: scroll;
`;