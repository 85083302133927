import { Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
// import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import { PATH } from 'constants/path.enum';
import { get } from 'lodash';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import locationActions from '../services/actions';
import apisLocation from '../services/apis';
import locationSelectors from '../services/selectors';

const LocationsTable = () => {
  // const [searchParams] = useSearchParams();

  const data = locationSelectors.getPaginateListLocations();
  const pagination = data.pagination;
  const [searchParams, setSearchParams] = useSearchParams();
  const loading = locationSelectors.loadingLocationsPaginateList();



  const dispatch = useDispatch();

  // const page: { page: number, per_page: number } = {
  //   page: Number(searchParams.get('page')) ?? 1,
  //   per_page: Number(searchParams.get('per_page')) ?? 10
  // };

  const dataSource = data?.data?.map((item: any) => ({
    ...item,
    statusLabel: item.status ? 'Active' : 'Disable'
  }));

  interface DataType {
    key: React.Key;
    name: string;
    address: string;
    contact_number: string;
    status: string;
    actions: string;
  }

  const setPageLoading = useSetLoadingPage();
  const confirmRef = useRef<ModalConfirmRef>(null);
  useEffect(() => {
    dispatch(locationActions.getListPaginateLocations.fetch({
      page: Number(searchParams.get('page')),
      per_page: Number(searchParams.get('per_page')),
    }));
  },[searchParams]);

  const confirm = (id: string) => {
    const onOk = async () => {

      setPageLoading(true);
      try {
        const res = await apisLocation.deleteLocation(id?.replace('#', ''));
        const msg = get(res, 'data.message', '');
        if (msg) {
          message.success(msg);
          dispatch(locationActions.deleteLocation.success({}));
          dispatch(locationActions.getListPaginateLocations.fetch({
            page: Number(searchParams.get('page')),
            per_page: Number(searchParams.get('per_page')),
          }));
        } else {
          const msgError = get(res, 'data.error.message');
          if (msgError) message.error(msgError);
          else throw 'fail';
        }
      } catch (error) {
        message.error('An error occurred. Please try again!');
      } finally {
        setPageLoading(false);
      }
    };
    confirmRef.current?.show({
      title: 'Are you sure',
      icon: 'delete',
      msg: 'Are you sure remove this location?',
      submit: onOk
    });
  };
  // const handleUpdateStatus = async (id: number, status: boolean) => {
  //   setPageLoading(true);
  //   try {
  //     const res = await apisLocation.updateLocationStatus(id, status);
  //     const msg = get(res, 'data.message', '');
  //     if (msg) {
  //       dispatch(locationActions.getListPaginateLocations.fetch({
  //         page: 1,
  //         per_page: 10
  //       }));
  //       message.success(msg);
  //     } else {
  //       const msgError = get(res, 'data.error.message', '');
  //       if (msgError) message.error(msgError);
  //       else throw 'fail';
  //     }
  //   } catch (error) {
  //     const errors = Object.entries(get(error, 'response.data.errors', {}));
  //     if (errors.length > 0) {
  //       errors.map(([, valueArr]) => {
  //         message.error(get(valueArr, [0], ''));
  //       });
  //       return;
  //     }
  //     message.error('An error occurred. Please try again');
  //   } finally {
  //     setPageLoading(false);
  //   }
  // };
  // const onSwitchStatus = (id: number, status: boolean) => {
  //   if (!confirmRef) return;
  //   const nextStatus = !status;
  //   confirmRef.current?.show({
  //     title: 'Are you sure',
  //     msg: `Are you sure ${nextStatus ? 'enable' : 'disable'} this location?`,
  //     submit: () => handleUpdateStatus(id, nextStatus),
  //   });
  // };

  const columns: ColumnsType<DataType> = [
    {
      title: 'Store Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left'
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Phone number',
      dataIndex: 'contact_number',
      key: 'contact_number',
      className:'nowrap',
    },
    {
      title: 'Status',
      dataIndex: 'statusLabel',
      key: 'statusLabel',
      render: (text: string) => (
        <div className={`common-status is-green ${text == 'Disable' ? 'disabled' : ''}`}>{text}</div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: string, record: any) => {
        return (
          <ul className={'common-action'}>
            {/* <Switch onClick={() => onSwitchStatus(+record?.id, !!record?.status)} checked={!!record?.status} /> */}
            <li>
              <NavLink
                to={PATH.locations_edit + record?.id}
              >
                <span className='action-item action-edit'></span>
              </NavLink>
            </li>
            <li className="action-remove" onClick={() => confirm(String(record?.id ?? ''))}></li>
          </ul>
        );
      },
    },
  ];

  return (
    <>

      <Spin spinning={loading}>

        <Table
          className={'custom-table'}
          dataSource={dataSource}
          columns={columns}
          scroll={{
            x: 900,
          }}
          pagination={{
            total: pagination?.total_record,
            pageSize: pagination?.record_per_page,
            defaultCurrent: Number(searchParams.get('page') ?? 1),
            defaultPageSize: Number(searchParams.get('per_page') ?? 10),
            onChange: (pageChange, sizeChange) => {
              const params: any = {
                page: pageChange == 0 ? 1 : pageChange,
                per_page: sizeChange,
              };
              setSearchParams(params);
              // dispatch(promotionActions.setListPromotionParams({
              //   page: params.page,
              //   per_page: params.per_page,
              // }));
            },
            showSizeChanger: true,
          }}
        />
      </Spin>
      <ModalConfirm ref={confirmRef} />
    </>
  );
};

export default LocationsTable;
