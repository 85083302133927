import IconCustomerCircle from 'assets/svg/IconCustomerCircle';
import IconDiscountCircle from 'assets/svg/IconDiscountCircle';
import IconStarCircle from 'assets/svg/IconStarCircle';
import IconVoucherCircle from 'assets/svg/IconVoucherCircle';
import ChartSales from '../components/ChartSales';
import ChartServices from '../components/ChartServices';
import SalesTable from '../components/SalesTable';
import WorkingTable from '../components/WorkingTable';
import styles from './index.module.scss';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import analyticActions from '../services/actions';
import { useDispatch } from 'react-redux';
import analyticSelectors from '../services/selectors';
import moment from 'moment';
import downloadFile from 'utils/downloadFile';
import { first, get, last } from 'lodash';
import apisAnalytic from '../services/apis';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { Dayjs } from 'dayjs';
import { useRangeDate } from 'components/common/GroupFilter/hook/useRangeDate';
import GroupFilterDate from 'components/common/GroupFilter';
import { ISummary } from '../services/types/api';
import { formatMoney } from 'utils/unit';

type MyDivProps = React.HTMLProps<HTMLDivElement> & {
  style?: {
    '--p': number;
  };
};

export interface IOptionAnalyticItem {
  value: 'month' | 'week' | 'year' | 'month_3';
  label: string;
}

const AnalyticsPage = () => {

  const dispatch = useDispatch();
  const setLoadingPage = useSetLoadingPage();
  const [optionDate, setOptionDate] = useState<{dateStart: number, dateEnd: number}>({dateStart : moment().unix(), dateEnd: moment().unix()});  

  const getRangeDate = useRangeDate;

  // const options: IOptionAnalyticItem[] = [
  //   {
  //     value: 'month',
  //     label: 'This month'
  //   },
  //   {
  //     value: 'week',
  //     label: 'This week'
  //   },
  //   {
  //     value: 'year',
  //     label: 'This year'
  //   } 
  // ];

  const onChangeOptionPicker = (val:string) => {
    const [unit, amount] = val.split('_');
    const [dateStart, dateEnd] = getRangeDate(unit, Number(amount));    
    setOptionDate({dateStart, dateEnd});
    dispatch(analyticActions.getAnalytics.fetch({
      start_date: dateStart,
      end_date: dateEnd,
    }));    
  };

  useEffect(() => {
    // const [dateStart, dateEnd] = getRangeDate('month', 0);
    dispatch(analyticActions.getAnalytics.fetch({
      start_date: moment().unix(),
      end_date: moment().unix(),
    })); 
  }, []);


  const onChangeDateRangePicker = (values: Dayjs[]) => {
    const dateStart = first(values)?.unix() ?? 0;
    const dateEnd = last(values)?.unix() ?? 0;
    setOptionDate({dateStart, dateEnd});
    dispatch(analyticActions.getAnalytics.fetch({
      start_date: dateStart,
      end_date: dateEnd,
    }));
  };
  
  const cancelledBooking = analyticSelectors.bookingCancel();
  const numberOfBooking = analyticSelectors.booking();
  const getNewCustomer = analyticSelectors.getNewCustomer();
  // const getVoucher = analyticSelectors.getVoucher();
  const revenueService = analyticSelectors.getRevenueService() ?? [];
  // const promotion = analyticSelectors.getPromotion();
  const review = analyticSelectors.getReview();
  const getAverageSpendCustomer = analyticSelectors.getAverageSpendCustomer();
  const totalSale = analyticSelectors.getTotalSale();
  

  const cancelledProps: MyDivProps = {
    style: {
      '--p': cancelledBooking.booking_cancel * 100 / cancelledBooking.total,
    },
  };

  const bookingProps: MyDivProps = {
    style: {
      '--p': numberOfBooking.booking_offline * 100 / (numberOfBooking.booking_online + numberOfBooking.booking_offline),
    },
  };

  const onClickExport = async () => {
    setLoadingPage(true);
    
    try {
      const res = await apisAnalytic.exportAnalytic({
        start_date: optionDate.dateStart,
        end_date: optionDate.dateEnd
      });
      const file_export_url = get(res, ['data', 'data', 'file_export'], '');
      if (!file_export_url) return;
      downloadFile(file_export_url);
    } catch (error) {
      const errorText = get(error, 'response.data.error.message', '');
      if (errorText) {
        message.error(errorText);
        return;
      }
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setLoadingPage(false);
    }
  };    

  const dataCardSummary = [
    {
      ...totalSale,
      icon: <IconCustomerCircle />,
      money: true
    },
    {
      ...getNewCustomer,
      icon: <IconDiscountCircle />,
      
    },
    {
      ...getAverageSpendCustomer,
      icon:  <IconVoucherCircle />,
      money: true
    },
    {
      ...review,
      icon: <IconStarCircle />
    }
  ];

  const CardSummary = (values: ISummary) => {
    
    return (
      <li className={`${styles.overview_item}`}>
        <div className={styles.card_info}>
          <p className={styles.info_title}>
            {values.money ? formatMoney(values.total_amount) : values.total_amount}<span className={`common-growth ${values.percent < 0 ? 'is-reduce' : ''}`}>{values.percent} %</span>
          </p>
          <p className={styles.info_text}>{values.content}</p>
        </div>
        {/* <div>
          {values.icon}
        </div> */}
      </li>
    );
  };

  return (
    <>
      <div className={'private-filter'}>
        {/* <button className='common-btn-export'>Export</button> */}
        <button className='common-btn-export' onClick={onClickExport}>
          <span>{'Export'}</span>
        </button>
        <GroupFilterDate onChangeOptionPicker={onChangeOptionPicker} onChangeDateRangePicker={onChangeDateRangePicker} optionDate={optionDate} />
        {/* <div className='common-select'>
          <OptionPicker onChange={onChangeOptionPicker} />
        </div>
        <div className='common-select'>
          <DateRangePicker onChange={onChangeDateRangePicker} start_date={optionDate.dateStart} end_date={optionDate.dateEnd}/>
        </div> */}
      </div>
      <section className={styles.section_analytics}>
        <div className={styles.analytics_left}>
          <div className={styles.left_inner}>
            <ul className={styles.analytics_overview}>
              {dataCardSummary.map((o, index) => (
                <CardSummary key={index} {...o} />
              ))}
            </ul>
            <div className={`${styles.analytics_card}`}>
              <div className={styles.analytics_label}>Sales Figures</div>
              <div style={{ maxWidth: '100%' }}>
                <ChartSales />
              </div>
            </div>
            <div className={`${styles.analytics_card}`}>
              <div className={styles.analytics_label}>Category of Sales</div>
              <SalesTable />
            </div>
            <div className={`${styles.analytics_card}`}>
              <div className={styles.analytics_label}>Working hours</div>
              <WorkingTable />
            </div>
          </div>
        </div>
        <div className={styles.analytics_right}>
          <div
            className={`${styles.analytics_services} ${styles.analytics_card}`}
          >
            <div className={styles.analytics_label}>Services</div>
            <ChartServices revenueServices={revenueService} />
          </div>
          <div
            className={`${styles.analytics_bookings} ${styles.analytics_card}`}
          >
            <div className={styles.analytics_label}>Number of bookings</div>
            <div className='pie-wrapper'>
              <div className='pie animate' {...bookingProps}></div>
              <div className='pie-content'>
                <span>Total</span>
                <p>{(numberOfBooking?.booking_online ?? 0) + (numberOfBooking?.booking_offline ?? 0)}</p>
              </div>
            </div>
            <ul className={styles.pie_detail}>
              <li>
                <span>Online booking</span>
                <span>{numberOfBooking?.booking_offline ?? 0}</span>
              </li>
              <li>
                <span>Walk-in customer</span>
                <span>{numberOfBooking?.booking_online ?? 0}</span>
              </li>
            </ul>
          </div>
          <div
            className={`${styles.analytics_cancel} ${styles.analytics_card}`}
          >
            <div className={styles.analytics_label}>Cancelled Bookings</div>
            <div className='pie-wrapper is-red'>
              <div className='pie animate' {...cancelledProps}></div>
              <div className='pie-content'>
                <span>Total</span>
                <p>{cancelledBooking?.total ?? 0}</p>
              </div>
            </div>
            <ul className={`${styles.pie_detail} ${styles.is_red}`}>
              <li>
                <span>Cancel & Refund</span>
                <span>{cancelledBooking?.booking_cancel ?? 0}</span>
              </li>
              <li>
                <span>No-show</span>
                <span>{cancelledBooking?.booking_no_show ?? 0}</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default AnalyticsPage;
