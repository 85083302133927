import { images } from 'assets';
import PrimaryButton from 'components/common/Buttons/PrimaryButton';
import OTP from 'components/common/OTP';
import { useState } from 'react';
import styled from 'styled-components';
import userApis from '../services/apis';
import { IApiStaffCheckIn } from '../services/types/teamMember';
import { get } from 'lodash';
import message from 'components/common/Message';
import { useForm } from 'antd/es/form/Form';
import { Col, Form, Row } from 'antd';
import MainLogo from 'assets/icons/main_logo.svg';
import storage from 'utils/sessionStorage';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import IconBack from 'assets/svg/IconBack';
import moment from 'moment';
import { useTranslation } from 'react-i18next';


enum E_STEPS {
  OTP = 'OTP',
  SUCCESS = 'SUCCESS',
}
interface IStaffCheckinCheckoutRes {
  id: number;
  full_name: string;
  phone: string;
  email?: any;
  total_sales: number;
  no_of_customer: number;
  total_worked: number;
  checkin_time: string;
  checkout_time?: string;
  checktype: 'check_in' | 'checkout_out';
}

interface IResultItem {
  label: string;
  value: string;
}

function UserCheckInPage() {
  const [step, setStep] = useState<E_STEPS>(E_STEPS.OTP);
  const [form] = useForm();
  const [label, setLabel] = useState<string>();
  const [result, setResult] = useState<IStaffCheckinCheckoutRes>();
  const [error, setError] = useState<boolean>();
  const setLoadingPage = useSetLoadingPage();
  const merchant_code = storage.merchantCode.get() ?? '';
  const navigate = useNavigate();
  const { t: headerLang } = useTranslation('header');
  const handleCheckIn = async (values: any) => {
    setLoadingPage(true);
    try {
      const pin = values._one.toString() + values._two.toString() + values._three.toString() + values._four.toString();

      const params: IApiStaffCheckIn = {
        pin_number: pin,
        merchant_code: merchant_code ?? '',
        date_time: moment().format('YYYY-MM-DD HH:mm:ss')
      };
      
      const res = await userApis.staffCheckIn(params);
      const data = get(res, 'data.data', '');
      const dataLabel = data?.checktype.replace('_', ' ') ?? '';
      const label = dataLabel.charAt(0).toUpperCase() + dataLabel.slice(1);
      setResult(data); 
      setLabel(label);
      const msg = get(res, 'data.message', '');
      if (msg) {
        message.success(msg);

        setStep(E_STEPS.SUCCESS);
      } else {
        throw 'fail';
      }

    } catch (error) {
      setLoadingPage(true);

      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        form.resetFields();
        return;
      }
      form.resetFields();
      setLoadingPage(false);
      setError(true);
    } finally {
      setLoadingPage(false);
    }
  };

  const results: IResultItem[] = [
    // {
    //   label: 'Full Name',
    //   value: result?.full_name ?? ''
    // },
    // {
    //   label: 'Phone',
    //   value: result?.phone ?? ''
    // },
    // {
    //   label: 'Email',
    //   value: result?.email ?? ''
    // },
    // {
    //   label: 'Checkin Time',
    //   value: getFormatShortDate(result?.checkin_time ?? '')
    // },
    // {
    //   label: 'Checkout Time',
    //   value: getFormatShortDate(result?.checkout_time ?? ''),
    // },

    {
      label: 'Duration',
      value: (moment(result?.checkin_time ?? '').format('HH:mm'))+ ' ~ ' + (result?.checkout_time  !== null ? moment(result?.checkout_time ?? '').format('HH:mm')  : '--:--')
    },
  ];


  const ResultItem = ({ label, value }: IResultItem) => (
    <tr>
      <td className='label'>{label}:</td>
      <td className='value'> {value}</td>
    </tr>
  );

  return (
    <Form
      onChange={() => setError(false)}  
      form={form}
      onFinish={handleCheckIn}
    >
      <UserCheckInPageStyled>
        <img src={MainLogo} alt='Natural Nails and Beauty'></img>

        {step === E_STEPS.OTP && <div className='box'>
          <p className="title">{headerLang('TimeAttendance')}</p>
          <p>{headerLang('PleaseProvideYourPINNumberToBeginTheCheckInOrCheckOutProcess')}</p>
          <OTP size='big' />
          {error && <p className='error'>* {headerLang('PinNumberIsInvalid')}</p>}
        </div>}
        {step === E_STEPS.SUCCESS && <div className='box'>
          <img src={images.success} className='success-icon' />
          <p className="success-text">{label} success</p>
          <p className="success-text">{result?.full_name ?? ''}</p>
          <TableStyled>
            <tbody>
              {results.map((o, index) => (
                <ResultItem key={index} {...o} />
              ))}
            </tbody>
          </TableStyled>
          {/* <div className='form-submit'>
            <div
              className='common-btn is-white'
              onClick={() => window.location.reload()}
            >
              Back
            </div>
            <button className='common-btn' >
              Save
            </button>
          </div> */}
        </div>}
        {step !== E_STEPS.SUCCESS ? 
        <div className='search-wrapper'>
          <button
            type='button'
            onClick={() => navigate(-1)}
            className='icon-back'>
              <IconBack />
          </button>
          <PrimaryButton type='submit' className='primary_button' label={headerLang('Confirm')||''} />
        </div> :
          <Row className='btn-row'>
            <Col xs={24} sm={24} md={18} lg={16} xl={12}>
              <Row gutter={[16, 16]} className='column-xs-revert'>
                <Col xs={24} sm={24} md={12}>
                  <NavLink to={'/private/bookings'}><PrimaryButton type='button' className='primary_button white' label='Go to Booking page' /></NavLink>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <PrimaryButton type='button' onClick={() => window.location.reload()} className='primary_button' label='Back to Time attendance' />
                </Col>
              </Row>
            </Col>
          </Row>
        }

      </UserCheckInPageStyled>
    </Form>
  );
}

export default UserCheckInPage;

const TableStyled = styled.table`
    margin-top: 32px;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 12px 0px;
    color: var(--color-primary);
    tr.tr-service {
      th {
        text-transform: uppercase;
      }
    }

    .text-left {
      text-align: left;
    }
    .label {
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      color: #363565;
      width:100%;
    }
    .value {
      font-weight: 400;
      font-size: 20px;
      line-height: 140%;
      text-align: right;
      color: #363565;
      width:100%;
    }
`;
const UserCheckInPageStyled = styled.div`
  display: flex;
  height: 100vh;
  padding: 24px 15%;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background: #F0F2F7;

  .error {
    color: red;
  }

  .logo {
    height: 10vh;
  }
  .box {
    display: flex;
    width: 100%;
    padding: 5%;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    // flex: 1 0 0;
    border-radius: 6px;
    background: #FFF;
  }
  p {
    align-self: stretch;
    color: #363565;
    /* font-family: "Inter"; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }
  .title {
    color: #363565;
    /* font-family: "Inter"; */
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 44.8px */
  }
  .success-icon {
    display: flex;
    width: 140px;
    height: 140px;
    justify-content: center;
    align-items: center;
  }
  .success-text {
    color: var(--grayish-navy-main, #363565);
    text-align: center;
    /* font-family: 'Inter'; */
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 44.8px */
  }
  button.primary_button {
    width: 100%;
  }
  .form-submit-wrapper {
    position: fixed;
  }
  .btn-row {
    width:100%;
    justify-content:center;
    button {
      flex:1;
      &.white {
        border-radius: 6px;
        border: 1px solid var(--grayish-navy-main, #363565);
        background: var(--white, #FFF);
        color: var(--grayish-navy-main, #363565);
      }
    }
   
  }

  .search-wrapper {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
    width: 100%;
    &:last-child {
      margin-bottom: 0;
    }

    .icon-back {
      width: 64px;
      height: 64px;
      background: #fff;
      border-radius: 6px;
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 4%;
    .success-text {
      font-size: 24px;
    }
    .label {
      font-size: 18px;
    }
    .value {
      font-size: 16px; 
    }
    button.primary_button span {
      font-size:16px;
    }
    .success-icon {
      width: 100px;
      height: 100px;
    }
    .column-xs-revert {
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 576.98px) {
    .success-text {
      font-size: 20px;
    }
    .label {
      font-size: 14px;
    }
    .value {
      font-size: 14px; 
    }
    button.primary_button span {
     font-size: 14px;
    }
    .success-icon {
      width: 80px;
      height: 80px;
    }
  }
`;
