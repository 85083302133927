import { Empty, message } from 'antd';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import notificationActions from 'components/layout/Header/service/actions';
import apisNotification from 'components/layout/Header/service/apis';
import notificationSelectors from 'components/layout/Header/service/selectors';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import getSingleDateShortFormat from 'utils/moment/getSingleDateShortFormat';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

const UnreadTab = () => {
  const selectors = notificationSelectors.getNoificationList();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const modalConfirmRef = useRef<ModalConfirmRef>(null);
  const { t: headerLang } = useTranslation('header');
  useEffect(() => {
    dispatch(notificationActions.getListNotification.fetch());
  }, []);

  const onclickCheckView = (e: number) => {
    apisNotification.getCheckViewNotification(e);
    dispatch(notificationActions.getListNotification.fetch());
  };
  const list = selectors.filter(selectors => selectors.viewed === 0);

  const onClickDeleteNotification = () => {
    setOpen(!open);
    modalConfirmRef.current?.show({
      title: headerLang('DeleteThisNotifications'),
      msg: headerLang('AreYouSureYouWantToDeleteThisNotifications'),
      submit: async () => {
        try {
          const res = await apisNotification.deleteNotification();
          const msg = get(res, 'data.message', '');
          if (msg) {
            message.success(msg);
            dispatch(notificationActions.getListNotification.fetch());
          } else {
            const msgError = get(res, 'data.error.message');
            if (msgError) message.error(msgError);
          }
        } catch (error) {
          message.error('An error occurred. Please try again');
        }
      },
      icon: 'delete',
    });
  };

  return (
    <div className='notification-block is-all'>
      <NotificationListStyled>
        <button className={selectors.length > 0 ? 'notification-remove-btn' : 'dl-none'} onClick={() => onClickDeleteNotification()}></button>
      </NotificationListStyled>
      <p className='notification-title'>{headerLang('Notifications')}</p>
      <ul className='notification-list'>
        {
          list.length > 0 ?
            list.map(o => (
              <>
                <li onClick={() => onclickCheckView(o.id)}>
                  <div className={o.viewed == 0 ? 'icon' : 'no_icon'}></div>
                  <NotificationImageStyled>
                    <div className='notification-avatar'>

                      <div className='notification-img common-avatar'>
                        {o.full_name && Array.from(o.full_name)[0]}
                      </div>

                    </div>
                  </NotificationImageStyled>
                  <div>
                    <p className='notification-text'>
                      <span>{o.full_name}</span> {o.notify_type} {' '}
                      {/* <span>{e.service}</span> */}
                    </p>
                    <p className='notification-time'>{getSingleDateShortFormat(moment(o.time))} {headerLang('at')} {moment(o.time).format('HH:mm')}</p>
                  </div>
                </li>
              </>
            )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No notification'} />
        }
      </ul>
      <ModalConfirm ref={modalConfirmRef} />
    </div>
  );
};

export default UnreadTab;

const NotificationListStyled = styled.div`
.dl-none {
    display: none;
}
`;

const NotificationImageStyled = styled.div`
.notification-img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
}
`;